<div class="new-survey" [ngClass]="darkThemeService.getCurrentTheme">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center p-3 px-md-4 mb-3" style="width: inherit">
      <div class="d-flex align-items-center">
        <a href="https://kafuapp.com/" class="my-0 mr-md-auto font-weight-normal">
          <img [src]="
              darkThemeService.getCurrentTheme == 'light-mode'
                ? 'assets/imgs/logo.svg'
                : 'assets/imgs/logo-dark.png'
            " alt="" style="width: 50%" />
        </a>
      </div>
      <div>
        <h4 class="heading1">{{"newSurveySecond.heading"|translate}}</h4>
        <p class="paragraph headingSub">{{"newSurveySecond.headingSub"|translate}}</p>
      </div>
    </div>
  </div>
  <div class="pricing-container mt-5">
    <div class="card-deck mb-3 text-center">
      <div class="card mb-4 card1">
        <div class="card-body">
          <div class="card-img-top">
            <div class="image-box">
              <img [src]="
                  darkThemeService.getCurrentTheme == 'light-mode'
                    ? 'assets/imgs/new-survey/kafu-light-logo.png'
                    : 'assets/imgs/logo.svg'
                " alt="" class="img-fixed bg-color1" style="width: 50%" />
            </div>
          </div>
          <h1 class="card-title pricing-card-title font-heading">
            {{"newSurveySecond.brand"|translate}}
          </h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>
              <p class="paragraph">{{"newSurveySecond.brandSub"|translate}}

              </p>
            </li>
          </ul>
          <div class="btnfixing">
            <button type="button" class="btn btn-lg btn-primary1" (click)="createSurvey()">
              {{"newSurveySecond.Create"|translate}}
            </button>
          </div>
        </div>
      </div>
      <div class="card mb-4 card2">
        <div class="card-body">
          <div class="card-img-top">
            <div class="image-box">
              <img [src]="
                  darkThemeService.getCurrentTheme == 'light-mode'
                    ? 'assets/imgs/new-survey/survey-light-two.png'
                    : 'assets/imgs/new-survey/survey-two.png'
                " alt="" class="bg-color2 img-fixed" />
            </div>
          </div>
          <h1 class="card-title pricing-card-title font-heading">
            {{"newSurveySecond.own"|translate}}
          </h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>
              <p class="paragraph">{{"newSurveySecond.ownSub"|translate}}
              </p>
            </li>
            <li>
              <div style="text-align: center" class="mt-2">
                <span class="text-area">{{"newSurveySecond.form"|translate}} </span><span class="text-area"
                  style="text-decoration: underline">{{"newSurveySecond.HERE"|translate}}</span>
              </div>
            </li>
          </ul>
          <div class="btnfixing">
            <button type="button" class="btn btn-lg btn-primary2" (click)="createCustomSurvey()">
              {{"newSurveySecond.Create"|translate}}
            </button>
          </div>
        </div>