import { Injectable } from '@angular/core';
import { WebApiService } from './webApi.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private webApi: WebApiService) {
  }

  getNotifications() {
    return this.webApi.get('/notifications')
  }

}
