import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LanguageService } from "./../../../services/language.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { SurveyService } from "src/services/survey.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-new-promo-code",
  templateUrl: "./new-promo-code.component.html",
  styleUrls: ["./new-promo-code.component.css"],
})
export class NewPromoCodeComponent implements OnInit {
  survey: any;
  form: FormGroup;
  code: any;
  language: string = "en";
  activeStep: number = 1;
  constructor(
    public darkThemeService: DarkLiteThemeService,
    private surveyService: SurveyService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private toastr: ToastrService,

    public languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.language = this.languageService.getLanguageOrDefault();
    this.survey = this.surveyService.getLocalSurveyModal();
    console.log(
      "🚀 ~ NewPromoCodeComponent ~ ngOnInit ~ this.survey:",
      this.survey
    );
    this.form = this.formBuilder.group({
      phoneNumber: [
        "",
        [
          Validators.maxLength(14),
          Validators.minLength(10),
          Validators.pattern(/^\d{10,14}$/), // Updated pattern to match 10-14 digits
          Validators.required,
        ],
      ],
      code: [this.survey.promoCodeId.codes[0]],
    });
  }

  tabClick(tabNo) {
    this.activeStep = tabNo;
  }

  async copyValue(inputElement: HTMLInputElement) {
    try {
      await navigator.clipboard.writeText(inputElement.value);
      inputElement.setSelectionRange(0, 0);
      this.translateService.get("profile.copy").subscribe((text) => {
        this.toastr.success(text);
      });
    } catch (error) {
      console.error("Error copying text:", error);
      // Handle error, show error message, etc.
    }
  }

  save() {
    const data = {
      ...this.form.value,
      survey: this.survey.id || this.survey._id,
      promoCodeId: this.survey.promoCodeId._id,
    };
    this.surveyService.createClaimedCode(data).subscribe((result) => {
      this.translateService
        .get("promoCode.success_message")
        .subscribe((text) => {
          this.toastr.success(text);
        });
      this.router.navigate(["/new-completed-survey"]);
    });
  }
}
