
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-interests',
  templateUrl: './my-interests.component.html',
  styleUrls: ['./my-interests.component.css']
})
export class MyInterestsComponent implements OnInit {
  interestsList = [];
  form: FormGroup
  imageUrl = "https://apiv2.kafuapp.com:3001/images/"

  @Input("userInfo") set setUser(data: any) {
    if (data) {
      this.form.patchValue({ interests: data.interestIds });
    }
  }
  constructor(public authService: AuthService, private formBuilder: FormBuilder, private translateService: TranslateService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.getInterests();
    this.initForm();
  }
  initForm() {
    this.form = this.formBuilder.group({
      interests: [[]]
    });
  }
  getInterests() {
    this.authService.getInterests()
      .subscribe(result => {
        this.interestsList = result;
      });
  }
  changeInterest(event, interest) {
    const index = this.form.value.interests.findIndex(id => id === interest._id);
    if (index === -1 && event.target.checked) {
      this.form.value.interests.push(interest._id);
    } else if (index !== -1 && event.target.checked === false) {
      this.form.value.interests.splice(index, 1);
    }
  }

  isChecked(interest) {
    const index = this.form.value.interests.findIndex(id => id === interest._id);

    if (index !== -1) {

      return true;
    }

    return false;
  }
  save() {
    const updated = {
      interests: this.form.value.interests
    }
    this.authService.updateProfile(updated, null)
      .subscribe(result => {
        // this.showLoader = false;
        this.translateService.get('profile.success_message')
          .subscribe((text) => {
            this.toastr.success(text);
          });
        this.authService.setCurrentUser(result, null);
      })
  }
}
