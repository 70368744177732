import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/services/auth.service";
import { LanguageService } from "src/services/language.service";
import { CommingSoonModalComponent } from "../../loggedin-home/comming-soon-modal/comming-soon-modal.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { eLanguage } from "src/app/general/enums/language.enum";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";

declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  dropdownStyleDisplay = "block";
  user: any;

  changeToLang: any;

  eLanguage = eLanguage;

  constructor(
    public authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    public darkThemeService: DarkLiteThemeService,
    public languageService: LanguageService,
    public activatedRoute: ActivatedRoute
  ) {
    this.user = this.authService.currentUser;
    this.changeToLang =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_English
        ? eLanguage.Short_Arabic
        : eLanguage.Short_English;
  }

  ngOnInit(): void { }

  logout() {
    this.authService.LogOut();
  }
  handleNavigate() {
    this.router.navigate(["/reporting"]);
  }
  RechargeWallet() {
    this.router.navigate(["/profile"]);
    $('.nav-tabs a[href="#Wallet"]').tab("show active");
  }

  Surveys() {
    this.router.navigate(["/reporting"]);
  }
  SurveysPortal() {
    this.router.navigate(["/surveys"]);
  }
  changeLang(): any {
    if (
      this.languageService.getLanguageOrDefault() == eLanguage.Short_English
    ) {
      this.languageService.setLanguage(eLanguage.Short_Arabic);

      this.router.navigate(["."], {
        queryParams: {
          lang: eLanguage.Short_Arabic,
        },
        relativeTo: this.activatedRoute,
        queryParamsHandling: "merge",
      });
    } else {
      this.languageService.setLanguage(eLanguage.Short_English);

      this.router.navigate(["."], {
        queryParams: {
          lang: eLanguage.Short_English,
        },
        relativeTo: this.activatedRoute,
        queryParamsHandling: "merge",
      });
    }
    setTimeout(() => window.location.reload(), 0);
  }
  openComingSoonModal() {
    const entModel = this.modalService.show(CommingSoonModalComponent, {
      class: "redeem-modal-container modal-dialog-centered",
      // backdrop: 'static',
    });
    entModel.content.onClose = () => {
      entModel.hide();
    };
  }
  showDropDownMenu() {
    if (this.dropdownStyleDisplay == "block") {
      this.dropdownStyleDisplay = "none";
    } else {
      this.dropdownStyleDisplay = "block";
    }
  }
}
