import { async } from "@angular/core/testing";
import { AuthService } from "src/services/auth.service";
import { Socket } from "ngx-socket-io";
import { Component, OnDestroy } from "@angular/core";
import { MessagingService } from "../services/messaging.service";
import { ShortenNumberPipe } from "./shorten-number.pipe";
import { NavigationEnd, Router } from "@angular/router";

declare const gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnDestroy {
  title = "kafu";
  private subscription;

  constructor(
    private messagingService: MessagingService,
    public router: Router,
    public socket: Socket,
    public authService: AuthService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag("config", "G-7BG3GK0D4G", { page_path: event.urlAfterRedirects });
      }
    });
  }

  ngOnInit() {
    if (!this.socket.ioSocket.connected) {
      // Connect to the Socket.IO server
      this.socket.connect();
      if (this.authService.currentUser?._id) {
        this.socket.emit("setOnline", {
          userId: this.authService.currentUser?._id,
        });
      }

      // Subscribe to events
      // this.subscription = this.socket.on("onlineUsers", (data) => {
      //   console.log(
      //     "🚀 ~ AppComponent ~ this.subscription=this.socket.on ~ data:",
      //     data
      //   );
      //   // Handle event data
      // });
    }
  }

  async ngOnDestroy() {
    console.log("onDestroy");

    // Disconnect the socket when the component is destroyed
    if (this.authService.currentUser?._id) {
      const userId = this.authService.currentUser._id;
      this.socket.emit("setOffline", { userId });
      this.socket.disconnect();
    }
  }
}
