import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { HomeService } from "../../../services/home.service";
import { ToastrService } from "ngx-toastr";
import jspdf from "jspdf";
import { WebApiService } from "../../../services/webApi.service";
import { saveAs } from "file-saver";
import { BaseChartDirective } from "ng2-charts";
import { LanguageService } from "../../../services/language.service";
import { SurveyService } from "../../../services/survey.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { CommingSoonModalComponent } from "../loggedin-home/comming-soon-modal/comming-soon-modal.component";
import * as _html2canvas from "html2canvas";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { eLanguage } from "src/app/general/enums/language.enum";
import { AuthService } from "src/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
const html2canvas: any = _html2canvas;
@Component({
  selector: "app-survey-report",
  templateUrl: "./survey-report.component.html",
  styleUrls: ["./survey-report.component.css"],
})
export class SurveyReportComponent implements OnInit {
  language: "";
  survey: any;
  lang: any;
  showAllSurveys: boolean = false;
  ageGroup: any;
  isButtonDisabled = false;
  showLoader = false;
  constructor(
    private toastr: ToastrService,
    private webApi: WebApiService,
    private authService: AuthService,
    private homeService: HomeService,
    private modalService: BsModalService,
    private surveyService: SurveyService,
    public languageService: LanguageService,
    private translateService: TranslateService,
    public darkThemeService: DarkLiteThemeService
  ) {
    this.survey = this.surveyService.getLocalSurveyModal();
    this.lang =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
  }

  @ViewChild("content", { static: false }) content: ElementRef;

  currentLanguage = "en";
  // Data Store
  surveyData = [];
  clickedSurvey = [];
  isSidebarVisible = true;
  userStatsReport: any = [];
  female: any;
  male: any;
  showingFile = false;
  webAnswers: any;
  mobileAnswers: any;
  mobile: any;
  web: any;
  userStats: any;
  total: any;
  kafu: any;
  clicked: any;
  clickMobile: any;
  clickWeb: any;
  city: any;
  likes: any;
  free: any;
  test: any;
  country: any[] = [];
  label: any;
  count: any;
  files: any;
  mobileAnswersPercentage: any;
  webAnswersPercentage: any;

  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function (value: any) {
              const maxLength = 10; // Set your desired maximum length
              return value.length > maxLength
                ? value.substring(0, maxLength) + "..."
                : value;
            },
          },
        },
      ],
      yAxes: [{}],
    },
  };
  chartHeight = 14; // Set the desired height

  barChartLabels = [];
  barChartType: ChartType = "bar" as ChartType;
  barChartLegend = false;

  barChartDataForWebSurveyAnswer: ChartDataSets[] = [
    // { data: [65, 59, 80, 81, 56, 55, 40, 20], label: "Series A" },
  ];
  barChartDataForLike: ChartDataSets[] = [
    // { data: [65, 59, 80, 81, 56, 55, 40, 20], label: "Series A" },
  ];

  barChartDataForMobileSurveyAnswer: ChartDataSets[] = [
    // {
    //   data: [65, 59, 80, 81, 56, 55, 40, 20],
    //   label: "Series A",
    //   borderColor: "rgb(255, 174, 59)",
    //   backgroundColor: "rgb(255, 174, 59)",
    // },
  ];

  barCountryChartLabels: ChartDataSets[] = [];
  barCountryChartType: ChartType = "bar" as ChartType;
  barCountryChartLegend = false;
  barCityChartLabels: ChartDataSets[] = [];
  barCityChartType: ChartType = "bar" as ChartType;
  barCityChartLegend = false;

  barChartDataCountry: ChartDataSets[] = [];
  barChartDataCity: ChartDataSets[] = [];
  // Doughnut Chart
  doughnutChartData = [];
  doughnutChartLabels: string[] = ["Kafu Network", "Kafu Promotion"];
  doughnutChartLabelClick: string[] = ["click on Mobile", "click on Web"];

  fellowGenderDoughnutChartLabels: string[] = [];
  fellowGenderDoughnutChartData: number[] = [];
  doughnutChartDataClick: number[] = [];
  colors = ["#1d1d49", "#f05c6d", "#AE3A60", "#FF33E6", "#1872bb"]; // Add more colors if needed

  doughnutChartType: ChartType = "doughnut" as ChartType;
  doughnutChartLegend = false;
  doughnutChartColors: any[] = [
    {
      backgroundColor: this.colors, // Add your colors here
    },
  ];
  @ViewChild("myChart", { static: true }) myChart: BaseChartDirective;

  ngOnInit(): void {
    this.currentLanguage = this.languageService.getLanguageOrDefault();

    this.surveyReport(this.survey);
    this.surveyFileDownload(this.survey);
  }
  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  surveyReport(survey: any) {
    this.showLoader = true;

    this.surveyService.getSurveyReports(survey.id).subscribe((result) => {
      this.male = result.male;
      this.surveyData = result?.answer;
      this.female = result.female;
      this.mobile = result.mobile;
      this.web = result.web;
      this.kafu = result.kafu;
      this.free = result.free;
      this.total = result.country.length;
      this.showLoader = false;

      this.ageGroup = this.countDuplicates(
        Array.from(
          result.answer
            .map((item) => item.age.replace(/سنين|Years/g, "Years"))
            .filter((age) => age && age.trim())
        )
      );

      this.likes = this.groupByDayWithPercentage(result?.likes);
      console.log(
        "🚀 ~ SurveyReportComponent ~ this.surveyService.getSurveyReports ~ likes:",
        this.likes
      );
      this.webAnswers = result.answer.filter(
        (ans: any) => ans.channel == "web"
      );
      this.mobileAnswers = result.answer.filter(
        (ans: any) => ans.channel == "mobile"
      );
      this.clickWeb = result.clicked.filter((ans: any) => ans.channel == "web");
      this.clickMobile = result.clicked.filter(
        (ans: any) => ans.channel == "mobile"
      );
      this.mobileAnswersPercentage = Math.ceil(
        (this.mobileAnswers.length /
          (this.webAnswers.length + this.mobileAnswers.length)) *
          100
      );
      this.webAnswersPercentage = Math.floor(
        (this.webAnswers.length /
          (this.webAnswers.length + this.mobileAnswers.length)) *
          100
      );
      this.arrlabel(result.clicked);
      const country: any = Array.from(
        result.answer.map((item) => item.country)
      );

      this.country = this.countDuplicates(country);
      this.city = this.countDuplicates(
        Array.from(
          result.answer
            .map((item) => item.cityId)
            .filter((cityId) => cityId !== undefined)
        )
      );

      this.barCountryChartLabels = this.country.map(
        (country: any) => country.name
      );
      this.clicked = this.barCityChartLabels = this.city.map(
        (city: any) => city.name
      );

      this.fellowGenderDoughnutChartLabels = this.ageGroup.map(
        (item: any) => item.name
      );

      this.fellowGenderDoughnutChartData = this.ageGroup.map(
        (item: any) => item.count
      );

      this.barChartDataCity = [
        {
          data: this.city.map((city: any) => city.count),
          label: "City",
        },
      ];

      this.barChartDataCountry = [
        {
          data: this.country.map((country: any) => country.count),
          label: "Countries",
        },
      ];

      this.doughnutChartData = [this.mobile, this.web];
      this.doughnutChartDataClick = [
        this.clickMobile?.length || 0,
        this.clickWeb?.length,
      ];

      const dailyData = this.groupDataByDay(this.webAnswers);

      const dailyDataMob = this.groupDataByDay(this.mobileAnswers);

      // Extract unique days and counts
      const allDays = Array.from(
        new Set([...dailyData, ...dailyDataMob].map((entry) => entry.day))
      ).sort();

      this.barChartLabels = allDays.map((entry) => entry);
      const counts = allDays.map(
        (day) => dailyData.find((entry) => entry.day === day)?.count || 0
      );

      const mobCounts = allDays.map(
        (day) => dailyDataMob.find((entry) => entry.day === day)?.count || 0
      );

      this.barChartDataForWebSurveyAnswer = [
        {
          data: counts,
          label: "Daily Answer",
        },
      ];
      this.barChartDataForLike = [
        {
          data: this.likes.map((item: any) => item.count),
          label: "Like Survey by Date",
        },
      ];
      this.barChartDataForMobileSurveyAnswer = [
        {
          data: mobCounts,
          label: "Daily Answer",
        },
      ];
      this.showLoader = false;
    });
  }

  surveyFileDownload(survey: any) {
    this.surveyService.getSurveyFIleDownload(survey.id).subscribe((result) => {
      this.files = result;
    });
  }
  toggleShowAllFile() {
    this.showingFile = !this.showingFile;
  }
  // getUserAllStats() {
  //   this.homeService.getUserStats().subscribe((result) => {
  //     this.userStats = result;

  //     const femalePercentage = Math.ceil(
  //       (result.female / (result.female + result.male)) * 100
  //     );
  //     const malePercentage = Math.floor(
  //       (result.male / (result.female + result.male)) * 100
  //     );
  //     this.fellowGenderDoughnutChartData = [femalePercentage, malePercentage];

  //     this.female = result && result.female ? result.female : 0;
  //     this.male = result && result.male ? result.male : 0;
  //     this.mobile = result && result.mobile ? result.mobile : 0;
  //     this.web = result && result.web ? result.web : 0;
  //     this.total = result.country?.length;
  //     this.kafu = result.UserReport.kafu ?? 0;
  //     this.free = result.UserReport.free ?? 0;
  //     this.test = this.arrlabel(result.UserReport?.clicked);
  //     this.country = this.countDuplicates(result?.country);
  //     this.barCountryChartLabels = this.country.map(
  //       (country: any) => country.name
  //     );
  //     this.barChartDataCountry = [
  //       {
  //         data: this.country.map((country: any) => country.count),
  //         label: "Series A",
  //       },
  //     ];
  //   });
  // }
  groupDataByDay(data: any[]): { day: string; count: number }[] {
    const dayCounts = {};

    data.forEach((item) => {
      const date = new Date(item.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // January is 0, so add 1
      const day = date.getDate();
      const dayKey = `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;

      if (dayCounts[dayKey]) {
        dayCounts[dayKey]++;
      } else {
        dayCounts[dayKey] = 1;
      }
    });

    return Object.keys(dayCounts).map((dayKey) => ({
      day: dayKey,
      count: dayCounts[dayKey],
    }));
  }

  groupByDayWithPercentage(
    data: any[]
  ): { day: string; count: number; percentage: number }[] {
    const dayCounts = {};
    const totalItems = data.length;

    data.forEach((item) => {
      const date = new Date(item.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // January is 0, so add 1
      const day = date.getDate();
      const dayKey = `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;

      if (dayCounts[dayKey]) {
        dayCounts[dayKey]++;
      } else {
        dayCounts[dayKey] = 1;
      }
    });

    return Object.keys(dayCounts).map((dayKey) => ({
      day: dayKey,
      count: dayCounts[dayKey],
      percentage: (dayCounts[dayKey] / totalItems) * 100,
    }));
  }

  // basicReportDownload() {
  //   const data = { name: "Basic Report", surveyId: this.survey.id };
  //   this.webApi.basicPdfReport(data).subscribe((response: any) => {
  //     console.log(response, "whats here");
  //     const blob: any = new Blob([response], { type: response.type });
  //     saveAs(blob, "report.pdf");
  //   });
  // }

  // bater() {
  //   // this.isButtonDisabled = true

  //   // const fileName = { name: "Basic Report", type: "pdf", surveyId: this.survey.id };

  //   // this.webApi.basicPdfReport(fileName).subscribe((response: any) => {
  //   //   // const blob: any = new Blob([response], { type: response.type });
  //   //   // saveAs(blob, "report.pdf");
  //   // });
  //   var data = document.getElementById("capture");
  //   var topSection = data.querySelector(".top-section");
  //   var bottomSection = data.querySelector(".bottom-section");
  //   if (topSection) topSection.remove();
  //   if (bottomSection) bottomSection.remove();
  //   console.log("🚀 ~ SurveyReportComponent ~ bater ~ data:", data)
  //   html2canvas(data).then((canvas) => {
  //     console.log("🚀 ~ SurveyReportComponent ~ html2canvas ~ canvas:", canvas)
  //     // Few necessary setting options
  //     var imgWidth = 208;
  //     var pageHeight = 295;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     console.log("🚀 ~ SurveyReportComponent ~ html2canvas ~ imgHeight:", imgHeight)
  //     var heightLeft = imgHeight;

  //     const contentDataURL = canvas.toDataURL("image/png");
  //     let pdf = new jspdf(); // A4 size page of PDF
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);

  //     pdf.save("report.pdf"); // Generated PDF
  //   });
  //   // setTimeout(() => {
  //   //   this.isButtonDisabled = false
  //   // }, 60000)
  // }
  bater() {
    this.showLoader = true;
    var data: any = document.getElementById("capture");
    // var topSection = data.querySelector(".top-section");
    // var bottomSection = data.querySelector(".bottom-section");

    // Create a temporary clone of the data element

    // Hide top and bottom sections in the clone
    // if (topSection) data.querySelector(".top-section").style.display = 'none';
    // if (bottomSection) data.querySelector(".bottom-section").style.display = 'none';

    html2canvas(data).then((canvas) => {
      // Remove top and bottom sections from the canvas
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf(); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);

      pdf.save("report.pdf"); // Generated PDF

      // Remove the temporary clone and replace the iframes
      // if (topSection) data.querySelector(".top-section").style.display = 'flex';
      // if (bottomSection) data.querySelector(".bottom-section").style.display = 'flex';
      this.showLoader = false;
    });
  }

  Download(data: object): void {
    this.webApi.basicPdfReport(data).subscribe((response: any) => {
      console.log(response, "whats here");
      // const blob: any = new Blob([response], { type: response.type });
      // saveAs(blob, "report.pdf");
    });
  }
  dataToCSV(surveyData: any): string {
    const header = Object.keys(surveyData[0]).join(",");

    const rows = surveyData.map((item) => Object.values(item).join(","));

    return `${header}\n${rows.join("\n")}`;
  }
  downloadCSV() {
    this.isButtonDisabled = true;

    if (
      this.authService.currentUser &&
      this.authService.currentUser.planId &&
      this.authService.currentUser.planId.type === "free"
    ) {
      this.translateService
        .get("newSurvey.surveyLimitExceeded")
        .subscribe((text) => {
          this.toastr.error(text);
        });
    } else {
      this.surveyData = this.surveyData.map(
        ({ _id, surveyId, __v, questions, ...ans }) => {
          console.log(
            "🚀 ~ file: reporting.component.ts:506 ~ ReportingComponent ~ downloadCSV ~ questions:",
            questions
          );
          const mobile = this.clickedSurvey.filter(
            (item: any) =>
              item.channel == "mobile" && item.survey?._id == surveyId._id
          );
          const web = this.clickedSurvey.filter(
            (item: any) =>
              item.channel == "web" && item.survey?._id == surveyId?._id
          );

          const flattenedQuestions = questions
            .map((question) => {
              const selectedAnswerText =
                question.selectedAnswer !== undefined
                  ? `, Selected Answer: ${question.selectedAnswer}`
                  : "";
              const textAnswerText =
                question.textAnswer !== undefined
                  ? `, Text Answer: ${question.textAnswer}`
                  : "";

              let answersText = "";
              if (question.answers && question.answers.length > 0) {
                const answersList = question.answers
                  .map((a) => `${a.optionIndex}: ${a.option}`)
                  .join(", ");
                answersText = `, Answers: ${answersList}`;
              }

              return `Question Type: ${question.questionType}, Question: ${question.question}${answersText}${selectedAnswerText}${textAnswerText}`;
            })
            .join("; ");

          // if (mapping) {
          return {
            ...ans,
            // "Survey Name": this.survey.title,
            // Response: this.survey.totalResponse,
            // "Kafu Rsp": this.survey.kafuRes,
            // "General Rsp": this.survey.generalRes,
            // click: this.survey.click,
            // likes: this.survey.likes,
            // WebSurveyClicks: web.length || 0,
            // mobileSurveyClicks: mobile.length || 0,
            questions: flattenedQuestions,
          };
          // }
          // return ans;
        }
      );

      const csvData = this.dataToCSV(this.surveyData);

      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "report.csv";
      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
      const fileName = {
        name: "Basic Report",
        type: "csv",
        surveyId: this.survey.id,
      };

      this.Download(fileName);
      this.surveyFileDownload(this.survey);
    }
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 60000);
  }

  downloadExcel() {
    this.isButtonDisabled = true;

    if (
      this.authService.currentUser &&
      this.authService.currentUser.planId &&
      this.authService.currentUser.planId.type === "free"
    ) {
      this.translateService
        .get("newSurvey.surveyLimitExceeded")
        .subscribe((text) => {
          this.toastr.error(text);
        });
    } else {
      import("xlsx").then((xlsx) => {
        this.surveyData = this.surveyData.map(
          ({ _id, surveyId, __v, questions, ...ans }) => {
            // const mapping = this.survey._id === surveyId?._id;
            const mobile = this.clickedSurvey.filter(
              (ans: any) =>
                ans.channel == "mobile" && ans.survey == surveyId?._id
            );

            const web = this.clickedSurvey.filter(
              (ans: any) => ans.channel == "web" && ans.survey == surveyId?._id
            );
            const flattenedQuestions = questions
              .map((question) => {
                const selectedAnswerText =
                  question.selectedAnswer !== undefined
                    ? `, Selected Answer: ${question.selectedAnswer}`
                    : "";
                const textAnswerText =
                  question.textAnswer !== undefined
                    ? `, Text Answer: ${question.textAnswer}`
                    : "";

                let answersText = "";
                if (question.answers && question.answers.length > 0) {
                  const answersList = question.answers
                    .map((a) => `${a.optionIndex}: ${a.option}`)
                    .join(", ");
                  answersText = `, Answers: ${answersList}`;
                }

                return `Question Type: ${question.questionType}, Question: ${question.question}${answersText}${selectedAnswerText}${textAnswerText}`;
              })
              .join("; ");

            // if (mapping) {
            return {
              ...ans,

              questions: flattenedQuestions,
            };
            // }
            // return ans;
          }
        );
        const ws = xlsx.utils.json_to_sheet(this.surveyData);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        xlsx.writeFile(wb, "report.xlsx");
      });
      const fileName = {
        name: "Basic Report",
        type: "xlsx",
        surveyId: this.survey.id,
      };
      this.Download(fileName);
      this.surveyFileDownload(this.survey);
      // }
    }
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 60000);
  }
  countDuplicates(arr: string[]) {
    const counts: { [key: string]: number } = {};

    arr.forEach((str) => {
      counts[str] = (counts[str] || 0) + 1;
    });

    // Use Object.entries to convert the counts object into an array of [name, count] pairs
    const entries = Object.entries(counts);

    // Use map to transform the entries and filter out entries where count is null
    const result = entries
      .map(([name, count]) => ({ name, count }))
      .filter((entry) => entry.count !== null);
    return result;
  }
  openComingSoonModal() {
    const entModel = this.modalService.show(CommingSoonModalComponent, {
      class: "redeem-modal-container modal-dialog-centered",

      // backdrop: 'static',
    });
    entModel.content.onClose = () => {
      entModel.hide();
    };
  }
  arrlabel(strArray: any) {
    // Ensure this.label is initialized as an empty array
    this.label = this.label || [];
    this.count = this.count || [];

    strArray.forEach((str: any) => {
      const index = this.label.indexOf(str);
      if (index !== -1) {
        this.count[index]++;
      } else {
        this.label.push(str);
        this.count.push(1);
      }
    });
  }

  stopSurvey(survey: any) {
    this.surveyService.stop(survey).subscribe((result) => {
      let id = result._id;
      this.survey = { ...result, id };
      this.surveyService.createLocalSurveyModal({ ...result, id });
    });
  }
  edit(survey) {
    this.surveyService.edit(survey);
  }

  async copyUrl(surveyId: string, surveyType: string) {
    this.homeService.copySurveyUrl(surveyId, surveyType);
  }
}
