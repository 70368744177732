import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { LocalStorageService } from "angular-2-local-storage";
import { LanguageService } from "./language.service";
import { map, tap } from "rxjs/operators";

export interface ResultViewModel {
  Success: boolean;
  Message: string;
  Data: any;
  IsAuthorized: boolean;
}

@Injectable({
  providedIn: "root",
})
export class WebApiService {
  resources: any[] = [];

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    private localStorageService: LocalStorageService
  ) { }

  private setHeaders(token = null): HttpHeaders {
    // @ts-ignore
    const headersConfig = {
      "Content-Type": "application/json; charset=UTF-8",
      language: this.languageService.getLanguageOrDefault(),
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "application/XMLHttpRequest",
      Security: "Nmd@E>5.D]q.rE3:",
      Software: "web",
      Authorization: token
        ? token
        : String(this.localStorageService.get("accessToken") || ""),
    };
    // @ts-ignore
    return new HttpHeaders(headersConfig);
  }

  private setCustomHeaders(
    token = null,
    contentLength,
    $file = false
  ): HttpHeaders {
    // @ts-ignore
    const headersConfig = {
      "Content-Type": $file
        ? "application/octet-stream; charset=UTF-8"
        : "application/json; charset=UTF-8",
      language: this.languageService.getLanguageOrDefault(),
      Accept: $file ? "application/octet-stream" : "application/json",
      "X-Requested-With": "application/XMLHttpRequest",
      Security: "Nmd@E>5.D]q.rE3:",
      Software: "web",
      "Content-Length": contentLength,
      Authorization: token
        ? token
        : String(this.localStorageService.get("accessToken") || ""),
    };
    // @ts-ignore
    return new HttpHeaders(headersConfig);
  }

  private logError(filename, error: any) {
    console.error(filename, error);
    // return Observable.throw(error);
  }

  private log(path, data) {
    if (!environment.production) {
    }
    // return data;
  }

  // : Observable<ResultViewModel>
  get(path: string, params?: HttpParams, token = null): Observable<any> {
    return this.http
      .get<any>(`${environment.api_url}${path}`, {
        headers: this.setHeaders(token),
        params,
      })
      .pipe(
        tap(
          (data) => this.log(path, data),
          (error) => this.logError(path, error)
        )
      );
  }

  basicPdfReport(body): Observable<any> {
    return this.http.post(`${environment.api_url}/user/userStatsReport`, body, {
      responseType: "blob",
      headers: this.setHeaders(),
    });
  }

  invoicePdf(body: Object = {}): Observable<any> {
    console.log(body);
    return this.http.post(`${environment.api_url}/user/invoice/download`, body, {
      responseType: "blob",
      headers: this.setHeaders(),
    });
  }
  post(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post<any>(`${environment.api_url}${path}`, body, {
        headers: this.setHeaders(),
      })
      .pipe(
        tap(
          (data) => this.log(path, data),
          (error) => this.logError(path, error)
        )
      );
  }

  postObserveResponse(
    path: string,
    body: Object = {},
    token = null
  ): Observable<any> {
    return this.http
      .post<any>(`${environment.api_url}${path}`, body, {
        headers: this.setHeaders(token),
        observe: "response" as "body",
      })
      .pipe(
        map(
          (data) => {
            return data;
          },
          (error) => this.logError(path, error)
        )
      );
  }

  getObserveResponse(path: string, params?: HttpParams): Observable<any> {
    return this.http
      .get<any>(`${environment.api_url}${path}`, {
        headers: this.setHeaders(),
        observe: "response" as "body",
        params,
      })
      .pipe(
        tap(
          (data) => this.log(path, data),
          (error) => this.logError(path, error)
        )
      );
  }

  put(
    path: string,
    body: Object = {},
    token = null,
    $file = false
  ): Observable<any> {
    return this.http
      .put<any>(`${environment.api_url}${path}`, body, {
        headers: this.setCustomHeaders(token, 2, $file),
      })
      .pipe(
        tap(
          (data) => this.log(path, data),
          (error) => this.logError(path, error)
        )
      );
  }

  delete(path: string, params?: HttpParams): Observable<any> {
    return this.http
      .delete<any>(`${environment.api_url}${path}`, {
        headers: this.setHeaders(),
        params,
      })
      .pipe(
        tap(
          (data) => this.log(path, data),
          (error) => this.logError(path, error)
        )
      );
  }

  fileUpload(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post<any>(`${environment.api_url}${path}`, body, {
        headers: this.setHeaders(),
        reportProgress: true,
      })
      .pipe(
        tap(
          (data) => this.log(path, data),
          (error) => this.logError(path, error)
        )
      );
  }

  publicPost(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post<any>(`${environment.api_url}${path}`, body, {
        headers: this.setHeaders(),
      })
      .pipe(
        tap(
          (data) => this.log(path, data),
          (error) => this.logError(path, error)
        )
      );
  }

  publicGet(path: string, params?: HttpParams): Observable<any> {
    return this.http
      .get<any>(`${environment.api_url}${path}`, {
        headers: this.setHeaders(),
        params,
      })
      .pipe(
        tap(
          (data) => this.log(path, data),
          (error) => this.logError(path, error)
        )
      );
  }
}
