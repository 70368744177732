<div class="d-flex" [ngClass]="darkThemeService.getCurrentTheme">
  <app-new-sidebar
    style="border: 1px solid gray"
    class="side-bar"
    [ngClass]="{
      'sidebar-show': isSidebarVisible,
      'sidebar-hide': !isSidebarVisible,
      'border-ar': this.languageService.getLanguageOrDefault() === 'ar',
      'border-en': this.languageService.getLanguageOrDefault() !== 'ar'
    }"
  ></app-new-sidebar>
  <div
    class=""
    [ngClass]="
      this.languageService.getLanguageOrDefault() === 'ar'
        ? 'humburg-ar'
        : 'humburg'
    "
    (click)="toggleSidebar()"
  >
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 18L20 18"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M4 12L20 12"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M4 6L20 6"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>
  <div
    [ngClass]="darkThemeService.getCurrentTheme"
    class="wrapper home-wrap w-100 adjust-height"
    style="overflow-x: hidden"
  >
    <!-- my account section start -->
    <section class="my-stats">
      <div class="container-fluid">
        <section class="row align-items-center">
          <div class="wrapper profile-wrap col-lg-4">
            <section class="edit-profile">
              <div class="container">
                <form>
                  <!-- <div class="ed-image">
                    <div class="upload-tBK">
                      <div class="avatar-edit">
                        <input #fileEl (change)="onSelectFile(fileEl.files)" type="file" accept=".png, .jpg, .jpeg" />
                        <label for="imageUpload"></label>
                      </div>
                      <div class="avatar-preview">
                        <div id="imagePreview" *ngIf="!avatar" style="
                          background-image: url(assets/imgs/default-user.png);
                        "></div>
                        <div id="imagePreview">
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="upload-tBK">
                    <input
                      type="file"
                      id="fileInput1"
                      #fileEl
                      class="upload-input"
                      accept=".png, .jpg, .jpeg"
                      (change)="onSelectFile(fileEl.files)"
                    />
                    <label for="fileInput1" class="upload-label">
                      <span>+</span>
                    </label>

                    <img
                      [src]="avatar"
                      (click)="openFileInput()"
                      Class="image-preview"
                      id="fileInput1"
                      *ngIf="avatar"
                      alt="sadfd"
                    />
                  </div>
                </form>
                <div
                  class="px-3 w-50 text-white user-font"
                  [title]="authService.currentUser?.firstName"
                >
                  <h3>{{ authService.currentUser?.firstName }}</h3>
                  <p class="pb-2">
                    {{ "profile.PersonalAccount" | translate }}
                  </p>
                  <!-- <div class="m-progress" style="width: 80%">
                    <span class="num">80%</span>
                  </div> -->
                </div>
              </div>
            </section>
          </div>
          <div class="col-lg-8">
            <div class="stats">
              <div class="d-flex flex-wrap flex-sm-nowrap">
                <div class="stats-widget">
                  <div class="headerTop">
                    <h5 class="text-white">
                      {{ "home.mySurveys" | translate }}
                    </h5>
                  </div>
                  <div class="bottomFooter">
                    <b class="titleb">{{ userStats?.surveys }}</b>
                  </div>
                </div>
                <div class="stats-widget">
                  <div class="headerTop">
                    <h5 class="text-white">
                      {{ "home.answer" | translate }}
                    </h5>
                  </div>
                  <div class="bottomFooter">
                    <b class="titleb">{{ userStats?.answers }}</b>
                  </div>
                </div>

                <div class="stats-widget">
                  <div class="headerTop">
                    <h5 class="text-white">
                      {{ "home.spendingSAR" | translate }}
                    </h5>
                  </div>
                  <div class="bottomFooter">
                    <b class="titleb" *ngIf="userStats?.spendingPoints">{{
                      userStats?.spendingPoints | shortenNumber
                    }}</b>
                    <b class="titleb" *ngIf="!userStats?.spendingPoints">0</b>
                  </div>
                </div>
                <div class="stats-widget">
                  <div class="headerTop">
                    <h5 class="text-white">
                      {{ "home.freePoints" | translate }}
                    </h5>
                  </div>
                  <div class="bottomFooter">
                    <b class="titleb">{{
                      userStats?.freePoints | shortenNumber
                    }}</b>
                  </div>
                </div>
                <div class="stats-widget">
                  <div class="headerTop">
                    <h5 class="text-white">
                      {{ "home.referrals" | translate }}
                    </h5>
                  </div>
                  <div class="bottomFooter">
                    <b class="titleb">{{ userStats?.referrals }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <!--end my account-->
    <!--tab section-->
    <section class="section">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item paddingTab" role="presentation">
          <a
            class="nav-link text-capitalize active"
            id="Info-tab"
            data-toggle="tab"
            href="#Info"
            role="tab"
            ><i class="fas fa-user font-20"></i>
            {{ "profile.myInfo" | translate }}</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link text-capitalize"
            id="Wallet-tab"
            data-toggle="tab"
            href="#Wallet"
            role="tab"
          >
            <i class="fas fa-wallet font-20"></i>
            {{ "profile.wallet" | translate }}
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link text-capitalize"
            id="Interests-tab"
            data-toggle="tab"
            href="#Interests"
            role="tab"
            ><i class="fas fa-heart font-20"></i>
            {{ "profile.Interests" | translate }}</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="Surveys-tab"
            data-toggle="tab"
            href="#Surveys"
            role="tab"
          >
            <i class="fas fa-clipboard-list font-20"></i>
            {{ "home.mySurveys" | translate }}
          </a>
        </li>
      </ul>
      <div class="container-fluid">
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="Info"
            role="tabpanel"
            aria-labelledby="Info-tab"
          >
            <app-new-complete-profile
              [userInfo]="user"
            ></app-new-complete-profile>
          </div>
          <div
            class="tab-pane fade"
            id="Wallet"
            role="tabpanel"
            aria-labelledby="Wallet-tab"
          >
            <app-new-wallet></app-new-wallet>
          </div>
          <div
            class="tab-pane fade"
            id="Interests"
            role="tabpanel"
            aria-labelledby="Interests-tab"
          >
            <app-my-interests [userInfo]="user"></app-my-interests>
          </div>
          <div
            class="tab-pane fade"
            id="Surveys"
            role="tabpanel"
            aria-labelledby="Surveys-tab"
          >
            <app-my-surveys></app-my-surveys>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<!-- <app-footer></app-footer> -->
