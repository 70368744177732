<header
  [ngClass]="{
    logged: authService.isLoggedIn(),
    path: path === '/about-us'
  }"
  [ngClass]="darkThemeService.getCurrentTheme"
>
  <div class="container-fluid">
    <div class="d-flex desktop-nav">
      <div class="logo-nav d-flex">
        <a href="https://kafuapp.com/">
          <div class="logo">
            <img
              [src]="
                darkThemeService.getCurrentTheme == 'light-mode'
                  ? 'assets/imgs/logo.svg'
                  : 'assets/imgs/logo-dark.png'
              "
              alt=""
            />
          </div>
        </a>
        <nav>
          <ul>
            <li>
              <a routerLink="/" [class.active]="isRouteActive('/')">{{
                "header.Home" | translate
              }}</a>
            </li>
            <!-- <li>
              <a [routerLink]="['/about-us']">about</a>
            </li> -->
            <li>
              <a
                routerLink="/surveys"
                [class.active]="isRouteActive('/surveys')"
                >{{ "header.Survey" | translate }}</a
              >
            </li>
            <li *ngIf="!authService.isLoggedIn()">
              <a
                href="#services"
                [class.active]="isRouteActive('/#services')"
                >{{ "header.Services" | translate }}</a
              >
            </li>
            <li *ngIf="!authService.isLoggedIn()">
              <a
                href="#partners"
                [class.active]="isRouteActive('/#partners')"
                >{{ "header.Partners" | translate }}</a
              >
            </li>
            <li *ngIf="!authService.isLoggedIn()">
              <a href="#contact" [class.active]="isRouteActive('/#contact')">{{
                "header.contact" | translate
              }}</a>
            </li>
            <li *ngIf="!authService.isLoggedIn()">
              <a
                routerLink="/privacy-policy"
                [class.active]="isRouteActive('/privacy-policy')"
                >{{ "header.Privacy" | translate }}</a
              >
            </li>
            <li *ngIf="!authService.isLoggedIn()">
              <a
                routerLink="/terms-conditions"
                [class.active]="isRouteActive('/terms-conditions')"
                >{{ "header.Terms" | translate }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
      <!-- Start Before Sign-In Menu -->
      <div class="bf-sign" *ngIf="!authService.isLoggedIn()">
        <div class="in-out">
          <a (click)="signup()"
            ><i class="fas fa-lock"></i>{{ "header.join us" | translate }}</a
          >
          <a (click)="login()"
            ><i class="fas fa-user"></i>{{ "header.sign in" | translate }}</a
          >
        </div>
        <div class="lang">
          <a (click)="changeLang(changeToLang)">{{ changeToLang }}</a>
        </div>
        <div class="color-change">
          <a (click)="toggleButtonToChangeThemeClass()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.6596 10.75C14.6596 5.91751 18.4937 2 23.2234 2C27.9531 2 31.7872 5.91751 31.7872 10.75C31.7872 15.5825 27.9531 19.5 23.2234 19.5C18.4937 19.5 14.6596 15.5825 14.6596 10.75Z"
                fill="#FF8C58"
                stroke="url(#paint0_linear_1_1074)"
                stroke-width="2.7"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 15.9204C2 10.8683 5.83416 6.77271 10.5638 6.77271C15.2935 6.77271 19.1277 10.8683 19.1277 15.9204C19.1277 20.9726 15.2935 25.0682 10.5638 25.0682C5.83416 25.0682 2 20.9726 2 15.9204Z"
                fill="#1BBAA2"
                stroke="url(#paint1_linear_1_1074)"
                stroke-width="2.7"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.9362 23.875C10.9362 16.6263 16.7708 10.75 23.9681 10.75C31.1655 10.75 37 16.6263 37 23.875C37 31.1237 31.1655 37 23.9681 37C16.7708 37 10.9362 31.1237 10.9362 23.875Z"
                fill="#F05C6D"
                stroke="url(#paint2_linear_1_1074)"
                stroke-width="2.7"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_1074"
                  x1="31.7872"
                  y1="2"
                  x2="14.6596"
                  y2="2"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1D1D49" />
                  <stop offset="1" stop-color="#505084" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_1_1074"
                  x1="19.1277"
                  y1="6.77271"
                  x2="2"
                  y2="6.77271"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1D1D49" />
                  <stop offset="1" stop-color="#505084" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_1_1074"
                  x1="37"
                  y1="10.75"
                  x2="10.9362"
                  y2="10.75"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1D1D49" />
                  <stop offset="1" stop-color="#505084" />
                </linearGradient>
              </defs>
            </svg>
          </a>
        </div>
      </div>
      <!-- End Before Sign-In Menu -->

      <!-- Start After Sign-In Menu -->
      <div class="af-sign" *ngIf="authService.isLoggedIn()">
        <div class="profile-actions">
          <ul class="main-ul">
            <!-- <li class="has-child">
              <a><i class="fas fa-search"></i></a>
              <div class="search-inp s-menu">
                <input type="search" placeholder="{{ 'header.Search' | translate }}" />
                <button class="search-btn">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </li>
            <li class="has-child">
              <a><i class="fas fa-cog"></i></a>
              <div class="settings s-menu">
                <a>
                  <div class="setting-menu-tags">
                    <span class="menu-tags">
                      <img src="./assets/imgs/s-menu-tag-wallet.svg" alt="" />

                      {{ authService.currentUser?.totalBalance }}
                      <span class="tag-unit">SAR</span>
                    </span>
                    <span class="menu-tags">
                      <img src="./assets/imgs/s-menu-tag-wallet.svg" alt="" />
                      {{ authService.currentUser?.points }}
                      <span class="tag-unit">Pts</span>
                    </span>
                    <span class="menu-tags">
                      <img src="./assets/imgs/s-menu-tag-mobile.svg" alt="" />
                      0
                      <span class="tag-unit">Mpts</span>
                    </span>
                  </div>
                </a>
                <a [routerLink]="['/profile']">{{
                  "header.My Account" | translate
                  }}</a>
                <a [routerLink]="['/terms-conditions']">{{
                  "header.Terms" | translate
                  }}</a>
                <a [routerLink]="['/privacy-policy']">{{
                  "header.Privacy" | translate
                  }}</a>
                <a data-toggle="modal" data-target="#forgetPassword">{{
                  "header.Forget" | translate
                  }}</a>
                <a (click)="logout()">{{ "header.Logout" | translate }}</a>
              </div>
            </li> -->
            <li class="has-child">
              <a><i class="fas fa-globe"></i></a>
              <div class="settings langs s-menu">
                <a
                  (click)="changeLang(changeToLang)"
                  *ngIf="changeToLang === elanguage.Short_Arabic"
                  >عربي</a
                >
                <a
                  (click)="changeLang(changeToLang)"
                  *ngIf="changeToLang === elanguage.Short_English"
                  >English</a
                >
              </div>
            </li>
            <li class="has-child">
              <a>
                <i class="fas fa-bell"></i>
                <!-- <b>{{ this.length }}</b> -->
              </a>
              <div class="s-menu notifications" *ngIf="isLoaded">
                <!-- Add class new for unread notificaion -->
                <ng-container
                  *ngFor="let notification of notifications; let i = index"
                >
                  <a class="d-flex new" *ngIf="i < 3">
                    <div class="logo">
                      <img src="assets/imgs/logo.svg" alt="" />
                    </div>
                    <p>
                      <b>{{ notification.title }}</b>
                      {{ notification.description }}
                      <span>{{ notification.date }}</span>
                    </p>
                  </a>
                </ng-container>
                <a class="view-all" routerLink="/notifications">View All</a>
              </div>
            </li>
          </ul>
        </div>
        <div class="profile-info">
          <div class="user-img">
            <img [src]="avatar" alt="" />
            <div class="pf-progress">
              <svg class="progress-ring" width="120" height="120">
                <circle
                  class="progress-ring__circle"
                  stroke="#FF9054"
                  stroke-width="4"
                  fill="transparent"
                  r="52"
                  cx="60"
                  cy="60"
                />
              </svg>
            </div>
            <!-- Put progress percentage in value attribute -->
            <input value="85" type="hidden" />
          </div>
          <div class="user-info">
            <h5>{{ authService.currentUser?.firstName }}</h5>
          </div>
          <div class="px-2 pb-1"><i class="fas fa-angle-down"></i></div>
          <div class="dropdown-content s-menu">
            <a>
              <div class="setting-menu-tags">
                <span class="menu-tags">
                  <img src="./assets/imgs/s-menu-tag-wallet.svg" alt="" />

                  {{ totalBalance | number : "0.3-3" }}
                  <span class="tag-unit">SAR</span>
                </span>
                <span class="menu-tags">
                  <img src="./assets/imgs/s-menu-tag-wallet.svg" alt="" />
                  {{ authService.currentUser?.points | number : "0.2-2" }}
                  <span class="tag-unit">Pts</span>
                </span>
                <span class="menu-tags">
                  <img src="./assets/imgs/s-menu-tag-mobile.svg" alt="" />
                  0
                  <span class="tag-unit">Mpts</span>
                </span>
              </div>
            </a>

            <a [routerLink]="['/profile']">{{
              "header.My Account" | translate
            }}</a>
            <div class="line"></div>
            <a [routerLink]="['/profile']">{{
              "profile.wallet" | translate
            }}</a>
            <div class="line"></div>
            <a [routerLink]="['/surveys']">{{
              "header.surveyList" | translate
            }}</a>
            <div class="line"></div>
            <a [routerLink]="['/terms-conditions']">{{
              "header.Terms" | translate
            }}</a>
            <div class="line"></div>
            <a (click)="logout()">{{ "header.Logout" | translate }}</a>
          </div>
        </div>
        <div class="color-change">
          <a (click)="toggleButtonToChangeThemeClass()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="39"
              viewBox="0 0 39 39"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.6596 10.75C14.6596 5.91751 18.4937 2 23.2234 2C27.9531 2 31.7872 5.91751 31.7872 10.75C31.7872 15.5825 27.9531 19.5 23.2234 19.5C18.4937 19.5 14.6596 15.5825 14.6596 10.75Z"
                fill="#FF8C58"
                stroke="url(#paint0_linear_1_1074)"
                stroke-width="2.7"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 15.9204C2 10.8683 5.83416 6.77271 10.5638 6.77271C15.2935 6.77271 19.1277 10.8683 19.1277 15.9204C19.1277 20.9726 15.2935 25.0682 10.5638 25.0682C5.83416 25.0682 2 20.9726 2 15.9204Z"
                fill="#1BBAA2"
                stroke="url(#paint1_linear_1_1074)"
                stroke-width="2.7"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.9362 23.875C10.9362 16.6263 16.7708 10.75 23.9681 10.75C31.1655 10.75 37 16.6263 37 23.875C37 31.1237 31.1655 37 23.9681 37C16.7708 37 10.9362 31.1237 10.9362 23.875Z"
                fill="#F05C6D"
                stroke="url(#paint2_linear_1_1074)"
                stroke-width="2.7"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_1074"
                  x1="31.7872"
                  y1="2"
                  x2="14.6596"
                  y2="2"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1D1D49" />
                  <stop offset="1" stop-color="#505084" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_1_1074"
                  x1="19.1277"
                  y1="6.77271"
                  x2="2"
                  y2="6.77271"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1D1D49" />
                  <stop offset="1" stop-color="#505084" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_1_1074"
                  x1="37"
                  y1="10.75"
                  x2="10.9362"
                  y2="10.75"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1D1D49" />
                  <stop offset="1" stop-color="#505084" />
                </linearGradient>
              </defs>
            </svg>
          </a>
        </div>
      </div>
      <!-- End After Sign-In Menu -->
    </div>

    <div class="mobile-nav">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <a (click)="openSidebar()" class="open-nav"
            ><i class="fas fa-bars"></i
          ></a>
          <a href="https://kafuapp.com/">
            <img
              [src]="
                darkThemeService.getCurrentTheme == 'light-mode'
                  ? 'assets/imgs/logo.svg'
                  : 'assets/imgs/logo-dark.png'
              "
              alt=""
              class="mobile-logo"
            />
          </a>
        </div>
        <div class="d-flex align-items-center">
          <div class="m-af-sign" *ngIf="authService.isLoggedIn()">
            <div class="profile-actions">
              <ul class="main-ul">
                <!-- <li class="has-child">
                  <a (click)="avoid()"><i class="fas fa-search"></i></a>
                  <div class="search-inp s-menu">
                    <input type="search" placeholder="Search..." />
                    <button class="search-btn">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </li> -->
                <li class="has-child">
                  <a><i class="fas fa-globe"></i></a>
                  <div class="settings langs s-menu" style="width: 150px">
                    <a
                      (click)="changeLang(changeToLang)"
                      *ngIf="changeToLang === elanguage.Short_Arabic"
                      >عربي</a
                    >
                    <a
                      (click)="changeLang(changeToLang)"
                      *ngIf="changeToLang === elanguage.Short_English"
                      >English</a
                    >
                  </div>
                </li>
                <li class="has-child" *ngIf="authService.isLoggedIn()">
                  <a (click)="avoid()">
                    <i class="fas fa-bell"></i>
                    <b></b>
                  </a>
                  <div class="s-menu notifications">
                    <!-- Add class new for unread notificaion -->
                    <a class="d-flex new">
                      <div class="logo">
                        <img src="assets/imgs/logo.svg" alt="" />
                      </div>
                      <p>
                        <b>Kafu App</b> Please Complete your Profile to get free
                        500 Point
                        <span>57 minutes ago</span>
                      </p>
                    </a>
                    <a class="d-flex new">
                      <div class="logo">
                        <img src="assets/imgs/logo.svg" alt="" />
                      </div>
                      <p>
                        <b>Kafu App</b> Please Complete your Profile to get free
                        500 Point
                        <span>57 minutes ago</span>
                      </p>
                    </a>
                    <a class="d-flex">
                      <div class="logo">
                        <img src="assets/imgs/logo.svg" alt="" />
                      </div>
                      <p>
                        <b>Kafu App</b> Please Complete your Profile to get free
                        500 Point
                        <span>57 minutes ago</span>
                      </p>
                    </a>
                    <a class="d-flex">
                      <div class="logo">
                        <img src="assets/imgs/logo.svg" alt="" />
                      </div>
                      <p>
                        <b>Kafu App</b> Please Complete your Profile to get free
                        500 Point
                        <span>57 minutes ago</span>
                      </p>
                    </a>
                    <a class="view-all">View All</a>
                  </div>
                </li>
                <li class="has-child" *ngIf="authService.isLoggedIn()">
                  <div class="d-flex align-items-center border">
                    <div class="user-img">
                      <img [src]="avatar" alt="" />
                    </div>
                    <div class="user-info">
                      <h5>{{ authService.currentUser?.firstName }}</h5>
                    </div>
                    <div class="px-2">
                      <i class="fas fa-angle-down"></i>
                    </div>
                  </div>
                  <div class="settings s-menu">
                    <a [routerLink]="['/profile']">{{
                      "header.My Account" | translate
                    }}</a>
                    <div class="line"></div>
                    <a [routerLink]="['/profile']">{{
                      "profile.wallet" | translate
                    }}</a>
                    <div class="line"></div>
                    <a [routerLink]="['/surveys']">{{
                      "header.surveyList" | translate
                    }}</a>
                    <div class="line"></div>
                    <a [routerLink]="['/terms-conditions']">{{
                      "header.Terms" | translate
                    }}</a>
                    <div class="line"></div>
                    <a (click)="logout()">{{ "header.Logout" | translate }}</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="lang" *ngIf="!authService.isLoggedIn()">
            <a (click)="changeLang(changeToLang)">{{ changeToLang }}</a>
          </div>
          <div class="color-change">
            <a (click)="toggleButtonToChangeThemeClass()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.6596 10.75C14.6596 5.91751 18.4937 2 23.2234 2C27.9531 2 31.7872 5.91751 31.7872 10.75C31.7872 15.5825 27.9531 19.5 23.2234 19.5C18.4937 19.5 14.6596 15.5825 14.6596 10.75Z"
                  fill="#FF8C58"
                  stroke="url(#paint0_linear_1_1074)"
                  stroke-width="2.7"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 15.9204C2 10.8683 5.83416 6.77271 10.5638 6.77271C15.2935 6.77271 19.1277 10.8683 19.1277 15.9204C19.1277 20.9726 15.2935 25.0682 10.5638 25.0682C5.83416 25.0682 2 20.9726 2 15.9204Z"
                  fill="#1BBAA2"
                  stroke="url(#paint1_linear_1_1074)"
                  stroke-width="2.7"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.9362 23.875C10.9362 16.6263 16.7708 10.75 23.9681 10.75C31.1655 10.75 37 16.6263 37 23.875C37 31.1237 31.1655 37 23.9681 37C16.7708 37 10.9362 31.1237 10.9362 23.875Z"
                  fill="#F05C6D"
                  stroke="url(#paint2_linear_1_1074)"
                  stroke-width="2.7"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_1074"
                    x1="31.7872"
                    y1="2"
                    x2="14.6596"
                    y2="2"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#1D1D49" />
                    <stop offset="1" stop-color="#505084" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_1_1074"
                    x1="19.1277"
                    y1="6.77271"
                    x2="2"
                    y2="6.77271"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#1D1D49" />
                    <stop offset="1" stop-color="#505084" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_1_1074"
                    x1="37"
                    y1="10.75"
                    x2="10.9362"
                    y2="10.75"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#1D1D49" />
                    <stop offset="1" stop-color="#505084" />
                  </linearGradient>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="off-canvas" [ngClass]="isOffCanvasOpen ? 'opened' : 'closed'">
        <a class="close-nav" (click)="closeSidebar()"
          ><i class="far fa-times-circle"></i
        ></a>
        <div class="af-sign" *ngIf="authService.isLoggedIn()">
          <div class="profile-info">
            <div class="user-img">
              <img [src]="avatar" alt="" />
              <div class="pf-progress">
                <svg class="progress-ring" width="120" height="120">
                  <circle
                    class="progress-ring__circle"
                    stroke="#FF9054"
                    stroke-width="4"
                    fill="transparent"
                    r="52"
                    cx="60"
                    cy="60"
                    style="
                      stroke-dasharray: 326.726, 326.726;
                      stroke-dashoffset: 49.0088;
                    "
                  ></circle>
                </svg>
              </div>
              <!-- Put progress percentage in value attribute -->
              <input value="85" type="hidden" />
            </div>
            <div class="user-info">
              <h5>{{ authService.currentUser?.firstName }}</h5>
              <h6>
                <span><img src="assets/imgs/wallet-icon.svg" alt="" /></span>
                {{ points | number : "0.2-2" }} pts
              </h6>
            </div>
          </div>
        </div>
        <div class="bf-sign" *ngIf="!authService.isLoggedIn()">
          <div class="in-out">
            <a (click)="signup()"
              ><i class="fas fa-lock"></i>{{ "header.join us" | translate }}</a
            >
            <a (click)="login()"
              ><i class="fas fa-user"></i>{{ "header.sign in" | translate }}</a
            >
          </div>
        </div>
        <nav>
          <ul>
            <li>
              <a [routerLink]="['/']" [class.active]="isRouteActive('/')">{{
                "header.Home" | translate
              }}</a>
            </li>
            <!-- <li>
              <a href="#about">about</a>
            </li> -->
            <li>
              <a
                [routerLink]="['/surveys']"
                [class.active]="isRouteActive('/surveys')"
              >
                {{ "header.Survey" | translate }}</a
              >
            </li>
            <li *ngIf="!authService.isLoggedIn()">
              <a href="#services" [class.active]="isRouteActive('/#services')">
                {{ "header.Services" | translate }}</a
              >
            </li>
            <li *ngIf="!authService.isLoggedIn()">
              <a href="#partners" [class.active]="isRouteActive('/#partners')">
                {{ "header.Partners" | translate }}</a
              >
            </li>
            <li>
              <a href="#contact" [class.active]="isRouteActive('/#contact')">
                {{ "header.contact" | translate }}</a
              >
            </li>

            <li *ngIf="!authService.isLoggedIn()">
              <a
                routerLink="/privacy-policy"
                [class.active]="isRouteActive('/privacy-policy')"
                >{{ "header.Privacy" | translate }}</a
              >
            </li>
            <li *ngIf="!authService.isLoggedIn()">
              <a
                routerLink="/terms-conditions"
                [class.active]="isRouteActive('/terms-conditions')"
                >{{ "header.Terms" | translate }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>
