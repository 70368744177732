import { SurveyConstants } from "./../../general/constants/survey.constants";
import { HomeService } from "./../../../services/home.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { LanguageService } from "../../../services/language.service";
import { SurveyService } from "src/services/survey.service";
import { Subscription } from "rxjs";
import { AuthService } from "src/services/auth.service";
import { first } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "angular-2-local-storage";
import { SurveyApprovalComponent } from "../survey-approval/survey-approval.component";
import { Country, City, ICountry, ICity, } from "country-state-city";
import { eLanguage } from "src/app/general/enums/language.enum";
import { countriesAr } from "src/assets/data/countries";
import { citiesAr } from "src/assets/data/cities";
import { LoginComponent } from "src/app/partials/login/login.component";

@Component({
  selector: "app-survey-options",
  templateUrl: "./survey-options.component.html",
  styleUrls: ["./survey-options.component.css"],
})
export class SurveyOptionsComponent implements OnInit, OnDestroy {
  showLoader = false;
  countriesList = [];
  citiesList = [];
  cities: ICity[] = [];
  countries: ICountry[] = [];
  surveyOptions = [];
  survey: any = null;
  surveyImage: any;
  Cost = {
    spendingNow: 1500,
    costPerSurvey: 0,
    response: 500,
    perQuestion: 0,
  };
  selectedItemsList = [];
  checkedIDs = [];
  genders: any;
  userStats: any
  visibility: any;
  form: FormGroup;
  totalBalance: any;
  totalSar: any;
  maxValue: number;
  spendingPoints: number;
  showSaveButton = true;
  totalPoints: number;
  language: any;
  userPoints: number;
  maxSending: number;
  currentUser: any;
  savedOptions: any;
  surveyImageToBlob: any;
  questionImageToBlob: any;
  answerImageToBlob: any;
  onClose: any;
  subscriptions = new Subscription();
  points: any;
  isPublished: boolean = false;
  ages: any;
  questionRates: any;
  lang: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public authService: AuthService,
    private homeService: HomeService,
    private surveyService: SurveyService,
    private modalService: BsModalService,
    public languageService: LanguageService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private _ac: ActivatedRoute
  ) {
    (this.points = this.authService.getWalletRates?.totalCredits),
      (this.userPoints = this.authService.getWalletRates?.currentCredits),
      (this.totalBalance = this.authService.getWalletRates?.totalBalance);
    this.totalSar = this.authService.getWalletRates?.totalBalance;
    this.survey = this.surveyService.getQuestionSurvey();
    this.savedOptions = this.surveyService.getOptionsSurvey();
    this.currentUser = this.authService.currentUser;
    this.maxValue = this.survey.totalPoints;
    console.log("currentUser", this.currentUser)
    // this.Cost.costPerSurvey = this.survey.totalPoints;
    if (!this.survey) {
      this.router.navigate(["/"]);
    }
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    console.log("language", this.language);
  }

  async ngOnInit() {
    this.getCountriesAr(this.language);

    if (this.language === "Arabic") {
      this.visibility = [
        {
          id: 1,
          title: "private",
        },
        {
          id: 2,
          title: "public",
        },
      ];
      this.ages = [
        "9 - 12 سنين",
        "13 - 17 سنين",
        "18 - 29 سنين",
        "30 - 45 سنين",
        "45 - 60 سنين",
        "60 زائد سنوات",
      ];
      this.genders = [
        {
          id: 1,
          title: "أنثى",
          isChecked: false,
        },
        {
          id: 2,
          title: "ذكر",
          isChecked: false,
        },
      ];
    } else {
      this.visibility = [
        {
          id: 1,
          title: "private",
        },
        {
          id: 2,
          title: "public",
        },
      ];
      this.ages = [
        "9 - 12 Years",
        "13 - 17 Years",
        "18 - 29 Years",
        "30 - 45 Years",
        "45 - 60 Years",
        "60 plus Years",
      ];
      this.genders = [
        {
          id: 1,
          title: "female",
          isChecked: false,
        },
        {
          id: 2,
          title: "male",
          isChecked: false,
        },
      ];
    }
    if (this.survey.imageUrl) {
      this.surveyImage = this.survey.imageUrl;
    }
    if (this.surveyService.isSavedSurvey()) {
      this.showSaveButton = false;
    }
    this.Cost.costPerSurvey = this.survey.totalBalance
      ? this.survey.totalBalance
      : 0;
    this.totalPoints = this.maxValue;
    this.getCountries();
    this.createForm();
    await this.getQuestionRates();
    this.calculateTotalPoints();
    this.getUpdatedWallet();
    this.getUserAllStats()
    console.log(this.totalSar, "wdfdsf");
  }

  createForm() {
    this.form = new FormGroup({
      surveyImageUrl: new FormControl(this.surveyImage),
      cityId: new FormControl(
        this.savedOptions ? this.savedOptions.cityId : null,
        [Validators.required]
      ),
      countryId: new FormControl(
        this.savedOptions ? this.savedOptions.countryId : null,
        [Validators.required]
      ),
      maxSending: new FormControl(
        this.savedOptions ? this.savedOptions.maxSending : 0,

        [Validators.required]
      ),
      lastMaxSending: new FormControl(
        this.savedOptions ? this.savedOptions.maxSending : 0,
        [Validators.required]
      ),
      totalBalance: new FormControl(
        this.survey.totalBalance ? this.survey.totalBalance : 0,
        [Validators.required]
      ),
      sliderValue: new FormControl(0, [Validators.required]),
      usersValue: new FormControl(
        this.savedOptions ? this.savedOptions.usersValue : 50,
        [Validators.required]
      ),
      startDate: new FormControl(
        this.savedOptions ? this.savedOptions.startDate : "",
        [Validators.required]
      ),
      endDate: new FormControl(
        this.savedOptions ? this.savedOptions.endDate : "",
        [Validators.required]
      ),
      startTime: new FormControl(
        this.savedOptions ? this.savedOptions?.startTime : "12:12",
        [Validators.required]
      ),
      endTime: new FormControl(
        this.savedOptions ? this.savedOptions?.endTime : "12:12",
        [Validators.required]
      ),
      visibility: new FormControl(
        this.savedOptions?.visibility ? this.savedOptions.visibility : ""
      ),
      gender: new FormControl(
        this.savedOptions?.gender ? this.savedOptions.gender : ""
      ),
      age: new FormControl(
        this.savedOptions?.age ? this.savedOptions.age : null
      ),
      promoteSurvey: new FormControl(
        this.savedOptions ? this.savedOptions.promoteSurvey : false
      ),
      kafuNetwork: new FormControl(
        this.savedOptions ? this.savedOptions.kafuNetwork : true
      ),
      agreePolicy: new FormControl(
        this.savedOptions ? this.savedOptions.agreePolicy : false,
        [Validators.required]
      ),
      agreeCondition: new FormControl(
        this.savedOptions ? this.savedOptions.agreeCondition : false,
        [Validators.required]
      ),
    });
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getUserAllStats() {
    if (this.currentUser) {

      this.showLoader = true;
      this.homeService.getUserStats().subscribe((result) => {
        this.userStats = result;
        this.showLoader = false;
      });
    }
  }

  setRam(eValue) {
    if (this.totalBalance <= 0) {
      debugger;
      this.translateService.get("home.rechargeAccount").subscribe((text) => {
        this.toastr.error(text);
      });
      // this.router.navigateByUrl("/profile#Wallet");
    }
    this.form.get("maxSending").setValue(eValue);
    this.form.get("sliderValue").setValue(eValue);
    this.spendingPoints = eValue;
    this.calculateSpendPoints();
  }

  calculateSpendPoints() {
    let maxSending = this.form.value.maxSending;
    let lastMaxSending = this.form.value.lastMaxSending;
    let usersValue = this.form.value.usersValue;
    if (!maxSending || usersValue < 50) {
      this.form.get("lastMaxSending").setValue(0);
      this.form.get("usersValue").setValue(50);
      return;
    }

    maxSending = Number(maxSending ?? 0);
    lastMaxSending = Number(lastMaxSending);
    // maxSending * cost per survey + 50
    // let result = Math.floor(maxSending / 0.04);
    let result = Math.floor(maxSending / this.Cost.costPerSurvey);
    console.log(result, "resulttt");
    if (maxSending > lastMaxSending) {
      this.form.get("usersValue").setValue(50 + result);
    } else {
      result = 50 - result < 50 ? 50 + result : 50 - result;
      this.form.get("usersValue").setValue(result);
    }
    this.form.get("lastMaxSending").setValue(maxSending);
    this.calculateSurveyCost();
  }
  changeSelection() {
    this.fetchSelectedItems();
    this.fetchCheckedIDs();
  }
  fetchSelectedItems() {
    this.selectedItemsList = this.genders.filter((value, index) => {
      return value.isChecked;
    });
  }

  fetchCheckedIDs() {
    this.checkedIDs = [];
    this.genders.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.title);
      }
    });
  }
  getCities() {
    this.form.get("cityId").reset();
    const countryName = this.form.value.countryId;
    const country = this.countries.find((x) => x.name === countryName);
    if (country) {
      this.cities = City.getCitiesOfCountry(country.isoCode);
    }
  }
  getCountries() {
    this.countries = Country.getAllCountries();
  }
  getCountriesAr(language: any) {
    console.log(countriesAr, countriesAr.length, "whas");
    this.countriesList = [];
    for (let i = 0; i < countriesAr.length; i++) {
      this.countriesList.push({
        index: countriesAr[i].value,
        label:
          language === "English"
            ? countriesAr[i].label_fr
            : language === "Arabic"
              ? countriesAr[i].label_ar
              : countriesAr[i].label,
      });
    }
  }

  async getCitiesArabic() {
    this.form.get("cityId").reset();
    const countryName = this.form.value.countryId;
    const country_index = countryName.$ngOptionLabel;
    const country = countriesAr.find(
      (country: any) => country.label_ar === country_index.trim()
    );

    if (!country) {
      // Country not found, return an empty array or handle the error as needed.
      return [];
    }

    const selectedCountryLabel = country.label;
    const selectedLanguageKey =
      this.language === "English"
        ? "label_fr"
        : this.language === "Arabic"
          ? "label_ar"
          : "label";

    for (let i = 0; i < citiesAr.length; i++) {
      if (citiesAr[i].country === selectedCountryLabel) {
        // Map cities directly and return the result.
        this.citiesList = citiesAr[i].cities.map((city) => ({
          value: city.value,
          label: city[selectedLanguageKey],
        }));
        return this.citiesList;
      }
    }

    // If the country is found but no cities match, return an empty array or handle the error as needed.
    return [];
  }

  getUpdatedWallet() {
    if (this.currentUser) {
      this.authService.updatedWallet().subscribe((result) => {
        this.authService.setWalletRates(result);
      });
    }
  }

  validateForm() {
    if (!this.form.controls.agreePolicy.value) {
      this.translateService
        .get("survey.select agree policy")
        .subscribe((text) => {
          this.toastr.warning(text);
        });
    } else if (!this.form.controls.agreeCondition.value) {
      this.translateService.get("survey.select terms").subscribe((text) => {
        this.toastr.warning(text);
      });
    } else if (this.form.controls.cityId.invalid) {
      this.translateService.get("survey.select city").subscribe((text) => {
        this.toastr.warning(text);
      });
    } else if (this.form.controls.startDate.invalid) {
      this.translateService
        .get("survey.select start date")
        .subscribe((text) => {
          this.toastr.warning(text);
        });
    } else if (this.form.controls.endDate.invalid) {
      this.translateService.get("survey.select end date").subscribe((text) => {
        this.toastr.warning(text);
      });
    }
    //  else if (this.form.controls.maxSending.invalid) {
    //   this.translateService
    //     .get("survey.select max sending")
    //     .subscribe((text) => {
    //       this.toastr.warning(text);
    //     });
    // }
    else if (this.form.controls.age.invalid) {
      this.translateService.get("survey.selectAge").subscribe((text) => {
        this.toastr.warning(text);
      });
    }
  }

  recharge() {
    this.router.navigateByUrl("/profile#Wallet");
  }

  async publish() {
    this.currentUser = this.authService.currentUser;
    console.log("🚀 ~ file: survey-options.component.ts:447 ~ SurveyOptionsComponent ~ publish ~ this.currentUser:", this.currentUser)

    if (this.currentUser == null || !this.currentUser._id) {
      const loginModel = this.modalService.show(LoginComponent, {
        class: "modal-dialog-centered login",
        backdrop: "static",
      });
      loginModel.content.onClose = (auth) => {
        loginModel.hide();
      };

    } else {


      this.validateForm();
      this.totalPoints = this.Cost.costPerSurvey + this.form.value.maxSending;
      if (this.totalSar < this.form.value.sliderValue) {
        this.isPublished = false;
        debugger;
        this.translateService.get("home.rechargeAccount").subscribe((text) => {
          this.toastr.error(text);
        });
      } else {

        // if (this.userStats.surveys != 0 && this.currentUser?.planId?.title.includes("Basic")) {
        //   this.translateService.get("home.rechargeAccount").subscribe((text) => {
        //     this.toastr.error(text);
        //   });
        // } else
        if (
          this.form.controls.agreePolicy.value &&
          this.form.controls.agreeCondition.value
        ) {
          if (this.form.valid) {
            // let surveyImage;
            // let questionImageArray = [];

            // this.survey.questions.map((question, index) => {
            //   if (question.imageUrl) {
            //     question.imageUrl = this.homeService.resetImageURL(question.imageUrl);
            //     const imageName = `name${index}.png`;
            //     const imageBlob = this.homeService.dataURItoBlob(question.imageUrl);
            //     question.image = new File([imageBlob], imageName, { type: "image/png" });
            //     // questionImageArray.push({
            //     //   image: question.imageUrl,
            //     //   index: index,
            //     //   questionType: question.questionType,
            //     // });
            //   }
            // });

            // this.survey.questions.map((question) =>
            //   question.answers.map((answer) => {
            //     if (answer.answerImage) {
            //       answer.answerImage = fetch(question.answerImage)
            //         .then((res) => res.blob())
            //         .then();
            //     }
            //   })
            // );
            this.showLoader = true;

            this.totalPoints =
              this.Cost.costPerSurvey + parseInt(this.form.value.maxSending);
            debugger;
            let publishData = {
              ...this.survey,
              ...this.form.value,
              totalResponse: this.form.value.usersValue,
              spendingPoints: this.form.value.maxSending,
              gender: this.checkedIDs,
              userId: this.currentUser?.userId,
              isPublished: true,
              totalPoints: this.totalPoints,
            };
            publishData = this.homeService.deepCopy(publishData);
            this.isPublished = true;
            this.surveyService.publishSurvey(publishData).subscribe(
              (result) => {
                this.showLoader = false;
                this.localStorageService.remove("surveyQuestions");
                this.localStorageService.remove("KF-survey");
                if (this.localStorageService.get("surveyOption")) {
                  this.localStorageService.remove("surveyOption");
                }
                if (this.surveyService.isSavedSurvey()) {
                  this.localStorageService.remove("kafu-savedSurvey");
                }
                this.translateService
                  .get("survey.success publish")
                  .subscribe((text) => {
                    this.toastr.success(text);
                  });
                debugger;
                this.surveyService.createLocalSurveyApproval({
                  title: this.survey.title,
                  totalResponses: parseInt(this.form.value.usersValue),
                  spendingNow: parseInt(this.form?.value?.sliderValue),
                  costPerSurvey: this.Cost.costPerSurvey,
                  costPerQuestion: this.Cost.perQuestion,
                  costPerResponse:
                    parseInt(this.form?.value?.sliderValue) /
                    parseInt(this.form.value.usersValue),
                });
                const currentUser: any =
                  this.localStorageService.get("currentUser");
                if (currentUser) {
                  currentUser.points = currentUser.points - this.totalPoints;
                }
                this.localStorageService.set("currentUser", currentUser);
                const ApprovalModel = this.modalService.show(
                  SurveyApprovalComponent,
                  {
                    class:
                      "c-survey-modal modal-dialog modal-dialog-centered sign-up",
                    backdrop: "static",
                    initialState: {
                      surveyRes: result?.body,
                    },
                  }
                );
                ApprovalModel.content.onClose = () => {
                  ApprovalModel.hide();
                };

                this.router.navigate(["/"]);
              },
              (err) => {
                this.showLoader = false;
                this.isPublished = false;
                this.translateService
                  .get("survey.error saving")
                  .subscribe((text) => {
                    this.toastr.error(text);
                  });
              }
            );
            // if (this.survey.imageUrl) {
            //   await this.surveyService.saveImage(this.survey.imageUrl);
            // }
            // if (questionImageArray) {
            //   await this.surveyService.saveQuestionImage(questionImageArray);
            // }
            this.showLoader = false;
            // this.router.navigate(["/survey/preview"]);
          } else {
            for (let control in this.form.controls) {
              this.form.get(control).markAsDirty();
            }
          }
        } else {
          this.translateService.get("survey.select terms").subscribe((text) => {
            this.toastr.warning(text);
          });
        }
      }

    }
  }

  update() {
    this.validateForm();
    this.totalPoints = this.Cost.costPerSurvey + this.form.value.maxSending;
    if (this.totalSar < this.form.value.maxSending) {
      this.translateService
        .get("survey points is greater then totalPoints")
        .subscribe((text) => {
          this.toastr.error(text);
        });
    } else {
      if (
        this.form.controls.agreePolicy.value &&
        this.form.controls.agreeCondition.value
      ) {
        if (this.form.valid) {
          // let surveyImage;
          // let questionImageArray = [];

          // this.survey.questions.map((question, index) => {
          //   if (question.imageUrl) {
          //     question.imageUrl = this.homeService.resetImageURL(question.imageUrl);
          //     const imageName = `name${index}.png`;
          //     const imageBlob = this.homeService.dataURItoBlob(question.imageUrl);
          //     question.image = new File([imageBlob], imageName, { type: "image/png" });
          //     // questionImageArray.push({
          //     //   image: question.imageUrl,
          //     //   index: index,
          //     //   questionType: question.questionType,
          //     // });
          //   }
          // });

          // this.survey.questions.map((question) =>
          //   question.answers.map((answer) => {
          //     if (answer.answerImage) {
          //       answer.answerImage = fetch(question.answerImage)
          //         .then((res) => res.blob())
          //         .then();
          //     }
          //   })
          // );
          this.showLoader = true;

          this.totalPoints =
            this.Cost.costPerSurvey + this.form.value.maxSending;
          let publishData = {
            ...this.survey,
            ...this.form.value,
            totalResponse: this.form.value.usersValue,
            spendingPoints: this.form.value.maxSending,
            gender: this.checkedIDs,
            userId: this.currentUser._id || this.currentUser.userId,
            isPublished: true,
            totalPoints: this.totalPoints,
          };
          publishData = this.homeService.deepCopy(publishData);

          this.surveyService.surveyUpdate(publishData).subscribe(
            (result) => {
              this.showLoader = false;
              this.localStorageService.remove("surveyQuestions");
              this.localStorageService.remove("KF-survey");
              if (this.localStorageService.get("surveyOption")) {
                this.localStorageService.remove("surveyOption");
              }
              if (this.surveyService.isSavedSurvey()) {
                this.localStorageService.remove("kafu-savedSurvey");
              }
              this.translateService
                .get("survey.success publish")
                .subscribe((text) => {
                  this.toastr.success(text);
                });

              this.surveyService.createLocalSurveyApproval({
                title: this.survey.title,
                totalResponses: parseInt(this.form.value.usersValue),
                spendingNow: parseInt(this.form?.value?.sliderValue),
                costPerSurvey: this.Cost.costPerSurvey,
                costPerQuestion: this.Cost.perQuestion,
                costPerResponse:
                  parseInt(this.form?.value?.sliderValue) /
                  parseInt(this.form.value.usersValue),
              });
              const currentUser: any =
                this.localStorageService.get("currentUser");
              if (currentUser) {
                currentUser.points = currentUser.points - this.totalPoints;
              }
              this.localStorageService.set("currentUser", currentUser);
              const ApprovalModel = this.modalService.show(
                SurveyApprovalComponent,
                {
                  class:
                    "c-survey-modal modal-dialog modal-dialog-centered sign-up",
                  backdrop: "static",
                  initialState: {
                    surveyRes: result?.body,
                  },
                }
              );
              ApprovalModel.content.onClose = () => {
                ApprovalModel.hide();
              };
              this.router.navigate(["/"]);
            },
            (err) => {
              this.showLoader = false;
              this.translateService
                .get("survey.error saving")
                .subscribe((text) => {
                  this.toastr.error(text);
                });
            }
          );
          this.showLoader = false;
        } else {
          for (let control in this.form.controls) {
            this.form.get(control).markAsDirty();
          }
        }
      } else {
        this.translateService.get("survey.select terms").subscribe((text) => {
          this.toastr.warning(text);
        });
      }
    }
  }
  preview() {
    this.validateForm();
    if (this.form.valid) {
      this.showLoader = true;
      this.surveyService.createOptionsSurvey({
        ...this.form.value,
      });
      this.showLoader = false;
      this.router.navigate(["/survey/preview"]);
    } else {
      for (let control in this.form.controls) {
        this.form.get(control).markAsDirty();
      }
    }
  }

  async save() {
    debugger;
    this.validateForm();
    if (this.form.valid) {
      // let surveyImage;
      let questionImageArray = [];

      this.survey.backGroundImageUrl = this.homeService.resetImageURL(
        this.survey.backGroundImageUrl
      );
      this.survey.questions.map((question, index) => {
        // if (question.imageUrl) {
        //   question.imageUrl = this.homeService.resetImageURL(question.imageUrl);
        //   const imageName = `name${index}.png`;
        //   const imageBlob = this.homeService.dataURItoBlob(question.imageUrl);
        //   question.image = new File([imageBlob], imageName, {
        //     type: "image/png",
        //   });
        //   // questionImageArray.push({
        //   //   image: question.imageUrl,
        //   //   index: index,
        //   //   questionType: question.questionType,
        //   // });
        // }
      });
      // this.survey.questions.map((question) =>
      //   question.answers.map((answer) => {
      //     if (answer.answerImage) {
      //       answer.answerImage = fetch(question.answerImage)
      //         .then((res) => res.blob())
      //         .then();
      //     }
      //   })
      // );
      this.showLoader = true;
      this.localStorageService.set("kafu-savedSurvey", true);
      debugger;
      await this.surveyService
        .saveSurvey({
          ...this.form.value,
          ...this.survey,
          userId: this.currentUser?.userId,
          isPublished: false,
          totalPoints: this.totalPoints,
        })
        .pipe(first())
        .subscribe(
          (result) => {
            this.showLoader = false;
            this.showSaveButton = false;
            this.translateService
              .get("survey.success saving")
              .subscribe((text) => {
                this.toastr.success(text);
              });
            this.router.navigate(["/"]);
          },
          (err) => {
            this.showLoader = false;
            this.translateService
              .get("survey.error saving")
              .subscribe((text) => {
                this.toastr.error(text);
              });
          }
        );

      if (this.survey.imageUrl) {
        await this.surveyService.saveImage(this.survey.imageUrl);
      }
      if (questionImageArray) {
        await this.surveyService.saveQuestionImage(questionImageArray);
      }
      this.showLoader = false;
      // this.router.navigate(["/survey/preview"]);
    } else {
      for (let control in this.form.controls) {
        this.form.get(control).markAsDirty();
      }
    }
  }

  calculateTotalPoints() {
    let total = 0;
    if (
      this.form.controls.gender.value &&
      this.form.controls.visibility.value
    ) {
      total = this.form.controls.maxSending.value + 10;
    } else if (
      this.form.controls.gender.value ||
      this.form.controls.visibility.value
    ) {
      total = this.form.controls.maxSending.value + 5;
    } else {
      total = this.form.controls.maxSending.value;
    }

    this.totalPoints = total;

    return total;
  }

  decreaseValue() {
    let totalValue = this.form.controls.maxSending.value;
    if (totalValue <= this.survey.totalPoints) {
      return this.form.controls.maxSending.setValue(this.survey.totalPoints);
    } else {
      this.form.controls.maxSending.setValue(
        +this.form.controls.maxSending.value - +this.survey.totalPoints
      );
      this.form.controls.usersValue.setValue(
        this.form.controls.usersValue.value - 1
      );
      this.calculateTotalPoints();
    }
    this.calculateSurveyCost();
  }

  increaseValue() {
    let currentUserPoints = this.currentUser.points;
    this.maxValue =
      +this.survey.totalPoints + +this.form.controls.maxSending.value;

    if (
      this.maxValue >= this.survey.totalPoints &&
      this.maxValue <= currentUserPoints
    ) {
      this.form.controls.maxSending.setValue(this.maxValue);
      this.form.controls.usersValue.setValue(
        this.form.controls.usersValue.value + 1
      );
      this.calculateTotalPoints();
    }
    this.calculateSurveyCost();
  }

  async getQuestionRates() {
    const rates = await this.homeService.getQuestionRate().toPromise();
    this.questionRates = rates?.length ? rates[0] : {};
    this.setQuestionRate();
    this.calculateSurveyCost();
  }

  setQuestionRate() {
    const quesCount = this.survey?.questions?.length;
    console.log(quesCount, "quesCount");
    if (!quesCount || !this.questionRates) {
      this.Cost.perQuestion = 0;
    } else if (quesCount <= SurveyConstants.QuestionRateRanges.firstGroup) {
      this.Cost.perQuestion = Number(
        parseFloat(
          (this.questionRates?.firstGroup / quesCount).toString()
        ).toFixed(2)
      );
    } else if (quesCount <= SurveyConstants.QuestionRateRanges.secondGroup) {
      this.Cost.perQuestion = Number(
        parseFloat(
          (this.questionRates?.secondGroup / quesCount).toString()
        ).toFixed(2)
      );
    } else {
      this.Cost.perQuestion = Number(
        parseFloat(
          (this.questionRates?.thirdGroup / quesCount).toString()
        ).toFixed(2)
      );
    }
  }

  calculateSurveyCost() {
    console.log(this.form.value.sliderValue, "asdf");
    this.Cost.costPerSurvey = Number(this.survey.totalBalance);
  }
}
