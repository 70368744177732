import {Injectable} from '@angular/core';
import {WebApiService} from './webApi.service';

@Injectable()
export class SettingService {

  constructor(private webApi: WebApiService) {
  }

  getCities() {
    return this.webApi.get(`/cities`);
  }

}
