import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/services/auth.service';
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { LanguageService } from 'src/services/language.service';
import { SurveyService } from 'src/services/survey.service';
import { CreateSurveyComponent } from '../create-survey/create-survey.component';
@Component({
  selector: 'app-survey-brand-list',
  templateUrl: './survey-brand-list.component.html',
  styleUrls: ['./survey-brand-list.component.css']
})
export class SurveyBrandListComponent implements OnInit {
  brandList = []
  loading: any
  page: any
  isSidebarVisible = true
  constructor(
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    public darkThemeService: DarkLiteThemeService,
    private surveyService: SurveyService,
    public authService: AuthService,
    public languageService: LanguageService,
    private modalService: BsModalService,

    private breakpointObserver: BreakpointObserver,


  ) { }

  ngOnInit(): void {
    this.userBrandList()
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
      if (result.matches) {
        this.isSidebarVisible = false;
      }
    });
  }
  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
  userBrandList() {
    this.loading = true
    this.surveyService.getUserBrand().subscribe((result) => {
      this.brandList = result
      this.loading = false
    })
  }
  createBrand() {
    this.surveyService.removeBrand()
    this.router.navigate(["/create-custom-survey"]);
  }
  editBrand(survey) {
    this.surveyService.editBrand(survey)
    this.router.navigate(["/create-custom-survey"]);
  }
  next(data) {
    this.surveyService.editBrand(data)
    this.router.navigate(['']);
    const verifyModel = this.modalService.show(CreateSurveyComponent, {
      class:
        "modal-lg modal-dialog-centered sign-up c-survey-modal  create-survey-modal",
      backdrop: "static",
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };

  }
  deleteBrand(id) {
    this.loading = true
    this.surveyService.deleteUserBrand(id).subscribe((result) => {
      this.userBrandList()
    })
  }


}
