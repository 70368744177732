<div class="main-div w-100" [ngClass]="darkThemeService.getCurrentTheme">
  <form [formGroup]="form">
    <div class="content-main">
      <div
        class="row w-100 justify-content-center mx-auto"
        *ngIf="activeStep == 1"
      >
        <div class="col-12 col-md-7 col-sm-8 text-center box">
          <div class="row w-100 align-items-center text-sm-center">
            <div class="col-12">
              <div>
                <h5 class="input-label">
                  {{ "promoCode.Add_your_number" | translate }}
                </h5>
                <div class="input-container">
                  <input
                    [ngClass]="language === 'ar' ? 'input-tag-ar' : 'input-tag'"
                    formControlName="phoneNumber"
                    placeholder="{{ 'login.Your Mobile' | translate }}"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                  />

                  <span [ngClass]="language === 'ar' ? 'icon-ar' : 'icon'">
                    <!-- Add your icon here -->
                    <svg
                      width="18"
                      height="29"
                      viewBox="0 0 18 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.86365 0.544678C1.2882 0.544678 0 1.83168 0 3.40568V25.6579C0 27.2319 1.2882 28.5189 2.86365 28.5189H11.7728C11.8571 28.5201 11.9408 28.5045 12.0191 28.4731C12.0973 28.4417 12.1686 28.3951 12.2286 28.3359C12.2887 28.2768 12.3364 28.2063 12.3689 28.1285C12.4015 28.0508 12.4182 27.9674 12.4182 27.8831C12.4182 27.7989 12.4015 27.7155 12.3689 27.6377C12.3364 27.56 12.2887 27.4895 12.2286 27.4304C12.1686 27.3712 12.0973 27.3246 12.0191 27.2931C11.9408 27.2617 11.8571 27.2462 11.7728 27.2474H2.86365C1.97128 27.2474 1.27273 26.5495 1.27273 25.6579V23.4327H15.9092V25.6579C15.9092 26.5495 15.2106 27.2474 14.3183 27.2474C14.2339 27.2462 14.1502 27.2617 14.072 27.2931C13.9937 27.3246 13.9225 27.3712 13.8624 27.4304C13.8024 27.4895 13.7547 27.56 13.7221 27.6377C13.6896 27.7155 13.6728 27.7989 13.6728 27.8831C13.6728 27.9674 13.6896 28.0508 13.7221 28.1285C13.7547 28.2063 13.8024 28.2768 13.8624 28.3359C13.9225 28.3951 13.9937 28.4417 14.072 28.4731C14.1502 28.5045 14.2339 28.5201 14.3183 28.5189C15.8937 28.5189 17.1819 27.2319 17.1819 25.6579V3.40568C17.1819 1.83168 15.8937 0.544678 14.3183 0.544678H2.86365ZM2.86365 1.81623H14.3183C15.2106 1.81623 15.9092 2.51413 15.9092 3.40568V3.72357H1.27273V3.40568C1.27273 2.51413 1.97128 1.81623 2.86365 1.81623ZM1.27273 4.99512H15.9092V22.1611H1.27273V4.99512ZM8.59095 24.3864C8.06377 24.3864 7.6364 24.8133 7.6364 25.34C7.6364 25.8667 8.06377 26.2937 8.59095 26.2937C9.11813 26.2937 9.5455 25.8667 9.5455 25.34C9.5455 24.8133 9.11813 24.3864 8.59095 24.3864Z"
                        fill="url(#paint0_linear_964_163)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_964_163"
                          x1="8.59095"
                          y1="0.544678"
                          x2="8.59095"
                          y2="28.519"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#9F87D0" />
                          <stop offset="1" stop-color="#E98174" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                </div>
                <div
                  *ngIf="
                    form.controls.phoneNumber.invalid &&
                    (form.controls.phoneNumber.dirty ||
                      form.controls.phoneNumber.touched)
                  "
                  class="error-message"
                >
                  <div *ngIf="form.controls.phoneNumber.errors.required">
                    {{ "promoCode.phone" | translate }}
                  </div>

                  <div *ngIf="form.controls.phoneNumber.errors.minlength">
                    {{ "promoCode.phone-10" | translate }}
                  </div>
                  <div *ngIf="form.controls.phoneNumber.errors.maxlength">
                    {{ "promoCode.phone-14" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8 text-center marg-top-50">
          <button
            class="btn-finish purple-grad cursor-pointer"
            (click)="tabClick(2)"
            [disabled]="form.invalid"
          >
            {{ "promoCode.next" | translate }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="19"
              viewBox="0 0 25 19"
              fill="none"
              style="padding: 3px"
            >
              <path
                d="M4.00543e-05 12.0718C4.00543e-05 13.9093 0.71526 15.6715 1.98836 16.9708C3.26146 18.2701 4.98816 19 6.7886 19H10.5902C10.8903 19 11.178 18.8783 11.3902 18.6618C11.6024 18.4452 11.7216 18.1515 11.7216 17.8453C11.7216 17.539 11.6024 17.2453 11.3902 17.0288C11.178 16.8123 10.8903 16.6906 10.5902 16.6906H6.84517C5.64488 16.6906 4.49375 16.204 3.64501 15.3378C2.79628 14.4716 2.31946 13.2968 2.31946 12.0718C2.31946 10.8468 2.79628 9.67199 3.64501 8.80579C4.49375 7.9396 5.64488 7.45298 6.84517 7.45298H20.773L17.1638 10.5476C16.9342 10.7451 16.791 11.0276 16.7655 11.3329C16.74 11.6383 16.8345 11.9414 17.028 12.1757C17.2216 12.41 17.4984 12.5562 17.7975 12.5822C18.0967 12.6082 18.3938 12.5118 18.6233 12.3143L24.5972 7.19894C24.7234 7.09055 24.8248 6.95533 24.8944 6.80274C24.964 6.65015 25 6.48389 25 6.31559C25 6.14729 24.964 5.98103 24.8944 5.82844C24.8248 5.67586 24.7234 5.54064 24.5972 5.43225L18.6233 0.270726C18.3923 0.0731973 18.0938 -0.0225662 17.7935 0.00450243C17.4933 0.031571 17.2159 0.179254 17.0223 0.415064C16.8288 0.650873 16.735 0.955492 16.7615 1.26191C16.788 1.56833 16.9327 1.85144 17.1638 2.04897L20.773 5.12048H6.84517C5.94703 5.11283 5.05632 5.28716 4.22467 5.63336C3.39302 5.97956 2.63695 6.49074 2.00027 7.1373C1.36359 7.78386 0.858952 8.55293 0.515602 9.39996C0.172253 10.247 -0.002985 11.1551 4.00543e-05 12.0718Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        class="row w-100 justify-content-center mx-auto"
        *ngIf="activeStep == 2"
      >
        <div class="col-12 col-md-7 col-sm-8 text-center box">
          <div class="row w-100 align-items-center text-sm-center">
            <div class="col-12 col-sm-6">
              <div>
                <h5 class="input-label">
                  {{ "promoCode.Your_coupon_code" | translate }}
                </h5>
                <div class="input-container" (click)="copyValue(myInput)">
                  <input
                    [ngClass]="
                      language === 'ar'
                        ? 'input-tag-ar cursor-pointer'
                        : 'input-tag cursor-pointer'
                    "
                    placeholder="{{ 'profile.Code' | translate }}"
                    readonly
                    #myInput
                    formControlName="code"
                  />
                  <span
                    [ngClass]="
                      language === 'ar'
                        ? 'icon-ar cursor-pointer'
                        : 'icon cursor-pointer'
                    "
                    (click)="copyValue(myInput)"
                  >
                    <i class="fas fa-copy"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 mt-2 mt-sm-0">
              <img src="assets/imgs/new-survey/qrcode1.png" />
              <h6 class="check mt-2">
                {{ "promoCode.Check_me_out" | translate }}
              </h6>
              <h6 class="advantage mt-2 mt-sm-0">
                {{ "promoCode.advantage" | translate }}
              </h6>
            </div>
          </div>
        </div>
        <div class="col-8 text-center marg-top-50">
          <button
            class="btn-finish purple-grad cursor-pointer"
            (click)="save()"
          >
            {{ "promoCode.next" | translate }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="19"
              viewBox="0 0 25 19"
              fill="none"
              style="padding: 3px"
            >
              <path
                d="M4.00543e-05 12.0718C4.00543e-05 13.9093 0.71526 15.6715 1.98836 16.9708C3.26146 18.2701 4.98816 19 6.7886 19H10.5902C10.8903 19 11.178 18.8783 11.3902 18.6618C11.6024 18.4452 11.7216 18.1515 11.7216 17.8453C11.7216 17.539 11.6024 17.2453 11.3902 17.0288C11.178 16.8123 10.8903 16.6906 10.5902 16.6906H6.84517C5.64488 16.6906 4.49375 16.204 3.64501 15.3378C2.79628 14.4716 2.31946 13.2968 2.31946 12.0718C2.31946 10.8468 2.79628 9.67199 3.64501 8.80579C4.49375 7.9396 5.64488 7.45298 6.84517 7.45298H20.773L17.1638 10.5476C16.9342 10.7451 16.791 11.0276 16.7655 11.3329C16.74 11.6383 16.8345 11.9414 17.028 12.1757C17.2216 12.41 17.4984 12.5562 17.7975 12.5822C18.0967 12.6082 18.3938 12.5118 18.6233 12.3143L24.5972 7.19894C24.7234 7.09055 24.8248 6.95533 24.8944 6.80274C24.964 6.65015 25 6.48389 25 6.31559C25 6.14729 24.964 5.98103 24.8944 5.82844C24.8248 5.67586 24.7234 5.54064 24.5972 5.43225L18.6233 0.270726C18.3923 0.0731973 18.0938 -0.0225662 17.7935 0.00450243C17.4933 0.031571 17.2159 0.179254 17.0223 0.415064C16.8288 0.650873 16.735 0.955492 16.7615 1.26191C16.788 1.56833 16.9327 1.85144 17.1638 2.04897L20.773 5.12048H6.84517C5.94703 5.11283 5.05632 5.28716 4.22467 5.63336C3.39302 5.97956 2.63695 6.49074 2.00027 7.1373C1.36359 7.78386 0.858952 8.55293 0.515602 9.39996C0.172253 10.247 -0.002985 11.1551 4.00543e-05 12.0718Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
