<app-header></app-header>

<!-- wrapper create-survey-wrap profile-wrap single-survey-wrap -->
<div class="create-survey-container" [dir]="surveyForm?.value?.language == elanguage.Short_Arabic ? 'rtl' : 'ltr'">
  <div class="survey-info-header">
    <div iv class="row">
      <div class="col-lg-6">
        <div class="d-flex flex-md-row flex-column align-items-center">
          <img src="assets/imgs/create-survey-icon.png" alt="" />
          <h1 class="heading my-5">
            <span class="sub-heading1 text-capitalize">
              {{
              (surveyForm.value.surveyType === eSurveyType.ENTERPRISE
              ? "survey.enterprise"
              : "survey.create"
              ) | translate
              }}
            </span>
            <span class="sub-heading2">{{ "survey.survey" | translate }}</span>
          </h1>
        </div>
      </div>
      <div class="col-lg-6" *ngIf="surveyForm.value.surveyType === eSurveyType.NORMAL">
        <div class="survey-info-container">
          <h3>{{ "survey.survey info" | translate }}</h3>
          <div class="survey-info-fields d-flex" [formGroup]="surveyForm">
            <div class="avatar-upload">
              <div class="avatar-edit">
                <input type="file" id="surveyImageUpload" hidden accept=".png, .jpg, .jpeg"
                  (change)="onSelectFile($event.target.files, 'surveyForm')" />
                <label for="surveyImageUpload"></label>
              </div>
              <div class="avatar-preview surveyLogoDefault" [ngStyle]="{
                'background-image': 'url(./assets/imgs/upload_survey_icon.png)',
                'background-size': 'contain'
              }">
                <!-- <div id="imagePreview" [ngStyle]="{'background-image': 'ur(' + surveyForm.value.imageUrl + ')'}"></div> -->
                <!-- <div id="imagePreview" [ngStyle]="{
                    'background-image': surveyForm.value.surveyImage
                      ? 'ur(' + surveyForm.value.imageUrl + ')'
                      : 'url(./assets/imgs/upload_survey_icon.png)'
                  }"></div> -->
                <img class="imagePreviewe" [src]="surveyForm.value.imageUrl" id="imagePreview"
                  *ngIf="surveyForm.value.imageUrl">

              </div>
            </div>
            <div class="w-100">
              <div class="-input">
                <input type="text" formControlName="title" placeholder="{{ 'survey.Survey Title' | translate }}" />
              </div>
              <div class="-input">
                <textarea formControlName="description" placeholder="{{ 'survey.Survey Description' | translate }}">
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <section [formGroup]="surveyForm" class="survey-create-block enterprise-survey"
    *ngIf="surveyForm.value.surveyType === eSurveyType.ENTERPRISE">
    <div class="row">
      <div class="col-md-7 col-12">
        <div class="survey-info-container">
          <h3>{{ "survey.survey info" | translate }}</h3>
          <div class="survey-info-fields d-flex justify-content-between">
            <div class="avatar-upload">
              <div class="avatar-edit">
                <input type="file" id="surveyImageUpload" hidden accept=".png, .jpg, .jpeg"
                  (change)="onSelectFile($event.target.files, 'surveyForm')" />
                <label for="surveyImageUpload"></label>
              </div>
              <div class="avatar-preview">
                <div id="imagePreview" [ngStyle]="{
                    'background-image': surveyForm.value.imageUrl
                      ? 'ur(' + surveyForm.value.imageUrl + ')'
                      : 'url(./assets/imgs/upload_survey_icon.png)'
                  }"></div>
              </div>
            </div>
            <div class="w-100">
              <div class="-input">
                <input type="text" formControlName="title" placeholder="{{ 'survey.Survey Title' | translate }}" />
              </div>
              <div class="-input">
                <textarea formControlName="description" row="5"
                  placeholder="{{ 'survey.Survey Description' | translate }}">
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-12 d-flex align-self-end">
        <div class="survey-info-container w-100">
          <div class="survey-info-fields p-3">
            <div class="row px-2">
              <div class="col-12">
                <div class="m-border-grey d-flex justify-content-between m-border-radius-5 p-2 mb-2">
                  <h6 class="file-type-title">Background</h6>
                  <span class="file-upload-info">
                    Background image Size:
                    <span class="font-weight-bold">1024 X 720</span>
                  </span>
                  <input type="file" class="d-none" hidden id="surveyBackground"
                    (change)="onBackgroundSelect($event.target.files)" />
                  <label for="surveyBackground" class="upload-tag p-2">
                    Upload File
                  </label>
                  <label *ngIf="surveyForm.value.backGroundImageType" class="upload-tag p-2">
                    {{ surveyForm.value.backGroundImageType | json }}
                  </label>
                </div>
              </div>
            </div>

            <div class="row px-2">
              <div class="col-12">
                <div class="m-border-grey d-flex justify-content-between m-border-radius-5 p-2 mb-2">
                  <h6 class="file-type-title">Contact List</h6>
                  <span class="file-upload-info">
                    File format TXT,CSV MAX size:
                    <span class="font-weight-bold">50MB</span>
                  </span>
                  <input type="file" class="d-none" hidden id="surveyContactList" />
                  <label for="surveyContactList" class="upload-tag p-2">
                    Upload File
                  </label>
                  <label class="upload-tag p-2"> Download Template </label>
                </div>
              </div>
            </div>

            <div class="row px-2">
              <div class="col-12">
                <div class="m-border-grey d-flex justify-content-between m-border-radius-5 p-2 mb-2">
                  <h6 class="file-type-title">Questions File</h6>
                  <span class="file-upload-info">
                    File format TXT,CSV MAX size:
                    <span class="font-weight-bold">50MB</span>
                  </span>
                  <input type="file" class="d-none" hidden id="surveyQuestionList" />
                  <label for="surveyQuestionList" class="upload-tag p-2">
                    Upload File
                  </label>
                  <label class="upload-tag p-2"> Download Template </label>
                </div>
              </div>
            </div>

            <div class="row px-2">
              <div class="col-12">
                <div class="m-border-grey d-flex justify-content-between m-border-radius-5 p-2 mb-2">
                  <h6 class="file-type-title">Welcome message</h6>
                  <div class="-input mb-0">
                    <textarea class="p-3" formControlName="message" row="5"
                      placeholder="{{ 'survey.message' | translate }}">
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- Survey Question Display Area Start -->
  <section class="survey-create-block" [dir]="surveyForm?.value?.language == elanguage.Short_Arabic ? 'rtl' : 'ltr'"
    *ngIf="surveyQuestionsValues?.length">
    <ng-container *ngFor="let question of surveyQuestionsValues; let quesIndex = index">
      <ng-container *ngIf="
          actionMode === 'edit' && editIndex === quesIndex;
          else quesDisplayTemp
        ">
        <ng-container *ngTemplateOutlet="QuestionAddEditTemp"></ng-container>
      </ng-container>
      <ng-template #quesDisplayTemp>
        <div class="row justify-content-center">
          <div class="col-md-10 col-12">
            <div class="survey-card question-preview">
              <div class="">
                <div class="preview-action-bar">
                  <div>
                    <h4 class="text-capitalize">
                      {{ "survey.Question" | translate }} {{ quesIndex + 1 }}
                      {{
                      question.isYesNo
                      ? ""
                      : "(" + question.questionType + ")"
                      }}
                      <span class="font-14" *ngIf="question.isOptional">{{
                        "survey.optional" | translate
                        }}</span>
                    </h4>
                  </div>
                  <div>
                    <div class="d-flex justify-content-between">
                      <a class="mr-2"><i class="fas fa-trash" (click)="deleteQuestion(quesIndex)"></i></a>
                      <a class="mr-2"><i class="fas fa-pencil-alt" (click)="edit(question, quesIndex)"></i></a>
                      <a class="drag-icon"><i class="fas fa-arrows-alt"></i></a>
                    </div>
                  </div>
                </div>
                <div class="preview-question-header">
                  <div class="row">
                    <div class="col-md-2 col-12" *ngIf="question.imageUrl">
                      <div class="question-icon-preview" [ngStyle]="{
                          'background-image': 'url(' + question.imageUrl + ')'
                        }"></div>
                    </div>
                    <div [ngClass]="{
                        'col-md-10 col-12': question.imageUrl,
                        'col-12': !question.imageUrl
                      }">
                      <p class="text-break">{{ question.question }}</p>
                    </div>
                  </div>
                </div>
                <div class="preview-answer-options mt-3 pl-4">
                  <ng-container *ngIf="!question.isYesNo">
                    <!-- Text Answer Preview Start -->
                    <ng-container *ngIf="question.questionType === eQuesTypes.TEXT">
                      <div class="row" *ngFor="
                          let answer of question.answers;
                          let ansIndex = index
                        ">
                        <div class="col-md-2 col-12" *ngIf="answer.imageUrl">
                          <div class="option-icon-preview" [ngStyle]="{
                              'background-image': answer.imageUrl
                                ? answer.imageUrl
                                : 'url(./assets/imgs/create-survey-icon-1.png)'
                            }"></div>
                        </div>
                        <div [ngClass]="{
                            'col-md-10 col-12': answer.imageUrl,
                            'col-12': !answer.imageUrl
                          }">
                          <p>{{ answer?.option }}</p>
                        </div>
                      </div>
                    </ng-container>
                    <!-- Text Answer Preview End -->

                    <!-- Multi-Select Answer Preview Start -->
                    <ng-container *ngIf="question.questionType === eQuesTypes.MULTI_SELECT">
                      <div class="row mb-4" cdkDropList (cdkDropListDropped)="
                          arrangeOptions(question.answers, $event)
                        ">
                        <ng-container *ngFor="
                            let options of question.answers;
                            let ansIndex = index
                          ">
                          <div class="col-md-6 col-12">
                            <div class="height-70" [class.allow-drag]="question.isArrange">
                              <div class="checkbox-round d-flex align-items-center"
                                [cdkDragDisabled]="!question.isArrange" cdkDrag>
                                <input type="checkbox" disabled [value]="options.option" id="option-checkbox-{{ quesIndex }}-{{
                                    ansIndex
                                  }}" />
                                <label for="option-checkbox-{{ quesIndex }}-{{
                                    ansIndex
                                  }}"></label>
                                <div class="option-icon-preview" [ngStyle]="{
                                    'background-image': options.imageUrl
                                      ? 'url(' + options.imageUrl + ')'
                                      : 'url(./assets/imgs/create-survey-icon-1.png)'
                                  }"></div>
                                <span class="checkbox-text">{{
                                  options.option
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                    <!-- Multi-Select Answer Preview End -->

                    <!-- Single Select Template Start -->
                    <ng-container *ngIf="question.questionType === eQuesTypes.SINGLE_SELECT">
                      <div class="row" cdkDropList (cdkDropListDropped)="
                          arrangeOptions(question.answers, $event)
                        ">
                        <ng-container *ngFor="
                            let options of question.answers;
                            let ansIndex = index
                          ">
                          <div class="col-md-6 col-12">
                            <div class="height-70" [class.allow-drag]="question.isArrange">
                              <div class="radio-round d-flex align-items-center" [cdkDragDisabled]="!question.isArrange"
                                cdkDrag>
                                <input type="radio" disabled [value]="options.option" name="radio" id="option-radio-{{ quesIndex }}-{{
                                    ansIndex
                                  }}" />
                                <label for="option-radio-{{ quesIndex }}-{{
                                    ansIndex
                                  }}"></label>
                                <div class="option-icon-preview" [ngStyle]="{
                                    'background-image': options.imageUrl
                                      ? 'url(' + options.imageUrl + ')'
                                      : 'url(./assets/imgs/create-survey-icon-1.png)'
                                  }"></div>
                                <span class="radio-text">{{
                                  options.option
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                    <!-- Single Select Template End -->

                    <!-- Evaluation Template Start -->
                    <ng-container *ngIf="question.questionType === eQuesTypes.EVALUATION">
                      <div class="row">
                        <div class="col-12">
                          <div class="evaluation preview">
                            <ul>
                              <li>
                                <input type="checkbox" id="1" checked disabled hidden />
                                <label for="1">
                                  <span>1</span>
                                </label>
                              </li>
                              <li>
                                <input type="checkbox" id="2" checked disabled hidden />
                                <label for="2">
                                  <span>2</span>
                                </label>
                              </li>
                              <li>
                                <input type="checkbox" id="3" checked disabled hidden />
                                <label for="3">
                                  <span>3</span>
                                </label>
                              </li>
                              <li>
                                <input type="checkbox" id="4" checked disabled hidden />
                                <label for="4">
                                  <span>4</span>
                                </label>
                              </li>
                              <li>
                                <input type="checkbox" id="5" checked disabled hidden />
                                <label for="5">
                                  <span>5</span>
                                </label>
                              </li>
                            </ul>
                            <ul class="evaluation-answer-options">
                              <li>
                                <input type="text" disabled readonly [value]="question.answers[0].option" />
                              </li>
                              <li></li>
                              <li>
                                <input type="text" disabled readonly [value]="question.answers[1].option" />
                              </li>
                              <li></li>
                              <li>
                                <input type="text" disabled readonly [value]="question.answers[2].option" />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!-- Evaluation Template End -->
                  </ng-container>

                  <!-- Advance Options Start -->

                  <!-- Yes or No Start -->
                  <ng-container *ngIf="question.isYesNo">
                    <div class="row justify-content-center my-4">
                      <div class="col-md-3 col-6" *ngFor="let option of question.answers">
                        <div class="yes-no-card">
                          {{ "survey." + option.option | translate }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!-- Yes or No End -->

                  <!-- Other Text Start -->
                  <ng-container *ngIf="question.isOrderText">
                    <div class="row mt-4">
                      <div class="col-12">
                        <h6 class="font-weight-bold text-capitalize">
                          {{ "survey.Other (Text)" | translate }}
                        </h6>
                        <p class="text-wrap">{{ question.additionalText }}</p>
                        <!-- <textarea disabled formControlName="additionalText" cols="30" rows="10"></textarea> -->
                      </div>
                    </div>
                  </ng-container>
                  <!-- Other Text End -->

                  <!-- Advance Options End -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </section>
  <!-- Survey Question Display Area End -->

  <section class="survey-create-block" [dir]="surveyForm?.value?.language == elanguage.Short_Arabic ? 'rtl' : 'ltr'">
    <!-- Add Question Button Start -->
    <ng-container *ngIf="!surveyQuestionsValues?.length">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="survey-card p-4">
            <div class="card-header">
              <h4 class="font-weight-bold">
                {{ "survey.stepOneAddFirstQuestion" | translate }}
              </h4>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-lg-10 col-12">
                  <button class="btn btn-add-question btn-block" (click)="addQuestion()">
                    <span class="add-icon">
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </span>
                    {{ "survey.AddQuestion" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- Add Question Button End -->

    <!-- New Survey Question Card Start -->
    <ng-container *ngIf="actionMode === 'add'">
      <ng-container *ngTemplateOutlet="QuestionAddEditTemp"></ng-container>
    </ng-container>
    <!-- New Survey Question Card End -->

    <div class="row my-4">
      <div class="col-12 total">
        <!-- *ngFor="let wallet of rate" -->
        <p class="total">
          {{ "survey.Total" | translate }}
          <span>
            {{ surveyForm.value.totalBalance }}
            <!-- {{ "survey.points" | translate }} -->
            {{ "survey.SAR" | translate }}
          </span>
        </p>
      </div>
    </div>

    <!-- Add & Next Button Block Start -->
    <ng-container *ngIf="surveyQuestionsValues?.length">
      <div class="row my-4">
        <div class="col-md-6 col-12">
          <button class="btn btn-add-question btn-block" (click)="addQuestion()">
            <span class="add-icon">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </span>
            {{ "survey.AddQuestion" | translate }}
          </button>
        </div>
        <div class="col-md-6 col-12 mt-md-0 mt-4">
          <button type="button" [disabled]="actionMode == 'add' || actionMode == 'edit'" (click)="saveSurvey()"
            class="btn m-btn-gradient w-100 h-100 m-border-radius-30 font-30 font-weight-bold">
            {{ "general.Next" | translate }}
          </button>
        </div>
      </div>
    </ng-container>
    <!-- Add & Next Button Block End -->
  </section>
</div>

<app-loader [show]="showLoader"></app-loader>

<ng-template #QuestionAddEditTemp>
  <div [formGroup]="questionForm" class="row">
    <!-- Question Template Block Start -->
    <div class="col-lg-6 col-md-12 col-12">
      <h4 class="font-weight-bold mb-2">
        {{ "survey.Step1" | translate }}:
        {{ "survey.AddYourQuestion" | translate }}
      </h4>

      <div class="survey-card">
        <div class="card-body p-3 h-100">
          <div class="m-border-dashed-green m-border-radius-30 px-3 py-5">
            <h4 class="font-weight-bold mb-2">
              {{ "survey.Question" | translate }}
              {{ surveyQuestionsValues.length + 1 }}
            </h4>

            <div class="row">
              <div class="col-md-12 col-12">
                <div class="-input">
                  <textarea class="form-control h-auto px-4" style="padding-top: 8px" formControlName="question"
                    placeholder="{{ 'survey.Write Your Question' | translate }}" cols="30" rows="5"></textarea>
                </div>
              </div>
              <!-- <div class="col-md-3 col-12 text-center">
                <h6 class="m-text-grey-primary">{{'survey.AddQuestionVisual' | translate}}</h6>
                <input type="file" id="questionImageUpload" class="d-none" hidden accept=".png, .jpg, .jpeg" (change)="onSelectFile($event.target.files, 'questionForm')" />
                <label class="upload-image-area" for="questionImageUpload">
                  <i class="fas fa-upload" *ngIf="!questionForm.value.imageUrl"></i>
                  <div *ngIf="questionForm.value.imageUrl" class="question-icon-preview"
                  [ngStyle]="{ 'background-image': questionForm.value.imageUrl }"></div>
                </label>
              </div> -->
            </div>

            <!-- Answer Templated -->

            <div class="row" *ngIf="questionFormValues.questionType">
              <div class="col-12">
                <h4 class="font-weight-bold mb-2 text-capitalize">
                  {{ "home.answer" | translate }}
                </h4>
              </div>
            </div>

            <!-- Question Types Template Start -->
            <ng-container *ngIf="!questionFormValues.isYesNo">
              <!-- Text Template Start -->
              <ng-container *ngIf="questionFormValues.questionType === eQuesTypes.TEXT">
                <div class="row">
                  <div class="col-12">
                    <ng-container *ngFor="let control of answers.controls">
                      <div class="-input" [formGroup]="control">
                        <textarea name="" cols="30" rows="5" formControlName="option"
                          (focus)="onFieldFocus(control)"></textarea>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <!-- Text Template End -->

              <!-- MultiSelect Template Start -->
              <ng-container *ngIf="
                  questionFormValues.questionType === eQuesTypes.MULTI_SELECT
                ">
                <div class="row my-4">
                  <ng-container *ngFor="let control of answers.controls; let i = index">
                    <div class="col-md-6 col-12 mb-5" [formGroup]="control">
                      <div class="checkbox-round d-flex align-items-center">
                        <input type="checkbox" formControlName="option" id="checkbox-{{ i }}" />
                        <label for="checkbox-{{ i }}"></label>
                        <input type="text" class="m-input" formControlName="option" placeholder="Option {{ i + 1 }}"
                          (focus)="onFieldFocus(control)" />
                      </div>
                    </div>
                  </ng-container>
                </div>

                <div class="row justify-content-center">
                  <div class="col-md-6 col-12">
                    <button class="m-btn m-btn-add-answer w-100" (click)="addOptions(1)">
                      <span class="add-icon">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </span>
                      {{ "survey.Add answer" | translate }}
                    </button>
                  </div>
                </div>
              </ng-container>
              <!-- MultiSelect Template End -->

              <!-- Single Select Template Start -->
              <ng-container *ngIf="
                  questionFormValues.questionType === eQuesTypes.SINGLE_SELECT
                ">
                <div class="row my-4">
                  <div class="col-md-6 col-12 mb-5" [formGroup]="control"
                    *ngFor="let control of answers.controls; let i = index">
                    <div class="radio-round d-flex align-items-center">
                      <input type="radio" name="radio-{{ i }}" id="radio-{{ i }}" />
                      <label for="radio-{{ i }}"></label>
                      <input type="text" class="m-input" formControlName="option" placeholder="Option"
                        (focus)="onFieldFocus(control)" />
                      <!-- <span class="radio-text">Option 1</span> -->
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-md-6 col-12">
                    <button class="m-btn m-btn-add-answer w-100" (click)="addOptions(1)">
                      <span class="add-icon">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </span>
                      {{ "survey.Add answer" | translate }}
                    </button>
                  </div>
                </div>
              </ng-container>
              <!-- Single Select Template End -->

              <!-- Evaluation Template Start -->
              <ng-container *ngIf="
                  questionFormValues.questionType === eQuesTypes.EVALUATION
                ">
                <div class="row">
                  <div class="col-12">
                    <div class="evaluation">
                      <ul>
                        <li>
                          <input type="checkbox" id="1" checked disabled hidden />
                          <label for="1">
                            <span>1</span>
                          </label>
                        </li>
                        <li>
                          <input type="checkbox" id="2" checked disabled hidden />
                          <label for="2">
                            <span>2</span>
                          </label>
                        </li>
                        <li>
                          <input type="checkbox" id="3" checked disabled hidden />
                          <label for="3">
                            <span>3</span>
                          </label>
                        </li>
                        <li>
                          <input type="checkbox" id="4" checked disabled hidden />
                          <label for="4">
                            <span>4</span>
                          </label>
                        </li>
                        <li>
                          <input type="checkbox" id="5" checked disabled hidden />
                          <label for="5">
                            <span>5</span>
                          </label>
                        </li>
                      </ul>
                      <ul class="evaluation-answer-options">
                        <li [formGroup]="answers.controls[0]">
                          <input type="text" formControlName="option" />
                        </li>
                        <li></li>
                        <li [formGroup]="answers.controls[1]">
                          <input type="text" formControlName="option" />
                        </li>
                        <li></li>
                        <li [formGroup]="answers.controls[2]">
                          <input type="text" formControlName="option" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- Evaluation Template End -->
            </ng-container>
            <!-- Question Types Template End -->

            <!-- Question Additional Options Template Start -->

            <!-- Yes or No Start-->
            <ng-container *ngIf="questionFormValues.isYesNo">
              <div class="row my-4">
                <div class="col-6" *ngFor="let control of answers.controls">
                  <div class="yes-no-card">
                    {{ "survey." + control.value.option | translate }}
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- Yes or No End-->

            <!-- Other (text) Start -->
            <ng-container *ngIf="questionFormValues.isOrderText">
              <div class="row mt-4">
                <div class="col-12">
                  <h4 class="font-weight-bold text-capitalize mb-2">
                    {{ "survey.Other (Text)" | translate }}
                  </h4>
                  <div class="-input">
                    <textarea class="form-control h-auto" formControlName="additionalText"
                      placeholder="{{ 'survey.AdditionalText' | translate }}" cols="30" rows="5"></textarea>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- Other (text) End -->

            <!-- Question Additional Options End -->

            <!-- Answer Templated End -->
          </div>
        </div>
      </div>
    </div>
    <!-- Question Template Block End -->

    <!-- Question Type & Advance Options Block Start -->
    <div class="col-lg-6 col-md-12 col-12">
      <div class="row">
        <div class="col-12">
          <h4 class="font-weight-bold mb-2">
            {{ "survey.Responsestype2" | translate }}
          </h4>
        </div>
      </div>

      <!-- Question Types Block Start -->
      <div class="survey-card mb-2" [class.disabled]="questionFormValues.isYesNo">
        <div class="card-body px-3 py-5">
          <div class="d-flex justify-content-between flex-md-row flex-column">
            <div class="tag question-type-tag mb-md-0 mb-3" [ngClass]="{
                'cursor-pointer': !questionFormValues.isYesNo,
                'cursor-not-allowed': questionFormValues.isYesNo,
                'm-border-green m-bg-green m-text-white-imp':
                  questionFormValues.questionType === eQuesTypes.EVALUATION
              }" (click)="setQuestionType(eQuesTypes.EVALUATION)">
              <img src="./assets/imgs/survey-icons/star.svg" class="mr-2" />
              {{ "survey.Evaluation" | translate }}
            </div>

            <div class="tag question-type-tag mb-md-0 mb-3" [ngClass]="{
                'cursor-pointer': !questionFormValues.isYesNo,
                'cursor-not-allowed': questionFormValues.isYesNo,
                'm-border-green m-bg-green m-text-white-imp':
                  questionFormValues.questionType === eQuesTypes.MULTI_SELECT
              }" (click)="setQuestionType(eQuesTypes.MULTI_SELECT)">
              <img src="./assets/imgs/survey-icons/multi-select.svg" class="mr-2" />
              {{ "survey.MultipleChoice" | translate }}
            </div>

            <div class="tag question-type-tag mb-md-0 mb-3" [ngClass]="{
                'cursor-pointer': !questionFormValues.isYesNo,
                'cursor-not-allowed': questionFormValues.isYesNo,
                'm-border-green m-bg-green m-text-white-imp':
                  questionFormValues.questionType === eQuesTypes.SINGLE_SELECT
              }" (click)="setQuestionType(eQuesTypes.SINGLE_SELECT)">
              <img src="./assets/imgs/survey-icons/single-select.svg" class="mr-2" />
              {{ "survey.SingleChoice" | translate }}
            </div>

            <div class="tag question-type-tag mb-md-0 mb-3" [ngClass]="{
                'cursor-pointer': !questionFormValues.isYesNo,
                'cursor-not-allowed': questionFormValues.isYesNo,
                'm-border-green m-bg-green m-text-white-imp':
                  questionFormValues.questionType === eQuesTypes.TEXT
              }" (click)="setQuestionType(eQuesTypes.TEXT)">
              <img src="./assets/imgs/survey-icons/text.svg" class="mr-2" />
              {{ "survey.Text" | translate }}
            </div>
          </div>
        </div>
      </div>
      <!-- Question Type Block End -->

      <!-- Advance Options Block Start -->
      <h4 class="font-weight-bold mb-2">
        <img src="./assets/imgs/survey-icons/setting.svg" alt="" />
        {{ "survey.AdvanceOptions" | translate }}
      </h4>

      <div class="survey-card">
        <div class="card-body px-3">
          <div class="d-flex justify-content-between text-center" style="flex-flow: wrap; flex-direction: row">
            <!-- <div class="advance-option mb-md-0 mb-3">
              <h6 class="m-text-grey-primary">Question Workflow</h6>
              <div class="tag">
                <img src="./assets/imgs/survey-icons/question-flow.svg" alt="">
              </div>
            </div> -->
            <div class="advance-option mb-md-0 mb-3">
              <h6 class="m-text-grey-primary">
                {{ "survey.AddQuestionVisual" | translate }}
              </h6>

              <input type="file" id="questionImageUpload" class="d-none" hidden accept=".png, .jpg, .jpeg"
                (change)="onSelectFile($event.target.files, 'questionForm')" />
              <ng-container *ngIf="questionForm.value.imageUrl; else tempQuesDefaultImage">
                <label class="upload-image-area cursor-pointer" for="questionImageUpload">
                  <div class="question-icon-preview" [ngStyle]="{
                      'background-image':
                        'url(' + questionForm.value.imageUrl + ')'
                    }"></div>
                </label>
              </ng-container>

              <ng-template #tempQuesDefaultImage>
                <label class="tag cursor-pointer" for="questionImageUpload">
                  <img src="./assets/imgs/survey-icons/upload.svg" alt="" />
                  <img class="available" src="./assets/imgs/survey-icons/available-option.svg" alt="" />
                </label>
              </ng-template>
            </div>
            <div class="advance-option mb-md-0 mb-3">
              <h6 class="m-text-grey-primary">
                {{ "survey.Arrange" | translate }}
              </h6>
              <input type="checkbox" id="arrangeOption" formControlName="isArrange" class="d-none" hidden
                accept=".png, .jpg, .jpeg" />
              <label for="arrangeOption" class="tag cursor-pointer"
                [class.m-border-green]="questionFormValues.isArrange">
                <img src="./assets/imgs/survey-icons/arrange.svg" alt="" />
                <img class="available" src="./assets/imgs/survey-icons/available-option.svg" alt="" />
              </label>
            </div>
            <div class="advance-option mb-md-0 mb-3">
              <h6 class="m-text-grey-primary">
                {{ "survey.AddAnswerVisual" | translate }}
              </h6>
              <input type="file" id="answerImageUpload" class="d-none" hidden accept=".png, .jpg, .jpeg"
                (change)="onSelectFile($event.target.files, 'answer')" />
              <ng-container *ngIf="
                  answers?.controls[questionForm?.value.currentOptionIndex]
                    ?.value.imageUrl;
                  else tempAnsDefaultImage
                ">
                <label class="upload-image-area cursor-pointer" for="answerImageUpload">
                  <div class="question-icon-preview" [ngStyle]="{
                      'background-image':
                        'url(' +
                        answers.controls[questionForm.value.currentOptionIndex]
                          .value.imageUrl +
                        ')'
                    }"></div>
                </label>
              </ng-container>

              <ng-template #tempAnsDefaultImage>
                <label class="tag cursor-pointer" for="answerImageUpload">
                  <img src="./assets/imgs/survey-icons/upload.svg" alt="" />
                  <img class="available" src="./assets/imgs/survey-icons/available-option.svg" alt="" />
                </label>
              </ng-template>
            </div>
            <div class="advance-option mb-md-0 mb-3">
              <h6 class="m-text-grey-primary">
                {{ "survey.Yes OR No" | translate }}
              </h6>
              <input type="checkbox" id="yesNoOption" formControlName="isYesNo" class="d-none" hidden
                (change)="onYesNoChange()" />
              <label for="yesNoOption" class="tag cursor-pointer" [class.m-border-green]="questionFormValues.isYesNo">
                <img src="./assets/imgs/survey-icons/yes-or-no.svg" alt="" />
                <img class="available" src="./assets/imgs/survey-icons/available-option.svg" alt="" />
              </label>
            </div>
            <div class="advance-option mb-md-0 mb-3">
              <h6 class="m-text-grey-primary">
                {{ "survey.Add other (text)" | translate }}
              </h6>
              <input type="checkbox" class="d-none" formControlName="isOrderText" hidden id="otherTextCheckbox" />
              <label class="tag cursor-pointer" for="otherTextCheckbox"
                [class.m-border-green]="questionFormValues.isOrderText">
                <img src="./assets/imgs/survey-icons/other-text.svg" alt="" />
                <img class="available" src="./assets/imgs/survey-icons/available-option.svg" alt="" />
              </label>
            </div>
            <div class="advance-option mb-md-0 mb-3">
              <h6 class="m-text-grey-primary">
                {{ "survey.None of the above" | translate }}
              </h6>
              <input type="checkbox" class="d-none" formControlName="noneOfAbove" hidden id="noneOfAboveCheckbox" />
              <label class="tag cursor-pointer" for="noneOfAboveCheckbox"
                [class.m-border-green]="questionFormValues.noneOfAbove">
                <img src="./assets/imgs/survey-icons/monkey.svg" alt="" />
                <img class="available" src="./assets/imgs/survey-icons/available-option.svg" alt="" />
              </label>
            </div>
            <div class="advance-option mb-md-0 mb-3">
              <h6 class="m-text-grey-primary">
                {{ "survey.Optional Question" | translate }}
              </h6>
              <input type="checkbox" class="d-none" formControlName="isOptional" hidden id="optionalQuesCheckbox" />
              <label class="tag cursor-pointer" for="optionalQuesCheckbox"
                [class.m-border-green]="questionFormValues.isOptional">
                <img src="./assets/imgs/survey-icons/optional-question.svg" alt="" />
                <img class="available" src="./assets/imgs/survey-icons/available-option.svg" alt="" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- Advance Options Block End -->

      <div class="row justify-content-end" style="height: 54px">
        <div class="col-md-3 col-6">
          <button type="button" style="height: 54px" class="m-btn m-btn-cancel btn-block w-100 h-100"
            (click)="cancel()">
            {{ "survey.Cancel" | translate }}
          </button>
        </div>
        <div class="col-md-3 col-6">
          <button type="button" style="height: 54px" class="m-btn m-btn-green w-100 h-100" (click)="saveQuestion()">
            {{ "survey.save" | translate }}
          </button>
          <img src="./assets/imgs/survey-icons/hand-point-left.svg" class="hand-icon" alt="" />
        </div>
      </div>
    </div>
    <!-- Question Type & Advance Options Block End -->
  </div>
</ng-template>