import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {

    currentMessage = new BehaviorSubject(null);

    constructor(
        private angularFireDB: AngularFireDatabase,
        private angularFireAuth: AngularFireAuth,
        private translateService: TranslateService,
        private toastr: ToastrService,
        private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messages.subscribe(
            (_messaging: any) => {
                console.log(_messaging.notification);
                // this.translateService
                //     .get("login.auth_error_message")
                //     .subscribe((text) => {
                this.toastr.success(_messaging.notification.body);
                // });
                // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                // _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
    }

    /**
     * update token in firebase database
     *
     * @param userId userId as a key
     * @param token token as a value
     */
    updateToken(userId, token) {
        // we can change this function to request our backend service
        this.angularFireAuth.authState.pipe(take(1)).subscribe(
            () => {
                const data = {};
                data[userId] = token
                this.angularFireDB.object('fcmTokens/').update(data)
            })
    }
    requestPermissionNew() {
        return this.angularFireMessaging.requestToken;
    }
    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userId userId
     */
    requestPermission(userId): Observable<string> {
        return new Observable<string>((observer) => {
            this.angularFireMessaging.requestToken.subscribe(
                (token) => {
                    console.log(token, "requestToken");
                    // this.updateToken(userId, token);
                    observer.next(token);
                    observer.complete();
                },
                (err) => {
                    console.error('Unable to get permission to notify.', err);
                    observer.error(err); // Emit an error if there's a problem
                }
            );
        });
    }


    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                console.log("new message received. ", payload);
                this.currentMessage.next(payload);
            })
    }
}
