import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { SurveyService } from 'src/services/survey.service';
@Component({
  selector: 'app-invite-survey',
  templateUrl: './invite-survey.component.html',
  styleUrls: ['./invite-survey.component.css']
})
export class InviteSurveyComponent implements OnInit {
  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private surveyService: SurveyService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
    })
  }

  async handleStartSurvey() {
    if (!this.form.value.name) {
      return;
    }

    const surveUrl = await navigator.clipboard.readText();
    if (surveUrl?.includes('/survey-answer?id=')) {
      const surveId = surveUrl.split('=')[1];
      if (surveId) {
        this.surveyService.getSurveyById(surveId).pipe(first()).subscribe((result) => {
          if (result) {
            this.surveyService.createLocalSurveyModal({...result});
            this.surveyService.createSurveyUserInfo({...this.form.value});
            this.router.navigate([`/survey-answer/${result._id}/${result?.language}`]);
          } else {
            this.toastr.error('No Survey Found');
          }
        }, err => {
          this.toastr.error('No Survey Found');
        });
      } else {
        this.toastr.error('No Survey Found');
      }
    } else {
      this.toastr.error('No Survey Found');
    }

  }

  async startSurvey() {
    // navigator.clipboard.readText().then(x => {
    // }).catch(() => { alert("read something went wrong"); })
  }
}
