import { Component, OnInit } from '@angular/core';
import { eLanguage } from 'src/app/general/enums/language.enum';
import { DarkLiteThemeService } from 'src/services/dark-lite-themeService.service';
import { LanguageService } from 'src/services/language.service';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'app-new-main-dashboard',
  templateUrl: './new-main-dashboard.component.html',
  styleUrls: ['./new-main-dashboard.component.css']
})
export class NewMainDashboardComponent implements OnInit {

  isSidebarVisible = true;
  changeToLang: any;
  eLanguage = eLanguage;

  constructor(public darkThemeService: DarkLiteThemeService,

              private breakpointObserver: BreakpointObserver,
    public languageService: LanguageService
    ) {
      this.changeToLang =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_English
        ? eLanguage.Short_Arabic
        : eLanguage.Short_English;
     }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
      if (result.matches) {
        this.isSidebarVisible = false;
      }
    });
  }

  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

}
