import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators, FormArray, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { eSurveyQuestionTypes } from "src/app/general/enums/survey-question-type.enum";
import { SurveyTypes } from "src/app/general/enums/survey-types.enum";
import { AuthService } from "src/services/auth.service";
import { HomeService } from "src/services/home.service";
import { SurveyService } from "../../../services/survey.service";

@Component({
  selector: "app-survey-preview",
  templateUrl: "./survey-preview.component.html",
  styleUrls: ["./survey-preview.component.css"],
})
export class SurveyPreviewComponent implements OnInit, OnDestroy {

  allAnswersSurvey: any[] =[];
  currentAnswerSurvey: any = {};
  showLoader = false;
  surveyOption;
  surveyImage = null;
  endDate;
  currentDate;

  surveyFinished;
  eQuesTypes = eSurveyQuestionTypes;

  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  page: number = 1;
  surveyForm: FormArray;
  subscriptions = new Subscription();
  survey: any = {};

  constructor(
    private router: Router,
    private _fb: FormBuilder,
    private ar: ActivatedRoute,
    public authService: AuthService,
    private homeService: HomeService,
    private surveyService: SurveyService,
  ) {
    // let option = this.surveyService.getOptionsSurvey();
    // let questions = this.surveyService.getQuestionSurvey();
    // this.surveyOption = { ...option, ...questions };

    // if (this.surveyOption.surveyImage) {
    //   this.surveyImage = this.surveyOption.surveyImage;
    // }

    // if (!this.surveyOption) {
    //   this.router.navigate(["/"]);
    // }


  }

  async ngOnInit() {
    await this.getSurveyById(atob(this.ar.snapshot.params.surveyId));
    // this.subscriptions.add(this.counter());
  }

  ngOnDestroy() {
    // this.subscriptions.unsubscribe();
  }

  counter() {
    this.currentDate = new Date().getTime();
    this.endDate = new Date(this.surveyOption.endDate).getTime();
    // Find the distance between now and the count down date
    this.surveyFinished = this.endDate - this.currentDate;
    this.days = Math.floor(this.surveyFinished / (1000 * 60 * 60 * 24));
    this.hours = Math.floor(
      (this.surveyFinished % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    this.minutes = Math.floor(
      (this.surveyFinished % (1000 * 60 * 60)) / (1000 * 60)
    );
    this.seconds = Math.floor((this.surveyFinished % (1000 * 60)) / 1000);
  }

  async getSurveyById(surveyId) {

    const surveys = await this.surveyService.getAllFilledSurvey().toPromise();
    if (surveys?.length) {
      console.log('surveys by ID: ', surveys);
      const filteredSurveys = surveys.filter(x => x.surveyId?._id == surveyId);
      if (filteredSurveys?.length) {
        this.survey = filteredSurveys[0].surveyId;
        this.allAnswersSurvey = this.homeService.deepCopy(filteredSurveys);
        this.onPageChange(1);
      }
      console.log('filteredSurveys by ID: ', filteredSurveys);
      console.log('allAnswersSurvey: ', this.allAnswersSurvey);

    }

    // const survey = await this.surveyService.getFilledSurveyById(surveyId).toPromise();
    // console.log('survey res: ', survey);
    // if (survey?.surveyId) {
    //   this.survey = survey?.surveyId;
    //   // this.createSurveyFrom(survey?.surveyId);
    // }
  }

  private createSurveyFrom(data?: any) {
    this.surveyForm = this._fb.array([]);
    const form = this._fb.group({
      surveyId: new FormControl(data._id ? data._id : "", [
        Validators.required,
      ]),
      channel: new FormControl(data.channel ? data.channel : "", [
        Validators.required,
      ]),
      country: new FormControl(data.country ? data.country : "", [
        Validators.required,
      ]),
      cityId: new FormControl(data.cityId ? data.cityId : "", [
        Validators.required,
      ]),
      gender: new FormControl(data.gender ? data.gender : "", [
        Validators.required,
      ]),
      name: new FormControl(data.name ? data.name : "", [Validators.required]),
      title: new FormControl(data.title ? data.title : "", [
        Validators.required,
      ]),
      surveyType: new FormControl(
        data?.surveyType ? data?.surveyType : SurveyTypes.NORMAL
      ),
      categoryId: new FormControl(data.categoryId ? data.categoryId : null, [
        Validators.required,
      ]),
      description: new FormControl(data.description ? data.description : "", [
        Validators.required,
      ]),
      language: new FormControl(data.language ? data.language : "en", [
        Validators.required,
      ]),
      image: new FormControl(data.image ? data.image : null),
      imageUrl: new FormControl(data.surveyImage ? data.surveyImage : null),
      totalPoints: new FormControl(data.totalPoints ? data.totalPoints : 0),
      exchangeRate: new FormControl(data.exchangeRate ? data.exchangeRate : ""),
      questionRate: new FormControl(data.questionRate ? data.questionRate : ""),
      uploadQuestion: new FormControl(data.uploadQuestion ? data.uploadQuestion : ""),
      contactAudienceList: new FormControl(data.contactAudienceList ? data.contactAudienceList : ""),
      backGroundImage: new FormControl(data.backGroundImage ? data.backGroundImage : null),
      backGroundImageUrl: new FormControl(data.backGroundImageUrl ? data.backGroundImageUrl : null),
      message: new FormControl(data.message ? data.message : ""),
      startDate: new FormControl(data.startDate ? data.startDate : ""),
      endDate: new FormControl(data.endDate ? data.endDate : ""),
      questions: data?.questions?.length ? this.addQuestions(data?.questions) : this._fb.array([]),
    });
    this.surveyForm.push(form);
  }
  private addQuestions(questions: any = []) {
    let questionsFormArr: FormArray = this._fb.array([]);
    for (let index = 0; index < questions.length; index++) {
      const question = questions[index];
      question['questionNo'] = index + 1;
      questionsFormArr.push(this.createQuestionFrom(question));
    }
    return questionsFormArr;
  }
  private createQuestionFrom(data?: any) {
    const formGroup = this._fb.group({
      questionType: new FormControl(data?.questionType ? data.questionType : null, [Validators.required]),
      question: new FormControl(data?.question ? data.question : null, [Validators.required]),
      image: new FormControl(data?.image ? data.image : null),
      imageUrl: new FormControl(data?.imageUrl ? data.imageUrl : null),
      answers: data?.answers?.length ? this.addOptions(data.answers.length, data.answers) : this._fb.array([]),
      currentOptionIndex: new FormControl(data?.currentOptionIndex ? data.currentOptionIndex : null),
      isOrderText: new FormControl(data?.isOrderText ? data?.isOrderText : false),
      additionalText: new FormControl(data?.additionalText ? data?.additionalText : null),
      isOptional: new FormControl(data?.isOptional ? data?.isOptional : false),
      noneOfAbove: new FormControl(data?.noneOfAbove ? data?.noneOfAbove : false),
      isArrange: new FormControl(data?.isArrange ? data?.isArrange : false),
      isYesNo: new FormControl(data?.isYesNo ? data?.isYesNo : false),
      selectedAnswer: new FormControl( data?.selectedAnswer?.length ? data?.selectedAnswer : []),
      selectedAnswerIndex: new FormControl((data?.selectedAnswerIndex >= 0) ? data.selectedAnswerIndex : null),
      textAnswer: new FormControl(data?.textAnswer ? data?.textAnswer : null),
      questionNo: new FormControl(data?.questionNo ? data?.questionNo : 0),
      status: new FormControl('pending')
    });
    return formGroup;
  }
  private addOptions(noOfOptions: number, items?: any[]): FormArray | void {
    let answers: FormArray = this._fb.array([]);
    for (let index = 0; index < noOfOptions; index++) {
      const optionIndex = answers.controls.length ? answers.controls[answers.controls.length - 1].get("optionIndex").value + 1 : index;
      answers.push(this.createOption(optionIndex, items?.length ? items[index] : {}));
    }
    return answers;
  }
  private createOption(optionIndex: number, item?: any) {
    return this._fb.group({
      optionIndex: new FormControl(optionIndex),
      option: new FormControl(item?.option ? item.option : null),
      image: new FormControl(item?.image ? item.image : null),
      imageUrl: new FormControl(item?.imageUrl ? item.imageUrl : null),
    });
  }
  get allQuestions() {
    return (
      this.surveyForm?.controls ? this.surveyForm?.get("questions") : []
    ) as FormArray;
  }
  // get survey() {
  //   return this.surveyForm?.value;
  // }

  onPageChange(pageNo) {
    this.page = pageNo;
    let survey = this.allAnswersSurvey[pageNo - 1];
    this.currentAnswerSurvey = this.homeService.deepCopy(survey);
    this.selectEvaluationOptionIndex(survey);
  }

  selectEvaluationOptionIndex(survey: any) {
    // survey = this.homeService.deepCopy(survey);
    survey = survey.questions?.map(ques => {
      if (ques?.questionType == eSurveyQuestionTypes.EVALUATION) {
        const index = ques?.selectedAnswer[0]?.split('-')[1];
        ques.selectedAnswerIndex = index;
        //ques?.answers?.find(ans => ans.option == ques?.selectedAnswer[0])?.optionIndex;
      }
      return ques;
    })
  }

}
