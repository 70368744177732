<div [ngClass]="darkThemeService.getCurrentTheme" class="survyes-container">
  <ul class="nav nav-tabs justify-content-start" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <a
        class="nav-link text-captalize"
        id="active-tab"
        data-toggle="tab"
        href="#activeSurvey"
        role="tab"
        aria-controls="active"
        aria-selected="false"
      >
        {{ "home.active" | translate }}
      </a>
    </li>
    <!-- <li class="nav-item" role="presentation">
      <a class="nav-link text-captalize" id="archive-tab" data-toggle="tab" href="#archiveSurvey" role="tab"
        aria-controls="archive" aria-selected="false">
        {{ "home.archive" | translate }}
      </a>
    </li> -->
    <li class="nav-item" role="presentation">
      <a
        class="nav-link text-captalize"
        id="rejected-tab"
        data-toggle="tab"
        href="#rejectedSurvey"
        role="tab"
        aria-controls="rejected"
        aria-selected="false"
      >
        {{ "home.rejected" | translate }}
      </a>
    </li>
    <!-- <li class="nav-item" role="presentation">
      <a class="nav-link text-captalize" id="liked-tab" data-toggle="tab" href="#likedSurvey" role="tab"
        aria-controls="liked" aria-selected="false">
        {{ "home.liked" | translate }}
      </a>
    </li> -->
    <li class="nav-item" role="presentation">
      <a
        class="nav-link active text-captalize"
        id="all-tab"
        data-toggle="tab"
        href="#AllSurvey"
        role="tab"
        aria-controls="all"
        aria-selected="true"
      >
        {{ "home.All" | translate }}
      </a>
    </li>
  </ul>

  <div class="tab-content" id="surveyTabContent">
    <!-- Active Surveys -->
    <div
      class="tab-pane fade"
      id="activeSurvey"
      role="tabpanel"
      aria-labelledby="active-tab"
    >
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12"
          *ngFor="let survey of survey.active.all; let i = index"
        >
          <div class="survey-card">
            <div class="top">
              <div class="row">
                <div class="col-md-4 col-3">
                  <div class="comp-logo">
                    <img
                      [src]="
                        survey?.surveyImage
                          ? survey?.surveyImage
                          : 'assets/imgs/logo.svg'
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-md-8 col-9">
                  <div class="text">
                    <!-- <p title="{{ survey.title }}">
                                        {{ survey?.title }}
                                      </p> -->
                    <div class="iconRow">
                      <div class="col-3 borderBox">
                        <i class="fas fa-calendar-alt iconSetting"></i>
                        <span class="surveyIconHeading">{{
                          "survey.survey" | translate
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <i
                          class="fas fa-heart iconSetting"
                          style="color: #f05c6d !important"
                        ></i>
                        <span class="surveyIconHeading">{{
                          survey?.likes
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <img
                          src="./assets/imgs/flame.svg"
                          class="status-icon"
                          alt=""
                        />
                        <span class="surveyIconHeading">{{
                          "home.trend" | translate
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <i
                          class="fas fa-heart iconSetting"
                          style="color: #efa536 !important; right: 2px"
                        ></i>
                        <span class="surveyIconHeading">{{
                          "home.win" | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 overflow-hidden">
                  <h4 title="{{ survey.title }}" class="title-style">
                    {{ survey?.title }}
                  </h4>
                  <p class="mt-2 text-description fix-description">
                    {{ survey?.description }}
                  </p>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="timer d-flex">
                <app-counter
                  [dates]="{
                    startDate: survey?.startDate,
                    endDate: survey?.endDate
                  }"
                ></app-counter>

                <!-- <div>
                                    <h5>
                                      {{ survey?.days }}
                                      <span>{{ "general.days" | translate }}</span>
                                    </h5>
                                    :
                                    <h5>
                                      {{ survey?.hours }}
                                      <span>{{ "general.hours" | translate }}</span>
                                    </h5>
                                    :
                                    <h5>
                                      {{ survey?.minutes }}
                                      <span>{{ "general.mins" | translate }}</span>
                                    </h5>
                                    :
                                    <h5>
                                      {{ survey?.seconds }}
                                      <span>{{ "general.secs" | translate }}</span>
                                    </h5>
                                  </div> -->
                <div>
                  <button disabled class="m-btn m-btn-green">
                    {{ "home.details" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Archive Surveys -->

    <div
      class="tab-pane fade"
      id="archiveSurvey"
      role="tabpanel"
      aria-labelledby="archive-tab"
    >
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12"
          *ngFor="let survey of survey.archive.all; let i = index"
        >
          <div class="survey-card">
            <div class="top">
              <div class="row">
                <div class="col-md-4 col-3">
                  <div class="comp-logo">
                    <img
                      [src]="
                        survey?.surveyImage
                          ? survey?.surveyImage
                          : 'assets/imgs/logo.svg'
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-md-8 col-9">
                  <div class="text">
                    <!-- <p title="{{ survey.title }}">
                                                  {{ survey?.title }}
                                                </p> -->
                    <div class="iconRow">
                      <div class="col-3 borderBox">
                        <i class="fas fa-calendar-alt iconSetting"></i>
                        <span class="surveyIconHeading">{{
                          "survey.survey" | translate
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <i
                          class="fas fa-heart iconSetting"
                          style="color: #f05c6d !important"
                        ></i>
                        <span class="surveyIconHeading">{{
                          survey?.likes
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <img
                          src="./assets/imgs/flame.svg"
                          class="status-icon"
                          alt=""
                        />
                        <span class="surveyIconHeading">{{
                          "home.trend" | translate
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <i
                          class="fas fa-heart iconSetting"
                          style="color: #efa536 !important; right: 2px"
                        ></i>
                        <span class="surveyIconHeading">{{
                          "home.win" | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h4 title="{{ survey.title }}" class="title-style">
                    {{ survey?.title }}
                  </h4>
                  <p class="mt-2 text-description fix-description">
                    {{ survey?.description }}
                  </p>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="timer d-flex">
                <app-counter
                  [dates]="{
                    startDate: survey?.startDate,
                    endDate: survey?.endDate
                  }"
                ></app-counter>

                <!-- <div>
                                              <h5>
                                                {{ survey?.days }}
                                                <span>{{ "general.days" | translate }}</span>
                                              </h5>
                                              :
                                              <h5>
                                                {{ survey?.hours }}
                                                <span>{{ "general.hours" | translate }}</span>
                                              </h5>
                                              :
                                              <h5>
                                                {{ survey?.minutes }}
                                                <span>{{ "general.mins" | translate }}</span>
                                              </h5>
                                              :
                                              <h5>
                                                {{ survey?.seconds }}
                                                <span>{{ "general.secs" | translate }}</span>
                                              </h5>
                                            </div> -->
                <div>
                  <button disabled class="m-btn m-btn-green">
                    {{ "home.details" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Rejected Surveys -->

    <div
      class="tab-pane fade"
      id="rejectedSurvey"
      role="tabpanel"
      aria-labelledby="rejected-tab"
    >
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12"
          *ngFor="let survey of survey.rejected.all; let i = index"
        >
          <div class="survey-card">
            <div class="top">
              <div class="row">
                <div class="col-md-4 col-3">
                  <div class="comp-logo">
                    <img
                      [src]="
                        survey?.surveyImage
                          ? survey?.surveyImage
                          : 'assets/imgs/logo.svg'
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-md-8 col-9">
                  <div class="text">
                    <!-- <p title="{{ survey.title }}">
                                                  {{ survey?.title }}
                                                </p> -->
                    <div class="iconRow">
                      <div class="col-3 borderBox">
                        <i class="fas fa-calendar-alt iconSetting"></i>
                        <span class="surveyIconHeading">{{
                          "survey.survey" | translate
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <i
                          class="fas fa-heart iconSetting"
                          style="color: #f05c6d !important"
                        ></i>
                        <span class="surveyIconHeading">{{
                          survey?.likes
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <img
                          src="./assets/imgs/flame.svg"
                          class="status-icon"
                          alt=""
                        />
                        <span class="surveyIconHeading">{{
                          "home.trend" | translate
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <i
                          class="fas fa-heart iconSetting"
                          style="color: #efa536 !important; right: 2px"
                        ></i>
                        <span class="surveyIconHeading">{{
                          "home.win" | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 overflow-hidden">
                  <h4 title="{{ survey.title }}" class="title-style">
                    {{ survey?.title }}
                  </h4>
                  <p class="mt-2 text-description fix-description">
                    {{ survey?.description }}
                  </p>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="timer d-flex">
                <app-counter
                  [dates]="{
                    startDate: survey?.startDate,
                    endDate: survey?.endDate
                  }"
                ></app-counter>

                <!-- <div>
                                              <h5>
                                                {{ survey?.days }}
                                                <span>{{ "general.days" | translate }}</span>
                                              </h5>
                                              :
                                              <h5>
                                                {{ survey?.hours }}
                                                <span>{{ "general.hours" | translate }}</span>
                                              </h5>
                                              :
                                              <h5>
                                                {{ survey?.minutes }}
                                                <span>{{ "general.mins" | translate }}</span>
                                              </h5>
                                              :
                                              <h5>
                                                {{ survey?.seconds }}
                                                <span>{{ "general.secs" | translate }}</span>
                                              </h5>
                                            </div> -->
                <div>
                  <button disabled class="m-btn m-btn-green">
                    {{ "home.details" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="survey.rejected.all.length == 0">NO DATA FOUND</div> -->
      </div>
    </div>
    <div
      class="tab-pane fade show active"
      id="AllSurvey"
      role="tabpanel"
      aria-labelledby="rejected-tab"
    >
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12"
          *ngFor="let survey of survey.all; let i = index"
        >
          <div class="survey-card">
            <div class="top">
              <div class="row">
                <div class="col-md-4 col-3">
                  <div class="comp-logo">
                    <img
                      [src]="
                        survey?.surveyImage
                          ? survey?.surveyImage
                          : 'assets/imgs/logo.svg'
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-md-8 col-9">
                  <div class="text">
                    <!-- <p title="{{ survey.title }}" class="title-style">
                                                {{ survey?.title }}
                                              </p> -->
                    <div class="iconRow">
                      <div class="col-3 borderBox">
                        <i class="fas fa-calendar-alt iconSetting"></i>
                        <span class="surveyIconHeading">{{
                          "survey.survey" | translate
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <i
                          class="fas fa-heart iconSetting"
                          style="color: #f05c6d !important"
                        ></i>
                        <span class="surveyIconHeading">{{
                          survey?.likes
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <img
                          src="./assets/imgs/flame.svg"
                          class="status-icon"
                          alt=""
                        />
                        <span class="surveyIconHeading">{{
                          "home.trend" | translate
                        }}</span>
                      </div>
                      <div class="col-3 borderBox">
                        <i
                          class="fas fa-heart iconSetting"
                          style="color: #efa536 !important; right: 2px"
                        ></i>
                        <span class="surveyIconHeading">{{
                          "home.win" | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 overflow-hidden">
                  <h4 title="{{ survey.title }}" class="title-style">
                    {{ survey?.title }}
                  </h4>
                  <p class="mt-2 text-description fix-description">
                    {{ survey?.description }}
                  </p>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="timer d-flex">
                <app-counter
                  [dates]="{
                    startDate: survey?.startDate,
                    endDate: survey?.endDate
                  }"
                ></app-counter>

                <!-- <div>
                                            <h5>
                                              {{ survey?.days }}
                                              <span>{{ "general.days" | translate }}</span>
                                            </h5>
                                            :
                                            <h5>
                                              {{ survey?.hours }}
                                              <span>{{ "general.hours" | translate }}</span>
                                            </h5>
                                            :
                                            <h5>
                                              {{ survey?.minutes }}
                                              <span>{{ "general.mins" | translate }}</span>
                                            </h5>
                                            :
                                            <h5>
                                              {{ survey?.seconds }}
                                              <span>{{ "general.secs" | translate }}</span>
                                            </h5>
                                          </div> -->
                <div>
                  <button disabled class="m-btn m-btn-green">
                    {{ "home.details" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Liked Surveys -->

    <div
      class="tab-pane fade"
      id="likedSurvey"
      role="tabpanel"
      aria-labelledby="liked-tab"
    ></div>
  </div>
</div>
