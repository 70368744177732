import { HomeService } from "src/services/home.service";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "../../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { LanguageService } from "../../../services/language.service";
import { first, map, startWith } from "rxjs/operators";
// import {RecaptchaErrorParameters} from 'ng-recaptcha';
import { VerifyComponent } from "../verify/verify.component";
import { Country, ICountry } from "country-state-city";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {
  showLoader = false;
  onClose: any;
  form: FormGroup;
  countries: ICountry[] = [];
  countryFlag: string;
  countryCode: string;
  filteredOptions: any;

  language: string = 'en';

  constructor(
    public languageService: LanguageService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    public modal: BsModalRef,
    private homeService: HomeService
  ) { }

  ngOnInit() {
    this.language = this.languageService.getLanguageOrDefault();
    this.showLoader = false;
    this.form = this.formBuilder.group({
      // firstName: [null, Validators.required],
      email: [
        null,
        [
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      phoneNumber: [null, [Validators.required]],
      phoneCode: ["", [Validators.required]],
      password: [null, [Validators.required, Validators.pattern(/^.{6,16}$/)]],
      passwordConfirm: [
        null,
        [Validators.required, this.matchValues("password")],
      ],
      filterControl: [""],
      // captcha: false
    });
    this.getCountries();
    this.filteredOptions = this.form.get("filterControl").valueChanges.pipe(
      startWith(""),
      map((value: string) => {
        this.countries.forEach((option) => {
          option["show"] = option.phonecode
            .toLocaleLowerCase()
            .includes(value.toLowerCase());
        });
        return this.countries;
      })
    );
  }

  onPanelClose() {
    this.form.get("filterControl").setValue("");
  }

  countryCodeUpdate(e) {
    const code = e?.source?.selected?._element;
    this.countryFlag = code?.nativeElement?.dataset?.selectedcode;
    this.countryCode = this.form.get("phoneCode").value;
  }

  // +966508881119

  matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  close(Result = null) {
    this.onClose(Result);
  }

  // handleCorrectCaptcha(captchaResponse: string) {
  //   this.form.patchValue({
  //     captcha: (captchaResponse !== null)
  //   });
  // }

  // errored(errorDetails: RecaptchaErrorParameters): void {
  //   this.form.patchValue({
  //     captcha: false
  //   });
  // }

  processSignup() {
    if (this.form.valid || this.form.value.captcha) {
      if (!this.form.value.email && !this.form.value.phoneNumber) {
        this.translateService
          .get("signup.phone_or_email_are_required")
          .subscribe((text) => {
            this.toastr.error(text);
          });
        return false;
      }
      this.showLoader = true;
      const registerData = {
        email: this.form.value.email,
        phonecode: this.form.value.phoneCode,
        phoneNumber: this.form.value.phoneCode + this.form.value.phoneNumber,
        password: this.form.value.password,
        passwordConfirm: this.form.value.passwordConfirm,
      };
      this.authService
        .Register(registerData)
        .pipe(first())
        .subscribe(
          (result) => {
            this.showLoader = false;
            this.authService
              .sendSMSVerificationCode(
                this.form.value.phoneCode + this.form.value.phoneNumber,
                this.form.value.phoneCode,
                result.headers.get("AuthToken")
              )
              .subscribe((res) => {
                this.close({});
                this.verify(result.headers.get("AuthToken"));
              });
          },
          (err) => {
            this.showLoader = false;
            console.log(err);
            this.translateService
              .get("signup.register_error_message")
              .subscribe((text) => {
                this.toastr.error(err.error);
              });
          }
        );
    } else {
      for (let control in this.form.controls) {
        this.form.get(control).markAsDirty();
      }
    }
  }

  verify(token) {
    this.close({});
    const verifyModel = this.modalService.show(VerifyComponent, {
      class: "modal-dialog-centered login",
      backdrop: "static",
      initialState: {
        forget: false,
        authToken: token,
        phoneNumber: this.form.value.phoneNumber,
      },
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
      if (auth) {
      }
    };
  }

  getCountries() {
    this.countries = this.homeService.getCountries();
    // this.countries = Country.getAllCountries();
    console.log("this.countries: ", this.countries);
  }
}
