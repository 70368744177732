import { first } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { HomeService } from "src/services/home.service";
import { LanguageService } from "../../../services/language.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OwlOptions } from "ngx-owl-carousel-o";
import * as moment from "moment";
import { BsModalService } from "ngx-bootstrap/modal";
import { SurveyService } from "src/services/survey.service";
import { SignupComponent } from "../../partials/signup/signup.component";
import { ThankYouService } from "./../../../services/thankyou.service";
import { SurveyNameComponent } from "../survey-name/survey-name.component";
import { eLanguage } from "src/app/general/enums/language.enum";

@Component({
  selector: "app-thank-you",
  templateUrl: "./thank-you.component.html",
  styleUrls: ["./thank-you.component.css"],
})
export class ThankYouComponent implements OnInit {
  showLoader = false;
  isLiked: boolean = false;
  surveyRes: any;
  surveyId: any;
  isDisabled = false;
  thankYouData: any;
  id: any;
  surveys = [];
  url: any;
  title: any;
  description: any;
  slideConfig: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    autoplay: true,
    pullDrag: false,
    animateOut: "fadeOut",
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
        dots: false,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    nav: false,
  };
  services = [];
  currentService: any;

  selectedItemStatic = 0;
  survey = {
    all: [],
    featured: {
      all: [],
      display: [],
      displayLimit: 0,
    },

    mostViewed: {
      all: [],
      display: [],
      displayLimit: 0,
    },
    answers: {
      all: [],
      display: [],
      displayLimit: 0,
    },
    latest: {
      all: [],
      display: [],
      displayLimit: 0,
    },
    polls: {
      all: [],
      display: [],
      displayLimit: 0,
    },
  };
  statisticsConfig: OwlOptions = {
    loop: false,
    nav: true,
    navText: [
      "<i class='fas fa-angle-left'></i>",
      "<i class='fas fa-angle-right'></i>",
    ],
    dots: false,
    margin: 20,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 6,
      },
    },
  };
  statistics = [];
  language: string = eLanguage.English;

  partnersConfig: OwlOptions = {
    loop: false,
    nav: true,
    navText: [
      "<i class='fas fa-angle-left'></i>",
      "<i class='fas fa-angle-right'></i>",
    ],
    dots: false,
    margin: 20,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 5,
      },
    },
  };
  constructor(
    private router: Router,
    private ar: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private _ac: ActivatedRoute,
    private surveyService: SurveyService,
    private translateService: TranslateService,
    private homeService: HomeService,
    private thankYouService: ThankYouService,

    public languageService: LanguageService
  ) {
    const id = this._ac.snapshot.params;
    this.surveyId = atob(String(id.surveyId));
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    if (this.languageService.getLanguageOrDefault() === "ar") {
      this.slideConfig.rtl = true;
      this.statisticsConfig.rtl = true;
      this.partnersConfig.rtl = true;
    }
    console.log("language", this.language);
  }

  ngOnInit(): void {
    this.surveyId = atob(this.ar.snapshot.params.surveyId);
    this.showLoader = true;
    this.getAllSurveys();
    this.getThanks();
    // this.surveyId = this.surveyRes?._id;
  }

  onLikeClick() {
    this.showLoader = true;

    this.surveyService.likeSurvey(this.surveyId).subscribe((x) => {
      // this.translateService.get("Survey Liked").subscribe((text) => {
      //   this.toastr.success(text);
      //
      // });
      this.translateService
        .get("thankyou.success_message")
        .subscribe((text) => {
          this.toastr.success(text);
          this.showLoader = false;
        });
    });
  }
  getThanks() {
    this.thankYouService.getThankYou(this.surveyId).subscribe((result) => {
      if (this.language == "Arabic") {
        this.description = result?.descriptionArabic;
        this.title = result?.titleArabic;
        this.url = result?.url;
      } else {
        this.description = result?.description;
        this.title = result?.title;
        this.url = result?.url;
      }
      this.thankYouData = result;
      console.log("result Thanks", result);
    });
  }
  surveyName(survey: { id: any; surveyType: string; language: string }) {
    const id = survey.id;
    const lang = survey.language;
    console.log(survey, "surveyIdddd");
    this.surveyService
      .ClickedSurvey(survey)
      .pipe(first())
      .subscribe((result) => {
        console.log("result", result);
      });
    this.surveyService
      .getSurveyById(id)
      .pipe(first())
      .subscribe((result) => {
        this.showLoader = true;
        let values = {
          ...survey,
          ...result,
        };
        this.surveyService.createLocalSurveyModal(values);
        if (survey.surveyType == "Enterprise Survey") {
          this.router.navigate(["/surveys/enterprise"]);
        } else {
          this.router.navigate([`/survey/start/${id}/${lang}`]);
          // const verifyModel = this.modalService.show(SurveyNameComponent, {
          //   class: "modal-dialog-centered sign-up c-survey-modal",
          //   backdrop: "static",
          // });
          // verifyModel.content.onClose = (auth) => {
          //   verifyModel.hide();
          // };
        }
      });
  }
  slickInit(e) {
    console.log("slick initialized");
  }

  mouseEnter(service) {
    this.currentService = service;
  }

  getAllSurveys() {
    let data = [];
    this.surveyService.getAllSurvey(0, 6).subscribe(
      (result) => {
        data = result.filter((item: any) => {
          // Find the distance between now and the count down date
          let surveyFinished =
            new Date(item.endDate).getTime() - new Date().getTime();
          item["days"] = Math.floor(surveyFinished / (1000 * 60 * 60 * 24));
          item["hours"] = Math.floor(
            (surveyFinished % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          item["minutes"] = Math.floor(
            (surveyFinished % (1000 * 60 * 60)) / (1000 * 60)
          );
          item["seconds"] = Math.floor((surveyFinished % (1000 * 60)) / 1000);
          return (
            new Date(item.startDate) <= new Date() &&
            moment().isBefore(moment(item.endDate), "minute") &&
            item.isPublished
          );
        });
        // const slicedArray = data.slice(0, 9);
        // this.surveys.push(...data);
        this.survey.all = data?.length ? data : [];
        if (this.survey.all.length) {
          this.filterFeaturedSurvey();
          this.filterMostViwedSurvey();
          this.filterAnwseredSurvey();
          this.filterLatestSurvey();
          this.filterPollSurvey();
        }
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
      }
    );
  }
  filterFeaturedSurvey() {
    this.survey.featured.all = this.homeService.deepCopy(
      this.survey.all.filter((x) => x.isFeatured)
    );
    this.loadMoreSurvey("featured", "allFeatured");
  }

  filterMostViwedSurvey() {
    this.survey.mostViewed.all = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("mostViewed", "allMostViewed");
  }

  filterLatestSurvey() {
    this.survey.latest.all = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("latest", "allLatest");
  }

  filterAnwseredSurvey() {
    this.survey.answers.all = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("answers", "allAnswers");
  }

  filterPollSurvey() {
    this.survey.polls.all = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("polls", "allPolls");
  }

  loadMoreSurvey(key: string, masterKey?: string) {
    if (this.survey[key].display?.length === this.survey[key].all?.length) {
      this.survey[key].displayLimit = this.survey[key].all?.length;
      return;
    }

    this.survey[key].displayLimit += 6;

    let surveys = [];
    for (let i = 0; i < this.survey[key].displayLimit; i++) {
      if (this.survey[key].all[i]) {
        const survey = this.survey[key].all[i];
        if (survey) {
          surveys.push(survey);
        }
      } else {
        this.survey[key].displayLimit = this.survey[key].all?.length;
        break;
      }
    }
    this.survey[key].display = this.homeService.deepCopy(surveys);
  }

  viewAnsweredSurvey(survey: any) {
    this.router.navigate(["/survey/preview", btoa(survey.id)]);
  }
  async copyUrl() {
    console.log(this.survey);
    this.homeService.copySurveyUrl(
      this.surveyId,
      this.surveyRes?.surveyType,
      this.surveyRes?.language
    );
  }
  signup() {
    const loginModel = this.modalService.show(SignupComponent, {
      class: "modal-dialog-centered sign-up",
      backdrop: "static",
    });
    loginModel.content.onClose = (auth) => {
      loginModel.hide();
    };
  }
}
