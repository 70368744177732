import {
  Component,
  HostListener,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SurveyConstants } from "./../../general/constants/survey.constants";
import { Font, FontPickerDirective } from "ngx-font-picker";
import { FontsService } from "src/services/fonts.service";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { sha256 } from "js-sha256"; //Lib For Encription
import { ResponseCode } from "src/constant/response-code";
import { ColorPickerService, Cmyk } from "ngx-color-picker";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { HomeService } from "../../../services/home.service";
import { SurveyService } from "../../../services/survey.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { eLanguage } from "../../general/enums/language.enum";
import { LanguageService } from "src/services/language.service";
import { LoginComponent } from "src/app/partials/login/login.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { PricingComponent } from "../pricing/pricing.component";
import { Location } from "@angular/common";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ShareSurveyComponent } from "../share-survey/share-survey.component";
import { Subject } from "rxjs";
import { debounceTime, first } from "rxjs/operators";
import { PublicationModelComponent } from "../publication-model/publication-model.component";
import { environment } from "src/environments/environment";
import { RechargeService } from "src/services/recharge.service";
import { PluginService } from "src/services/pluginservice.service";
@Component({
  selector: "app-create-custom-forth-survey",
  templateUrl: "./create-custom-forth-survey.component.html",
  styleUrls: ["./create-custom-forth-survey.component.css"],
})
export class CreateCustomForthSurveyComponent implements OnInit {
  @ViewChild("fontPickerElement", { static: true })
  fontPicker: FontPickerDirective;
  totalBalance: any = 0;

  closeFontPicker(field: string): void {
    this.fontPicker.closeDialog();
  }
  private _presetFonts = [
    "Arial",
    "Times",
    "Courier",
    "Lato",
    "Open Sans",
    "Roboto Slab",
  ];
  isButtonDisabled = false;
  isSidebarVisible = true;
  surveyForm!: FormGroup;
  promo!: FormGroup;
  survey: any = null;
  private debounceSubject = new Subject();

  elanguage = eLanguage;
  questionRates: any;
  startTime: any;
  endTime: any;
  public font: Font = new Font({
    family: "noto-bd",
    size: "14px",
    style: "regular",
    styles: ["regular"],
  });
  public fonts: Font = new Font({
    family: "noto-bd",
    size: "14px",
    style: "regular",
    styles: ["regular"],
  });
  public sizeSelect: boolean = true;
  public sortFonts: boolean = false;
  public styleSelect: boolean = true;
  public toggle: boolean = false;

  public filteredFonts = [];
  public presetFonts = this._presetFonts;

  rangeValue: number = 0;
  userRsp: number = 0;
  config: number = 0;
  responseValue: number = 50;
  Cost = {
    spendingNow: 1500,
    costPerSurvey: 0,
    response: 500,
    perQuestion: 0,
  };

  ages = [
    "9 - 12 Years",
    "13 - 17 Years",
    "18 - 29 Years",
    "30 - 45 Years",
    "45 - 60 Years",
    "60 plus Years",
  ];
  gender = ["male", "female", "both"];
  codeType = ["add Code", "file"];
  desktopBrowser = [
    "Google Chrome",
    "Mozilla Firefox",
    "Apple Safari",
    "Microsoft Edge(Chromium)",
    "Opera",
    "Brave",
    "Vivaldi",
    "UC Browser",
    "Tor Browser",
    "Epic Privacy Browser",
  ];

  desktopBrowserSettings = [
    { value: "default", label: "Arial, sans-serif" },
    { value: "Helvetica, sans-serif", label: "Helvetica, sans-serif" },
    { value: "Verdana, sans-serif", label: "Verdana, sans-serif" },
    { value: "Tahoma, sans-serif", label: "Tahoma, sans-serif" },
    {
      value: "Times New Roman, sans-serif",
      label: "Times New Roman, sans-serif",
    },
    { value: "Georgia, sans-serif", label: "Georgia, sans-serif" },
    { value: "Courier New, monospace", label: "Courier New, monospace" },
    { value: "Trebuchet MS, sans-serif", label: "Trebuchet MS, sans-serif" },
  ];

  smallScreenBrowser = [
    "Google Chrome for Mobile",
    "Safari(iOS default browser)",
    "Mozilla Firefox for Mobile",
    "Samsung Internet Browser",
    "Microsoft Edge for Mobile",
    "Opera Mini",
    "UC Browser",
    "Brave for Mobile",
    "Dolphin Browser",
    "Puffin Browser",
  ];

  mainFontSettings = [
    { value: "default", label: "Arial, sans-serif" },
    { value: "Helvetica, sans-serif", label: "Helvetica, sans-serif" },
    { value: "Times New Roman, serif", label: "Times New Roman, serif" },
    // Add more font options as needed
  ];

  secondaryFontSettings = [
    { value: "default", label: "Arial, sans-serif" },
    { value: "Helvetica, sans-serif", label: "Helvetica, sans-serif" },
    { value: "Times New Roman, serif", label: "Times New Roman, serif" },
    // Add more font options as needed
  ];
  currentUser: any;

  advancedOptions = false;
  extractedNumber: any;
  color2: "#343456";
  promoCodeId: null;
  surveyImageSrc: string | ArrayBuffer | undefined;
  bgSurveyImageSrc: string | ArrayBuffer | undefined;
  Brand: any;
  language: string = "en";
  userStats: any;
  showPopup = false;
  @HostListener("window:storage", ["$event"])
  getTerminalId = environment.GET_TERMINAL_ID;
  getPassword = environment.GET_PASSWORD;
  getMerchantKey = environment.GET_MERCHANT_KEY;
  serverUrl = environment.SERVER_URL;
  constructor(
    private route: Router,
    private fb: FormBuilder,
    public authService: AuthService,
    private homeService: HomeService,
    private surveyService: SurveyService,
    private modalService: BsModalService,
    private ngZone: NgZone,
    private services: PluginService,

    private ac: ActivatedRoute,
    private translateService: TranslateService,
    private fontService: FontsService,
    private location: Location,
    private breakpointObserver: BreakpointObserver,
    private rechargeService: RechargeService,
    private toasterService: ToastrService,
    public languageService: LanguageService,
    public darkThemeService: DarkLiteThemeService
  ) {
    this.debounceSubject
      .pipe(debounceTime(1000)) // Adjust the debounce time as needed
      .subscribe(() => {
        if (this.rangeValue > this.currentUser?.totalRes && this.showPopup) {
          this.showPopup = false;
          this.modalService.show(PublicationModelComponent, {
            class: "c-survey-modal modal-dialog modal-dialog-centered sign-up",
            backdrop: "static",
            initialState: {
              rangeValue: this.rangeValue - this.currentUser?.totalRes,
              spendingPoint: this.config,
              // surveyRes: this.totalResponse
            },
          });
        }
      });
  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguageOrDefault();
    this.Brand = this.surveyService.getEditBrand();

    this.currentUser = this.authService.currentUser;
    const time = new Date();
    const currentHour = time.getHours();
    const currentMinute = time.getMinutes();
    this.startTime = `${String(currentHour).padStart(2, "0")}:${String(
      currentMinute
    ).padStart(2, "0")}`;
    this.endTime = `${String(currentHour).padStart(2, "0")}:${String(
      currentMinute
    ).padStart(2, "0")}`;
    const survey = this.surveyService.getLocalSurvey();
    if (!survey) {
      this.route.navigate(["/"]);
    }
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => {
        if (result.matches) {
          this.isSidebarVisible = false;
        }
      });
    this.createSurveyFrom(survey);
    this.promoForm();
    this.getUserAllStats();
    this.rechargingWallet();
  }

  handleStorageChange(event: StorageEvent) {
    this.ngZone.run(() => {
      // Inside the NgZone to trigger change detection
      console.log("Storage event occurred:", event);
      if (event.key === "myKey") {
        // Handle changes to the specific key 'myKey'
        console.log('Value of "myKey" changed:', event.newValue);
        // Update your UI or perform other actions as needed
      }
    });
  }
  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
  private createSurveyFrom(data?: any) {
    this.surveyForm = this.fb.group({
      id: new FormControl(data?._id || data?.id ? data._id || data?.id : null),
      title: new FormControl(data.title ? data.title : "", [
        Validators.required,
      ]),
      description: new FormControl(data.description ? data.description : "", [
        Validators.required,
      ]),
      language: new FormControl(data.language ? data.language : "English", [
        Validators.required,
      ]),
      surveyImageUrl: [data.surveyImageUrl],
      startDate: new FormControl(data.startDate ? data.startDate : "", [
        Validators.required,
      ]),
      endDate: new FormControl(data.endDate ? data.endDate : "", [
        Validators.required,
      ]),
      startTime: new FormControl(
        data.startTime ? data.startTime : this.startTime,
        [Validators.required]
      ),
      endTime: new FormControl(data.endTime ? data.endTime : this.endTime, [
        Validators.required,
      ]),

      isPublished: true,
      isFeatured: false,
      // questionView: new FormGroup({
      //   desktopBrowserSettings: new FormControl("Google Chrome"),
      //   smallScreenBrowserSettings: new FormControl("Google Chrome for Mobile"),
      //   mainFontFamily: new FormControl("noto-bd"),
      //   mainFontSize: new FormControl("14px"),
      //   mainFontStyle: new FormControl("regular"),
      //   secondaryFontFamily: new FormControl("noto-bd"),
      //   secondaryFontSize: new FormControl("14px"),
      //   secondaryFontStyle: new FormControl("regular"),
      //   surveyBackgroundColor: new FormControl("#343456"),
      // }),
      surveyImage: new FormControl(null),
      gender: new FormControl(null),
      age: new FormControl(null),
      bgSurveyImage: new FormControl(null),
      contactListFile: this.fb.array([]),
      publicOrPrivate: [false],
      publicOrPrivatePromotion: [false],
      publicationCost: [false],
      genderAndTarget: [false],
      // promote: new FormControl(false),
      // kafuNetwork: new FormControl(false),
      agreeOnTermsCondition: new FormControl(false, Validators.required),
      agreePolicy: new FormControl(false, Validators.required),
      advanced: new FormControl(false),
      multiQuestList: [data.multiQuestList],
    });
  }

  private promoForm() {
    this.promo = this.fb.group({
      title: new FormControl("", [Validators.required]),
      titleArabic: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
      descriptionArabic: new FormControl("", [Validators.required]),
      type: new FormControl("add Code", [Validators.required]),
      image: "",
      file: "",
      codes: "",
    });
  }
  async promoCode(data) {
    // Check if the form is valid
    if (!this.authService.currentUser) {
      const loginModel = this.modalService.show(LoginComponent, {
        class: "modal-dialog-centered login",
        backdrop: "static",
      });
      loginModel.content.onClose = (auth) => {
        loginModel.hide();
      };
    } else if (data.valid) {
      if (data.value.type == "file") {
        const res: any = await this.surveyService.promoCodeSubmit(data.value);

        console.log("res:", res?.body?._id);

        this.promoCodeId = res?.body?._id;
        this.translateService.get("created").subscribe((text) => {
          this.toasterService.success(text);
        });
      } else {
        const { file, codes, ...rest } = data.value;
        this.surveyService
          .createPromoCode({ codes: codes.split(","), ...rest })
          .subscribe((res) => {
            this.promoCodeId = res?._id;
            this.translateService.get("created").subscribe((text) => {
              this.toasterService.success(text);
            });
          });
      }
    } else {
      // Form is not valid, handle validation error
      this.translateService
        .get("invalid  promo form data")
        .subscribe((text) => {
          this.toasterService.error(text);
        });
    }
  }

  // Remove a code input field

  onRangeChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.rangeValue = parseInt(inputElement.value, 10);
    // this.config = this.rangeValue;
    if (this.rangeValue > this.currentUser?.totalRes) {
      this.userRsp = this.rangeValue - this.currentUser?.totalRes;
      this.config = this.rangeValue + this.userRsp;
    } else {
      this.config = this.rangeValue;
      this.userRsp = this.currentUser?.totalRes;
    }
  }

  surveyFormValidations = {
    title: [{ type: "required", message: "Title is required" }],
    description: [{ type: "required", message: "Description is required" }],
    language: [{ type: "required", message: "Language is required" }],
    startDate: [{ type: "required", message: "Start Date is required" }],
    endDate: [{ type: "required", message: "End Date is required" }],
    startTime: [{ type: "required", message: "Start Time is required" }],
    endTime: [{ type: "required", message: "End Time is required" }],
    agreePolicy: [{ type: "required", message: "agreePolicy is required" }],
    agreeOnTermsCondition: [
      { type: "required", message: "Terms&Condition: is required" },
    ],
    // rasterColors1: [
    //   { type: "required", message: "Raster Colors 1 is required" },
    // ],
    // rasterColors2: [
    //   { type: "required", message: "Raster Colors 2 is required" },
    // ],
    // desktopBrowserSettings: [
    //   { type: "required", message: "Desktop Browser Settings is required" },
    // ],
    // smallScreenBrowserSettings: [
    //   {
    //     type: "required",
    //     message: "Small Screen Browser Settings is required",
    //   },
    // ],
    // mainFontSettings: [
    //   { type: "required", message: "Main Font Settings is required" },
    // ],
    // secondaryFontSettings: [
    //   { type: "required", message: "Secondary Font Settings is required" },
    // ],
  };

  promoValidation = {
    title: [{ type: "required", message: "Title is required" }],
    titleArabic: [{ type: "required", message: "Title is required" }],
    description: [{ type: "required", message: "Title is required" }],
    descriptionArabic: [
      { type: "required", message: "Description is required" },
    ],
  };

  get getContactList(): FormArray {
    return this.surveyForm.get("contactListFile") as FormArray;
  }

  async getQuestionRates() {
    const rates = await this.homeService.getQuestionRate().toPromise();
    this.questionRates = rates?.length ? rates[0] : {};
    // this.setQuestionRate();
    // this.calculateSurveyCost();
  }

  onFontChange(event: any) {
    // You can perform further actions based on the selected font here
    console.log("Selected font:", event);
    // this.surveyForm.get("mainFontSettings").setValue(event?.family)
    this.surveyForm
      .get("questionView")
      .get("mainFontFamily")
      .setValue(event?.family);
    this.surveyForm
      .get("questionView")
      .get("mainFontSize")
      .setValue(event?.size);
    this.surveyForm
      .get("questionView")
      .get("mainFontStyle")
      .setValue(event?.style);
    // closeDialog()
    this.fontPicker.closeDialog();
    // Perform any other necessary actions with the selected font
  }
  onSecondFontChange(event: any) {
    // You can perform further actions based on the selected font here
    console.log("Selected font:", event?.family);
    this.surveyForm
      .get("questionView")
      .get("secondaryFontFamily")
      .setValue(event?.family);
    this.surveyForm
      .get("questionView")
      .get("secondaryFontSize")
      .setValue(event?.size);
    this.surveyForm
      .get("questionView")
      .get("secondaryFontStyle")
      .setValue(event?.style);
    // closeDialog()
    this.fontPicker.closeDialog();
    // Perform any other necessary actions with the selected font
  }

  onContactListFileChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];

    if (file) {
      console.log("File:", file);
      console.log("File Name:", file.name);
      this.addContactListFile(file);
    }
  }
  onEventBackGround(event: string): void {
    this.surveyForm
      .get("questionView")
      .get("surveyBackgroundColor")
      .setValue(event);
  }
  addContactListFile(image: File | null = null) {
    const imageControl = this.fb.control(image, [Validators.required]);
    this.getContactList.push(imageControl);

    if (image) {
      this.previewContactListFile(image, this.getContactList.length - 1);
    }
  }

  removeContactListFile(index: number) {
    this.getContactList.removeAt(index);
  }

  previewContactListFile(file: File, index: number) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileWithMetadata = {
        file: file,
        name: file.name, // Extract the file name
      };
      this.getContactList.at(index).setValue(fileWithMetadata);
      // Add code to preview the image if needed
    };
    reader.readAsDataURL(file);
  }

  onFileSurveyImageChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];

    if (file) {
      // Read the selected file and set it to the FormControl
      const reader = new FileReader();
      reader.onload = (e) => {
        this.surveyImageSrc = e.target?.result;

        this.promo.get("image")?.setValue(e.target?.result);
      };
      reader.readAsDataURL(file);
    }
  }

  onFileChangeBgSurveyImage(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];

    if (file) {
      // Read the selected file and set it to the FormControl
      const reader = new FileReader();
      reader.onload = (e) => {
        this.promo.get("file")?.setValue(file);
      };
      reader.readAsDataURL(file);
    }
  }
  getFormattedToday(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  getFormattedStartDate(): string {
    const startDate = this.surveyForm.get("startDate").value;

    // Add your logic to format the startDate (assuming it's in 'YYYY-MM-DD' format)
    if (startDate) {
      // Calculate the next date by adding one day to the startDate
      const nextDate = new Date(startDate);

      nextDate.setDate(nextDate.getDate() + 1);
      const today = new Date(nextDate);
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");

      // Format the next date using DatePipe

      return `${year}-${month}-${day}`;
    }
  }

  removeImageSurveyImage() {
    this.surveyForm.get("surveyImage")?.setValue(null); // Clear the bgImage FormControl
    this.surveyImageSrc = null; // Clear the image source
  }
  removeImageBgSurveyImage() {
    this.surveyForm.get("bgSurveyImage")?.setValue(null); // Clear the bgImage FormControl
    this.bgSurveyImageSrc = null; // Clear the image source
  }

  shouldShowError(
    input?: any,
    validation?: { type: string; message: string }
  ): string {
    if (input?.hasError(validation?.type) && (input?.dirty || input?.touched)) {
      return "block";
    } else {
      return "none";
    }
  }

  checkValidations(formGroup: FormGroup): void {
    if (formGroup.invalid) {
      formGroup.markAllAsTouched();
    }
  }

  getUserAllStats() {
    if (this.authService.currentUser) {
      // this.showLoader = true;
      this.homeService.getMySurvey().subscribe((result) => {
        this.userStats = result.filter((item: any) => item.isPublished);
        // this.showLoader = false;
      });
    }
  }

  back(): void {
    this.route.navigate(["/create-second-custom-survey"]);
  }

  submitQuestion() {
    if (this.surveyForm.invalid) {
      this.surveyForm.markAllAsTouched();
      return;
    }

    console.log("form", this.surveyForm.value);
  }
  extractSurveyNumbers(conditions: string[]): number | null {
    for (const condition of conditions) {
      const match = condition.match(/(\d+)/);
      if (match && condition.toLowerCase().includes("survey")) {
        return parseInt(match[0], 10);
      }
    }

    return null;
  }
  publish(formGroup: any) {
    let surveyLimit;
    this.isButtonDisabled = true;
    // this.getUserAllStats()
    if (formGroup.invalid) {
      console.log("mmadsnf");
      formGroup.markAllAsTouched();
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 3000); // 3000 milliseconds = 3 seconds
      return this.translateService
        .get("survey.select start date")
        .subscribe((text) => {
          this.toasterService.warning(text);
        });
    }
    if (
      !this.surveyForm.value.agreeOnTermsCondition ||
      !this.surveyForm.value.agreePolicy
    ) {
      return this.translateService
        .get("survey.select terms")
        .subscribe((text) => {
          this.toasterService.warning(text);
          // Optionally, re-enable the button after a certain time (for example, 3 seconds)
          setTimeout(() => {
            this.isButtonDisabled = false;
          }, 3000); // 3000 milliseconds = 3 seconds
        });
    }
    if (this.authService.currentUser?.planId) {
      surveyLimit = this.extractSurveyNumbers(
        this.authService?.currentUser?.planId?.description
      );
    }
    if (!this.authService.currentUser) {
      const loginModel = this.modalService.show(LoginComponent, {
        class: "modal-dialog-centered login",
        backdrop: "static",
      });
      loginModel.content.onClose = (auth) => {
        loginModel.hide();
      };
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 3000); // 3000 milliseconds = 3 seconds
    } else if (this.authService.currentUser?.subscriptionStatus == "expired") {
      this.translateService.get("survey.expired").subscribe((text) => {
        this.toasterService.error(text);
      });
      const PlanModel = this.modalService.show(PricingComponent, {
        class: "modal-dialog1 modal-content1",
        backdrop: "static",
      });
      PlanModel.content.onClose = () => {
        PlanModel.hide();
      };
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 3000);
    } else if (!this.authService.currentUser?.planId) {
      const currentUrl = this.location.path();
      this.homeService.markIsRedirect(currentUrl);
      const PlanModel = this.modalService.show(PricingComponent, {
        class: "modal-dialog1 modal-content1",
        backdrop: "static",
      });
      PlanModel.content.onClose = () => {
        PlanModel.hide();
      };
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 3000); // 3000 milliseconds = 3 seconds
    } else if (this.homeService?.isNotLogin) {
      this.homeService?.resetIsNotLogin();
      const currentUrl = this.location.path();
      this.homeService.markIsRedirect(currentUrl);
      const PlanModel = this.modalService.show(PricingComponent, {
        class: "modal-dialog1 modal-content1",
        backdrop: "static",
      });
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 3000); // 3000 milliseconds = 3 seconds
      PlanModel.content.onClose = () => {
        PlanModel.hide();
      };
    } else if (
      this.userStats?.length &&
      surveyLimit !== null &&
      this.userStats?.length >= surveyLimit &&
      this.authService?.currentUser?.planId?.type === "free"
    ) {
      const currentUrl = this.location.path();
      this.homeService.markIsRedirect(currentUrl);
      const PlanModel = this.modalService.show(PricingComponent, {
        class: "modal-dialog1 modal-content1",
        backdrop: "static",
      });
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 3000); // 3000 milliseconds = 3 seconds
      PlanModel.content.onClose = () => {
        PlanModel.hide();
      };
    } else if (!this.advancedOptions) {
      const data = {
        title: this.surveyForm.value.title,
        id: this.surveyForm.value.id,
        surveyImageUrl: this.surveyForm.value.surveyImageUrl,
        description: this.surveyForm.value.description,
        language: this.surveyForm.value.language,
        startDate: this.surveyForm.value.startDate,
        endDate: this.surveyForm.value.endDate,
        startTime: this.surveyForm.value.startTime,
        endTime: this.surveyForm.value.endTime,
        isPublished: this.surveyForm.value.isPublished,
        brandManagementId: this.Brand ? this.Brand?._id : null,
        promoCodeId: this.promoCodeId,
        multiQuestList: this.surveyForm.value.multiQuestList,
        surveyType: "Survey",
        totalResponse: 0,
      };

      this.surveyService.saveSurvey(data).subscribe((res) => {
        this.translateService
          .get("survey.success publish")
          .subscribe((text) => {
            this.toasterService.success(text);
          });
        // Optionally, re-enable the button after a certain time (for example, 3 seconds)
        setTimeout(() => {
          this.isButtonDisabled = false;
        }, 3000); // 3000 milliseconds = 3 seconds
        this.route.navigate(["/"]);
      });
    } else if (
      this.rangeValue > this.authService.currentUserData?.totalRes &&
      this.rangeValue > this.authService.currentUserData?.totalBalance
    ) {
      this.translateService.get("newSurvey.detailsText").subscribe((text) => {
        this.toasterService.error(text);
      });
    } else {
      const data = {
        ...this.surveyForm.value,
        brandManagementId: this.Brand ? this.Brand?._id : null,
        totalResponse: this.config,
        spendingSar: this.rangeValue,
        userRsp: this.userRsp,
        promoCodeId: this.promoCodeId,
        surveyType: "Survey",
      };
      this.surveyService.saveSurvey(data).subscribe((res) => {
        this.translateService
          .get("survey.success publish")
          .subscribe((text) => {
            this.toasterService.success(text);
          });

        // Optionally, re-enable the button after a certain time (for example, 3 seconds)
        setTimeout(() => {
          this.isButtonDisabled = false;
        }, 3000); // 3000 milliseconds = 3 seconds
        this.route.navigate(["/"]);
      });
    }
    // }
  }

  backButton() {
    // Optionally, re-enable the button after a certain time (for example, 3 seconds)
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 3000); // 3000 milliseconds = 3 seconds
    this.route.navigate(["/create-second-custom-survey"]);
  }
  advanced() {
    this.advancedOptions = !this.advancedOptions;
  }
  PreviewData() {
    this.route.navigate(["/create-third-custom-survey"]);
  }
  generateHash(arg: string) {
    // hashSequence = posted["trackid"]+"|"+posted["terminalId"]+"|"+posted["password"]+"|"+posted["secret"]+"|"+posted["amount"]+"|"+posted["currency"];
    var hashSequence = arg;
    return sha256(hashSequence);
  }
  getOrderId() {
    return Math.random().toString().slice(2, 5);
  }

  rechargingWallet() {
    this.ac.queryParams.subscribe((params) => {
      if (Object.keys(params).length === 0) {
        console.warn(
          "No query parameters found. Skipping rechargingWallet function."
        );
        return;
      }
      const {
        AuthCode,
        ECI,
        PaymentId,
        PaymentType,
        RRN,
        ResponseCode,
        Result,
        TrackId,
        TranId,
        UserField1,
        UserField3,
        amount,
        cardBrand,
        maskedPAN,
        responseHash,
      } = params;

      const data = {
        PaymentId,
        cardType: cardBrand,
        plan: UserField3,
        PaymentType,
        responseHash,
        amount: Number(amount),
        trackingId: TrackId,
        transactionId: TranId,
      };

      if (ResponseCode === "000") {
        this.handleSuccessfulRecharge(data);
        window.location.assign(
          `${environment.app_url}/create-forth-custom-survey`
        );
      } else if (Result) {
        this.handleFailedRecharge(Result);
        window.location.assign(
          `${environment.app_url}/create-forth-custom-survey`
        );
      } else {
      }
    });
  }

  private handleSuccessfulRecharge(data: any) {
    this.rechargeService
      .userRechargeWallet(data)
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.authService.setWalletRates(result);
          // this.showSuccessMessage();
          // Additional logic if needed
        } else {
          this.showErrorMessage("wallet.recharge_error_message");
        }
      });
  }

  private handleFailedRecharge(result: string) {
    this.showErrorMessage("wallet.failure");
    // this.toasterService.error(result);
  }

  private showErrorMessage(messageKey: string) {
    this.translateService.get(messageKey).subscribe((text) => {
      this.toasterService.error(text);
    });

    // Redirect to the desired location
    window.location.assign(`${environment.app_url}/create-forth-custom-survey`);
  }
  recharging() {
    let num = this.rangeValue - this.currentUser?.totalRes;
    let trackingId = this.getOrderId();
    // hashSequence = posted["trackid"]+"|"+posted["terminalId"]+"|"+posted["password"]+"|"+posted["secret"]+"|"+posted["amount"]+"|"+posted["currency"];
    let hashSequence =
      trackingId +
      "|" +
      this.getTerminalId +
      "|" +
      this.getPassword +
      "|" +
      this.getMerchantKey +
      "|" +
      num +
      "|" +
      "SAR";
    console.log(hashSequence, "hashSequence");
    let hash = this.generateHash(hashSequence);
    let paymentForm = {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      trackid: trackingId,
      terminalId: this.getTerminalId,
      customerEmail:
        this.authService.currentUser?.email || "support@kafuapp.com",
      action: "1",
      merchantIp: "9.10.10.102",
      password: this.getPassword,
      currency: "SAR",
      country: "SA",
      transid: "",
      tokenOperation: "",
      cardToken: "",
      tokenizationType: "0",
      amount: num,
      requestHash: hash,
      udf1: "create-forth-custom-survey",
      udf2: "create-forth-custom-survey",
      udf3: "",
      udf4: "plan.id",
      udf5: "plan.id",
    };
    debugger;
    this.services.paymentAPI(paymentForm).subscribe(async (res) => {
      let getResponse = JSON.parse(JSON.stringify(res));
      if (ResponseCode.response_msg[getResponse["responseCode"]]) {
        console.log(getResponse, "getResponse");
        this.route.navigate(["payment-status"], {
          queryParams: getResponse,
        });
      } else {
        window.location.assign(
          getResponse["targetUrl"].replace("?", "") +
            "?paymentid=" +
            getResponse["payid"]
        );
      }
      // this.redirectURL =
      //   getResponse["targetUrl"].replace("?", "") +
      //   "?paymentid=" +
      //   getResponse["payid"];
    });
  }
}
