import { Component, OnInit } from "@angular/core";
import { DarkLiteThemeService } from "../../../services/dark-lite-themeService.service";

@Component({
  selector: "app-new-finished-survey",
  templateUrl: "./new-finished-survey.component.html",
  styleUrls: ["./new-finished-survey.component.css"],
})
export class NewFinishedSurveyComponent implements OnInit {
  rating = 0;
  activeWidth: any;
  constructor(public darkThemeService: DarkLiteThemeService) {}

  ngOnInit(): void {}
  toggleCheck(index: number) {
    this.rating = index + 1;
    this.activeWidth = (index + 1) * (100 / this.rating) + "%";
  }
  // In your component class
  getLineWidth(rating: number): number {
    switch (rating) {
      case 1:
        return 5;
      case 2:
        return 30;
      case 3:
        return 50;
      case 4:
        return 77;
      case 5:
        return 100;
      default:
        return 0;
    }
  }
}
