import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { sha256 } from "js-sha256"; //Lib For Encription
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ResponseCode } from "src/constant/response-code";
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';
import { DarkLiteThemeService } from 'src/services/dark-lite-themeService.service';
import { LanguageService } from 'src/services/language.service';
import { PluginService } from 'src/services/pluginservice.service';
import { RechargeService } from 'src/services/recharge.service';
@Component({
  selector: 'app-publication-model',
  templateUrl: './publication-model.component.html',
  styleUrls: ['./publication-model.component.css']
})
export class PublicationModelComponent implements OnInit {
  onClose: any;
  rangeValue: any
  survey: any;
  surveyRes: any;
  surveyId: any;
  spendingPoint: any
  getTerminalId = environment.GET_TERMINAL_ID;
  getPassword = environment.GET_PASSWORD;
  getMerchantKey = environment.GET_MERCHANT_KEY
  serverUrl = environment.SERVER_URL
  constructor(public darkThemeService: DarkLiteThemeService, private languageService: LanguageService,
    private translateService: TranslateService, private renderer: Renderer2, public modal: BsModalRef,
    private authService: AuthService,
    private services: PluginService,
    private route: Router,
    private rechargeService: RechargeService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
  }
  close() {
    this.onClose();
  }

  generateHash(arg: string) {
    // hashSequence = posted["trackid"]+"|"+posted["terminalId"]+"|"+posted["password"]+"|"+posted["secret"]+"|"+posted["amount"]+"|"+posted["currency"];
    var hashSequence = arg;
    return sha256(hashSequence);
  }
  getOrderId() {
    return Math.random().toString().slice(2, 5);
  }
  recharging() {
    // if (this.isChecked === false) {
    //   this.translateService
    //     .get("plan.error_message")
    //     .subscribe((text) => {
    //       this.toastr.error(text);
    //     });
    // }
    let trackingId = this.getOrderId()
    // hashSequence = posted["trackid"]+"|"+posted["terminalId"]+"|"+posted["password"]+"|"+posted["secret"]+"|"+posted["amount"]+"|"+posted["currency"];
    let hashSequence = trackingId + "|" + this.getTerminalId + "|" + this.getPassword + "|" + this.getMerchantKey + "|" + this.rangeValue + "|" + "SAR";
    console.log(hashSequence, "hashSequence")
    let hash = this.generateHash(hashSequence);
    let paymentForm = {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      trackid: trackingId,
      terminalId: this.getTerminalId,
      customerEmail: this.authService.currentUser?.email || "support@kafuapp.com",
      action: "1",
      merchantIp: "9.10.10.102",
      password: this.getPassword,
      currency: "SAR",
      country: "SA",
      transid: "",
      tokenOperation: "",
      cardToken: "",
      tokenizationType: "0",
      amount: this.rangeValue,
      requestHash: hash,
      udf1: "create-forth-custom-survey",
      udf2: "create-forth-custom-survey",
      udf3: "",
      udf4: "plan.id",
      udf5: "plan.id",
    };
    debugger;
    this.services.paymentAPI(paymentForm).subscribe(async (res) => {
      let getResponse = JSON.parse(JSON.stringify(res));
      if (ResponseCode.response_msg[getResponse["responseCode"]]) {
        console.log(getResponse, "getResponse");
        this.route.navigate(["payment-status"], {
          queryParams: getResponse,
        });
      } else {
        window.location.assign(
          getResponse["targetUrl"].replace("?", "") +
          "?paymentid=" +
          getResponse["payid"]
        );

      }
      // this.redirectURL =
      //   getResponse["targetUrl"].replace("?", "") +
      //   "?paymentid=" +
      //   getResponse["payid"];
    });
  }
}
