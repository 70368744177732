<div
  [ngClass]="{
    'dark-mode': darkThemeService.getCurrentTheme === 'dark-mode',
    'light-mode': darkThemeService.getCurrentTheme === 'light-mode',
    'd-flex': authService.isLoggedIn() == true,
    'd-block': authService.isLoggedIn() == false
  }"
>
  <app-header *ngIf="!authService.isLoggedIn()"></app-header>
  <app-new-sidebar
    style="border: 1px solid gray"
    class="side-bar"
    [ngClass]="{
      'sidebar-show': isSidebarVisible,
      'sidebar-hide': !isSidebarVisible,
      'border-ar': this.languageService.getLanguageOrDefault() === 'ar',
      'border-en': this.languageService.getLanguageOrDefault() !== 'ar'
    }"
    *ngIf="authService.isLoggedIn()"
  ></app-new-sidebar>
  <div
    class=""
    *ngIf="authService.isLoggedIn()"
    [ngClass]="
      this.languageService.getLanguageOrDefault() === 'ar'
        ? 'humburg-ar'
        : 'humburg'
    "
    (click)="toggleSidebar()"
  >
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 18L20 18"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M4 12L20 12"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M4 6L20 6"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>
  <div
    class="w-100"
    [ngClass]="{ 'adjust-height': authService.isLoggedIn() == true }"
  >
    <div class="pg-head">
      <div class="container-fluid">
        <div class="d-flex justify-content-between">
          <div>
            <h1 class="title">{{ "home.mySurveys" | translate }}</h1>
            <div class="kf-breadcrumb">
              <ul>
                <li>
                  <a>{{ "profile.Home" | translate }}</a>
                </li>
                <li>
                  <a>{{ "home.mySurveys" | translate }}</a>
                </li>
                <!-- <li>
                <a>{{ "home.mySurveys" | translate }}</a>
              </li> -->
              </ul>
            </div>
          </div>
          <div>
            <button class="m-btn m-btn-gradient" (click)="createSurvery()">
              {{ "home.createSurvey" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid w-100">
      <div class="survyes-container">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="featured-tab"
              data-toggle="tab"
              href="#featured"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              (click)="tabChange(0, 9, 'isFeatured')"
              >{{ "home.featuredSurvey" | translate }}
              <i class="fas fa-star"></i
            ></a>
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="mostViewd-tab"
              data-toggle="tab"
              href="#mostViewd"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              (click)="tabChange(0, 9, 'isPublished')"
              >{{ "home.mostViewed" | translate }}</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="answers-tab"
              data-toggle="tab"
              href="#answers"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              (click)="tabChange(0, 9, 'isPublished')"
              >{{ "home.answers" | translate }}</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="latestSurvyes-tab"
              data-toggle="tab"
              href="#latestSurvyes"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              (click)="tabChange(0, 9, 'latest')"
              >{{ "home.latestSurvyes" | translate }}</a
            >
          </li>
          <!-- <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="polls-tab"
            data-toggle="tab"
            href="#polls"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
            >polls</a
          >
        </li> -->
        </ul>
        <!-- <div class="filter">
        <button class="m-btn m-btn-green">filter</button>
      </div> -->
      </div>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="featured"
          role="tabpanel"
          aria-labelledby="featured-tab"
        >
          <div class="row" *ngIf="survey.featured?.length">
            <ng-container *ngFor="let survey of survey.featured; let i = index">
              <div class="col-lg-4 col-md-6 col-12" *ngIf="survey">
                <!-- <div class="survey-card shadow">
                <div class="top">
                  <div class="d-flex">
                    <div class="comp-logo">
                      <img src="assets/imgs/logo.svg" alt="" />
                      <span>
                        <i class="fas fa-star"></i>
                      </span>
                    </div>
                    <div class="text" style="overflow: auto">
                      <p title="{{ survey.title }}" class="title-style">{{ survey.title }}</p>
                      <h6>
                        <i class="fas fa-calendar-alt"></i>
                        {{ "survey.survey" | translate }}
                      </h6>
                      <h6>
                        <i class="fas fa-heart"></i>
                        {{ survey?.likes }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="timer d-flex">
                    <div class="display-contents">
                      <div class="circle text-capitalize">
                        <img src="./assets/imgs/green-clipboard-list.svg" alt="" />
                        <span>{{ "survey.survey" | translate }}</span>
                      </div>

                      <div class="circle text-capitalize">
                        <img src="./assets/imgs/red-heart.svg" alt="" />
                        <span>{{ survey?.likes }}</span>
                      </div>

                      <div class="circle text-capitalize">
                        <img src="./assets/imgs/colored-calender.svg" alt="" />
                        <span>{{ survey?.days }}
                          {{ "general.days" | translate }}</span>
                      </div>

                      <div class="circle text-capitalize">
                        <img src="./assets/imgs/colored-tag.svg" alt="" />
                        <span>{{ "home.win" | translate }}</span>
                      </div>
                    </div>
                    <div>
                      <button class="m-btn m-btn-green" (click)="startSurvey(survey)" *ngIf="user">
                        {{ "general.Detail" | translate }}
                      </button>
                      <button class="m-btn m-btn-green" (click)="startSurvey(survey)" *ngIf="!user">
                        {{ "general.Start" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
                <div class="survey-card" *ngIf="survey">
                  <div class="top">
                    <div class="row">
                      <div class="col-md-4 col-3">
                        <div class="comp-logo">
                          <img
                            [src]="
                              survey?.surveyImage
                                ? survey?.surveyImage
                                : 'assets/imgs/logo.svg'
                            "
                            alt=""
                          />
                        </div>
                      </div>

                      <div class="col-md-8 col-9">
                        <div class="text">
                          <!-- <p title="{{ survey.title }}" class="title-style">
                                                      {{ survey?.title }}
                                                    </p> -->
                          <div class="iconRow">
                            <div class="col-3 borderBox">
                              <i class="fas fa-calendar-alt iconSetting"></i>
                              <span class="surveyIconHeading">{{
                                "survey.survey" | translate
                              }}</span>
                            </div>
                            <div class="col-3 borderBox">
                              <i
                                class="fas fa-heart iconSetting"
                                style="color: #f05c6d !important"
                              ></i>
                              <span class="surveyIconHeading">{{
                                survey?.likes
                              }}</span>
                            </div>
                            <div class="col-3 borderBox">
                              <img
                                src="./assets/imgs/flame.svg"
                                class="status-icon"
                                alt=""
                              />
                              <span class="surveyIconHeading">{{
                                "home.trend" | translate
                              }}</span>
                            </div>
                            <div class="col-3 borderBox">
                              <i
                                class="fas fa-heart iconSetting"
                                style="color: #efa536 !important; right: 2px"
                              ></i>
                              <span class="surveyIconHeading">{{
                                "home.win" | translate
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row overflow-hidden">
                      <div class="col-12 overflow-hidden">
                        <h4 title="{{ survey.title }}" class="title-style">
                          {{ survey?.title }}
                        </h4>
                        <p class="mt-2 text-description fix-description">
                          {{ survey?.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="timer d-flex">
                      <app-counter
                        [dates]="{
                          startDate: survey?.startDate,
                          endDate: survey?.endDate
                        }"
                      ></app-counter>

                      <!-- <div>
                                                  <h5>
                                                    {{ survey?.days }}
                                                    <span>{{ "general.days" | translate }}</span>
                                                  </h5>
                                                  :
                                                  <h5>
                                                    {{ survey?.hours }}
                                                    <span>{{ "general.hours" | translate }}</span>
                                                  </h5>
                                                  :
                                                  <h5>
                                                    {{ survey?.minutes }}
                                                    <span>{{ "general.mins" | translate }}</span>
                                                  </h5>
                                                  :
                                                  <h5>
                                                    {{ survey?.seconds }}
                                                    <span>{{ "general.secs" | translate }}</span>
                                                  </h5>
                                                </div> -->
                      <div>
                        <button
                          class="m-btn m-btn-green"
                          (click)="startSurvey(survey)"
                        >
                          {{ "general.Start" | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div
            class="row justify-content-center"
            *ngIf="count > 9 && survey.featured.length < count"
          >
            <div class="col-md-3">
              <button
                class="m-btn m-btn-gradient"
                (click)="
                  newLoadMore(
                    'featured',
                    'isFeatured',
                    survey.featured,
                    currentPage
                  )
                "
              >
                {{ "survey.loadMore" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="mostViewd"
          role="tabpanel"
          aria-labelledby="mostViewd-tab"
        >
          <div class="row" *ngIf="survey.mostViewed?.length">
            <!-- <ng-container *ngFor="let survey of survey.mostViewed; let i = index">
            <div class="col-lg-4 col-md-6 col-12" *ngIf="survey">
              <div class="survey-card shadow">
                <div class="top">
                  <div class="d-flex">
                    <div class="comp-logo">
                      <img src="assets/imgs/logo.svg" alt="" />
                      <span>
                        <i class="fas fa-star"></i>
                      </span>
                    </div>
                    <div class="text" style="overflow: auto">
                      <p title="{{ survey.title }}" class="title-style">{{ survey.title }}</p>
                      <h6 class="text-capitalize">
                        <i class="fas fa-calendar-alt"></i>
                        {{ "survey.survey" | translate }}
                      </h6>
                      <h6>
                        <i class="fas fa-heart"></i>
                        {{ survey?.likes }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="timer d-flex">
                    <div class="display-contents">
                      <div class="circle text-capitalize">
                        <img src="./assets/imgs/green-clipboard-list.svg" alt="" />
                        <span>{{ "survey.survey" | translate }}</span>
                      </div>

                      <div class="circle text-capitalize">
                        <img src="./assets/imgs/red-heart.svg" alt="" />
                        <span>{{ survey?.likes }}</span>
                      </div>

                      <div class="circle text-capitalize">
                        <img src="./assets/imgs/colored-calender.svg" alt="" />
                        <span>{{ survey?.days }}
                          {{ "general.days" | translate }}</span>
                      </div>

                      <div class="circle text-capitalize">
                        <img src="./assets/imgs/colored-tag.svg" alt="" />
                        <span>{{ "home.win" | translate }}</span>
                      </div>
                    </div>
                    <div>
                      <button class="m-btn m-btn-green" (click)="startSurvey(survey)" *ngIf="user">
                        {{ "general.Detail" | translate }}
                      </button>
                      <button class="m-btn m-btn-green" (click)="startSurvey(survey)" *ngIf="!user">
                        {{ "general.Start" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container> -->
            <div
              class="col-lg-4"
              *ngFor="let survey of survey.mostViewed; let i = index"
            >
              <div class="survey-card" *ngIf="survey">
                <div class="top">
                  <div class="row">
                    <div class="col-md-4 col-3">
                      <div class="comp-logo">
                        <img
                          [src]="
                            survey?.surveyImage
                              ? survey?.surveyImage
                              : 'assets/imgs/logo.svg'
                          "
                          alt=""
                        />
                      </div>
                    </div>

                    <div class="col-md-8 col-9">
                      <div class="text">
                        <!-- <p title="{{ survey.title }}" class="title-style">
                                        {{ survey?.title }}
                                      </p> -->
                        <div class="iconRow">
                          <div class="col-3 borderBox">
                            <i class="fas fa-calendar-alt iconSetting"></i>
                            <span class="surveyIconHeading">{{
                              "survey.survey" | translate
                            }}</span>
                          </div>
                          <div class="col-3 borderBox">
                            <i
                              class="fas fa-heart iconSetting"
                              style="color: #f05c6d !important"
                            ></i>
                            <span class="surveyIconHeading">{{
                              survey?.likes
                            }}</span>
                          </div>
                          <div class="col-3 borderBox">
                            <img
                              src="./assets/imgs/flame.svg"
                              class="status-icon"
                              alt=""
                            />
                            <span class="surveyIconHeading">{{
                              "home.trend" | translate
                            }}</span>
                          </div>
                          <div class="col-3 borderBox">
                            <i
                              class="fas fa-heart iconSetting"
                              style="color: #efa536 !important; right: 2px"
                            ></i>
                            <span class="surveyIconHeading">{{
                              "home.win" | translate
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 overflow-hidden">
                      <h4 title="{{ survey.title }}" class="title-style">
                        {{ survey?.title }}
                      </h4>
                      <p class="mt-2 text-description fix-description">
                        {{ survey?.description }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="timer d-flex">
                    <app-counter
                      [dates]="{
                        startDate: survey?.startDate,
                        endDate: survey?.endDate
                      }"
                    ></app-counter>

                    <!-- <div>
                                    <h5>
                                      {{ survey?.days }}
                                      <span>{{ "general.days" | translate }}</span>
                                    </h5>
                                    :
                                    <h5>
                                      {{ survey?.hours }}
                                      <span>{{ "general.hours" | translate }}</span>
                                    </h5>
                                    :
                                    <h5>
                                      {{ survey?.minutes }}
                                      <span>{{ "general.mins" | translate }}</span>
                                    </h5>
                                    :
                                    <h5>
                                      {{ survey?.seconds }}
                                      <span>{{ "general.secs" | translate }}</span>
                                    </h5>
                                  </div> -->
                    <div>
                      <button
                        class="m-btn m-btn-green"
                        (click)="startSurvey(survey)"
                      >
                        {{ "general.Start" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row justify-content-center"
            *ngIf="count > 9 && survey.mostViewed.length < count"
          >
            <div class="col-md-3">
              <button
                class="m-btn m-btn-gradient"
                (click)="
                  newLoadMore(
                    'mostViewed',
                    'isPublished',
                    survey.mostViewed,
                    currentPage
                  )
                "
              >
                {{ "survey.loadMore" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="answers"
          role="tabpanel"
          aria-labelledby="answers-tab"
        >
          <div class="row" *ngIf="survey.answers?.length">
            <ng-container *ngFor="let survey of survey.answers; let i = index">
              <!-- <div class="col-lg-4 col-md-6 col-12" *ngIf="survey">
              <div class="survey-card shadow">
                <div class="top">
                  <div class="d-flex">
                    <div class="comp-logo">
                      <img src="assets/imgs/logo.svg" alt="" />
                      <span>
                        <i class="fas fa-star"></i>
                      </span>
                    </div>
                    <div class="text" style="overflow: auto">
                      <p title="{{ survey.title }}" class="title-style">{{ survey.title }}</p>
                      <h6>
                        <i class="fas fa-calendar-alt"></i>
                        {{ "survey.survey" | translate }}
                      </h6>
                      <h6>
                        <i class="fas fa-heart"></i>
                        {{ survey?.likes }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="timer d-flex">
                    <div class="display-contents">
                      <div class="circle">
                        <img src="./assets/imgs/green-clipboard-list.svg" alt="" />
                        <span>{{ "survey.survey" | translate }}</span>
                      </div>

                      <div class="circle">
                        <img src="./assets/imgs/red-heart.svg" alt="" />
                        <span>{{ survey?.likes }}</span>
                      </div>

                      <div class="circle">
                        <img src="./assets/imgs/colored-calender.svg" alt="" />
                        <span>{{ survey?.days }}
                          {{ "general.days" | translate }}</span>
                      </div>

                      <div class="circle">
                        <img src="./assets/imgs/colored-tag.svg" alt="" />
                        <span>{{ "home.win" | translate }}</span>
                      </div>
                    </div>
                    <div>
                      <button class="m-btn m-btn-green" (click)="viewSurvey(survey)">
                        {{ "home.view" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
              <div class="col-lg-4 col-md-6 col-12" *ngIf="survey">
                <div class="survey-card shadow">
                  <div class="top">
                    <div class="row">
                      <div class="col-md-4 col-3">
                        <div class="comp-logo">
                          <img
                            [src]="
                              survey?.surveyImage
                                ? survey?.surveyImage
                                : 'assets/imgs/logo.svg'
                            "
                            alt=""
                          />
                        </div>
                      </div>

                      <div class="col-md-8 col-9">
                        <div class="text">
                          <!-- <p title="{{ survey.title }}" class="title-style">
                                                    {{ survey?.title }}
                                                  </p> -->
                          <div class="iconRow">
                            <div class="col-3 borderBox">
                              <i class="fas fa-calendar-alt iconSetting"></i>
                              <span class="surveyIconHeading">{{
                                "survey.survey" | translate
                              }}</span>
                            </div>
                            <div class="col-3 borderBox">
                              <i
                                class="fas fa-heart iconSetting"
                                style="color: #f05c6d !important"
                              ></i>
                              <span class="surveyIconHeading">{{
                                survey?.likes
                              }}</span>
                            </div>
                            <div class="col-3 borderBox">
                              <img
                                src="./assets/imgs/flame.svg"
                                class="status-icon"
                                alt=""
                              />
                              <span class="surveyIconHeading">{{
                                "home.trend" | translate
                              }}</span>
                            </div>
                            <div class="col-3 borderBox">
                              <i
                                class="fas fa-heart iconSetting"
                                style="color: #efa536 !important; right: 2px"
                              ></i>
                              <span class="surveyIconHeading">{{
                                "home.win" | translate
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row overflow-hidden">
                      <div class="col-12 overflow-hidden">
                        <h4 title="{{ survey.title }}" class="title-style">
                          {{ survey?.title }}
                        </h4>
                        <p class="mt-2 text-description fix-description">
                          {{ survey?.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="timer d-flex">
                      <app-counter
                        [dates]="{
                          startDate: survey?.startDate,
                          endDate: survey?.endDate
                        }"
                      ></app-counter>

                      <!-- <div>
                                                <h5>
                                                  {{ survey?.days }}
                                                  <span>{{ "general.days" | translate }}</span>
                                                </h5>
                                                :
                                                <h5>
                                                  {{ survey?.hours }}
                                                  <span>{{ "general.hours" | translate }}</span>
                                                </h5>
                                                :
                                                <h5>
                                                  {{ survey?.minutes }}
                                                  <span>{{ "general.mins" | translate }}</span>
                                                </h5>
                                                :
                                                <h5>
                                                  {{ survey?.seconds }}
                                                  <span>{{ "general.secs" | translate }}</span>
                                                </h5>
                                              </div> -->
                      <div>
                        <button
                          class="m-btn m-btn-green"
                          (click)="startSurvey(survey)"
                        >
                          {{ "general.Start" | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div
            class="row justify-content-center"
            *ngIf="count > 9 && survey.answers.length < count"
          >
            <div class="col-md-3">
              <button
                class="m-btn m-btn-gradient"
                (click)="
                  newLoadMore(
                    'answers',
                    'isPublished',
                    survey.answers,
                    currentPage
                  )
                "
              >
                {{ "survey.loadMore" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="latestSurvyes"
          role="tabpanel"
          aria-labelledby="latestSurvyes-tab"
        >
          <div class="row" *ngIf="survey.latest?.length">
            <ng-container *ngFor="let survey of survey.latest; let i = index">
              <!-- <div class="col-lg-4 col-md-6 col-12" *ngIf="survey">
              <div class="survey-card shadow">
                <div class="top">
                  <div class="d-flex">
                    <div class="comp-logo">
                      <img src="assets/imgs/logo.svg" alt="" />
                      <span>
                        <i class="fas fa-star"></i>
                      </span>
                    </div>
                    <div class="text" style="overflow: auto">
                      <p title="{{ survey.title }}" class="title-style">
                        {{ survey.title }}
                      </p>
                      <h6>
                        <i class="fas fa-calendar-alt"></i>
                        {{ "survey.survey" | translate }}
                      </h6>
                      <h6>
                        <i class="fas fa-heart"></i>
                        {{ survey?.likes }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="timer d-flex">
                    <div class="display-contents">
                      <div class="circle">
                        <img src="./assets/imgs/green-clipboard-list.svg" alt="" />
                        <span>{{ "survey.survey" | translate }}</span>
                      </div>

                      <div class="circle">
                        <img src="./assets/imgs/red-heart.svg" alt="" />
                        <span>{{ survey?.likes }}</span>
                      </div>

                      <div class="circle">
                        <img src="./assets/imgs/colored-calender.svg" alt="" />
                        <span>{{ survey?.days }}
                          {{ "general.days" | translate }}</span>
                      </div>

                      <div class="circle">
                        <img src="./assets/imgs/colored-tag.svg" alt="" />
                        <span>{{ "home.win" | translate }}</span>
                      </div>
                    </div>
                    <div>
                      <button class="m-btn m-btn-green" (click)="startSurvey(survey)" *ngIf="user">
                        {{ "general.Detail" | translate }}
                      </button>
                      <button class="m-btn m-btn-green" (click)="startSurvey(survey)" *ngIf="!user">
                        {{ "general.Start" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
              <div class="col-lg-4 col-md-6 col-12" *ngIf="survey">
                <div class="survey-card">
                  <div class="top">
                    <div class="row">
                      <div class="col-md-4 col-3">
                        <div class="comp-logo">
                          <img
                            [src]="
                              survey?.surveyImage
                                ? survey?.surveyImage
                                : 'assets/imgs/logo.svg'
                            "
                            alt=""
                          />
                        </div>
                      </div>

                      <div class="col-md-8 col-9">
                        <div class="text">
                          <!-- <p title="{{ survey.title }}" class="title-style">
                                                    {{ survey?.title }}
                                                  </p> -->
                          <div class="iconRow">
                            <div class="col-3 borderBox">
                              <i class="fas fa-calendar-alt iconSetting"></i>
                              <span class="surveyIconHeading">{{
                                "survey.survey" | translate
                              }}</span>
                            </div>
                            <div class="col-3 borderBox">
                              <i
                                class="fas fa-heart iconSetting"
                                style="color: #f05c6d !important"
                              ></i>
                              <span class="surveyIconHeading">{{
                                survey?.likes
                              }}</span>
                            </div>
                            <div class="col-3 borderBox">
                              <img
                                src="./assets/imgs/flame.svg"
                                class="status-icon"
                                alt=""
                              />
                              <span class="surveyIconHeading">{{
                                "home.trend" | translate
                              }}</span>
                            </div>
                            <div class="col-3 borderBox">
                              <i
                                class="fas fa-heart iconSetting"
                                style="color: #efa536 !important; right: 2px"
                              ></i>
                              <span class="surveyIconHeading">{{
                                "home.win" | translate
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row overflow-hidden">
                      <div class="col-12 overflow-hidden">
                        <h4 title="{{ survey.title }}" class="title-style">
                          {{ survey?.title }}
                        </h4>
                        <p class="mt-2 text-description fix-description">
                          {{ survey?.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="timer d-flex">
                      <app-counter
                        [dates]="{
                          startDate: survey?.startDate,
                          endDate: survey?.endDate
                        }"
                      ></app-counter>

                      <!-- <div>
                                                <h5>
                                                  {{ survey?.days }}
                                                  <span>{{ "general.days" | translate }}</span>
                                                </h5>
                                                :
                                                <h5>
                                                  {{ survey?.hours }}
                                                  <span>{{ "general.hours" | translate }}</span>
                                                </h5>
                                                :
                                                <h5>
                                                  {{ survey?.minutes }}
                                                  <span>{{ "general.mins" | translate }}</span>
                                                </h5>
                                                :
                                                <h5>
                                                  {{ survey?.seconds }}
                                                  <span>{{ "general.secs" | translate }}</span>
                                                </h5>
                                              </div> -->
                      <div>
                        <button
                          class="m-btn m-btn-green"
                          (click)="startSurvey(survey)"
                        >
                          {{ "general.Start" | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div
            class="row justify-content-center"
            *ngIf="count > 9 && survey.latest.length < count"
          >
            <div class="col-md-3">
              <button
                class="m-btn m-btn-gradient"
                (click)="
                  newLoadMore('latest', 'latest', survey.answers, currentPage)
                "
              >
                {{ "survey.loadMore" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="polls"
          role="tabpanel"
          aria-labelledby="polls-tab"
        >
          <div class="row" *ngIf="survey?.polls?.length">
            <ng-container *ngFor="let survey of survey.polls; let i = index">
              <div class="col-lg-4 col-md-6 col-12" *ngIf="survey">
                <div class="survey-card">
                  <form class="poll-card">
                    <div class="info d-flex">
                      <img src="assets/imgs/logo.svg" alt="" />
                      <p>
                        question1 Lorem ipsum dolor sit amet, adipiscing elit?
                      </p>
                    </div>
                    <div class="answers">
                      <ul>
                        <li>
                          <h6>Answer1</h6>
                          <input type="radio" id="radio1" name="answers" />
                          <label for="radio1">
                            <span style="width: 24%"><b>24%</b></span>
                          </label>
                        </li>
                        <li>
                          <h6>Answer2</h6>
                          <input type="radio" id="radio2" name="answers" />
                          <label for="radio2">
                            <span style="width: 31%"><b>31%</b></span>
                          </label>
                        </li>
                        <li>
                          <h6>Answer3</h6>
                          <input type="radio" id="radio3" name="answers" />
                          <label for="radio3">
                            <span style="width: 70%"><b>70%</b></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div class="vote">
                      <div class="d-flex">
                        <ul>
                          <li>
                            <i class="fas fa-calendar-alt"></i>
                            {{ "survey.surveys" | translate }}
                          </li>
                          <li>
                            <i class="fas fa-heart"></i> {{ survey?.likes }}
                          </li>
                          <li>
                            <i class="far fa-calendar-alt"></i>
                            {{ survey?.days }} {{ "general.days" | translate }}
                          </li>
                        </ul>
                        <a class="m-btn m-btn-green">{{
                          "survey.vote" | translate
                        }}</a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </ng-container>
          </div>
          <div
            class="row justify-content-center"
            *ngIf="survey.allPolls.length != survey.polls.length"
          >
            <div class="col-md-3">
              <button
                class="m-btn m-btn-gradient"
                (click)="loadMoreSurvey('latest', 'allPolls')"
              >
                {{ "survey.loadMore" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-loader [show]="showLoader"></app-loader>
  </div>
</div>
<!-- <app-footer></app-footer> -->
