<div class="redeem-modal-container">
  <div class="row">
    <div class="col-12 text-center">
      <img src="./assets/imgs/logo.svg" />
    </div>
    <div class="col-12 text-center">
      <p class="download-text">Coming Soon</p>
    </div>
  </div>
</div>
