import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'src/services/auth.service';
import { DarkLiteThemeService } from 'src/services/dark-lite-themeService.service';
import { HomeService } from 'src/services/home.service';
import { Router, NavigationEnd } from '@angular/router';
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { LocalStorageService } from 'angular-2-local-storage';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { LanguageService } from 'src/services/language.service';
import { eLanguage } from 'src/app/general/enums/language.enum';

// import { Pipe, PipeTransform } from '@angular/core';
// import * as numeral from 'numeral';

// @Pipe({
//   name: 'shortenNumber'
// })

declare var $: any;

@Component({
  selector: 'app-new-my-account',
  templateUrl: './new-my-account.component.html',
  styleUrls: ['./new-my-account.component.css']
})
export class NewMyAccountComponent implements OnInit {
  MySurvey: any;
  isSidebarVisible = true;

  avatar: any;
  SpentPoints: any;
  uploadAvatar: any = null;
  userStats: any;
  showLoader: boolean = false;
  user: any;
  changeToLang: any
  constructor(
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private breakpointObserver: BreakpointObserver,
    public languageService: LanguageService,

    public homeService: HomeService,
    private sanitization: DomSanitizer,
    public darkThemeService: DarkLiteThemeService,
    private router: Router

  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Call your function when the URL changes
        this.handleUrlChange();
      }
    });
    this.changeToLang =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_English
        ? eLanguage.Short_Arabic
        : eLanguage.Short_English;
    var url = window.location.href;
    var activeTab = url.substring(url.indexOf("#") + 1);
    if (activeTab === "Wallet") {
      $(".tab-pane").removeClass("active in");
      $("#" + activeTab).addClass("active in");
      $('a[href="#' + activeTab + '"]').tab("show");
    }
  }

  ngOnInit(): void {
    this.getUser();
    this.getUserAllStats();
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
      if (result.matches) {
        this.isSidebarVisible = false;
      }
    });
  }


  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  MySurveys() {
    this.homeService.getMySurvey().subscribe((result) => {
      setTimeout(() => {
        this.MySurvey = result.length;
      }, 1000);
    });
  }
  getUserAllStats() {
    this.homeService.getUserStats().subscribe((result) => {
      this.userStats = result;
    });

  }
  handleUrlChange() {
    console.log('handleUrlChange')
    var url = window.location.href;
    var activeTab = url.substring(url.indexOf("#") + 1);
    if (activeTab === "Wallet" || activeTab === "Surveys") {
      $(".tab-pane").removeClass("active in");
      $("#" + activeTab).addClass("active in");
      $('a[href="#' + activeTab + '"]').tab("show");
    } else {
      $(".tab-pane").removeClass("active in");
      $("#" + 'Info').addClass("active in");
      $('a[href="#' + 'Info' + '"]').tab("show");
    }
  }

  openFileInput(): void {
    const fileInput = document.getElementById('fileInput1');
    if (fileInput) {
      fileInput.click();
    }
  }
  onSelectFile(files) {
    if (files.length > 0) {
      this.showLoader = true;

      let reader = new FileReader();

      reader.onload = () => {
        this.showLoader = false;
        this.uploadAvatar = files[0];

        if (this.uploadAvatar) {
          const formData: FormData = new FormData();
          formData.append(
            "avatar",
            this.uploadAvatar,
            this.uploadAvatar.name
          );
          this.http
            .request(
              new HttpRequest(
                "POST",
                `${environment.api_url}/update-profile`,
                formData,
                {
                  headers: new HttpHeaders({
                    "X-Requested-With": "application/XMLHttpRequest",
                    Security: "Nmd@E>5.D]q.rE3:",
                    Software: "web",
                    Authorization: String(
                      this.localStorageService.get("accessToken") || ""
                    ),
                  }),
                }
              )
            )
            .subscribe(
              (event) => {
                this.showLoader = false;
                if (event.type === HttpEventType.Response) {
                }
              },
              (error) => { }
            );
        }
        // Convert the file content to Base64
        const base64String = reader.result;

        // Display the Base64 string in the 'avatar' property
        this.avatar = this.sanitization.bypassSecurityTrustResourceUrl(`${base64String}`)

      };

      reader.onerror = () => {
        this.showLoader = false;
      };

      // Read the file as a data URL
      reader.readAsDataURL(files[0]);
    }
  }

  getUser() {
    this.showLoader = true;
    this.authService.getUser().subscribe(
      (result) => {
        this.SpentPoints = result.body.SpentPoints;
        this.user = result?.body ? result.body : {};
        if (this.user.avatar) {
          this.avatar = this.user.avatar
          //  this.sanitization.bypassSecurityTrustStyle(
          //   `${this.user.avatar}`
          // );
        }
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
      }
    );
    var url = window.location.href;
    var activeTab = url.substring(url.indexOf("#") + 1);
    if (activeTab === "Wallet") {
      $(".tab-pane").removeClass("active in");
      $("#" + activeTab).addClass("active in");
      $('a[href="#' + activeTab + '"]').tab("show");
    }
  }
}
