import { Component, OnInit } from '@angular/core';
import { DarkLiteThemeService } from "../../../services/dark-lite-themeService.service";
import { Router } from "@angular/router";
import { SurveyService } from 'src/services/survey.service';

@Component({
  selector: 'app-new-completed-survey',
  templateUrl: './new-completed-survey.component.html',
  styleUrls: ['./new-completed-survey.component.css']
})
export class NewCompletedSurveyComponent implements OnInit {
  survey: any
  constructor(public darkThemeService: DarkLiteThemeService,
    private surveyService: SurveyService,

    private router: Router) { }

  ngOnInit(): void {
    this.survey = this.surveyService.getLocalSurveyModal();

  }

  goToMain() {
    this.surveyService.removeLocalSurveyModal
    this.router.navigate(['/']);
  }
}
