<!-- Start Wrapper -->
<div class="wrapper profile-wrap" [ngClass]="darkThemeService.getCurrentTheme">
  <section class="edit-profile main-second">
    <div class="card-sec">
      <form [formGroup]="form">
        <div class="ed-image">
          <div class="avatar-upload d-none">
            <div class="avatar-edit">
              <input
                #fileEl
                type="file"
                id="imageUpload"
                (change)="onSelectFile(fileEl.files)"
                accept=".png, .jpg, .jpeg"
              />
              <label for="imageUpload"></label>
            </div>
            <div class="avatar-preview">
              <div
                id="imagePreview"
                *ngIf="!avatar"
                style="
                  background-image: url(https://karateinthewoodlands.com/wp-content/uploads/2017/09/default-user-image.png);
                "
              ></div>
              <div
                id="imagePreview"
                *ngIf="avatar"
                [style.background-image]="avatar"
              ></div>
            </div>
          </div>
        </div>
        <div class="info-sec">
          <h5 class="stf-title stf-title-1 text-center">
            {{ "profile.fillInformation" | translate }}
          </h5>
          <div class="row p-md-5 p-3">
            <div class="col-lg-4 col-md-4 col-12">
              <div class="label-style">
                {{ "profile.nameLabel" | translate }}
              </div>
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('firstName').invalid && form.get('firstName').dirty
                }"
              >
                <div class="icon">
                  <i class="icon-gradient fas fa-user"></i>
                </div>
                <input
                  type="text"
                  formControlName="firstName"
                  (input)="onInput($event, 'firstName')"
                  class="new-input-style"
                  placeholder="{{ 'profile.Your Name*' | translate }}"
                />
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-12">
              <div class="label-style">
                {{ "profile.emailLabel" | translate }}
              </div>
              <div
                class="-input"
                [ngClass]="{
                  invalid: form.get('email').invalid && form.get('email').dirty
                }"
              >
                <div class="icon">
                  <i class="icon-gradient fas fa-envelope"></i>
                </div>
                <input
                  type="email"
                  [attr.disabled]="emailDisable ? true : null"
                  formControlName="email"
                  class="new-input-style"
                  placeholder="{{ 'profile.Your Email*' | translate }}"
                />
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-12">
              <div class="label-style">
                {{ "profile.numberLabel" | translate }}
              </div>
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('phoneNumber').invalid &&
                    form.get('phoneNumber').dirty
                }"
              >
                <div class="icon">
                  <i class="icon-gradient fas fa-mobile"></i>
                </div>
                <input
                  type="text"
                  [attr.disabled]="isDisabled ? true : null"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  formControlName="phoneNumber"
                  class="new-input-style"
                  placeholder="{{ 'profile.Your Phone*' | translate }}"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="label-style">
                {{ "profile.genderLabel" | translate }}
              </div>
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('gender').invalid && form.get('gender').dirty
                }"
              >
                <div class="icon">
                  <i class="icon-gradient fas fa-venus-mars"></i>
                </div>
                <ng-select
                  formControlName="gender"
                  class="m-select new-input-style"
                  placeholder="{{ 'profile.Gender' | translate }}"
                >
                  <ng-option *ngFor="let gender of genders" [value]="gender.id">
                    {{ "profile." + gender.name | translate }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="label-style">
                {{ "profile.birthLabel" | translate }}
              </div>
              <div class="-input">
                <div class="icon">
                  <i class="far fa-calendar-alt"></i>
                </div>
                <ngx-datepicker
                  formControlName="birthDate"
                  class="new-input-style"
                  [options]="options"
                ></ngx-datepicker>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="label-style">
                {{ "profile.personalLabel" | translate }}
              </div>
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('personal').invalid && form.get('personal').dirty
                }"
              >
                <div class="icon">
                  <i class="icon-gradient fas fa-address-card"></i>
                </div>
                <ng-select
                  formControlName="personal"
                  class="m-select new-input-style"
                  placeholder="{{ 'profile.Personal' | translate }}"
                >
                  <ng-option
                    *ngFor="let gender of information"
                    [value]="gender.name"
                  >
                    {{ "profile." + gender.name | translate }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="label-style">
                {{ "profile.statusLabel" | translate }}
              </div>
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('status').invalid && form.get('status').dirty
                }"
              >
                <div class="icon">
                  <i class="icon-gradient">
                    <img src="assets/imgs/wedding-rings.svg" alt="" />
                  </i>
                </div>
                <ng-select
                  formControlName="status"
                  class="m-select new-input-style"
                  placeholder="{{ 'profile.Status' | translate }}"
                >
                  <ng-option *ngFor="let gender of status" [value]="gender.id">
                    {{ "profile." + gender.name | translate }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div
              class="col-lg-8 col-md-8 col-12 d-md-flex"
              *ngIf="language === 'Arabic'; else elseBlockEnglish"
            >
              <div class="col-lg-6 col-md-6 col-12">
                <div class="label-style">
                  {{ "profile.countryLabel" | translate }}
                </div>
                <div
                  class="-input"
                  [ngClass]="{
                    invalid:
                      form.get('country').invalid && form.get('country').dirty
                  }"
                >
                  <div class="icon">
                    <i class="icon-gradient fas fa-flag"></i>
                  </div>

                  <ng-select
                    formControlName="country"
                    class="-select new-input-style"
                    placeholder="{{ 'profile.Country' | translate }}"
                    (change)="getCitiesArabic()"
                  >
                    <ng-option
                      *ngFor="let country of countriesList"
                      [value]="country.name"
                    >
                      {{ country.label }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <div class="label-style">
                  {{ "profile.cityLabel" | translate }}
                </div>
                <div
                  class="-input"
                  [ngClass]="{
                    invalid: form.get('city').invalid && form.get('city').dirty
                  }"
                >
                  <div class="icon">
                    <i class="icon-gradient fas fa-map-marker-alt"></i>
                  </div>
                  <!-- <ng-select formControlName="city" class="m-select new-input-style"
                  placeholder="{{ 'profile.City' | translate }}">
                  <ng-option *ngFor="let city of cities" [value]="city.name">
                    {{ city.name }}
                  </ng-option>
                </ng-select> -->
                  <ng-select
                    formControlName="city"
                    class="m-select new-input-style"
                    placeholder="{{ 'profile.City' | translate }}"
                  >
                    <ng-option
                      *ngFor="let city of citiesList"
                      [value]="city.name"
                    >
                      {{ city.label }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
            <ng-template #elseBlockEnglish>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="label-style">
                  {{ "profile.countryLabel" | translate }}
                </div>
                <div
                  class="-input"
                  [ngClass]="{
                    invalid:
                      form.get('country').invalid && form.get('country').dirty
                  }"
                >
                  <div class="icon">
                    <i class="icon-gradient fas fa-flag"></i>
                  </div>
                  <ng-select
                    formControlName="country"
                    class="m-select new-input-style"
                    placeholder="{{ 'profile.Country' | translate }}"
                    (change)="getCities()"
                  >
                    <ng-option
                      *ngFor="let country of countries"
                      [value]="country.name"
                    >
                      {{ country.name }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="label-style">
                  {{ "profile.cityLabel" | translate }}
                </div>
                <div
                  class="-input"
                  [ngClass]="{
                    invalid: form.get('city').invalid && form.get('city').dirty
                  }"
                >
                  <div class="icon">
                    <i class="icon-gradient fas fa-map-marker-alt"></i>
                  </div>
                  <ng-select
                    formControlName="city"
                    class="m-select new-input-style"
                    placeholder="{{ 'profile.City' | translate }}"
                  >
                    <ng-option *ngFor="let city of cities" [value]="city.name">
                      {{ city.name }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </ng-template>

            <div class="col-lg-4 col-md-4 col-12">
              <div class="label-style">
                {{ "profile.educationLabel" | translate }}
              </div>
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('education').invalid && form.get('education').dirty
                }"
              >
                <div class="icon">
                  <i class="icon-gradient fas fa-graduation-cap"></i>
                </div>
                <ng-select
                  formControlName="education"
                  class="m-select new-input-style"
                  style="border-radius: 10px"
                  placeholder="{{ 'profile.Education' | translate }}"
                >
                  <ng-option
                    *ngFor="let employ of education"
                    [value]="employ.name"
                  >
                    <!-- {{ employ.name | translate }} -->
                    {{ "profile." + employ.name | translate }}
                  </ng-option>
                </ng-select>
                <!-- <input type="text" formControlName="education" placeholder="{{ 'profile.Education' | translate }}" /> -->
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="label-style">
                {{ "profile.employLabel" | translate }}
              </div>
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('employ').invalid && form.get('employ').dirty
                }"
              >
                <div class="icon">
                  <i class="icon-gradient fas fa-graduation-cap"></i>
                </div>
                <ng-select
                  formControlName="employ"
                  class="m-select new-input-style"
                  placeholder="{{ 'profile.Employment Status' | translate }}"
                >
                  <ng-option
                    *ngFor="let employ of employ"
                    [value]="employ.name"
                  >
                    {{ "profile." + employ.name | translate }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="social-sec">
          <h5 class="title text-center">
            {{ "survey.socialMedia" | translate }}
          </h5>
          <div class="row p-md-5 p-3">
            <div class="col-lg-4 col-md-4 col-12">
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('facebookId').invalid &&
                    form.get('facebookId').dirty
                }"
              >
                <div class="icon">
                  <i class="fab fa-facebook-f" style="color: #3b5998"></i>
                </div>
                <input
                  type="text"
                  formControlName="facebookId"
                  placeholder="{{ 'profile.Facebook' | translate }}"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('twitterId').invalid && form.get('twitterId').dirty
                }"
              >
                <div class="icon">
                  <i class="fab fa-twitter" style="color: #1da1f2"></i>
                </div>
                <input
                  type="text"
                  formControlName="twitterId"
                  placeholder="{{ 'profile.Twitter' | translate }}"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('instagramId').invalid &&
                    form.get('instagramId').dirty
                }"
              >
                <div class="icon">
                  <i class="fab fa-instagram" style="color: #c32aa3"></i>
                </div>
                <input
                  type="text"
                  formControlName="instagramId"
                  placeholder="{{ 'profile.Instagram' | translate }}"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('googleId').invalid && form.get('googleId').dirty
                }"
              >
                <div class="icon">
                  <i class="fab fa-google" style="color: #ea4335"></i>
                </div>
                <input
                  type="text"
                  formControlName="googleId"
                  placeholder="{{ 'profile.Google' | translate }}"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('linkedinId').invalid &&
                    form.get('linkedinId').dirty
                }"
              >
                <div class="icon">
                  <i class="fab fa-linkedin-in" style="color: #0a66c2"></i>
                </div>
                <input
                  type="text"
                  formControlName="linkedinId"
                  placeholder="{{ 'profile.Linkedin' | translate }}"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div
                class="-input"
                [ngClass]="{
                  invalid:
                    form.get('youtubeId').invalid && form.get('youtubeId').dirty
                }"
              >
                <div class="icon">
                  <i class="fab fa-youtube" style="color: #ff0000"></i>
                </div>
                <input
                  type="text"
                  formControlName="youtubeId"
                  placeholder="{{ 'profile.Youtube' | translate }}"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row p-md-5 p-3">
          <div class="your-code col-lg-6 col-md-6 col-12">
            <h5 class="label-style">
              {{ "profile.Your Code" | translate }}
            </h5>
            <span class="des">{{
              "profile.share this code with your friend to get 500 points"
                | translate
            }}</span>

            <div class="-input">
              <div class="icon">
                <i
                  class="icon-gradient fas fa-user-tag"
                  style="color: #1bbaa2"
                ></i>
              </div>
              <input
                #userInput
                type="text"
                readonly
                placeholder="{{ 'profile.Code' | translate }}"
                (click)="copy(userInput)"
                formControlName="code"
                style="cursor: pointer"
              />
              <a (click)="copy(userInput)" class="code-cpy"
                ><i class="icon-gradient fas fa-copy"></i
              ></a>
            </div>
            <p class="des">
              {{ "survey.youHaveInvitationCode" | translate }}
            </p>
          </div>

          <div class="referral-code col-lg-6 col-md-6 col-12">
            <h5 class="label-style">
              {{ "survey.referralCode" | translate }}
            </h5>
            <div class="d-flex justify-content-between">
              <p class="des">
                {{ "survey.youHaveInvitationCode" | translate }}
              </p>
              <span class="sub-text"
                ><b>+500</b> {{ "survey.Point" | translate }}</span
              >
            </div>

            <div class="-input ref-code">
              <input
                type="text"
                #referralCode
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                placeholder="{{ 'survey.referralCode' | translate }}"
              />
              <div class="icon">
                <i class="icon-gradient fas fa-tag"></i>
              </div>
              <a class="send-btn" (click)="referral(referralCode.value)">{{
                "survey.activation" | translate
              }}</a>
            </div>
          </div>
        </div>

        <!-- <div class="btns-sec d-flex col-xs-12">
            <button (click)="save()" class="btn-save m-btn m-btn-green">
              {{ "profile.Save" | translate }}
            </button>
            <button (click)="save(true)" class="btn-complete m-btn">
              {{ "profile.Complete" | translate }}
            </button>
          </div> -->
      </form>
    </div>
    <div class="cpy-toast"></div>
    <div class="row justify-content-center">
      <div class="col-md-2 col-5">
        <button (click)="save()" class="btn-save m-btn w-100 cursor-pointer">
          {{ "profile.Save" | translate }}
        </button>
      </div>
      <div class="col-md-2 col-5">
        <button
          (click)="save(true)"
          class="btn-complete m-btn w-100 cursor-pointer"
        >
          {{ "profile.Complete" | translate }}
        </button>
      </div>
    </div>
  </section>
  <!-- End  Edit Profile Section -->
  <!-- End Main -->
</div>
<!-- End wrapper -->

<app-loader [show]="showLoader"></app-loader>
