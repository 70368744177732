import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "../../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { LanguageService } from "../../../services/language.service";
import { VerifyComponent } from "../verify/verify.component";
import * as allCountries from "src/assets/const/countries.json";
import { Country, ICountry } from "country-state-city";
import { HomeService } from "src/services/home.service";

@Component({
  selector: "app-forget-password",
  templateUrl: "./forget-password.component.html",
  styleUrls: ["./forget-password.component.css"],
})
export class ForgetPasswordComponent implements OnInit {
  showLoader = false;
  onClose: any = () => {};

  activeTab: string = "sms"; // Initialize with the default tab

  form: FormGroup;
  countries: ICountry[] = [];
  constructor(
    public languageService: LanguageService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    public homeService: HomeService,
    public modal: BsModalRef
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      // validate email or phone
      phoneNumber: [null],
      phoneCode: ["", [Validators.required]],
    });
    this.getCountries();
  }

  close(Result = null) {
    this.onClose(Result);
  }
  selectTab(tab: string) {
    this.activeTab = tab;
    // You can perform additional actions here when a tab is selected
    console.log("Selected tab:", tab);
  }
  processForget() {
    if (this.form.valid) {
      this.showLoader = true;
      // const phoneNumber = "+966" + this.form.value.phoneNumber;

      const loginData = {
        phoneNumber: this.form.value.phoneCode + this.form.value.phoneNumber,
        otpType: this.activeTab,
      };
      this.authService.forgottenPasswordSendSms(loginData).subscribe(
        (result) => {
          this.showLoader = false;
          this.close({});
          this.verify();
        },
        (err) => {
          this.showLoader = false;
          this.translateService
            .get("forget.error_message")
            .subscribe((text) => {
              this.toastr.error(text);
            });
        }
      );
    } else {
      for (let control in this.form.controls) {
        this.form.get(control).markAsDirty();
      }
    }
  }

  verify() {
    this.close({});
    const verifyModel = this.modalService.show(VerifyComponent, {
      class: "modal-dialog-centered login",
      backdrop: "static",
      initialState: {
        forget: true,
        phoneNumber: this.form.value.phoneNumber,
      },
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
  }
  getCountries() {
    this.countries = this.homeService.getCountries();
    // this.countries = Country.getAllCountries();
    console.log("this.countries: ", allCountries);
  }
}
