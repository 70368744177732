import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { LanguageService } from "../../../services/language.service";
import { SurveyService } from "../../../services/survey.service";
import { Router } from "@angular/router";
import { SurveyTypes } from 'src/app/general/enums/survey-types.enum';
import { eLanguage } from 'src/app/general/enums/language.enum';

@Component({
  selector: 'app-enterprise-answer',
  templateUrl: './enterprise-answer.component.html',
  styleUrls: ['./enterprise-answer.component.css']
})
export class EnterpriseAnswerComponent implements OnInit {
  showLoader = false;
  onClose: any;
  survey: any = null;
  form: FormGroup;
  cities = [];
  locale = "en";
  allowClose: boolean = true;
  constructor(
    public languageService: LanguageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private surveyService: SurveyService,
    private translateService: TranslateService,
    private toastr: ToastrService
  ) {
    this.survey = this.surveyService.getLocalSurveyModal();
  }

  ngOnInit() {
    // this.getCities();
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
    });
  }
  handleCreateSurvey() {
    if (this.form.valid) {
      this.showLoader = true;
      this.surveyService.createSurveyUserInfo({
        ...this.form.value
      });
      this.showLoader = false;

      const currentLanguage = this.languageService.getLanguageOrDefault() == eLanguage.Short_Arabic ? eLanguage.Arabic : eLanguage.English

      if (this.survey?.language != currentLanguage) {
        this.languageService.setLanguage(this.survey?.language == eLanguage.Arabic ? eLanguage.Short_Arabic: eLanguage.Short_English);
      }

      if (this.survey?.surveyType == SurveyTypes.NORMAL) {
        this.router.navigate([`/survey-answer/${currentLanguage}`]);
      } else if (this.survey?.surveyType == SurveyTypes.ENTERPRISE) {
        this.router.navigate([`enterprise/survey-answer/${currentLanguage}`]);
      } else {
        this.translateService.get("survey.surveyTimeNotValid").subscribe((text) => {
          this.toastr.error(text);
        });
      }

    } else {
      for (let control in this.form.controls) {
        this.form.get(control).markAsDirty();
      }
    }
  }
}
