import { Component, OnInit } from "@angular/core";
import { LanguageService } from "../../../services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OwlOptions } from "ngx-owl-carousel-o";
import { HomeService } from "../../../services/home.service";
import * as moment from "moment";
import { BsModalService } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { SurveyService } from "src/services/survey.service";
import { first } from "rxjs/operators";
import { eLanguage } from "src/app/general/enums/language.enum";
import { SurveyNameComponent } from "../survey-name/survey-name.component";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { SignupComponent } from "src/app/partials/signup/signup.component";
import { LoginComponent } from "src/app/partials/login/login.component";
import { CreateSurveyComponent } from "../create-survey/create-survey.component";
import { ToastrService } from "ngx-toastr";
declare var $: any;
declare const gtag: Function;

@Component({
  selector: "app-not-loggedin-home",
  templateUrl: "./not-loggedin-home.component.html",
  styleUrls: ["./not-loggedin-home.component.css"],
})
export class NotLoggedinHomeComponent implements OnInit {
  showLoader = false;
  form: FormGroup;
  page: number = 0;
  limit: number = 6;
  newsletter: FormGroup;
  slideConfig: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    autoplay: true,
    pullDrag: false,
    animateOut: "fadeOut",
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
        dots: false,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    nav: false,
  };
  slides = [];
  kafu: any;
  statisticsConfig: OwlOptions = {
    loop: false,
    nav: true,
    navText: [
      "<i class='fas fa-angle-left'></i>",
      "<i class='fas fa-angle-right'></i>",
    ],
    dots: false,
    margin: 20,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 6,
      },
    },
  };
  statistics = [];

  partnersConfig: OwlOptions = {
    loop: false,
    nav: true,
    navText: [
      "<i class='fas fa-angle-left'></i>",
      "<i class='fas fa-angle-right'></i>",
    ],
    dots: false,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 7,
      },
    },
  };

  partners = [];
  surveys = [];
  services = [];
  earnPointsSliderConfig: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    animateOut: "fadeOut",
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    navSpeed: 700,
    nav: true,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  kafuContactDetails: any;
  currentService: any;
  imageUrl = "https://apiv2.kafuapp.com:3001/images/"
  selectedItemStatic = 0;
  formSubmitAttempt: boolean = false;
  newsAttempt: boolean = false;
  errorToastShown: boolean = false;
  subErrorToastShown: boolean = false;

  count = 0;
  currentPage = 0;
  survey = {
    all: [],
    featured: {
      all: [],
      display: [],
      displayLimit: 0,
    },

    mostViewed: {
      all: [],
      display: [],
      displayLimit: 0,
    },
    answers: {
      all: [],
      display: [],
      displayLimit: 0,
    },
    latest: {
      all: [],
      display: [],
      displayLimit: 0,
    },
    polls: {
      all: [],
      display: [],
      displayLimit: 0,
    },
  };
  language: string = eLanguage.English;

  constructor(
    public homeService: HomeService,
    private surveyService: SurveyService,
    private formBuilder: FormBuilder,
    public darkThemeService: DarkLiteThemeService,
    public languageService: LanguageService,
    private router: Router,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    if (this.language === eLanguage.Arabic) {
      this.slideConfig.rtl = true;
      this.earnPointsSliderConfig.rtl = true;
      this.statisticsConfig.rtl = true;
      this.partnersConfig.rtl = true;
    }
  }

  ngOnInit() {
    this.showLoader = true;
    this.getAllSurveys(this.page, this.limit);
    this.getSliders();
    this.getServices();
    this.getKafuContact();
    this.getStatistics();
    this.getAllPartners();
    this.form = this.formBuilder.group({
      name: [null, [Validators.required,
      Validators.pattern(
        /^[A-Za-z\s]+$/
      ),]],
      email: [null, [Validators.required,
      Validators.email]],
      phone: [null, [Validators.required, Validators.maxLength(12)]],
      message: [null, Validators.required],
      subject: [null, Validators.required],
    });

    this.newsletter = this.formBuilder.group({
      email: [null, [
        Validators.required,
        Validators.pattern(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        ),
      ]],
    });
  }
  sendMessage() {
    if (!this.formSubmitAttempt) {
      this.formSubmitAttempt = true;
    }
    if (!this.form.valid) {
      if (this.formSubmitAttempt && !this.errorToastShown) {
        this.translateService
          .get("home.validInfo")
          .subscribe((text) => {
            this.toastr.error(text);
          });
        this.errorToastShown = true
      };
    } else {
      this.homeService.contactUs(this.form.value).subscribe((result) => {
        this.translateService
          .get("home.contact")
          .subscribe((text) => {
            this.toastr.success(text);
          });
        this.homeService.markFormAsSubmitted();
        this.form.reset()
        this.formSubmitAttempt = false
      });
    }
  }

  onInput(event: any): void {
    const input = event.target.value;

    // Filter out numbers and emojis
    const filteredInput = input.replace(/[^A-Za-z\s]/g, '');

    // Update the input value
    this.form.get('name').setValue(filteredInput);
  }
  onEmailInput(event: any): void {
    const input = event.target.value;

    // Filter out unwanted characters
    const filteredInput = input.replace(/[^A-Za-z0-9@._-]/g, '');

    // Update the input value
    this.form.get('email').setValue(filteredInput);
  }

  getAllPartners() {
    this.homeService.getAllPartners().subscribe(
      (result) => {
        this.partners = result;
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
      }
    );
  }

  getAllSurveys(page, limit) {
    let data = [];
    this.surveyService.getAllSurvey(page, limit).subscribe(
      (result) => {
        data = result.list
        this.count = result.count
        this.currentPage = result.currentPage
        this.survey.all = data?.length ? data : [];
        if (this.survey.all.length) {
          this.filterFeaturedSurvey();
          // this.filterMostViwedSurvey();
          // this.filterAnwseredSurvey();
          // this.filterLatestSurvey();
          // this.filterPollSurvey();
        }
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
      }
    );
  }
  getSliders() {
    this.homeService.getSliders().subscribe((result) => {
      this.slides = result;
    });
  }

  getKafuContact() {
    this.homeService.getAllKafuContact().subscribe((result) => {
      this.kafuContactDetails = result[0];
    });
  }
  joinNowClick(data) {
    if (data?.includes("/signup")) {
      const loginModel = this.modalService.show(LoginComponent, {
        class: "modal-dialog-centered login",
        backdrop: "static",
      });
      loginModel.content.onClose = (auth) => {
        loginModel.hide();
      };
    } else {
      this.homeService.markIsNotLogin()
      this.surveyService.removeBrand()
      const loginModel = this.modalService.show(CreateSurveyComponent, {
        class:
          "modal-lg modal-dialog-centered sign-up c-survey-modal  create-survey-modal",
        backdrop: "static",
      });
      loginModel.content.onClose = (auth) => {
        loginModel.hide();
      };
    }
  }
  removePrefix(originalString: string, prefix: string): string {
    if (originalString.startsWith(prefix)) {
      return originalString.substring(prefix.length);
    }
    return originalString;
  }
  getServices() {
    this.homeService.getServices().subscribe((result) => {
      this.services = result;
      if (this.services.length > 0) {
        this.currentService = this.services[0];
      }
    });
  }

  getStatistics() {
    this.homeService.getStatistics().subscribe((result) => {
      this.statistics = result;
      $(".statics-slider .item").hover(
        function () {
          $(this).find("p").slideDown();
          $(this).css("transform", "translateY(-15%)");
        },
        function () {
          $(this).find("p").slideUp();
          $(this).css("transform", "unset");
        }
      );
    });
  }
  surveyName(survey: { id: any; surveyType: string; language: string, title }) {
    const id = survey.id;
    const lang = survey.language;
    console.log(survey, "surveyIdddd");

    this.surveyService
      .ClickedSurvey(survey)
      .pipe(first())
      .subscribe((result) => {
        console.log("result", result);
      });
    const eventAction = survey?.title || 'Survey_Clicked';

    gtag('event', eventAction, {
      event_category: 'Button',
      event_label: 'Survey Clicked',
    });
    this.surveyService
      .getSurveyById(id)
      .pipe(first())
      .subscribe((result) => {
        this.showLoader = true;
        let values = {
          ...survey,
          ...result,
        };
        this.surveyService.createLocalSurveyModal(values);
        if (survey.surveyType == "Enterprise Survey") {
          this.router.navigate(["/surveys/enterprise"]);
        } else {

          this.router.navigate([`/survey/start/${id}/${lang}`]);
          // const verifyModel = this.modalService.show(SurveyNameComponent, {
          //   class: "modal-dialog-centered sign-up c-survey-modal",
          //   backdrop: "static",
          // });
          // verifyModel.content.onClose = (auth) => {
          //   verifyModel.hide();
          // };
        }
      });
  }
  slickInit(e) {
    console.log("slick initialized");
  }

  mouseEnter(service) {
    this.currentService = service;
  }

  filterFeaturedSurvey() {
    this.survey.featured.all = this.homeService.deepCopy(
      this.survey.all.filter((x) => x.isFeatured)
    );
    this.loadMoreSurvey("featured", "allFeatured");
  }

  filterMostViwedSurvey() {
    this.survey.mostViewed.all = this.homeService.deepCopy(this.survey.all.filter((x) => x.isPublished));
    this.loadMoreSurvey("mostViewed", "allMostViewed");
  }
  // 
  filterLatestSurvey() {
    this.survey.latest.all = this.homeService.deepCopy(this.survey.all.filter((x) => x.isPublished));
    // this.survey.latest.all.reverse();
    this.loadMoreSurvey("latest", "allLatest");
  }
  tabChange(page, limit, key) {
    this.showLoader = true
    let data = [];
    this.surveyService.getAllSurvey(page, limit, key).subscribe(
      (result) => {
        data = result.list
        this.count = result.count
        this.currentPage = result.currentPage

        this.survey.all = data?.length ? data : [];
        if (this.survey.all.length) {
          if (key == "isFeatured") {
            this.count = result.count
            this.filterFeaturedSurvey();
          } else if (key == 'isPublished') {

            this.filterMostViwedSurvey();
          } else {
            this.count = result.count
            this.filterLatestSurvey();
          }
          // this.filterAnwseredSurvey();
          // this.filterPollSurvey();
        }
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
      }
    );
  }
  async newLoadMore(key: string, getKey: any, masterKey: any, currentPage?: number) {
    this.showLoader = true
    // if (this.survey[key].display?.length === this.count) {
    //   this.survey[key].displayLimit = this.survey[key].all?.length;
    //   return;
    // }
    debugger
    this.survey[key].displayLimit += 6;
    let surveys = []
    // await this.surveyService.getAllSurvey(currentPage + 1, 6, getKey).subscribe(
    //   (result) => {
    //     console.log("🚀 ~ file: not-loggedin-home.component.ts:432 ~ NotLoggedinHomeComponent ~ newLoadMore ~ result:", result.list)


    //     surveys = [...masterKey, ...result.list]
    //     debugger
    //     this.count = result.count
    //     this.currentPage = result.currentPage
    //   })
    // console.log("🚀 ~ file: not-loggedin-home.component.ts:430 ~ NotLoggedinHomeComponent ~ newLoadMore ~ surveys:", surveys)
    // this.survey[key].display = this.homeService.deepCopy(surveys);
    try {
      const result = await this.surveyService.getAllSurvey(currentPage + 1, 6, getKey).toPromise();


      // Add new data to the surveys array
      surveys = [...masterKey, ...result.list, /* Add your new data here */];

      debugger;
      this.count = result.count;
      this.currentPage = result.currentPage;
      this.showLoader = false

    } catch (error) {
      // Handle error if needed
      console.error("Error fetching survey data:", error);
    }


    this.survey[key].display = this.homeService.deepCopy(surveys);
  };

  filterAnwseredSurvey() {
    this.survey.answers.all = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("answers", "allAnswers");
  }

  filterPollSurvey() {
    this.survey.polls.all = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("polls", "allPolls");
  }

  loadMoreSurvey(key: string, masterKey?: string) {
    if (this.survey[key].display?.length === this.survey[key].all?.length) {
      this.survey[key].displayLimit = this.survey[key].all?.length;
      return;
    }

    this.survey[key].displayLimit += 6;

    let surveys = [];
    for (let i = 0; i < this.survey[key].displayLimit; i++) {
      if (this.survey[key].all[i]) {
        const survey = this.survey[key].all[i];
        if (survey) {
          surveys.push(survey);
        }
      } else {
        this.survey[key].displayLimit = this.survey[key].all?.length;
        break;
      }
    }
    this.survey[key].display = this.homeService.deepCopy(surveys);
  }


  viewAnsweredSurvey(survey: any) {
    this.router.navigate(["/survey/preview", btoa(survey.id)]);
  }
  submit() {
    this.showLoader = true;
    if (!this.newsAttempt) {
      this.newsAttempt = true;
    }
    if (!this.newsletter.valid) {
      if (this.newsAttempt && !this.subErrorToastShown) {

        this.translateService.get("home.valid").subscribe((text) => {
          this.toastr.warning(text);
        });
        this.subErrorToastShown = true
      }
      this.showLoader = false;

    } else {
      this.homeService.addNewsletter(this.newsletter.value).subscribe((newsletter) => {
        this.translateService
          .get("home.success")
          .subscribe((text) => {
            this.toastr.success(text);
          });
        this.homeService.markFormAsSubscribe()
        this.newsletter.reset()
        this.showLoader = false;
        this.newsAttempt = false;
      })
    }
  }
}
