<div class="preview-modal-body">
  <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <img src="assets/imgs/times-icon.svg" class="w-75" alt="" />
      </button>

      <img class="modal-preview" *ngIf="imageUrl" [src]="imageUrl"/>


  <!-- <div class="modal-preview" *ngIf="imageUrl" [ngStyle]="{'background-image': 'url('+ imageUrl +')'}">

  </div> -->
</div>
