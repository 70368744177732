import { Country } from "country-state-city";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { HomeService } from "../../../services/home.service";
import { ToastrService } from "ngx-toastr";
import { WebApiService } from "../../../services/webApi.service";
import { saveAs } from "file-saver";
import jspdf from "jspdf";

import { BaseChartDirective } from "ng2-charts";
import { LanguageService } from "../../../services/language.service";
import * as moment from "moment/moment";
import { SurveyService } from "../../../services/survey.service";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import * as _html2canvas from "html2canvas";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/services/auth.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { PricingComponent } from "../pricing/pricing.component";
import { eLanguage } from "src/app/general/enums/language.enum";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
const html2canvas: any = _html2canvas;

@Component({
  selector: "app-reporting",
  templateUrl: "./reporting.component.html",
  styleUrls: ["./reporting.component.css"],
})
export class ReportingComponent implements OnInit {
  language: "";
  lang: any;
  survey: any;
  showLoader: any;
  isButtonDisabled = false;
  showAllSurveys: boolean = false;
  isSidebarVisible = true;
  constructor(
    private router: Router,
    private homeService: HomeService,
    private webApi: WebApiService,
    private surveyService: SurveyService,
    public authService: AuthService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    public darkThemeService: DarkLiteThemeService,
    private breakpointObserver: BreakpointObserver,

    public languageService: LanguageService
  ) {
    this.lang =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    console.log(
      "🚀 ~ file: reporting.component.ts:49 ~ ReportingComponent ~ lang:",
      this.lang
    );
  }
  @ViewChild("content", { static: false }) content: ElementRef;

  currentLanguage = "en";
  showingFile = false;
  // Data Store
  surveyData = [];
  clickedSurvey = [];

  userStatsReport: any = [];
  female: any;
  male: any;
  mobile: any;
  web: any;
  userStats: any;
  total: any;
  kafu: any;
  free: any;
  test: any;
  country: any[] = [];
  label: any;
  clickWeb: any;
  clickMobile: any;
  count: any;
  network: any;
  promotion: any;
  likes: any;
  webAnswers: any;
  mobileAnswers: any;
  // Define your chart data here
  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function (value: any) {
              const maxLength = 10; // Set your desired maximum length
              return value.length > maxLength
                ? value.substring(0, maxLength) + "..."
                : value;
            },
          },
        },
      ],
      yAxes: [{}],
    },
  };
  chartHeight = 14; // Set the desired height

  barChartLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  barChartType: ChartType = "bar" as ChartType;
  barChartLegend = false;

  barChartDataForWebSurveyAnswer: ChartDataSets[] = [
    // { data: [65, 59, 80, 81, 56, 55, 40, 20], label: "Series A" },
  ];

  barChartDataForMobileSurveyAnswer: ChartDataSets[] = [];
  barChartDataForLike: ChartDataSets[] = [];
  barCountryChartLabels: ChartDataSets[] = [];
  barCountryChartType: ChartType = "bar" as ChartType;
  barCountryChartLegend = false;
  doughnutChartDataClick: ChartDataSets[] = [];
  barChartDataCountry: ChartDataSets[] = [];
  // Doughnut Chart
  doughnutChartLabels: string[] = ["Kafu Network", "Kafu Promotion"];
  lineChartLabels = [];
  lineChartData: ChartDataSets[] = [];
  doughnutChartData: number[] = [];
  lineChartLegend = false;
  lineChartType: ChartType = "line" as ChartType;

  fellowGenderDoughnutChartLabels: string[] = ["Mobile", "Web"];
  fellowGenderDoughnutChartData: number[] = [50, 50];
  files: [];
  doughnutChartType: ChartType = "doughnut" as ChartType;
  doughnutChartLegend = false;
  mobileAnswersPercentage: any;
  webAnswersPercentage: any;
  progress1 = 25; // Change this value as needed
  progress2 = 50; // Change this value as needed
  progress3 = 75; // Change this value as needed

  @ViewChild("myChart", { static: true }) myChart: BaseChartDirective;

  ngOnInit(): void {
    this.currentLanguage = this.languageService.getLanguageOrDefault();
    console.log(
      "🚀 ~ file: reporting.component.ts:147 ~ ReportingComponent ~ ngOnInit ~ currentLanguage:",
      this.currentLanguage
    );
    this.surveyFileDownload();
    this.getUserAllStats();
    this.getAllSurveys();
    this.getUserAllSurveyReport();
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => {
        if (result.matches) {
          this.isSidebarVisible = false;
        }
      });
  }

  extractSurveyNumbers(conditions: string[]): number | null {
    for (const condition of conditions) {
      const match = condition.match(/(\d+)\s+report/i);

      if (match) {
        return parseInt(match[0], 10);
      }
    }

    return null;
  }
  checkValidity() {
    return new Promise((resolve, reject) => {
      const planId = this.authService.currentUser?.planId;
      const reportLimit = this.extractSurveyNumbers(planId?.description);
      const pdfNumber = this.files.filter((item: any) => item.type === "pdf");

      if (!planId) {
        console.log("Please select a plan");
        const PlanModel = this.modalService.show(PricingComponent, {
          class: "modal-dialog1 modal-content1",
          backdrop: "static",
        });
        PlanModel.content.onClose = () => {
          PlanModel.hide();
          reject("Plan not selected");
        };
      } else if (
        planId.type === "free" &&
        pdfNumber.length &&
        reportLimit !== null &&
        pdfNumber.length >= reportLimit
      ) {
        this.translateService
          .get("newSurvey.surveyLimitExceeded")
          .subscribe((text) => {
            this.toastr.error(text);
            reject("Survey limit exceeded");
          });
      } else {
        resolve(true); // Validity checks passed
      }
    });
  }

  getUserAllSurveyReport() {
    this.showLoader = true;
    this.homeService.getUserSurveyReport().subscribe((result) => {
      this.surveyData = result?.surveys?.answer;
      this.clickedSurvey = result?.surveys?.clicked;
      this.network = result.UserReport.mobile;
      this.promotion = result.UserReport.web;
      this.showLoader = false;

      this.likes = this.groupByDayWithPercentage(result?.surveys?.likes || []);
      this.webAnswers = result.surveys?.answer.filter(
        (ans: any) => ans.channel == "web"
      );

      this.mobileAnswers = result?.surveys?.answer.filter(
        (ans: any) => ans.channel == "mobile"
      );
      this.mobileAnswersPercentage = Math.ceil(
        (this.mobileAnswers?.length /
          (this.webAnswers?.length + this.mobileAnswers?.length)) *
          100
      );
      this.webAnswersPercentage = Math.floor(
        (this.webAnswers?.length /
          (this.webAnswers?.length + this.mobileAnswers?.length)) *
          100
      );
      this.clickWeb = result.surveys?.clicked.filter(
        (ans: any) => ans.channel == "web"
      );
      this.clickMobile = result.surveys?.clicked.filter(
        (ans: any) => ans.channel == "mobile"
      );
      this.doughnutChartDataClick = [
        this.clickMobile?.length || 0,
        this.clickWeb?.length,
      ];
      this.doughnutChartData = [this.network, this.promotion];

      const dailyData = this.groupDataByMonth(this.webAnswers?.sort() || []);

      const dailyDataMob = this.groupDataByMonth(
        this.mobileAnswers?.sort() || []
      );
      const allMonths = Array.from(
        new Set([...dailyData, ...dailyDataMob].map((entry) => entry.month))
      ).sort();

      // Extract unique days and counts
      this.barChartLabels = dailyData.map((entry) => entry.month).sort();
      // this.barChartLabels = Object.keys(groupedData).sort();

      const webCounts = allMonths.map(
        (month) => dailyData.find((entry) => entry.month === month)?.count || 0
      );
      const mobileCounts = allMonths.map(
        (month) =>
          dailyDataMob.find((entry) => entry.month === month)?.count || 0
      );

      this.barChartDataForWebSurveyAnswer = [
        {
          data: webCounts,
          label: "Monthly Answers",
        },
      ];
      this.barChartDataForLike = [
        {
          data: this.likes.map((item: any) => item.count),
          label: "Like Survey of Month ",
        },
      ];
      this.barChartDataForMobileSurveyAnswer = [
        {
          data: mobileCounts,
          label: "Monthly Answers",
          borderColor: "rgb(255, 174, 59)",
          backgroundColor: "rgb(255, 174, 59)",
        },
      ];
      this.showLoader = false;
    });
  }

  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
  getUserAllStats() {
    this.homeService.getUserStats().subscribe((result) => {
      this.userStats = result;
      const femalePercentage = Math.ceil(
        (result.female / (result.female + result.male)) * 100
      );
      const malePercentage = Math.floor(
        (result.male / (result.female + result.male)) * 100
      );
      this.fellowGenderDoughnutChartData = [femalePercentage, malePercentage];

      this.female = result && result.female ? result.female : 0;
      this.male = result && result.male ? result.male : 0;
      this.mobile = result && result.mobile ? result.mobile : 0;
      this.web = result && result.web ? result.web : 0;
      this.total = result.country?.length;
      this.kafu = result.UserReport.kafu ?? 0;
      this.free = result.UserReport.free ?? 0;
      this.test = this.arrlabel(result.UserReport?.clicked);

      this.country = this.countDuplicates(
        result?.country?.filter((country: any) => country !== null)
      );
      this.barCountryChartLabels = this.country.map(
        (country: any) => country.name
      );
      this.barChartDataCountry = [
        {
          data: this.country.map((country: any) => country.count),
          label: "Series A",
        },
      ];
    });
  }

  downloadPdf() {
    // this.isButtonDisabled = true

    var data = document.getElementById("capture");
    html2canvas(data).then((canvas) => {
      console.log("🚀 ~ ReportingComponent ~ html2canvas ~ canvas:", canvas);
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      console.log(
        "🚀 ~ ReportingComponent ~ html2canvas ~ imgHeight:",
        imgHeight
      );

      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf(); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);

      pdf.save("report.pdf"); // Generated PDF
    });
    const fileName = { name: "Basic Report", type: "pdf" };

    this.Download(fileName);
    this.surveyFileDownload();
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 60000);
  }

  async captureAndConvertToPdf() {
    console.log("capturing");
    // this.isButtonDisabled = true;
    if (
      this.authService.currentUser &&
      this.authService.currentUser.planId &&
      this.authService.currentUser.planId.type === "free"
    ) {
      const isValid = await this.checkValidity();
      console.log(
        "🚀 ~ ReportingComponent ~ captureAndConvertToPdf ~ isValid:",
        isValid
      );

      if (isValid) {
        this.downloadPdf();
      } else {
        this.translateService
          .get("newSurvey.surveyLimitExceeded")
          .subscribe((text) => {
            this.toastr.error(text);
          });
      }
    } else {
      this.downloadPdf();
    }
    // setTimeout(() => {
    //   this.isButtonDisabled = false
    // }, 60000)
  }

  countDuplicates(arr: string[]) {
    const counts: { [key: string]: number } = {};

    arr.forEach((str) => {
      counts[str] = (counts[str] || 0) + 1;
    });

    // Use Object.entries to convert the counts object into an array of [name, count] pairs
    const entries = Object.entries(counts);

    // Use map to transform the entries and filter out entries where count is null
    const result = entries
      .map(([name, count]) => ({ name, count }))
      .filter((entry) => entry.count !== null);
    return result;
  }

  surveyFileDownload() {
    this.surveyService.getUserFileDownload().subscribe((result) => {
      this.files = result;
    });
  }

  arrlabel(strArray: any) {
    // Ensure this.label is initialized as an empty array
    this.label = this.label || [];
    this.count = this.count || [];

    strArray.forEach((str: any) => {
      const index = this.label.indexOf(str);
      if (index !== -1) {
        this.count[index]++;
      } else {
        this.label.push(str);
        this.count.push(1);
      }
    });
  }
  Download(data: object): void {
    this.webApi.basicPdfReport(data).subscribe((response: any) => {
      // const blob: any = new Blob([response], { type: response.type });
      // saveAs(blob, "report.pdf");
    });
  }
  getAllSurveys() {
    let data = [];

    this.homeService.getMySurvey().subscribe(
      (result) => {
        if (result?.length) {
          this.survey = result;

          data = result.filter((item: any) => {
            // Find the distance between now and the count down date
            let surveyFinished =
              new Date(item.endDate).getTime() - new Date().getTime();
            item["days"] = Math.floor(surveyFinished / (1000 * 60 * 60 * 24));
            item["hours"] = Math.floor(
              (surveyFinished % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            item["minutes"] = Math.floor(
              (surveyFinished % (1000 * 60 * 60)) / (1000 * 60)
            );
            item["seconds"] = Math.floor((surveyFinished % (1000 * 60)) / 1000);
            return (
              new Date(item.startDate) <= new Date() &&
              moment().isBefore(moment(item.endDate), "minute") &&
              item.language === this.language
            );
          });
          this.lineChartLabels = this.survey.map((item) => item.title);
          this.lineChartData = [
            {
              data: this.survey.map((item) => item.kafuRes || 0),
              label: "Kafu Response",
            },
            {
              data: this.survey.map((item) => item.generalRes || 0),
              label: "General Response",
            },
          ];

          this.survey.all = data?.length ? this.homeService.deepCopy(data) : [];
          if (this.survey.all.length) {
            this.survey.all = this.survey.all.map((x) => {
              x.isStartClicked = false;
              return x;
            });
            this.filterFeaturedSurvey();
            this.filterMostViwedSurvey();
            // this.filterAnwseredSurvey();
            this.filterLatestSurvey();
            this.filterPollSurvey();
          }
        }
      },
      (err) => {}
    );
  }

  filterLatestSurvey() {
    this.survey.latest.all = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("latest", "allLatest");
  }

  filterPollSurvey() {
    this.survey.polls.all = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("polls", "allPolls");
  }
  toggleShowAllSurveys() {
    this.showAllSurveys = !this.showAllSurveys;
  }
  toggleShowAllFile() {
    this.showingFile = !this.showingFile;
  }
  filterMostViwedSurvey() {
    this.survey.mostViewed.all = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("mostViewed", "allMostViewed");
  }

  filterFeaturedSurvey() {
    this.survey.featured.all = this.homeService.deepCopy(
      this.survey.all.filter((x) => x.isFeatured)
    );
    this.loadMoreSurvey("featured", "allFeatured");
  }

  loadMoreSurvey(key: string, masterKey?: string) {
    if (this.survey[key].display?.length === this.survey[key].all?.length) {
      this.survey[key].displayLimit = this.survey[key].all?.length;
      return;
    }

    this.survey[key].displayLimit += 6;

    const surveys = [];
    for (let i = 0; i < this.survey[key].displayLimit; i++) {
      if (this.survey[key].all[i]) {
        const survey = this.survey[key].all[i];
        if (survey) {
          surveys.push(survey);
        }
      } else {
        this.survey[key].displayLimit = this.survey[key].all?.length;
        break;
      }
    }
    this.survey[key].display = this.homeService.deepCopy(surveys);
  }

  dataToCSV(surveyData: any): string {
    const header = Object.keys(surveyData[0]).join(",");

    const rows = surveyData.map((item) => Object.values(item).join(","));

    return `${header}\n${rows.join("\n")}`;
  }
  downloadCSV() {
    this.isButtonDisabled = true;
    if (
      this.authService.currentUser &&
      this.authService.currentUser.planId &&
      this.authService.currentUser.planId.type === "free"
    ) {
      this.translateService
        .get("newSurvey.surveyLimitExceeded")
        .subscribe((text) => {
          this.toastr.error(text);
        });
    } else {
      this.surveyData = this.surveyData.map(
        ({ _id, surveyId, __v, questions, ...ans }) => {
          const mapping = this.survey.find((item) => item.id === surveyId?._id);
          const mobile = this.clickedSurvey.filter(
            (item: any) =>
              item.channel == "mobile" && item.survey == surveyId?._id
          );
          const web = this.clickedSurvey.filter(
            (item: any) => item.channel == "web" && item.survey == surveyId?._id
          );

          const flattenedQuestions = questions
            ?.map((question) => {
              const selectedAnswerText =
                question.selectedAnswer !== undefined
                  ? `, Selected Answer: ${question.selectedAnswer}`
                  : "";
              const textAnswerText =
                question.textAnswer !== undefined
                  ? `, Text Answer: ${question.textAnswer}`
                  : "";

              let answersText = "";
              if (question.answers && question.answers.length > 0) {
                const answersList = question.answers
                  .map((a) => `${a.optionIndex}: ${a.option}`)
                  .join(", ");
                answersText = `, Answers: ${answersList}`;
              }

              return `Question Type: ${question.questionType}, Question: ${question.question}${answersText}${selectedAnswerText}${textAnswerText}`;
            })
            .join("; ");

          if (mapping) {
            return {
              ...ans,
              "Survey Name": mapping.title,
              Response: mapping.totalResponse,
              "Kafu Rsp": mapping.kafuRes,
              "General Rsp": mapping.generalRes,
              click: mapping.click,
              likes: mapping.likes,
              WebSurveyClicks: web.length || 0,
              mobileSurveyClicks: mobile.length || 0,
              questions: flattenedQuestions,
            };
          }
          return ans;
        }
      );

      const csvData = this.dataToCSV(this.surveyData);
      console.log(
        "🚀 ~ ReportingComponent ~ downloadCSV ~ this.surveyData:",
        this.surveyData
      );

      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "report.csv";
      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
      const fileName = { name: "Basic Report", type: "csv" };

      this.Download(fileName);
      this.surveyFileDownload();
    }
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 60000);
  }

  downloadExcel() {
    this.isButtonDisabled = true;
    if (
      this.authService.currentUser &&
      this.authService.currentUser.planId &&
      this.authService.currentUser.planId.type === "free"
    ) {
      this.translateService
        .get("newSurvey.surveyLimitExceeded")
        .subscribe((text) => {
          this.toastr.error(text);
        });
    } else {
      import("xlsx").then((xlsx) => {
        this.surveyData = this.surveyData.map(
          ({ _id, surveyId, __v, questions, ...ans }) => {
            const mapping = this.survey.find(
              (item: any) => item.id === surveyId
            );
            const mobile = this.clickedSurvey.filter(
              (ans: any) => ans.channel == "mobile" && ans.survey == surveyId
            );
            const flattenedQuestions = questions
              ?.map((question) => {
                const selectedAnswerText =
                  question.selectedAnswer !== undefined
                    ? `, Selected Answer: ${question.selectedAnswer}`
                    : "";
                const textAnswerText =
                  question.textAnswer !== undefined
                    ? `, Text Answer: ${question.textAnswer}`
                    : "";

                let answersText = "";
                if (question.answers && question.answers.length > 0) {
                  const answersList = question.answers
                    .map((a) => `${a.optionIndex}: ${a.option}`)
                    .join(", ");
                  answersText = `, Answers: ${answersList}`;
                }

                return `Question Type: ${question.questionType}, Question: ${question.question}${answersText}${selectedAnswerText}${textAnswerText}`;
              })
              .join("; ");
            const web = this.clickedSurvey.filter(
              (ans: any) => ans.channel == "web" && ans.survey == surveyId
            );

            if (mapping) {
              return {
                ...ans,
                "Survey Name": mapping.title,
                Response: mapping.totalResponse,
                click: mapping.click,
                "Kafu Res": mapping.KafuRes,
                "General Res": mapping.generalRes,
                likes: mapping.likes,
                WebSurveyClicks: web.length || 0,
                mobileSurveyClicks: mobile.length || 0,
                questions: flattenedQuestions,
              };
            }
            return ans;
          }
        );
        const ws = xlsx.utils.json_to_sheet(this.surveyData);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        xlsx.writeFile(wb, "report.xlsx");
      });

      const fileName = { name: "Basic Report", type: "xlsx" };
      this.Download(fileName);
      this.surveyFileDownload();
    }
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 60000);
  }

  stopSurvey(survey: any) {
    this.surveyService.stop(survey).subscribe((result) => {
      this.translateService.get("update_success_message").subscribe((text) => {
        this.toastr.success(text);
      });
    });

    this.getUserAllStats();
    this.getUserAllSurveyReport();
    this.getUserAllStats();
  }

  edit(survey) {
    this.surveyService.edit(survey);
  }

  detail(survey: any) {
    // this.surveyService.createLocalSurveyDetail(survey);
    // this.router.navigate(["/survey/details"]);
    this.surveyService.createLocalSurveyModal(survey);
    this.router.navigate([`/survey/details`]);
  }

  groupDataByDay(data: any[]): { day: string; count: number }[] {
    const dayCounts = {};

    data.forEach((item) => {
      const date = new Date(item.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // January is 0, so add 1
      const day = date.getDate();
      const dayKey = `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;

      if (dayCounts[dayKey]) {
        dayCounts[dayKey]++;
      } else {
        dayCounts[dayKey] = 1;
      }
    });

    return Object.keys(dayCounts).map((dayKey) => ({
      day: dayKey,
      count: dayCounts[dayKey],
    }));
  }
  groupDataByMonth(data: any[]): { month: string; count: number }[] {
    const monthCounts = {};

    data.forEach((item) => {
      const date = new Date(item.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // January is 0, so add 1
      const monthKey = `${year}-${month < 10 ? "0" : ""}${month}`;

      if (monthCounts[monthKey]) {
        monthCounts[monthKey]++;
      } else {
        monthCounts[monthKey] = 1;
      }
    });

    return Object.keys(monthCounts).map((monthKey) => ({
      month: monthKey,
      count: monthCounts[monthKey],
    }));
  }

  groupByDayWithPercentage(
    data: any[]
  ): { day: string; count: number; percentage: number }[] {
    const dayCounts = {};
    const totalItems = data?.length;

    data.forEach((item) => {
      const date = new Date(item.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // January is 0, so add 1
      const day = date.getDate();
      const dayKey = `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;

      if (dayCounts[dayKey]) {
        dayCounts[dayKey]++;
      } else {
        dayCounts[dayKey] = 1;
      }
    });

    return Object.keys(dayCounts).map((dayKey) => ({
      day: dayKey,
      count: dayCounts[dayKey],
      percentage: (dayCounts[dayKey] / totalItems) * 100,
    }));
  }
}
