<app-header></app-header>

<!-- Start Wrapper -->
<div class="wrapper privacy-terms-wrap" [ngClass]="darkThemeService.getCurrentTheme">
  <!-- Start Main -->
  <main>
    <div class="pg-head">
      <div class="container-fluid">
        <div class="d-flex">
          <div>
            <h1 class="title">{{ "survey.privacyPolicy" | translate }}</h1>
            <div class="kf-breadcrumb">
              <ul>
                <li>
                  <a>{{ "header.Home" | translate }}</a>
                </li>
                <li>
                  <a>{{ "survey.privacyPolicy" | translate }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Privacy Policy -->
    <section class="-container">
      <div class="container-fluid">
        <div class="row">
          <!-- <div class="col-lg-6">
            <img src="assets/imgs/privacy-pic.png" alt="" />
          </div> -->
          <div class="col-lg-12">
            <p>{{ "Privacy.des" | translate }}</p>
            <p>{{ "Privacy.des1" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Tableofcontents" | translate }}
            </h3>
            <div class="col-12 mt-1">
              <ol>
                <li>
                  <a>{{
                    "Privacy.Collection of personal information" | translate
                    }}</a>
                </li>

                <li>
                  <a>{{ "Privacy.Privacy of children" | translate }}</a>
                </li>
                <li>
                  <a>{{
                    "Privacy.Use and processing of collected information"
                    | translate
                    }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Managing information" | translate }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Disclosure of information" | translate }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Retention of information" | translate }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Cookies" | translate }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Data analytics" | translate }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Do Not Track signals" | translate }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Email marketing" | translate }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Push notifications" | translate }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Links to other resources" | translate }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Information security" | translate }}</a>
                </li>
                <li>
                  <a>{{ "Privacy.Data breach" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Changesandamendments" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Acceptanceoftheseterms" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Contactingus" | translate }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-12 mt-1" id="Accountsandmembership">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Collection of personal information" | translate }}
            </h3>
            <p>{{ "Privacy.CollectionDes" | translate }}</p>
            <p>{{ "Privacy.CollectionDes1" | translate }}</p>
            <ul>
              <li>{{ "Privacy.l1" | translate }}</li>
              <li>{{ "Privacy.l2" | translate }}</li>
              <li>{{ "Privacy.l3" | translate }}</li>
              <li>{{ "Privacy.l4" | translate }}</li>
            </ul>
            <p>{{ "Privacy.CollectionDesLast" | translate }}</p>
          </div>
          <div class="col-12 mt-1" id="Accountsandmembership">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Privacy of children" | translate }}
            </h3>
            <p>{{ "Privacy.privacyDes" | translate }}</p>
            <p>{{ "Privacy.privacyDes1" | translate }}</p>
          </div>
          <div class="col-12 mt-1" id="Accountsandmembership">
            <h3 class="font-700 font-size-20 my-2">
              {{
              "Privacy.Use and processing of collected information"
              | translate
              }}
            </h3>
            <p>{{ "Privacy.UseDes" | translate }}</p>
            <p>{{ "Privacy.UseDes1" | translate }}</p>
            <p>{{ "Privacy.useDes2" | translate }}</p>
            <p>{{ "Privacy.useDes3" | translate }}</p>
            <ol>
              <li>{{ "Privacy.useL1" | translate }}</li>
              <li>{{ "Privacy.useL2" | translate }}</li>
              <li>{{ "Privacy.useL3" | translate }}</li>
              <li>{{ "Privacy.useL4" | translate }}</li>
              <li>{{ "Privacy.useL5" | translate }}</li>
              <li>{{ "Privacy.useL6" | translate }}</li>
              <li>{{ "Privacy.useL7" | translate }}</li>
              <li>{{ "Privacy.useL8" | translate }}</li>
              <li>{{ "Privacy.useL9" | translate }}</li>
            </ol>
            <p>{{ "Privacy.useL10" | translate }}</p>
            <p>{{ "Privacy.L11" | translate }}</p>
          </div>
          <div class="col-12 mt-1" id="Accountsandmembership">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Managing information" | translate }}
            </h3>
            <p>{{ "Privacy.ManagingDes" | translate }}</p>
          </div>
          <div class="col-12 mt-1" id="Accountsandmembership">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Retention of information" | translate }}
            </h3>
            <p>{{ "Privacy.RetentionDes" | translate }}</p>
          </div>
          <div class="col-12 mt-1" id="Accountsandmembership">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Cookies" | translate }}
            </h3>
            <p>{{ "Privacy.CookiesDes" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Data analytics" | translate }}
            </h3>
            <p>{{ "Privacy.DataDes" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Do Not Track signals" | translate }}
            </h3>
            <p>{{ "Privacy.DoDes" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Email marketing" | translate }}
            </h3>

            <p>{{ "Privacy.EmailDes" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Push notifications" | translate }}
            </h3>
            <p>{{ "Privacy.PushDes" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Links to other resources" | translate }}
            </h3>
            <p>{{ "Privacy.linkDes" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Information security" | translate }}
            </h3>
            <p>{{ "Privacy.InformationDes" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "Privacy.Data breach" | translate }}
            </h3>
            <p>{{ "Privacy.DataDesc" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Changesandamendments" | translate }}
            </h3>
            <p>{{ "terms.changedes1" | translate }}</p>
            <p>{{ "terms.changedes2" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Acceptanceoftheseterms" | translate }}
            </h3>
            <p>{{ "terms.Acceptdes" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Contactingus" | translate }}
            </h3>
            <p>{{ "terms.desc" | translate }}</p>
            <div>
              <a href="https://www.kafuapp.com" target="_blank" class="link">https://www.kafuapp.com</a>
            </div>
            <a href="mailto:support@kafuapp.com" class="link">support@kafuapp.com</a>
          </div>
        </div>
      </div>
    </section>
    <!-- /Privacy Policy -->
  </main>
  <!-- End Main -->
</div>
<!-- End wrapper -->

<app-footer></app-footer>