<app-header></app-header>

<!-- Start Wrapper -->
<div class="wrapper privacy-terms-wrap fullbg">
  <!-- Start Main -->
  <main>
    <!-- <div class="pg-head">
      <div class="container-fluid">
        <div class="d-flex">
          <div>
            <h1 class="title">Privacy Policy</h1>
            <div class="kf-breadcrumb">
              <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Privacy Policy -->
    <section class="-container">
      <div class="container-fluid">
        <div class="d-md-flex content justify-content-center">
          <div class="text-center not-found">
            <!-- <img src="assets/imgs/privacy-pic.png" alt="" /> -->
            <h1>404</h1>
            <h2 class="text-uppercasr">{{'notFound.pageNotFound' | translate}}</h2>
          </div>
          <div class="col-sm-8 Opps">
            <h1>{{'notFound.Opps' | translate}}</h1>
            <h2>{{'notFound.thisPageWasNotFound' | translate}}</h2>
            <p>{{'general.defaultDetail' | translate }}</p>
          </div>
        </div>
      </div>
    </section>
    <!-- /Privacy Policy -->
  </main>
  <!-- End Main -->
</div>
<!-- End wrapper -->

<app-footer></app-footer>
