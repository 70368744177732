<div class="counter-container">
  <div class="counter-area" *ngIf="showDays">
    <b [class]="countClass">{{ daysToDday }}</b>
    <span [class]="countLabelClass">{{ "general.days" | translate }}</span>
  </div>
  <div *ngIf="showSeparator">
    <span [class]="separatorClass" *ngIf="showDays">:</span>
  </div>
  <div class="counter-area">
    <b [class]="countClass">{{ hoursToDday }}</b>
    <span [class]="countLabelClass">{{ "general.hours" | translate }}</span>
  </div>
  <div *ngIf="showSeparator">
    <span [class]="separatorClass">:</span>
  </div>
  <div class="counter-area">
    <b [class]="countClass">{{ minutesToDday }}</b>
    <span [class]="countLabelClass">{{ "general.mins" | translate }}</span>
  </div>
  <div *ngIf="showSeparator">
    <span [class]="separatorClass">:</span>
  </div>
  <div class="counter-area">
    <b id="seconds" [class]="countClass">{{ secondsToDday }}</b>
    <span [class]="countLabelClass">{{ "general.secs" | translate }}</span>
  </div>
</div>