import { Component, OnInit } from "@angular/core";
import { DarkLiteThemeService } from "../../../services/dark-lite-themeService.service";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { eSurveyQuestionTypes } from "../../general/enums/survey-question-type.enum";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../services/auth.service";
import { SurveyService } from "../../../services/survey.service";
import { ToastrService } from "ngx-toastr";
import { BsModalService } from "ngx-bootstrap/modal";
import { LanguageService } from "../../../services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "../../../services/home.service";
import { eLanguage } from "../../general/enums/language.enum";
import { SurveyNameComponent } from "../survey-name/survey-name.component";
import { DomSanitizer } from "@angular/platform-browser";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-new-ques-answer",
  templateUrl: "./new-ques-answer.component.html",
  styleUrls: ["./new-ques-answer.component.css"],
})
export class NewQuesAnswerComponent implements OnInit {
  currentPage = 0;
  progress = 0;
  totalPages = 0;
  rating = 0;
  activeWidth: any;
  showLastPage = false;
  buttonClicked = false;
  showLoader = false;
  likes: any;
  surveyForm: FormGroup;
  singleQuestion: FormArray;
  dummySurvey: any;
  surveyId: any;
  startDate: any;
  endDate: any;
  userId: any;
  gender: any;
  answerUserName: any;
  selectedAnswer: any;
  eQuesTypes = eSurveyQuestionTypes;
  currentQuestionNo: number;
  questionValidation = false;
  questionAnimationClass = "d-none";
  lang: any;
  displayStates = {
    invite: true,
    surveyDetails: false,
    question: false,
  };

  survey: any;

  constructor(
    public darkThemeService: DarkLiteThemeService,
    private _router: Router,
    private _fb: FormBuilder,
    private _ac: ActivatedRoute,
    private authService: AuthService,
    private surveyService: SurveyService,
    private toasterService: ToastrService,
    private bsModalService: BsModalService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private homeService: HomeService,
    private sanitizer: DomSanitizer
  ) {
    let currentUser = this.authService.currentUser;
    const languages = [eLanguage.Arabic, eLanguage.English];
    this.lang =
      this._ac.snapshot.params?.lang &&
      languages.includes(this._ac.snapshot.params?.lang)
        ? this._ac.snapshot.params?.lang
        : eLanguage.English;
    console.log(this.lang, "current language");
    const langPrefix =
      this.lang === eLanguage.Arabic
        ? eLanguage.Short_Arabic
        : eLanguage.Short_English;
    if (this.languageService.getLanguageOrDefault() !== langPrefix) {
      this.languageService.setLanguage(langPrefix);
      window.location.reload();
    }
    currentUser = currentUser
      ? { ...currentUser, language: this.lang }
      : { language: this.lang };
    this.authService.setCurrentUser(currentUser, null);
  }

  async ngOnInit() {
    this.singleQuestion = this._fb.array([]);
    this._ac.snapshot.params?.surveyId
      ? await this.getSurveyById()
      : this.surveyInIt();
    setTimeout(
      () => (
        (this.displayStates.surveyDetails = true), this.showQuestionAnimation()
      ),
      200
    );
  }

  public surveyInIt() {
    const surveyInfo = this.surveyService.getLocalSurveyUserInfo();

    const getSurvey = this.surveyService.getLocalSurveyModal();

    this.likes = getSurvey.likes;
    this.startDate = getSurvey.startDate;
    this.endDate = getSurvey.endDate;
    const surveyData = {
      ...getSurvey,
      ...surveyInfo,
    };

    this.survey = surveyData;

    const questions = this.survey.questions;
    this.totalPages = questions.length;
    this.currentPage = 0;
    this.progress = 0;

    this.gender = surveyInfo.gender;
    this.surveyId = getSurvey._id;
    this.userId = getSurvey.userId;
    this.answerUserName = surveyInfo.name;
  }

  public onTextAnswerChange(question: any, answer: string) {
    question.selectedAnswer = [answer];
  }
  public onTextAdditionalChange(question: any, answer: string) {
    question.additionalText = answer;
  }
  toggleCheck(index: number) {
    this.rating = index + 1;
    this.activeWidth = (index + 1) * (100 / this.rating) + "%";
  }
  // In your component class
  getLineWidth(rating: number): number {
    switch (rating) {
      case 1:
        return 5;
      case 2:
        return 30;
      case 3:
        return 50;
      case 4:
        return 77;
      case 5:
        return 100;
      default:
        return 0;
    }
  }
  nextPage() {
    const currentQuestion = this.survey.questions[this.currentPage];
    if (currentQuestion.selectedAnswer.length === 0) {
      console.error(
        `Please select an answer for question ${this.currentPage + 1}.`
      );
      this.toasterService.error(
        `Please select an answer for question ${this.currentPage + 1}.`
      );
      return;
    }
    this.hideQuestion();
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
      this.progress = ((this.currentPage + 1) / this.totalPages) * 100;
      this.showLastPage = this.currentPage === this.totalPages - 1;
      this.showQuestionAnimation();
    } else {
      const areAllQuestionsAnswered = this.survey.questions.every(
        (question) => question.selectedAnswer.length > 0
      );
      if (areAllQuestionsAnswered) {
        console.log("All questions are answered!");
        this.translateService
          .get("newQuestSurvey.allAnswered")
          .subscribe((text) => {
            this.toasterService.show(text);
          });
        // this.toasterService.show("All questions are answered!");
        // this.buttonClicked = true;
      } else {
        console.error("Not all questions are answered.");
        this.toasterService.error("Not all questions are answered.");
      }
    }
  }
  dynamicColor(surveyBackgroundColor, secondColor): string {
    // Use the property from the brandmanagement object
    return surveyBackgroundColor || secondColor;
  }
  dynamicTextColor(questionFontColor): string {
    return questionFontColor || "white";
  }
  dynamicFontFamily(fontFamily): string {
    return fontFamily;
  }
  submitSurvey() {
    this.buttonClicked = true;
    const areAllQuestionsAnswered = this.survey.questions.every(
      (question) => question.selectedAnswer.length > 0
    );
    if (areAllQuestionsAnswered) {
      if (this.survey.brandManagementId) {
        if (this.rating > 0) {
          forkJoin([
            this.surveyService.answerSurvey(this.survey),
            this.createRating({ rating: this.rating, survey: this.survey._id }),
          ]).subscribe(
            ([firstResponse, secondResponse]) => {
              // Handle responses from both APIs here
              this.buttonClicked = false;
              if (
                this.survey.promoCodeId &&
                this.survey.promoCodeId.remaining > 0
              ) {
                this._router.navigate(["/new-promo-code"]);
              } else {
                this._router.navigate(["/new-completed-survey"]);
              }
            },
            (error) => {
              console.error("API error:", error);
              this.buttonClicked = false;
              this.translateService
                .get("newQuestSurvey.Something went wrong")
                .subscribe((text) => {
                  this.toasterService.error(text);
                });
            }
          );
        } else {
          console.error("Rating must be greater than 0.");
          this.buttonClicked = false;
          this.translateService
            .get("promoCode.selctRating")
            .subscribe((text) => {
              this.toasterService.error(text);
            });
        }
      } else {
        this.surveyService.answerSurvey(this.survey).subscribe(
          (response) => {
            this.buttonClicked = false;
            if (
              this.survey.promoCodeId &&
              this.survey.promoCodeId.remaining > 0
            ) {
              this._router.navigate(["/new-promo-code"]);
            } else {
              this._router.navigate(["/new-completed-survey"]);
            }
          },
          (error) => {
            console.error("API error:", error);
            this.translateService
              .get("newQuestSurvey.Something went wrong")
              .subscribe((text) => {
                this.toasterService.error(text);
              });
          }
        );
      }
    } else {
      console.error("Not all questions are answered.");
      this.translateService
        .get("newQuestSurvey.notAnswered")
        .subscribe((text) => {
          this.toasterService.error(text);
        });
    }
  }

  private hideQuestion() {
    this.questionAnimationClass = "d-none";
  }
  createRating(data) {
    // Make your second API call here and return the observable
    return this.surveyService.surveyRating(data);
  }
  private showQuestionAnimation() {
    setTimeout(() => {
      this.questionAnimationClass = "question-slide-animate";
      this.progress = ((this.currentPage + 1) / this.totalPages) * 100;
    }, 200);
  }
  prevQuestion() {
    if (this.currentPage > 0) {
      this.questionAnimationClass = "d-none";
      this.currentPage--;
      this.progress = ((this.currentPage + 1) / this.totalPages) * 100;
      this.showLastPage = this.currentPage === this.totalPages - 1;
    }
    this.showQuestionAnimation();
  }

  public async getSurveyById() {
    if (this._ac.snapshot.params.surveyId) {
      const surveyId = this._ac.snapshot.params.surveyId;
      const survey = await this.surveyService
        .getSurveyById(surveyId)
        .toPromise();
      this.surveyService.createLocalSurveyModal(survey);
      const verifyModel = this.bsModalService.show(SurveyNameComponent, {
        class: "modal-dialog-centered sign-up c-survey-modal",
        backdrop: "static",
        ignoreBackdropClick: true,
        keyboard: false,
      });
      verifyModel.content.allowClose = false;
      verifyModel.content.onClose = async (result) => {
        if (result) {
          const surveyId = this._ac.snapshot.params.surveyId;
          const survey = await this.surveyService
            .getSurveyById(surveyId)
            .toPromise();
          if (!survey) {
            this.translateService
              .get("survey.surveyNotFound")
              .subscribe((text) => {
                this.toasterService.error(text);
              });
            this._router.navigate(["/"]);
            return;
          }

          this.surveyService.createLocalSurveyModal(survey);
          this.surveyInIt();
          verifyModel.hide();
        }
      };
    }
  }

  imageReturn(surveyImageUrl: any) {
    return surveyImageUrl ? surveyImageUrl : "";
  }
  getSafeUrl(data: string) {
    return (
      this.sanitizer.bypassSecurityTrustResourceUrl(data) ??
      "../assets/imgs/kafu-logo-red.png"
    );
  }

  onAnswerSelected(answer: any, questionType: any) {
    this.ensureSelectedAnswersArrayInitialized();
    this.selectedAnswer = answer;

    if (questionType !== eSurveyQuestionTypes.MULTI_SELECT) {
      this.survey.questions[this.currentPage].selectedAnswer = [];
    }

    const index = this.survey.questions[
      this.currentPage
    ].selectedAnswer.indexOf(answer.option);

    if (index !== -1) {
      this.survey.questions[this.currentPage].selectedAnswer.splice(index, 1);
    } else {
      this.survey.questions[this.currentPage].selectedAnswer.push(
        answer.option
      );
    }

    if (
      questionType != eSurveyQuestionTypes.MULTI_SELECT &&
      questionType !== eSurveyQuestionTypes.TEXT
    ) {
      console.log(
        questionType != eSurveyQuestionTypes.MULTI_SELECT,
        questionType != eSurveyQuestionTypes.TEXT,
        "questionType"
      );
      setTimeout(() => {
        if (this.currentPage < this.totalPages - 1) {
          debugger;
          this.nextPage();
        } else {
        }
      }, 500);
    }
  }
  setEvaluationAns(answer, select, index) {
    this.survey.questions[this.currentPage].selectedAnswer = [index];
    setTimeout(() => {
      if (this.currentPage < this.totalPages - 1) {
        debugger;
        this.nextPage();
      } else {
      }
    }, 500);
  }
  ensureSelectedAnswersArrayInitialized() {
    if (!this.survey.questions[this.currentPage].selectedAnswer) {
      this.survey.questions[this.currentPage].selectedAnswer = [];
    }
  }
}
