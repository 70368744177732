import { Component, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from "angular-2-local-storage";
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { HomeService } from 'src/services/home.service';
import { ShareSurveyComponent } from '../share-survey/share-survey.component';

@Component({
  selector: 'app-survey-approval',
  templateUrl: './survey-approval.component.html',
  styleUrls: ['./survey-approval.component.css']
})
export class SurveyApprovalComponent implements OnInit {
  onClose: () => void;
  survey: any;
  surveyRes: any;
  surveyId: any;
  constructor(
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private homeService: HomeService
  ) {
    this.survey = this.localStorageService.get("KF-modal-approval");
  }
  ngOnInit(): void {
    this.surveyId = this.surveyRes?._id;
  }

  async copyUrl() {
    this.homeService.copySurveyUrl(this.surveyId, this.surveyRes?.surveyType, this.surveyRes?.language);

  }
  share() {
    const shareModel = this.modalService.show(ShareSurveyComponent, {
      class: 'c-survey-modal modal-dialog modal-dialog-centered sign-up',
      backdrop: 'static',
      initialState: {
      }
    });
    shareModel.content.onClose = () => {
      shareModel.hide();
    };
  }


}
