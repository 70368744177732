import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HomeRoutingModule } from "./home-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxStripeModule } from "ngx-stripe";
import { PartialsModule } from "../partials/partials.module";
import { GeneralModule } from "../general/general.module";
import { HomeComponent } from "./home/home.component";
import { LanguageService } from "../../services/language.service";
import { BsModalRef, ModalModule } from "ngx-bootstrap/modal";
import { CarouselModule } from "ngx-owl-carousel-o";
import { HomeService } from "../../services/home.service";
import { NotLoggedinHomeComponent } from "./not-loggedin-home/not-loggedin-home.component";
import { LoggedinHomeComponent } from "./loggedin-home/loggedin-home.component";
import { CompleteProfileComponent } from "./complete-profile/complete-profile.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { DatepickerModule } from "ng2-datepicker";
import { ColorPickerModule } from 'ngx-color-picker';
import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { FontPickerModule, FontPickerConfigInterface } from 'ngx-font-picker';

import { SettingService } from "../../services/setting.service";
import { CreateSurveyComponent } from "./create-survey/create-survey.component";
import { SurveyNameComponent } from "./survey-name/survey-name.component";
import { SurveyService } from "../../services/survey.service";
import { PlanService } from "src/services/plan.service";
import { RechargeService } from "src/services/recharge.service";
import { CompleteSurveyComponent } from "./complete-survey/complete-survey.component";
import { SurveyOptionsComponent } from "./survey-options/survey-options.component";
import { SurveyPreviewComponent } from "./survey-preview/survey-preview.component";
import { TermsComponent } from "./terms/terms.component";
import { PolicyComponent } from "./policy/policy.component";
import { NgxPaginationModule } from "ngx-pagination";
import { NotFoundComponent } from "./not-found/not-found.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AboutUsComponent } from "./about-us/about-us.component";
import { AnswerSurveyComponent } from "./answer-survey/answer-survey.component";
import { PlanComponent } from "./plan/plan.component";
import { WalletComponent } from "./wallet/wallet.component";
import { MyAccountComponent } from "./my-account/my-account.component";

import { FinishSurveyComponent } from "./finish-survey/finish-survey.component";

import { MyInterestsComponent } from "./my-interests/my-interests.component";
import { RechargeComponent } from "./recharge/recharge.component";
import { MyStatsComponent } from "./my-stats/my-stats.component";
import { ChartsModule } from "ng2-charts";
import { MySurveysComponent } from "./my-surveys/my-surveys.component";
import { AllSurveysComponent } from "./all-surveys/all-surveys.component";
import { SurveyApprovalComponent } from "./survey-approval/survey-approval.component";
import { EditSurveyComponent } from "./edit-survey/edit-survey.component";
import { InviteSurveyComponent } from "./invite-survey/invite-survey.component";
import { SurveyDetailsComponent } from "./survey-details/survey-details.component";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { ShareSurveyComponent } from "./share-survey/share-survey.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { DesignSurveyComponent } from "./design-survey/design-survey.component";
import { EnterpriseSurveyModalComponent } from "./enterprise-survey-modal/enterprise-survey-modal.component";
import { EnterpriseAnswerComponent } from "./enterprise-answer/enterprise-answer.component";
import { EarnMorePointsModalComponent } from "./loggedin-home/earn-more-points-modal/earn-more-points-modal.component";
import { RedeemModalComponent } from "./loggedin-home/redeem-modal/redeem-modal.component";
import { ImagePreviewerComponent } from "./answer-survey/image-previewer/image-previewer.component";
import { CommingSoonModalComponent } from "./loggedin-home/comming-soon-modal/comming-soon-modal.component";
import { ThankYouComponent } from "./thank-you/thank-you.component";
import { HighchartsChartModule } from "highcharts-angular";
import { AnswerSurveyTwoComponent } from "./answer-survey-two/answer-survey-two.component";
// import { ClipboardModule } from 'ngx-clipboard';
import { MerchantFormComponent } from "./merchant-form/merchant-form.component";
import { ThankYouService } from "src/services/thankyou.service";
import { ReportingComponent } from "./reporting/reporting.component";
import { DashboardComponent } from "./new-dashboard/dashboard/dashboard.component";
import { SidebarComponent } from "./new-dashboard/sidebar/sidebar.component";
import { MainComponent } from "./new-dashboard/main/main.component";
import { SurveyReportComponent } from "./survey-report/survey-report.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NewServeyComponent } from './new-servey/new-servey.component';
import { NewServeySecondComponent } from "./new-servey-second/new-servey-second.component";
import { CreateCustomSurveyComponent } from './create-custom-survey/create-custom-survey.component';
import { CreateSecondCustomSurveyComponent } from './create-second-custom-survey/create-second-custom-survey.component';
import { CreateCustomThirdSurveyComponent } from './create-custom-third-survey/create-custom-third-survey.component';
import { CreateCustomForthSurveyComponent } from './create-custom-forth-survey/create-custom-forth-survey.component';
import { CustomFifthSurveyComponent } from './custom-fifth-survey/custom-fifth-survey.component';
import { PricingComponent } from './pricing/pricing.component';
import { NewMyAccountComponent } from './new-my-account/new-my-account.component';
import { NewWalletComponent } from './new-wallet/new-wallet.component';
import { NewCompleteProfileComponent } from './new-complete-profile/new-complete-profile.component';
import { MyNewDashboardComponent } from './my-new-dashboard/my-new-dashboard.component';
import { NewSidebarComponent } from './new-sidebar/new-sidebar.component';
import { NewMainDashboardComponent } from './new-main-dashboard/new-main-dashboard.component';
import { ShortenNumberPipe } from "../shorten-number.pipe";
import { MatMenuModule } from '@angular/material/menu';
import { NewQuesAnswerComponent } from './new-ques-answer/new-ques-answer.component';
import { NewFinishedSurveyComponent } from './new-finished-survey/new-finished-survey.component';
import { NewCompletedSurveyComponent } from './new-completed-survey/new-completed-survey.component';
import { SurveyBrandListComponent } from './survey-brand-list/survey-brand-list.component';
import { PublicationModelComponent } from './publication-model/publication-model.component';
import { NewPromoCodeComponent } from './new-promo-code/new-promo-code.component';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  // Change this to your Google API key
  apiKey: 'AIzaSyBfpfAQT_I-lOSZkLohLlfqlxrg2Jmz_uk'
};

@NgModule({
  imports: [
    CommonModule, MatMenuModule,
    HomeRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ColorPickerModule,
    FontPickerModule,
    DragDropModule,
    ChartsModule,
    NgxPaginationModule,
    PartialsModule,
    MatTooltipModule,
    NgxStripeModule.forRoot(
      "pk_test_51JQoRWLCPJ7AA4OoPdeRYTXStH79J97nR52aAWksHbWWIB9NQmIxNNjPFQDXhp3kQghEBYr4AYl00WpJ0Zek8stu00ztMNVbWX"
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GeneralModule,
    ModalModule.forRoot(),
    CarouselModule,
    NgSelectModule,
    DatepickerModule,
    ProgressbarModule,
    HighchartsChartModule,
    // ClipboardModule
  ],
  declarations: [
    ShortenNumberPipe,
    HomeComponent,
    NotLoggedinHomeComponent,
    LoggedinHomeComponent,
    CompleteProfileComponent,
    ReportingComponent,
    CreateSurveyComponent,
    MerchantFormComponent,
    SurveyNameComponent,
    CompleteSurveyComponent,
    SurveyOptionsComponent,
    SurveyPreviewComponent,
    NotFoundComponent,
    TermsComponent,
    PolicyComponent,
    AboutUsComponent,
    AnswerSurveyComponent,
    PlanComponent,
    WalletComponent,
    MyAccountComponent,
    MyStatsComponent,
    FinishSurveyComponent,

    MyInterestsComponent,
    RechargeComponent,
    MySurveysComponent,
    AllSurveysComponent,
    SurveyApprovalComponent,
    EditSurveyComponent,
    InviteSurveyComponent,
    SurveyDetailsComponent,
    ShareSurveyComponent,
    NotificationsComponent,
    DesignSurveyComponent,
    EnterpriseSurveyModalComponent,
    EnterpriseAnswerComponent,
    EarnMorePointsModalComponent,
    RedeemModalComponent,
    ImagePreviewerComponent,
    CommingSoonModalComponent,
    ThankYouComponent,
    AnswerSurveyTwoComponent,
    DashboardComponent,
    SidebarComponent,
    MainComponent,
    SurveyReportComponent,
    NewServeyComponent,
    NewServeySecondComponent,
    CreateCustomSurveyComponent,
    CreateSecondCustomSurveyComponent,
    CreateCustomThirdSurveyComponent,
    CreateCustomForthSurveyComponent,
    CustomFifthSurveyComponent,
    PricingComponent,
    NewMyAccountComponent,
    NewWalletComponent,
    NewCompleteProfileComponent,
    MyNewDashboardComponent,
    NewSidebarComponent,
    NewMainDashboardComponent,
    SurveyBrandListComponent,
    NewMainDashboardComponent,
    NewQuesAnswerComponent,
    NewFinishedSurveyComponent,
    NewCompletedSurveyComponent,
    PublicationModelComponent,
    NewPromoCodeComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    LanguageService,
    HomeService,
    SettingService,
    SurveyService,
    PlanService,
    RechargeService,
    ThankYouService,
    BsModalRef,
    {
      provide: FONT_PICKER_CONFIG,
      useValue: DEFAULT_FONT_PICKER_CONFIG
    }
  ],
  entryComponents: [CreateSurveyComponent],
  bootstrap: [],
  exports: [ShortenNumberPipe]
})
export class HomeModule {
  constructor(private languageService: LanguageService) {
    this.languageService.setLanguage(
      this.languageService.getLanguageOrDefault()
    );
  }
}
