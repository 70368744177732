import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { LanguageService } from "../../../services/language.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { LoginComponent } from "../login/login.component";
import { AuthService } from "../../../services/auth.service";
import { SignupComponent } from "../signup/signup.component";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "angular-2-local-storage";
import { NotificationService } from "src/services/notification.service";
import { eLanguage } from "src/app/general/enums/language.enum";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { HomeService } from "src/services/home.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  points: any;
  userPoints: any;
  totalBalance: any;
  isOffCanvasOpen: boolean = false;
  avatar: string;
  changeToLang: any;
  isLoaded: boolean = false;
  path: any;
  length: any;
  notifications: any[] = [];
  elanguage = eLanguage;
  constructor(
    public languageService: LanguageService,
    public homeService: HomeService,
    private router: Router,
    public darkThemeService: DarkLiteThemeService,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private modalService: BsModalService,
    private _ns: NotificationService
  ) {
    const lang =
      this.activatedRoute.snapshot.queryParams?.lang ?? eLanguage.Short_English;
    if (
      lang == eLanguage.Short_Arabic &&
      this.languageService.getLanguageOrDefault() !== lang
    ) {
      this.languageService.setLanguage(lang);
      window.location.reload();
    }
    this.points = this.authService.getWalletRates?.currentCredits;
    this.totalBalance = this.authService.getWalletRates?.totalBalance;
  }
  @ViewChild("loginbutton", { read: ElementRef, static: false })
  loginbutton: ElementRef;

  ngOnInit() {
    this.avatar = this.authService.currentUser?.avatar
      ? this.authService.currentUser?.avatar
      : "assets/imgs/default-user.png";

    this.changeToLang =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_English
        ? eLanguage.Short_Arabic
        : eLanguage.Short_English;
    this.path = this.router.url;
    setTimeout(() => this.getNotifications(), 5000);
  }

  changeLang(lang): any {
    this.languageService.setLanguage(lang);
    this.router.navigate(["."], {
      queryParams: { lang: lang },
      relativeTo: this.activatedRoute,
      queryParamsHandling: "merge",
    });
    setTimeout(() => window.location.reload(), 0);
  }
  isRouteActive(route: string): boolean {
    return this.router.isActive(route, false) && this.router.url === route;
  }
  login() {
    this.homeService.resetIsLogin()
    const loginModel = this.modalService.show(LoginComponent, {
      class: "modal-dialog-centered login",
      backdrop: "static",
    });
    loginModel.content.onClose = (auth) => {
      loginModel.hide();
      // this.loginbutton.nativeElement.classList
      // ?.remove("opened")
      // ?.add("close-nav");
    };
  }

  signup() {
    this.homeService.markIsLogin()
    const SignupModel = this.modalService.show(LoginComponent, {
      class: "modal-dialog-centered login",
      backdrop: "static",
    });
    SignupModel.content.onClose = (auth) => {
      SignupModel.hide();
    };
  }

  logout() {
    this.authService.LogOut();
  }

  avoid() {
    return false;
  }

  openSidebar() {
    return (this.isOffCanvasOpen = true);
  }

  closeSidebar() {
    return (this.isOffCanvasOpen = false);
  }

  toggleButtonToChangeThemeClass() {
    this.darkThemeService.toggleTheme();
  }

  getNotifications(viewType?: string) {
    this._ns.getNotifications().subscribe((x) => {
      this.notifications = x;
      this.sortPR();
      this.isLoaded = true;
    });
  }
  sortPR() {
    this.notifications.sort((a, b) => {
      let fa = a.date,
        fb = b.date;
      if (fa < fb) return 1;
      if (fa > fb) return -1;
      return 0;
    });
  }
}
