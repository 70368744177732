import { Component, OnInit } from '@angular/core';
import { DarkLiteThemeService } from 'src/services/dark-lite-themeService.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {

  constructor(public darkThemeService: DarkLiteThemeService,) { }

  ngOnInit(): void {
  }

}
