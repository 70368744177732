<section class="-container pb-3" [ngClass]="darkThemeService.getCurrentTheme">
  <div class="main-second">
    <div class="card-sec border1">
      <div class="stf-title stf-title-1 text-center">
        {{ "wallet.header" | translate }}
      </div>
      <!--first three card start -->
      <div class="row text-capitalize p-4">
        <div class="col-lg-4">
          <div class="survey-card">
            <div
              class="d-flex justify-content-around align-items-center plan-bg fw-bold text-white card-top-round py-2"
            >
              <i class="wallet"></i>
              <h1>{{ "wallet.walletBalance" | translate }}</h1>
            </div>
            <div
              class="card-bg d-flex justify-content-around align-items-center text-white py-2 card-bottom-round"
            >
              <div class="content">
                <h5 class="text-color pb-3">
                  {{ "survey.available" | translate }}
                </h5>
                <h4 class="text-green text-uppercase pointTexst">
                  <!-- {{ balance }} -->
                  {{ totalBalance || 0 | shortenNumber }}

                  <span class="text-white pointCurrency">{{
                    "survey.SAR" | translate
                  }}</span>
                </h4>
              </div>
              <div class="line"></div>
              <div class="content">
                <h5 class="text-color pb-3">
                  {{ "survey.available" | translate }}
                </h5>
                <h3 class="points-color text-uppercase pointTexst">
                  {{ points || 0 | shortenNumber }}
                  <span class="text-white pointCurrency">{{
                    "survey.points" | translate
                  }}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="survey-card">
            <div
              class="d-flex justify-content-around align-items-center plan-bg fw-bold text-white card-top-round py-2"
            >
              <i class="exchange"></i>
              <h1>{{ "wallet.ExchangeRates" | translate }}</h1>
            </div>
            <div
              class="card-bg d-flex justify-content-around align-items-center text-white py-2 card-bottom-round"
            >
              <div class="content">
                <h5 class="text-color pb-3">{{ "survey.SAR" | translate }}</h5>
                <h4 class="text-green text-uppercase pointTexst">
                  1
                  <span class="text-white pointCurrency">{{
                    "survey.SAR" | translate
                  }}</span>
                </h4>
              </div>
              <div class="line"></div>
              <div class="content">
                <h5 class="text-color pb-3">
                  {{ "survey.Point" | translate }}
                </h5>
                <h3 class="points-color text-uppercase pointTexst">
                  {{ exchangeRate || Rate?.exchangeRate }}
                  <span class="text-white pointCurrency">{{
                    "survey.points" | translate
                  }}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="survey-card">
            <div
              class="d-flex justify-content-around align-items-center plan-bg fw-bold text-white card-top-round py-2"
            >
              <i class="rates"></i>
              <h1>{{ "survey.QuestionRates" | translate }}</h1>
            </div>
            <div class="card-bg text-white card-bottom-round">
              <table class="table question-rate-table">
                <thead>
                  <th>{{ "wallet.questionGroup" | translate }}</th>
                  <th>{{ "wallet.pointCost" | translate }}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span class="text-green text-uppercase pointText">
                        1-5 <span class="text-white pointUnit">qsn</span>
                      </span>
                    </td>
                    <td>
                      <span class="points-color text-uppercase pointText">
                        {{ Group?.firstGroup }}
                        <span class="text-white pointUnit">{{
                          "survey.SAR" | translate
                        }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="text-green text-uppercase pointText">
                        +5 <span class="text-white pointUnit">qsn</span>
                      </span>
                    </td>
                    <td>
                      <span class="points-color text-uppercase pointText">
                        {{ Group?.secondGroup }}
                        <span class="text-white pointUnit">{{
                          "survey.SAR" | translate
                        }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="text-green text-uppercase pointText">
                        +10 <span class="text-white pointUnit">qsn</span>
                      </span>
                    </td>
                    <td>
                      <span class="points-color text-uppercase pointText">
                        {{ Group?.thirdGroup }}
                        <span class="text-white pointUnit">{{
                          "survey.SAR" | translate
                        }}</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--first three card end -->
      <!--second three card start -->

      <div class="row text-capitalize p-4 pt-0">
        <div class="col-lg-4">
          <div class="survey-card">
            <div
              class="d-flex justify-content-around align-items-center second-card fw-bold text-white card-top-round py-2"
            >
              <i class="rates"></i>
              <h1>{{ "survey.TransferToPoints" | translate }}</h1>
            </div>
            <div class="second-card-body py-2 card-bottom-round">
              <div
                [formGroup]="transferForm"
                class="d-flex justify-content-around py-2 align-items-center"
              >
                <div
                  class="p-2 mx-2 sar-radus w-50 bg-white d-flex justify-content-around align-items-center -input"
                >
                  <input
                    type="number"
                    formControlName="sar"
                    (input)="onSARChange()"
                  />
                  <h6 class="pointCurrency">{{ "survey.SAR" | translate }}</h6>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  [ngClass]="language === 'ar' ? 'rotation' : ''"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.6386 8.92919L11.36 14.2078C10.9744 14.5934 10.9738 15.2172 11.3583 15.6017C11.7454 15.9888 12.3668 15.9853 12.7522 15.5999L19.7095 8.64258L19.7104 8.64172L19.7112 8.64086C19.9045 8.44759 20.0004 8.19593 20.0001 7.9444C19.9991 7.69224 19.9032 7.44063 19.7112 7.2487L19.7104 7.24784L19.7095 7.24698L12.7522 0.289624C12.3666 -0.0959762 11.7427 -0.0965312 11.3583 0.287903C10.9712 0.675018 10.9746 1.2964 11.36 1.68178L16.6386 6.96038H1.60007C1.05815 6.96038 0.614258 7.40111 0.614258 7.94478C0.614258 8.49224 1.05562 8.92919 1.60007 8.92919H16.6386Z"
                    fill="#fff"
                  />
                </svg>
                <div
                  class="p-2 mx-2 sar-radus w-50 bg-white d-flex justify-content-around align-items-center -input"
                >
                  <input
                    type="number"
                    formControlName="points"
                    (input)="onPointsChange()"
                  />
                  <h6 class="pointCurrency">{{ "survey.Pts" | translate }}</h6>
                </div>
              </div>
              <div class="btn-container">
                <button class="m-btn m-btn-green" (click)="onclick()">
                  {{ "survey.Proceed" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="survey-card">
            <div
              class="d-flex justify-content-around align-items-center second-card fw-bold text-white card-top-round py-2"
            >
              <i class="spending"></i>
              <h1>{{ "wallet.Average Spending" | translate }}</h1>
            </div>

            <div class="second-card-body py-2 card-bottom-round">
              <div class="py-2 text-center">
                <!-- <div>
                  <h3 class="py-3 text-color pointCurrency">
                    {{ "survey.SAR" | translate }}
                  </h3>
                  <h2 class="text-green pointTexst">
                    21.7
                    <span class="text-muted">{{
                      "survey.SAR" | translate
                    }}</span>
                  </h2>
                </div>
                <div>
                  <h3 class="py-3 text-color pointCurrency">
                    {{ "home.points" | translate }}
                  </h3>
                  <h2 class="points-color pb-2 pointTexst">
                    15.72
                    <span class="text-muted">{{
                      "survey.points" | translate
                    }}</span>
                  </h2>
                </div> -->
                <div
                  *ngIf="language === 'Arabic'; else elseBlock"
                  class="text-center"
                >
                  <h3 class="py-3 text-color points-color">
                    {{ authService.currentUser?.planId?.titleArabic }}
                  </h3>
                  <h2
                    class="fw-700 text-green pointTexst"
                    *ngIf="authService.currentUser?.planId?.totalAmount == 0"
                  >
                    {{ "pricing.plan" | translate }}
                  </h2>
                  <h2
                    class="text-green pointTexst"
                    *ngIf="authService.currentUser?.planId?.totalAmount > 0"
                  >
                    {{ authService.currentUser?.planId?.totalAmount }}
                    {{ "survey.SAR" | translate }}
                    <span
                      *ngIf="authService.currentUser?.planId?.totalAmount > 0"
                      >&nbsp;/
                      {{
                        authService.currentUser?.planId?.planTypeArabic
                      }}</span
                    >
                  </h2>
                </div>
                <ng-template #elseBlock>
                  <h3 class="py-3 text-color points-color">
                    {{ authService.currentUser?.planId?.title }}
                  </h3>
                  <h2
                    class="orange-grad-color fw-700 text-green pointTexst"
                    *ngIf="authService.currentUser?.planId?.totalAmount == 0"
                  >
                    {{ "pricing.plan" | translate }}
                  </h2>
                  <h2
                    class="text-green pointTexst"
                    *ngIf="authService.currentUser?.planId?.totalAmount > 0"
                  >
                    {{ authService.currentUser?.planId?.totalAmount }}
                    {{ "survey.SAR" | translate }}
                    <span
                      *ngIf="authService.currentUser?.planId?.totalAmount > 0"
                      >&nbsp;/
                      {{ authService.currentUser?.planId?.planType }}</span
                    >
                  </h2>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="survey-card">
            <div
              class="d-flex justify-content-around align-items-center second-card fw-bold text-white card-top-round py-2"
            >
              <i class="recharge"></i>
              <h1>{{ "wallet.Subscription" | translate }}</h1>
            </div>
            <div class="second-card-body py-2 card-bottom-round">
              <div class="text-center mb-4">
                <h4 class="text-color textPaddding font-size-14 font-400 mt-4">
                  {{ "wallet.subText" | translate }}
                </h4>
              </div>
              <div class="btn-container">
                <button (click)="Plan()" class="m-btn m-btn-green">
                  {{ "wallet.Subscribe" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--second three card end -->
    <div class="card-sec mt-5 p-4 back">
      <div class="d-flex justify-content-between py-2 table_actions">
        <div class="d-flex align-items-center _dates">
          <div>
            <div class="-input">
              <input type="date" id="startDate" />
            </div>
          </div>
          <div>
            <div class="-input">
              <input type="date" id="endDate" />
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <h4 class="historical-heading">
            {{ "wallet.HistoricalTransactions" | translate }}
          </h4>
        </div>
        <div class="my-md-0 my-3">
          <button class="m-btn btn-download" (click)="pdfDownload()">
            {{ "wallet.DownloadPDF" | translate }}
            <img src="./assets/imgs/download-icon.svg" alt="" />
          </button>
        </div>
      </div>

      <!--table section start -->
      <div class="table-responsive">
        <table class="table historical-table">
          <tbody>
            <ng-container
              *ngFor="
                let data of filterData
                  | paginate : { itemsPerPage: 5, currentPage: page };
                let oddElement = odd;
                let evenElement = even;
                let i = index
              "
            >
              <tr [ngClass]="{ odd: oddElement, even: evenElement }">
                <td>
                  <input type="checkbox" [(ngModel)]="data.isChecked" />
                </td>
                <!-- <td>
                  <p class="text-muted">
                    {{ "wallet.TrackingId" | translate }}
                  </p>
                  <span>{{ data.trackingId }}</span>
                </td> -->
                <td>
                  <img
                    src="assets/imgs/Icon-open-arrow-thick-left.png"
                    alt="test"
                  />
                </td>
                <td>
                  <p class="text-muted">{{ "wallet.Value" | translate }}</p>
                  <span class="font-weight-bold"
                    >{{ data.amount }}
                    <span class="text-muted">
                      {{ "survey.SAR" | translate }}</span
                    >
                  </span>
                </td>
                <!-- <td>
                  <p class="text-muted">{{ "wallet.points" | translate }}</p>
                  <span class="font-weight-bold">{{
                    data.value ? data.value : 0
                    }}</span>
                </td>
                <td>
                  <p class="text-muted">{{ "wallet.VAT" | translate }}</p>
                  <span>{{ data.vat | number : "0.2-2" }}</span>
                </td> -->
                <td>
                  <p class="text-muted">{{ "wallet.Date" | translate }}</p>
                  <span>{{ moment(data.date).format("lll") }}</span>
                </td>
                <td>
                  <p class="text-muted">
                    {{ "wallet.Description" | translate }}
                  </p>
                  <div *ngIf="language === 'Arabic'; else elseBlock">
                    <span>{{ data.descriptionArabic }}</span>
                  </div>
                  <ng-template #elseBlock>
                    <span>{{ data.description }}</span></ng-template
                  >
                </td>

                <!-- <td>
              <p class="text-muted">{{ "wallet.Type" | translate }}</p>
              <span>{{ data.cardType }}</span>
            </td> -->
                <!-- <td>
                  <p class="text-muted">{{ "wallet.Type" | translate }}</p>
                  <span>{{ data.cardType }}</span>
                </td> -->
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <pagination-controls
          class="my-pagination"
          (pageChange)="page = $event"
        ></pagination-controls>
      </div>
    </div>

    <!--table section end -->
  </div>
  <app-loader [show]="showLoader"></app-loader>
</section>
