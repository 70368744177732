<div id="main-body-div" [ngClass]="darkThemeService.getCurrentTheme">
  <!--Main layout-->
  <div>
    <div class="container-fluid">
      <div class="row d-flex">
        <div class="col-xl-2 col-lg-2 sidebar pad-zero" [ngClass]="{
            'show-dropdown': showDropdown,
            'hide-dropdown': !showDropdown
          }">
          <app-sidebar></app-sidebar>
        </div>
        <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 main-sides">
          <div>
            <div class="d-flex flex-row-reverse header-top-most">
              <!-- <button class="p-2 header-top-most-btn align-self-center" (change)="toggleButtonToChangeThemeClass()">
                <label class="switch">
                  <input type="checkbox" id="toggle-dark-mode" />
                  <span class="slider round"></span>
                </label>
              </button> -->
              <div class="color-change cursor-pointer" (click)="changeLang()">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M21.54 18C21.66 17.0099 21.75 16.02 21.75 15C21.75 13.98 21.66 12.99 21.54 12H26.61C26.85 12.96 27 13.965 27 15C27 16.035 26.85 17.04 26.61 18L21.54 18ZM18.885 26.3398C19.785 24.6748 20.475 22.8748 20.955 20.9998H25.38C23.94 23.4748 21.645 25.3948 18.885 26.3398ZM18.51 18H11.49C11.355 17.0099 11.25 16.02 11.25 15C11.25 13.98 11.355 12.975 11.49 12L18.51 12C18.645 12.975 18.75 13.98 18.75 15C18.75 16.02 18.645 17.0099 18.51 18ZM15 26.9398C13.755 25.1398 12.78 23.1448 12.135 20.9998H17.865C17.22 23.1448 16.245 25.1398 15 26.9398ZM9.04495 9.00011H4.61996C6.05996 6.51012 8.35495 4.6051 11.115 3.6601C10.215 5.3251 9.52496 7.1251 9.04496 9.00011H9.04495ZM4.61996 20.9998H9.04495C9.52495 22.8748 10.215 24.6748 11.1149 26.3398C8.35495 25.3948 6.05995 23.4898 4.61995 20.9998H4.61996ZM3.39001 18C3.15001 17.0399 3.00002 16.0349 3.00002 15C3.00002 13.965 3.15001 12.96 3.39001 12H8.46002C8.34002 12.99 8.25002 13.98 8.25002 15C8.25002 16.02 8.34002 17.0099 8.46002 18H3.39001ZM15 3.06007C16.245 4.86006 17.22 6.85506 17.865 9.00006L12.135 9.00006C12.78 6.85506 13.755 4.86006 15 3.06007ZM25.38 9.00011H20.955C20.475 7.12511 19.785 5.3251 18.885 3.6601C21.645 4.6051 23.94 6.5251 25.38 9.00011H25.38ZM14.985 0C6.705 0 0 6.72 0 15C0 23.28 6.705 30 14.985 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 14.985 0Z"
                    fill="url(#paint0_linear_1_1063)" />
                  <defs>
                    <linearGradient id="paint0_linear_1_1063" x1="30" y1="0" x2="0" y2="0"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#1D1D49" />
                      <stop offset="1" stop-color="#505084" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div class="color-change">
                <a (click)="toggleButtonToChangeThemeClass()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M14.6596 10.75C14.6596 5.91751 18.4937 2 23.2234 2C27.9531 2 31.7872 5.91751 31.7872 10.75C31.7872 15.5825 27.9531 19.5 23.2234 19.5C18.4937 19.5 14.6596 15.5825 14.6596 10.75Z"
                      fill="#FF8C58" stroke="url(#paint0_linear_1_1074)" stroke-width="2.7" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2 15.9204C2 10.8683 5.83416 6.77271 10.5638 6.77271C15.2935 6.77271 19.1277 10.8683 19.1277 15.9204C19.1277 20.9726 15.2935 25.0682 10.5638 25.0682C5.83416 25.0682 2 20.9726 2 15.9204Z"
                      fill="#1BBAA2" stroke="url(#paint1_linear_1_1074)" stroke-width="2.7" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M10.9362 23.875C10.9362 16.6263 16.7708 10.75 23.9681 10.75C31.1655 10.75 37 16.6263 37 23.875C37 31.1237 31.1655 37 23.9681 37C16.7708 37 10.9362 31.1237 10.9362 23.875Z"
                      fill="#F05C6D" stroke="url(#paint2_linear_1_1074)" stroke-width="2.7" />
                    <defs>
                      <linearGradient id="paint0_linear_1_1074" x1="31.7872" y1="2" x2="14.6596" y2="2"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#1D1D49" />
                        <stop offset="1" stop-color="#505084" />
                      </linearGradient>
                      <linearGradient id="paint1_linear_1_1074" x1="19.1277" y1="6.77271" x2="2" y2="6.77271"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#1D1D49" />
                        <stop offset="1" stop-color="#505084" />
                      </linearGradient>
                      <linearGradient id="paint2_linear_1_1074" x1="37" y1="10.75" x2="10.9362" y2="10.75"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#1D1D49" />
                        <stop offset="1" stop-color="#505084" />
                      </linearGradient>
                    </defs>
                  </svg>
                </a>
              </div>
              <div class="p-2 header-top-most-btn header-top-most-btn-2nd align-self-center d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none">
                  <path
                    d="M18.7596 2.20654H7.29538C6.42321 2.20654 5.71411 2.78659 5.71411 3.50002V19.0218C5.71411 19.7352 6.42321 20.3152 7.29538 20.3152H18.7596C19.6318 20.3152 20.3409 19.7352 20.3409 19.0218V3.50002C20.3409 2.78659 19.6318 2.20654 18.7596 2.20654ZM18.5619 18.8601H7.49304V3.66171H18.5619V18.8601Z"
                    fill="url(#paint0_linear_1_945)" />
                  <path
                    d="M7.49304 18.8601H18.5619V3.66174H7.49304V18.8601ZM13.0275 15.9902C13.5735 15.9902 14.0158 16.352 14.0158 16.7986C14.0158 17.2453 13.5735 17.6071 13.0275 17.6071C12.4815 17.6071 12.0392 17.2453 12.0392 16.7986C12.0392 16.352 12.4815 15.9902 13.0275 15.9902Z"
                    fill="#1BBAA2" fill-opacity="0.3" />
                  <path
                    d="M12.0392 16.7987C12.0392 17.0131 12.1433 17.2187 12.3286 17.3703C12.514 17.5219 12.7654 17.6071 13.0275 17.6071C13.2896 17.6071 13.541 17.5219 13.7263 17.3703C13.9116 17.2187 14.0158 17.0131 14.0158 16.7987C14.0158 16.5843 13.9116 16.3786 13.7263 16.227C13.541 16.0754 13.2896 15.9902 13.0275 15.9902C12.7654 15.9902 12.514 16.0754 12.3286 16.227C12.1433 16.3786 12.0392 16.5843 12.0392 16.7987Z"
                    fill="url(#paint1_linear_1_945)" />
                  <defs>
                    <linearGradient id="paint0_linear_1_945" x1="20.3409" y1="2.20654" x2="5.71411" y2="2.20654"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#1D1D49" />
                      <stop offset="1" stop-color="#505084" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_1_945" x1="14.0158" y1="15.9902" x2="12.0392" y2="15.9902"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#1D1D49" />
                      <stop offset="1" stop-color="#505084" />
                    </linearGradient>
                  </defs>
                </svg>
                <span>{{points?.toFixed(2)}}<sub class="font-6">PTS</sub></span>
              </div>
              <div class="p-2 header-top-most-btn header-top-most-btn-2nd align-self-center d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14" fill="none">
                  <path
                    d="M0.99353 2.82616C0.99353 2.4341 1.19979 2.0581 1.56695 1.78087C1.9341 1.50365 2.43206 1.3479 2.95129 1.3479H18.6134C19.1326 1.3479 19.6306 1.50365 19.9977 1.78087C20.3649 2.0581 20.5712 2.4341 20.5712 2.82616V11.6957C20.5712 12.0878 20.3649 12.4638 19.9977 12.741C19.6306 13.0182 19.1326 13.174 18.6134 13.174H2.95129C2.43206 13.174 1.9341 13.0182 1.56695 12.741C1.19979 12.4638 0.99353 12.0878 0.99353 11.6957V2.82616ZM0.99353 4.30442H20.5712H0.99353Z"
                    fill="url(#paint0_linear_1_937)" fill-opacity="0.1" />
                  <path
                    d="M0.99353 4.30442H20.5712M0.99353 2.82616C0.99353 2.4341 1.19979 2.0581 1.56695 1.78087C1.9341 1.50365 2.43206 1.3479 2.95129 1.3479H18.6134C19.1326 1.3479 19.6306 1.50365 19.9977 1.78087C20.3649 2.0581 20.5712 2.4341 20.5712 2.82616V11.6957C20.5712 12.0878 20.3649 12.4638 19.9977 12.741C19.6306 13.0182 19.1326 13.174 18.6134 13.174H2.95129C2.43206 13.174 1.9341 13.0182 1.56695 12.741C1.19979 12.4638 0.99353 12.0878 0.99353 11.6957V2.82616Z"
                    stroke="url(#paint1_linear_1_937)" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M0.99353 7.26099H7.8457C7.8457 8.00012 8.43303 9.47838 10.7823 9.47838C13.1317 9.47838 13.719 8.00012 13.719 7.26099H20.5712"
                    fill="url(#paint2_linear_1_937)" fill-opacity="0.2" />
                  <path
                    d="M0.99353 7.26099H7.8457C7.8457 8.00012 8.43303 9.47838 10.7823 9.47838C13.1317 9.47838 13.719 8.00012 13.719 7.26099H20.5712"
                    stroke="url(#paint3_linear_1_937)" stroke-linecap="round" stroke-linejoin="round" />
                  <defs>
                    <linearGradient id="paint0_linear_1_937" x1="20.5712" y1="1.3479" x2="0.99353" y2="1.3479"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#0CAB93" />
                      <stop offset="1" stop-color="#08DCBC" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_1_937" x1="20.5712" y1="1.3479" x2="0.99353" y2="1.3479"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#1D1D49" />
                      <stop offset="1" stop-color="#505084" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_1_937" x1="20.5712" y1="7.26099" x2="0.99353" y2="7.26099"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#0CAB93" />
                      <stop offset="1" stop-color="#08DCBC" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_1_937" x1="20.5712" y1="7.26099" x2="0.99353" y2="7.26099"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#1D1D49" />
                      <stop offset="1" stop-color="#505084" />
                    </linearGradient>
                  </defs>
                </svg>
                <span>{{balance?.toFixed(2)}}<sub class="font-6">SAR</sub></span>
              </div>
              <div class="p-2 header-top-most-search">
                <input type="search" name="search-bar-top" id="top-search-bar" />
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.34444 14.4362C6.16823 14.4362 3.59402 11.9786 3.59402 8.93673C3.59402 5.89914 6.16374 3.43727 9.34444 3.43727C12.5206 3.43727 15.0949 5.89485 15.0949 8.93673C15.0949 11.9743 12.5251 14.4362 9.34444 14.4362ZM22.6872 19.0204L18.2082 14.7369C18.006 14.5435 17.732 14.4361 17.4444 14.4361H16.7122C17.9521 12.9195 18.6889 11.0118 18.6889 8.93663C18.6889 4 14.5063 0 9.34444 0C4.18253 0 0 4 0 8.93663C0 13.8733 4.18253 17.8733 9.34444 17.8733C11.5143 17.8733 13.509 17.1686 15.0949 15.9828V16.6831C15.0949 16.9581 15.2072 17.2202 15.4093 17.4135L19.8884 21.6971C20.3107 22.101 20.9935 22.101 21.4113 21.6971L22.6827 20.4812C23.105 20.0773 23.105 19.4243 22.6872 19.0204L22.6872 19.0204Z"
                    fill="url(#paint0_linear_1_1166)" class="iconColor" />
                  <defs>
                    <linearGradient id="paint0_linear_1_1166" x1="23" y1="0" x2="0" y2="0"
                      gradientUnits="userSpaceOnUse">
                      <stop stop-color="#1D1D49" />
                      <stop offset="1" stop-color="#505084" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div class="p-2 header-top-most-btn align-self-center side-nav-act-btn" [style.cursor]="'pointer'"
                (click)="toggleDropDownMenu()" [ngClass]="{
                  'side-nav-act-btn-open-show': showDropdown,
                  'side-nav-act-btn-close-show': !showDropdown
                }">
                <div class="hamburger-lines"></div>
                <div class="hamburger-lines"></div>
                <div class="hamburger-lines"></div>
              </div>
            </div>
          </div>
          <!-- Serach Header -->
          <app-main></app-main>
        </div>
      </div>
    </div>
  </div>
  <!--Main layout-->
  <app-footer></app-footer>
  <!-- <footer class="footer-101 text-lg-start" style="z-index: 999">
    <div class="row">
      <div class="col-lg-3 col-4">
        <ul class="list-footer-101">
          <li><a href="#">About Kafu</a></li>
          <li><a href="#">Terms and Condition</a></li>
          <li><a href="#">Privacy Policy</a></li>
        </ul>
      </div>
      <div class="col-2  align-self-center">
        <div class="row get-social-101">{{ "newDasboard.GetSocial" | translate }}</div>
        <div class="row">
          <a href="#" class="fa fa-facebook">
            <i class="fab fa-facebook-f" style="color: #3b5998"></i>
          </a>
          <a href="#" class="fa fa-twitter">
            <i class="fab fa-twitter" style="color: #1da1f2"></i>
          </a>
          <a href="#" class="fa fa-google">
            <i class="fab fa-google" style="color: #ea4335"></i>
          </a>
          <a href="#" class="fa fa-youtube">
            <i class="fab fa-youtube" style="color: #ff0000"></i>
          </a>
          <a href="#" class="fa fa-instagram">
            <i class="fab fa-instagram" style="color: #c32aa3"></i>
          </a>
        </div>
      </div>
      <div class="col-lg-5 col-6 align-self-center news-letter-101">
        <form action="" method="post">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" viewBox="0 0 20 23" fill="none">
            <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
              d="M17.1897 7.66665H4.8131C4.58527 7.66665 4.40055 7.34483 4.40055 6.9479V4.55207C4.40055 4.15514 4.58527 3.83332 4.8131 3.83332H17.1897C17.4175 3.83332 17.6022 4.15514 17.6022 4.55207V6.9479C17.6022 7.34483 17.4175 7.66665 17.1897 7.66665ZM17.1896 13.4167H11.964C11.7362 13.4167 11.5514 13.0949 11.5514 12.6979V12.2188C11.5514 11.8218 11.7362 11.5 11.964 11.5H17.1896C17.4175 11.5 17.6022 11.8218 17.6022 12.2188V12.6979C17.6022 13.0949 17.4175 13.4167 17.1896 13.4167ZM10.0388 13.4167H4.81311C4.58527 13.4167 4.40055 13.0949 4.40055 12.6979V12.2188C4.40055 11.8218 4.58527 11.5 4.81311 11.5H10.0388C10.2666 11.5 10.4513 11.8218 10.4513 12.2188V12.6979C10.4513 13.0949 10.2666 13.4167 10.0388 13.4167ZM17.1896 19.1666H11.964C11.7362 19.1666 11.5514 18.8448 11.5514 18.4479V17.9687C11.5514 17.5718 11.7362 17.25 11.964 17.25H17.1896C17.4175 17.25 17.6022 17.5718 17.6022 17.9687V18.4479C17.6022 18.8448 17.4175 19.1666 17.1896 19.1666ZM10.0388 19.1666H4.81311C4.58527 19.1666 4.40055 18.8448 4.40055 18.4479V17.9687C4.40055 17.5718 4.58527 17.25 4.81311 17.25H10.0388C10.2666 17.25 10.4513 17.5718 10.4513 17.9687V18.4479C10.4513 18.8448 10.2666 19.1666 10.0388 19.1666ZM1.92523 20.125C1.77334 20.125 1.6502 19.9105 1.6502 19.6458V4.79168H2.20027V19.6458C2.20027 19.9105 2.07714 20.125 1.92524 20.125H1.92523ZM18.9774 0H3.02538C2.56968 0 2.20028 0.643579 2.20028 1.4375V1.91667H0.825104C0.369405 1.91667 0 2.56024 0 3.35417V19.6458C0 21.4983 0.861959 23 1.92524 23H18.1523C19.0637 23 19.8025 21.7128 19.8025 20.125V1.4375C19.8025 0.643579 19.4331 0 18.9774 0H18.9774Z"
              fill="url(#paint0_linear_1_621)" />
            <defs>
              <linearGradient id="paint0_linear_1_621" x1="19.8025" y1="0" x2="0" y2="0" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1D1D49" />
                <stop offset="1" stop-color="#505084" />
              </linearGradient>
            </defs>
          </svg>
          <span> {{ "newDasboard.NEWSLETTER" | translate }}</span>
          <input type="email" name="email" id="email" placeholder='{{"home.enterEmail"|translate}}'
            class="news-letter-email" />
          <input type="submit" value='{{"home.subscribe"|translate}}' class="subscribe-btn" />
        </form>
      </div>
      <div class="col-lg-2 col-1"></div>
    </div>
  </footer> -->
</div>