<div class="d-flex" [ngClass]="darkThemeService.getCurrentTheme">
  <app-new-sidebar
    style="border: 1px solid gray"
    class="side-bar"
    [ngClass]="{
      'sidebar-show': isSidebarVisible,
      'sidebar-hide': !isSidebarVisible,
      'border-ar': this.languageService.getLanguageOrDefault() === 'ar',
      'border-en': this.languageService.getLanguageOrDefault() !== 'ar'
    }"
  ></app-new-sidebar>
  <div
    class=""
    [ngClass]="
      this.languageService.getLanguageOrDefault() === 'ar'
        ? 'humburg-ar'
        : 'humburg'
    "
    (click)="toggleSidebar()"
  >
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 18L20 18"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M4 12L20 12"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M4 6L20 6"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>
  <app-my-new-dashboard
    class="w-100 adjust-height"
    style="overflow-x: auto"
  ></app-my-new-dashboard>
</div>
