import { Component, OnInit } from "@angular/core";
import { HomeService } from "../../../../services/home.service";
import { DarkLiteThemeService } from "../../../../services/dark-lite-themeService.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import moment = require("moment");
import { BsModalService } from "ngx-bootstrap/modal";
import { FormBuilder } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { SurveyService } from "src/services/survey.service";
import { LanguageService } from "src/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { CreateSurveyComponent } from "../../create-survey/create-survey.component";
import { CommingSoonModalComponent } from "../../loggedin-home/comming-soon-modal/comming-soon-modal.component";
import { AuthService } from "src/services/auth.service";
import { eLanguage } from "src/app/general/enums/language.enum";
import { NewServeyComponent } from "../../new-servey/new-servey.component";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
export class MainComponent implements OnInit {
  slideConfig: OwlOptions = {
    loop: true,
    mouseDrag: false,
    margin: 12,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    animateOut: "fadeOut",
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 1,
      },
      900: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    navSpeed: 700,
    nav: true,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  newSlideConfig: OwlOptions = {
    loop: true,
    mouseDrag: false,
    margin: 12,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    animateOut: "fadeOut",
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 1,
      },
      900: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
    navSpeed: 700,
    nav: true,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  userSurveys = [];
  userSurveySlider = []
  tableSurvey = [];
  user: any;
  currentUser: any;
  filterText = "All";
  mobile: Number;
  showAllSurveys: boolean = false;
  language: string = eLanguage.English;

  web: Number;
  female: Number;
  male: Number;
  surveys: Number;
  spendingPoints: Number;
  answers: Number;
  likes: Number;
  clicked: Number;
  referrals: Number;
  current_rotation: Number;
  locale = eLanguage.Short_English;
  elanguage = eLanguage;
  currentLanguage: any;
  dynamicStyles: { [key: string]: string } = {};

  userStats: any;
  showLoader = false;
  constructor(
    private homeService: HomeService,
    private authService: AuthService,
    public darkThemeService: DarkLiteThemeService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private surveyService: SurveyService,
    public languageService: LanguageService
  ) {
    this.currentUser = this.authService?.currentUser;
    this.currentLanguage = this.languageService.getLanguageOrDefault();
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    if (this.currentLanguage === eLanguage.Arabic) {
      console.log("🚀 ~ file: main.component.ts:153 ~ MainComponent ~ currentLanguage:", this.currentLanguage)
      this.slideConfig.rtl = true;
    }
  }

  ngOnInit(): void {
    this.getUserSurveys();
    this.getUserAllStatsReport();
  }

  getUserAllStats() {
    // this.showLoader = true;
    this.homeService.getUserStats().subscribe((result) => {
      this.userStats = result;
      this.showLoader = false;
    });
  }

  getUserSurveys() {
    let data = [];
    this.homeService.getMySurvey().subscribe((result) => {
      if (result?.length) {
        data = result.filter((item: any) => {
          // Find the distance between now and the count down date
          let surveyFinished =
            new Date(item.endDate).getTime() - new Date().getTime();
          item["days"] = Math.floor(surveyFinished / (1000 * 60 * 60 * 24));
          item["hours"] = Math.floor(
            (surveyFinished % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          item["minutes"] = Math.floor(
            (surveyFinished % (1000 * 60 * 60)) / (1000 * 60)
          );
          item["seconds"] = Math.floor((surveyFinished % (1000 * 60)) / 1000);
          item["pointsOrResponse"] = "points";
          return (
            new Date(item.startDate) <= new Date() &&
            moment().isBefore(moment(item.endDate), "minute")
          );
        });
        this.userSurveys = data?.length ? this.homeService.deepCopy(data) : [];
        this.userSurveySlider = data?.length ? this.homeService.deepCopy(data.filter((item: any) => item.isPublished)) : [];
        this.tableSurvey = data?.length ? this.homeService.deepCopy(data) : [];
        console.log("userSurveys: ", this.userSurveys);
      }
    });
  }

  onPointsOrResponseClick(survey: any) {
    survey.pointsOrResponse =
      survey.pointsOrResponse == "points" ? "responses" : "points";
  }

  openSurvey() {
    this.router.navigate(["/new-survey"]);
  }

  createSurvey() {
    const verifyModel = this.modalService.show(CreateSurveyComponent, {
      class:
        "modal-lg modal-dialog-centered sign-up c-survey-modal  create-survey-modal",
      backdrop: "static",
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
  }
  async copyUrl(surveyId: string, surveyType: string, language: string) {
    this.homeService.copySurveyUrl(surveyId, surveyType);
  }

  openComingSoonModal() {
    const entModel = this.modalService.show(CommingSoonModalComponent, {
      class: "redeem-modal-container modal-dialog-centered",

      // backdrop: 'static',
    });
    entModel.content.onClose = () => {
      entModel.hide();
    };
  }
  CustomSurvey() {
    // this.router.navigate([`/new-survey`]);
    const verifyModel = this.modalService.show(NewServeyComponent, {
      class: "new-survey create-survey-modal",
      backdrop: "static",
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
  }
  stopSurvey(survey: any) {
    this.surveyService.stop(survey).subscribe((result) => {
      this.translateService.get("update_success_message").subscribe((text) => {
        this.toastr.success(text);
      });
    });

    this.getUserSurveys();
    this.getUserAllStatsReport();
    this.getUserAllStats();
  }

  edit(survey) {
    this.surveyService.edit(survey);
  }
  toggleShowAllSurveys() {
    this.showAllSurveys = !this.showAllSurveys;
  }

  detail(survey: any) {
    // this.surveyService.createLocalSurveyDetail(survey);
    // this.router.navigate(["/survey/details"]);
    this.surveyService.createLocalSurveyModal(survey);
    this.router.navigate([`/survey/details`]);
  }

  getDynamicStyles() {
    if (this.currentLanguage === this.elanguage.Short_Arabic) {
      this.dynamicStyles = {
        "align-items": "end !important", // Example: Add Arabic-specific styles
      };
    } else {
      this.dynamicStyles = {
        "align-items": "flex-start !important",
      };
    }
    return this.dynamicStyles;
  }
  filterSurvey(key, value: any): void {
    this.filterText = key;
    this.tableSurvey = this.userSurveys.filter(
      (survey) => survey[key] == value
    );
  }
  AllUserSurvey() {
    this.filterText = "All";
    this.tableSurvey = this.userSurveys;
  }

  getUserAllStatsReport() {
    this.showLoader = true;
    this.homeService.getUserStats().subscribe((result) => {
      this.female = result?.female;
      this.clicked = result?.UserReport?.clicked?.length;
      this.male = result?.male;
      this.mobile = result?.mobile;
      this.web = result?.web;
      this.answers = result?.answers;
      this.referrals = result?.referrals;
      this.surveys = result?.surveys;
      this.spendingPoints = result?.spendingPoints;
      this.web = result?.web;
      this.likes = result?.likes;
      this.showLoader = false;
    });
  }

  date() {
    const start = (document.getElementById("startDate") as HTMLInputElement)
      .value;

    const end = (document.getElementById("endDate") as HTMLInputElement).value;

    if (start && end) {
      console.log("startDate", start, end);
      const filterData = this.userSurveys.filter((item) => {
        return (
          new Date(item.startDate).getTime() >= new Date(start).getTime() &&
          new Date(item.endDate).getTime() <= new Date(end).getTime()
        );
      });
      this.tableSurvey = filterData.map((x) => {
        return x;
      });
    } else {
      setTimeout(() => {
        this.tableSurvey = this.userSurveys;
        // this.sortPR();
      }, 2000);
    }
  }

  sortPR() {
    this.tableSurvey.sort((a, b) => {
      let fa = a.startDate,
        fb = b.startDate;
      if (fa < fb) {
        return 1;
      }
      if (fa > fb) {
        return -1;
      }
      return 0;
    });
  }
  refresh() {
    this.getUserAllStatsReport();
    // this.getUserAllStats();
    var myImage = document.getElementById("myImage");
    if (myImage.style.transform == "rotate(-360deg)") {
      myImage.style.transform = "rotate(360deg)";
      myImage.style.transition = "transform 1s linear";
    } else {
      myImage.style.transform = "rotate(-360deg)";
      myImage.style.transition = "transform 1s linear";
    }
  }
}
