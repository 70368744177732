export const countriesAr =
  [
    {
      "value": 304,
      "label": "Saudi Arabia",
      "label_ar": "المملكة العربية السعودية",
      "label_fr": "Arabie Saoudite"
    },
    {
      "value": 345,
      "label": "United Arab Emirates",
      "label_ar": "الإمارات العربية المتحدة",
      "label_fr": "Emirats Arabes Unis"
    },
    {
      "value": 112,
      "label": "Afghanistan",
      "label_ar": "أفغانستان",
      "label_fr": "Afghanistan"
    },
    {
      "value": 113,
      "label": "Albania",
      "label_ar": "ألبانيا",
      "label_fr": "Albanie"
    },
    {
      "value": 114,
      "label": "Algeria",
      "label_ar": "الجزائر",
      "label_fr": "Algérie"
    },
    {
      "value": 115,
      "label": "American Samoa",
      "label_ar": "ساموا الأمريكية",
      "label_fr": "Samoa américaines"
    },
    {
      "value": 116,
      "label": "Andorra",
      "label_ar": "أندورا",
      "label_fr": "Andorre"
    },
    {
      "value": 117,
      "label": "Angola",
      "label_ar": "أنغولا",
      "label_fr": "Angola"
    },
    {
      "value": 118,
      "label": "Anguilla",
      "label_ar": "أنغيلا",
      "label_fr": "Anguilla"
    },
    {
      "value": 119,
      "label": "Antarctica",
      "label_ar": "القارة القطبية الجنوبية",
      "label_fr": "Antarctique"
    },
    {
      "value": 120,
      "label": "Antigua and Barbuda",
      "label_ar": "أنتيغوا وبربودا",
      "label_fr": "Antigua-et-Barbuda"
    },
    {
      "value": 121,
      "label": "Argentina",
      "label_ar": "الأرجنتين",
      "label_fr": "Argentine"
    },
    {
      "value": 122,
      "label": "Armenia",
      "label_ar": "أرمينيا",
      "label_fr": "Arménie"
    },
    { "value": 123, "label": "Aruba", "label_ar": "أروبا", "label_fr": "Aruba" },
    {
      "value": 124,
      "label": "Australia",
      "label_ar": "أستراليا",
      "label_fr": "Australie"
    },
    {
      "value": 125,
      "label": "Austria",
      "label_ar": "النمسا",
      "label_fr": "L'Autriche"
    },
    {
      "value": 127,
      "label": "Bahamas",
      "label_ar": "جزر البهاما",
      "label_fr": "Bahamas"
    },
    {
      "value": 128,
      "label": "Bahrain",
      "label_ar": "البحرين",
      "label_fr": "Bahreïn"
    },
    {
      "value": 129,
      "label": "Bangladesh",
      "label_ar": "بنغلاديش",
      "label_fr": "Bangladesh"
    },
    {
      "value": 130,
      "label": "Barbados",
      "label_ar": "بربادوس",
      "label_fr": "Barbade"
    },
    {
      "value": 131,
      "label": "Belarus",
      "label_ar": "بيلاروسيا",
      "label_fr": "Biélorussie"
    },
    {
      "value": 132,
      "label": "Belgium",
      "label_ar": "بلجيكا",
      "label_fr": "Belgique"
    },
    { "value": 133, "label": "Belize", "label_ar": "بليز", "label_fr": "Belize" },
    { "value": 134, "label": "Benin", "label_ar": "بنين", "label_fr": "Bénin" },
    {
      "value": 135,
      "label": "Bermuda",
      "label_ar": "برمودا",
      "label_fr": "Bermudes"
    },
    {
      "value": 136,
      "label": "Bhutan",
      "label_ar": "بوتان",
      "label_fr": "Bhoutan"
    },
    {
      "value": 137,
      "label": "Bolivia",
      "label_ar": "بوليفيا",
      "label_fr": "Bolivie"
    },
    {
      "value": 138,
      "label": "Bonaire",
      "label_ar": "بونير",
      "label_fr": "Bonaire"
    },
    {
      "value": 139,
      "label": "Bosnia and Herzegovina",
      "label_ar": "البوسنة والهرسك",
      "label_fr": "Bosnie Herzégovine"
    },
    {
      "value": 140,
      "label": "Botswana",
      "label_ar": "بوتسوانا",
      "label_fr": "Botswana"
    },
    {
      "value": 141,
      "label": "Brazil",
      "label_ar": "البرازيل",
      "label_fr": "Brésil"
    },
    {
      "value": 143,
      "label": "British Virgin Islands",
      "label_ar": "جزر فيرجن البريطانية",
      "label_fr": "Îles Vierges britanniques"
    },
    {
      "value": 144,
      "label": "Brunei",
      "label_ar": "بروناي",
      "label_fr": "Brunei"
    },
    {
      "value": 146,
      "label": "Burkina Faso",
      "label_ar": "بوركينا فاسو",
      "label_fr": "Burkina Faso"
    },
    {
      "value": 147,
      "label": "Burundi",
      "label_ar": "بوروندي",
      "label_fr": "Burundi"
    },
    {
      "value": 148,
      "label": "Cambodia",
      "label_ar": "كمبوديا",
      "label_fr": "Cambodge"
    },
    {
      "value": 149,
      "label": "Cameroon",
      "label_ar": "الكاميرون",
      "label_fr": "Cameroun"
    },
    { "value": 150, "label": "Canada", "label_ar": "كندا", "label_fr": "Canada" },
    {
      "value": 151,
      "label": "Cape Verde",
      "label_ar": "الرأس الأخضر",
      "label_fr": "Cap-Vert"
    },
    {
      "value": 152,
      "label": "Cayman Islands",
      "label_ar": "جزر كايمان",
      "label_fr": "Îles Caïmans"
    },
    {
      "value": 153,
      "label": "Central African Republic",
      "label_ar": "جمهورية افريقيا الوسطى",
      "label_fr": "République centrafricaine"
    },
    { "value": 154, "label": "Chad", "label_ar": "تشاد", "label_fr": "Tchad" },
    { "value": 155, "label": "Chile", "label_ar": "تشيلي", "label_fr": "Chili" },
    { "value": 156, "label": "China", "label_ar": "الصين", "label_fr": "Chine" },
    {
      "value": 157,
      "label": "Christmas Island",
      "label_ar": "جزيرة الكريسماس",
      "label_fr": "L'île de noël"
    },
    {
      "value": 158,
      "label": "Cocos [Keeling] Islands",
      "label_ar": "جزر كوكوس [كيلينغ]",
      "label_fr": "Îles Cocos [Keeling]"
    },
    {
      "value": 159,
      "label": "Colombia",
      "label_ar": "كولومبيا",
      "label_fr": "Colombie"
    },
    {
      "value": 160,
      "label": "Comoros",
      "label_ar": "جزر القمر",
      "label_fr": "Comores"
    },
    {
      "value": 161,
      "label": "Congo",
      "label_ar": "الكونغو",
      "label_fr": "Congo"
    },
    {
      "value": 162,
      "label": "Cook Islands",
      "label_ar": "جزر كوك",
      "label_fr": "les Îles Cook"
    },
    {
      "value": 163,
      "label": "Costa Rica",
      "label_ar": "كوستا ريكا",
      "label_fr": "Costa Rica"
    },
    {
      "value": 164,
      "label": "Croatia",
      "label_ar": "كرواتيا",
      "label_fr": "Croatie"
    },
    { "value": 165, "label": "Cuba", "label_ar": "كوبا", "label_fr": "Cuba" },
    {
      "value": 166,
      "label": "Curaçao",
      "label_ar": "كوراساو",
      "label_fr": "Curacao"
    },
    { "value": 167, "label": "Cyprus", "label_ar": "قبرص", "label_fr": "Chypre" },
    {
      "value": 168,
      "label": "Czech Republic",
      "label_ar": "جمهورية التشيك",
      "label_fr": "République Tchèque"
    },
    {
      "value": 169,
      "label": "Denmark",
      "label_ar": "الدنمارك",
      "label_fr": "Danemark"
    },
    {
      "value": 170,
      "label": "Djibouti",
      "label_ar": "جيبوتي",
      "label_fr": "Djibouti"
    },
    {
      "value": 171,
      "label": "Dominica",
      "label_ar": "دومينيكا",
      "label_fr": "Dominique"
    },
    {
      "value": 172,
      "label": "Dominican Republic",
      "label_ar": "جمهورية الدومنيكان",
      "label_fr": "République Dominicaine"
    },
    {
      "value": 173,
      "label": "East Timor",
      "label_ar": "تيمور الشرقية",
      "label_fr": "Timor oriental"
    },
    {
      "value": 174,
      "label": "Ecuador",
      "label_ar": "الاكوادور",
      "label_fr": "Equateur"
    },
    { "value": 175, "label": "Egypt", "label_ar": "مصر", "label_fr": "Egypte" },
    {
      "value": 176,
      "label": "El Salvador",
      "label_ar": "السلفادور",
      "label_fr": "Le Salvador"
    },
    {
      "value": 177,
      "label": "Equatorial Guinea",
      "label_ar": "غينيا الإستوائية",
      "label_fr": "Guinée Équatoriale"
    },
    {
      "value": 178,
      "label": "Eritrea",
      "label_ar": "إريتريا",
      "label_fr": "Érythrée"
    },
    {
      "value": 179,
      "label": "Estonia",
      "label_ar": "إستونيا",
      "label_fr": "Estonie"
    },
    {
      "value": 180,
      "label": "Ethiopia",
      "label_ar": "أثيوبيا",
      "label_fr": "Ethiopie"
    },
    {
      "value": 181,
      "label": "Falkland Islands",
      "label_ar": "جزر فوكلاند",
      "label_fr": "les îles Falkland"
    },
    {
      "value": 182,
      "label": "Faroe Islands",
      "label_ar": "جزر فاروس",
      "label_fr": "Îles Féroé"
    },
    {
      "value": 183,
      "label": "Federated States of Micronesia",
      "label_ar": "ولايات ميكرونيزيا الموحدة",
      "label_fr": "États fédérés de Micronésie"
    },
    { "value": 184, "label": "Fiji", "label_ar": "فيجي", "label_fr": "Fidji" },
    {
      "value": 186,
      "label": "France",
      "label_ar": "فرنسا",
      "label_fr": "France"
    },
    {
      "value": 187,
      "label": "French Guiana",
      "label_ar": "غيانا الفرنسية",
      "label_fr": "Guyane Française"
    },
    {
      "value": 188,
      "label": "French Polynesia",
      "label_ar": "بولينيزيا الفرنسية",
      "label_fr": "Polynésie française"
    },
    {
      "value": 189,
      "label": "French Southern Territories",
      "label_ar": "المناطق الجنوبية لفرنسا",
      "label_fr": "Terres australes françaises"
    },
    {
      "value": 190,
      "label": "Gabon",
      "label_ar": "الجابون",
      "label_fr": "Gabon"
    },
    {
      "value": 191,
      "label": "Gambia",
      "label_ar": "غامبيا",
      "label_fr": "Gambie"
    },
    {
      "value": 192,
      "label": "Georgia",
      "label_ar": "جورجيا",
      "label_fr": "Géorgie"
    },
    {
      "value": 193,
      "label": "Germany",
      "label_ar": "ألمانيا",
      "label_fr": "Allemagne"
    },
    { "value": 194, "label": "Ghana", "label_ar": "غانا", "label_fr": "Ghana" },
    {
      "value": 195,
      "label": "Gibraltar",
      "label_ar": "جبل طارق",
      "label_fr": "Gibraltar"
    },
    {
      "value": 196,
      "label": "Greece",
      "label_ar": "اليونان",
      "label_fr": "Grèce"
    },
    {
      "value": 197,
      "label": "Greenland",
      "label_ar": "الأرض الخضراء",
      "label_fr": "Groenland"
    },
    {
      "value": 198,
      "label": "Grenada",
      "label_ar": "غرينادا",
      "label_fr": "Grenade"
    },
    {
      "value": 199,
      "label": "Guadeloupe",
      "label_ar": "جوادلوب",
      "label_fr": "Guadeloupe"
    },
    { "value": 200, "label": "Guam", "label_ar": "غوام", "label_fr": "Guam" },
    {
      "value": 201,
      "label": "Guatemala",
      "label_ar": "غواتيمالا",
      "label_fr": "Guatemala"
    },
    {
      "value": 202,
      "label": "Guernsey",
      "label_ar": "غيرنسي",
      "label_fr": "Guernesey"
    },
    {
      "value": 203,
      "label": "Guinea",
      "label_ar": "غينيا",
      "label_fr": "Guinée"
    },
    {
      "value": 204,
      "label": "Guinea-Bissau",
      "label_ar": "غينيا بيساو",
      "label_fr": "Guinée-Bissau"
    },
    {
      "value": 205,
      "label": "Guyana",
      "label_ar": "غيانا",
      "label_fr": "Guyane"
    },
    { "value": 206, "label": "Haiti", "label_ar": "هايتي", "label_fr": "Haïti" },
    {
      "value": 207,
      "label": "Hashemite Kingdom of Jordan",
      "label_ar": "المملكة الأردنية الهاشمية",
      "label_fr": "Royaume hachémite de Jordanie"
    },
    {
      "value": 208,
      "label": "Honduras",
      "label_ar": "هندوراس",
      "label_fr": "Honduras"
    },
    {
      "value": 209,
      "label": "Hong Kong",
      "label_ar": "هونج كونج",
      "label_fr": "Hong Kong"
    },
    {
      "value": 210,
      "label": "Hungary",
      "label_ar": "هنغاريا",
      "label_fr": "Hongrie"
    },
    { "value": 212, "label": "India", "label_ar": "الهند", "label_fr": "Inde" },
    {
      "value": 213,
      "label": "Indonesia",
      "label_ar": "إندونيسيا",
      "label_fr": "Indonésie"
    },
    { "value": 214, "label": "Iran", "label_ar": "إيران", "label_fr": "L'Iran" },
    { "value": 215, "label": "Iraq", "label_ar": "العراق", "label_fr": "Irak" },
    {
      "value": 216,
      "label": "Ireland",
      "label_ar": "أيرلندا",
      "label_fr": "Irlande"
    },
    {
      "value": 217,
      "label": "Isle of Man",
      "label_ar": "جزيرة آيل أوف مان",
      "label_fr": "île de Man"
    },
    {
      "value": 218,
      "label": "Israel",
      "label_ar": "إسرائيل",
      "label_fr": "Israël"
    },
    {
      "value": 219,
      "label": "Italy",
      "label_ar": "إيطاليا",
      "label_fr": "Italie"
    },
    {
      "value": 220,
      "label": "Ivory Coast",
      "label_ar": "ساحل العاج",
      "label_fr": "Côte d'Ivoire"
    },
    {
      "value": 221,
      "label": "Jamaica",
      "label_ar": "جامايكا",
      "label_fr": "Jamaïque"
    },
    {
      "value": 222,
      "label": "Japan",
      "label_ar": "اليابان",
      "label_fr": "Japon"
    },
    {
      "value": 224,
      "label": "Kazakhstan",
      "label_ar": "كازاخستان",
      "label_fr": "Kazakhstan"
    },
    { "value": 225, "label": "Kenya", "label_ar": "كينيا", "label_fr": "Kenya" },
    {
      "value": 226,
      "label": "Kiribati",
      "label_ar": "كيريباتي",
      "label_fr": "Kiribati"
    },
    {
      "value": 227,
      "label": "Kosovo",
      "label_ar": "كوسوفو",
      "label_fr": "Kosovo"
    },
    {
      "value": 228,
      "label": "Kuwait",
      "label_ar": "الكويت",
      "label_fr": "Koweit"
    },
    {
      "value": 229,
      "label": "Kyrgyzstan",
      "label_ar": "قيرغيزستان",
      "label_fr": "Kirghizistan"
    },
    { "value": 230, "label": "Laos", "label_ar": "لاوس", "label_fr": "Laos" },
    {
      "value": 231,
      "label": "Latvia",
      "label_ar": "لاتفيا",
      "label_fr": "Lettonie"
    },
    {
      "value": 232,
      "label": "Lebanon",
      "label_ar": "لبنان",
      "label_fr": "Liban"
    },
    {
      "value": 233,
      "label": "Lesotho",
      "label_ar": "ليسوتو",
      "label_fr": "Lesotho"
    },
    {
      "value": 234,
      "label": "Liberia",
      "label_ar": "ليبيريا",
      "label_fr": "Libéria"
    },
    { "value": 235, "label": "Libya", "label_ar": "ليبيا", "label_fr": "Libye" },
    {
      "value": 237,
      "label": "Luxembourg",
      "label_ar": "لوكسمبورغ",
      "label_fr": "Luxembourg"
    },
    { "value": 238, "label": "Macao", "label_ar": "ماكاو", "label_fr": "Macao" },
    {
      "value": 240,
      "label": "Madagascar",
      "label_ar": "مدغشقر",
      "label_fr": "Madagascar"
    },
    {
      "value": 241,
      "label": "Malawi",
      "label_ar": "ملاوي",
      "label_fr": "Malawi"
    },
    {
      "value": 242,
      "label": "Malaysia",
      "label_ar": "ماليزيا",
      "label_fr": "Malaisie"
    },
    {
      "value": 243,
      "label": "Maldives",
      "label_ar": "جزر المالديف",
      "label_fr": "Maldives"
    },
    { "value": 244, "label": "Mali", "label_ar": "مالي", "label_fr": "Mali" },
    { "value": 245, "label": "Malta", "label_ar": "مالطا", "label_fr": "Malte" },
    {
      "value": 246,
      "label": "Marshall Islands",
      "label_ar": "جزر مارشال",
      "label_fr": "Iles Marshall"
    },
    {
      "value": 247,
      "label": "Martinique",
      "label_ar": "مارتينيك",
      "label_fr": "Martinique"
    },
    {
      "value": 248,
      "label": "Mauritania",
      "label_ar": "موريتانيا",
      "label_fr": "Mauritanie"
    },
    {
      "value": 249,
      "label": "Mauritius",
      "label_ar": "موريشيوس",
      "label_fr": "Ile Maurice"
    },
    {
      "value": 250,
      "label": "Mayotte",
      "label_ar": "مايوت",
      "label_fr": "Mayotte"
    },
    {
      "value": 251,
      "label": "Mexico",
      "label_ar": "المكسيك",
      "label_fr": "Mexique"
    },
    {
      "value": 252,
      "label": "Monaco",
      "label_ar": "موناكو",
      "label_fr": "Monaco"
    },
    {
      "value": 253,
      "label": "Mongolia",
      "label_ar": "منغوليا",
      "label_fr": "Mongolie"
    },
    {
      "value": 254,
      "label": "Montenegro",
      "label_ar": "الجبل الأسود",
      "label_fr": "Monténégro"
    },
    {
      "value": 255,
      "label": "Montserrat",
      "label_ar": "مونتسيرات",
      "label_fr": "Montserrat"
    },
    {
      "value": 256,
      "label": "Morocco",
      "label_ar": "المغرب",
      "label_fr": "Maroc"
    },
    {
      "value": 257,
      "label": "Mozambique",
      "label_ar": "موزمبيق",
      "label_fr": "Mozambique"
    },
    {
      "value": 258,
      "label": "Myanmar [Burma]",
      "label_ar": "ميانمار [بورما]",
      "label_fr": "Myanmar [Birmanie]"
    },
    {
      "value": 259,
      "label": "Namibia",
      "label_ar": "ناميبيا",
      "label_fr": "Namibie"
    },
    { "value": 260, "label": "Nauru", "label_ar": "ناورو", "label_fr": "Nauru" },
    { "value": 261, "label": "Nepal", "label_ar": "نيبال", "label_fr": "Népal" },
    {
      "value": 262,
      "label": "Netherlands",
      "label_ar": "هولندا",
      "label_fr": "Pays-Bas"
    },
    {
      "value": 263,
      "label": "New Caledonia",
      "label_ar": "كاليدونيا الجديدة",
      "label_fr": "Nouvelle Calédonie"
    },
    {
      "value": 264,
      "label": "New Zealand",
      "label_ar": "نيوزيلاندا",
      "label_fr": "Nouvelle-Zélande"
    },
    {
      "value": 265,
      "label": "Nicaragua",
      "label_ar": "نيكاراغوا",
      "label_fr": "Nicaragua"
    },
    { "value": 266, "label": "Niger", "label_ar": "النيجر", "label_fr": "Niger" },
    {
      "value": 267,
      "label": "Nigeria",
      "label_ar": "نيجيريا",
      "label_fr": "Nigeria"
    },
    { "value": 268, "label": "Niue", "label_ar": "نيوي", "label_fr": "Niue" },
    {
      "value": 269,
      "label": "Norfolk Island",
      "label_ar": "جزيرة نورفولك",
      "label_fr": "l'ile de Norfolk"
    },
    {
      "value": 270,
      "label": "North Korea",
      "label_ar": "كوريا الشمالية",
      "label_fr": "Corée du Nord"
    },
    {
      "value": 271,
      "label": "Northern Mariana Islands",
      "label_ar": "جزر مريانا الشمالية",
      "label_fr": "Îles Mariannes du Nord"
    },
    {
      "value": 272,
      "label": "Norway",
      "label_ar": "النرويج",
      "label_fr": "Norvège"
    },
    {
      "value": 274,
      "label": "Pakistan",
      "label_ar": "باكستان",
      "label_fr": "Pakistan"
    },
    { "value": 277, "label": "Panama", "label_ar": "بنما", "label_fr": "Panama" },
    {
      "value": 278,
      "label": "Papua New Guinea",
      "label_ar": "بابوا غينيا الجديدة",
      "label_fr": "Papouasie Nouvelle Guinée"
    },
    {
      "value": 279,
      "label": "Paraguay",
      "label_ar": "باراغواي",
      "label_fr": "Paraguay"
    },
    { "value": 280, "label": "Peru", "label_ar": "بيرو", "label_fr": "Pérou" },
    {
      "value": 281,
      "label": "Philippines",
      "label_ar": "الفلبين",
      "label_fr": "Philippines"
    },
    {
      "value": 282,
      "label": "Pitcairn Islands",
      "label_ar": "جزر بيتكيرن",
      "label_fr": "Îles Pitcairn"
    },
    {
      "value": 283,
      "label": "Poland",
      "label_ar": "بولندا",
      "label_fr": "Pologne"
    },
    {
      "value": 284,
      "label": "Portugal",
      "label_ar": "البرتغال",
      "label_fr": "le Portugal"
    },
    {
      "value": 285,
      "label": "Puerto Rico",
      "label_ar": "بورتوريكو",
      "label_fr": "Porto Rico"
    },
    {
      "value": 286,
      "label": "Qatar",
      "label_ar": "دولة قطر",
      "label_fr": "Qatar"
    },
    {
      "value": 287,
      "label": "Republic of Korea",
      "label_ar": "جمهورية كوريا",
      "label_fr": "République de Corée"
    },
    {
      "value": 290,
      "label": "Republic of the Congo",
      "label_ar": "جمهورية الكونغو",
      "label_fr": "République du Congo"
    },
    {
      "value": 291,
      "label": "Romania",
      "label_ar": "رومانيا",
      "label_fr": "Roumanie"
    },
    {
      "value": 292,
      "label": "Russia",
      "label_ar": "روسيا",
      "label_fr": "Russie"
    },
    {
      "value": 293,
      "label": "Rwanda",
      "label_ar": "رواندا",
      "label_fr": "Rwanda"
    },
    {
      "value": 294,
      "label": "Réunion",
      "label_ar": "جمع شمل",
      "label_fr": "Réunion"
    },
    {
      "value": 295,
      "label": "Saint Helena",
      "label_ar": "سانت هيلانة",
      "label_fr": "Sainte-Hélène"
    },
    {
      "value": 296,
      "label": "Saint Kitts and Nevis",
      "label_ar": "سانت كيتس ونيفيس",
      "label_fr": "Saint-Christophe-et-Niévès"
    },
    {
      "value": 297,
      "label": "Saint Lucia",
      "label_ar": "القديسة لوسيا",
      "label_fr": "Sainte-Lucie"
    },
    {
      "value": 298,
      "label": "Saint Martin",
      "label_ar": "القديس مارتن",
      "label_fr": "Saint Martin"
    },
    {
      "value": 300,
      "label": "Saint Vincent and the Grenadines",
      "label_ar": "سانت فنسنت وجزر غرينادين",
      "label_fr": "Saint-Vincent-et-les-Grenadines"
    },
    {
      "value": 301,
      "label": "Saint-Barthélemy",
      "label_ar": "سانت بارتيليمي",
      "label_fr": "Saint-Barthélemy"
    },
    { "value": 302, "label": "Samoa", "label_ar": "ساموا", "label_fr": "Samoa" },
    {
      "value": 303,
      "label": "San Marino",
      "label_ar": "سان مارينو",
      "label_fr": "Saint Marin"
    },

    {
      "value": 305,
      "label": "Senegal",
      "label_ar": "السنغال",
      "label_fr": "Sénégal"
    },
    {
      "value": 307,
      "label": "Seychelles",
      "label_ar": "سيشيل",
      "label_fr": "les Seychelles"
    },
    {
      "value": 308,
      "label": "Sierra Leone",
      "label_ar": "سيرا ليون",
      "label_fr": "Sierra Leone"
    },
    {
      "value": 309,
      "label": "Singapore",
      "label_ar": "سنغافورة",
      "label_fr": "Singapour"
    },
    {
      "value": 310,
      "label": "Sint Maarten",
      "label_ar": "سينت مارتن",
      "label_fr": "Sint Maarten"
    },
    {
      "value": 312,
      "label": "Slovenia",
      "label_ar": "سلوفينيا",
      "label_fr": "Slovénie"
    },
    {
      "value": 313,
      "label": "Solomon Islands",
      "label_ar": "جزر سليمان",
      "label_fr": "Les îles Salomon"
    },
    {
      "value": 314,
      "label": "Somalia",
      "label_ar": "الصومال",
      "label_fr": "Somalie"
    },
    {
      "value": 315,
      "label": "South Africa",
      "label_ar": "جنوب أفريقيا",
      "label_fr": "Afrique du Sud"
    },
    {
      "value": 316,
      "label": "South Georgia and the South Sandwich Islands",
      "label_ar": "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
      "label_fr": "Géorgie du Sud et îles Sandwich du Sud"
    },
    {
      "value": 317,
      "label": "South Sudan",
      "label_ar": "جنوب السودان",
      "label_fr": "Soudan du sud"
    },
    {
      "value": 318,
      "label": "Spain",
      "label_ar": "إسبانيا",
      "label_fr": "Espagne"
    },
    {
      "value": 319,
      "label": "Sri Lanka",
      "label_ar": "سيريلانكا",
      "label_fr": "Sri Lanka"
    },
    {
      "value": 320,
      "label": "Sudan",
      "label_ar": "السودان",
      "label_fr": "Soudan"
    },
    {
      "value": 321,
      "label": "Suriname",
      "label_ar": "سورينام",
      "label_fr": "Suriname"
    },
    {
      "value": 322,
      "label": "Svalbard and Jan Mayen",
      "label_ar": "سفالبارد وجان ماين",
      "label_fr": "Svalbard et Jan Mayen"
    },
    {
      "value": 323,
      "label": "Swaziland",
      "label_ar": "سوازيلاند",
      "label_fr": "Swaziland"
    },
    {
      "value": 324,
      "label": "Sweden",
      "label_ar": "السويد",
      "label_fr": "Suède"
    },
    {
      "value": 325,
      "label": "Switzerland",
      "label_ar": "سويسرا",
      "label_fr": "Suisse"
    },
    { "value": 326, "label": "Syria", "label_ar": "سوريا", "label_fr": "Syrie" },
    {
      "value": 327,
      "label": "São Tomé and Príncipe",
      "label_ar": "ساو تومي وبرينسيبي",
      "label_fr": "São Tomé et Príncipe"
    },
    {
      "value": 328,
      "label": "Taiwan",
      "label_ar": "تايوان",
      "label_fr": "Taïwan"
    },
    {
      "value": 329,
      "label": "Tajikistan",
      "label_ar": "طاجيكستان",
      "label_fr": "Tadjikistan"
    },
    {
      "value": 330,
      "label": "Tanzania",
      "label_ar": "تنزانيا",
      "label_fr": "Tanzanie"
    },
    {
      "value": 331,
      "label": "Thailand",
      "label_ar": "تايلاند",
      "label_fr": "Thaïlande"
    },
    { "value": 332, "label": "Togo", "label_ar": "توجو", "label_fr": "Aller" },
    {
      "value": 333,
      "label": "Tokelau",
      "label_ar": "توكيلاو",
      "label_fr": "Tokelau"
    },
    { "value": 334, "label": "Tonga", "label_ar": "تونغا", "label_fr": "Tonga" },
    {
      "value": 335,
      "label": "Trinidad and Tobago",
      "label_ar": "ترينداد وتوباغو",
      "label_fr": "Trinité-et-Tobago"
    },
    {
      "value": 336,
      "label": "Tunisia",
      "label_ar": "تونس",
      "label_fr": "Tunisie"
    },
    {
      "value": 337,
      "label": "Turkey",
      "label_ar": "ديك رومي",
      "label_fr": "dinde"
    },
    {
      "value": 338,
      "label": "Turkmenistan",
      "label_ar": "تركمانستان",
      "label_fr": "Turkménistan"
    },
    {
      "value": 339,
      "label": "Turks and Caicos Islands",
      "label_ar": "جزر تركس وكايكوس",
      "label_fr": "îles Turques-et-Caïques"
    },
    {
      "value": 340,
      "label": "Tuvalu",
      "label_ar": "توفالو",
      "label_fr": "Tuvalu"
    },
    {
      "value": 342,
      "label": "U.S. Virgin Islands",
      "label_ar": "جزر فيرجن الأمريكية",
      "label_fr": "Îles Vierges américaines"
    },
    {
      "value": 343,
      "label": "Uganda",
      "label_ar": "أوغندا",
      "label_fr": "Ouganda"
    },
    {
      "value": 344,
      "label": "Ukraine",
      "label_ar": "أوكرانيا",
      "label_fr": "Ukraine"
    },

    {
      "value": 346,
      "label": "United Kingdom",
      "label_ar": "المملكة المتحدة",
      "label_fr": "Royaume-Uni"
    },
    {
      "value": 347,
      "label": "United States",
      "label_ar": "الولايات المتحدة الأمريكية",
      "label_fr": "États Unis"
    },
    {
      "value": 348,
      "label": "Uruguay",
      "label_ar": "أوروغواي",
      "label_fr": "Uruguay"
    },
    {
      "value": 349,
      "label": "Uzbekistan",
      "label_ar": "أوزبكستان",
      "label_fr": "Ouzbékistan"
    },
    {
      "value": 350,
      "label": "Vanuatu",
      "label_ar": "فانواتو",
      "label_fr": "Vanuatu"
    },
    {
      "value": 352,
      "label": "Venezuela",
      "label_ar": "فنزويلا",
      "label_fr": "Venezuela"
    },
    {
      "value": 353,
      "label": "Vietnam",
      "label_ar": "فيتنام",
      "label_fr": "Vietnam"
    },
    {
      "value": 354,
      "label": "Wallis and Futuna",
      "label_ar": "واليس وفوتونا",
      "label_fr": "Wallis et Futuna"
    },
    { "value": 355, "label": "Yemen", "label_ar": "اليمن", "label_fr": "Yémen" },
    {
      "value": 356,
      "label": "Zambia",
      "label_ar": "زامبيا",
      "label_fr": "Zambie"
    },
    {
      "value": 357,
      "label": "Zimbabwe",
      "label_ar": "زمبابوي",
      "label_fr": "Zimbabwe"
    },
    {
      "value": 358,
      "label": "Åland",
      "label_ar": "أرض",
      "label_fr": "Un terrain"
    }
  ]


