<div class="modal-body" [ngClass]="darkThemeService.getCurrentTheme">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12 mb-2">
        <ul class="create-survey-steps nav justify-content-around reverse" role="tablist">
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeStep >= 1" id="step-1-tab" data-bs-toggle="pill"
              data-bs-target="#step1" role="tab" aria-controls="step1" aria-selected="true">
              <span class="step-circle">1</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeStep >= 2" id="step-2-tab" data-bs-toggle="pill"
              data-bs-target="#step2" role="tab" aria-controls="step2" aria-selected="true">
              <span class="step-circle">2</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeStep >= 3" id="step-3-tab" data-bs-toggle="pill"
              data-bs-target="#step3" role="tab" aria-controls="step3" aria-selected="true">
              <span class="step-circle">3</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeStep >= 4" id="step-4-tab" data-bs-toggle="pill"
              data-bs-target="#step4" role="tab" aria-controls="step4" aria-selected="true">
              <span class="step-circle">4</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [class.active]="activeStep == 5" id="step-5-tab" data-bs-toggle="pill"
              data-bs-target="#step5" role="tab" aria-controls="step5" aria-selected="true">
              <span class="step-circle">5</span>
            </a>
          </li>
        </ul>
      </div>
    </div>




    <div class="tab-content">
      <div id="step1" class="tab-pane fade" [ngClass]="{ 'show active': activeStep == 1 }" role="tabpanel"
        aria-labelledby="step-1-tab">

        <div class="step-card">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <div>
                <h5 class="modal-title text-center step-1-title">
                  {{ "home.createSurvey" | translate }}
                </h5>
                <span>{{ "survey.createSurveyStep1Text" | translate }}</span>
              </div>
            </div>
            <div class="col-md-12 col-12 align-self-center padding-seen">
              <div class="row">
                <div class="col-6">
                  <div class="s-input cursor-pointer">
                    <input (change)="changeSurveyLang($event, elanguage.Short_Arabic)" type="radio"
                      [checked]="locale === elanguage.Short_Arabic" name="lang" id="ar" />
                    <label for="ar" class="label-arabic" [class.lang-selected]="locale === elanguage.Short_Arabic">
                      {{ "survey.Arabic" | translate }}
                    </label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="s-input cursor-pointer">
                    <input (change)="changeSurveyLang($event, elanguage.Short_English)" id="en" type="radio"
                      [checked]="locale === elanguage.Short_English" name="lang" />
                    <label for="en" class="label-english" [class.lang-selected]="locale === elanguage.Short_English">
                      {{ "survey.English" | translate }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-3 col-6">

            <button class="btn m-btn-green1 w-100 m-border-radius-30" [disabled]="form.get('language').invalid"
              (click)="tabClick(2)">
              {{ 'Enterprise.Next' | translate }}

            </button>
          </div>
        </div>
      </div>

      <div id="step2" class="tab-pane fade" [ngClass]="{ 'show active': activeStep == 2 }" role="tabpanel"
        aria-labelledby="step-2-tab">
        <div class="step-card">
          <div class="row space-for-equal">
            <div class="col-md-12 col-12 align-self-center">
              <h5 class="modal-title step-1-title pb-2 text-center">
                {{ "survey.pleaseEnterYourSurveyTitle" | translate }}
              </h5>
              <div class="s-input" [ngClass]="{
                  invalid: form.get('title').invalid && form.get('title').dirty
                }">
                <input type="text" formControlName="title" class="text-area"
                  placeholder="{{ 'survey.Survey Title' | translate }}" />
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-md-3 col-6">
            <button class="btn m-btn-previous w-100 m-border-radius-30 text-capitalize" (click)="tabClick(1)">{{
              'survey.previous' | translate }}</button>
          </div>

          <div class="col-md-3 col-6">

            <button class="btn m-btn-green1 w-100 m-border-radius-30" [disabled]="form.get('title').invalid"
              (click)="tabClick(3)">
              {{ 'Enterprise.Next' | translate }}

            </button>
          </div>
        </div>
      </div>

      <div id="step3" class="tab-pane fade" [ngClass]="{ 'show active': activeStep == 3 }" role="tabpanel"
        aria-labelledby="step-3-tab">
        <div class="step-card">
          <div class="row space-for-equal">
            <div class="col-md-12 col-12 align-self-center">
              <h5 class="modal-title step-1-title pb-2 text-center">
                {{ "survey.describeYourSurveyInFewWords" | translate }}
              </h5>
              <div class="s-input" [ngClass]="{
                  invalid:
                    form.get('description').invalid &&
                    form.get('description').dirty
                }">
                <textarea formControlName="description" placeholder="{{ 'survey.Survey Description' | translate }}">
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-md-3 col-6">
            <button class="btn m-btn-previous w-100 m-border-radius-30 text-capitalize" (click)="tabClick(2)">{{
              'survey.previous' | translate }}</button>
          </div>
          <div class="col-md-3 col-6">
            <button class="btn m-btn-green1 w-100 m-border-radius-30" [disabled]="form.get('description').invalid"
              (click)="tabClick(4)">
              {{ 'Enterprise.Next' | translate }}

            </button>
          </div>
        </div>
      </div>

      <div id="step4" class="tab-pane fade" [ngClass]="{ 'show active': activeStep == 4 }" role="tabpanel"
        aria-labelledby="step-4-tab">
        <div class="step-card">
          <div class="row space-for-equal">

            <div class="col-md-12 col-12 align-self-center">
              <h5 class="modal-title step-1-title pb-2">
                {{ "survey.pleaseSelectSurveyCategory" | translate }}
              </h5>
              <div class="-input color" [ngClass]="{
                  invalid:
                    form.get('categoryId').invalid &&
                    form.get('categoryId').dirty
                }">
                <ng-select formControlName="categoryId" class="m-select text-capitalize"
                  placeholder="{{ 'survey.Survey Category' | translate }}">
                  <ng-option style="color: black!important;text-transform: capitalize!important;"
                    *ngFor="let category of categories" [value]="category.id">
                    {{ category.name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-md-3 col-6">

            <button class="btn m-btn-previous w-100 m-border-radius-30 text-capitalize" (click)="tabClick(3)">{{
              'survey.previous' | translate }}</button>
          </div>
          <div class="col-md-3 col-6">
            <button class="btn m-btn-green1 w-100 m-border-radius-30" [disabled]="form.get('categoryId').invalid"
              (click)="tabClick(5)">
              {{ 'Enterprise.Next' | translate }}

            </button>
          </div>
        </div>
      </div>

      <div id="step5" class="tab-pane fade" [ngClass]="{ 'show active': activeStep == 5 }" role="tabpanel"
        aria-labelledby="step-5-tab">
        <div class="step-card">
          <div class="row space-for-equal">
            <div class="col-md-12 col-12 align-self-center">
              <h5 class="modal-title step-1-title mb-4">
                {{ "survey.doYouWantToUploadSurveyImage" | translate }}
              </h5>
              <ng-container *ngIf="!isImageUploaded">
                <label class="btn m-btn-upload" for="survey-icon">{{
                  "survey.uploadVisualLogo" | translate
                  }}<svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="upload-btn-svg">
                    <g id="Group 1000002054">
                      <path id="Vector"
                        d="M4.16882 9.14616C4.36026 9.09628 4.48829 8.91606 4.49697 8.71843C4.55148 7.47682 4.81094 6.30126 5.3326 5.18539C6.98287 1.65551 10.6543 -0.381422 14.5765 0.0595766C18.2991 0.478462 21.3949 3.30325 22.2441 7.07448C22.3009 7.3272 22.3982 7.44598 22.6446 7.52875C25.4163 8.46318 27.1563 11.0245 26.9889 13.8998C26.8284 16.7044 24.777 19.061 21.9635 19.6575C21.5505 19.7411 21.1302 19.7834 20.7088 19.7838C20.5922 19.7884 20.4759 19.7692 20.367 19.7275C20.258 19.6858 20.1587 19.6224 20.0749 19.5412C19.9912 19.46 19.9248 19.3626 19.8797 19.255C19.8347 19.1474 19.8119 19.0318 19.8129 18.9151C19.8028 18.4097 20.1288 18.0306 20.6652 18.0097C22.2731 17.9377 23.5746 17.3053 24.4705 15.945C26.1638 13.3723 24.7732 9.87526 21.7784 9.14932C20.7555 8.90102 20.7429 8.88775 20.5698 7.83264C20.2925 6.12421 19.4076 4.57338 18.0778 3.46552C16.748 2.35765 15.0629 1.76728 13.3325 1.80303C11.6021 1.83878 9.94278 2.49826 8.65991 3.6601C7.37704 4.82195 6.55692 6.408 6.35044 8.12643C6.28726 8.64072 6.31569 9.16575 6.30684 9.68572C6.29421 10.4363 6.02885 10.7263 5.28269 10.7933C3.338 10.9676 2.02133 12.2174 1.81662 14.0837C1.61824 15.8944 2.98925 17.6717 4.79178 17.9377C5.00027 17.968 5.2113 17.9832 5.42358 18.0047C5.96757 18.0609 6.33148 18.452 6.29863 18.9448C6.26578 19.4376 5.87216 19.8053 5.32439 19.7895C3.67475 19.7415 2.29174 19.1021 1.24358 17.824C-1.30701 14.7193 0.261123 10.0857 4.16882 9.14616Z"
                        fill="white" />
                      <path id="Vector_2"
                        d="M14.4554 11.9113C14.42 11.9321 14.3983 11.97 14.3983 12.011V12.3959C14.3983 14.9404 14.3983 17.4851 14.3983 20.03C14.3983 20.1564 14.404 20.2827 14.3939 20.4091C14.3522 20.9568 13.9775 21.3328 13.4841 21.3283C12.9907 21.3239 12.6267 20.9493 12.5876 20.3945C12.5787 20.2682 12.5838 20.1418 12.5838 20.0155C12.5838 17.471 12.5838 14.9263 12.5838 12.3814C12.5838 12.2506 12.5838 12.1198 12.5838 11.989C12.5838 11.9561 12.5653 11.926 12.5359 11.9111C12.502 11.8939 12.4608 11.9007 12.4342 11.9279C11.9437 12.4291 11.4531 12.9301 10.9626 13.4308C10.8147 13.5812 10.6701 13.736 10.5102 13.8731C10.1311 14.1978 9.62569 14.189 9.28767 13.8642C8.93639 13.5294 8.91807 13.0119 9.2466 12.6101C9.30978 12.5343 9.37991 12.4654 9.44941 12.3959C10.5226 11.3219 11.5967 10.2478 12.6716 9.17373C13.2718 8.57541 13.7185 8.5792 14.3244 9.18384C15.4092 10.2661 16.4959 11.3465 17.5706 12.4389C17.7402 12.6042 17.8699 12.806 17.9497 13.029C18.0678 13.4024 17.8625 13.8048 17.5239 13.9969C17.1852 14.189 16.7657 14.1485 16.4384 13.8358C15.8906 13.3101 15.3574 12.7699 14.8254 12.2285C14.7497 12.1396 14.6792 12.0463 14.6143 11.9492C14.5793 11.8969 14.5097 11.8794 14.4554 11.9113Z"
                        fill="white" />
                    </g>
                  </svg>
                </label>
                <input type="file" id="survey-icon" accept=".png" class="d-none" hidden
                  (change)="onFileSelect($event.target.files)" />
              </ng-container>

              <ng-container *ngIf="isImageUploaded">
                <label class="btn m-btn-upload" for="survey-icon">{{
                  "survey.changeFile" | translate
                  }}</label>
                <input type="file" id="survey-icon" accept="image/png" class="d-none" hidden
                  (change)="onFileSelect($event.target.files)" />
              </ng-container>

              <div class="best-resolution-text">
                For best resolution please upload resolution
                <span style="color: #19BAA3;"> 160 x 140 </span> maximum size
                <span style="color: #19BAA3;"> 2MB </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row " [ngClass]="language=='Arabic'?'arabic':'eng'">
          <div class="col-md-3 col-6">

            <button class="btn m-btn-previous w-100 m-border-radius-30 text-capitalize" (click)="tabClick(4)">{{
              'survey.previous' | translate }}</button>
          </div>
          <div class="col-md-3 col-6">
            <button class="btn add-first-ques w-sm-100" [disabled]="form.invalid" (click)="handleCreateSurvey()">
              {{ "survey.Add1stQuestion" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>