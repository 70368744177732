import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FooterComponent } from "./footer/footer.component";
import { NgOtpInputModule } from "ng-otp-input";
import { HeaderComponent } from "./header/header.component";
import { GeneralModule } from "../general/general.module";
import { RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AuthService } from "../../services/auth.service";
import { SignupComponent } from "./signup/signup.component";
import { RecaptchaModule } from "ng-recaptcha";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";
import { VerifyComponent } from "./verify/verify.component";
import { ChangeForgetPasswordComponent } from "./change-forget-password/change-forget-password.component";
import { WelcomeComponent } from "./welcome/welcome.component";
import { CounterComponent } from "./counter/counter.component";

import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    SignupComponent,
    ForgetPasswordComponent,
    VerifyComponent,
    ChangeForgetPasswordComponent,
    WelcomeComponent,
    CounterComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    GeneralModule,
    RouterModule,
    RecaptchaModule,
    NgOtpInputModule,
    MatSelectModule,
    MatFormFieldModule,
  ],
  providers: [AuthService],
  entryComponents: [
    LoginComponent,
    SignupComponent,
    ForgetPasswordComponent,
    VerifyComponent,
    ChangeForgetPasswordComponent,
    WelcomeComponent,
  ],
  exports: [FooterComponent, HeaderComponent, GeneralModule, CounterComponent],
  bootstrap: [],
})
export class PartialsModule {}
