<app-header></app-header>

<div class="wrapper create-survey-wrap survey-options">
  <!-- Start Main -->
  <main class="navy-part" [formGroup]="form">
    <section class="create-info">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7">
            <div class="text d-flex align-items-center">
              <img src="assets/imgs/create-survey-icon-1.png" class="mt-3" alt="" />
              <h1>
                <span>{{ "survey.configure" | translate }}</span>
                <span class="text-survey-options">{{
                  "survey.surveyOptions" | translate
                  }}</span>
              </h1>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="survey-info">
              <h3>{{ "approveSurvey.surveyCostDetails" | translate }}</h3>
              <div class="d-flex align-items-center justify-content-between"
                style="background: white; border-radius: 30px">
                <div class="key text-capitalize">
                  <p>{{ "survey.SpendingNow" | translate }}</p>
                  <p>{{ "approveSurvey.costPerSurvey" | translate }}</p>
                  <p>{{ "approveSurvey.expectedResponse" | translate }}</p>
                  <p>{{ "approveSurvey.costPerQuestion" | translate }}</p>
                </div>
                <div class="points">
                  <p>{{ form?.value?.sliderValue }}</p>
                  <p>{{ Cost.costPerSurvey }}</p>
                  <p>{{ userValue?.value }}</p>
                  <p>{{ Cost.perQuestion }}</p>
                </div>
                <div class="text-uppercase key">
                  <p>{{ "survey.SAR" | translate }}</p>
                  <p>{{ "approveSurvey.RSP" | translate }}</p>

                </div>

                <!-- <div class="photo">
                  <img
                    [src]="
                      surveyImage
                        ? surveyImage
                        : 'https://karateinthewoodlands.com/wp-content/uploads/2017/09/default-user-image.png'
                    "
                    alt=""
                  />
                </div> -->
              </div>
              <h4 class="note">
                {{ "survey.NoteFirst50Responses" | translate }}
                <span class="free text-capitalize">{{
                  "survey.free" | translate
                  }}</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- settings Section -->
    <section class="settings">
      <div class="container-fluid">
        <h3 class="set-title title">
          {{ "survey.BasicOptions" | translate }}
        </h3>

        <div class="row">
          <div class="col-md-6 col-12">
            <div class="set-item">
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="item-heading">
                    {{ "survey.publication cost" | translate }}
                    <span class="tooltip">
                      <i class="text-green-gradient fas fa-info-circle"></i>
                      <span class="tooltiptext">{{
                        "survey.TooltipText" | translate
                        }}</span>
                    </span>
                  </h4>
                </div>
              </div>

              <div class="row mb-5">
                <div class="col-12">
                  <p class="p">
                    {{ "survey.PointsModifySliderInstruction" | translate }}
                  </p>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-5">
                  <div class="point-cal-area">
                    <h5 class="mb-2">
                      {{ "home.spendingPoints" | translate }}
                    </h5>
                    <div class="-input">
                      <span class="unit">{{ "survey.SAR" | translate }}</span>
                      <input class="points-input" type="number" [min]="survey.totalPoints" id="pts"
                        formControlName="maxSending" (change)="setRam($event.target.value)" />
                    </div>
                  </div>
                </div>

                <div class="col-2 align-self-end text-center">
                  <i class="fas fa-arrow-right font-50"></i>
                </div>

                <div class="col-5">
                  <div class="point-cal-area">
                    <h5 class="mb-2">
                      {{ "survey.TotalResponses" | translate }}
                    </h5>
                    <div class="-input">
                      <input class="points-input" type="number" #userValue formControlName="usersValue" readonly />
                      <span class="unit">{{
                        "approveSurvey.RSP" | translate
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-8">
                  <div class="-input">
                    <input type="range" value="0" min="0" formControlName="maxSending" max="{{ totalBalance }}"
                      id="slider" #slider (input)="setRam($event.target.value)" />
                  </div>
                </div>
                <div class="col-2">
                  <label id="slider" class="input">{{
                    form.value.sliderValue
                    }}</label>
                </div>
                <div class="col-2">
                  <button class="btn-calculate">
                    {{ "survey.Calculate" | translate }}
                  </button>
                </div>
                <div class="col-10">
                </div>
                <div class="col-2">

                  <button class="btn-calculate cursor-pointer" *ngIf="totalSar==0" (click)="recharge()">
                    {{ "survey.recharge" | translate }}
                  </button>
                </div>
              </div>
              <hr *ngIf="totalSar!=0" />
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="set-item">
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="item-heading">
                    {{ "survey.publication time" | translate }}
                    <span class="tooltip">
                      <i class="text-green-gradient fas fa-info-circle"></i>
                      <span class="tooltiptext">{{
                        "survey.TooltipText" | translate
                        }}</span>
                    </span>
                  </h4>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <p class="p">
                    {{ "survey.surveyStartDateNote" | translate }}
                  </p>
                </div>
              </div>

              <div class="row justify-content-between">
                <div class="col-md-5 col-12 mb-md-0 mb-3">
                  <div class="point-cal-area">
                    <h5 class="mb-2">{{ "survey.start date" | translate }}</h5>
                    <div class="-input">
                      <input type="date" formControlName="startDate" class="inp-survey-option-date-time" />
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-12 mb-md-0 mb-3">
                  <div class="point-cal-area">
                    <h5 class="mb-2">{{ "survey.end date" | translate }}</h5>
                    <div class="-input">
                      <input type="date" formControlName="endDate" class="inp-survey-option-date-time" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-2 text-center">
                  <i class="fas fa-arrow-right font-30"></i>
                </div>
              </div>

              <div class="row justify-content-between">
                <div class="col-md-5 col-12 mb-md-0 mb-3">
                  <div class="point-cal-area">
                    <h5 class="mb-2">{{ "survey.startTime" | translate }}</h5>
                    <div class="-input">
                      <input type="time" formControlName="startTime" class="inp-survey-option-date-time" />
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-12 mb-md-0 mb-3">
                  <div class="point-cal-area">
                    <h5 class="mb-2">{{ "survey.endTime" | translate }}</h5>
                    <div class="-input">
                      <input type="time" formControlName="endTime" class="inp-survey-option-date-time" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="d-flex justify-content-between align-items-center">
                <div class="text">


                </div>
                <div class="config d-flex align-items-center justify-content-end text-center">
                  <div>
                    <h5>{{ "survey.start date" | translate }}</h5>
                    <div class="-input">
                      <input type="date" formControlName="startDate" />
                    </div>
                  </div>
                  <span class="icon"><i class="fas fa-arrow-right"></i></span>
                  <div>
                    <h5>{{ "survey.end date" | translate }}</h5>
                    <div class="-input">
                      <input type="date" formControlName="endDate" />
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <h3 class="set-title title">
          {{ "survey.TargetAudiences" | translate }}
        </h3>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="set-item">
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="item-heading">
                    {{ "survey.publicOrPrivate" | translate }}
                    <span class="tooltip">
                      <i class="text-green-gradient fas fa-info-circle"></i>
                      <span class="tooltiptext">{{
                        "survey.TooltipText" | translate
                        }}</span>
                    </span>
                  </h4>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <p class="p">
                    {{ "survey.surveyPromotionNote" | translate }}
                  </p>
                </div>
              </div>

              <div class="row justify-content-center mb-3">
                <div class="col-4 config" *ngFor="let visibility of visibility">
                  <input type="radio" formControlName="visibility" id="{{ visibility.title }}"
                    value="{{ visibility.title }}" hidden />
                  <label for="{{ visibility.title }}">
                    {{ visibility.title }}
                    <span><i class="fas fa-check"></i></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="set-item">
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="item-heading">
                    {{ "survey.gender target" | translate }}
                    <span class="tooltip">
                      <i class="text-green-gradient fas fa-info-circle"></i>
                      <span class="tooltiptext">{{
                        "survey.TooltipText" | translate
                        }}</span>
                    </span>
                  </h4>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <p class="p">
                    {{ "survey.surveyGenderNote" | translate }}
                  </p>
                </div>
              </div>

              <div class="row justify-content-center mb-md-0 mb-3">

                <div class="col-md-6 col-12 mb-md-0 mb-3">
                  <!-- <div class="-input" > -->
                  <ng-select formControlName="age" id="age" class="m-select"
                    [ngClass]="{ invalid: form.get('age').invalid && form.get('age').dirty}"
                    placeholder="{{ 'survey.Age' | translate }}">
                    <ng-option *ngFor="let age of ages" [value]="age">{{ age }}</ng-option>
                  </ng-select>
                  <!-- </div> -->
                </div>

                <div class="col-md-3 col-12 mb-md-0 mb-3 config align-self-center" *ngFor="let gender of genders">
                  <input type="checkbox" id="{{ gender.title }}" formControlName="gender" value="{{ gender.title }}"
                    [(ngModel)]="gender.isChecked" (change)="changeSelection()" hidden />
                  <label for="{{ gender.title }}">
                    {{ gender.title }}
                    <span><i class="fas fa-check"></i></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-12">
            <div class="set-item">
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="item-heading">
                    {{ "survey.publication place" | translate }}
                    <span class="tooltip">
                      <i class="text-green-gradient fas fa-info-circle"></i>
                      <span class="tooltiptext">{{
                        "survey.TooltipText" | translate
                        }}</span>
                    </span>
                  </h4>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <p class="p">
                    {{ "survey.surveyLocationNote" | translate }}
                  </p>
                </div>
              </div>


              <div *ngIf="language === 'Arabic'; else elseBlockEnglish">
                <div class="row">
                  <div class="col-6">
                    <div class="profile-wrap">
                      <div [ngClass]="{
                          invalid:
                            form.get('countryId').invalid &&
                            form.get('countryId').dirty
                        }">
                        <ng-select formControlName="countryId" class="m-select"
                          placeholder="{{ 'profile.Country' | translate }}" (change)="getCitiesArabic()">
                          <ng-option *ngFor="let country of countriesList" [value]="country.name">
                            {{ country.label }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="profile-wrap">
                      <div [ngClass]="{
                          invalid:
                            form.get('cityId').invalid && form.get('cityId').dirty
                        }">
                        <ng-select formControlName="cityId" class="m-select"
                          placeholder="{{ 'survey.city' | translate }}">
                          <ng-option *ngFor="let city of citiesList" [value]="city.name">
                            {{ city.label }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #elseBlockEnglish>
                <div class="row">
                  <div class="col-6">
                    <div class="profile-wrap">
                      <div [ngClass]="{
                          invalid:
                            form.get('countryId').invalid &&
                            form.get('countryId').dirty
                        }">
                        <ng-select formControlName="countryId" class="m-select"
                          placeholder="{{ 'profile.Country' | translate }}" (change)="getCities()">
                          <ng-option *ngFor="let country of countries" [value]="country.name">
                            {{ country.name }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="profile-wrap">
                      <div [ngClass]="{
                          invalid:
                            form.get('cityId').invalid && form.get('cityId').dirty
                        }">
                        <ng-select formControlName="cityId" class="m-select"
                          placeholder="{{ 'survey.city' | translate }}">
                          <ng-option *ngFor="let city of cities" [value]="city.name">
                            {{ city.name }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="set-item">
              <div class="row mb-3">
                <div class="col-12">
                  <h4 class="item-heading">
                    {{ "survey.contacts list" | translate }}
                    <b class="coming">
                      {{ "survey.contacts list description" | translate }}
                    </b>
                    <span class="tooltip">
                      <i class="text-green-gradient fas fa-info-circle"></i>
                      <span class="tooltiptext">{{
                        "survey.TooltipText" | translate
                        }}</span>
                    </span>
                  </h4>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col-12">
                  <p class="p">
                    {{ "survey.uploadCSVNote" | translate }}
                  </p>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <button class="m-btn m-btn-disabled" disabled>
                    <i class="fas fa-arrow-up" style="padding-right: 10px"></i>
                    {{ "survey.upload" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="container-fluid">
          <div
            class="d-flex justify-content-center align-items-center total p-0"
          >
            <p class="total p-0">
              {{ "survey.Total" | translate }}

              <span> {{ totalPoints }}</span>
            </p>
          </div>
        </div> -->
        <div class="promotion">
          <h3 class="set-title title">{{ "survey.promotion" | translate }}</h3>
          <div class="prom-container">
            <div class="d-flex">
              <div class="icon">
                <img src="assets/imgs/surface1.svg" alt="" />
              </div>
              <ul>
                <li>
                  <div>
                    <h4>
                      {{ "survey.promote survey" | translate }}
                      <span class="pt pts">+5pts</span>
                    </h4>
                    <p>{{ "survey.promote survey description" | translate }}</p>
                  </div>
                  <div class="switch-toggle">
                    <label class="switch">
                      <input type="checkbox" formControlName="promoteSurvey" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </li>
                <hr />
                <li>
                  <div>
                    <h4>
                      {{ "survey.kafu network" | translate }}
                      <span class="free pts">{{
                        "survey.free" | translate
                        }}</span>
                    </h4>
                    <p>{{ "survey.kafu network description" | translate }}</p>
                  </div>
                  <div class="switch-toggle">
                    <label class="switch">
                      <input type="checkbox" formControlName="kafuNetwork" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="agree-checkboxes">
          <div>
            <input type="checkbox" id="agree1" formControlName="agreeCondition" name="agreeCondition" hidden="" />
            <label for="agree1">
              <span class="marginIcon"><i class="fas fa-check"></i></span>
              <a [routerLink]="['/terms-conditions']">
                {{ "survey.conditions" | translate }}</a>
            </label>
          </div>
          <div>
            <input type="checkbox" formControlName="agreePolicy" id="agree2" name="agreePolicy" hidden="" />
            <label for="agree2">
              <span class="marginIcon"><i class="fas fa-check"></i></span>
              <a [routerLink]="['/privacy-policy']">
                {{ "survey.policy" | translate }}
              </a>
            </label>
          </div>
        </div>
        <div class="btns-section d-flex justify-content-between">
          <div class="d-flex">
            <a (click)="preview()" class="prev-btn">
              <i class="fas fa-eye"></i>
              {{ "survey.preview" | translate }}
            </a>
            <div *ngIf="showSaveButton">
              <a (click)="save()" class="save-btn">
                <i class="fas fa-save"></i>
                {{ "survey.save" | translate }}
              </a>
            </div>
          </div>
          <div>
            <a *ngIf="!survey.id" [disabled]="isPublished" (click)="publish()" class="m-btn m-btn-gradient">
              {{ "survey.publish" | translate }}</a>
            <a *ngIf="survey.id" (click)="update()" class="m-btn m-btn-gradient">
              {{ "survey.update" | translate }}</a>
          </div>
        </div>
      </div>
    </section>
    <!-- /settings Section -->
  </main>
  <!-- End Main -->
</div>

<app-footer></app-footer>

<app-loader [show]="showLoader"></app-loader>