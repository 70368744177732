import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {JwPaginationComponent} from './jw-pagination/jw-pagination.component';
import {CommonModule} from '@angular/common';
import {LoaderComponent} from './loader/loader.component';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../services/language.service';

@NgModule({
    imports: [
        CommonModule,
        NgxSpinnerModule,
        TranslateModule.forRoot(),
    ],
    declarations: [
        JwPaginationComponent,
        LoaderComponent,
    ],
    exports: [
        JwPaginationComponent ,
        LoaderComponent ,
        TranslateModule,
    ],
    providers: [
        NgxSpinnerService,
        TranslateService,
        LanguageService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [
    ],
})
export class GeneralModule {
}

