<div class="start-survey-chat-wrapper" [dir]="dir" [ngClass]="darkThemeService.getCurrentTheme">
  <div class="w-100 header-resp marg-tb-50">
    <div class="row justify-content-between w-100 align-items-center">
      <div class="col-11">
        <div style="display: flex" *ngIf="survey.brandManagementId">
          <div>
            <img [src]="getSafeUrl(survey?.brandManagementId?.surveyImageUrl)" alt="logo" width="55px" />
          </div>
          <div class="ml-2">
            <h6 class="heaading-top">
              <!-- {{ "newSurveySecond.HeadingTop" | translate }} -->
              {{survey?.brandManagementId?.brandName}}
            </h6>
            <!-- <p>{{ "newSurveySecond.HeadingSub" | translate }}</p> -->
          </div>
        </div>
        <div style="display: flex" *ngIf="!survey.brandManagementId">
          <div>
            <img src="\assets\imgs\kafu-logo-red.png" alt="logo" width="55px" />
          </div>
          <div class="ml-2">
            <h6 class="heaading-top">
              {{ "newSurveySecond.HeadingTop" | translate }}
            </h6>
            <p>{{ "newSurveySecond.HeadingSub" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-1 text-right bell-svg">
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 27 29" fill="none"
          *ngIf="darkThemeService.getCurrentTheme === 'light-mode'">
          <path
            d="M15.075 28.7529C12.8309 28.7529 11.0426 26.9647 11.0426 24.7205H19.1074C19.1074 26.9647 17.3191 28.7529 15.075 28.7529Z"
            fill="#1D1D49" />
          <path
            d="M18.5845 19.2855C18.9702 19.2855 19.2858 18.9699 19.2858 18.5842C19.2858 18.1985 18.9702 17.8829 18.5845 17.8829H4.90933V16.0946C5.08465 16.0946 5.25997 16.1297 5.4353 16.1297C9.22227 16.1297 12.2729 13.0791 12.2729 9.29211C12.2729 7.11811 11.2209 5.15449 9.60798 3.89217C10.169 3.54152 10.8002 3.261 11.4313 3.01555C11.782 1.29739 13.2898 0 15.078 0C16.9715 0 18.5494 1.47271 18.7248 3.33113C22.1611 4.87397 24.5455 8.3103 24.5455 12.2726V19.2855L27 21.0387V23.4933H3.1561V21.0387L4.90933 19.2855H18.5845ZM15.078 1.40258C14.2014 1.40258 13.4651 1.89349 13.0794 2.59478C13.6053 2.52465 14.1664 2.45452 14.7274 2.45452C15.569 2.45452 16.3754 2.55971 17.1469 2.7701C16.7962 1.96362 16.0248 1.40258 15.078 1.40258ZM21.3897 13.2193C21.7754 13.2193 22.091 12.9038 22.091 12.518C22.091 9.92327 20.6884 7.50382 18.4092 6.20643C17.3222 5.57527 16.0949 5.25968 14.8326 5.25968C14.4469 5.25968 14.1313 5.57526 14.1313 5.96098C14.1313 6.34669 14.4469 6.66227 14.8326 6.66227C15.8495 6.66227 16.8313 6.90772 17.7079 7.43369C19.5312 8.48562 20.6884 10.4142 20.6884 12.518C20.6884 12.9038 21.004 13.2193 21.3897 13.2193Z"
            fill="#1D1D49" />
          <path
            d="M5.43218 14.7272C2.43051 14.7272 -0.00282669 12.2939 -0.00282669 9.29218C-0.00282669 6.29051 2.43051 3.85718 5.43218 3.85718C8.43385 3.85718 10.8672 6.29051 10.8672 9.29218C10.8672 12.2939 8.43385 14.7272 5.43218 14.7272Z"
            fill="#FC324A" />
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 27 29" fill="none"
          *ngIf="darkThemeService.getCurrentTheme === 'dark-mode'">
          <g id="Group">
            <path id="Vector"
              d="M11.925 28.7529C14.1691 28.7529 15.9574 26.9646 15.9574 24.7205H7.89258C7.89258 26.9646 9.68087 28.7529 11.925 28.7529Z"
              fill="white" />
            <path id="Vector_2"
              d="M8.41549 19.2855C8.02978 19.2855 7.7142 18.9699 7.7142 18.5842C7.7142 18.1985 8.02978 17.8829 8.41549 17.8829H22.0907V16.0946C21.9153 16.0946 21.74 16.1297 21.5647 16.1297C17.7777 16.1297 14.7271 13.0791 14.7271 9.29211C14.7271 7.11811 15.7791 5.15449 17.392 3.89217C16.831 3.54152 16.1998 3.261 15.5687 3.01555C15.218 1.29739 13.7102 0 11.922 0C10.0285 0 8.45056 1.47271 8.27524 3.33113C4.83891 4.87397 2.45452 8.3103 2.45452 12.2726V19.2855L0 21.0387V23.4933H23.8439V21.0387L22.0907 19.2855H8.41549ZM11.922 1.40258C12.7986 1.40258 13.5349 1.89349 13.9206 2.59478C13.3947 2.52465 12.8336 2.45452 12.2726 2.45452C11.431 2.45452 10.6246 2.55971 9.85314 2.7701C10.2038 1.96362 10.9752 1.40258 11.922 1.40258ZM5.61033 13.2193C5.22462 13.2193 4.90904 12.9038 4.90904 12.518C4.90904 9.92327 6.31162 7.50382 8.59082 6.20643C9.67782 5.57527 10.9051 5.25968 12.1674 5.25968C12.5531 5.25968 12.8687 5.57526 12.8687 5.96098C12.8687 6.34669 12.5531 6.66227 12.1674 6.66227C11.1505 6.66227 10.1687 6.90772 9.29211 7.43369C7.46875 8.48562 6.31162 10.4142 6.31162 12.518C6.31162 12.9038 5.99604 13.2193 5.61033 13.2193Z"
              fill="white" />
            <path id="Vector_3"
              d="M21.5678 14.7272C24.5695 14.7272 27.0028 12.2939 27.0028 9.29218C27.0028 6.29051 24.5695 3.85718 21.5678 3.85718C18.5661 3.85718 16.1328 6.29051 16.1328 9.29218C16.1328 12.2939 18.5661 14.7272 21.5678 14.7272Z"
              fill="#FC324A" />
          </g>
        </svg>
      </div>
    </div>
  </div>
  <div class="start-survey-chat-container">
    <form [formGroup]="form">
      <ng-container *ngIf="gettingStarted">
        <!-- Survey Detail Area Start -->
        <div class="main-cont-1 text-center marg-top-50">
          <div>
            <h6>{{ "newSurveySecond.headOne" | translate }}</h6>
            <p>{{ "newSurveySecond.headTwo" | translate }}</p>
          </div>
        </div>
        <!-- Survey Detail Area End -->

        <div class="d-flex justify-content-end slide-animate justify-content-center">
          <button (click)="gettingStart()" class="m-btn start-surv-btn p-2 bg-green-grad">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"
              class="marg-right-5 marg-left-5">
              <path
                d="M29.687 7.70164C28.0321 4.98 25.5516 2.75222 22.6492 1.44007C19.545 0.0377939 16.0657 -0.355132 12.7271 0.32618C9.57959 0.967838 6.64478 2.61164 4.42745 4.93314C2.1993 7.26546 0.688634 10.2683 0.198298 13.4585C0.0685029 14.2985 0 15.1456 0 15.9963C0 19.2875 1.01673 22.5607 2.93481 25.2391C4.83125 27.8886 7.47402 29.9182 10.535 31.0429C13.6717 32.1928 17.187 32.3046 20.3958 31.3889C23.4712 30.5094 26.2763 28.6853 28.3097 26.2124C30.4189 23.653 31.6916 20.5168 31.9512 17.2076C32.2144 13.9055 31.4068 10.5314 29.687 7.70164ZM22.0002 17.0634H17.0897V22.0056C17.0897 22.6112 16.5921 23.0618 16.008 23.087C15.424 23.1123 14.9264 22.5715 14.9264 22.0056V17.0634H9.987C9.38129 17.0634 8.93061 16.5659 8.90537 15.9819C8.88014 15.3979 9.42095 14.9005 9.987 14.9005H14.9264V9.99071C14.9264 9.3851 15.424 8.93449 16.008 8.90926C16.5921 8.88403 17.0897 9.42475 17.0897 9.99071V14.9005H22.0002C22.6059 14.9005 23.0566 15.3979 23.0819 15.9819C23.1071 16.5659 22.5663 17.0634 22.0002 17.0634Z"
                fill="white" />
            </svg>
            {{ "newSurveySecond.StartTheSurvey" | translate }}
          </button>
        </div>
        <hr class="marg-top-30" />
        <div class="marg-top-30">
          <label class="checkbox-container" [ngClass]="{
              'right-20': survey?.language == elanguage.Arabic,
              'start-alignment': survey?.language == elanguage.English
            }">
            <input type="checkbox" class="checkbox-input" (change)="clickButton()" />
            <div class="custom-checkbox" [ngClass]="{
                'end-alignment': survey?.language == elanguage.Arabic,
                'start-alignment': survey?.language == elanguage.English
              }">
              <div class="checkmark"></div>
            </div>
          </label>
          <span [ngClass]="{
              'margin-right-35': survey?.language == elanguage.Arabic,
              'margin-left-35': survey?.language == elanguage.English
            }">
            {{ "newSurveySecond.headThree" | translate }}
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="!gettingStarted">
        <!-- User Name Area Start -->
        <ng-container *ngIf="!displayState">
          <!-- Survey Detail Area Start -->
          <div class="d-flex justify-content-center flex-column w-100">
            <div class="survey-info-card">
              <div class="p-3 question-title">
                <h5>{{ survey?.title }}</h5>
              </div>
              <div class="inner-card">
                <p>{{ survey?.description }}</p>
              </div>
            </div>
          </div>
          <!-- Survey Detail Area End -->

          <ng-container *ngIf="displayStates > 0">
            <div class="d-flex justify-content-start slide-animate marg-top-50">
              <div class="chat-card w-100 tell-ur-name pos-rel">
                <div class="trapezoid"></div>
                <p class="name-question primary-orange-text">
                  {{ "survey.whatsYourName" | translate }}
                </p>
                <h6>{{ "survey.PleaseWriteYourFirstName" | translate }}</h6>
              </div>
            </div>

            <div class="d-flex justify-content-end slide-animate">
              <div class="chat-card chat-answer d-flex justify-content-end w-70 pos-rel">
                <div class="trapezoid-2"></div>
                <input type="text" formControlName="name" (change)="onNameInput()" [ngClass]="{
                    invalid: form.get('name').invalid && form.get('name').dirty
                  }" class="answer-field w-100 text-right" />
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="displayStates > 1">
            <div class="d-flex justify-content-start slide-animate">
              <div class="chat-card d-flex justify-content-start w-100 tell-ur-name pos-rel">
                <div class="trapezoid"></div>
                <h6>{{ "survey.WhatsYourGender" | translate }}</h6>
              </div>
            </div>

            <div class="d-flex justify-content-center slide-animate gender-flex">
              <div class="chat-card gender-option bg-pink-grad row cursor-pointer mx-2" (click)="changeGender('male')"
                [class.selected]="locale === 'male'">
                <input type="radio" [checked]="locale === 'male'" name="gend" id="male" hidden />
                <label for="male" class="w-100 d-flex justify-content-around cursor-pointer"
                  style="align-items: center">
                  <div class="svg-bg-gender">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.2667" height="25" viewBox="0 0 19 50" fill="none">
                      <path
                        d="M9.5 0C6.00222 0 3.16667 2.79824 3.16667 6.25C3.16667 9.70176 6.00222 12.5 9.5 12.5C12.9978 12.5 15.8333 9.70176 15.8333 6.25C15.8333 2.79824 12.9978 0 9.5 0ZM4.75 14.0625H5.87417C8.12161 15.0823 10.7815 15.1264 13.1258 14.0625H14.25C16.8734 14.0625 19 16.1611 19 18.75V32.0312C19 33.3257 17.9367 34.375 16.625 34.375H15.0417V47.6562C15.0417 48.9507 13.9784 50 12.6667 50H6.33333C5.02164 50 3.95833 48.9507 3.95833 47.6562V34.375H2.375C1.06331 34.375 0 33.3257 0 32.0312V18.75C0 16.1611 2.12661 14.0625 4.75 14.0625Z"
                        fill="url(#paint0_linear_644_973)" />
                      <defs>
                        <linearGradient id="paint0_linear_644_973" x1="0" y1="0" x2="19" y2="0"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#9288E0" />
                          <stop offset="1" stop-color="#ED816F" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  {{ "survey.Male" | translate }}
                </label>
              </div>

              <div class="chat-card gender-option bg-green-grad cursor-pointer mx-2" (click)="changeGender('female')"
                [class.selected]="locale === 'female'">
                <input type="radio" [checked]="locale === 'female'" name="gend" id="female" hidden />

                <label for="female" class="w-100 d-flex justify-content-around cursor-pointer"
                  style="align-items: center">
                  <div class="svg-bg-gender">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.85" height="25" viewBox="0 0 26 55" fill="none">
                      <path
                        d="M13 0C9.17122 0 6.0673 3.07807 6.0673 6.875C6.0673 10.6719 9.17122 13.75 13 13.75C16.8289 13.75 19.9328 10.6719 19.9328 6.875C19.9328 3.07807 16.8289 0 13 0ZM0.0788326 38.0466L5.27838 17.4216C5.419 16.8638 5.7436 16.3686 6.20058 16.0148C6.65757 15.661 7.22071 15.4688 7.80049 15.4688C8.6114 15.4688 9.40557 15.667 10.1807 15.9052C11.9894 16.4609 13.9606 16.4778 15.8194 15.9057C16.5945 15.6672 17.3887 15.4688 18.1996 15.4688C18.7794 15.4688 19.3425 15.661 19.7995 16.0148C20.2565 16.3686 20.5811 16.8638 20.7217 17.4216L25.9212 38.0466C26.3309 39.6714 25.0908 41.25 23.3991 41.25C20.0489 41.25 17.333 43.9659 17.333 47.3161V52.4219C17.333 53.8458 16.1691 55 14.7332 55H11.2669C9.83102 55 8.66708 53.8458 8.66708 52.4219V47.3161C8.66708 43.9659 5.95117 41.25 2.60094 41.25C0.91206 41.25 -0.331499 39.674 0.0788326 38.0466Z"
                        fill="url(#paint0_linear_644_974)" />
                      <defs>
                        <linearGradient id="paint0_linear_644_974" x1="0" y1="0" x2="26" y2="0"
                          gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FFAE3B" />
                          <stop offset="1" stop-color="#08DCBC" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  {{ "survey.Female" | translate }}
                </label>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="displayStates > 1">
            <div class="d-flex justify-content-end slide-animate justify-content-center">
              <button (click)="handleNext()" class="m-btn m-btn-pink p-2 bg-pink-grad">
                {{ "general.Next" | translate }}
                <svg class="ml-2" [ngClass]="{
                    'rotate-180': survey?.language == elanguage.Arabic,
                    'rotate-360': survey?.language == elanguage.English
                  }" xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.0243 8.92919L10.7457 14.2078C10.3601 14.5934 10.3596 15.2172 10.744 15.6017C11.1311 15.9888 11.7525 15.9853 12.1379 15.5999L19.0952 8.64258L19.0961 8.64172L19.097 8.64086C19.2902 8.44759 19.3862 8.19593 19.3858 7.9444C19.3849 7.69224 19.2889 7.44063 19.097 7.2487L19.0961 7.24784L19.0952 7.24698L12.1379 0.289624C11.7523 -0.0959762 11.1284 -0.0965313 10.744 0.287903C10.3569 0.675018 10.3603 1.2964 10.7457 1.68178L16.0243 6.96038H0.985813C0.443893 6.96038 0 7.40111 0 7.94478C0 8.49224 0.441363 8.92919 0.985813 8.92919H16.0243Z"
                    fill="white" />
                </svg>
              </button>
            </div>
          </ng-container>
        </ng-container>

        <!-- Country City Selection Start -->
        <ng-container *ngIf="displayStates > 2">
          <div class="d-flex justify-content-start slide-animate">
            <div class="chat-card d-flex justify-content-start w-100 tell-ur-name pos-rel">
              <div class="trapezoid"></div>
              <h6>{{ "survey.WhichCountryAreYouFrom" | translate }}</h6>
            </div>
          </div>
          <div *ngIf="lang === 'Arabic'; else elseBlockEnglish">
            <div class="d-flex justify-content-center slide-animate">
              <div class="mx-2 w-50" [ngClass]="{
                  invalid:
                    form.get('country').invalid && form.get('country').dirty
                }">
                <ng-select formControlName="country" class="m-select ddl-field"
                  placeholder="{{ 'profile.Country' | translate }}" (change)="getCitiesArabic()">
                  <ng-option *ngFor="let country of countriesList" [value]="country.name">
                    {{ country.label }}
                  </ng-option>
                </ng-select>
              </div>

              <div class="mx-2 w-50" [ngClass]="{
                  invalid:
                    form.get('cityId').invalid && form.get('cityId').dirty
                }">
                <ng-select formControlName="cityId" (change)="onCityChange()" class="m-select ddl-field w-100"
                  placeholder="{{ 'survey.city' | translate }}">
                  <ng-option *ngFor="let city of citiesList" [value]="city.name">
                    {{ city.label }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <ng-template #elseBlockEnglish>
            <div class="d-flex justify-content-center slide-animate">
              <div class="mx-2 w-50" [ngClass]="{
                  invalid:
                    form.get('country').invalid && form.get('country').dirty
                }">
                <ng-select formControlName="country" class="m-select ddl-field w-100"
                  placeholder="{{ 'profile.Country' | translate }}" (change)="getCities()">
                  <ng-option *ngFor="let country of countries" [value]="country.name">
                    {{ country.name }}
                  </ng-option>
                </ng-select>
              </div>

              <div class="w-50 mx-2" [ngClass]="{
                  invalid:
                    form.get('cityId').invalid && form.get('cityId').dirty
                }">
                <ng-select formControlName="cityId" (change)="onCityChange()" class="m-select ddl-field w-100"
                  placeholder="{{ 'survey.city' | translate }}">
                  <ng-option *ngFor="let city of cities" [value]="city.name">
                    {{ city.name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </ng-template>
        </ng-container>

        <!-- Country City Selection End -->

        <!-- Age Selection Start -->
        <ng-container *ngIf="displayStates > 3">

          <div class="d-flex justify-content-start slide-animate">
            <div class="chat-card w-100 tell-ur-name pos-rel">
              <div class="trapezoid"></div>
              <p class="name-question primary-orange-text">
                {{ "survey.whatsYourName" | translate }}
              </p>
              <h6>{{ "survey.ChooseYourAgeFromTheListBelow" | translate }}</h6>
            </div>
          </div>

          <div class="d-flex justify-content-center slide-animate">
            <div class="w-100 mx-2" [ngClass]="{
                invalid: form.get('age').invalid && form.get('age').dirty
              }">
              <ng-select formControlName="age" (change)="onAgeChange()" id="age" class="m-select ddl-field w-100"
                placeholder="{{ 'survey.AgeRange' | translate }}">
                <ng-option *ngFor="let age of ages" [value]="age">{{
                  age
                  }}</ng-option>
              </ng-select>
            </div>
          </div>
        </ng-container>
        <!-- Age Selection End -->

        <!-- Submit Button Start -->
        <ng-container *ngIf="displayStates > 3">

          <div class="d-flex slide-animate justify-content-center" [ngClass]="{
              'justify-content-end': survey?.language == elanguage.Arabic,
              'justify-content-start': survey?.language == elanguage.English
            }" dir="ltr">
            <button (click)="handleCreateSurvey()" class="m-btn m-btn-pink p-2 bg-green-grad" style="width: 190px">
              {{ "survey.startSurvey" | translate }}
              <svg class="ml-2" [ngClass]="{
                  'rotate-180': survey?.language == elanguage.Arabic,
                  'rotate-360': survey?.language == elanguage.English
                }" xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16.0243 8.92919L10.7457 14.2078C10.3601 14.5934 10.3596 15.2172 10.744 15.6017C11.1311 15.9888 11.7525 15.9853 12.1379 15.5999L19.0952 8.64258L19.0961 8.64172L19.097 8.64086C19.2902 8.44759 19.3862 8.19593 19.3858 7.9444C19.3849 7.69224 19.2889 7.44063 19.097 7.2487L19.0961 7.24784L19.0952 7.24698L12.1379 0.289624C11.7523 -0.0959762 11.1284 -0.0965313 10.744 0.287903C10.3569 0.675018 10.3603 1.2964 10.7457 1.68178L16.0243 6.96038H0.985813C0.443893 6.96038 0 7.40111 0 7.94478C0 8.49224 0.441363 8.92919 0.985813 8.92919H16.0243Z"
                  fill="white" />
              </svg>
            </button>
          </div>
        </ng-container>
      </ng-container>
      <!-- Submit Button End -->
      <!-- </ng-container> -->
    </form>
  </div>
</div>

<!-- <app-footer></app-footer> -->