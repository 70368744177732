import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-previewer',
  templateUrl: './image-previewer.component.html',
  styleUrls: ['./image-previewer.component.css']
})
export class ImagePreviewerComponent implements OnInit {

  imageUrl: string;
  onClose: any;

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.onClose();
  }

}
