<div class="answer-survey-chat-container">
  <div class="answer-survey-chat-wrapper">
    <!-- Initial Invitation Area Start -->
    <ng-container *ngIf="displayStates.invite">
      <div class="invitation-card-container">
        <div class="invitation-card">
          <p class="invite-text">
            <span class="kafu-user">{{ answerUserName }} </span>
            {{ "survey.answerSurveyInviteText1" | translate }}
          </p>
          <p class="invite-text">
            {{ "survey.answerSurveyInviteText2" | translate }}
          </p>
        </div>
        <p class="invite-tip">
          <span class="tips">{{ "survey.tips" | translate }}</span>
          <span class="tips-text"> {{ "survey.tipText" | translate }}</span>
          <span class="rewarded"> {{ "survey.rewarded" | translate }}</span>
          !
        </p>
      </div>

      <ng-container *ngIf="displayStates.surveyDetails">
        <!-- Survey Detail Area Start -->
        <div class="d-flex justify-content-center flex-column question-slide-animate">
          <div class="survey-info-card">
            <div class="survey-title-container">
              <h4>{{ survey?.title }}</h4>
            </div>
            <div class="inner-card">
              <p>{{ survey?.description }}</p>
            </div>
          </div>
        </div>
        <!-- Survey Detail Area End -->

        <!-- Start Survey & Leave Page Buttons Area Start -->
        <div class="d-flex justify-content-center question-slide-animate">
          <button class="btn-start-survey" (click)="onStartSurveyClick()">
            {{ "survey.startSurvey" | translate }}
          </button>
          <button class="btn-leave-page ml-3" (click)="onLeavePageClick()">
            {{ "survey.leavePage" | translate }}
          </button>
        </div>
        <!-- Start Survey & Leave Page Buttons Area End -->
      </ng-container>
    </ng-container>
    <!-- Initial Invitation Area End -->

    <!-- Survey Attempt Area Start -->
    <ng-container *ngIf="displayStates.question">
      <section *ngIf="surveyForm" [class]="questionAnimationClass" class="survey-question-wrapper">
        <form [formGroup]="surveyForm">
          <ng-container *ngFor="
              let control of singleQuestion.controls;
              let quesIndex = index
            ">
            <!-- Question Start -->
            <div class="survey-question-area">
              <h3 class="question-number">
                {{ "survey.Question" | translate }}
                {{ control.value.questionNo }}
                (<span class="question-type">{{
                  control.value.questionType
                  }}</span>)
                <span class="optional" *ngIf="control.value?.isOptional">(optional)</span>
              </h3>
              <div class="survey-question-card">
                <div *ngIf="control.value?.imageUrl" class="question-image-area">
                  <img [src]="control.value?.imageUrl" class="question-image" alt="" />
                </div>

                <p class="survey-question" id="ques-{{ quesIndex }}">
                  {{ control.value.question }}
                </p>
              </div>
            </div>
            <!-- Question End -->

            <!-- Answer Start -->
            <div [formGroup]="control" class="survey-answer-wrapper">
              <!-- Text Start -->
              <ng-container *ngIf="control.value?.questionType == eQuesTypes.TEXT">

                <textarea cols="30" rows="5" formControlName="textAnswer"
                  (input)="onTextAnswerChange(control, $event.target.value)" name="text-answer-{{ quesIndex }}"
                  id="text-answer-{{ quesIndex }}" placeholder="Enter your answer"
                  class="survey-text-answer-field"></textarea>
              </ng-container>
              <!-- Text End -->

              <!-- Multi Select Start -->
              <ng-container *ngIf="control.value?.questionType == eQuesTypes.MULTI_SELECT">
                <ng-container *ngFor="
                    let answer of control.value.answers;
                    let ansIndex = index
                  ">
                  <div class="multi-select-answer">
                    <div class="k-checkbox align-self-center">
                      <input type="checkbox" [value]="answer?.option"
                        id="option-checkbox-{{ quesIndex }}-{{ ansIndex }}" [checked]="
                          control.value?.selectedAnswerIndex?.includes(ansIndex)
                        " (change)="
                          setMultiSelectAns(control, answer.option, ansIndex)
                        " />
                      <label for="option-checkbox-{{ quesIndex }}-{{ ansIndex }}"></label>

                    </div>
                    <div
                      [ngClass]="answer.imageUrl == 'answer.imageUrl' ? 'option-icon-preview cursor-pointer' : 'my_class2'">
                      <img class="answer-multi-select-image" (click)="openPreviewModal(answer.imageUrl)" src="{{
                          answer.imageUrl
                            ? answer.imageUrl
                            : './assets/imgs/nopath.png'
                        }}" alt="" />
                    </div>
                    <div class="option-box"
                      [class.checked-background]="control.value?.selectedAnswerIndex?.includes(ansIndex)"
                      [ngClass]="{'textAlignment' : lang==='Arabic'}"
                      (click)="setMultiSelectAns(control, answer.option, ansIndex)">
                      {{ answer?.option }}
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <!-- Multi Select End -->

              <!-- Single Select Start -->
              <ng-container *ngIf="control.value?.questionType == eQuesTypes.SINGLE_SELECT">
                <ng-container *ngFor="
                    let answer of control.value.answers;
                    let sAnsIndex = index
                  ">
                  <div class="single-select-answer">
                    <div class="k-radio align-self-center">
                      <input type="radio" [value]="answer?.option" id="option-radio-{{ quesIndex }}-{{ sAnsIndex }}"
                        name="sq-radio-{{ quesIndex }}" [checked]="
                          control.value?.selectedAnswerIndex?.includes(sAnsIndex)
                        " />
                      <label for="option-radio-{{ quesIndex }}-{{ sAnsIndex }}"
                        (click)="setSingleQuestionAns(control, answer.option, sAnsIndex)">
                      </label>
                    </div>
                    <div
                      [ngClass]="answer.imageUrl == 'answer.imageUrl' ? 'option-icon-preview cursor-pointer' : 'my_class2'">
                      <img class="answer-multi-select-image" (click)="openPreviewModal(answer.imageUrl)" src="{{
                          answer.imageUrl
                            ? answer.imageUrl
                            : './assets/imgs/nopath.png'
                        }}" alt="" />
                    </div>
                    <div class="option-box" (click)="setSingleQuestionAns(control, answer.option, sAnsIndex)"
                      [ngClass]="{'textAlignment' : lang==='Arabic'}"
                      [class.checked-background]="control.value?.selectedAnswerIndex?.includes(sAnsIndex)">
                      {{ answer?.option }}
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <!-- Single Select End -->

              <!-- Evaluation Start -->
              <ng-container *ngIf="control.value?.questionType == eQuesTypes.EVALUATION">
                <div class="evaluation-answers-wrapper">
                  <div class="evaluation-options-area">
                    <div class="evaluation-option" (click)="
                        setEvaluationAns(
                          control,
                          control.value.answers[0]?.option,
                          0
                        )
                      ">
                      <div class="option-point" [class.eval-option-active]="
                          control?.value?.selectedAnswerIndex == 0
                        ">
                        1
                      </div>
                      <div class="option-text">
                        {{ control.value?.answers[0]?.option }}
                      </div>
                    </div>
                    <div class="evaluation-option" (click)="
                        setEvaluationAns(
                          control,
                          control.value.answers[0].option,
                          1
                        )
                      ">
                      <div class="option-point" [class.eval-option-active]="
                          control?.value?.selectedAnswerIndex == 1
                        ">
                        2
                      </div>
                    </div>
                    <div class="evaluation-option" (click)="
                        setEvaluationAns(
                          control,
                          control.value.answers[1].option,
                          2
                        )
                      ">
                      <div class="option-point" [class.eval-option-active]="
                          control?.value?.selectedAnswerIndex == 2
                        ">
                        3
                      </div>
                      <div class="option-text">
                        {{ control.value?.answers[1]?.option }}
                      </div>
                    </div>
                    <div class="evaluation-option" (click)="
                        setEvaluationAns(
                          control,
                          control.value.answers[1].option,
                          3
                        )
                      ">
                      <div class="option-point" [class.eval-option-active]="
                          control?.value?.selectedAnswerIndex == 3
                        ">
                        4
                      </div>
                    </div>
                    <div class="evaluation-option" (click)="
                        setEvaluationAns(
                          control,
                          control.value.answers[2].option,
                          4
                        )
                      ">
                      <div class="option-point" [class.eval-option-active]="
                          control?.value?.selectedAnswerIndex == 4
                        ">
                        5
                      </div>
                      <div class="option-text">
                        {{ control.value?.answers[2]?.option }}
                      </div>
                    </div>
                  </div>

                  <div class="evaluation-bar-container">
                    <div class="evaluation-bar">
                      <div class="evaluation-bar-option" [class.eval-option-active]="
                          control?.value?.selectedAnswerIndex == 0
                        "></div>
                      <div class="evaluation-bar-option" [class.eval-option-active]="
                          control?.value?.selectedAnswerIndex == 1
                        "></div>
                      <div class="evaluation-bar-option" [class.eval-option-active]="
                          control?.value?.selectedAnswerIndex == 2
                        "></div>
                      <div class="evaluation-bar-option" [class.eval-option-active]="
                          control?.value?.selectedAnswerIndex == 3
                        "></div>
                      <div class="evaluation-bar-option" [class.eval-option-active]="
                          control?.value?.selectedAnswerIndex == 4
                        "></div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- Evaluation End -->

              <!-- Yes or No Template Start-->
              <ng-container *ngIf="control.value?.questionType == eQuesTypes.YES_OR_NO">
                <div class="row my-4 justify-content-center">
                  <div class="col-md-4 col-6" *ngFor="
                      let answer of control.value.answers;
                      let ansIndex = index
                    ">
                    <input type="radio" hidden class="yesNoHiddenRadio" id="yesNo-option-{{ quesIndex }}-{{ ansIndex }}"
                      name="yesNo-{{ quesIndex }}" (change)="scroll('ques-' + (quesIndex + 1))" />
                    <label for="yesNo-option-{{ quesIndex }}-{{ ansIndex }}" class="yes-no-card" [class.active]=""
                      (click)="setAnswerYesNo(control, answer.option)">
                      {{ "survey." + answer.option | translate }}
                    </label>
                  </div>
                </div>
              </ng-container>
              <!-- Yes or No Template End-->

              <ng-container *ngIf="control.value?.isadditionalText =='true'||control.value?.isOrderText">
                <textarea class="form-control h-auto" formControlName="additionalText"
                  placeholder="{{ 'survey.AdditionalText' | translate }}" cols="30" rows="5"
                  class="survey-text-answer-field mt-3"></textarea>
              </ng-container>
            </div>
            <!-- Answer End -->

            <div class="d-flex justify-content-start" *ngIf="questionValidation">
              <p class="text-danger font-20">
                {{ "survey.mandatoryQuestionErrorMsg" | translate }}
              </p>
            </div>
          </ng-container>
        </form>

        <!-- [ngClass]="{'justify-content-between': currentQuestionNo > 1, 'justify-content-end': currentQuestionNo <= 1}" -->
        <div class="d-flex mt-4 justify-content-end">
          <div *ngIf="currentQuestionNo < allQuestions.value.length">
            <img [ngClass]="{'flipping ar' : lang==='Arabic','en' : lang==='English'}"
              src="./assets/imgs/survey-icons/orange-right-arrow.svg" (click)="questionSubmit()"
              class="question-nav-icon" alt="" />
          </div>
          <div *ngIf="currentQuestionNo > 1">
            <img [ngClass]="{'flipping ar' : lang==='Arabic','en' : lang==='English'} "
              src="./assets/imgs/survey-icons/blue-left-arrow.svg" (click)="previousQuestion()"
              class="question-nav-icon-previous" alt="">
          </div>
          <div *ngIf="currentQuestionNo == allQuestions.value.length">
            <button class="btn-start-survey" (click)="questionSubmit(true)">
              {{ "survey.finish" | translate }}
            </button>
          </div>
        </div>
      </section>
    </ng-container>
    <!-- Survey Attempt Area End -->
  </div>
</div>