<app-header *ngIf="!authService.isLoggedIn()"></app-header>

<app-not-loggedin-home *ngIf="!authService.isLoggedIn()"></app-not-loggedin-home>

<!-- <app-loggedin-home *ngIf="authService.isLoggedIn()">

</app-loggedin-home> -->
<!-- <app-dashboard *ngIf="authService.isLoggedIn()"></app-dashboard>' -->

<app-new-main-dashboard *ngIf="authService.isLoggedIn()"></app-new-main-dashboard>

<app-footer [showLoader]="showLoader" *ngIf="!authService.isLoggedIn()"></app-footer>