import { AuthService } from "src/services/auth.service";
import { Router } from "@angular/router";
import { HomeService } from "src/services/home.service";
import { FormArray, Validators } from "@angular/forms";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { eSurveyQuestionTypes } from "src/app/general/enums/survey-question-type.enum";
import { SurveyService } from "src/services/survey.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { SurveyTypes } from "src/app/general/enums/survey-types.enum";
import { eLanguage } from "src/app/general/enums/language.enum";
@Component({
  selector: "app-design-survey",
  templateUrl: "./design-survey.component.html",
  styleUrls: ["./design-survey.component.css"],
})
export class DesignSurveyComponent implements OnInit {
  eQuesTypes = eSurveyQuestionTypes;
  questionForm: FormGroup;
  surveyQuestionsForm: FormArray;
  surveyForm: FormGroup;
  isSubmitted: boolean = false;
  actionMode: string;
  editIndex: number;
  eSurveyType = SurveyTypes;
  formError: any = {};
  showLoader: boolean = false;
  rate: any;
  elanguage = eLanguage;

  questionPointGroupRange = {
    firstGroup: { initial: 1, last: 5 },
    secondGroup: { initial: 6, last: 10 },
    thirdGroup: { initial: 11, last: 0 },
  }

  questionGroupPoints: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public authService: AuthService,
    private homeService: HomeService,
    private surveyService: SurveyService,
    private toasterService: ToastrService,
    private translateService: TranslateService
  ) { }

  async ngOnInit() {
    this.inIt();
  }

  async inIt() {
    this.surveyQuestionsForm = this.fb.array([]);
    const survey = this.surveyService.getLocalSurvey();
    // if (!survey) {
    //   this.router.navigate(["/"]);
    // }

    const surveyQuestions = this.surveyService.getQuestionSurvey();
    this.createSurveyFrom(survey);
    this.questionForm = await this.createQuestionFrom();

    if (this.surveyForm.value.backGroundImage) {
      this.onBackgroundSelect(this.surveyForm.value.backGroundImage as File);
    }

    await this.getRates();
    await this.getQuestionRate();

    if (surveyQuestions?.questions?.length) {
      await this.populateSurveyQuestions(surveyQuestions?.questions);
    }
    this.surveyForm.get("exchangeRate").setValue(this.authService.currentUser?.exchangeRate);
    this.surveyForm.get("questionRate").setValue(this.authService.currentUser?.questionRate);
    // this.questionForm.get('totalBalance').setValue(this.authService.currentUser?.totalBalance ? this.authService.currentUser?.totalBalance : 0);
    // this.surveyForm.get('imageUrl').setValue(this.authService.currentUser?.questionRate);
  }

  private createSurveyFrom(data?: any) {
    this.surveyForm = this.fb.group({
      id: new FormControl(data?.surveyId ? data.surveyId : null),
      title: new FormControl(data.title ? data.title : "", [
        Validators.required,
      ]),
      categoryId: new FormControl(data.categoryId ? data.categoryId : null, [
        Validators.required,
      ]),
      description: new FormControl(data.description ? data.description : "", [
        Validators.required,
      ]),
      language: new FormControl(data.language ? data.language : "en", [
        Validators.required,
      ]),
      image: new FormControl(data.image ? data.image : null),
      imageUrl: new FormControl(data.imageUrl ? data.imageUrl : null),
      totalPoints: new FormControl(data.totalPoints ? data.totalPoints : 0),
      totalBalance: new FormControl(data.totalBalance ? data.totalBalance : 0),
      exchangeRate: new FormControl(data.exchangeRate ? data.exchangeRate : ""),
      questionRate: new FormControl(data.questionRate ? data.questionRate : ""),
      uploadQuestion: new FormControl(
        data.uploadQuestion ? data.uploadQuestion : ""
      ),
      contactAudienceList: new FormControl(
        data.contactAudienceList ? data.contactAudienceList : ""
      ),
      type: new FormControl(data.type ? data.type : ""),
      backGroundImage: new FormControl(
        data.backGroundImageUrl
          ? this.convertUriToFile(data.backGroundImageUrl)
          : ""
      ),
      backGroundImageUrl: new FormControl(
        data.backGroundImageUrl ? data.backGroundImageUrl : ""
      ),
      backGroundImageType: new FormControl(
        data.backGroundImageType ? data.backGroundImageType : ""
      ),
      message: new FormControl(data.message ? data.message : ""),
      surveyType: new FormControl(
        data?.surveyType ? data?.surveyType : this.eSurveyType.NORMAL
      ),
    });
  }
  async createQuestionFrom(data?: any) {
    const formGroup = this.fb.group({
      questionType: new FormControl(
        data?.questionType ? data.questionType : null,
        [Validators.required]
      ),
      question: new FormControl(data?.question ? data.question : null, [
        Validators.required,
      ]),
      image: new FormControl(
        data?.imageUrl ? this.convertUriToFile(data?.imageUrl) : null
      ),
      imageUrl: new FormControl(data?.imageUrl ? await data?.imageUrl : null),
      answers: data?.answers?.length
        ? this.addOptions(data?.answers.length, data.answers, false)
        : this.fb.array([]),
      currentOptionIndex: new FormControl(
        data?.currentOptionIndex ? data.currentOptionIndex : null
      ),
      isOrderText: new FormControl(
        data?.isOrderText ? data?.isOrderText : false
      ),
      additionalText: new FormControl(
        data?.additionalText ? data?.additionalText : null
      ),
      isOptional: new FormControl(data?.isOptional ? data?.isOptional : false),
      noneOfAbove: new FormControl(
        data?.noneOfAbove ? data?.noneOfAbove : false
      ),
      isArrange: new FormControl(data?.isArrange ? data?.isArrange : false),
      isYesNo: new FormControl(data?.isYesNo ? data?.isYesNo : false),
    });
    return formGroup;
  }
  private convertUriToFile(URI: string, imageName?: string): File {
    URI = this.homeService.resetImageURL(URI);
    const imageBlob = this.homeService.dataURItoBlob(URI);
    return new File([imageBlob], `${imageName ? imageName : "name"}.png`, {
      type: "image/png",
    });
  }

  get questionControls() {
    return this.questionForm.controls;
  }
  get questionFormValues() {
    return this.questionForm.value;
  }
  get surveyQuestionsValues() {
    return this.surveyQuestionsForm.value;
  }
  get answers() {
    return this.questionForm.get("answers") as FormArray;
  }

  async getRates() {
    const result = await this.homeService.getRate().toPromise();
    this.rate = result?.length ? result[0] : {};
  }

  async getQuestionRate() {
    const groups = await this.homeService.getQuestionRate().toPromise();
    this.questionGroupPoints = groups?.length ? groups[0] : {};
  }

  addQuestion() {
    this.actionMode = "add";
  }

  setQuestionType(type: string) {
    if (this.questionForm.get("isYesNo").value) {
      return;
    }

    this.questionForm.get("questionType").setValue(type);
    this.answers.clear();
    if (this.eQuesTypes.TEXT === type) {
      this.addOptions(1);
    } else if (
      this.eQuesTypes.MULTI_SELECT === type ||
      this.eQuesTypes.SINGLE_SELECT === type
    ) {
      this.addOptions(2);
    } else if (this.eQuesTypes.EVALUATION === type) {
      this.addOptions(3);
    }
  }

  addOptions(
    noOfOptions: number,
    items?: any[],
    isAnswer: boolean = true
  ): FormArray | void {
    if (isAnswer) {
      for (let index = 0; index < noOfOptions; index++) {
        const optionIndex = this.answers.controls.length
          ? this.answers.controls[this.answers.controls.length - 1].get(
            "optionIndex"
          ).value + 1
          : index;

        this.answers.push(
          this.createOption(optionIndex, items?.length ? items[index] : {})
        );
      }
    } else {
      let answers: FormArray = this.fb.array([]);
      for (let index = 0; index < noOfOptions; index++) {
        const optionIndex = answers.controls.length
          ? answers.controls[answers.controls.length - 1].get("optionIndex")
            .value + 1
          : index;

        answers.push(
          this.createOption(optionIndex, items?.length ? items[index] : {})
        );
      }
      return answers;
    }
  }
  createOption(optionIndex: number, item?: any) {
    return this.fb.group({
      optionIndex: new FormControl(optionIndex),
      option: new FormControl(item?.option ? item.option : null),
      image: new FormControl(
        item?.imageUrl ? this.convertUriToFile(item.imageUrl) : null
      ),
      imageUrl: new FormControl(item?.imageUrl ? item.imageUrl : null),
    });
  }

  onFieldFocus(fb: FormGroup) {
    this.questionForm
      .get("currentOptionIndex")
      .setValue(fb.get("optionIndex").value);
  }
  onYesNoChange() {
    if (this.questionForm.get("isYesNo").value) {
      this.questionForm.get("questionType").setValue(this.eQuesTypes.YES_OR_NO);
      this.answers.clear();
      const options = [{ option: "Yes" }, { option: "No" }];
      this.addOptions(2, options);
    }
  }
  arrangeOptions(data: any[], event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(data, data, event.currentIndex, event.previousIndex);
    }
  }

  async cancel() {
    this.questionForm = await this.createQuestionFrom();
    this.actionMode = null;
    this.isSubmitted = false;
    this.editIndex = undefined;
  }
  saveQuestion() {
    this.isSubmitted = true;

    if (!this.questionFormValues.question) {
      this.translateService.get("survey.please add question").subscribe((text) => {
        this.toasterService.warning(text);
      });
      return;
    }

    if (!this.questionFormValues.questionType && !this.questionFormValues.isYesNo) {
      this.translateService.get("survey.choose_answer_type").subscribe((text) => {
        this.toasterService.error(text);
      });
      return;
    }

    if (!this.questionFormValues.isYesNo) {
      let isError = false;
      for (let fg of this.answers.controls) {
        if (!fg.get("option").value) {
          this.translateService.get("survey.please add answer").subscribe((text) => {
            this.toasterService.warning(text);
          });
          isError = true;
          break;
        }
      }
      if (isError) return;
    }

    if (this.actionMode == "add") {
      this.surveyQuestionsForm.push(this.questionForm);

      if (this.surveyQuestionsValues.length > 0) {
        this.calculateSAR();
        // this.surveyForm.controls.totalPoints.setValue(this.questionsPoint.OneToFive.val * this.surveyQuestionsValues.length);
      } else {
        // show error must select answer
        this.translateService.get("survey.write_answer").subscribe((text) => {
          this.toasterService.error(text);
        });
      }
    } else {
      const quesValues = this.homeService.deepCopy(this.questionForm.value);
      this.surveyQuestionsForm.controls[this.editIndex].patchValue({
        questionType: quesValues.questionType,
        question: quesValues.question,
        image: quesValues.image,
        imageUrl: quesValues.imageUrl,
        answers: quesValues.answers,
        currentOptionIndex: quesValues.currentOptionIndex,
        isOrderText: quesValues.isOrderText,
        additionalText: quesValues.additionalText,
        isOptional: quesValues.isOptional,
        noneOfAbove: quesValues.noneOfAbove,
        isArrange: quesValues.isArrange,
        isYesNo: quesValues.isYesNo,
      });
    }
    this.cancel();
  }
  deleteQuestion(index: number) {
    this.surveyQuestionsForm.removeAt(index);
  }

  async onSelectFile(files: File[], key: string) {
    if (key === "answer" && !this.questionForm?.value.currentOptionIndex == null) {
      return;
    }

    if (files.length > 0) {
      this.showLoader = true;

      const file = files[0];
      if (file?.size > 1000000) {
        this.translateService.get("survey.ImageSize").subscribe((text) => {
          this.toasterService.warning(text);
        });
        this.showLoader = false;
        return;
      }

      const base64 = await this.homeService.convertToBase64(file);

      if (key === "answer") {
        if (this.answers?.controls?.length) {
          this.answers?.controls[this.questionForm?.value.currentOptionIndex].get("image").setValue(file);
          this.answers?.controls[this.questionForm?.value.currentOptionIndex].get("imageUrl").setValue(base64);
        } else {
          this.translateService.get("survey.error_option_not_selected_for_image").subscribe((text) => {
            this.toasterService.warning(text);
          });
        }
      } else {
        this[key].get("image").setValue(file);
        this[key].get("imageUrl").setValue(base64);
      }
      this.showLoader = false;
    }
  }
  async onBackgroundSelect(image: File[] | File) {
    if (!image) {
      return;
    }

    let file;
    if (Array.isArray(image) && image?.length) {
      file = image[0];
    } else if (typeof image === "object") {
      file = image;
    }

    if (file?.size >= 2000000) {
      this.translateService
        .get("survey.backgroundImageSize")
        .subscribe((text) => {
          this.toasterService.warning(text);
        });
      return;
    }

    this.showLoader = true;
    const base64 = await this.homeService.convertToBase64(file);
    this.surveyForm.get("backGroundImageUrl").setValue(base64);
    this.surveyForm.get("backGroundImageType").setValue(file.size);
    this.surveyForm.get("backGroundImage").setValue(file);
    this.showLoader = false;
  }

  saveSurvey() {
    if (!this.surveyForm.controls.description.value) {
      this.translateService.get("survey.3 description")
        .subscribe((text) => {
          this.toasterService.warning(text);
        });
      return;
    }

    // const survey = this.deleteKeysFromSurvey(this.homeService.deepCopy(this.surveyForm.value));
    const survey = this.homeService.deepCopy(this.surveyForm.value);

    this.surveyService.createQuestionSurvey({
      ...survey,
      questions: this.homeService.deepCopy(this.surveyQuestionsForm.value),
      language: survey.language === "ar" ? "Arabic" : "English",
      sharedSurveyImage: survey.image,
      imageName: survey?.image?.name ? survey?.image.name : null,
      surveyImage: this.surveyForm.value.imageUrl ? this.surveyForm.value.imageUrl : null,
    });

    this.router.navigate(["/survey/options"]);
  }

  async edit(data: any, index: number) {
    this.actionMode = "edit";
    this.editIndex = index;
    await this.populateQuestionForEdit(data);
  }
  async populateQuestionForEdit(data: any) {
    if (data) {
      data = this.homeService.deepCopy(data);
      this.questionForm = await this.createQuestionFrom(data);
      // this.addOptions(data.answers.length, data.answers);
    }
  }
  async populateSurveyQuestions(questions: Array<any>) {
    if (questions?.length) {
      for (let index = 0; index < questions?.length; index++) {
        const question = questions[index];
        this.surveyQuestionsForm.push(await this.createQuestionFrom(question));
        this.calculateSAR();
      }
      // this.surveyForm.get('totalBalance').setValue();
    }
  }

  deleteKeysFromSurvey(survey: any) {
    delete survey.imageUrl;
    delete survey.backGroundImageUrl;
    delete survey.backGroundImageType;
    survey.questions.forEach((q) => {
      delete q.imageUrl;
    });
    return survey;
  }

  calculateSAR() {

    let configPoints = 0;
    const quesCount = this.surveyQuestionsValues.length;
    if (quesCount >= this.questionPointGroupRange.firstGroup.initial && quesCount <= this.questionPointGroupRange.firstGroup.last) {
      configPoints = configPoints + this.questionGroupPoints?.firstGroup;
    } else if (quesCount >= this.questionPointGroupRange.secondGroup.initial && quesCount <= this.questionPointGroupRange.secondGroup.last) {
      configPoints = configPoints + this.questionGroupPoints?.secondGroup;
    } else if (quesCount >= this.questionPointGroupRange.thirdGroup.initial) {
      configPoints = configPoints + this.questionGroupPoints?.thirdGroup;
    }

    let sar = this.surveyForm.value.totalBalance ? this.surveyForm.value.totalBalance : 0;
    const remTotalPoints = sar - configPoints;
    this.surveyForm.get('totalPoints').setValue(remTotalPoints);
    this.surveyForm.get('totalBalance').setValue(configPoints);
  }

}
