import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { Font, FontPickerDirective } from "ngx-font-picker";
import { ColorPickerService, Cmyk } from "ngx-color-picker";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { NewServeySecondComponent } from "../new-servey-second/new-servey-second.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { FontsService } from "src/services/fonts.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { LanguageService } from "../../../services/language.service";
import { SurveyService } from "src/services/survey.service";
import { AuthService } from "src/services/auth.service";
import { PricingComponent } from "../pricing/pricing.component";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { CreateSurveyComponent } from "../create-survey/create-survey.component";

@Component({
  selector: "app-create-custom-survey",
  templateUrl: "./create-custom-survey.component.html",
  styleUrls: ["./create-custom-survey.component.css"],
})
export class CreateCustomSurveyComponent implements OnInit, AfterViewInit {
  @ViewChild("fontPickerElement", { static: true })
  fontPicker: FontPickerDirective;

  closeFontPicker(field: string): void {
    this.fontPicker.closeDialog();
  }
  isMobileView = "col-12";
  private _filteredFonts = [
    "Open Sans",
    "Oswald",
    "Courier",
    "Nunito Sans",
    "Quicksand",
    "Karla",
    "Oxygen",
    "Dosis",
    "Bitter",
    "Noto Sans SC",
    "Assistant",
    "Domine",
  ];
  private _presetFonts = [
    "Arial",
    "Times",
    "Courier",
    "Lato",
    "Open Sans",
    "Roboto Slab",
  ];

  public font: Font = new Font({
    family: "Lato",
  });
  public fonts: Font = new Font({
    family: "Lato",
  });
  public headerFont: Font = new Font({
    family: "Lato",
  });
  public subFont: Font = new Font({
    family: "Lato",
  });
  public sizeSelect: boolean = true;
  public sortFonts: boolean = false;
  public styleSelect: boolean = true;
  public toggle: boolean = false;

  public rgbaText: string = "rgba(165, 26, 214, 0.2)";
  stars = [1, 2, 3, 4, 5];
  public arrayColors: any = {
    color1: "#2883e9",
    color2: "#e920e9",
    color3: "rgb(255,245,0)",
    color4: "rgb(236,64,64)",
    color5: "rgba(45,208,45,1)",
  };

  public selectedColor: string = "color1";

  color: string = "#2889e9";
  public color1: string = "#2889e9";
  public color2: string = "#e920e9";
  public color3: string = "#fff500";
  public color4: string = "rgb(236,64,64)";
  public color5: string = "rgba(45,208,45,1)";
  public color6: string = "#1973c0";
  public color7: string = "#f200bd";
  public color8: string = "#a8ff00";
  public color9: string = "#278ce2";
  public color10: string = "#0a6211";
  public color11: string = "#f2ff00";
  public color12: string = "#f200bd";
  public color13: string = "rgba(0,255,0,0.5)";
  public color14: string = "rgb(0,255,255)";
  public color15: string = "rgb(255,0,0)";
  public color16: string = "#a51ad633";
  public color17: string = "#666666";
  public color18: string = "#fa8072";
  public color19: string = "#f88888";

  public cmykValue: string = "";

  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);
  public filteredFonts = [];
  public presetFonts = this._presetFonts;

  onClose: any;
  form: FormGroup;
  previousUrl: any;
  selectedFont: string = "";
  userBrand: [];
  data: any;
  customSurveyForm!: FormGroup;

  fontFamilies: any[] = [];
  colors: any[] = [];
  rating = ["star", "emotion"];
  imageSrc: any | SafeResourceUrl = "assets/imgs/logo.svg";
  fileSelected = false;
  isInputVisible: boolean = false;

  // Details for the survey Preview
  fontFamilyBrand: string | null = null;
  colorBrand: string | null = null;
  colorBgBrand: string | null = null;

  // Details of Header Text for the survey Preview
  fontFamilyHeaderText: string | null = null;
  colorHeaderText: string | null = null;

  // Details of Header Text Style for the survey Preview
  fontFamilyHeaderTextStyle: string | null = null;
  colorHeaderTextStyle: string | null = null;

  // Details of Header Text for the survey Preview
  fontFamilyAnswerText: string | null = null;
  colorHeaderAnswerTextS: string | null = null;
  isDisabled: boolean = false;
  language: string = "en";

  // brandName: string | null = 'Brand Name';
  headerText: string | null = "Survey Title";
  headerTextStyle: string | null =
    "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
  answerTextStyle: string | null =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit.\n" +
    "Impedit nihil, praesentium sint dolorem cum sed delectus\n" +
    "laborum voluptate commodi, debitis explicabo eos in! Harum.";

  constructor(
    public darkThemeService: DarkLiteThemeService,
    private router: Router,
    private modalService: BsModalService,
    private fontService: FontsService,
    private sanitizer: DomSanitizer,
    private surveyService: SurveyService,
    public authService: AuthService,
    public languageService: LanguageService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private toasterService: ToastrService,

    public vcRef: ViewContainerRef,
    private cpService: ColorPickerService
  ) {}

  toggleMobileView(pass: string) {
    if (pass === "mobile") {
      this.isMobileView = "col-6";
    } else {
      this.isMobileView = "col-12";
    }
  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguageOrDefault();

    this.fontFamilies = this.fontService.getAvailableFontFamilies();
    this.colors = this.fontService.getAvailableColors();
    this.data = this.surveyService.getEditBrand();
    if (this.data) {
      this.imageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.data.surveyImageUrl
      );
    }
    this.customSurveyForm = this.createCustomSurveyFrom(this.data);
    this.getUserBrandManagement();
  }

  ngAfterViewInit(): void {
    this.customSurveyForm
      .get("questionFontFamily")
      ?.valueChanges.subscribe((value) => {
        this.fontFamilyBrand = value;
      });

    this.customSurveyForm
      .get("questionFontColor")
      ?.valueChanges.subscribe((value) => {
        this.colorBrand = value;
      });

    this.customSurveyForm
      .get("surveyBackgroundColor")
      ?.valueChanges.subscribe((value) => {
        this.colorBgBrand = value;
      });

    this.headerTextChangeUpdate();
    this.headerTextStyleChangeUpdate();
    this.answerTextStyleChangeUpdate();
  }

  private createCustomSurveyFrom(data): FormGroup {
    return (this.customSurveyForm = this.fb.group({
      // 1
      brandName: [data ? data.brandName : "", Validators.required],
      brandNameArabic: [data ? data.brandNameArabic : ""],
      logoColor: [data ? data.logoColor : ""],
      surveyImageUrl: [data ? data.surveyImageUrl : ""],

      // 2.SURVEY STYLE FORMAT
      surveyBackgroundColor: [
        data ? data.surveyBackgroundColor : "",
        Validators.required,
      ],
      questionFontFamily: [data ? data.questionFontFamily : "Lato"],
      questionFontColor: [
        data ? data.questionFontColor : "",
        Validators.required,
      ],

      // 3. SURVEY STYLE FORMAT
      // 3.1
      headerText: [data ? data.headerText : "", Validators.required],
      headerColor: [data ? data.headerColor : "", Validators.required],
      headerFontFamily: [
        data ? data.headerFontFamily : "Lato",
        Validators.required,
      ],
      // 3.2
      headerTextStyle: [data ? data.headerTextStyle : "", Validators.required],
      headerTextStyleColor: [
        data ? data.headerTextStyleColor : "",
        Validators.required,
      ],
      headerTextStyleFontFamily: [
        data ? data.headerTextStyleFontFamily : "Lato",
        Validators.required,
      ],
      // 3.3
      answerTextStyle: [data ? data.answerTextStyle : "", Validators.required],
      answerTextColor: [data ? data.answerTextColor : "", Validators.required],
      answerTextFontFamily: [
        data ? data.answerTextFontFamily : "Lato",
        Validators.required,
      ],
      rating: [data ? data.rating : "emotion", Validators.required],
    }));
  }
  getSafeUrl(data: string) {
    return (
      this.sanitizer.bypassSecurityTrustResourceUrl(data) ??
      "assets/imgs/logo.svg"
    );
  }
  onFileChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];

    if (file) {
      if (file.name.toLowerCase().endsWith(".svg")) {
        this.toasterService.success("SVG file selected.");
        const reader = new FileReader();
        reader.onload = (e) => {
          const svgContent: any = this.sanitizer.bypassSecurityTrustResourceUrl(
            e.target?.result as string
          ) as SafeResourceUrl;
          this.imageSrc = svgContent;
          this.customSurveyForm
            .get("surveyImageUrl")
            ?.setValue(svgContent?.changingThisBreaksApplicationSecurity);
          this.fileSelected = true;
        };
        reader.readAsDataURL(file);
      } else {
        console.error("Please select an SVG file.");
        this.toasterService.error("Please select an SVG file.");
        inputElement.value = "";
        this.fileSelected = false;
      }
    }
  }

  close() {
    this.onClose();
  }
  openSelect(selectId: string) {
    const selectElement = document.getElementById(selectId);
    if (selectElement) {
      selectElement.click();
    }
  }

  triggerColorPicker(className: any) {
    // Find the color picker input element
    const colorPickerInput: any = document.querySelector(`.${className}`);

    // Trigger a click event on the color picker input element
    if (colorPickerInput) {
      colorPickerInput.click();
    }
  }
  triggerFontPicker(className: any) {
    // Find the font picker input element
    const fontPickerInput: any = document.querySelector(`.${className}`);

    // Trigger a click event on the font picker input element
    if (fontPickerInput) {
      fontPickerInput.click();
    }
  }

  goBack() {
    this.router.navigate([""]);
    const verifyModel = this.modalService.show(NewServeySecondComponent, {
      class: "new-survey create-survey-modal",
      backdrop: "static",
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
  }
  family(e) {
    console.log("e", e);
  }

  onColorChange(formValue: any, event: any): void {
    this.customSurveyForm.get(formValue).setValue(event);
  }
  selectColor(color: string) {
    this.selectedColor = color;

    // Decode base64 string to SVG XML

    if (this.imageSrc) {
      const svgContent = atob(
        this.imageSrc.changingThisBreaksApplicationSecurity.split(",")[1]
      );
      // Replace the fill attribute with the selected color
      const modifiedSVGContent = svgContent.replace(
        /fill=".*?"/g,
        `fill="${color}"`
      );

      // Create a new SafeResourceUrl with the modified SVG content
      const modifiedUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl(
        "data:image/svg+xml;base64," + btoa(modifiedSVGContent)
      );

      this.imageSrc = modifiedUrl;
      this.customSurveyForm
        .get("surveyImageUrl")
        ?.setValue(modifiedUrl.changingThisBreaksApplicationSecurity);
    }
  }

  getUserBrandManagement() {
    this.surveyService.getUserBrand().subscribe((result) => {
      return (this.userBrand = result);
    });
  }
  submit() {
    console.log(this.form.value);
  }
  extractSurveyNumbers(conditions: string[]): number | null {
    for (const condition of conditions) {
      const match = condition.match(/(\d+)/);

      if (match && condition.toLowerCase().includes("brand")) {
        return parseInt(match[0], 10);
      }
    }

    return null;
  }

  customSubmit() {
    debugger;
    this.isDisabled = true;
    const surveyLimit = this.extractSurveyNumbers(
      this.authService?.currentUser?.planId?.description
    );
    if (!this.authService.currentUser?.planId) {
      console.log("Please select");
      const PlanModel = this.modalService.show(PricingComponent, {
        class: "modal-dialog1 modal-content1",
        backdrop: "static",
      });
      PlanModel.content.onClose = () => {
        PlanModel.hide();
      };
      setTimeout(() => {
        this.isDisabled = false;
      }, 3000);
    } else if (
      this.userBrand?.length &&
      surveyLimit !== null &&
      this.userBrand.length >= surveyLimit &&
      this.authService?.currentUser?.planId?.type === "free"
    ) {
      this.translateService
        .get("newSurvey.surveyLimitExceeded")
        .subscribe((text) => {
          this.toasterService.error(text);
          setTimeout(() => {
            this.isDisabled = false;
          }, 3000);
        });
    } else if (this.data) {
      const data = {
        ...this.customSurveyForm.value,
        _id: this.data?._id,
        logoColor: this.selectedColor,
      };
      this.surveyService.updateBrandManagement(data).subscribe((res) => {
        this.surveyService.editBrand(data);
        this.translateService.get("brand.update").subscribe((text) => {
          this.toasterService.success(text);
          setTimeout(() => {
            this.isDisabled = false;
          }, 3000);
        });
      });
    } else {
      if (this.customSurveyForm.valid) {
        const data = {
          ...this.customSurveyForm.value,
          logoColor: this.selectedColor,
        };
        this.surveyService.createBrandManagement(data).subscribe((res) => {
          this.surveyService.editBrand(data);
          this.translateService.get("brand.success").subscribe((text) => {
            this.toasterService.success(text);
            setTimeout(() => {
              this.isDisabled = false;
            }, 3000);
          });
        });
        this.getUserBrandManagement();
      } else {
        console.log(
          "🚀 ~ CreateCustomSurveyComponent ~ customSubmit ~ this.customSurveyForm.valid:",
          this.customSurveyForm.value
        );

        this.translateService
          .get("please add all  and valid data")
          .subscribe((text) => {
            this.toasterService.error(text);
            setTimeout(() => {
              this.isDisabled = false;
            }, 3000);
          });
      }
    }
  }
  createSurvey() {
    if (this.data) {
      this.router.navigate([""]);
      const verifyModel = this.modalService.show(CreateSurveyComponent, {
        class:
          "modal-lg modal-dialog-centered sign-up c-survey-modal  create-survey-modal",
        backdrop: "static",
      });
      verifyModel.content.onClose = (auth) => {
        verifyModel.hide();
      };
    }
  }
  headerTextChangeUpdate() {
    this.customSurveyForm
      .get("headerColor")
      ?.valueChanges.subscribe((value) => {
        this.colorHeaderText = value;
      });
    this.customSurveyForm
      .get("headerFontFamily")
      ?.valueChanges.subscribe((value) => {
        this.fontFamilyHeaderText = value;
      });
  }

  headerTextStyleChangeUpdate() {
    this.customSurveyForm
      .get("headerTextStyleColor")
      ?.valueChanges.subscribe((value) => {
        this.colorHeaderTextStyle = value;
      });
    this.customSurveyForm
      .get("headerTextStyleFontFamily")
      ?.valueChanges.subscribe((value) => {
        this.fontFamilyHeaderTextStyle = value;
      });
  }

  answerTextStyleChangeUpdate() {
    this.customSurveyForm
      .get("answerTextColor")
      ?.valueChanges.subscribe((value) => {
        this.colorHeaderAnswerTextS = value;
      });
    this.customSurveyForm
      .get("answerTextFontFamily")
      ?.valueChanges.subscribe((value) => {
        this.fontFamilyAnswerText = value;
      });
  }

  onEventLog(color: string): void {
    console.log(color, "onEventLog");
    this.customSurveyForm.get("questionFontColor").setValue(color);
  }
  onEventBackGround(event: string): void {
    this.customSurveyForm.get("surveyBackgroundColor").setValue(event);
  }
  public onChangeColor(color: string): void {
    console.log("Color changed:", color);
  }

  public onChangeColorCmyk(color: string): Cmyk {
    const hsva = this.cpService.stringToHsva(color);

    if (hsva) {
      const rgba = this.cpService.hsvaToRgba(hsva);

      return this.cpService.rgbaToCmyk(rgba);
    }

    return new Cmyk(0, 0, 0, 0);
  }

  public onChangeColorHex8(color: string): string {
    const hsva = this.cpService.stringToHsva(color, true);

    if (hsva) {
      return this.cpService.outputFormat(hsva, "rgba", null);
    }

    return "";
  }

  onFontChange(selectedFont: Font, key): void {
    console.log(selectedFont, key);
    this.customSurveyForm.get(key).setValue(selectedFont.family);
    this.fontPicker.closeDialog();
  }
  onFontChanget(selectedFont: Font, key): void {
    console.log(selectedFont, key);
    this.customSurveyForm.get(key).setValue(selectedFont.family);
    this.fontPicker.closeDialog();
  }
}
