<div class="dashed-border p-2">

  <div class="modal-header">
    <!-- <h4 class="modal-title title" id="exampleModalLongTitle">
      {{ survey?.title | translate }}
    </h4> -->
    <button (click)="onClose()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <img src="assets/imgs/times-icon.svg" alt="" />
    </button>
  </div>

  <div class="modal-body">
    <div class="text-center">
      <h3>{{ "approveSurvey.YourSurveyIsPendingApproval" | translate }}</h3>
      <p>{{ "approveSurvey.approvalDetails" | translate }}</p>
    </div>
    <div class="survey-cost-details-section">
      <h4 class="details">{{ "approveSurvey.surveyCostDetails" | translate }}</h4>
      <div class="box shadow-lg text-capitalize p-2">
        <div class="dashed-border-grey p-2">

          <table class="finalize-table">
            <tbody>
              <tr>
                <td>
                  <h5>{{ "approveSurvey.costPerSurvey" | translate }}</h5>
                </td>
                <td>
                  <h6 class="pts">{{ survey?.costPerSurvey }}</h6>
                </td>
                <td>
                  <h6 class="span">{{ "survey.SAR" | translate }}</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>{{ "approveSurvey.costPerQuestion" | translate }}</h5>
                </td>
                <td>
                  <h6 class="pts">{{ survey?.costPerQuestion }}</h6>
                </td>
                <td>
                  <h6 class="span">{{ "survey.SAR" | translate }}</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>{{ "approveSurvey.totalSpendingSar" | translate }}</h5>
                </td>
                <td>
                  <h6 class="pts">{{ survey?.spendingNow }}</h6>
                </td>
                <td>
                  <h6 class="span">{{ "survey.SAR" | translate }}</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 class="text-theme">{{ "survey.costPerResponse" | translate }}</h5>
                </td>
                <td>
                  <h6 class="pts">{{ survey?.costPerResponse | number:'0.2-2' }}</h6>
                </td>
                <td>
                  <h6 class="span text-theme">{{ "survey.SAR" | translate }}</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>{{ "approveSurvey.expectedResponse" | translate }}</h5>
                </td>
                <td>
                  <h6 class="pts">{{ survey?.totalResponses }}</h6>
                </td>
                <td>
                  <h6 class="span">{{ "approveSurvey.RSP" | translate }}</h6>
                </td>
              </tr>
              <!-- <tr>
                <td>
                  <h5>{{ "survey.TargetAudiences" | translate }}</h5>
                </td>
                <td>
                  <h6 class="pts">{{ survey?.usersValue }}</h6>
                </td>
                <td>
                  <h6 class="span">{{ "approveSurvey.RSP" | translate }}</h6>
                </td>
              </tr> -->
              <tr>
                <td>
                  <h5>
                    <span class="free">{{ "survey.free" | translate }}</span>
                    <span class="free ml-1">{{ "approveSurvey.Response" | translate }}</span>
                  </h5>
                </td>
                <td>
                  <h6 class="free pts">50</h6>
                </td>
                <td>
                  <h6 class="span text-green-imp">{{ "approveSurvey.RSP" | translate }}</h6>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <div class="d-flex justify-content-between p-2">
            <h5>{{ "approveSurvey.totalSpendingPoints" | translate }}</h5>
            <h6 class="pts">{{ survey?.totalPoints }}</h6>
            <h6 class="span">{{ "survey.SAR" | translate }}</h6>
          </div>
          <hr> -->

          <!-- <div class="d-flex justify-content-between p-2">
            <h5 class="text-theme">{{ "survey.costPerResponse" | translate }}</h5>
            <h6 class="pts">{{ survey?.costPerSurvey }}</h6>
            <h6 class="span text-theme">{{ "survey.SAR" | translate }}</h6>
          </div>
          <hr> -->

          <!-- <div class="d-flex justify-content-between p-2">
            <h5>{{ "approveSurvey.expectedResponse" | translate }}</h5>
            <h6 class="pts">{{ survey?.usersValue }}</h6>
            <h6 class="span">{{ "approveSurvey.RSP" | translate }}</h6>
          </div>
          <hr> -->

          <!-- <div class="d-flex justify-content-between p-2">
            <h5 class="text-theme">{{ "survey.TargetAudiences" | translate }}</h5>
            <h6 class="pts">{{ survey?.usersValue }}</h6>
            <h6 class="span text-theme">{{ "approveSurvey.RSP" | translate }}</h6>
          </div>
          <hr> -->

          <!-- <div class="d-flex justify-content-between p-2">
            <h5>{{ "survey.paidMarketingCost" | translate }}</h5>
            <h6 class="pts">{{ survey?.usersValue }}</h6>
            <h6 class="span">{{ "approveSurvey.RSP" | translate }}</h6>
          </div>
          <hr> -->

          <!-- <div class="d-flex justify-content-between p-2">
            <h5>
              <span class="free">{{ "survey.free" | translate }}</span>
              <span class="free ml-1">{{ "approveSurvey.Response" | translate }}</span>
            </h5>
            <h6 class="free pts">50</h6>
            <h6 class="span text-green-imp">{{ "approveSurvey.RSP" | translate }}</h6>
          </div> -->

        </div>

        <p class="note">{{ 'approveSurvey.first50ResponsesNote' | translate }}</p>
      </div>
      <div class="allbutton">
        <input type="text" [value]="surveyId" id="surveyIdCopy" hidden />
        <button class="m-btn view">
          {{ "approveSurvey.viewReport" | translate }}
          <img src="assets/imgs/survey-icons/stats-icon.svg" class="ml-2" alt="">
        </button>
        <button class="m-btn copy" (click)="copyUrl()">
          {{ "approveSurvey.copySurveyUrl" | translate }}
          <img src="assets/imgs/survey-icons/chain.svg" class="ml-2" alt="">
        </button>
        <input type="text" hidden #inpUrlHidden name="url-hidden" id="url-hidden" />
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-9 text-center">
        <p class="note">{{ "approveSurvey.dataNote" | translate }}</p>
      </div>
    </div>
  </div>

</div>