import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DarkLiteThemeService {
  private localStorageKey = "themeState";
  private isDarkTheme = true;

  constructor() {
    const storedThemeState = localStorage.getItem(this.localStorageKey);
    if (storedThemeState) {
      this.isDarkTheme = JSON.parse(storedThemeState);
    }
  }

  toggleTheme() {
    this.isDarkTheme = !this.isDarkTheme;
    localStorage.setItem(
      this.localStorageKey,
      JSON.stringify(this.isDarkTheme)
    );
  }

  get getCurrentTheme() {
    return this.isDarkTheme ? "dark-mode" : "light-mode";
  }
}
