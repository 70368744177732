<!-- <app-header></app-header> -->
<div [ngClass]="darkThemeService.getCurrentTheme" class="wrapper home-wrap">
  <!-- my account section start -->
  <section class="my-stats">
    <div class="container-fluid">
      <section class="row align-items-center py-4">
        <div class="col-lg-3">
          <div class="text-white user-font">
            <div class="welcome-heading">
              <span class="font-20"
                >{{ "newDasboard.WelcomeBack" | translate }},&nbsp;</span
              >
              <span
                class="nameStyle"
                [title]="authService.currentUser?.firstName"
                >{{
                  authService.currentUser?.firstName
                    ? authService.currentUser?.firstName
                    : "KAFU"
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="stats">
            <div class="row pb-3">
              <div class="col-6">
                <a (click)="refresh()">
                  <img
                    src="./assets/imgs/fa_refresh.png"
                    alt=""
                    id="myImage"
                    height="35"
                  />
                </a>
              </div>
              <div class="col-6">
                <h1
                  class="title"
                  style="
                    font-size: 22px;
                    font-weight: 700;
                    text-transform: uppercase;
                    text-align: end;
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.20294 21H9.06918C9.33191 21 9.54689 20.8032 9.54689 20.5625V0.43989C9.54689 0.199295 9.33192 0.00244048 9.06918 0.00244048H6.20294C5.9402 0.00244048 5.72523 0.199295 5.72523 0.43989V20.5625C5.72523 20.8031 5.94019 21 6.20294 21Z"
                      fill="#1BBAA2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.478418 21H3.34025C3.60258 21 3.81722 20.8031 3.81722 20.5625V12.6876C3.81722 12.447 3.60258 12.2501 3.34025 12.2501H0.478418C0.216086 12.2501 0.00144503 12.447 0.00144503 12.6876V20.5625C0.00144503 20.8031 0.216086 21 0.478418 21Z"
                      fill="#1BBAA2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.9378 21H14.7877C15.056 21 15.2706 20.8032 15.2706 20.5571V9.19521C15.2706 8.94916 15.056 8.75232 14.7877 8.75232H11.9378C11.6695 8.75232 11.4549 8.94916 11.4549 9.19521V20.5571C11.4549 20.8032 11.6695 21 11.9378 21Z"
                      fill="#1BBAA2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.1841 3.94321V20.557C17.1841 20.8031 17.3988 21 17.6671 21H20.517C20.7853 21 20.9999 20.8031 20.9999 20.557V3.94321C20.9999 3.69712 20.7853 3.50024 20.517 3.50024H17.6671C17.3988 3.50024 17.1841 3.69712 17.1841 3.94321Z"
                      fill="#1BBAA2"
                    />
                  </svg>
                  {{ "home.myStats" | translate }}
                </h1>
              </div>
            </div>

            <!-- <img src="./assets/imgs/refresh.svg" alt="" /> -->
          </div>
          <div class="d-flex justify-content-between flex-sm-nowrap flex-wrap">
            <div class="stats-widget">
              <div class="headerTop">
                <div class="text-white mt-2 font-16">
                  {{ "newDasboard.TotalSurveys" | translate }}
                </div>
              </div>
              <div class="bottomFooter">
                <b class="titleb">{{ surveys || 0 }}</b>
              </div>
            </div>
            <div class="stats-widget">
              <div class="headerTop">
                <div class="text-white mt-2 font-16">
                  {{ "newDasboard.TotalResponseses" | translate }}
                </div>
              </div>
              <div class="bottomFooter">
                <b class="titleb">{{ answers || 0 }}</b>
              </div>
            </div>
            <div class="stats-widget">
              <div class="headerTop">
                <div class="text-white mt-2 font-16">
                  {{ "newDasboard.LiveSpending" | translate }}
                </div>
              </div>
              <div class="bottomFooter">
                <b class="titleb">{{
                  spendingPoints?.toFixed(2) || 0 | shortenNumber
                }}</b>
              </div>
            </div>
            <div class="stats-widget">
              <div class="headerTop">
                <div class="text-white mt-2 font-16">
                  {{ "newDasboard.LinkVisit" | translate }}
                </div>
              </div>
              <div class="bottomFooter">
                <b class="titleb">{{ clicked || 0 | shortenNumber }}</b>
              </div>
            </div>
            <div class="stats-widget">
              <div class="headerTop">
                <div class="text-white mt-2 font-16">
                  {{ "newDasboard.Referrals" | translate }}
                </div>
              </div>
              <div class="bottomFooter">
                <b class="titleb">{{ referrals || 0 }}</b>
              </div>
            </div>
            <div class="stats-widget">
              <div class="headerTop">
                <div class="text-white mt-2 font-16">
                  {{ "newDasboard.Likes" | translate }}
                </div>
              </div>
              <div class="bottomFooter">
                <b class="titleb">{{ likes || 0 }}</b>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>

  <!--end my account-->
  <div class="main-sec p-5">
    <div class="card-sec border1">
      <div class="stf-title stf-title-1 three text-center">
        {{ "newDasboard.QuickActions" | translate }}
      </div>
      <div class="row p-5">
        <div class="col-lg-4 col-md-4 col-sm-4 col-12 mb-sm-0 mb-5">
          <div class="quick-action-card first">
            <h4>{{ "newDasboard.CREATE SURVEY" | translate }}</h4>
            <p class="card-description">
              {{ "newDasboard.customized_expirence" | translate }}
            </p>
            <div class="btn-container">
              <button class="quick-action-btn" (click)="createSurvey()">
                {{ "newDasboard.Create" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-12 mb-sm-0 mb-5">
          <div class="quick-action-card second">
            <h4>{{ "newDasboard.CUSTOM SURVEY" | translate }}</h4>

            <p class="card-description">
              {{ "newDasboard.ratings_satisfaction_feedback" | translate }}
            </p>

            <!-- <button class="quick-card-btn" (click)="CustomSurvey()">{{ "newDasboard.Create" |
                  translate }}</button> -->
            <div class="btn-container">
              <button class="quick-action-btn" (click)="CustomSurvey()">
                {{ "newDasboard.Create" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-12 mb-sm-0 mb-5">
          <div class="quick-action-card third">
            <h4>{{ "newDasboard.KAFU POLL" | translate }}</h4>

            <p class="card-description">
              {{
                "newDasboard.Ask your audinace to VOTE about a topic or Question!"
                  | translate
              }}
            </p>
            <div class="btn-container">
              <button class="quick-action-btn" (click)="openComingSoonModal()">
                {{ "newDasboard.Create" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="quick-action-cards mt-4 wrapper home-wrap"
      *ngIf="visibleSurveys.length"
    >
      <div class="h2 font-fixes text-center">
        {{ "newDasboard.Currentlylive" | translate }}
      </div>
      <section class="timer-slider row">
        <!--        <div class="col-12 p-0">-->
        <!--          <owl-carousel-o [options]="slideConfig" [ngClass]="currentLanguage==elanguage.Short_Arabic?'clarusola':''">-->
        <!--            <ng-container *ngFor="let survey of userSurveySlider; let i = index">-->
        <!--              <ng-template [id]="survey.id" carouselSlide>-->
        <!--                <div class="item" [class]="'item_' + i">-->
        <!--                  <div class="sub_part_1">-->
        <!--                    <div class="text">-->
        <!--                      <p>{{ survey?.title }}</p>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="sub_part_2">-->
        <!--                    <div class="d-flex justify-content-center w-100">-->
        <!--                      <div class="point-type-container">-->
        <!--                        <div class="point-type" (click)="onPointsOrResponseClick(survey)">-->
        <!--                          <span class="point-type-label" [class.selected-type]="-->
        <!--                                        survey.pointsOrResponse == 'points'-->
        <!--                                      ">{{ "survey.SAR" | translate }}-->

        <!--                          </span>-->
        <!--                        </div>-->
        <!--                        <div class="point-type" (click)="onPointsOrResponseClick(survey)">-->
        <!--                          <span class="point-type-label" [class.selected-type]="-->
        <!--                                        survey.pointsOrResponse == 'responses'-->
        <!--                                      ">{{ "newDasboard.Response" | translate }}-->

        <!--                          </span>-->
        <!--                        </div>-->
        <!--                      </div>-->
        <!--                    </div>-->

        <!--                    <ul class="points-container" *ngIf="survey.pointsOrResponse == 'points'">-->
        <!--                      <li>-->
        <!--                        <div class="points-box spend-points">-->
        <!--                          <h6>-->
        <!--                            {{ survey?.totalPoints }}-->
        <!--                            {{ "survey.points" | translate }}-->
        <!--                          </h6>-->
        <!--                          <p class="new-text">{{ "home.spendingLive" | translate }}</p>-->
        <!--                        </div>-->
        <!--                      </li>-->
        <!--                      <li>-->
        <!--                        <img src="./assets/imgs/survey-icons/up-left-circle-arrow.svg" />-->
        <!--                      </li>-->
        <!--                      <li>-->
        <!--                        <div class="points-box max-points">-->
        <!--                          <h6>-->
        <!--                            {{ survey?.spendingPoints }}-->
        <!--                            {{ "survey.points" | translate }}-->
        <!--                          </h6>-->
        <!--                          <p>{{ "survey.maxSending" | translate }}</p>-->
        <!--                        </div>-->
        <!--                      </li>-->
        <!--                    </ul>-->

        <!--                    <ul class="points-container" *ngIf="survey.pointsOrResponse == 'responses'">-->
        <!--                      <li>-->
        <!--                        <div class="points-box spend-points">-->
        <!--                          <h6>{{ survey?.spendingPoints }}</h6>-->
        <!--                          <p>{{ "home.responses" | translate }}</p>-->
        <!--                        </div>-->
        <!--                      </li>-->
        <!--                      <li>-->
        <!--                        <img src="./assets/imgs/survey-icons/up-left-circle-arrow.svg" alt="" />-->
        <!--                      </li>-->
        <!--                      <li>-->
        <!--                        <div class="points-box max-points">-->
        <!--                          <h6>-->
        <!--                            {{ survey?.spendingPoints }}-->
        <!--                            {{ "survey.SAR" | translate }}-->
        <!--                          </h6>-->
        <!--                          <p>{{ "survey.maxSending" | translate }}</p>-->
        <!--                        </div>-->
        <!--                      </li>-->
        <!--                    </ul>-->
        <!--                  </div>-->
        <!--                  <div class="sub_part_3">-->
        <!--                    <div class="counter counter-container">-->
        <!--                      <app-counter [showSeparator]="true" [dates]="{-->
        <!--                                    startDate: survey?.startDate,-->
        <!--                                    endDate: survey?.endDate-->
        <!--                                  }"></app-counter>-->
        <!--                    </div>-->

        <!--                    <ul class="counter-status">-->
        <!--                      <li>-->
        <!--                        <div class="circle">-->
        <!--                          <i class="fa fa-play-circle font-20 text-green-gradient"></i>-->
        <!--                          <p class="new-text">{{ "home.active" | translate }}</p>-->
        <!--                        </div>-->
        <!--                      </li>-->
        <!--                      <li>-->
        <!--                        <div class="circle">-->
        <!--                          <img src="./assets/imgs/red-heart.svg" class="status-icon" alt="" />-->
        <!--                          <p class="new-text">{{ survey?.likes }}</p>-->
        <!--                        </div>-->
        <!--                      </li>-->
        <!--                      <li>-->
        <!--                        <div class="circle">-->
        <!--                          <img src="./assets/imgs/flame.svg" class="status-icon" alt="" />-->
        <!--                          <p class="new-text">{{ "home.trend" | translate }}</p>-->
        <!--                        </div>-->
        <!--                      </li>-->
        <!--                      <li>-->
        <!--                        <div class="circle">-->
        <!--                          <img src="./assets/imgs/win-tag.svg" class="status-icon" alt="" />-->
        <!--                          <p class="new-text">{{ "home.win" | translate }}</p>-->
        <!--                        </div>-->
        <!--                      </li>-->
        <!--                    </ul>-->
        <!--                  </div>-->
        <!--                  <div class="sub_part_4">-->
        <!--                    <ul class="actions">-->
        <!--                      <li (click)="stopSurvey(survey)">-->
        <!--                        <a *ngIf="survey.isPublished" class="stop"><i class="fas fa-square"></i></a>-->
        <!--                        <a class="play" *ngIf="survey.isPublished == false"><i class="fa fa-play-circle"></i></a>-->
        <!--                      </li>-->
        <!--                      <li (click)="edit(survey)">-->
        <!--                        <a><i class="fas fa-pen"></i></a>-->
        <!--                      </li>-->
        <!--                      <li (click)="detail(survey)">-->
        <!--                        <a><i class="far fa-chart-bar"></i></a>-->
        <!--                      </li>-->

        <!--                      <li>-->
        <!--                        <a (click)="-->
        <!--                                      copyUrl(-->
        <!--                                        survey.id,-->
        <!--                                        survey.surveyType,-->
        <!--                                        survey?.language-->
        <!--                                      )-->
        <!--                                    ">-->
        <!--                          <input type="text" [value]="survey.id" [id]="'surveyIdCopy-' + i" hidden />-->
        <!--                          <i class="fas fa-link"></i>-->
        <!--                        </a>-->
        <!--                      </li>-->
        <!--                    </ul>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </ng-template>-->
        <!--            </ng-container>-->
        <!--          </owl-carousel-o>-->
        <!--        </div>-->

        <div class="carousel-currently-live">
          <div class="row justify-content-center">
            <div
              class="col-md-6 col-sm-10"
              *ngFor="let survey of visibleSurveys; let i = index"
            >
              <div class="container survey-title-box" [id]="survey.id">
                <div class="row">
                  <div class="col-6">
                    <div class="row title-text-survey">
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        class="surv-tit"
                        disabled
                        >{{ survey?.title }}</textarea
                      >
                      <div class="col-12 use-this-feature">
                        {{ "newDasboard.Usethisfeature" | translate }}
                      </div>
                      <div class="col-12">
                        <div class="row svgicons-row margin-top-10">
                          <button
                            class="col svg-bg-g text-center"
                            (click)="stopSurvey(survey)"
                          >
                            <a *ngIf="survey.isPublished" class="stop"
                              ><i class="fas fa-square video"></i
                            ></a>
                            <a class="play" *ngIf="survey.isPublished == false">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 15 16"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.2136 7.07542L2.42591 0.979357C1.46816 0.484287 0.00140774 0.964712 0.00140774 2.18919V14.3784C0.00140774 15.4769 1.36436 16.139 2.42591 15.5882L14.2136 9.4951C15.2651 8.95317 15.2684 7.61736 14.2136 7.07542Z"
                                  fill="url(#paint0_linear_1193_494)"
                                />
                                <defs>
                                  <linearGradient
                                    id="paint0_linear_1193_494"
                                    x1="15"
                                    y1="0.784058"
                                    x2="0"
                                    y2="0.784058"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#FF716F" />
                                    <stop offset="1" stop-color="#FFAE3B" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </a>
                            <div class="font-8">
                              {{ "newDasboard.video" | translate }}
                            </div>
                          </button>
                          <button
                            class="col svg-bg-g text-center"
                            (click)="edit(survey)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 25 23"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M24.3915 5.35233C24.9108 4.87558 24.9108 4.10545 24.3915 3.6287L21.2755 0.768204C20.7562 0.291458 19.9173 0.291458 19.398 0.768204L17.7636 2.2686C17.332 2.66486 17.332 3.34565 17.7636 3.74191L21.2784 6.96855C21.6609 7.31967 22.2485 7.31967 22.6309 6.96855L24.3915 5.35233ZM1.13232 17.5363C0.926024 17.7257 0.808594 17.9929 0.808594 18.273V21.4177C0.808594 21.97 1.25631 22.4177 1.80859 22.4177H5.41265C5.66309 22.4177 5.90442 22.3237 6.08891 22.1543L19.727 9.6342C20.1587 9.23794 20.1587 8.55715 19.727 8.16089L16.2123 4.93425C15.8298 4.58312 15.2422 4.58313 14.8597 4.93425L1.13232 17.5363Z"
                                fill="url(#paint0_linear_1193_488)"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_1193_488"
                                  x1="24.7812"
                                  y1="0.410645"
                                  x2="0.808594"
                                  y2="0.410645"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#FF716F" />
                                  <stop offset="1" stop-color="#FFAE3B" />
                                </linearGradient>
                              </defs>
                            </svg>
                            <div class="font-8">
                              {{ "newDasboard.editt" | translate }}
                            </div>
                          </button>
                          <button
                            class="col svg-bg-g text-center"
                            (click)="detail(survey)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 21 26"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.12607 25.0142H8.85991C9.11052 25.0142 9.31555 24.782 9.31555 24.4982V0.764692C9.31555 0.480918 9.11052 0.248744 8.85991 0.248744H6.12607C5.87546 0.248744 5.67042 0.480918 5.67042 0.764692V24.4982C5.67042 24.782 5.87546 25.0142 6.12607 25.0142Z"
                                fill="url(#paint0_linear_1193_480)"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.661549 25.0142H3.3954C3.646 25.0142 3.85104 24.7819 3.85104 24.498V15.2069C3.85104 14.923 3.646 14.6908 3.3954 14.6908H0.661549C0.410943 14.6908 0.205907 14.923 0.205907 15.2069V24.498C0.205907 24.7819 0.410943 25.0142 0.661549 25.0142Z"
                                fill="url(#paint1_linear_1193_480)"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.6023 25.0142H14.3247C14.581 25.0142 14.7861 24.7821 14.7861 24.4919V11.0944C14.7861 10.8043 14.581 10.5722 14.3247 10.5722H11.6023C11.346 10.5722 11.141 10.8043 11.141 11.0944V24.4919C11.141 24.7821 11.346 25.0142 11.6023 25.0142Z"
                                fill="url(#paint2_linear_1193_480)"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.6055 4.89865V24.4917C16.6055 24.782 16.8105 25.0141 17.0668 25.0141H19.7893C20.0456 25.0141 20.2506 24.782 20.2506 24.4917V4.89865C20.2506 4.60843 20.0456 4.37626 19.7893 4.37626H17.0668C16.8105 4.37626 16.6055 4.60843 16.6055 4.89865Z"
                                fill="url(#paint3_linear_1193_480)"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_1193_480"
                                  x1="9.31782"
                                  y1="0.252563"
                                  x2="5.67383"
                                  y2="0.252563"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#FF716F" />
                                  <stop offset="1" stop-color="#FFAE3B" />
                                </linearGradient>
                                <linearGradient
                                  id="paint1_linear_1193_480"
                                  x1="3.85102"
                                  y1="14.6946"
                                  x2="0.207031"
                                  y2="14.6946"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#FF716F" />
                                  <stop offset="1" stop-color="#FFAE3B" />
                                </linearGradient>
                                <linearGradient
                                  id="paint2_linear_1193_480"
                                  x1="14.7827"
                                  y1="10.5682"
                                  x2="11.1387"
                                  y2="10.5682"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#FF716F" />
                                  <stop offset="1" stop-color="#FFAE3B" />
                                </linearGradient>
                                <linearGradient
                                  id="paint3_linear_1193_480"
                                  x1="20.2495"
                                  y1="4.37878"
                                  x2="16.6055"
                                  y2="4.37878"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#FF716F" />
                                  <stop offset="1" stop-color="#FFAE3B" />
                                </linearGradient>
                              </defs>
                            </svg>
                            <div class="font-8">
                              {{ "newDasboard.statistics" | translate }}
                            </div>
                          </button>
                          <button
                            class="col svg-bg-g text-center"
                            (click)="
                              copyUrl(
                                survey.id,
                                survey.surveyType,
                                survey?.language
                              )
                            "
                          >
                            <input
                              type="text"
                              [value]="survey.id"
                              [id]="'surveyIdCopy-' + i"
                              hidden
                            />
                            <!--                            <i class="fas fa-link"></i>-->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 19 20"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.8849 2.2199C16.8849 2.2199 16.8849 2.2199 16.8849 2.2199C14.7926 0.019133 11.388 0.0188373 9.2954 2.2199L6.92279 4.71546C6.91855 4.71992 6.91397 4.72474 6.91008 4.7292C4.84231 6.91623 4.81332 10.4772 6.92279 12.6983C7.14603 12.9331 7.38847 13.1468 7.64732 13.3369C7.87335 13.5028 8.17918 13.4776 8.37407 13.2726L9.11742 12.4907C9.41223 12.1806 9.54441 11.7762 9.53044 11.3838C9.52358 11.1958 9.43264 11.0222 9.28516 10.9157C9.18235 10.8412 9.05073 10.7346 8.92006 10.5974C7.92889 9.55491 7.92473 7.86276 8.92006 6.81585L11.2927 4.32066C12.2897 3.27196 13.9159 3.27828 14.9054 4.3396C15.8852 5.39055 15.8542 7.08523 14.8646 8.12601L14.4026 8.61202C14.249 8.77356 14.1989 9.01272 14.269 9.22893C14.4761 9.86745 14.5883 10.5287 14.6111 11.1868C14.6291 11.7032 15.2274 11.9461 15.5748 11.5807L16.8849 10.2027C18.9775 8.00202 18.9776 4.4206 16.8849 2.2199C16.8849 2.21989 16.8849 2.21989 16.8849 2.2199ZM11.9105 7.4538C11.9105 7.4538 11.9105 7.45381 11.9105 7.45381C14.02 9.6749 13.991 13.2359 11.9232 15.4229C11.9193 15.4273 11.9147 15.4322 11.9105 15.4366L9.53789 17.9322C7.44527 20.1333 4.04068 20.133 1.94837 17.9322C-0.14425 15.7315 -0.14425 12.1501 1.94837 9.94936L3.25846 8.57138C3.60588 8.20596 4.20419 8.44883 4.22213 8.96525C4.245 9.62338 4.35721 10.2846 4.56425 10.9231C4.63437 11.1393 4.58427 11.3785 4.43069 11.54L3.96863 12.026C2.97912 13.0668 2.94809 14.7615 3.92785 15.8124C4.91728 16.8738 6.54358 16.8801 7.54061 15.8314L9.91321 13.3362C10.9085 12.2893 10.9044 10.5971 9.91321 9.5546C9.78254 9.41742 9.65092 9.31084 9.54811 9.23638C9.40062 9.12985 9.30968 8.95628 9.30283 8.76824C9.28885 8.37582 9.42104 7.97144 9.71585 7.66135L10.4592 6.87945C10.6541 6.67442 10.9599 6.64924 11.1859 6.81517C11.4448 7.00529 11.6873 7.21898 11.9105 7.45379C11.9105 7.4538 11.9105 7.4538 11.9105 7.4538Z"
                                fill="url(#paint0_linear_1193_473)"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_1193_473"
                                  x1="18.4544"
                                  y1="0.569214"
                                  x2="0.378906"
                                  y2="0.569214"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#FF716F" />
                                  <stop offset="1" stop-color="#FFAE3B" />
                                </linearGradient>
                              </defs>
                            </svg>
                            <div class="font-8">
                              {{ "newDasboard.web" | translate }}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 remove-padding">
                    <div
                      class="row survey-title-area-two justify-content-center"
                      style="align-items: center; padding-top: 15px"
                    >
                      <div class="col-3 tot-day-a-2">
                        <h3>{{ survey?.endDate | date : "dd" }}</h3>
                        <span>{{ "newDasboard.total_days" | translate }}</span>
                      </div>
                      <div class="col-4 text-center timer-time">
                        <div>
                          <app-counter
                            [showSeparator]="true"
                            [showDays]="false"
                            [dates]="{
                              startDate: survey?.startDate,
                              endDate: survey?.endDate
                            }"
                            countLabelClass="font-small"
                          ></app-counter>
                        </div>
                      </div>
                      <div class="col-2 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="17"
                          viewBox="0 0 21 17"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.2843 1.1624C18.2843 1.1624 18.2843 1.16239 18.2843 1.16239C16.1437 -0.609662 12.9602 -0.290922 10.9954 1.67846C10.5705 2.10375 9.88123 2.10375 9.45636 1.67846C7.49546 -0.290925 4.30802 -0.609663 2.16743 1.1624C-0.285643 3.19629 -0.41455 6.84667 1.78073 9.05131L9.33917 16.6329C9.82744 17.1224 10.6204 17.1224 11.1087 16.6329L18.6671 9.05131C20.8663 6.84666 20.7374 3.1963 18.2843 1.1624C18.2843 1.1624 18.2843 1.1624 18.2843 1.1624Z"
                            fill="#FC324A"
                          />
                        </svg>
                        <h6 class="font-11">{{ survey.likes }}</h6>
                      </div>
                      <div class="col-2 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="22"
                          viewBox="0 0 23 22"
                          fill="none"
                        >
                          <path
                            d="M6.82647 7.09487C6.72847 8.07486 6.6597 9.80919 7.27692 10.5474C7.27692 10.5474 6.98636 8.64222 9.59108 6.25188C10.6398 5.28962 10.8823 3.98081 10.5161 2.99921C10.308 2.44313 9.92806 1.98375 9.59796 1.663C9.4054 1.47442 9.55325 1.16333 9.8335 1.17462C11.5287 1.24554 14.2761 1.68718 15.4435 4.43374C15.9559 5.63938 15.9937 6.88533 15.7496 8.15223C15.5948 8.96137 15.0447 10.7602 16.2997 10.981C17.1955 11.1389 17.6287 10.4717 17.823 9.99133C17.9038 9.79146 18.1841 9.74149 18.3354 9.90106C19.8483 11.5145 19.9773 13.4149 19.6644 15.0509C19.0592 18.2133 15.643 20.515 12.2491 20.515C8.00934 20.515 4.63438 18.2407 3.75926 14.1241C3.40681 12.4623 3.58561 9.17413 6.31928 6.85309C6.52215 6.67901 6.85398 6.83375 6.82647 7.09487Z"
                            fill="url(#paint0_radial_1193_451)"
                          />
                          <path
                            d="M13.7983 13.0087C12.2354 11.1229 12.9352 8.97108 13.3186 8.11359C13.3702 8.00076 13.2326 7.89438 13.1243 7.96369C12.4521 8.39243 11.0749 9.40144 10.4336 10.8215C9.56538 12.7412 9.62728 13.6808 10.1413 14.8285C10.4508 15.5199 10.0915 15.6666 9.91096 15.6924C9.73559 15.7182 9.57398 15.6086 9.44503 15.4942C9.07539 15.1637 8.8089 14.7334 8.68167 14.2692C8.65416 14.1692 8.5149 14.1418 8.45129 14.224C7.96989 14.8478 7.72059 15.8488 7.70856 16.5564C7.67073 18.7436 9.59805 20.5166 11.9294 20.5166C14.8677 20.5166 17.0082 17.4703 15.3198 14.9236C14.8298 14.1821 14.3691 13.697 13.7983 13.0087Z"
                            fill="url(#paint1_radial_1193_451)"
                          />
                          <defs>
                            <radialGradient
                              id="paint0_radial_1193_451"
                              cx="0"
                              cy="0"
                              r="1"
                              gradientUnits="userSpaceOnUse"
                              gradientTransform="translate(11.4094 20.5651) rotate(-179.767) scale(12.136 18.6683)"
                            >
                              <stop offset="0.3144" stop-color="#FF9800" />
                              <stop offset="0.6616" stop-color="#FF6D00" />
                              <stop offset="0.9715" stop-color="#F44336" />
                            </radialGradient>
                            <radialGradient
                              id="paint1_radial_1193_451"
                              cx="0"
                              cy="0"
                              r="1"
                              gradientUnits="userSpaceOnUse"
                              gradientTransform="translate(12.091 9.2433) rotate(90.6173) scale(11.9045 9.55614)"
                            >
                              <stop offset="0.2141" stop-color="#FFF176" />
                              <stop offset="0.3275" stop-color="#FFF27D" />
                              <stop offset="0.4868" stop-color="#FFF48F" />
                              <stop offset="0.6722" stop-color="#FFF7AD" />
                              <stop offset="0.7931" stop-color="#FFF9C4" />
                              <stop
                                offset="0.8221"
                                stop-color="#FFF8BD"
                                stop-opacity="0.804"
                              />
                              <stop
                                offset="0.8627"
                                stop-color="#FFF6AB"
                                stop-opacity="0.529"
                              />
                              <stop
                                offset="0.9101"
                                stop-color="#FFF38D"
                                stop-opacity="0.2088"
                              />
                              <stop
                                offset="0.9409"
                                stop-color="#FFF176"
                                stop-opacity="0"
                              />
                            </radialGradient>
                          </defs>
                        </svg>
                        <h6 class="font-11">
                          {{ "newDasboard.trend" | translate }}
                        </h6>
                      </div>
                      <div class="col-12 margin-top-10"></div>
                      <div class="col-4 text-center box-surv">
                        <div class="green-div">
                          {{ "newDasboard.RespondingNow" | translate }}
                        </div>
                        <div class="white-div-bot font-13">
                          {{ survey?.point || 0 }}
                        </div>
                      </div>
                      <div class="col-2 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                          *ngIf="
                            darkThemeService.getCurrentTheme === 'light-mode'
                          "
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.6386 8.92907L11.36 14.2077C10.9744 14.5933 10.9738 15.2171 11.3583 15.6015C11.7454 15.9887 12.3668 15.9852 12.7522 15.5998L19.7095 8.64246L19.7104 8.6416L19.7112 8.64074C19.9045 8.44747 20.0004 8.19581 20.0001 7.94428C19.9991 7.69212 19.9032 7.44051 19.7112 7.24858L19.7104 7.24772L19.7095 7.24686L12.7522 0.289502C12.3666 -0.0960982 11.7427 -0.0966533 11.3583 0.287781C10.9712 0.674896 10.9746 1.29628 11.36 1.68166L16.6386 6.96025H1.60007C1.05815 6.96025 0.614258 7.40099 0.614258 7.94466C0.614258 8.49212 1.05562 8.92907 1.60007 8.92907H16.6386Z"
                            fill="#1D1D49"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                          *ngIf="
                            darkThemeService.getCurrentTheme === 'dark-mode'
                          "
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.0243 9.81701L10.7457 15.0956C10.3601 15.4812 10.3596 16.105 10.744 16.4895C11.1311 16.8766 11.7525 16.8731 12.1379 16.4878L19.0952 9.5304L19.0961 9.52954L19.097 9.52868C19.2902 9.33541 19.3862 9.08375 19.3858 8.83221C19.3849 8.58006 19.2889 8.32845 19.097 8.13652L19.0961 8.13566L19.0952 8.1348L12.1379 1.17744C11.7523 0.791841 11.1284 0.791286 10.744 1.17572C10.3569 1.56283 10.3603 2.18422 10.7457 2.5696L16.0243 7.84819H0.985813C0.443893 7.84819 0 8.28893 0 8.8326C0 9.38006 0.441363 9.81701 0.985813 9.81701H16.0243Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div class="col-4 text-center box-surv">
                        <div class="orange-div">
                          {{ "newDasboard.MaxResponses" | translate }}
                        </div>
                        <div class="font-13 orng-div-bot">
                          {{ survey?.totalResponse || 0 }} RSP
                        </div>
                      </div>
                      <div class="col-4 text-center box-surv">
                        <div class="green-div">
                          {{ "newDasboard.SpendingCash" | translate }}
                        </div>
                        <div class="white-div-bot font-13">
                          {{ survey?.spendingPoints || 0 }}
                          {{ "survey.SAR" | translate }}
                        </div>
                      </div>
                      <div class="col-2 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="17"
                          viewBox="0 0 20 17"
                          fill="none"
                          *ngIf="
                            darkThemeService.getCurrentTheme === 'light-mode'
                          "
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.97567 9.81688L9.25426 15.0955C9.63986 15.4811 9.64042 16.1049 9.25599 16.4894C8.86887 16.8765 8.24749 16.873 7.86211 16.4876L0.904751 9.53028L0.903889 9.52942L0.903028 9.52856C0.709759 9.33529 0.61384 9.08363 0.614199 8.83209C0.615126 8.57994 0.711098 8.32833 0.903028 8.1364L0.903889 8.13554L0.904751 8.13468L7.86211 1.17732C8.24771 0.791719 8.87155 0.791164 9.25599 1.1756C9.6431 1.56271 9.63965 2.18409 9.25426 2.56948L3.97567 7.84807H19.0142C19.5561 7.84807 20 8.28881 20 8.83248C20 9.37994 19.5586 9.81688 19.0142 9.81688H3.97567Z"
                            fill="#1D1D49"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                          *ngIf="
                            darkThemeService.getCurrentTheme === 'dark-mode'
                          "
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.36141 8.92919L8.64001 14.2078C9.02561 14.5934 9.02616 15.2172 8.64173 15.6017C8.25461 15.9888 7.63323 15.9853 7.24785 15.5999L0.290493 8.64258L0.289631 8.64172L0.288771 8.64086C0.0955009 8.44759 -0.000417709 8.19593 -5.91278e-05 7.9444C0.000867844 7.69224 0.0968399 7.44063 0.288771 7.2487L0.289631 7.24784L0.290493 7.24698L7.24785 0.289624C7.63345 -0.0959762 8.25729 -0.0965312 8.64173 0.287903C9.02884 0.675018 9.02539 1.2964 8.64001 1.68178L3.36141 6.96038H18.3999C18.9418 6.96038 19.3857 7.40111 19.3857 7.94478C19.3857 8.49224 18.9444 8.92919 18.3999 8.92919H3.36141Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div class="col-4 text-center box-surv">
                        <div class="orange-div">
                          {{ "newDasboard.MaxBudget" | translate }}
                        </div>
                        <div class="font-13 orng-div-bot">
                          0 {{ "survey.SAR" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
            <div class="row justify-content-center">
              <div class="col-sm-6 col-10">
                <button class="next-prev-btn" (click)="prev()">
                  {{ "newDasboard.Previous" | translate }}
                </button>
                <button class="next-prev-btn" (click)="next()">
                  {{ "newDasboard.Next" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="card-customerize">
      <div class="mb-3">
        <div
          class="d-md-flex justify-content-between align-items-center f-d-col table-text"
        >
          <div class="date-container">
            <div class="date-input-container align-setting">
              <input type="date" class="date-input" id="startDate" />
            </div>
            <div class="date-input-container align-setting mt-md-0 mt-2">
              <input
                type="date"
                class="date-input"
                id="endDate"
                (change)="date()"
              />
            </div>
          </div>
          <div class="new-title mt-2 mt-md-0">
            {{ "newDasboard.analytics" | translate }}
          </div>
          <div class="text-md-right text-center w-md-25 w-auto">
            <div class="btn-group">
              <button
                type="button"
                class="btn dropdown-toggle dropdown1 fix-width new-text"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ "newDasboard.RecentSurvey" | translate }}
              </button>
              <div class="dropdown-menu" style="right: 0px">
                <a
                  class="dropdown-item"
                  (click)="filterSurvey('isPublished', true)"
                  >{{ "newDasboard.isPublished" | translate }}</a
                >
                <a
                  class="dropdown-item"
                  (click)="filterSurvey('isRejected?', true)"
                  >{{ "newDasboard.Rejected" | translate }}</a
                >
                <a
                  class="dropdown-item"
                  (click)="filterSurvey('isFeatured', true)"
                  >{{ "newDasboard.Featured" | translate }}</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="AllUserSurvey()">{{
                  "newDasboard.all" | translate
                }}</a>
              </div>
            </div>
            <br />

            <!-- <a class="arrowcontent new-text" href="surveys">
              <p class="new-text">
                {{ "newDasboard.fullViewList" | translate }}
                <i class="fas fa-arrow-right text-body"></i>
              </p>
            </a> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive table-card">
          <table class="table" borderSpacing="0" border="none">
            <thead>
              <tr class="dashed-tr">
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.SurveyName" | translate }}
                </th>
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.MaxRSP" | translate }}
                </th>
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.MaxSar" | translate }}
                </th>
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.KAFU RSP" | translate }}
                </th>
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.generalRES" | translate }}
                </th>
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.startDate" | translate }}
                </th>
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.EndDate" | translate }}
                </th>
                <!-- <th scope="col" class="font-size-10" ">
                {{ "newDasboard.CurrentSpreading" | translate }}
              </th> -->
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.Clicks" | translate }}
                </th>
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.LikesCount" | translate }}
                </th>
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.Featured" | translate }}
                </th>
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.Rejected" | translate }}
                </th>
                <th scope="col" class="font-size-10">
                  {{ "newDasboard.Publish" | translate }}
                </th>

                <th scope="col" class="font-size-10">
                  {{ "newDasboard.Action" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="align-items-center dashed"
                *ngFor="let survey of tableSurvey; let i = index"
              >
                <ng-container *ngIf="showAllSurveys || i < 3">
                  <td>
                    <div class="d-flex align-items-center">
                      <div
                        style="
                          padding: 5px;
                          background: #fff;
                          border-radius: 4px;
                        "
                        class="mr-2"
                      >
                        <img
                          src="{{
                            survey?.surveyImage
                              ? survey.surveyImage
                              : './assets/imgs/new-sidebar/kafu.svg'
                          }}"
                          alt="Image"
                          width="30"
                        />
                      </div>
                      <!-- Replace image_url.jpg with your image URL -->
                      <span>{{ survey.title }}</span>
                      <!-- Replace "Name" with the actual name -->
                    </div>
                  </td>
                  <td>{{ survey?.totalResponse }}</td>
                  <td>{{ survey?.totalPoints || 0 }}</td>
                  <td>{{ survey?.kafuRes || 0 }}</td>
                  <td>{{ survey?.generalRes || 0 }}</td>
                  <td>{{ moment(survey?.startDate).format("ll") }}</td>
                  <td>{{ moment(survey?.endDate).format("ll") }}</td>
                  <!-- <td>{{ survey?.spendingPoints }}</td> -->
                  <td>{{ survey?.click ? survey?.click : "0" }}</td>
                  <td>{{ survey?.likes }}</td>
                  <td>
                    {{
                      survey.isFeatured
                        ? ("survey.Yes" | translate)
                        : ("survey.No" | translate)
                    }}
                  </td>
                  <td>
                    {{
                      survey.isPublish
                        ? ("survey.Yes" | translate)
                        : ("survey.No" | translate)
                    }}
                  </td>
                  <td>
                    {{
                      survey.isPublished
                        ? ("survey.Yes" | translate)
                        : ("survey.No" | translate)
                    }}
                  </td>
                  <td class="action-table">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      class="text-center cursor-pointer"
                    >
                      <img
                        src="./assets/imgs/new-sidebar/setting.png"
                        width="30px"
                      />
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="edit(survey)">
                        {{ "newDasboard.edit" | translate }}
                      </button>
                      <button mat-menu-item (click)="stopSurvey(survey)">
                        <span *ngIf="survey.isPublished">
                          {{ "newDasboard.Stop" | translate }}
                        </span>
                        <span *ngIf="survey.isPublished == false">
                          {{ "newDasboard.Play" | translate }}
                        </span>
                      </button>
                      <button mat-menu-item (click)="detail(survey)">
                        {{ "newDasboard.DetailSurvey" | translate }}
                      </button>
                      <button mat-menu-item (click)="deleteSurvey(survey)">
                        {{ "newDasboard.delete" | translate }}
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
          <div
            class="row justify-content-center"
            *ngIf="tableSurvey.length > 3"
          >
            <button
              class="m-btn btn-view-more"
              (click)="toggleShowAllSurveys()"
            >
              {{ !showAllSurveys ? "view More" : "view Less" }}
              <i
                class="fas"
                [ngClass]="
                  showAllSurveys
                    ? 'fa-arrow-circle-up iconColor font-40'
                    : 'fa-arrow-circle-down iconColor font-40'
                "
              ></i>
            </button>
          </div>
          <div class="row justify-content-center">
            <div class="new-title" *ngIf="userSurveys.length == 0">
              {{ "newDasboard.NoData" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--tab section-->
</div>
<app-loader [show]="showLoader"></app-loader>
