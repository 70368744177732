import { Component, OnInit, Renderer2 } from '@angular/core';
import { DarkLiteThemeService } from "../../../services/dark-lite-themeService.service";
import { PlanService } from 'src/services/plan.service';
import { sha256 } from "js-sha256"; //Lib For Encription
import { ResponseCode } from "src/constant/response-code";

import { LanguageService } from 'src/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { eLanguage } from "src/app/general/enums/language.enum";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PluginService } from 'src/services/pluginservice.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { RechargeService } from 'src/services/recharge.service';
import { first } from 'rxjs/operators';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  onClose: any;
  data = []
  elanguage = eLanguage;

  newData = []
  language: any;
  type: any;
  redirectURL = "";
  toggleValue: boolean = false;

  showLoader = false;
  getTerminalId = environment.GET_TERMINAL_ID;
  getPassword = environment.GET_PASSWORD;
  getMerchantKey = environment.GET_MERCHANT_KEY
  serverUrl = environment.SERVER_URL
  router: any;

  constructor(public darkThemeService: DarkLiteThemeService, private planService: PlanService, private languageService: LanguageService,
    private translateService: TranslateService, private renderer: Renderer2, public modal: BsModalRef,
    private authService: AuthService,
    private services: PluginService,
    private route: Router,
    private rechargeService: RechargeService,
    private toastr: ToastrService,
  ) {
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    console.log("language", this.language);
    this.type = this.authService?.currentUser?.planId?._id
  }

  ngOnInit(): void {
    this.getPlan()
    this.getOrderId();

  }
  ngAfterViewInit() {
    const theme = this.darkThemeService.getCurrentTheme;
    const elements = document.querySelectorAll(".modal-backdrop.fade.in.show");
    const backgroundColor = theme === "dark-mode" ? "#000" : "#000";
    elements.forEach((element) => {
      this.renderer.setStyle(element, "background-color", backgroundColor);
    });
  }
  close(data = null) {
    this.onClose(data);
  }
  getPlan() {
    this.planService.getMemberShip().subscribe((result) => {
      this.newData = result;
      this.data = result.filter((item) => (item.title.includes("Free basic") || item.title == "أساسية مجانية"
        || item.planType === "Monthly" || item.planType === "شهريا"));
    });
  }

  toggleValueChanged() {
    // Handle the toggle value change here
    if (this.toggleValue == false) {
      this.data = this.newData.filter((item) => (item.title.includes("Free basic") || item.title.includes("أساسية مجانية")
        || item.planType === "Monthly" || item.planType === "شهريا"))
    } else {
      this.data = this.newData.filter((item) => (item.title.includes("Free basic") || item.title.includes("أساسية مجانية") || item.planType == "Yearly" || item.planType === "سنوي"))
    }
  }
  recharge(plan: any) {
    if (this.authService.currentUser.planId?._id == plan.id) {
      this.translateService
        .get("you  already have this plan")
        .subscribe((text) => {
          this.toastr.error(text);
        });
    } else {
      const id = plan.id
      // this.planService.postPlan(id).subscribe((result) => {
      //   this.authService.setCurrentUser(result, null)

      // });
      console.log(plan, "plan");
      this.planService.createLocalPlanModal(plan);
      if (plan.type == "free") {
        let data = {
          PaymentId: "0000",
          cardType: "N/A",
          PaymentType: "PaymentType[1]",
          responseHash: "responseHash[1]",
          amount: Number(0),
          trackingId: this.getOrderId(),
          title: plan.title,
          plan: plan.id,
          transactionId: "111"
        };
        this.planService.postPlan(id).subscribe((result) => {
          this.authService.setCurrentUser(result, null)

        });
        this.rechargeService
          .rechargeBalance(data)
          .pipe(first())
          .subscribe((result) => {
            this.showLoader = false;
            if (result) {
              this.authService.setWalletRates(result);
              // this.getUpdatedWallet();
              // this.getTransaction()
              this.close()
              // this.translateService
              //   .get("wallet.recharge_success_message")
              //   .subscribe((text) => {
              //     this.toastr.success(text);
              //   });
            }

          }
          )
      } else {

        this.recharging(plan);
      }
    }
  }

  generateHash(arg: string) {
    // hashSequence = posted["trackid"]+"|"+posted["terminalId"]+"|"+posted["password"]+"|"+posted["secret"]+"|"+posted["amount"]+"|"+posted["currency"];
    var hashSequence = arg;
    return sha256(hashSequence);
  }
  getOrderId() {
    return Math.random().toString().slice(2, 5);
  }
  recharging(plan: any) {
    // if (this.isChecked === false) {
    //   this.translateService
    //     .get("plan.error_message")
    //     .subscribe((text) => {
    //       this.toastr.error(text);
    //     });
    // }
    let trackingId = this.getOrderId()
    // hashSequence = posted["trackid"]+"|"+posted["terminalId"]+"|"+posted["password"]+"|"+posted["secret"]+"|"+posted["amount"]+"|"+posted["currency"];
    let hashSequence = trackingId + "|" + this.getTerminalId + "|" + this.getPassword + "|" + this.getMerchantKey + "|" + plan.totalAmount + "|" + "SAR";
    console.log(hashSequence, "hashSequence")
    let hash = this.generateHash(hashSequence);
    let paymentForm = {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      trackid: trackingId,
      terminalId: this.getTerminalId,
      customerEmail: this.authService.currentUser?.email || "support@kafuapp.com",
      action: "1",
      merchantIp: "9.10.10.102",
      password: this.getPassword,
      currency: "SAR",
      country: "SA",
      transid: "",
      tokenOperation: "",
      cardToken: "",
      tokenizationType: "0",
      amount: plan.totalAmount,
      requestHash: hash,
      udf1: "profile",
      udf2: "profile#wallet",
      udf3: "",
      udf4: plan.id,
      udf5: plan.id,
    };
    debugger;
    this.services.paymentAPI(paymentForm).subscribe(async (res) => {
      let getResponse = JSON.parse(JSON.stringify(res));
      if (ResponseCode.response_msg[getResponse["responseCode"]]) {
        console.log(getResponse, "getResponse");
        this.route.navigate(["payment-status"], {
          queryParams: getResponse,
        });
      } else {
        window.location.assign(
          getResponse["targetUrl"].replace("?", "") +
          "?paymentid=" +
          getResponse["payid"]
        );

      }
      // this.redirectURL =
      //   getResponse["targetUrl"].replace("?", "") +
      //   "?paymentid=" +
      //   getResponse["payid"];
    });
  }

}
