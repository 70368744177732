import { HomeService } from "./../../../services/home.service";
import { AfterViewInit, Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { LanguageService } from "../../../services/language.service";
import { SurveyService } from "../../../services/survey.service";
import { Router } from "@angular/router";
import { SurveyTypes } from "src/app/general/enums/survey-types.enum";
import { eLanguage } from "src/app/general/enums/language.enum";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { AuthService } from "src/services/auth.service";

@Component({
  selector: "app-create-survey",
  templateUrl: "./create-survey.component.html",
  styleUrls: ["./create-survey.component.css"],
})
export class CreateSurveyComponent implements OnInit, AfterViewInit {
  showLoader = false;
  onClose: any;
  language: any;
  form: FormGroup;
  categories = [];
  locale = eLanguage.Short_English || eLanguage.Short_Arabic;

  activeStep = 1;
  error: string;
  isImageUploaded: boolean = false;
  elanguage = eLanguage;

  constructor(
    public languageService: LanguageService,
    public darkThemeService: DarkLiteThemeService,
    private formBuilder: FormBuilder,
    private surveyService: SurveyService,
    private translateService: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    public modal: BsModalRef,
    private homeService: HomeService,
    private renderer: Renderer2,
    private authService: AuthService,

  ) {
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    console.log("language", this.language);
  }

  ngOnInit() {
    this.getSurveyCategories();
    this.form = this.formBuilder.group({
      language: [this.locale, Validators.required],
      title: [null, Validators.required],
      categoryId: [null, Validators.required],
      description: [null, Validators.required],
      surveyType: [SurveyTypes.NORMAL, Validators.required],
      surveyImageUrl: [null],
      surveyImage: [null],
    });
  }

  ngAfterViewInit() {
    const theme = this.darkThemeService.getCurrentTheme;
    const elements = document.querySelectorAll(".modal-backdrop.fade.in.show");
    const backgroundColor = theme === "dark-mode" ? "#000" : "#000";
    elements.forEach((element) => {
      this.renderer.setStyle(element, "background-color", backgroundColor);
    });
  }

  getSurveyCategories() {
    this.surveyService.getSurveyCategories().subscribe((result) => {
      this.categories = result;
      this.showLoader = false;
    });
  }

  close(Result = null) {
    this.onClose(Result);
  }

  handleCreateSurvey() {
    if (this.form.valid) {
      this.showLoader = true;
      localStorage.removeItem("survey");
      this.surveyService.createLocalSurvey({
        ...this.form.value,
        language: this.locale,
      });
      this.showLoader = false;
      this.close();
      this.router.navigate(["/create-second-custom-survey"]);
    } else {
      for (let control in this.form.controls) {
        this.form.get(control).markAsDirty();
      }
    }
  }

  changeSurveyLang(event, lang) {
    if (event.target.checked) {
      this.form.get("language").setValue(lang);
      this.locale = lang;
    }
  }

  tabClick(tabNo) {
    if (tabNo == 3) {
      this.authService.clearSurvey()
    }
    this.activeStep = tabNo;
  }

  async onFileSelect(files: File[]) {
    if (files.length) {
      let file = files[0];

      if (file?.size >= 2000000) {
        this.translateService
          .get("survey.backgroundImageSize")
          .subscribe((text) => {
            this.toastr.warning(text);
          });
        this.isImageUploaded = false;
        return;
      }

      const base64 = await this.homeService.convertToBase64(files[0]);
      this.form.get("surveyImageUrl").setValue(base64);
      this.form.get("surveyImage").setValue(file);
      this.isImageUploaded = true;
    }
  }
}
