<div
  [ngClass]="{
    'dark-mode': darkThemeService.getCurrentTheme === 'dark-mode',
    'light-mode': darkThemeService.getCurrentTheme === 'light-mode',
    'd-flex': authService.isLoggedIn() == true,
    'd-block': authService.isLoggedIn() == false
  }"
>
  <app-header *ngIf="!authService.isLoggedIn()"></app-header>
  <app-new-sidebar
    style="border: 1px solid gray"
    class="side-bar"
    [ngClass]="{
      'sidebar-show': isSidebarVisible,
      'sidebar-hide': !isSidebarVisible,
      'border-ar': this.languageService.getLanguageOrDefault() === 'ar',
      'border-en': this.languageService.getLanguageOrDefault() !== 'ar'
    }"
    *ngIf="authService.isLoggedIn()"
  ></app-new-sidebar>
  <div
    class=""
    [ngClass]="
      this.languageService.getLanguageOrDefault() === 'ar'
        ? 'humburg-ar'
        : 'humburg'
    "
    (click)="toggleSidebar()"
  >
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 18L20 18"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M4 12L20 12"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M4 6L20 6"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>

  <div
    class="main-div-body"
    [ngClass]="{ 'adjust-height': authService.isLoggedIn() == true }"
  >
    <div class="w-100 wtk-header">
      <div class="header-div container">
        <div>
          <h1>{{ "preview.welcome" | translate }}</h1>
        </div>
        <div class="mt-2 mt-sm-0">
          <button
            class="ccsurvey-btn ccsurvey-btn-1 cursor-pointer"
            (click)="Back()"
          >
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
            <path
              d="M3.91406 21.8665H16.5868V11.4302H3.91406V21.8665ZM7.55252 13.4181H12.9483C13.2228 13.4181 13.4453 13.6405 13.4453 13.915C13.4453 14.1895 13.2228 14.412 12.9483 14.412H7.55252C7.27804 14.412 7.05555 14.1895 7.05555 13.915C7.05555 13.6405 7.27804 13.4181 7.55252 13.4181ZM7.55252 16.3999H12.9483C13.2228 16.3999 13.4453 16.6224 13.4453 16.8968C13.4453 17.1713 13.2228 17.3938 12.9483 17.3938H7.55252C7.27804 17.3938 7.05555 17.1713 7.05555 16.8968C7.05555 16.6224 7.27804 16.3999 7.55252 16.3999Z"
              fill="white" />
            <path
              d="M14.7228 0H5.77734V5.62634H14.7228V0ZM12.7262 3.7229C12.7262 3.99738 12.5037 4.21987 12.2292 4.21987C11.9547 4.21987 11.7322 3.99738 11.7322 3.7229V2.40065C11.7322 2.12617 11.9547 1.90368 12.2292 1.90368C12.5037 1.90368 12.7262 2.12617 12.7262 2.40065V3.7229Z"
              fill="white" />
            <path
              d="M15.7167 0.62828V6.12331C15.7167 6.39776 15.4944 6.62028 15.2197 6.62028H5.2803C5.00561 6.62028 4.78333 6.39776 4.78333 6.12331V0H1.49091C0.6675 0 0 0.6675 0 1.49091V20.3758C0 21.1992 0.6675 21.8667 1.49091 21.8667H2.9197V10.9333C2.9197 10.6589 3.14197 10.4364 3.41667 10.4364H17.0833C17.358 10.4364 17.5803 10.6589 17.5803 10.9333V21.8667H19.0091C19.8325 21.8667 20.5 21.1992 20.5 20.3758V4.9697L15.7167 0.62828Z"
              fill="white" />
          </svg> -->
            {{ "preview.Back" | translate }}
          </button>
          <button
            class="ccsurvey-btn ccsurvey-btn-2 cursor-pointer"
            (click)="Next()"
          >
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="31" height="21" viewBox="0 0 31 21" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15.2693 5.08979C14.815 5.09614 14.3636 5.16373 13.9274 5.29073C14.6705 6.30053 14.5645 7.70169 13.678 8.58822C12.7914 9.47475 11.3903 9.58074 10.3805 8.83767C9.80992 10.9397 10.6434 13.1715 12.4522 14.385C14.261 15.5984 16.6422 15.5232 18.3708 14.198C20.0994 12.8728 20.7904 10.5928 20.0883 8.53099C19.3862 6.46913 17.4474 5.08465 15.2693 5.08978V5.08979ZM15.269 17.8142C11.0525 17.8142 7.63431 14.396 7.63431 10.1795C7.63431 5.96301 11.0525 2.54485 15.269 2.54485C19.4855 2.54485 22.9037 5.96301 22.9037 10.1795C22.9057 14.3969 19.4863 17.8162 15.269 17.8142ZM30.3542 9.4055C27.479 3.7956 21.7869 0 15.2693 0C8.75175 0 3.05808 3.79825 0.184477 9.40602C-0.0614925 9.89257 -0.0614925 10.4671 0.184477 10.9536C3.05968 16.5635 8.75175 20.3591 15.2693 20.3591C21.7869 20.3591 27.4806 16.5609 30.3542 10.9531C30.6001 10.4666 30.6001 9.89204 30.3542 9.40549L30.3542 9.4055Z"
              fill="white" />
          </svg> -->
            {{ "preview.Next" | translate }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.0243 8.92919L10.7457 14.2078C10.3601 14.5934 10.3596 15.2172 10.744 15.6017C11.1311 15.9888 11.7525 15.9853 12.1379 15.5999L19.0952 8.64258L19.0961 8.64172L19.097 8.64086C19.2902 8.44759 19.3862 8.19593 19.3858 7.9444C19.3849 7.69224 19.2889 7.44063 19.097 7.2487L19.0961 7.24784L19.0952 7.24698L12.1379 0.289624C11.7523 -0.0959762 11.1284 -0.0965313 10.744 0.287903C10.3569 0.675018 10.3603 1.2964 10.7457 1.68178L16.0243 6.96038H0.985813C0.443893 6.96038 0 7.40111 0 7.94478C0 8.49224 0.441363 8.92919 0.985813 8.92919H16.0243Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="main-sec">
      <div class="row w-100 justify-content-center mx-auto">
        <div class="col-11 marg-top-30 pad-zero">
          <div
            class="row w-100 mx-auto justify-content-center c-survey-sec survey-title-format pad-bot"
          >
            <div class="col-12 text-center stf-title stf-title-3">
              {{ "preview.SurveyInformation" | translate }}
            </div>
            <div class="col-12">
              <div class="row justify-content-center">
                <div class="col-sm-9 col-12 marg-top-30">
                  <h2 class="option-box-title mleft-5">
                    {{ "preview.Brand" | translate }}
                  </h2>
                  <input
                    type="text"
                    name=""
                    placeholder="Best player 2021"
                    id=""
                    [(ngModel)]="surveyData.title"
                    [readonly]="true"
                    class="w-100 input-tag-survey"
                  />
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    class="w-100 input-tag-survey"
                    [(ngModel)]="surveyData.description"
                    [readonly]="true"
                  >
This questionnaire is to find out the best player of the year according to the Arab rating</textarea
                  >
                </div>
                <div
                  class="col-sm-3 col-12 text-center align-self-center"
                  *ngIf="surveyData.bgImage !== null"
                >
                  <!--                  <h2 class="option-box-title">Upload Image/logo</h2>-->
                  <!--                  <div class="upload-container" *ngIf="!imageSrc">-->
                  <!--                    <input type="file" id="fileInput" class="upload-input" (change)="onFileChange($event)">-->
                  <!--                    <label for="fileInput" class="upload-label">-->
                  <!--                      <span>+</span>-->
                  <!--                    </label>-->
                  <!--                  </div>-->
                  <div class="uploaded-image" *ngIf="surveyData.bgImage">
                    <img
                      class="img-thumbnail addquest-img"
                      [src]="surveyData.bgImage"
                      alt="Uploaded Image"
                    />
                    <!--                    <button class="btn btn-danger remove-image" (click)="removeImage()">X</button>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-11 marg-top-30">
          <ng-container *ngIf="surveyData">
            <ng-container
              *ngFor="
                let questionData of surveyData.multiQuestList;
                let i = index
              "
            >
              <div
                class="row justify-content-center c-survey-sec survey-title-format pad-bot mt-3"
              >
                <div class="col-12 stf-title stf-title-3 pos-rel">
                  {{ "survey.Question" | translate }} {{ i + 1 }}
                  {{ "survey.of" | translate }}
                  {{ "survey." + questionData.questionType | translate }}
                  <!--              <div class="pos-absolute">-->
                  <!--                <svg class="marg-5px" xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">-->
                  <!--                  <path d="M0.391306 12.6541L4.5 8.57121C4.75155 8.31952 5.05901 8.1797 5.42236 8.1797C5.75776 8.1797 6.09317 8.31952 6.34472 8.57121C6.84783 9.07457 6.84783 9.85759 6.34472 10.361L4.47205 12.1787H12.1863V4.43242L10.2857 6.30606C10.0342 6.55774 9.72671 6.69757 9.36336 6.69757C9 6.69757 8.69255 6.55774 8.441 6.30606C8.18944 6.05438 8.04969 5.7188 8.04969 5.38322C8.04969 5.04764 8.18944 4.71207 8.441 4.46038L12.5776 0.377525C13.0807 -0.125842 13.9193 -0.125842 14.4224 0.377525L18.5031 4.46038C18.7547 4.71207 18.8944 5.01968 18.8944 5.38322C18.8944 5.7188 18.7547 6.05438 18.5031 6.30606C18.2516 6.55774 17.9441 6.69757 17.5807 6.69757C17.2453 6.69757 16.9099 6.55774 16.6584 6.30606L14.7857 4.43242V12.2066H22.528L20.6553 10.3889C20.1522 9.88555 20.1522 9.07457 20.6553 8.57121C21.1584 8.06784 21.9689 8.06784 22.5 8.57121L26.6087 12.6541C26.8602 12.9057 27 13.2413 27 13.5769C27 13.9125 26.8602 14.2481 26.6087 14.4997L22.5 18.5826C22.2484 18.8343 21.941 18.9741 21.5776 18.9741C21.2422 18.9741 20.9068 18.8343 20.6553 18.5826C20.4037 18.3309 20.264 17.9953 20.264 17.6318C20.264 17.2683 20.4037 16.9327 20.6553 16.681L22.528 14.7794H14.7857V22.5536L16.6584 20.68C17.1615 20.1766 18 20.1766 18.5031 20.68C18.7547 20.9316 18.8944 21.2672 18.8944 21.6028C18.8944 21.9384 18.7547 22.274 18.5031 22.5256L14.3944 26.6085C14.1429 26.8602 13.8354 27 13.4721 27C13.1366 27 12.8012 26.8602 12.5497 26.6085L8.46894 22.5256C7.96584 22.0223 7.96584 21.1833 8.46894 20.68C8.97205 20.1766 9.81056 20.1766 10.3137 20.68L12.2143 22.5536V14.8074H4.47205L6.34472 16.709C6.59628 16.9606 6.73603 17.2962 6.73603 17.6318C6.73603 17.9953 6.59628 18.3309 6.34472 18.5546C6.09317 18.8063 5.75776 18.9461 5.42236 18.9461C5.08696 18.9461 4.75155 18.8063 4.5 18.5826L0.391306 14.4997C0.139753 14.2481 1.90735e-06 13.9404 1.90735e-06 13.5769C0.0279522 13.2413 0.139753 12.9057 0.391306 12.6541Z" fill="white"/>-->
                  <!--                </svg>-->
                  <!--                <svg class="marg-5px" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">-->
                  <!--                  <path d="M2.14087 12.9603C2.57919 12.9603 2.93782 13.3189 2.93782 13.7572V23.3963C2.93782 24.5279 3.85829 25.4484 4.98995 25.4484H24.268C25.3997 25.4484 26.3202 24.5279 26.3202 23.3963V4.11819C26.3202 2.98653 25.3997 2.06606 24.268 2.06606H14.629C14.1907 2.06606 13.8321 1.70743 13.8321 1.26911C13.8321 0.830793 14.1907 0.472168 14.629 0.472168H24.268C26.2763 0.472168 27.9141 2.1059 27.9141 4.11819V23.3963C27.9141 25.4046 26.2803 27.0423 24.268 27.0423H4.98995C2.98165 27.0423 1.34393 25.4086 1.34393 23.3963V13.7572C1.34393 13.3149 1.69857 12.9603 2.14087 12.9603Z" fill="white"/>-->
                  <!--                  <path d="M14.457 18.6545L18.9279 14.1836L19.7646 17.4232L17.6966 19.4912L14.457 18.6545Z" fill="white"/>-->
                  <!--                  <path d="M8.19702 2.95715L18.3291 13.0892L13.3617 18.0567L3.22959 7.92458L8.19702 2.95715Z" fill="white"/>-->
                  <!--                  <path d="M3.85395 0.364602C4.34009 -0.121534 5.12508 -0.121534 5.60723 0.364602L7.35254 2.10991L2.38359 7.07886L0.638281 5.33355C0.152145 4.84742 0.152145 4.06243 0.638281 3.58027L3.85395 0.364602Z" fill="white"/>-->
                  <!--                  <path d="M20.4824 20.2086L19.04 19.838L20.1118 18.7661L20.4824 20.2086Z" fill="white"/>-->
                  <!--                </svg>-->
                  <!--                <svg class="marg-5px" xmlns="http://www.w3.org/2000/svg" width="24" height="31" viewBox="0 0 24 31" fill="none">-->
                  <!--                  <path d="M6.12789 12.9788C6.57996 12.9788 6.94629 13.3456 6.94629 13.7958V27.0612C6.94629 27.5125 6.57884 27.8782 6.12789 27.8782C5.67693 27.8782 5.30949 27.5114 5.30949 27.0612V13.7969C5.30949 13.3456 5.67582 12.9788 6.12789 12.9788Z" fill="white"/>-->
                  <!--                  <path d="M17.9774 12.9788C18.4294 12.9788 18.7969 13.3456 18.7969 13.7958V27.0612C18.7969 27.5125 18.4294 27.8782 17.9774 27.8782C17.5253 27.8782 17.159 27.5114 17.159 27.0612V13.7969C17.159 13.3456 17.5253 12.9788 17.9774 12.9788Z" fill="white"/>-->
                  <!--                  <path d="M12.0527 12.9788C12.5048 12.9788 12.8711 13.3456 12.8711 13.7958V27.0612C12.8711 27.5125 12.5036 27.8782 12.0527 27.8782C11.6017 27.8782 11.2332 27.5114 11.2332 27.0612V13.7969C11.2343 13.3456 11.6017 12.9788 12.0527 12.9788Z" fill="white"/>-->
                  <!--                  <path d="M22.457 10.4565V26.6387C22.457 28.714 20.7657 30.4014 18.6879 30.4014H5.4187C3.33986 30.4014 1.64961 28.7129 1.64961 26.6387V10.4565H22.457ZM16.4343 27.0611C16.4343 27.9104 17.1268 28.6018 17.9775 28.6018C18.8282 28.6018 19.5208 27.9104 19.5208 27.0611V13.7968C19.5208 12.9476 18.8282 12.2562 17.9775 12.2562C17.1268 12.2562 16.4343 12.9476 16.4343 13.7968V27.0611ZM10.5095 27.0611C10.5095 27.9104 11.2021 28.6018 12.0528 28.6018C12.9035 28.6018 13.596 27.9104 13.596 27.0611V13.7968C13.596 12.9476 12.9035 12.2562 12.0528 12.2562C11.2021 12.2562 10.5095 12.9476 10.5095 13.7968V27.0611ZM4.58471 27.0611C4.58471 27.9104 5.27729 28.6018 6.12799 28.6018C6.97868 28.6018 7.67125 27.9104 7.67125 27.0611V13.7968C7.67125 12.9476 6.97868 12.2562 6.12799 12.2562C5.27729 12.2562 4.58471 12.9476 4.58471 13.7968V27.0611Z" fill="white"/>-->
                  <!--                  <path d="M17.1553 3.35138C17.1553 1.50284 15.6487 0 13.7982 0H10.3019C8.45684 0 6.95588 1.50284 6.95588 3.35138V4.66858H8.90446V3.35138C8.90446 2.58217 9.53134 1.95636 10.3019 1.95636H13.7982C14.5687 1.95636 15.1956 2.58217 15.1956 3.35138V4.66858H17.1553V3.35138Z" fill="white"/>-->
                  <!--                  <path d="M0.104918 9.02815C0.104918 9.4172 0.422258 9.73399 0.81086 9.73399H1.28631H22.8186H23.2941C23.6838 9.73399 24 9.4172 24 9.02815V7.88323C24 6.50934 22.881 5.39221 21.5047 5.39221H2.60021C1.22396 5.39221 0.104918 6.50934 0.104918 7.88323V9.02815Z" fill="white"/>-->
                  <!--                </svg>-->
                  <!--              </div>-->
                </div>
                <div class="col-12 text-center marg-top-30">
                  <h2 class="option-box-title">{{ questionData.question }}</h2>
                </div>
                <div
                  class="col-11 col-sm-10 col-md-12 text-center"
                  *ngIf="questionData.questionType !== eQuesTypes.EVALUATION"
                >
                  <div
                    class="row justify-content-center options-survey-row"
                    *ngIf="questionData?.answers"
                  >
                    <div
                      class="col-sm-5 col-md-4 col-lg-2 orange options-survey-box"
                      *ngFor="
                        let optionData of questionData?.answers;
                        let j = index
                      "
                    >
                      <span *ngIf="questionData.questionType != 'text'">{{
                        j + 1
                      }}</span>
                      <!-- <p
                        style="
                          display: inline;
                          word-wrap: break-word;
                          padding: 0 5px;
                        "
                      >
                        {{ optionData.option }}
                      </p> -->
                      <p
                        style="
                          word-wrap: break-word;
                          padding: 0 5px;
                          display: inline;
                        "
                        *ngIf="
                          questionData.questionType == eQuesTypes.YES_OR_NO
                        "
                      >
                        {{ "survey." + optionData.option | translate }}
                      </p>
                      <p
                        style="
                          word-wrap: break-word;
                          padding: 0 5px;
                          display: inline;
                        "
                        *ngIf="
                          questionData.questionType !== eQuesTypes.YES_OR_NO
                        "
                      >
                        {{ optionData.option }}
                      </p>
                    </div>
                  </div>
                </div>
                <ng-container
                  *ngIf="questionData.questionType == eQuesTypes.EVALUATION"
                >
                  <div class="row">
                    <div class="col-12">
                      <div class="evaluation preview">
                        <ul>
                          <li>
                            <input
                              type="checkbox"
                              id="1"
                              checked
                              disabled
                              hidden
                            />
                            <label for="1">
                              <span>1</span>
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="2"
                              checked
                              disabled
                              hidden
                            />
                            <label for="2">
                              <span>2</span>
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="3"
                              checked
                              disabled
                              hidden
                            />
                            <label for="3">
                              <span>3</span>
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="4"
                              checked
                              disabled
                              hidden
                            />
                            <label for="4">
                              <span>4</span>
                            </label>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              id="5"
                              checked
                              disabled
                              hidden
                            />
                            <label for="5">
                              <span>5</span>
                            </label>
                          </li>
                        </ul>
                        <ul class="evaluation-answer-options">
                          <li>
                            <input
                              type="text"
                              readonly
                              [value]="questionData?.answers[0].option"
                            />
                          </li>
                          <li>
                            <input
                              type="text"
                              readonly
                              [value]="questionData?.answers[1].option"
                            />
                          </li>
                          <li>
                            <input
                              type="text"
                              readonly
                              [value]="questionData?.answers[2].option"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="col-11 text-right marg-top-30">
          <button
            class="ccsurvey-btn ccsurvey-btn-1 add-quest-btn cursor-pointer"
            (click)="Back()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M29.687 7.70164C28.0321 4.98 25.5516 2.75222 22.6492 1.44007C19.545 0.0377939 16.0657 -0.355132 12.7271 0.32618C9.57959 0.967838 6.64478 2.61164 4.42745 4.93314C2.1993 7.26546 0.688634 10.2683 0.198298 13.4585C0.0685029 14.2985 0 15.1456 0 15.9963C0 19.2875 1.01673 22.5607 2.93481 25.2391C4.83125 27.8886 7.47402 29.9182 10.535 31.0429C13.6717 32.1928 17.187 32.3046 20.3958 31.3889C23.4712 30.5094 26.2763 28.6853 28.3097 26.2124C30.4189 23.653 31.6916 20.5168 31.9512 17.2076C32.2144 13.9055 31.4068 10.5314 29.687 7.70164ZM22.0002 17.0634H17.0897V22.0056C17.0897 22.6112 16.5921 23.0618 16.008 23.087C15.424 23.1123 14.9264 22.5715 14.9264 22.0056V17.0634H9.987C9.38129 17.0634 8.93061 16.5659 8.90537 15.9819C8.88014 15.3979 9.42095 14.9005 9.987 14.9005H14.9264V9.99071C14.9264 9.3851 15.424 8.93449 16.008 8.90926C16.5921 8.88403 17.0897 9.42475 17.0897 9.99071V14.9005H22.0002C22.6059 14.9005 23.0566 15.3979 23.0819 15.9819C23.1071 16.5659 22.5663 17.0634 22.0002 17.0634Z"
                fill="white"
              />
            </svg>
            {{ "preview.Back" | translate }}
          </button>
          <button
            class="ccsurvey-btn ccsurvey-btn-2 cursor-pointer"
            (click)="Next()"
          >
            {{ "preview.Next" | translate }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.0243 8.92919L10.7457 14.2078C10.3601 14.5934 10.3596 15.2172 10.744 15.6017C11.1311 15.9888 11.7525 15.9853 12.1379 15.5999L19.0952 8.64258L19.0961 8.64172L19.097 8.64086C19.2902 8.44759 19.3862 8.19593 19.3858 7.9444C19.3849 7.69224 19.2889 7.44063 19.097 7.2487L19.0961 7.24784L19.0952 7.24698L12.1379 0.289624C11.7523 -0.0959762 11.1284 -0.0965313 10.744 0.287903C10.3569 0.675018 10.3603 1.2964 10.7457 1.68178L16.0243 6.96038H0.985813C0.443893 6.96038 0 7.40111 0 7.94478C0 8.49224 0.441363 8.92919 0.985813 8.92919H16.0243Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
