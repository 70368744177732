import {Injectable} from '@angular/core';


const fontFamilies = [
  {
    name: 'Arial',
    value: 'Arial, sans-serif',
  },
  {
    name: 'Times New Roman',
    value: 'Times New Roman, serif',
  },
  {
    name: 'Helvetica',
    value: 'Helvetica, sans-serif',
  },
  {
    name: 'Georgia',
    value: 'Georgia, serif',
  },
  {
    name: 'Verdana',
    value: 'Verdana, sans-serif',
  },
  {
    name: 'Courier New',
    value: 'Courier New, monospace',
  },
  {
    name: 'Impact',
    value: 'Impact, sans-serif',
  },
  {
    name: 'Trebuchet MS',
    value: 'Trebuchet MS, sans-serif',
  },
  {
    name: 'Palatino',
    value: 'Palatino, serif',
  },
  {
    name: 'Arial Black',
    value: 'Arial Black, sans-serif',
  },
];


const colors = [
  {
    name: 'Red',
    code: '#FF0000',
  },
  {
    name: 'Green',
    code: '#00FF00',
  },
  {
    name: 'Blue',
    code: '#0000FF',
  },
  {
    name: 'Yellow',
    code: '#FFFF00',
  },
  {
    name: 'Cyan',
    code: '#00FFFF',
  },
  {
    name: 'Magenta',
    code: '#FF00FF',
  },
  {
    name: 'Black',
    code: '#000000',
  },
  {
    name: 'White',
    code: '#FFFFFF',
  },
  {
    name: 'Orange',
    code: '#FFA500',
  },
  {
    name: 'Purple',
    code: '#800080',
  },
];


@Injectable({
  providedIn: 'root'
})
export class FontsService {

  constructor() {
  }

  getAvailableFontFamilies() {
    // const fontList = Array.from(document.fonts).map((fontFace) => fontFace.family);
    // return Array.from(new Set(fontList));

    return fontFamilies;
  }

  getAvailableColors() {
    // const fontList = Array.from(document.fonts).map((fontFace) => fontFace.family);
    // return Array.from(new Set(fontList));

    return colors;
  }
}
