<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLongTitle">{{'login.Forget Password?' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <img (click)="close()" src="assets/imgs/times-icon.svg" alt="">
  </button>
</div>
<div class="modal-body">
  <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
    <li class="nav-item">
      <a class="nav-link active" #mobileTab (click)="selectTab('sms')" [class.active]="activeTab === 'sms'"
        data-toggle="tab" role="tab" aria-controls="sms" aria-selected="false">{{ "login.sms" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" #emailTab (click)="selectTab('email')" [class.active]="activeTab === 'email'" role="tab"
        aria-controls="email" aria-selected="true">{{ "login.mail" | translate }}</a>
    </li>
  </ul>
  <form [formGroup]="form">
    <!-- <div class="input-group mb-3"
      [ngClass]="{'invalid':form.get('phoneNumber').invalid && form.get('phoneNumber').dirty}">
      <div class="input-group-prepend">
        <span class="input-group-text inputGroup" id="inputGroup-sizing-default">+966</span>
      </div>
      <input type="text" class="inputText" formControlName="phoneNumber"
        placeholder="{{'signup.5xxxxxxxx*' | translate}}" aria-label="Default"
        aria-describedby="inputGroup-sizing-default">
    </div> -->
    <div class="input-group d-flex" [ngClass]="{
      invalid:
        form.get('phoneNumber').invalid && form.get('phoneNumber').dirty
    }">
      <div class="input-group-prepend">
        <select formControlName="phoneCode" class="input-group-text inputGroup cursor-pointer"
          id="inputGroup-sizing-default">
          <option value="">Code</option>
          <option *ngFor="let c of countries" value="{{ c.phonecode }}">
            {{ c.phonecode }}
          </option>
        </select>
      </div>
      <input type="number" class="inputText" formControlName="phoneNumber"
        placeholder="{{ 'signup.5xxxxxxxx*' | translate }}" aria-label="Default"
        aria-describedby="inputGroup-sizing-default" />
    </div>

    <!-- <div class="s-input" [ngClass]="{'invalid':form.get('phoneNumber').invalid && form.get('phoneNumber').dirty}">
      <input formControlName="phoneNumber"
             type="text" placeholder="{{'forget.phoneNumber' | translate}}">
      <i class="fas fa-user"></i>
    </div> -->

    <button (click)="processForget()" class="m-btn m-btn-green w-100">{{'forget.send' | translate}}</button>
  </form>
</div>


<app-loader [show]="showLoader"></app-loader>