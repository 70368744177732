import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthService} from '../../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {LanguageService} from '../../../services/language.service';

@Component({
  selector: 'app-change-forget-password',
  templateUrl: './change-forget-password.component.html',
  styleUrls: ['./change-forget-password.component.css']
})
export class ChangeForgetPasswordComponent implements OnInit {

  showLoader = false;
  onClose: any;
  form: FormGroup;
  token: any;

  constructor(public languageService: LanguageService,
              private modalService: BsModalService,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private translateService: TranslateService,
              private toastr: ToastrService,
              public modal: BsModalRef) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      password: [null, [Validators.required, Validators.pattern(/^.{6,16}$/)]],
      repeatPassword: [null, [Validators.required, this.matchValues('password')]],
    });
  }

  matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value &&
      control.value === control.parent.controls[matchTo].value ? null : {isMatching: false};
    };
  }

  close(Result = null) {
    this.onClose(Result);
  }

  processForget() {
    if (this.form.valid) {
      this.showLoader = true;
      this.authService.changeForgottenPassword(this.form.value, this.token)
        .subscribe(result => {
          this.showLoader = false;
          this.translateService.get('forget.change_success_message')
            .subscribe((text) => {
              this.toastr.success(text);
            });
          this.close({});
        }, err => {
          this.showLoader = false;
          this.translateService.get('forget.change_error_message')
            .subscribe((text) => {
              this.toastr.error(text);
            });
        });
    } else {
      for (let control in this.form.controls) {
        this.form.get(control).markAsDirty();
      }
    }
  }
}
