import { SurveyTypes } from 'src/app/general/enums/survey-types.enum';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from '../../../services/language.service';
import { SurveyService } from '../../../services/survey.service';
import { ActivatedRoute, Router } from '@angular/router';
import { City, Country, ICity, ICountry } from 'country-state-city';
import { eLanguage } from 'src/app/general/enums/language.enum';
import { countriesAr } from 'src/assets/data/countries';
import { citiesAr } from 'src/assets/data/cities';
import { DarkLiteThemeService } from '../../../services/dark-lite-themeService.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-survey-namey',
  templateUrl: './survey-name.component.html',
  styleUrls: ['./survey-name.component.css'],
})
export class SurveyNameComponent implements OnInit {
  showLoader = false;
  onClose: any;
  survey: any = null;
  surveyId: any;
  countriesList = [];
  citiesList = [];

  isReadMore = false;
  form: FormGroup;
  cities: ICity[] = [];
  countries: ICountry[] = [];
  locale = 'en';
  allowClose = true;
  dir = 'ltr';
  ages: any;
  displayState = false;
  elanguage = eLanguage;
  language: any;
  lang: any;

  displayStates = 0;

  gettingStarted = true;

  termsAndConditions = false;

  constructor(
    public languageService: LanguageService,
    private formBuilder: FormBuilder,
    private surveyService: SurveyService,
    public darkThemeService: DarkLiteThemeService,
    private translateService: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    public modal: BsModalRef,
    private _ac: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) {
    this.survey = this.surveyService.getLocalSurveyModal();
    this.surveyId = this.activatedRoute.snapshot.params.surveyId;
    const languages = [eLanguage.Arabic, eLanguage.English];
    this.lang = this._ac.snapshot.params?.lang && languages.includes(this._ac.snapshot.params?.lang) ? this._ac.snapshot.params?.lang : eLanguage.English;
    console.log(this.lang, 'current language');
    const langPrefix = this.lang === eLanguage.Arabic ? eLanguage.Short_Arabic : eLanguage.Short_English;
    if (this.languageService.getLanguageOrDefault() !== langPrefix) {
      this.languageService.setLanguage(langPrefix);
      window.location.reload();
    }
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;

    console.log('language', this.language);
    if (this.survey?.language == 'Arabic') {
      this.translateService.use('ar');
      this.dir = 'rtl';
    } else {
      this.translateService.use('en');
      this.dir = 'ltr';
    }
  }

  ngOnInit() {
    this.getCountriesAr(this.lang);
    this.getCountries();
    if (this.language === 'Arabic') {
      this.ages = [
        '9 - 12 سنين',
        '13 - 17 سنين',
        '18 - 29 سنين',
        '30 - 45 سنين',
        '45 - 60 سنين',
        '60 زائد سنوات',
      ];

    } else {
      this.ages = [
        '9 - 12 Years',
        '13 - 17 Years',
        '18 - 29 Years',
        '30 - 45 Years',
        '45 - 60 Years',
        '60 plus Years',
      ];

    }
    if (this.surveyId) {
      this.getSurvey(this.surveyId);
    }
    this.form = this.formBuilder.group({
      id: [this.activatedRoute.snapshot.params.surveyId],
      gender: [null, Validators.required],
      name: [null, Validators.required],
      cityId: [null, Validators.required],
      country: [null, Validators.required],
      age: [null, Validators.required],
    });

    this.nextDisplayState();
  }
  getSurvey(id: any) {
    this.surveyService.getSurveyById(id).subscribe((result) => {
      this.surveyService.createLocalSurveyModal(result);
      this.survey = result;
    });

  }
  getSafeUrl(data: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(data);
  }
  showText() {
    this.isReadMore = !this.isReadMore;
  }
  getCities() {
    const countryName = this.form.value.country;
    const country = this.countries.find((x) => x.name === countryName);
    if (country) {
      this.cities = City.getCitiesOfCountry(country.isoCode);
    }
  }
  getCountries() {
    this.countries = Country.getAllCountries();
  }

  close(Result = null) {
    this.onClose(Result);
  }
  handleCreateSurvey() {
    if (this.form.valid) {
      this.showLoader = true;
      console.log("🚀 ~ SurveyNameComponent ~ handleCreateSurvey ~ this.form.value:", this.form.value)
      this.surveyService.createSurveyUserInfo({
        ...this.form.value,
        gender: this.locale,
      });
      this.showLoader = false;
      // this.close(true);
      // if(this.allowClose) {

      if (this.survey?.surveyType == SurveyTypes.NORMAL) {
        // this.router.navigate([`/survey-answer/${this.survey?.language}`]);
        this.router.navigate([`answer-new-ui/${this.survey?.language}`]);
      } else if (this.survey?.surveyType == SurveyTypes.ENTERPRISE) {
        // this.router.navigate([
        //   `enterprise/survey-answer/${this.survey?.language}`,
        // ]);
        this.router.navigate([`answer-new-ui/${this.survey?.language}`]);
      } else {
        this.translateService
          .get('survey.surveyTimeNotValid')
          .subscribe((text) => {
            this.toastr.error(text);
          });
      }
      // }
    } else {
      for (const control in this.form.controls) {
        this.form.get(control).markAsDirty();
      }
    }
  }

  // changeGender(gend: string) {
  //   this.form.get("gender").setValue(gend);
  //   this.locale = gend;
  //
  //   // if (event.target.checked) {
  //   //   this.form.value.gender = gend;
  //   //   this.locale = gend;
  //   //   if(this.displayState <= 2) {
  //   //     this.nextDisplayState();
  //   //   }
  //   // }
  // }


  getCountriesAr(lang: any) {
    console.log(countriesAr, countriesAr.length, 'whas');
    this.countriesList = [];
    for (let i = 0; i < countriesAr.length; i++) {
      this.countriesList.push({
        index: countriesAr[i].value,
        label: lang === 'English' ? countriesAr[i].label_fr : lang === 'Arabic' ? countriesAr[i].label_ar : countriesAr[i].label,
      });
    }
  }

  async getCitiesArabic() {
    debugger;
    this.form.get('cityId').reset();
    const countryName = this.form.value.country;
    const country_index = countryName.$ngOptionLabel;
    const country = countriesAr.find((country: any) => country.label_ar === country_index.trim());

    if (!country) {
      // Country not found, return an empty array or handle the error as needed.
      return [];
    }

    const selectedCountryLabel = country.label;
    const selectedLanguageKey = this.language === 'English' ? 'label_fr' : this.language === 'Arabic' ? 'label_ar' : 'label';

    for (let i = 0; i < citiesAr.length; i++) {
      if (citiesAr[i].country === selectedCountryLabel) {
        // Map cities directly and return the result.
        this.citiesList = citiesAr[i].cities.map(city => ({
          value: city.value,
          label: city[selectedLanguageKey],
        }));
        return this.citiesList;
      }
    }

    // If the country is found but no cities match, return an empty array or handle the error as needed.
    return [];
  }

  handleNext() {
    const gender = this.form.get('gender').value;
    const name = this.form.get('name').value;

    if (gender && name) {
      this.displayState = true;
      // this.nextDisplayState()
    } else {
      this.toastr.error(this.language === 'Arabic' ? 'لو سمحت أملأ كل الحقول' : 'Please fill all fields');
    }
  }

  gettingStart() {
    if (this.termsAndConditions == true) {
      this.gettingStarted = false;
    } else {
      this.toastr.error(this.language === 'Arabic' ? 'يرجى قبول الشروط والأحكام' : 'Please accept terms and conditions');
    }
  }

  clickButton() {
    this.termsAndConditions = !this.termsAndConditions;
  }

  changeGender(gend: string) {
    this.form.get('gender').setValue(gend);
    this.locale = gend;
    if (this.displayStates <= 2) {
      console.log("🚀 ~ file: survey-name.component.ts:269 ~ SurveyNameComponent ~ changeGender ~ displayStates:", this.displayStates)
      this.nextDisplayState();
      this.handleNext()
    }

    // if (event.target.checked) {
    //   this.form.value.gender = gend;
    //   this.locale = gend;
    //   if(this.displayState <= 2) {
    //     this.nextDisplayState();
    //   }
    // }
  }

  onNameInput() {
    console.log('onNameInput', this.displayStates);

    if (this.displayStates < 2) {
      this.nextDisplayState();
    }
  }

  onCityChange() {
    console.log('onCityChange', this.displayStates);
    if (this.displayStates <= 3) {
      console.log('onCityChange');
      this.nextDisplayState();
    }
  }

  onAgeChange() {
    console.log('onAgeChange', this.displayStates);

    if (this.displayStates <= 4) {

      this.handleCreateSurvey()
      this.nextDisplayState();
    }
  }

  // setTimeOut(time: number, fn: any) {
  //   setTimeout(fn(), time);
  // }

  nextDisplayState() {
    setTimeout(() => this.displayStates++, 200);
  }
}
