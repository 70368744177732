import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comming-soon-modal',
  templateUrl: './comming-soon-modal.component.html',
  styleUrls: ['./comming-soon-modal.component.css']
})
export class CommingSoonModalComponent implements OnInit {
  onClose: any;

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.onClose();
  }

}
