import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "angular-2-local-storage";
import { WebApiService } from "./webApi.service";
import { Socket } from "ngx-socket-io";

@Injectable()
export class AuthService {
  private controller = "/";
  public currentUserData: any;
  public signUp = false;

  constructor(
    private localStorageService: LocalStorageService,
    public router: Router,
    private webApi: WebApiService,
    public socket: Socket
  ) {}

  setCurrentUser(user, accessToken) {
    this.localStorageService.set("currentUser", user);
    if (accessToken) {
      this.localStorageService.set("accessToken", accessToken);
    }
  }

  get currentUser() {
    if (this.localStorageService.get("currentUser")) {
      this.currentUserData = this.localStorageService.get("currentUser") as any;
      return this.currentUserData;
    }
    return null;
  }
  setWalletRates(result: any) {
    localStorage.removeItem("wallet-rates");
    this.localStorageService.set("wallet-rates", result);
  }
  setSignUp() {
    this.localStorageService.set("sigUp", true);
  }
  get getWalletRates() {
    return this.localStorageService.get("wallet-rates") as any;
  }
  get accessToken() {
    if (this.localStorageService.get("accessToken")) {
      return this.localStorageService.get("accessToken") as string;
    }
    return null;
  }

  LogOut() {
    // this.webApi.post(`${this.controller}/Logout`).subscribe(res => {});
    this.currentUser;

    this.socket.emit("userLogOut", { userId: this.currentUserData._id });
    this.localStorageService.remove("accessToken");
    this.localStorageService.remove("currentUser");
    this.localStorageService.remove("wallet-rates");
    // this.localStorageService.clearAll();
    this.router.navigate(["/"]);
  }
  clearSurvey() {
    this.localStorageService.remove("KF-survey");
    this.localStorageService.remove("survey");
  }

  isLoggedIn(): boolean {
    return this.accessToken !== null && this.currentUser !== null;
  }

  Login(param) {
    return this.webApi.postObserveResponse(`/user/auth`, param);
  }
  LoginTimeUpdate(param) {
    this.socket.emit("userLoggedIn", { userId: param._id });
  }
  social(param) {
    return this.webApi.postObserveResponse(`/user/auth/social`, param);
  }
  pushNotification(param) {
    return this.webApi.postObserveResponse(`/pushNotification`, param);
  }

  Register(param) {
    return this.webApi.postObserveResponse(`/register`, param);
  }

  forgottenPasswordSendSms(params: any) {
    return this.webApi.postObserveResponse(
      `/sms/forgottenPassword/phoneNumber`,
      params
    );
  }

  verifyForgottenPassword(params) {
    return this.webApi.postObserveResponse(`/sms/forgottenPassword`, params);
  }

  verify(params, token) {
    return this.webApi.postObserveResponse(`/user/sms/code`, params, token);
  }

  sendSMSVerificationCode(phoneNumber, phoneCode, token) {
    phoneNumber = encodeURIComponent(phoneNumber);
    return this.webApi.get(
      `/user/web/sms/code?phoneNumber=${phoneNumber}&phoneCode=${phoneCode}`,
      null,
      token
    );
  }

  changeForgottenPassword(params, token) {
    return this.webApi.put(`/sms/forgottenPassword`, params, token);
  }

  getUser() {
    return this.webApi.getObserveResponse(`/user`);
  }
  // getAllGift() {
  //   return this.webApi.getObserveResponse(`/all/gift`);
  // }

  updateProfile(params, $token) {
    return this.webApi.put("/user/update", params, $token);
  }

  getInterests() {
    return this.webApi.get(`/interests/all`);
  }
  uploadUserAvatar() {
    return this.webApi.post(`/interests`);
  }
  updatedWallet() {
    return this.webApi.get(`/user/userWallet`);
  }
  updateReferral(data) {
    return this.webApi.post(`/referral`, data);
  }
  deepCopy(data) {
    return JSON.parse(JSON.stringify(data));
  }
}
