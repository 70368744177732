import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { getFullYear } from "ngx-bootstrap/chronos";
import locale from "date-fns/locale/en-US";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "angular-2-local-storage";
import { Country, City, ICountry, ICity } from "country-state-city";
import { DatepickerOptions } from "ng2-datepicker";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/services/auth.service";
import { SettingService } from "src/services/setting.service";
import { DatePipe } from "@angular/common";
import { environment } from "src/environments/environment";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { countriesAr } from "src/assets/data/countries";
import { citiesAr } from "src/assets/data/cities";
import { LanguageService } from "src/services/language.service";
import { eLanguage } from "src/app/general/enums/language.enum";

@Component({
  selector: "app-new-complete-profile",
  templateUrl: "./new-complete-profile.component.html",
  styleUrls: ["./new-complete-profile.component.css"],
})
export class NewCompleteProfileComponent implements OnInit {
  showLoader = false;
  form: FormGroup;
  countriesList = [];
  citiesList = [];
  genders = [
    {
      id: "male",
      name: "male",
    },
    {
      id: "female",
      name: "female",
    },
  ];
  status = [
    {
      id: "single",
      name: "single",
    },
    {
      id: "married",
      name: "married",
    },
  ];
  information = [
    {
      id: "Personal",
      name: "Personal",
    },
    {
      id: "Business",
      name: "Business",
    },
  ];
  employ = [
    {
      id: "employed",
      name: "Employed",
    },
    {
      id: "unemployed",
      name: "Unemployed",
    },
  ];
  education = [
    {
      id: "Bachelor",
      name: "Bachelor's",
    },
    {
      id: "Associate",
      name: "Associate",
    },
    {
      id: "Master",
      name: "Master",
    },
    {
      id: "Specialist",
      name: "Specialist",
    },
  ];
  date = new Date();
  // options sample with default values
  options: DatepickerOptions;

  cities: ICity[] = [];

  countries: ICountry[] = [];
  currentUser: any;
  interests = [];
  isDisabled: boolean;
  emailDisable: boolean;
  uploadAvatar: any = null;
  avatar = null;
  elanguage = eLanguage;
  language: any;
  lang: any;
  @Input("userInfo") set setUser(data: any) {
    if (data) {
      if (data.birthdate) {
        let date = Date.parse(data.birthdate);
        if (!isNaN(date)) {
          // Date parsing successful, update the birthdate property
          data.birthDate = new Date(date);
        } else {
          // Handle the case where the date string is not in a valid format
          console.error("Invalid date format:", data.birthdate);
          // You might want to set a default date or handle the error in another way
        }
        // data.birthdate = new Date(date)
      }
      if (data.avatar) {
        this.avatar = this.sanitization.bypassSecurityTrustStyle(
          `url(${data.avatar})`
        );
      }
      this.form.patchValue(data);
    }
  }
  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private sanitization: DomSanitizer,
    private settingService: SettingService,
    private translateService: TranslateService,
    public darkThemeService: DarkLiteThemeService,
    private localStorageService: LocalStorageService,
    public languageService: LanguageService,
    private _ac: ActivatedRoute
  ) {
    this.currentUser = authService.currentUser;
    this.isDisabled =
      authService.currentUser?.phoneNumber &&
      authService.currentUser?.phoneNumber?.length > 5
        ? true
        : false;
    this.emailDisable =
      authService.currentUser?.email?.length > 3 ? true : false;
    this.translateService.get("profile.Birthday").subscribe((text) => {
      this.options = {
        minYear: getFullYear(new Date()) - 50, // minimum available and selectable year
        maxYear: getFullYear(new Date()), // maximum available and selectable year
        placeholder: text, // placeholder in case date model is null | undefined, example: 'Please pick a date'
        format: "dd/MM/yyyy", // date format to display in input
        formatTitle: "LLLL yyyy",
        formatDays: "EEEEE",
        maxDate: new Date(), // maximum
        firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
        locale: locale, // date-fns locale
        position: "bottom",
        inputClass: "", // custom input CSS class to be applied
        calendarClass: "datepicker-default", // custom datepicker calendar CSS class to be applied
        scrollBarColor: "#dfe3e9", // in case you customize you theme, here you define scroll bar color
      };
    });
    const languages = [eLanguage.Arabic, eLanguage.English];
    // this.lang = this._ac.snapshot.params?.lang && languages.includes(this._ac.snapshot.params?.lang) ? this._ac.snapshot.params?.lang : eLanguage.English;
    // console.log(this.lang, "current language")

    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;

    console.log("language", this.language);
    this.initForm();
  }

  ngOnInit(): void {
    this.getCountriesAr(this.language);
    this.getCountries();
  }

  initForm() {
    this.form = this.formBuilder.group({
      firstName: [null, Validators.required],
      email: [null, [Validators.required]],
      phoneNumber: [null, Validators.required],
      gender: [null, Validators.required],
      birthDate: [null, Validators.required],
      personal: [null, Validators.required],
      code: [null],
      education: [null, Validators.required],
      facebookId: [null],
      twitterId: [null],
      googleId: [null],
      instagramId: [null],
      linkedinId: [null],
      youtubeId: [null],
      employ: [null, Validators.required],
      status: [null, Validators.required],
      country: [null, Validators.required],
      city: [null, Validators.required],
    });
    // this.getUser();
  }

  getUser() {
    this.authService.getUser().subscribe(
      (result) => {
        // if (result.body.birthdate) {
        //   result.body.birthdate = new Date(result.body.birthdate);
        // }
        if (result.body.avatar) {
          this.avatar = this.sanitization.bypassSecurityTrustStyle(
            `url(${result.body.avatar})`
          );
        }
        this.form.patchValue(result.body);
        console.log(
          "🚀 ~ file: new-complete-profile.component.ts:220 ~ NewCompleteProfileComponent ~ getUser ~ form:",
          this.form.value
        );
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
      }
    );
  }

  getCities() {
    const countryName = this.form.value.country;

    const country = this.countries.find((x) => x.name === countryName);
    if (country) {
      this.cities = City.getCitiesOfCountry(country.isoCode);
    }
  }

  getCountries() {
    this.countries = Country.getAllCountries();
  }

  async copy(inputElement) {
    inputElement.select();
    document.execCommand("copy");
    inputElement.setSelectionRange(0, 0);
    this.translateService.get("profile.copy").subscribe((text) => {
      this.toastr.success(text);
    });
  }
  onInput(event: any, key): void {
    const input = event.target.value;

    // Filter out numbers and emojis
    const filteredInput = input.replace(
      /[^\u0600-\u06FF\u0621-\u064A\sA-Za-z]/g,
      ""
    );
    // Update the input value
    this.form.get(key).setValue(filteredInput);
  }
  changeInterest(event, interest) {
    const index = this.form.value.interestIds.findIndex(
      (id) => id === interest.id
    );
    if (index === -1 && event.target.checked) {
      this.form.value.interestIds.push(interest.id);
    } else if (index !== -1 && event.target.checked === false) {
      this.form.value.interestIds.splice(index, 1);
    }
  }

  isChecked(interest) {
    const index = this.form.value.interestIds.findIndex(
      (id) => id === interest.id
    );
    if (index !== -1) {
      return true;
    }
    return false;
  }

  getCountriesAr(language: any) {
    console.log(countriesAr, countriesAr.length, "whas");
    this.countriesList = [];
    for (let i = 0; i < countriesAr.length; i++) {
      this.countriesList.push({
        index: countriesAr[i].value,
        label:
          language === "English"
            ? countriesAr[i].label_fr
            : language === "Arabic"
            ? countriesAr[i].label_ar
            : countriesAr[i].label,
      });
    }
  }
  async getCitiesArabic() {
    this.form.get("city").reset();

    debugger;

    const countryName = this.form.value.country;
    const country_index = countryName.$ngOptionLabel;
    const country = countriesAr.find(
      (country: any) => country.label_ar === country_index.trim()
    );

    if (!country) {
      // Country not found, return an empty array or handle the error as needed.
      return [];
    }

    const selectedCountryLabel = country.label;
    const selectedLanguageKey =
      this.language === "English"
        ? "label_fr"
        : this.language === "Arabic"
        ? "label_ar"
        : "label";

    for (let i = 0; i < citiesAr.length; i++) {
      if (citiesAr[i].country === selectedCountryLabel) {
        // Map cities directly and return the result.
        this.citiesList = citiesAr[i].cities.map((city) => ({
          value: city.value,
          label: city[selectedLanguageKey],
        }));
        return this.citiesList;
      }
    }

    // If the country is found but no cities match, return an empty array or handle the error as needed.
    return [];
  }

  save(redirect = false) {
    debugger;
    if (redirect) {
      if (this.form.valid) {
        this.showLoader = true;
        if (this.uploadAvatar) {
          const formData: FormData = new FormData();
          formData.append(
            "avatar_upload",
            this.uploadAvatar,
            this.uploadAvatar.name
          );
          this.http
            .request(
              new HttpRequest(
                "POST",
                `${environment.api_url}/update-profile`,
                formData,
                {
                  headers: new HttpHeaders({
                    "X-Requested-With": "application/XMLHttpRequest",
                    Security: "Nmd@E>5.D]q.rE3:",
                    Software: "web",
                    Authorization: String(
                      this.localStorageService.get("accessToken") || ""
                    ),
                  }),
                }
              )
            )
            .subscribe(
              (event) => {
                this.showLoader = false;
                if (event.type === HttpEventType.Response) {
                }
              },
              (error) => {}
            );
        }
        const pipe = new DatePipe("en-US"); // Use your own locale
        const profile = {
          firstName: this.form.value.firstName,
          email: this.form.value.email,
          phoneNumber: this.form.value.phoneNumber,
          gender: this.form.value.gender,
          city: this.form.value.city,
          country: this.form.value.country,
          status: this.form.value.status,
          education: this.form.value.education,
          employ: this.form.value.employ,
          isProfileCompleted: true,
          facebookId: this.form.value.facebookId,
          twitterId: this.form.value.twitterId,
          googleId: this.form.value.googleId,
          instagramId: this.form.value.instagramId,
          linkedinId: this.form.value.linkedinId,
          youtubeId: this.form.value.youtubeId,
          personal: this.form.value.personal,
          // phoneNumberCode: "+966",
          birthDate: null,
          // interestIds: this.form.value.interestIds
        };
        if (this.form.value.birthDate) {
          profile.birthDate = pipe.transform(
            this.form.value.birthDate
              .toString()
              .replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"),
            "yyyy-MM-dd"
          );
        }

        this.authService.updateProfile(profile, null).subscribe(
          (result) => {
            this.showLoader = false;
            this.translateService
              .get("profile.success_message")
              .subscribe((text) => {
                this.toastr.success(text);
              });
            this.authService.setCurrentUser(result, null);
            if (redirect) {
              this.router.navigate(["/"]);
            }
          },
          (err) => {
            this.showLoader = false;
            this.translateService
              .get("profile.edit_error_message")
              .subscribe((text) => {
                this.toastr.error(text);
              });
          }
        );
      } else {
        debugger;
        for (let control in this.form.controls) {
          this.form.get(control).markAsDirty();
        }
      }
    } else {
      this.showLoader = true;
      if (this.uploadAvatar) {
        const formData: FormData = new FormData();
        formData.append(
          "avatar_upload",
          this.uploadAvatar,
          this.uploadAvatar.name
        );
        this.http
          .request(
            new HttpRequest(
              "POST",
              `${environment.api_url}/update-profile`,
              formData,
              {
                headers: new HttpHeaders({
                  "X-Requested-With": "application/XMLHttpRequest",
                  Security: "Nmd@E>5.D]q.rE3:",
                  Software: "web",
                  Authorization: String(
                    this.localStorageService.get("accessToken") || ""
                  ),
                }),
              }
            )
          )
          .subscribe(
            (event) => {
              this.showLoader = false;
              if (event.type === HttpEventType.Response) {
              }
            },
            (error) => {}
          );
      }
      const pipe = new DatePipe("en-US"); // Use your own locale
      const profile = {
        firstName: this.form.value.firstName,
        email: this.form.value.email,
        phoneNumber: this.form.value.phoneNumber,
        gender: this.form.value.gender,
        city: this.form.value.city,
        country: this.form.value.country,
        status: this.form.value.status,
        education: this.form.value.education,
        employ: this.form.value.employ,
        facebookId: this.form.value.facebookId,
        twitterId: this.form.value.twitterId,
        googleId: this.form.value.googleId,
        instagramId: this.form.value.instagramId,
        linkedinId: this.form.value.linkedinId,
        youtubeId: this.form.value.youtubeId,
        personal: this.form.value.personal,
        // phoneNumberCode: "+966",
        birthDate: null,
        // interestIds: this.form.value.interestIds
      };
      if (this.form.value.birthDate) {
        profile.birthDate = pipe.transform(
          this.form.value.birthDate
            .toString()
            .replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"),
          "yyyy-MM-dd"
        );
      }

      this.authService.updateProfile(profile, null).subscribe(
        (result) => {
          this.showLoader = false;
          this.translateService
            .get("profile.success_message")
            .subscribe((text) => {
              this.toastr.success(text);
            });
          this.authService.setCurrentUser(result, null);
          if (redirect) {
            this.router.navigate(["/"]);
          }
        },
        (err) => {
          this.showLoader = false;
          this.translateService
            .get("profile.edit_error_message")
            .subscribe((text) => {
              this.toastr.error(text);
            });
        }
      );
    }
  }
  referral(referralCode: any) {
    const data = { referralCode };
    this.authService.updateReferral(data).subscribe(
      (result) => {
        this.showLoader = false;
        this.translateService
          .get("profile.referralCodeCalmed")
          .subscribe((text) => {
            this.toastr.success(text);
          });
      },
      (err) => {
        this.showLoader = false;
        this.translateService
          .get("profile.referralCodeCalmed Error")
          .subscribe((text) => {
            this.toastr.error(text);
          });
      }
    );
  }
  onSelectFile(files) {
    debugger;
    if (files.length > 0) {
      this.showLoader = true;
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.showLoader = false;
        this.uploadAvatar = files[0];
        // const base64String = reader?.result?.split(',')[1];
        // console.log("🚀 ~ file: new-complete-profile.component.ts:374 ~ NewCompleteProfileComponent ~ onSelectFile ~ base64String:", base64String)
        this.avatar = this.sanitization.bypassSecurityTrustStyle(
          `url(${reader.result})`
        );
      };
      reader.onerror = () => {
        this.showLoader = false;
      };
    }
  }
}
