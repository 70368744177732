<app-header></app-header>

<div class="notification-container">
  <div class="notification-heading">
    <h2 class="text-capitalize">
      {{ "notification.notifications" | translate }}
    </h2>
    <h5 class="bread-crumb">
      {{ "profile.Home" | translate }} /
      {{ "notification.notification" | translate }}
    </h5>
  </div>

  <div class="notifications-area">
    <ng-container *ngFor="let n of notifications; let i = index">
      <div class="notification-card" *ngIf="lang === 'Arabic'; else elseBlock">
        <div class="notification-icon-area">
          <img src="assets/imgs/logo.svg" class="notification-icon" alt="" />
        </div>
        <div class="notification-details-area" style="padding-inline: 10px;">
          <p class="details">
            <b>{{ "general.kafuApp" | translate }}</b>
            {{ n.bodyArabic }}
          </p>
          <p class="time">
            {{ n.createdAt|date }}
          </p>
        </div>
      </div>
      <ng-template #elseBlock>
        <div class="notification-card">
          <div class="notification-icon-area">
            <img src="assets/imgs/logo.svg" class="notification-icon" alt="" />
          </div>

          <div class="notification-details-area">
            <p class="details">

              <b>{{ "general.kafuApp" | translate }}</b>
              {{ n.body }}
            </p>
            <p class="time">
              {{ n.createdAt|date }}
            </p>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>