import { Component, OnInit } from '@angular/core';
import { DarkLiteThemeService } from 'src/services/dark-lite-themeService.service';
import { FormArray } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { eSurveyQuestionTypes } from 'src/app/general/enums/survey-question-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/services/auth.service';
import { LanguageService } from 'src/services/language.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-create-custom-third-survey',
  templateUrl: './create-custom-third-survey.component.html',
  styleUrls: ['./create-custom-third-survey.component.css']
})
export class CreateCustomThirdSurveyComponent implements OnInit {
  imageSrc: any;
  eQuesTypes = eSurveyQuestionTypes;
  isSidebarVisible = true;
  surveyData: any;
  SurveyName: any = '';
  SurveyDescription: any = '';

  constructor(
    private router: Router,
    private toasterService: ToastrService,
    public authService: AuthService,
    public languageService: LanguageService,
    private breakpointObserver: BreakpointObserver,

    public darkThemeService: DarkLiteThemeService) { }

  ngOnInit(): void {
    const surveyData = localStorage.getItem('survey');
    if (surveyData) {
      this.surveyData = JSON.parse(surveyData);
      this.SurveyName = this.surveyData.title;
      this.SurveyDescription = this.surveyData.description;
    }
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
      if (result.matches) {
        this.isSidebarVisible = false;
      }
    });
  }
  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
  onFileChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];

    if (file) {
      // Read the selected file and set it to the FormControl
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageSrc = e.target?.result;
      };
      reader.readAsDataURL(file);
    }
  }
  removeImage() {
    this.imageSrc = null; // Clear the image source
  }

  Next() {
    if (this.surveyData.multiQuestList.length >= 1) {
      this.router.navigate(["/create-forth-custom-survey"]);
    } else {
      this.toasterService.error("You can add minimum 1 questions");
    }
  }

  Back() {
    this.router.navigate(["/create-second-custom-survey"]);
  }
}
