<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLongTitle">
    {{ "login.New Account" | translate }}
  </h5>
  <button (click)="close()" type="button" class="close" data-dismiss="modal" aria-label="Close">
    <img src="assets/imgs/times-icon.svg" alt="" />
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-lg-6">
        <div class="s-input" [ngClass]="{
            invalid: form.get('email').invalid && form.get('email').dirty
          }">
          <input type="text" formControlName="email" placeholder="{{ 'signup.Your Email*' | translate }}" />
          <i class="fas fa-envelope"></i>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="input-group d-flex" [ngClass]="{
            invalid:
              form.get('phoneNumber').invalid && form.get('phoneNumber').dirty
          }">
          <div class="input-group-prepend">
            <!-- <span
              class="input-group-text inputGroup"
              id="inputGroup-sizing-default"
              >+966</span
            > -->
            <mat-form-field floatLabel="never">
              <mat-label class="code-label">Code</mat-label>

              <mat-select formControlName="phoneCode" name="food" class="input-group-text inputGroup cursor-pointer"
                id="inputGroup-sizing-default" disableOptionCentering panelClass="myPanelClass"
                [dir]="language === 'ar' ? 'rtl' : 'ltr'" (closed)="onPanelClose()"
                (selectionChange)="countryCodeUpdate($event)">
                <input matInput formControlName="filterControl" type="text" name="filter-options" id="filter-options"
                  placeholder="Search" class="searchCodeInput" />
                <mat-select-trigger>
                  <i class="fi fi-{{ countryFlag }}"></i>
                  <span>{{ countryCode }}</span>
                </mat-select-trigger>

                <mat-option *ngFor="let c of filteredOptions | async" [attr.data-selectedCode]="c.isoCode.toLowerCase()"
                  value="{{ c.phonecode }}" [ngClass]="{ hide: !c?.show }">
                  <i class="fi fi-{{ c.isoCode.toLowerCase() }}"></i>
                  {{ c.phonecode }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <input type="number" class="inputText phoneNmberText" formControlName="phoneNumber"
            placeholder="{{ 'signup.5xxxxxxxx*' | translate }}" aria-label="Default"
            aria-describedby="inputGroup-sizing-default" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="s-input" [ngClass]="{
            invalid: form.get('password').invalid && form.get('password').dirty
          }">
          <input type="password" formControlName="password" placeholder="{{ 'signup.Enter Password*' | translate }}" />
          <i class="fas fa-lock"></i>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="s-input" [ngClass]="{
            invalid:
              form.get('passwordConfirm').invalid &&
              form.get('passwordConfirm').dirty
          }">
          <input type="password" formControlName="passwordConfirm"
            placeholder="{{ 'signup.Re Enter Password*' | translate }}" />
          <i class="fas fa-lock"></i>
        </div>
      </div>
    </div>
    <div class="s-input ref-code">
      <div class="d-flex">
        <p>{{ "survey.youHaveInvitationCode" | translate }}</p>
        <span><b>+300</b>{{ "survey.Point" | translate }}</span>
      </div>
      <input type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
        placeholder="{{ 'survey.referralCode' | translate }}" />
      <i class="fas fa-user-tag"></i>
      <a href="#" class="send-btn">{{ "signup.Validate" | translate }}</a>
    </div>
    <!-- <re-captcha (resolved)="handleCorrectCaptcha($event)" (error)="errored($event)" errorMode="handled"
      siteKey="6LcCuw8cAAAAABB7HkRpUETrFNZpmNPt12SdQI10"></re-captcha> -->

    <button (click)="processSignup()" class="m-btn m-btn-green">
      {{ "signup.Register" | translate }}
    </button>
  </form>
</div>

<app-loader [show]="showLoader"></app-loader>