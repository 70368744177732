import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { LocalStorageService } from 'angular-2-local-storage';
import { eLanguage } from 'src/app/general/enums/language.enum';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private currentLanguage: string;

  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.currentLanguage = this.getLanguageOrDefault();
  }



  setLanguage(currentLanguage: string) {
    this.translateService.use(currentLanguage);
    if (this.isLang(eLanguage.Short_Arabic)) {
      this.loadCssWithPosition('../assets/css/bootstrap-rtl.min.css', 2);
      this.loadCss('../assets/css/style-ar.css');
    }
    this.localStorageService.set('KFcurrentLanguage', currentLanguage);
  }

  loadCss(url) {
    const head: any = this.document.getElementsByTagName('head')[0];
    const link: any = this.document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    link.media = 'all';
    head.appendChild(link);
  }

  loadCssWithPosition(url, position) {
    const head: any = this.document.getElementsByTagName('head')[0];
    const link: any = this.document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    link.media = 'all';
    head.insertBefore(link, head.getElementsByTagName('link')[position]);
  }

  isLang(lang: string): boolean {
    return this.currentLanguage === lang;
  }

  getLanguageOrDefault(): string {
    const currentLanguage = (this.localStorageService.get('KFcurrentLanguage') as string);
    if (currentLanguage) {
      return currentLanguage;
    } else {
      return environment.defaultLanguage;
    }
  }
}
