<div class="modal-header">
  <button (click)="onClose()" type="button" class="close" data-dismiss="modal" aria-label="Close">
    <img src="assets/imgs/times-icon.svg" alt="" />
  </button>
</div>
<div class="modal-body">
  <div class="row g-2 justify-content-md-center">
    <div class="col-lg-4 text-center" *ngFor="let plan of data; let i = index">
      <div class="card-round _plan_body">
        <h4 class="text-uppercase fw-bolder plan-card-title">
          <div *ngIf="language === 'Arabic'; else elseBlock">
            {{ plan.titleArabic }}
          </div>
          <ng-template #elseBlock>
            {{plan.title}}</ng-template>
          <!-- {{ language === 'Arabic' ? 'plan.title' : 'plan.titleArabic' }} -->
        </h4>
        <!-- <p class="text-muted text-uppercase plan-card-detail">
          {{ "plan.inThisPlanYouWillHaveTheBelowPoints" | translate }}
        </p> -->
        <div class="mx-4">
          <div class="d-flex justify-content-around plan-bg fw-bold text-white card-top-round py-2">
            <img src="./assets/imgs/balance-wallet.svg" class="plan-wallet-icon" alt="" />
            <h5>{{ "recharge.rechargeAmount" | translate }}</h5>
            <!-- <h5>{{ "plan.vs" | translate }}</h5>
            <h5>{{ "home.points" | translate }}</h5> -->
          </div>

          <div class="card-bg d-flex justify-content-around text-white py-2 card-bottom-round">
            <div class="w-100">
              <h5 class="credit-title text-uppercase">
                {{ "plan.credit" | translate }}
              </h5>
              <div class="text-capitalize">
                <span class="points-amount">{{ plan.credit }}</span>
                <span class="text-white"> {{ "survey.SAR" | translate }}</span>
              </div>
            </div>
            <!-- <div class="border-start-4"></div>
            <div class="line"></div>
            <div class="w-50">
              <h5 class="credit-title">
                <span class="text-capitalize bonus-title">{{
                  "plan.bonus" | translate
                }}</span>
                {{ "home.points" | translate }}
              </h5>
              <h3 class="points-color text-capitalize points-amount">
                {{ plan.points }}
                <span class="text-white">{{
                  "survey.points" | translate
                }}</span>
              </h3>
            </div> -->
          </div>
        </div>
        <p class="text-muted plan-instruct pb-3">
          {{ "plan.PleaseReadAndAgreeTermsAndConditions" | translate }}
        </p>
        <div class="card shadow-lg p-3 mx-2 mb-3" style="border-radius: 2rem">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <h6 class="lbl-total-points">
                {{ "plan.totalAmount" | translate }}
                <b class="credit-color">{{ "survey.SAR" | translate }}</b>
                {{ "plan.toWallet" | translate }}
              </h6>
            </div>
            <div class="p-2 mx-2 sar-radus border-green d-flex justify-content-around">
              <b class="credit-color">{{ plan.totalAmount }}</b>
              {{ "survey.SAR" | translate }}
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mt-1">
            <div>
              <h6 class="lbl-total-points">
                {{ "plan.totalFree" | translate }}
                <b class="points-color">{{ "home.points" | translate }}</b>
                {{ "plan.toAdded" | translate }}
              </h6>
            </div>
            <div class="p-2 mx-2 sar-radus border-warning d-flex justify-content-around">
              <b class="points-color fs-2">{{ plan.freePoints }}</b>
              {{ "survey.points" | translate }}
            </div>
          </div>
        </div>
        <div class="card shadow-lg p-3 mx-2 mb-3" style="border-radius: 2rem">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="lbl-calc-title">
              {{ "plan.totalVat" | translate }}
              <!-- {{ "survey.points" | translate }} -->
            </h6>
            <h3>=</h3>
            <div class="p-2 mx-2 sar-radus border-green d-flex justify-content-around">
              <b class="credit-color fs-2">{{ .15 * plan.totalAmount | number:'0.3-3' }} </b>
              {{ "survey.SAR" | translate }}
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="lbl-calc-title">{{ "plan.sub-Total" | translate }}</h6>
            <h3 class="equalSign">=</h3>
            <div class="p-2 mx-2 sar-radus border-green d-flex justify-content-around">
              <b class="credit-color fs-2">{{ .15 * plan.totalAmount + plan.totalAmount | number:'0.3-3' }}</b>
              {{ "survey.SAR" | translate }}
            </div>
          </div>
        </div>
        <div class="agree-checkboxes pl-3">
          <div class="config">

            <label class="checkbox">
              <input type="checkbox" [(ngModel)]="isChecked" (change)="checkValue(isChecked? true:false)">
              {{ "plan.AgreeOnTermsAndConditions" | translate }}
            </label>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button class="m-btn m-btn-green" (click)="recharge(plan)">
            {{ "plan.GetThisPlan" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>