<app-header></app-header>
<div
  class="row bg"
  [ngStyle]="{ 'background-image': 'url(' + survey?.backGroundImageUrl + ')' }"
>
  <div class="col-lg-6 my-3">
    <div class="username">{{ "Enterprise.Kafu Survey" | translate }}</div>
    <div class="survey">{{ "Enterprise.Invits" | translate }}</div>
    <p class="description">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit
      amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
      labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur
      adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua.
    </p>
    <div>
      <button class="learn">{{ "Enterprise.Learn" | translate }}</button>
    </div>
  </div>
  <div class="col-lg-6" style="background-color: white; border-radius: 30px">
    <form [formGroup]="form">
      <div class="row m-4 p-3 surveyName">
        <div class="col-lg-2">
          <img src="assets/imgs/logo.svg" alt="" />
        </div>
        <div class="col-lg-10">
          <h3 class="textAlgin font-bold" id="">
            {{ survey?.title }}
          </h3>
          <p class="">{{ "survey.inviteSurveyNote" | translate }}</p>
        </div>
      </div>
      <div class="px-5">
        <div class="col-lg-12">
          <div
            class="s-input"
            [ngClass]="{
              invalid: form.get('name').invalid && form.get('name').dirty
            }"
          >
            <input
              formControlName="name"
              type="text"
              placeholder="{{ 'survey.Your Name' | translate }}"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <button class="m-btn m-btn-green" (click)="handleCreateSurvey()">
              {{ "Enterprise.START" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<app-footer></app-footer>
