import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { DarkLiteThemeService } from 'src/services/dark-lite-themeService.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input() showLoader = false;
  currentYear = new Date().getFullYear();

  constructor(private router: Router, public darkThemeService: DarkLiteThemeService,) {
  }

  ngOnInit() {
  }
}
