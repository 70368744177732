export const environment = {
  production: true,
  defaultLanguage: "ar",

  socketUrl: "https://apiv2.kafuapp.com:3001",
  app_url: "https://www.kafuapp.com",
  api_url: "https://apiv2.kafuapp.com:3001/api",
  ga: "G-7BG3GK0D4G",

  // test env
  // GET_TERMINAL_ID: "kafuworld",
  // GET_PASSWORD: "Urway@263",
  // GET_MERCHANT_KEY:
  //   "24944be7c0760e1ebe675802b2d06621c0195ce562e3666f2e824980b150792b",
  // SERVER_URL:
  //   "https://payments-dev.urway-tech.com/URWAYPGService/transaction/jsonProcess/JSONrequest",
  GET_TERMINAL_ID: "kafureward",
  GET_PASSWORD: "kafu@URWAY_682",
  GET_MERCHANT_KEY: "82f45b7e8eb68a6b9de802015479f1eb44e169de60e95a5c2d435ebd171e3eb7",
  SERVER_URL: "https://payments.urway-tech.com/URWAYPGService/transaction/jsonProcess/JSONrequest",

  firebase: {
    apiKey: "AIzaSyBBRGAxp5tHPLMkNYtfptWjIHEmvhfvCQs",
    authDomain: "kafu-app.firebaseapp.com",
    databaseURL: "https://kafu-app.firebaseio.com",
    projectId: "kafu-app",
    storageBucket: "kafu-app.appspot.com",
    messagingSenderId: "51059281077",
    appId: "1:51059281077:web:fc3ceca2364e93363902fc",
    measurementId: "G-KRBV2ST8EE",
    vapidKey: `BA_dw-oxqTTOAuLdsbw5GdxUe4tCpPNpI6sK6n8kLBIBllerKxZkaoXzaQuFN9meFGUJohLNwaHh1x-MlwOzslA`,
    // databaseURL: "config data from general tab",
    // apiKey: "AIzaSyAGs74UcB74HmhSSNVtupnzHRmX6320Z4A",
    // authDomain: "push-notification-4b0d0.firebaseapp.com",
    // projectId: "push-notification-4b0d0",
    // storageBucket: "push-notification-4b0d0.appspot.com",
    // messagingSenderId: "573129310795",
    // appId: "1:573129310795:web:d6da31516dd322f86643ce",
    // measurementId: "G-0VTR3782TC",
    // vapidKey:
    //   "AAAAhXErQks:APA91bGryXVHuP5_1HtX9GRTphDvos2ejVNUqVmJMCpU9zxIlFoA9AP6EUGssDlLGATNqNNybsg58L91L-OkqVtslnQBPqDXlUHiN_xkUvIYHKXoeEj5qWIdpSlHYu2g5AIsHQ6Pfkze",
  },
};
