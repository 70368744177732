import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  showLoader = false;

  constructor(public authService: AuthService) {
  }

  ngOnInit() {
  }
}
