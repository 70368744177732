<div class="modal-header">
  <h4 class="modal-title title" id="exampleModalLongTitle">
    {{ "survey.ShareSurvey" | translate }}
  </h4>
  <button
    (click)="onClose()"
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
  >
    <img src="assets/imgs/times-icon.svg" alt="" />
  </button>
</div>

<div class="modal-body">
  <div class="row justify-content-center">
    <div class="col-md-1 col-3">
      <img src="./assets/imgs/social-media/facebook.svg" alt="" />
    </div>
    <div class="col-md-1 col-3">
      <img src="./assets/imgs/social-media/instagram.svg" alt="" />
    </div>
    <div class="col-md-1 col-3">
      <img src="./assets/imgs/social-media/twitter.svg" alt="" />
    </div>
  </div>
</div>
