import { ICountry } from "country-state-city";
import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { WebApiService } from "./webApi.service";
import * as copy from "copy-to-clipboard";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { SurveyTypes } from "src/app/general/enums/survey-types.enum";
import * as Countries from "../assets/const/countries.json";

@Injectable()
export class HomeService {
  private formSubmittedKey = 'formSubmitted';
  private subscribeKey = 'subscribe';
  private login = 'false';
  private isNotLogoIn = 'isNotLogoIn';
  public redirectUrl: string = "isRedirect"

  constructor(
    private webApi: WebApiService,
    private sanitization: DomSanitizer,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }
  contactUs(param: any) {
    return this.webApi.postObserveResponse(`/contact`, param);
  }

  getAllPartners() {
    return this.webApi.get(`/partners/all`);
    // return this.webApi.get(`/clients/all`);
  }
  getAllKafuContact() {
    return this.webApi.get(`/kafuContact/all`);
  }
  getSliders() {
    return this.webApi.get(`/sliders/all`);
  }
  Transaction() {
    return this.webApi.get(`/transactions/user`);
  }
  getServices() {
    return this.webApi.get(`/services/all`);
  }
  getMySurvey() {
    return this.webApi.get(`/survey/user-survey`);
  }
  getMyFilledSurveys() {
    return this.webApi.get(`/user-filled-survey`);
  }
  getStatistics() {
    return this.webApi.get(`/statistics/all`);
  }
  addNewsletter(data) {
    return this.webApi.post(`/newsletter`, data);
  }
  getRate() {
    return this.webApi.get(`/rate/all`);
  }
  getQuestionRate() {
    return this.webApi.get(`/questionRate/all`);
  }
  getUserStats() {
    return this.webApi.get(`/user/userStats`);
  }
  getUserReport() {
    return this.webApi.get(`/user/userStatsReport`);
  }
  getUserSurveyReport() {
    return this.webApi.get(`/user/userSurveyReport`);
  }
  transfer(data) {
    return this.webApi.post(`/user/transfer-points`, data);
  }
  getAllGift() {
    return this.webApi.get(`/all/gift`);
  }

  invoiceDownload(data) {
    return this.webApi.post(`/invoice/download`, data);
  }

  async convertToBase64(file: File) {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject("");
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const image: any = this.sanitization.bypassSecurityTrustResourceUrl(
          `${reader.result}`
        );
        resolve(image.changingThisBreaksApplicationSecurity);
      };

      reader.onerror = () => {
        reject("");
      };
    });
  }

  deepCopy(data) {
    return JSON.parse(JSON.stringify(data));
  }

  copySurveyUrl(id: string, surveyType: string, language?: string) {
    try {
      const globalLang = this.authService.currentUser?.language;
      const lang = language ? language : globalLang ? globalLang : "English";
      const url =
        surveyType === SurveyTypes.ENTERPRISE
          ? `/enterprise/survey-answer/${id}/${lang}`
          : `/survey/start/${id}/${lang}`;
      const isCopied = copy(`${window.location.origin}${url}`, {
        debug: true,
        message: "Press #{key} to copy",
      });
      if (isCopied) {
        this.translateService.get("profile.copy").subscribe((text) => {
          this.toastr.success(text);
        });
      } else {
        this.toastr.error("Survey URL not copied");
      }
    } catch (error) {
      this.toastr.error("Survey URL not copied");
    }
  }

  resetImageURL(url: string) {
    if (!url) {
      return null;
    }
    if (url.includes("image/png")) {
      url = url.replace("data:image/png;base64,", "");
    } else if (url.includes("image/jpeg")) {
      url = url.replace("data:image/jpeg;base64,", "");
    } else if (url.includes("image/jpg")) {
      url = url.replace("data:image/jpg;base64,", "");
    }
    return url;
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);

    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/png" });
    return blob;
  }

  getCountries() {
    const countries: ICountry[] = Countries as any;
    return countries;
  }

  get isFormSubmitted(): boolean {
    return sessionStorage.getItem(this.formSubmittedKey) === 'true';
  }

  markFormAsSubmitted() {
    sessionStorage.setItem(this.formSubmittedKey, 'true');
  }

  resetState() {
    sessionStorage.removeItem(this.formSubmittedKey);
  }
  get isSubscribe(): boolean {
    return sessionStorage.getItem(this.subscribeKey) === 'true';
  }

  markFormAsSubscribe() {
    sessionStorage.setItem(this.subscribeKey, 'true');
  }
  get isLogin(): boolean {
    return sessionStorage.getItem(this.login) === 'true';
  }

  markIsLogin() {
    sessionStorage.setItem(this.login, 'true');
  }
  resetIsLogin() {
    sessionStorage.removeItem(this.login);
  }
  resetFormState() {
    sessionStorage.removeItem(this.subscribeKey);
  }
  get isNotLogin(): boolean {
    return sessionStorage.getItem(this.isNotLogoIn) === 'true';
  }

  markIsNotLogin() {
    sessionStorage.setItem(this.isNotLogoIn, 'true');
  }
  resetIsNotLogin() {
    sessionStorage.removeItem(this.isNotLogoIn);
  }
  get isRedirect(): string {
    return sessionStorage.getItem(this.redirectUrl);
  }

  markIsRedirect(url) {
    sessionStorage.setItem(this.redirectUrl, url);
  }
  resetIsRedirect() {
    sessionStorage.removeItem(this.redirectUrl);
  }

}
