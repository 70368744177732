import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {LanguageService} from '../../../services/language.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  showLoader = false;
  onClose: any;
  form: FormGroup;
  forget: boolean = false;

  constructor(public languageService: LanguageService,
              private router: Router,
              public modal: BsModalRef) {
  }

  ngOnInit() {

  }

  goComplete() {
    this.onClose();
    this.router.navigate(['/profile']);
  }
}
