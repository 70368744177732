import { Component, Input, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

type counterDatesInput = {
  startDate: string | any;
  endDate: string | any;
}
@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css']
})
export class CounterComponent implements OnInit {

  private subscription: Subscription;
  startDate = new Date();
  endDate = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  // public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  @Input('dates') set setDates(dates: counterDatesInput) {
    if (dates.startDate) {
      this.startDate = new Date(dates.startDate);
      this.endDate = dates.endDate ? new Date(dates.endDate) : new Date();
      this.subscription = interval(1000).subscribe(x => { this.getTimeDifference(); });
    }
  }
  @Input('countClass') countClass: string;
  @Input('countLabelClass') countLabelClass: string;
  @Input('separatorClass') separatorClass: string;
  @Input('showSeparator') showSeparator: boolean = false;
  @Input('showDays') showDays: boolean = true;

  private getTimeDifference() {
    if (this.startDate > this.endDate) {
      this.daysToDday = this.hoursToDday = this.minutesToDday = this.secondsToDday = '00';
    } else {
      const timeDifference = this.endDate.getTime() - (new Date()).getTime();
      if (timeDifference > 0) {
        this.allocateTimeUnits(timeDifference);
      } else {
        this.daysToDday = this.hoursToDday = this.minutesToDday = this.secondsToDday = '00';
      }
    }
  }
  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

}
