<!-- Start footer section -->
<footer [ngClass]="darkThemeService.getCurrentTheme">
  <div class="row m-0">
    <!-- <div class="col-md-4 col-12 d-flex justify-content-center">
      <div class="footer-social-media">
        <div class="social-card">
          <img src="assets/imgs/social-media/twit-icon.svg" alt="" />
        </div>
        <div class="social-card">
          <img src="assets/imgs/social-media/g-icon.svg" alt="" />
        </div>
        <div class="social-card">
          <img src="assets/imgs/social-media/yt-icon.svg" alt="" />
        </div>
        <div class="social-card">
          <img src="assets/imgs/social-media/insta-icon.svg" alt="" />
        </div>
      </div>
    </div> -->
    <div class="col-md-12 col-12 mb-5">
      <div class="cpy">
        © {{ currentYear }} - {{ "home.footerDescription" | translate }}
      </div>
    </div>
  </div>
</footer>
<!-- End footer section -->
<!-- <div class="customer-support">
  <a
    href="#"
    data-toggle="tooltip"
    data-placement="bottom"
    title=""
    data-original-title="Have a question?"
  >
    <span>
      <img src="assets/imgs/customer-service-agent.svg" alt="" />
    </span>
  </a>
</div> -->
