import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-fifth-survey',
  templateUrl: './custom-fifth-survey.component.html',
  styleUrls: ['./custom-fifth-survey.component.css']
})
export class CustomFifthSurveyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
