import { Component, OnInit } from '@angular/core';
import { DarkLiteThemeService } from 'src/services/dark-lite-themeService.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(
    public darkThemeService: DarkLiteThemeService,

  ) { }

  ngOnInit(): void {
  }

}
