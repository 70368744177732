import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { saveAs } from "file-saver";

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/services/auth.service';
import { HomeService } from 'src/services/home.service';
import { LanguageService } from 'src/services/language.service';
import { RechargeService } from 'src/services/recharge.service';
import { WebApiService } from 'src/services/webApi.service';
import { PricingComponent } from '../pricing/pricing.component';
import { eLanguage } from 'src/app/general/enums/language.enum';
import { first } from 'rxjs/operators';
import { DarkLiteThemeService } from 'src/services/dark-lite-themeService.service';
import { PlanService } from 'src/services/plan.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-wallet',
  templateUrl: './new-wallet.component.html',
  styleUrls: ['./new-wallet.component.css']
})
export class NewWalletComponent implements OnInit {
  showLoader = false;
  page: number = 1;
  currentUser: any
  form: FormGroup;
  onClose: any;
  Rate: any;
  moment: any = moment;
  Group: any;
  table = [];
  filterData = [];
  points: any;
  balance: any;
  totalBalance: any;
  exchangeRate: any;
  questionRate: any;
  exchangeWallet: any;
  ttpSAR: any;
  transferForm: FormGroup;
  language: any;
  details: any;
  data: any;

  constructor(
    private toastr: ToastrService,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private webApi: WebApiService,
    private homeService: HomeService,
    private modalService: BsModalService,
    private ac: ActivatedRoute, private planService: PlanService,
    private rechargeService: RechargeService,
    private translateService: TranslateService,
    private languageService: LanguageService,// Compiled script is not shown while source map is being loaded!
    public darkThemeService: DarkLiteThemeService,

  ) {
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    console.log("language", this.language);
    (this.points = this.authService.getWalletRates?.currentCredits),
      (this.balance = this.authService.getWalletRates?.totalBalance),
      (this.totalBalance = this.authService.getWalletRates?.totalBalance),
      (this.exchangeRate = this.authService.currentUser?.exchangeRate),
      (this.questionRate = this.authService.currentUser?.questionRate);
  }

  ngOnInit(): void {
    this.getUpdatedWallet();
    this.createTransferForm();
    this.getRates();
    this.getTransaction();
    this.date();
    this.getQuestionRate();
    this.rechargingwWallet();
  }
  onPointsChange() {
    const pointsValue = this.transferForm.value.points
      ? this.transferForm.value.points
      : 0;
    this.balance =
      pointsValue / (this.Rate.exchangeRate ? this.Rate.exchangeRate : 1);
    this.transferForm.get("sar").setValue(this.balance);
  }
  createTransferForm() {
    this.transferForm = this.formBuilder.group({
      sar: new FormControl(1),
      points: new FormControl(1),
    });
  }
  getOrderId() {
    return Math.random().toString().slice(2, 5);
    // console.log(Date.now.toString().slice(2,6) + Math.random().toString().slice(2,5), "whats here")
    // return Date.now.toString() + "_" + Math.random();
  }
  onSARChange() {
    const sarValue = this.transferForm.value.sar
      ? this.transferForm.value.sar
      : 0;
    this.balance =
      sarValue * (this.Rate.exchangeRate ? this.Rate.exchangeRate : 1);
    this.transferForm.get("points").setValue(this.balance);
  }
  getRates() {
    this.homeService.getRate().subscribe((result) => {
      this.Rate = result?.length ? result[0] : {};
      this.onSARChange();
    });
  }
  date() {
    const start = (document.getElementById("startDate") as HTMLInputElement)
      .value;
    const end = (document.getElementById("endDate") as HTMLInputElement).value;
    if (start && end) {
      const filterData = this.table.filter((item) => {
        return (
          new Date(item.date).getTime() >= new Date(start).getTime() &&
          new Date(item.date).getTime() <= new Date(end).getTime()
        );
      });
      this.filterData = filterData.map((x) => {
        x.isChecked = false;
        return x;
      });
    } else {
      setTimeout(() => {
        this.filterData = this.table;
        this.sortPR();
      }, 2000);
    }
  }
  sortPR() {
    this.filterData.sort((a, b) => {
      let fa = a.date, fb = b.date;
      if (fa < fb) {
        return 1;
      }
      if (fa > fb) {
        return -1;
      }
      return 0;
    });
  }
  getQuestionRate() {
    this.homeService.getQuestionRate().subscribe((result) => {
      this.Group = result?.length ? result[0] : {};
    });
  }
  getTransaction() {
    this.homeService.Transaction().subscribe((result) => {
      this.table = result;
    });
  }
  getUpdatedWallet() {
    this.authService.updatedWallet().subscribe((result) => {
      this.totalBalance = result.totalBalance;
      this.points = result.currentCredits;
      this.authService.setWalletRates(result);
    });
  }
  onclick() {
    const data = {
      amount: this.transferForm.value.sar ? this.transferForm.value.sar : 0,
      points: this.transferForm.value.points
        ? this.transferForm.value.points
        : 0,
      cardType: 'None',
      trackingId: this.getOrderId()
    };
    this.homeService.transfer(data).subscribe(
      (result) => {
        this.translateService
          .get("wallet.success_message_exchange")
          .subscribe((text) => {
            this.getUpdatedWallet();
            this.getTransaction();
            this.toastr.success(text);
          });
      },
      (err) => {
        this.translateService
          .get("wallet.error_message_exchange")
          .subscribe((text) => {
            this.toastr.error(text);
          });
      }
    );
  }
  pdfDownload() {
    this.showLoader = true
    const transaction = this.filterData.filter((result) => result.isChecked === true);

    this.data = {
      ...transaction[0],
      language: this.language
    }

    this.webApi.invoicePdf(this.data).subscribe((response: any) => {
      console.log(response, "whats here");
      let blob: any = new Blob([response], { type: response.type });
      saveAs(blob, "invoice.pdf");
      this.showLoader = false

    }),

      (error: any) => console.log("Error downloading the file", error),
      () => console.info("File downloaded successfully");


  }
  Plan() {
    const PlanModel = this.modalService.show(PricingComponent, {
      class: "modal-dialog1 modal-content1",
      backdrop: "static",
    });
    PlanModel.content.onClose = () => {
      this.getUpdatedWallet();
      this.getTransaction();
      PlanModel.hide();
    };
  }

  rechargingwWallet() {

    console.log(this.ac.snapshot, "params");
    const path1 = this?.ac?.snapshot?.fragment.split("?")
    const splitingAgain = path1[1].split("&")
    console.log("splitingAgain", splitingAgain)
    const PaymentId = splitingAgain[0].split("=")
    const cardType = splitingAgain[9].split("=")
    const PaymentType = splitingAgain[20].split("=")
    const responseHash = splitingAgain[8].split("=")
    const amount = splitingAgain[10].split("=")
    const TrackId = splitingAgain[4].split("=")
    const TranId = splitingAgain[1].split("=")
    const responseCode = splitingAgain[6].split("=")
    const id = splitingAgain[14].split("=")
    const result = splitingAgain[3].split("=")
    console.log("PaymentId", PaymentId[1]);
    console.log("cardType", cardType[1])
    console.log("PaymentType", PaymentType[1])
    console.log("responseHash", responseHash[1])
    console.log("amount", amount[1])
    console.log("TrackId", TrackId[1])
    console.log("TranId", TranId[1])
    console.log("Id", id[1])
    let data = {
      PaymentId: PaymentId[1],
      cardType: cardType[1],
      plan: id[1],
      PaymentType: PaymentType[1],
      responseHash: responseHash[1],
      amount: Number(amount[1]),
      trackingId: TrackId[1],
      transactionId: TranId[1]
    };
    if (responseCode[1] === '000') {
      this.planService.postPlan(id[1]).subscribe((result) => {
        this.authService.setCurrentUser(result, null)

      });
      this.rechargeService
        .rechargeBalance(data)
        .pipe(first())
        .subscribe((result) => {
          this.showLoader = false;
          if (result) {
            this.authService.setWalletRates(result);
            this.getUpdatedWallet();
            this.getTransaction()
            // this.translateService
            //   .get("wallet.recharge_success_message")
            //   .subscribe((text) => {
            //     this.toastr.success(text);
            //   });
            // window.location.assign("http://localhost:4200/profile");
            window.location.assign(`${environment.app_url}/profile`);
          } else {
            this.translateService
              .get("wallet.recharge_error_message")
              .subscribe((text) => {
                this.toastr.error(text);
              });
          }
        });
    }
    else {
      this.translateService
        .get("wallet.failure")
        .subscribe((text) => {
          this.toastr.error(text);
        });
      this.toastr.error(`${result[1]}`);
      window.location.assign(`${environment.app_url}/profile#wallet`);
    }

  }

}
