<app-header></app-header>
<div class="my-invite">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-8 col-md-9 col-12">
        <h3 class="kafu-username">{{'survey.KafuSurvey' | translate}} / {{'survey.username' | translate}}</h3>
        <h2 class="kafu-invite-heading">
          {{'survey.InvitsYouToAnswerTheSurveys' | translate}}
        </h2>
        <p class="kafu-invite-details">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam
          quisquam quas reprehenderit molestias aperiam, ratione, officia
          laboriosam corporis ut nostrum rem ex temporibus necessitatibus
          perferendis obcaecati alias harum accusantium beatae.
        </p>
      </div>
      <div class="col-lg-4 col-md-3 col-12 d-flex justify-content-end align-items-center">
        <!-- <button class="m-btn btn-grey">
          learn more
        </button> -->
        <button class="m-btn m-btn-green" style="color: rgb(69, 70, 106); background-color: transparent;">
          {{'survey.learnMore' | translate}}
        </button>
      </div>
    </div>
    <div class="child">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-lg-5">
            <div class="photo">
              <img src="assets/imgs/Online payment.png" width="100%" alt="" />
            </div>
          </div>
          <div class="col-lg-7">
            <div class="survey">
              <div class="survey-icon-area">
                <img src="assets/imgs/logo.svg" alt="" />
              </div>

              <div class="survey-about-detail">
                <h2>{{ "survey.survey" | translate }}</h2>
                <p>
                  {{ 'survey.inviteSurveyNote' | translate }}
                </p>
              </div>

              <!-- <div class="row">
                <div class="col-lg-4">

                </div>
                <div class="col-lg-8 m-auto">
                  <h2 style="font-weight: 700;font-size: 35px;">
                    {{ "Survey" }}
                  </h2>
                  <h5 style="color: #a1a1a4">
                    start earning your money back by click & answer start earning
                    your money back by click & answer start earning your money
                    back by click & answer
                  </h5>
                </div>
              </div> -->
            </div>
            <div class="row justify-content-center mt-5">
              <div class="col-lg-10 col-11">
                <div class="s-input">
                  <input
                    formControlName="name"
                    type="text"
                    placeholder="{{ 'survey.Your Name' | translate }}"
                  />
                </div>
              </div>

              <div class="col-12 mt-5 d-flex justify-content-center">
                <button
                  class="m-btn m-btn-green"
                  style="width: 50%; border-radius: 20px"
                  (click)="handleStartSurvey()"
                >
                  {{'survey.startSurvey' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-footer></app-footer>
