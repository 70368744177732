<div class="modal-header">
  <!-- <h5 class="modal-title" id="exampleModalLongTitle">{{'verify.verify phone' | translate}}</h5> -->

  <h5 class="modal-title" id="exampleModalLongTitle">
    {{ "profile.enterCode" | translate }}
  </h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <img (click)="close()" src="assets/imgs/times-icon.svg" alt="" />
  </button>
</div>
<div class="modal-body">
  <p class="detail-text mb-2">
    {{ "profile.weSent" | translate }}
  </p>

  <form [formGroup]="form">
    <!-- <div class="s-input" [ngClass]="{'invalid':form.get('verificationCode').invalid && form.get('verificationCode').dirty}">
      <input formControlName="verificationCode"
             type="text" placeholder="{{'verify.verificationCode' | translate}}">
      <i class="fas fa-user"></i>
    </div> -->

    <div class="row justify-content-center dir"
      [ngClass]="{'invalid':form.get('verificationCode').invalid && form.get('verificationCode').dirty}">
      <!-- <ng-otp-input style="direction: ltr;" (change)="onCodeInput($event.target.value)"></ng-otp-input> -->
      <ng-otp-input style="direction: ltr;" (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
      <!-- <ng-otp-input style="direction: ltr;" #ngOtpInput (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent"
        [config]="{length:4}"></ng-otp-input> -->
      <!-- <div class="col-md-2 col-2">
        <input type="number" max="1" class="input-code" (change)="onCodeInput($event.target.value)" />
      </div>
      <div class="col-md-2 col-2">
        <input type="number" max="1" class="input-code" (change)="onCodeInput($event.target.value)" />
      </div>
      <div class="col-md-2 col-2">
        <input type="number" max="1" class="input-code" (change)="onCodeInput($event.target.value)" />
      </div>
      <div class="col-md-2 col-2">
        <input type="number" max="1" class="input-code" (change)="onCodeInput($event.target.value)" />
      </div> -->
    </div>
    <button (click)="processVerify()" class="m-btn m-btn-gradient w-100 mt-4">
      {{ "profile.validate" | translate }}
    </button>
    <a class="resend_btn cursor-not-allowed" *ngIf="timerDuration>0">
      {{ "verify.Resend code In" | translate }}
      <span style="color: #ff9254" *ngIf="timerDuration>0">{{timerDuration}} sec</span><span style="color: #ff9254"
        *ngIf="timerDuration<=0">00 sec</span>
    </a>
    <a class="resend_btn" (click)="resendVerificationCode()" *ngIf="timerDuration<=0">
      {{ "verify.Resend code In" | translate }}
      <span style="color: #ff9254" *ngIf="timerDuration>0">{{timerDuration}} sec</span><span style="color: #ff9254"
        *ngIf="timerDuration<=0">00 sec</span>
    </a>
  </form>
</div>

<app-loader [show]="showLoader"></app-loader>