import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-survey',
  templateUrl: './share-survey.component.html',
  styleUrls: ['./share-survey.component.css']
})
export class ShareSurveyComponent implements OnInit {
  onClose: () => void;
  constructor() { }

  ngOnInit(): void {
  }

}
