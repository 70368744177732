import { AuthService } from "src/services/auth.service";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DarkLiteThemeService } from "../../../../services/dark-lite-themeService.service";
import { LanguageService } from "src/services/language.service";
import { eLanguage } from "src/app/general/enums/language.enum";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  dropdownStyleDisplay = "block";
  points: Number;
  balance: Number;
  changeToLang: any;

  eLanguage = eLanguage;
  showDropdown = false;

  constructor(
    public darkThemeService: DarkLiteThemeService,
    private authService: AuthService,
    public languageService: LanguageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.points = this.authService.currentUser?.points;
    this.balance = this.authService.currentUser?.totalBalance;
    this.changeToLang =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_English
        ? eLanguage.Short_Arabic
        : eLanguage.Short_English;
  }

  ngOnInit(): void {}

  showDropDownMenu() {
    if (this.dropdownStyleDisplay == "block") {
      this.dropdownStyleDisplay = "none";
    } else {
      this.dropdownStyleDisplay = "block";
    }
  }

  toggleDropDownMenu() {
    this.showDropdown = !this.showDropdown;
  }
  changeLang(): any {
    if (
      this.languageService.getLanguageOrDefault() == eLanguage.Short_English
    ) {
      this.languageService.setLanguage(eLanguage.Short_Arabic);

      this.router.navigate(["."], {
        queryParams: {
          lang: eLanguage.Short_Arabic,
        },
        relativeTo: this.activatedRoute,
        queryParamsHandling: "merge",
      });
    } else {
      this.languageService.setLanguage(eLanguage.Short_English);

      this.router.navigate(["."], {
        queryParams: {
          lang: eLanguage.Short_English,
        },
        relativeTo: this.activatedRoute,
        queryParamsHandling: "merge",
      });
    }
    setTimeout(() => window.location.reload(), 0);
  }

  toggleButtonToChangeThemeClass() {
    this.darkThemeService.toggleTheme();
  }
}
