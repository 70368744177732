import { Injectable } from "@angular/core";
import { WebApiService } from "./webApi.service";
import { LocalStorageService } from "angular-2-local-storage";
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError, first } from "rxjs/operators";
import { Router } from "@angular/router";

import { Subscription, interval, throwError } from "rxjs";

@Injectable()
export class SurveyService {
  data: any;

  constructor(
    private webApi: WebApiService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private router: Router
  ) {}

  getSurveyCategories() {
    return this.webApi.get(`/survey/categories`);
  }

  getUserBrand() {
    return this.webApi.get(`/brandManagement/user`);
  }
  deleteUserBrand(id: any) {
    return this.webApi.delete(`/brandManagement/${id}`);
  }
  createLocalSurvey(survey: any) {
    this.localStorageService.set("KF-survey", survey);
  }
  createPromoCode(data: any) {
    return this.webApi.post(`/promoCode`, data);
  }
  createSurveyUserInfo(survey: any) {
    this.localStorageService.set("KF-survey-user", survey);
  }
  createLocalSurveyModal(survey: any) {
    this.localStorageService.set("KF-modal-survey", survey);
  }
  createLocalSurveyDetail(survey: any) {
    this.localStorageService.set("KF-modal-survey-detail", survey);
  }
  createQuestionSurvey(survey: any) {
    return this.localStorageService.set("surveyQuestions", survey);
  }
  createOptionsSurvey(options: any) {
    this.localStorageService.set("surveyOption", options);
  }
  createLocalSurveyApproval(approval: any) {
    this.localStorageService.set("KF-modal-approval", approval);
  }
  getLocalSurveyApproval() {
    this.localStorageService.get("KF-modal-approval") as any;
  }
  getLocalSurvey() {
    return this.localStorageService.get("KF-survey") as any;
  }
  getLocalSurveyUserInfo() {
    return this.localStorageService.get("KF-survey-user") as any;
  }
  getLocalSurveyModal() {
    return this.localStorageService.get("KF-modal-survey") as any;
  }
  removeLocalSurveyModal() {
    this.localStorageService.remove("KF-modal-survey") as any;
  }
  getQuestionSurvey() {
    return this.localStorageService.get("surveyQuestions") as any;
  }
  editBrand(survey: any) {
    this.localStorageService.set("Brand", survey);
  }
  getEditBrand() {
    return this.localStorageService.get("Brand") as any;
  }
  removeBrand() {
    this.localStorageService.remove("Brand") as any;
  }

  isSavedSurvey() {
    return this.localStorageService.get("kafu-savedSurvey") as any;
  }

  getOptionsSurvey() {
    return this.localStorageService.get("surveyOption") as any;
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);

    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/png" });
    return blob;
  }
  saveSurvey(params) {
    // let data = {};
    // const formData: FormData = new FormData();
    // data["title"] = params.title;
    // data["description"] = params.description;
    // data["language"] = params.language;
    // data["categoryId"] = params.categoryId;
    // data["cityId"] = params.cityId;
    // data["userId"] = params.userId;
    // data["agreeCondition"] = params.agreeCondition;
    // data["agreePolicy"] = params.agreePolicy;
    // data["isPublished"] = params.isPublished;
    // data["totalPoints"] = params.totalPoints;
    // data["gender"] = params.gender;
    // data["visibility"] = params.visibility;
    // data["endDate"] = params.endDate;
    // data["startDate"] = params.startDate;
    // data["kafuNetwork"] = params.kafuNetwork;
    // data["promoteSurvey"] = params.promoteSurvey;
    // data["maxSending"] = params.maxSending;
    // data["usersValue"] = params.usersValue;
    // let singleIndex = 1;
    // let multiIndex = 1;
    // let evalIndex = 1;
    // let textIndex = 1;
    // for (let index = 0; index < params.questions.length; index++) {
    //   if (params.questions[index].answerTypeLabel === "SingleChoice") {
    //     const singleQuestion = params.questions[index];
    //     data["single"] = true;
    //     data[`singleQuestions.sQ${singleIndex++}.question`] =
    //       params.questions[index].question;

    //     // formData.append("single", "true");
    //     // formData.append(
    //     //   `singleQuestions.sQ${index + 1}.question`,
    //     //   params.questions[index].question
    //     // );
    //     let answers = [];
    //     for (const answer of singleQuestion.answers) {
    //       answers.push(answer.answer);
    //       data[`singleQuestions.sQ${singleIndex - 1}.answer`] = answers;

    //       // formData.append(
    //       //   `singleQuestions.sQ${answerIndex + 1}.answer`,
    //       //   singleQuestion.answers[answerIndex].answer
    //       // );
    //     }
    //   } else if (params.questions[index].answerTypeLabel === "MultipleChoice") {
    //     const multiQuestion = params.questions[index];
    //     data[`multi`] = true;
    //     data[`multiQuestions.mQ${multiIndex++}.question`] =
    //       params.questions[index].question;

    //     let answers = [];
    //     for (const answer of multiQuestion.answers) {
    //       answers.push(answer.answer);
    //       data[`multiQuestions.mQ${multiIndex - 1}.answer`] = answers;
    //     }
    //   } else if (params.questions[index].answerTypeLabel === "Evaluation") {
    //     const evaluationQuestion = params.questions[index];
    //     data[`evaluation`] = true;
    //     data[`evaluationQuestions.eQ${evalIndex++}.question`] =
    //       params.questions[index].question;

    //     let answers = [];
    //     for (const answer of evaluationQuestion.answers) {
    //       answers.push(answer.answer);
    //       data[`evaluationQuestions.eQ${evalIndex - 1}.answer`] = answers;
    //     }
    //   } else if (params.questions[index].answerTypeLabel === "Text") {
    //     const textQuestion = params.questions[index];
    //     data[`text`] = true;
    //     data[`textQuestions.tQ${textIndex++}.question`] =
    //       params.questions[index].question;

    //     for (
    //       let answerIndex = 0;
    //       answerIndex < textQuestion.answers.length;
    //       answerIndex++
    //     ) {
    //       data[`textQuestions.tQ${textIndex - 1}.answer`] =
    //         textQuestion.answers[answerIndex].answer;
    //     }
    //   }
    // }
    return this.webApi.postObserveResponse(`/survey/publish`, params);
  }

  async saveImage(image) {
    let surveyImage;
    if (image) {
      // if (image.includes("image/png")) {
      //   surveyImage = image.replace("data:image/png;base64,", "");
      // } else if (image.includes("image/jpeg")) {
      //   surveyImage = image.replace("data:image/jpeg;base64,", "");
      // } else if (image.includes("image/jpg")) {
      //   surveyImage = image.replace("data:image/jpg;base64,", "");
      // }
      const imageName = "name.png";
      const imageBlob = this.dataURItoBlob(surveyImage);
      image = new File([imageBlob], imageName, {
        type: "image/png",
      });
    }
    const formData: FormData = new FormData();
    formData.append("surveyImage", image, image.name);

    return this.http
      .request(
        new HttpRequest(
          "POST",
          `${environment.api_url}/survey/save-image`,
          formData,
          {
            headers: new HttpHeaders({
              "X-Requested-With": "application/XMLHttpRequest",
              Security: "Nmd@E>5.D]q.rE3:",
              Software: "web",
              Authorization: String(
                this.localStorageService.get("accessToken") || ""
              ),
            }),
          }
        )
      )
      .toPromise();
    // .subscribe(
    //   (event) => {
    //     if (event.type === HttpEventType.Response) {
    //     }
    //   },
    //   (error) => { }
    // );
  }

  saveQuestionImage(questionsImages) {
    const formData: FormData = new FormData();
    let singleIndex = 1;
    let multiIndex = 1;
    let evalIndex = 1;
    let textIndex = 1;
    for (let index = 0; index < questionsImages.length; index++) {
      if (questionsImages[index].answerTypeLabel === "SingleChoice") {
        formData.append(
          `singleQuestions.sQ${singleIndex++}.image`,
          questionsImages[index].image,
          questionsImages[index].image.name
        );
      } else if (questionsImages[index].answerTypeLabel === "MultipleChoice") {
        formData.append(
          `multiQuestions.mQ${multiIndex++}.image`,
          questionsImages[index].image,
          questionsImages[index].image.name
        );
      } else if (questionsImages[index].answerTypeLabel === "Evaluation") {
        formData.append(
          `evaluationQuestions.eQ${evalIndex++}.image`,
          questionsImages[index].image,
          questionsImages[index].image.name
        );
      } else if (questionsImages[index].answerTypeLabel === "Text") {
        formData.append(
          `textQuestions.tQ${textIndex++}.image`,
          questionsImages[index].image,
          questionsImages[index].image.name
        );
      }
    }
    this.http
      .request(
        new HttpRequest(
          "POST",
          `${environment.api_url}/survey/save-questions-image`,
          formData,
          {
            headers: new HttpHeaders({
              "X-Requested-With": "application/XMLHttpRequest",
              Security: "Nmd@E>5.D]q.rE3:",
              Software: "web",
              Authorization: String(
                this.localStorageService.get("accessToken") || ""
              ),
            }),
          }
        )
      )
      .subscribe(
        (event) => {
          if (event.type === HttpEventType.Response) {
          }
        },
        (error) => {}
      );
  }

  publishSurvey(survey: any) {
    let data = { ...survey };

    // let fd = new FormData();
    // fd.append('image', data.image);
    // data.image = fd;
    // delete data.imageUrl;

    // let fdBg = new FormData();
    // fdBg.append('backGroundImage', data.backGroundImage);
    // data.backGroundImage = fdBg;
    // delete data.backGroundImageUrl;

    delete data.image;
    delete data.backGroundImage;
    delete data.backGroundImageType;

    data.questions?.map((q) => {
      // if(q.image) {
      //   let formData = new FormData();
      //   formData.append('image', q.image);
      //   q.image = formData;
      // }
      // delete q.imageUrl;
      delete q.image;

      q.answers?.forEach((a) => {
        // if(a.image) {
        //   let formData = new FormData();
        //   formData.append('image', a.image);
        //   a.image = formData;
        // }
        delete a.image;
        // delete a.imageUrl;
      });
    });
    return this.webApi.postObserveResponse(`/survey/publish`, data);
  }
  surveyUpdate(survey: any) {
    let data = { ...survey };

    delete data.image;
    delete data.backGroundImage;
    delete data.backGroundImageType;

    data.questions?.map((q) => {
      delete q.image;
      q.answers?.forEach((a) => {
        delete a.image;
      });
    });
    return this.webApi.postObserveResponse(`/survey/${survey.id}`, data);
  }
  getAllSurvey(page, limit, key = "isFeatured") {
    return this.webApi.get(
      `/survey/tabs?page=${page}&limit=${limit}&key=${key}`
    );
  }
  async promoCodeSubmit(data) {
    const formData: FormData = new FormData();
    formData.append("file", data.file);
    formData.append("title", data.title);
    formData.append("titleArabic", data.titleArabic);
    formData.append("description", data.description);
    formData.append("descriptionArabic", data.descriptionArabic);
    formData.append("image", data.image);
    formData.append("type", data.type);

    // Return a promise
    return this.http
      .request(
        new HttpRequest("POST", `${environment.api_url}/promoCode`, formData, {
          headers: new HttpHeaders({
            "X-Requested-With": "application/XMLHttpRequest",
            Security: "Nmd@E>5.D]q.rE3:",
            Software: "web",
            Authorization: String(
              this.localStorageService.get("accessToken") || ""
            ),
          }),
          reportProgress: true,
        })
      )
      .pipe(
        catchError((error) => {
          console.error("Error submitting promo code:", error);
          return throwError(error); // Throw an error observable
        })
      )
      .toPromise();
  }

  getSurveyById(id) {
    return this.webApi.get(`/survey/${id}`);
  }

  getSurveyReports(id) {
    return this.webApi.get(`/survey/report/${id}`);
  }
  getSurveyFIleDownload(id) {
    return this.webApi.get(`/survey/${id}/fileDownload`);
  }
  getUserFileDownload() {
    return this.webApi.get(`/survey/userFileDownload`);
  }
  answerSurvey(data: any) {
    return this.webApi.post(`/filledSurvey`, data);
  }
  surveyRating(data: any) {
    return this.webApi.post(`/surveyRating`, data);
  }
  ClickedSurvey(data: any) {
    return this.webApi.post(`/clickedSurvey`, data);
  }
  getAllFilledSurvey() {
    return this.webApi.get("/survey/allfilledSurvey");
  }
  createBrandManagement(data: any) {
    return this.webApi.post(`/brandManagement`, data);
  }
  updateBrandManagement(data: any) {
    return this.webApi.put(`/brandManagement/${data._id}`, data);
  }

  getFilledSurveyById(surveyId) {
    return this.webApi.get(`/survey/filledSurvey/${surveyId}`);
  }

  likeSurvey(id: any) {
    return this.webApi.post(`/survey/like/${id}`);
  }

  getInteractionByCategory(category) {
    return this.webApi.post(`/interactionByCategory`, {
      categoryName: [category],
    });
  }

  getSurveyReport(id: any) {
    return this.webApi.get(`/report/survey/${id}`);
  }
  createClaimedCode(data: any) {
    return this.webApi.post(`/claimedSurveyCode`, data);
  }
  deleteSurvey(id: any) {
    return this.webApi.delete(`/survey/${id}`);
  }
  edit(survey) {
    const id = survey.id;
    this.getSurveyById(id)
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.createQuestionSurvey({
            questions: result.questions ? result.questions : [],
          });
          const multiQuestList = result.questions ? result.questions : [];
          const { questions, id, ...rest } = survey;
          const data = { ...rest, multiQuestList, _id: id };

          this.createLocalSurvey({
            surveyId: survey?.id,
            title: survey.title,
            description: survey.description,
            categoryId: survey.categoryId,
            ...data,
          });
          this.createOptionsSurvey({
            ...survey,
            usersValue: survey.totalResponse,
            maxSending: survey.spendingPoints,
          });
          localStorage.setItem("survey", JSON.stringify(data));
          setTimeout(() => {
            this.router.navigate(["/create-second-custom-survey"]);
          }, 3000);
        }
      });
  }

  stop(survey) {
    if (survey.isPublished || survey.isFeatured) {
      let data = {
        surveyId: survey.id,
        isFeatured: false,
        isPublished: false,
      };
      return this.webApi.post(`/survey/stop`, data);
    } else {
      let data = {
        surveyId: survey.id,
        isFeatured: true,
        isPublished: true,
      };
      return this.webApi.post(`/survey/stop`, data);
    }
  }

  // private allocateTimeUnits(startDate: string) {
  //   const date = new Date(startDate);
  //   const timeDifference = date.getTime() - new  Date().getTime();
  //   const secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
  //   const minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
  //   const hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
  //   const daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  // }
}
