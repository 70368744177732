import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { eLanguage } from 'src/app/general/enums/language.enum';
import { AuthService } from 'src/services/auth.service';
import { DarkLiteThemeService } from 'src/services/dark-lite-themeService.service';
import { HomeService } from 'src/services/home.service';
import { LanguageService } from 'src/services/language.service';
import { SurveyService } from 'src/services/survey.service';
import * as moment from 'moment';
import { CreateSurveyComponent } from '../create-survey/create-survey.component';
import { CommingSoonModalComponent } from '../loggedin-home/comming-soon-modal/comming-soon-modal.component';
import { NewServeyComponent } from '../new-servey/new-servey.component';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

declare const gtag: Function;

@Component({
  selector: 'app-my-new-dashboard',
  templateUrl: './my-new-dashboard.component.html',
  styleUrls: ['./my-new-dashboard.component.css']
})
export class MyNewDashboardComponent implements OnInit {

  visibleSurveys: any[] = [];
  currentIndex = 0;
  displayCount = 2;
  moment: any = moment;

  userStats: any
  slideConfig: OwlOptions = {
    loop: true,
    mouseDrag: false,
    margin: 12,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    animateOut: "fadeOut",
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 1,
      },
      900: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    navSpeed: 700,
    nav: true,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  newSlideConfig: OwlOptions = {
    loop: true,
    mouseDrag: false,
    margin: 12,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    animateOut: "fadeOut",
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 1,
      },
      900: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
    navSpeed: 700,
    nav: true,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  userSurveys = [];
  userSurveySlider = []
  tableSurvey = [];
  user: any;
  currentUser: any;
  filterText = "All";
  mobile: Number;
  showAllSurveys: boolean = false;
  language: string = eLanguage.English;

  web: Number;
  female: Number;
  male: Number;
  surveys: any;
  spendingPoints: any;
  answers: any;
  likes: any;
  clicked: any;
  referrals: any;
  current_rotation: Number;
  locale = eLanguage.Short_English;
  elanguage = eLanguage;
  currentLanguage: any;
  dynamicStyles: { [key: string]: string } = {};

  showLoader = false;
  constructor(
    private homeService: HomeService,
    public authService: AuthService,
    public darkThemeService: DarkLiteThemeService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private surveyService: SurveyService,
    public languageService: LanguageService
  ) {
    this.currentUser = this.authService.currentUser;
    this.currentLanguage = this.languageService.getLanguageOrDefault();
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    if (this.currentLanguage === eLanguage.Arabic) {
      this.slideConfig.rtl = true;
    }
  }

  ngOnInit(): void {
    this.getUserSurveys();
    this.getUserAllStatsReport();

    setInterval(() => {
      this.next();
    }, 10000);

    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
      if (result.matches) {
        // For XSmall and Small screens (<= 768px), display one item
        this.displayCount = 1;
      } else {
        // For screens larger than 768px, display two items
        this.displayCount = 2;
      }

      this.updateVisibleSurveys(this.userSurveySlider);
    });
  }

  getUserAllStats() {
    this.showLoader = true;
    this.homeService.getUserStats().subscribe((result) => {
      this.userStats = result;
      this.showLoader = false;
    });
  }

  getUserSurveys() {
    this.showLoader = true
    let data = [];
    this.homeService.getMySurvey().subscribe((result) => {
      if (result?.length) {
        data = result
        this.userSurveys = data?.length ? this.homeService.deepCopy(data) : [];
        this.userSurveySlider = data?.length ? this.homeService.deepCopy(data.filter((item: any) => item.isPublished)) : [];
        this.tableSurvey = data?.length ? this.homeService.deepCopy(data) : [];

        this.updateVisibleSurveys(this.userSurveySlider);

      }
      this.showLoader = false
    });
  }

  onPointsOrResponseClick(survey: any) {
    survey.pointsOrResponse =
      survey.pointsOrResponse == "points" ? "responses" : "points";
  }

  openSurvey() {
    this.router.navigate(["/new-survey"]);
  }

  createSurvey() {
    this.surveyService.removeBrand()

    gtag('event', 'button_click', {
      event_category: 'Button',
      event_label: 'Create new survey',
    });
    const verifyModel = this.modalService.show(CreateSurveyComponent, {
      class:
        "modal-lg modal-dialog-centered sign-up c-survey-modal  create-survey-modal",
      backdrop: "static",
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
  }
  async copyUrl(surveyId: string, surveyType: string, language: string) {
    this.homeService.copySurveyUrl(surveyId, surveyType);
  }

  openComingSoonModal() {
    const entModel = this.modalService.show(CommingSoonModalComponent, {
      class: "redeem-modal-container modal-dialog-centered",

      // backdrop: 'static',
    });
    entModel.content.onClose = () => {
      entModel.hide();
    };
  }
  CustomSurvey() {
    this.surveyService.removeBrand()
    // this.router.navigate([`/new-survey`]);
    const verifyModel = this.modalService.show(NewServeyComponent, {
      class: "new-survey create-survey-modal",
      backdrop: "static",
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
  }
  deleteSurvey(survey: any) {
    this.surveyService.deleteSurvey(survey.id).subscribe((result) => {
      this.translateService.get("general.delete").subscribe((text) => {
        this.toastr.success(text);
      });
    });

    this.getUserSurveys();
    this.getUserAllStatsReport();
    this.getUserAllStats();
  }
  stopSurvey(survey: any) {
    this.surveyService.stop(survey).subscribe((result) => {
      // this.translateService.get("newDasboard.update").subscribe((text) => {
      //   this.toastr.success(text);
      // });
    });

    this.getUserSurveys();
    this.getUserAllStatsReport();
    // this.getUserAllStats();
  }

  edit(survey) {
    this.surveyService.edit(survey);
  }
  toggleShowAllSurveys() {
    this.showAllSurveys = !this.showAllSurveys;
  }

  detail(survey: any) {
    // this.surveyService.createLocalSurveyDetail(survey);
    // this.router.navigate(["/survey/details"]);
    this.surveyService.createLocalSurveyModal(survey);
    this.router.navigate([`/survey/details`]);
  }

  getDynamicStyles() {
    if (this.currentLanguage === this.elanguage.Short_Arabic) {
      this.dynamicStyles = {
        "align-items": "end !important", // Example: Add Arabic-specific styles
      };
    } else {
      this.dynamicStyles = {
        "align-items": "flex-start !important",
      };
    }
    return this.dynamicStyles;
  }
  filterSurvey(key, value: any): void {
    this.filterText = key;
    this.tableSurvey = this.userSurveys.filter(
      (survey) => survey[key] == value
    );
  }
  AllUserSurvey() {
    this.filterText = "All";
    this.tableSurvey = this.userSurveys;
  }

  getUserAllStatsReport() {
    // this.showLoader = true;
    this.homeService.getUserStats().subscribe((result) => {
      this.female = result?.female;
      this.clicked = result?.UserReport?.clicked?.length;
      this.male = result?.male;
      this.mobile = result?.mobile;
      this.web = result?.web;
      this.answers = result?.answers;
      this.referrals = result?.referrals;
      this.surveys = result?.surveys;
      this.spendingPoints = result?.spendingPoints;
      this.web = result?.web;
      this.likes = result?.likes;
      // this.showLoader = false;
    });
  }

  date() {
    const start = (document.getElementById("startDate") as HTMLInputElement)
      .value;
    const end = (document.getElementById("endDate") as HTMLInputElement).value;

    if (start && end) {
      console.log("startDate", start, end);
      const filterData = this.userSurveys.filter((item) => {
        return (
          new Date(item.startDate).getTime() >= new Date(start).getTime() &&
          new Date(item.endDate).getTime() <= new Date(end).getTime()
        );
      });
      this.tableSurvey = filterData.map((x) => {
        return x;
      });
    } else {
      setTimeout(() => {
        this.tableSurvey = this.userSurveys;
        // this.sortPR();
      }, 2000);
    }
  }

  sortPR() {
    this.tableSurvey.sort((a, b) => {
      let fa = a.startDate,
        fb = b.startDate;
      if (fa < fb) {
        return 1;
      }
      if (fa > fb) {
        return -1;
      }
      return 0;
    });
  }
  refresh() {
    this.showLoader = true
    this.getUserAllStatsReport();
    // this.getUserAllStats();
    var myImage = document.getElementById("myImage");
    if (myImage.style.transform == "rotate(-360deg)") {
      myImage.style.transform = "rotate(360deg)";
      myImage.style.transition = "transform 1s linear";

      this.showLoader = false
    } else {
      myImage.style.transform = "rotate(-360deg)";
      myImage.style.transition = "transform 1s linear";
      this.showLoader = false
    }

  }

  prev() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateVisibleSurveys(this.userSurveySlider);
    }
  }

  next() {
    if (this.currentIndex < this.userSurveySlider.length - this.displayCount) {
      this.currentIndex++;
      this.updateVisibleSurveys(this.userSurveySlider);
    } else {
      this.currentIndex = 0;
      this.updateVisibleSurveys(this.userSurveySlider);
    }
  }

  updateVisibleSurveys(data: any) {
    this.visibleSurveys = data.slice(this.currentIndex, this.currentIndex + this.displayCount);
  }
}

