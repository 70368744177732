<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLongTitle" *ngIf="!homeService.isLogin">
    {{ "login.sign in" | translate }}
  </h5>
  <h5 class="modal-title text-capitalize" id="exampleModalLongTitle" *ngIf="homeService.isLogin">
    {{ "header.join us" | translate }}
  </h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <img (click)="close()" src="assets/imgs/times-icon.svg" alt="" />
  </button>
</div>
<div class="modal-header">
  <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
    <li class="nav-item">
      <a class="nav-link active" id="mobile-tab" data-toggle="tab" href="#mobile" (click)="selectTab('mobile')"
        role="tab" aria-controls="mobile" aria-selected="false">{{ "login.Mobile" | translate }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="email-tab" data-toggle="tab" (click)="selectTab('email')" href="#email" role="tab"
        aria-controls="email" aria-selected="true">{{ "login.mail" | translate }}</a>
    </li>
  </ul>
</div>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="mobile" role="tabpanel" aria-labelledby="mobile-tab">
    <div class="modal-body">
      <form [formGroup]="form">
        <div class="input-group d-flex" [ngClass]="{
            invalid:
              form.get('phoneNumber').invalid && form.get('phoneNumber').dirty
          }">
          <div class="input-group-prepend">
            <!-- <span
            class="input-group-text inputGroup"
            id="inputGroup-sizing-default"
            >+966</span
          > -->
            <mat-form-field floatLabel="never ">
              <mat-label class="code-label">Code</mat-label>
              <mat-select formControlName="phoneCode" name="food" aria-placeholder="code"
                class="input-group-text inputGroup cursor-pointer position-container" id="inputGroup-sizing-default"
                disableOptionCentering panelClass="myPanelClass" (closed)="onPanelClose()"
                (selectionChange)="countryCodeUpdate($event)">
                <input matInput formControlName="filterControl" type="text" name="filter-options" id="filter-options"
                  placeholder="Search" class="searchCodeInput" />
                <mat-select-trigger class="position-container">
                  <i class="fi fi-{{ countryFlag }}"></i>
                  <span class="mx-2">{{ countryCode }}</span>
                </mat-select-trigger>
                <mat-option class="position-container" aria-placeholder="Code" *ngFor="let c of filteredOptions | async"
                  [attr.data-selectedCode]="c.isoCode.toLowerCase()" value="{{ c.phonecode }}"
                  [ngClass]="{ hide: !c?.show }">
                  <i class="fi fi-{{ c.isoCode.toLowerCase() }}"></i>
                  {{ c.phonecode }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <input type="tel" class="inputText" formControlName="phoneNumber" maxlength="13"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" required
            placeholder="{{ 'signup.5xxxxxxxx*' | translate }}" aria-label="Default"
            aria-describedby="inputGroup-sizing-default" />

        </div>
        <!-- <div class="s-input" [ngClass]="{'invalid':form.get('email').invalid && form.get('email').dirty}">
          <input formControlName="email" type="text" placeholder="{{'login.Your Mobile' | translate}}">
          <i class="fas fa-mobile"></i>
        </div> -->
        <!-- <div
          class="input-group mb-3"
          [ngClass]="{
            invalid:
              form.get('phoneNumber').invalid && form.get('phoneNumber').dirty
          }"
        >
          <div class="input-group-prepend">
            <span
              class="input-group-text inputGroup"
              id="inputGroup-sizing-default"
              >+966</span
            >
          </div>
          <input
            type="number"
            class="inputText"
            formControlName="phoneNumber"
            placeholder="{{ 'signup.5xxxxxxxx*' | translate }}"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
          />
        </div> -->
        <!-- <div class="s-input" [ngClass]="{
            'has-danger':
              form.get('password').invalid && form.get('password').dirty
          }">
          <input formControlName="password" type="password" placeholder="{{ 'login.Enter Password' | translate }}" />
          <i class="fas fa-lock"></i>
        </div> -->
        <div class="s-input checkbox  m-2">
          <input type="checkbox" id="rememberMe" />
          <label for="rememberMe">
            {{ "login.Remember Me" | translate }}
          </label>
        </div>
        <button (click)="processLogin()" class="m-btn m-btn-green w-100"
          [disabled]="!form.value.phoneNumber && !form.value.phoneCode">
          {{ "login.sign in" | translate }}
        </button>

      </form>
    </div>
  </div>
  <div class="tab-pane fade show" id="email" role="tabpanel" aria-labelledby="email-tab">
    <div class="modal-body">
      <form [formGroup]="form">
        <div class="s-input" [ngClass]="{
            invalid: form.get('email').invalid && form.get('email').dirty
          }">
          <input formControlName="email" type="email" placeholder="{{ 'login.Your Email' | translate }}" />
          <i class="fas fa-user"></i>
        </div>
        <!-- <div class="s-input" [ngClass]="{
            'has-danger':
              form.get('password').invalid && form.get('password').dirty
          }">
          <input formControlName="password" type="password" placeholder="{{ 'login.Enter Password' | translate }}" />
          <i class="fas fa-lock"></i>
        </div> -->
        <div class="s-input checkbox m-2">
          <input type="checkbox" id="rememberMe" />
          <label for="rememberMe">
            {{ "login.Remember Me" | translate }}
          </label>
        </div>
        <button (click)="processLogin()" class="m-btn m-btn-green w-100" [disabled]="!form.value.email">
          {{ "login.sign in" | translate }}
        </button>
      </form>
    </div>

  </div>
  <div class="text-center my-3 font-18 font-weight-bold">
    OR
  </div>
  <div class="m-auto social-container">
    <button (click)="loginWithGoogle()" class="google-btn"><img src="/assets/imgs/google.png" width="30" /></button>
    <!-- <button class="google-btn"><img src="/assets/imgs/apple.png" width="30" /></button> -->
  </div>

  <div class="d-flex">
    <!-- <a (click)="signup()">{{ "login.New Account" | translate }}</a> -->
    <!-- <a (click)="forgetPassword()">{{
      "login.Forget Password?" | translate
      }}</a> -->
    <!-- </div> -->
  </div>

  <app-loader [show]="showLoader"></app-loader>