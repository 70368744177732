import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import * as Chart from "chart.js";
import { HomeService } from "../../../services/home.service";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { saveAs } from "file-saver";
import { WebApiService } from "../../../services/webApi.service";
import * as Highcharts from "highcharts/highmaps";

@Component({
  selector: "app-my-stats",
  templateUrl: "./my-stats.component.html",
  styleUrls: ["./my-stats.component.css"],
})
export class MyStatsComponent implements OnInit {
  title = "chartjsangular";
  showLoader = false;
  anwersChart: any;
  onClose: any;
  anwersChartContext: any;
  contactChart: any;
  contactChartContext: any;
  barChart: any;
  barChartContext: any;
  graphChart: any;
  graphChartContext: any;
  canvas: any;
  ctx: any;
  canvas1: any;
  ctx1: any;
  canvas2: any;
  ctx2: any;
  canvas3: any;
  ctx3: any;
  web = 30;
  mobile = 70;
  male = 30;
  label: string[] = [];
  count: number[] = [];
  female = 70;
  userStatsReport: any;
  userStats: any;
  worldMap: any;
  country: any;
  kafu: 60;
  free: 1;
  total: any;
  test: any;

  mapChartData = [];
  mapChartOptions: Highcharts.Options;
  Highcharts: typeof Highcharts = Highcharts;
  chartData: any = {
    labels: ["Kafu Network", "free"],
    datasets: [
      {
        label: "Total cases.",
        data: [60, 30],
        backgroundColor: ["rgba(129, 212, 250, 1)", "rgba(206, 147, 216, 1)"],
        borderWidth: 1,
      },
    ],
  };
  lineData: any = {
    labels: ["survey1", "survey2", "survey3", "survey4", "survey5"],
    datasets: [
      {
        label: "",
        fill: false,
        data: [3000, 2000, 4000, 5000, 6000],
        backgroundColor: ["rgb(159, 168, 218)"],
        borderColor: "rgb(159, 168, 218)",
        borderWidth: 2,
      },
    ],
  };
  constructor(
    private homeService: HomeService,
    private webApi: WebApiService,
    private toastr: ToastrService,
    private _http: HttpClient
  ) {}
  ngOnInit() {
    this.getWorldMap();
    this.getUserAllStats();
    // this.getUserAllStatsReport();
    this.createLineChart();
    this.createContactChart();
    this.createBarChart();
    this.createGraph();
  }
  getUserAllStatsReport() {
    this.homeService.getUserReport().subscribe((result) => {
      console.log("object result", result);
      if (result) {
        this.userStatsReport = result;
        this.female = result && result.female ? result.female : 0;
        this.male = result && result.male ? result.male : 0;
        this.mobile = result && result.mobile ? result.mobile : 0;
        this.web = result && result.web ? result.web : 0;
      }
    });
  }

  countDuplicates(arr: string[]) {
    return Object.entries(
      arr.reduce((counts, str) => {
        counts[str] = (counts[str] || 0) + 1;
        return counts;
      }, {})
    ).map(([name, count]) => ({ name, count }));
  }

  arrlabel(strArray) {
    strArray.forEach((str) => {
      const index = this.label.indexOf(str);
      if (index !== -1) {
        this.count[index]++;
      } else {
        this.label.push(str);
        this.count.push(1);
      }
    });
  }

  getUserAllStats() {
    this.homeService.getUserStats().subscribe((result) => {
      this.userStats = result;
      this.female = result && result.female ? result.female : 0;
      this.male = result && result.male ? result.male : 0;
      this.mobile = result && result.mobile ? result.mobile : 0;
      this.web = result && result.web ? result.web : 0;
      this.total = result.country?.length;
      this.kafu = result.UserReport.kafu ?? 0;
      this.free = result.UserReport.free ?? 0;
      this.test = this.arrlabel(result.UserReport?.clicked);
      this.country = this.countDuplicates(result?.country);
      this.updateChartData();
      this.updateLineChartData();
    });
    console.log("label", this.label, this.count);
  }

  getWorldMap() {
    this._http
      .get("https://code.highcharts.com/mapdata/custom/world.topo.json")
      .subscribe((x) => {
        console.log("world map res: ", x);
        this.worldMap = x;

        this.mapChartData = [
          { code3: "ABW", z: 105 },
          { code3: "AFG", z: 35530 },
        ];

        this.mapChartOptions = {
          chart: {
            map: this.worldMap,
          },
          title: {
            text: "Highmaps basic demo",
          },
          subtitle: {
            text: 'Source map: <a href="http://code.highcharts.com/mapdata/custom/world.js">World, Miller projection, medium resolution</a>',
          },
          mapNavigation: {
            enabled: true,
            buttonOptions: {
              alignTo: "spacingBox",
            },
          },
          legend: {
            enabled: true,
          },
          colorAxis: {
            min: 0,
          },
          series: [
            {
              type: "map",
              name: "Random data",
              states: {
                hover: {
                  color: "#BADA55",
                },
              },
              dataLabels: {
                enabled: true,
                format: "{point.name}",
              },
              allAreas: false,
              data: [
                ["fo", 0],
                ["um", 1],
                ["us", 2],
                ["jp", 3],
                ["sc", 4],
                ["in", 5],
                ["fr", 6],
                ["fm", 7],
                ["cn", 8],
                ["pt", 9],
                ["sw", 10],
                ["sh", 11],
                ["br", 12],
                ["ki", 13],
                ["ph", 14],
                ["mx", 15],
                ["es", 16],
                ["bu", 17],
                ["mv", 18],
                ["sp", 19],
                ["gb", 20],
                ["gr", 21],
                ["as", 22],
                ["dk", 23],
                ["gl", 24],
                ["gu", 25],
                ["mp", 26],
                ["pr", 27],
                ["vi", 28],
                ["ca", 29],
                ["st", 30],
                ["cv", 31],
                ["dm", 32],
                ["nl", 33],
                ["jm", 34],
                ["ws", 35],
                ["om", 36],
                ["vc", 37],
                ["tr", 38],
                ["bd", 39],
                ["lc", 40],
                ["nr", 41],
                ["no", 42],
                ["kn", 43],
                ["bh", 44],
                ["to", 45],
                ["fi", 46],
                ["id", 47],
                ["mu", 48],
                ["se", 49],
                ["tt", 50],
                ["my", 51],
                ["pa", 52],
                ["pw", 53],
                ["tv", 54],
                ["mh", 55],
                ["cl", 56],
                ["th", 57],
                ["gd", 58],
                ["ee", 59],
                ["ag", 60],
                ["tw", 61],
                ["bb", 62],
                ["it", 63],
                ["mt", 64],
                ["vu", 65],
                ["sg", 66],
                ["cy", 67],
                ["lk", 68],
                ["km", 69],
                ["fj", 70],
                ["ru", 71],
                ["va", 72],
                ["sm", 73],
                ["kz", 74],
                ["az", 75],
                ["tj", 76],
                ["ls", 77],
                ["uz", 78],
                ["ma", 79],
                ["co", 80],
                ["tl", 81],
                ["tz", 82],
                ["ar", 83],
                ["sa", 84],
                ["pk", 85],
                ["ye", 86],
                ["ae", 87],
                ["ke", 88],
                ["pe", 89],
                ["do", 90],
                ["ht", 91],
                ["pg", 92],
                ["ao", 93],
                ["kh", 94],
                ["vn", 95],
                ["mz", 96],
                ["cr", 97],
                ["bj", 98],
                ["ng", 99],
                ["ir", 100],
                ["sv", 101],
                ["sl", 102],
                ["gw", 103],
                ["hr", 104],
                ["bz", 105],
                ["za", 106],
                ["cf", 107],
                ["sd", 108],
                ["cd", 109],
                ["kw", 110],
                ["de", 111],
                ["be", 112],
                ["ie", 113],
                ["kp", 114],
                ["kr", 115],
                ["gy", 116],
                ["hn", 117],
                ["mm", 118],
                ["ga", 119],
                ["gq", 120],
                ["ni", 121],
                ["lv", 122],
                ["ug", 123],
                ["mw", 124],
                ["am", 125],
                ["sx", 126],
                ["tm", 127],
                ["zm", 128],
                ["nc", 129],
                ["mr", 130],
                ["dz", 131],
                ["lt", 132],
                ["et", 133],
                ["er", 134],
                ["gh", 135],
                ["si", 136],
                ["gt", 137],
                ["ba", 138],
                ["jo", 139],
                ["sy", 140],
                ["mc", 141],
                ["al", 142],
                ["uy", 143],
                ["cnm", 144],
                ["mn", 145],
                ["rw", 146],
                ["so", 147],
                ["bo", 148],
                ["cm", 149],
                ["cg", 150],
                ["eh", 151],
                ["rs", 152],
                ["me", 153],
                ["tg", 154],
                ["la", 155],
                ["af", 156],
                ["ua", 157],
                ["sk", 158],
                ["jk", 159],
                ["bg", 160],
                ["qa", 161],
                ["li", 162],
                ["at", 163],
                ["sz", 164],
                ["hu", 165],
                ["ro", 166],
                ["ne", 167],
                ["lu", 168],
                ["ad", 169],
                ["ci", 170],
                ["lr", 171],
                ["bn", 172],
                ["iq", 173],
                ["ge", 174],
                ["gm", 175],
                ["ch", 176],
                ["td", 177],
                ["kv", 178],
                ["lb", 179],
                ["dj", 180],
                ["bi", 181],
                ["sr", 182],
                ["il", 183],
                ["ml", 184],
                ["sn", 185],
                ["gn", 186],
                ["zw", 187],
                ["pl", 188],
                ["mk", 189],
                ["py", 190],
                ["by", 191],
                ["cz", 192],
                ["bf", 193],
                ["na", 194],
                ["ly", 195],
                ["tn", 196],
                ["bt", 197],
                ["md", 198],
                ["ss", 199],
                ["bw", 200],
                ["bs", 201],
                ["nz", 202],
                ["cu", 203],
                ["ec", 204],
                ["au", 205],
                ["ve", 206],
                ["sb", 207],
                ["mg", 208],
                ["is", 209],
                ["eg", 210],
                ["kg", 211],
                ["np", 212],
              ],
            },
          ],
        };
      });
  }
  createLineChart() {
    this.anwersChart = document.getElementById("anwersChart");
    this.anwersChartContext = this.anwersChart.getContext("2d");
    new Chart(this.anwersChartContext, {
      type: "line",
      data: this.lineData,
      options: {
        responsive: false,
        scales: {
          yAxes: [{ display: true }],
          xAxes: [
            {
              display: true, //this will remove all the x-axis grid lines
            },
          ],
        },
      },
    });
  }
  updateChartData() {
    // Update the data for the chart
    this.chartData.datasets[0].data = [this.kafu, this.free];
    // Re-render the chart with the updated data
    new Chart(this.contactChartContext, {
      type: "pie",
      data: this.chartData,
      options: {
        cutoutPercentage: 70,
        responsive: false,
        // display:true
      },
    });
  }
  updateLineChartData() {
    // Update the data for the chart
    console.log(this.label, this.count);
    this.lineData.labels = this.count.map((item, i) => `survey${i + 1}`);
    this.lineData.datasets[0].data = this.count;
    // Re-render the chart with the updated data
    new Chart(this.anwersChartContext, {
      type: "line",
      data: this.lineData,
      options: {
        responsive: false,
        scales: {
          yAxes: [{ display: true }],
          xAxes: [
            {
              display: true, //this will remove all the x-axis grid lines
            },
          ],
        },
      },
    });
  }
  createContactChart() {
    this.contactChart = document.getElementById("contactChart");
    this.contactChartContext = this.contactChart.getContext("2d");
    new Chart(this.contactChartContext, {
      type: "pie",
      data: this.chartData,
      options: {
        cutoutPercentage: 70,
        responsive: false,
        // display:true
      },
    });
  }

  createBarChart() {
    this.barChart = document.getElementById("barChart");
    this.barChartContext = this.barChart.getContext("2d");
    let myChart3 = new Chart(this.barChartContext, {
      type: "bar",
      data: {
        labels: ["2017", "2018", "2019", "2020", "2021"],
        datasets: [
          {
            label: "Kafu ",
            backgroundColor: "rgb(206, 147, 216)",
            borderWidth: 1,
            data: [350, 400, 500, 450, 350],
          },
          {
            label: "Promote",
            backgroundColor: "rgb(129, 212, 251)",
            data: [310, 500, 460, 380, 410],
          },
          {
            label: "Free",
            backgroundColor: "rgb(159, 168, 218)",
            data: [410, 445, 556, 218, 210],
          },
        ],
      },

      options: {
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: false,
        // display: true
      },
    });
  }

  createGraph() {
    this.graphChart = document.getElementById("graphChart");
    this.graphChartContext = this.graphChart.getContext("2d");
    let myChart1 = new Chart(this.graphChartContext, {
      type: "line",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "june",
          "july",
          "may",
          "august",
          "September",
          "233",
          "22",
          "23",
        ],
        datasets: [
          {
            label: "",
            fill: true,
            data: [4, 3, 4, 2, 5, 3, 6, 3, 8, 6, 9, 5],
            backgroundColor: ["rgb(179, 158, 217)"],
            borderColor: "#ffbd35",
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{ display: false }],
          xAxes: [
            {
              display: false, //this will remove all the x-axis grid lines
            },
          ],
        },
      },
    });
  }

  basicReportDownload() {
    const data = { name: "basic" };
    this.webApi.basicPdfReport(data).subscribe((response: any) => {
      console.log(response, "whats here");
      let blob: any = new Blob([response], { type: response.type });
      saveAs(blob, "report.pdf");
    }),
      (error: any) => console.log("Error downloading the file", error),
      () => console.info("File downloaded successfully");
  }
}
