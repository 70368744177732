import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "../../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Country, ICountry } from "country-state-city";
import { HomeService } from "src/services/home.service";
import { LanguageService } from "../../../services/language.service";
import { first, map, startWith } from "rxjs/operators";
import { SignupComponent } from "../signup/signup.component";
import { ForgetPasswordComponent } from "../forget-password/forget-password.component";
import * as allCountries from "src/assets/const/countries.json";
import { MessagingService } from "../../../services/messaging.service";
import { VerifyComponent } from "../verify/verify.component";
import { GoogleLoginProvider, SocialAuthService } from "angularx-social-login";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  showLoader = false;
  onClose: any;
  form: FormGroup;
  countries: ICountry[] = [];
  countryFlag: string;
  countryCode: string;
  filteredOptions: any;
  language: string = "en";
  message: any;
  selectedTab = "mobile";
  emailErrorToastShown = false;
  mobileErrorToastShown = false;

  fcmToken: any;
  constructor(
    public languageService: LanguageService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    public modal: BsModalRef,
    public homeService: HomeService,
    private socialAuthService: SocialAuthService,
    private messagingService: MessagingService
  ) {}

  ngOnInit() {
    this.language = this.languageService.getLanguageOrDefault();
    console.log(
      "🚀 ~ file: login.component.ts:50 ~ LoginComponent ~ ngOnInit ~ language:",
      this.language
    );
    this.form = this.formBuilder.group({
      // validate email or phone
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          ),
        ],
      ],
      phoneNumber: [
        null,
        [Validators.maxLength(12), Validators.pattern(/^.{6,12}$/)],
      ],
      phoneCode: ["", [Validators.required]],
      // password: [null, [Validators.required, Validators.pattern(/^.{6,16}$/)]],
      filterControl: [""],
    });
    this.getCountries();
    this.getFCMToken();

    this.filteredOptions = this.form.get("filterControl").valueChanges.pipe(
      startWith(""),
      map((value: string) => {
        this.countries.forEach((option) => {
          option["show"] = option.phonecode
            .toLocaleLowerCase()
            .includes(value.toLowerCase());
        });
        return this.countries;
      })
    );
  }
  onPanelClose() {
    this.form.get("filterControl").setValue("");
  }
  countryCodeUpdate(e) {
    const code = e?.source?.selected?._element;
    this.countryFlag = code?.nativeElement?.dataset?.selectedcode;
    this.countryCode = this.form.get("phoneCode").value;
  }

  close(Result = null) {
    this.onClose(Result);
  }

  loginWithGoogle(): void {
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((user) => {
        this.authService
          .social({
            type: "google",
            token: user?.idToken,
            fcmToken: this.fcmToken,
          })
          .subscribe((result) => {
            this.close({});
            this.authService.setCurrentUser(
              result.body.user,
              result.body.headers.AuthToken
            );
            this.authService.LoginTimeUpdate(result.body.user);
          });
      })
      .catch((error) => {
        this.translateService
          .get("login.auth_error_message")
          .subscribe((text) => {
            this.toastr.error(text);
          });
        console.error("Error during Google Sign-In:", error);
        // Handle the error as needed
      });
  }

  // processLogin() {
  //   this.showLoader = true;
  //   const loginData = {
  //     email: this.form.value.email,
  //     phonecode: this?.form?.value?.phoneCode || "",
  //     phoneNumber: this.form.value.phoneCode + this.form.value.phoneNumber,
  //     // password: this.form.value.password,
  //   };
  //   this.authService
  //     .Login(loginData)
  //     .pipe(first())
  //     .subscribe(
  //       (result) => {
  //         this.close({});
  //         this.verify(result.headers.get("AuthToken"));

  //         this.showLoader = false;

  //         this.close(result);
  //       },
  //       (err) => {
  //         this.showLoader = false;
  //         this.translateService
  //           .get("login.auth_error_message")
  //           .subscribe((text) => {
  //             this.toastr.error(text);
  //           });
  //       }
  //     );
  // }
  // processLogin() {
  //   this.showLoader = true;
  //   const loginData = {
  //     email: this.form.value.email,
  //     phonecode: this?.form?.value?.phoneCode || "",
  //     phoneNumber: this.form.value.phoneCode + this.form.value.phoneNumber,
  //     // password: this.form.value.password,
  //   };
  //   this.authService
  //     .Login(loginData)
  //     .pipe(first())
  //     .subscribe(
  //       (result) => {
  //         this.close({});
  //         this.verify(result.headers.get("AuthToken"));
  //         this.showLoader = false;
  //         this.close(result);
  //       },
  //       (err) => {
  //         this.showLoader = false;
  //         this.translateService
  //           .get("login.auth_error_message")
  //           .subscribe((text) => {
  //             this.toastr.error(text);
  //           });
  //       }
  //     );
  // }

  // processLogin() {
  //   this.showLoader = true;

  //   if (this.selectedTab === "mobile") {
  //     // Mobile Tab
  //     if (this.form.get("phoneCode").invalid) {
  //       // Handle validation errors for the mobile tab
  //       // For example, display a message or mark the invalid fields
  //       this.showLoader = false;
  //       return this.translateService.get("login.Code").subscribe((text) => {
  //         this.toastr.error(text);
  //       });
  //     } else if (this.form.get("phoneNumber").value < 9) {
  //       this.showLoader = false;
  //       return this.translateService.get("login.phone").subscribe((text) => {
  //         this.toastr.error(text);
  //       });
  //     }

  //     const loginData = {
  //       phoneCode: this.form.value.phoneCode || "",
  //       phoneNumber: this.form.value.phoneCode + this.form.value.phoneNumber,
  //     };

  //     this.authService
  //       .Login(loginData)
  //       .pipe(first())
  //       .subscribe(
  //         (result) => {
  //           console.log(result);
  //           this.close({});
  //           this.verify(result.body.headers.AuthToken, result.body);
  //           this.showLoader = false;
  //           this.close(result);
  //         },
  //         (err) => {
  //           this.showLoader = false;
  //           this.translateService
  //             .get("login.auth_error_message")
  //             .subscribe((text) => {
  //               this.toastr.error(text);
  //             });
  //         }
  //       );
  //   } else if (this.selectedTab === "email") {
  //     // Email Tab
  //     const regex =
  //       /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //     if (
  //       !this.form.get("email").value ||
  //       !this.form.get("email").value.match(regex)
  //     ) {
  //       console.log(
  //         "🚀 ~ file: login.component.ts:197 ~ LoginComponent ~ processLogin ~ email:",
  //         this.form.value.email
  //       );
  //       // Handle validation errors for the email tab
  //       // For example, display a message or mark the invalid field
  //       this.showLoader = false;
  //       return this.translateService.get("login.email").subscribe((text) => {
  //         this.toastr.error(text);
  //       });
  //     }
  //     const loginData = {
  //       email: this.form.value.email,
  //       phoneCode: null,
  //       phoneNumber: null,
  //     };

  //     this.authService
  //       .Login(loginData)
  //       .pipe(first())
  //       .subscribe(
  //         (result) => {
  //           this.close({});
  //           this.verify(result.body.headers.AuthToken, result.body);
  //           this.showLoader = false;
  //           this.close(result);
  //         },
  //         (err) => {
  //           this.showLoader = false;
  //           this.translateService
  //             .get("login.auth_error_message")
  //             .subscribe((text) => {
  //               this.toastr.error(text);
  //             });
  //         }
  //       );
  //   }
  // }
  processLogin() {
    this.showLoader = true;

    if (this.selectedTab === "mobile") {
      // Mobile Tab
      if (this.form.get("phoneCode").invalid) {
        this.showLoader = false;
        if (!this.mobileErrorToastShown) {
          this.mobileErrorToastShown = true;
          return this.translateService.get("login.Code").subscribe((text) => {
            this.toastr.error(text);
          });
        }
      } else if (this.form.get("phoneNumber").value < 9) {
        this.showLoader = false;
        if (!this.mobileErrorToastShown) {
          this.mobileErrorToastShown = true;
          return this.translateService.get("login.phone").subscribe((text) => {
            this.toastr.error(text);
          });
        }
      }

      const loginData = {
        phoneCode: this.form.value.phoneCode || "",
        phoneNumber: this.form.value.phoneCode + this.form.value.phoneNumber,
        fcmToken: this.fcmToken,
      };

      this.authService
        .Login(loginData)
        .pipe(first())
        .subscribe(
          (result) => {
            this.close({});
            this.verify(result.body.headers.AuthToken, result.body);
            this.authService.LoginTimeUpdate(result.body.user);

            this.showLoader = false;
            this.close(result);
          },
          (err) => {
            this.showLoader = false;
            this.translateService
              .get("login.auth_error_message")
              .subscribe((text) => {
                this.toastr.error(text);
              });
          }
        );
    } else if (this.selectedTab === "email") {
      // Email Tab
      const regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (
        !this.form.get("email").value ||
        !this.form.get("email").value.match(regex)
      ) {
        this.showLoader = false;
        if (!this.emailErrorToastShown) {
          this.emailErrorToastShown = true;
          return this.translateService.get("login.email").subscribe((text) => {
            this.toastr.error(text);
          });
        }
      }
      const loginData = {
        email: this.form.value.email,
        fcmToken: this.fcmToken,
        phoneCode: null,
        phoneNumber: null,
      };

      this.authService
        .Login(loginData)
        .pipe(first())
        .subscribe(
          (result) => {
            this.close({});
            this.verify(result.body.headers.AuthToken, result.body);
            this.authService.LoginTimeUpdate(result.body.user);
            this.showLoader = false;
            this.close(result);
          },
          (err) => {
            this.showLoader = false;
            this.translateService
              .get("login.auth_error_message")
              .subscribe((text) => {
                this.toastr.error(text);
              });
          }
        );
    }
  }

  verify(token, data) {
    this.close({});
    const verifyModel = this.modalService.show(VerifyComponent, {
      class: "modal-dialog-centered login",
      backdrop: "static",
      initialState: {
        forget: false,
        authToken: token,
        login: data.isVerified,
        phoneNumber: this.form.value.phoneNumber,
        phoneCode: this.form.value.phoneCode,
      },
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
  }

  getFCMToken() {
    this.messagingService.requestPermissionNew().subscribe(
      (token) => {
        console.log("FCM Token:", token);
        this.fcmToken = token;
        // Now you can use the FCM token as needed
      },
      (error) => {
        console.error("Error getting FCM Token:", error);
      }
    );
  }

  signup() {
    this.close({});
    const signupModel = this.modalService.show(SignupComponent, {
      class: "modal-dialog-centered sign-up",
      backdrop: "static",
    });
    signupModel.content.onClose = (auth) => {
      signupModel.hide();
    };
  }
  selectTab(tab: string) {
    this.selectedTab = tab;
    // You can perform additional actions here when a tab is selected
    console.log("Selected tab:", tab);
  }
  forgetPassword() {
    this.close({});
    const forgetModel = this.modalService.show(ForgetPasswordComponent, {
      class: "modal-dialog-centered login",
      backdrop: "static",
    });
    forgetModel.content.onClose = (auth) => {
      forgetModel.hide();
    };
  }
  getCountries() {
    this.countries = this.homeService.getCountries();
  }
}
