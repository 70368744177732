import { Component, OnInit } from "@angular/core";
import { HomeService } from "src/services/home.service";
import * as moment from "moment";
import { first } from "rxjs/operators";
import { LanguageService } from "../../../services/language.service";
import { Router } from "@angular/router";
import { SurveyService } from "../../../services/survey.service";
import { eLanguage } from "src/app/general/enums/language.enum";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";

@Component({
  selector: "app-my-surveys",
  templateUrl: "./my-surveys.component.html",
  styleUrls: ["./my-surveys.component.css"],
})
export class MySurveysComponent implements OnInit {
  mySurvey: any;
  showLoader = false;
  surveyFinished: any;
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  question = [];
  survey = {
    all: [],
    active: {
      all: [],
      display: [],
      displayLimit: 0,
    },
    archive: {
      all: [],
      display: [],
      displayLimit: 0,
    },
    rejected: {
      all: [],
      display: [],
      displayLimit: 0,
    },
    liked: {
      all: [],
      display: [],
      displayLimit: 0,
    },
  }
  language: string = eLanguage.English;

  constructor(public homeService: HomeService, private router: Router,
    private surveyService: SurveyService, public languageService: LanguageService, public darkThemeService: DarkLiteThemeService,

  ) {
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
  }

  ngOnInit(): void {
    // this.MySurveys();
    this.getAllSurveys();
  }
  MySurveys() {
    this.homeService.getMySurvey().subscribe((result) => {
      if (result?.length) {
        let archiveSurvey = result?.filter((x: { isPublished: any; }) => !x?.isPublished);
        let active = result?.filter((x: { isPublished: any; }) => x?.isPublished);
        let rejectedSurvey = result?.filter((x: { isRejected: any; }) => x.isRejected);
        let all = result?.filter((x: { isRejected: any; }) => x.isRejected);

        this.survey.archive = archiveSurvey;
        this.survey.active = active;
        this.survey.rejected = rejectedSurvey;
        this.survey.all = all;
        this.activeSurvey();


      }
    });
  }
  getAllSurveys() {
    let data = [];
    this.homeService.getMySurvey().subscribe(
      (result) => {
        if (result?.length) {
          data = result
          // .filter((item: any) => {
          //   // Find the distance between now and the count down date
          //   let surveyFinished =
          //     new Date(item.endDate).getTime() - new Date().getTime();
          //   item["days"] = Math.floor(surveyFinished / (1000 * 60 * 60 * 24));
          //   item["hours"] = Math.floor(
          //     (surveyFinished % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          //   );
          //   item["minutes"] = Math.floor(
          //     (surveyFinished % (1000 * 60 * 60)) / (1000 * 60)
          //   );
          //   item["seconds"] = Math.floor((surveyFinished % (1000 * 60)) / 1000);
          //   return (
          //     new Date(item.startDate) <= new Date() &&
          //     moment().isBefore(moment(item.endDate), "minute") &&
          //     item.language === this.language
          //   );
          // });
          this.survey.all = data?.length ? this.homeService.deepCopy(data) : [];
          if (this.survey.all.length) {
            this.survey.all = this.survey.all.map((x) => {
              x.isStartClicked = false;
              return x;
            });
            this.activeSurvey();
            // this.ArchiveSurvey();
            // this.rejectedSurvey();
            // this.likedSurvey();
          }
        }

        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
      }
    );
  }
  edit(survey: any) {
    this.surveyService.edit(survey);
  }
  navigate(survey: any) {
    this.router.navigateByUrl('survey/details');
  }
  activeSurvey() {
    console.log('active survey')
    this.survey.active.all = this.homeService.deepCopy(
      this.survey.all.filter((x) => x.isPublished)
    );
  }
  // ArchiveSurvey() {

  // }
  // rejectedSurvey() {
  //   this.survey.active.all = this.homeService.deepCopy(
  //     this.survey.all.filter((x) => x.isRejected)
  //   );
  // }
  // likedSurvey() {

  // }
}
