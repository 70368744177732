import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeModule } from './home/home.module';
import { APP_BASE_HREF } from '@angular/common';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => HomeModule,
  },
  {
    path: "**",
    redirectTo: "/not-found",
  },
];

@NgModule({
  providers: [
    //   {
    //     provide: LocationStrategy,
    //     useClass: PathLocationStrategy
    //   },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    }
  ],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
