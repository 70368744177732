import { Component, NgZone, OnInit } from "@angular/core";
import {
  FormBuilder,
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { eSurveyQuestionTypes } from "src/app/general/enums/survey-question-type.enum";
import { SurveyTypes } from "src/app/general/enums/survey-types.enum";
import { AuthService } from "src/services/auth.service";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { HomeService } from "src/services/home.service";
import { SurveyService } from "src/services/survey.service";
import { eLanguage } from "src/app/general/enums/language.enum";
import { LanguageService } from "src/services/language.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "app-create-second-custom-survey",
  templateUrl: "./create-second-custom-survey.component.html",
  styleUrls: ["./create-second-custom-survey.component.css"],
})
export class CreateSecondCustomSurveyComponent implements OnInit {
  surveyForm!: FormGroup;
  eSurveyType: SurveyTypes;
  eQuesTypes = eSurveyQuestionTypes;
  imageSrc: string | ArrayBuffer | null = null;
  loading: boolean = false;
  elanguage = eLanguage;
  retrievedSurveyData: any; // This will hold the retrieved user data
  isButtonDisabled = false;
  isSidebarVisible = true;
  questionAdvancedOptions: Set<string>[] = [];

  selectedOptions: any[] = [];

  selectOptionValidation: boolean = false;

  advancedQuestion = {
    quickWorkflow: "quickWorkflow",
    isArrange: "isArrange",
    addAnswerVisual: "addAnswerVisual",
    isYesNo: "isYesNo",
    isadditionalText: "isadditionalText",
    noneOfAbove: "noneOfAbove",
    isOptional: "isOptional",
  };
  firstAnswerSaved: boolean = false;

  resData: any;
  resDataEdit: {
    index?: number;
    isEdit?: boolean;
  }[] = [];

  hideButton: boolean = false;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private fb: FormBuilder,
    public authService: AuthService,
    private homeService: HomeService,
    private surveyService: SurveyService,
    private toasterService: ToastrService,
    public languageService: LanguageService,
    private translateService: TranslateService,
    public darkThemeService: DarkLiteThemeService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    const survey = this.surveyService.getLocalSurvey();
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => {
        if (result.matches) {
          this.isSidebarVisible = false;
        }
      });
    if (!survey) {
      this.router.navigate(["/"]);
    }

    this.createSurveyFrom(survey);

    for (let i = 0; i < this.multiQuestionDynamicArray().length; i++) {
      this.questionAdvancedOptions.push(new Set<string>());
    }

    const surveyValue = localStorage.getItem("survey");
    if (surveyValue) {
      this.resData = JSON.parse(surveyValue);
      console.log(
        "🚀 ~ file: create-second-custom-survey.component.ts:86 ~ CreateSecondCustomSurveyComponent ~ ngOnInit ~ resData:",
        this.resData
      );

      while (this.multiQuestList.controls.length !== 0) {
        this.multiQuestList.removeAt(0);
      }
      this.surveyForm.patchValue(this.resData, { emitEvent: false });
      const questionList = this.resData?.multiQuestList;
      questionList.forEach((element: any) => {
        const answers = element.answers.map((answer: any) =>
          this.answerDynamicFormGroup(answer)
        );
        const questionFormGroup = this.fb.group({
          ...element,
          answers: this.fb.array(answers),
        });
        this.multiQuestList.push(questionFormGroup);
        this.hideButton = true;
      });
    }
  }

  get multiQuestList() {
    return this.surveyForm.get("multiQuestList") as FormArray;
  }

  /**
   * Creates a survey form.
   *
   * @param data - Optional data to populate the form with.
   */
  private createSurveyFrom(data?: any) {
    this.surveyForm = this.fb.group({
      id: new FormControl(data?.surveyId ? data.surveyId : null),
      title: new FormControl(data.title ? data.title : "", [
        Validators.required,
      ]),
      categoryId: new FormControl(data.categoryId ? data.categoryId : null, [
        Validators.required,
      ]),
      questionCost: new FormControl(""),
      description: new FormControl(data.description ? data.description : "", [
        Validators.required,
      ]),
      language: new FormControl(data.language == "ar" ? "Arabic" : "English", [
        Validators.required,
      ]),
      surveyImageUrl: [data.surveyImageUrl],
      multiQuestList: this.fb.array([this.multiQuestionDynamicFormGroup()]),
    });
  }
  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
  multiQuestionDynamicFormGroup(): FormGroup {
    return this.fb.group({
      question: [
        "",
        Validators.compose([Validators.required, Validators.minLength(10)]),
      ],
      questionType: [""],
      quickWorkflow: [false],
      isArrange: [false],
      addAnswerVisual: [false],
      isYesNo: [false],
      isadditionalText: [false],
      currentOptionIndex: [],
      additionalText: [""],
      noneOfAbove: [false],
      isOptional: [false],
      ImageFile: [undefined],
      answers: this.fb.array([
        this.answerDynamicFormGroup({}),
        this.answerDynamicFormGroup({}),
      ]),
    });
  }

  multiQuestionDynamicFormValidations = {
    question: [
      { type: "required", message: "Question is required" },
      {
        type: "minlength",
        message: "Question must be at least 10 characters long",
      },
    ],
    // questionType: [{ type: "required", message: "Question type is required" }],
    // additionalText: [
    //   { type: "required", message: "Additional text is required" },
    // ],
  };

  answerFormValidations = {
    option: [
      { type: "required", message: "Answer is required." },
      {
        type: "minlength",
        message: "Answer must be at least 3 characters long.",
      },
    ],
  };

  answerDynamicFormGroup(answerData: any): FormGroup {
    if (answerData.option == "") {
      return this.fb.group({
        optionIndex: [answerData.optionIndex],
        option: [answerData.option],
        image: [null],
        imageUrl: [answerData.imageUrl],
      });
    } else {
      return this.fb.group({
        optionIndex: [answerData.optionIndex],
        option: [answerData.option, Validators.compose([Validators.required])],
        image: [null],
        imageUrl: [answerData.imageUrl],
      });
    }
  }

  multiQuestionDynamicArray(): FormArray {
    return this.surveyForm.get("multiQuestList") as FormArray;
  }

  onFileChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];
    if (file?.size >= 2000000) {
      this.translateService
        .get("survey.backgroundImageSize")
        .subscribe((text) => {
          this.toasterService.warning(text);
        });
      return;
    }

    if (file) {
      // Read the selected file and set it to the FormControl
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageSrc = e.target?.result;
        const base64String = reader.result;

        this.surveyForm.get("surveyImageUrl")?.setValue(base64String);
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage() {
    this.surveyForm.get("surveyImageUrl")?.setValue(null);
    this.imageSrc = null;
  }

  addMultiQuestionsRow() {
    const survey = this.surveyService.getLocalSurvey();
    const surveyMultiQuestListLength = survey.multiQuestList.length;
    const formMultiQuestListLength =
      this.surveyForm.get("multiQuestList").value.length;

    if (formMultiQuestListLength === surveyMultiQuestListLength) {
      if (this.multiQuestionDynamicArray().length < 100) {
        const newQuestion = this.multiQuestionDynamicFormGroup();
        this.multiQuestionDynamicArray().push(newQuestion);

        // Initialize the advanced options set for the new question
        this.questionAdvancedOptions.push(new Set<string>());
      }
      this.hideButton = false;
    } else {
      // Lengths are not equal, do not add a new question
      console.log(
        "Lengths of multiQuestList arrays are not equal. Skipping addition of a new question."
      );
    }

    // if (survey)
  }

  removeMultiQuestionsRow(index: number) {
    if (this.multiQuestionDynamicArray().length > 1) {
      this.multiQuestionDynamicArray().removeAt(index);
    }
  }

  shouldShowError(
    input?: any,
    validation?: { type: string; message: string }
  ): string {
    if (input?.hasError(validation?.type) && (input?.dirty || input?.touched)) {
      return "block";
    } else {
      return "none";
    }
  }

  checkValidations(formGroup: FormGroup): void {
    if (formGroup.invalid) {
      formGroup.markAllAsTouched();
    }
  }

  multiQuestFormArrayControls(): AbstractControl[] {
    return (this.surveyForm.get("multiQuestList") as FormArray)?.controls;
  }

  answerDynamicArray(questionGroup: FormGroup): FormArray {
    return questionGroup.get("answers") as FormArray;
  }

  addAnswerRow(questionGroup: FormGroup) {
    const answerArray = this.answerDynamicArray(questionGroup);
    const lastAnswer = answerArray.at(answerArray.length - 1) as FormGroup;

    if (lastAnswer) {
      const lastOptionIndex = lastAnswer.get("optionIndex").value as number;
      const newOptionIndex = lastOptionIndex !== null ? lastOptionIndex + 1 : 1;

      answerArray.push(
        this.answerDynamicFormGroup({ optionIndex: newOptionIndex })
      );
    }
  }

  removeAnswerRow(questionGroup: FormGroup, answerIndex: number) {
    const answerArray = this.answerDynamicArray(questionGroup);
    if (answerArray.length > 1) {
      answerArray.removeAt(answerIndex);
    }
  }

  selectOption(option: any, questionIndex: number) {
    const questionGroup = this.multiQuestionDynamicArray().at(
      questionIndex
    ) as FormGroup;
    console.log(this.selectedOptions[questionIndex], "select option");

    if (this.selectedOptions[questionIndex] === option) {
      this.selectOptionValidation = false;
      // Deselect the option
      this.selectedOptions[questionIndex] = "";
      questionGroup.patchValue({
        questionType: "",
        isYesNo: false,
      });
      // Remove the "Yes" and "No" options when deselected
      this.clearYesNoOptions(questionGroup);
    } else {
      // Select the option
      this.selectedOptions[questionIndex] = option;
      questionGroup.patchValue({
        questionType: option,
        // isYesNo: true
      });
      this.updateAnswersFormArray(questionIndex);
      this.selectOptionValidation = true;

      if (option === this.eQuesTypes.YES_OR_NO) {
        // Set the default "Yes" and "No" options
        const answersArray = questionGroup.get("answers") as FormArray;
        answersArray.at(0).get("optionIndex").setValue(1);
        answersArray.at(0).get("option").setValue("Yes");
        answersArray.at(1).get("optionIndex").setValue(2);
        answersArray.at(1).get("option").setValue("No");
      }
    }
  }

  clearYesNoOptions(questionGroup: FormGroup) {
    const answersArray = questionGroup.get("answers") as FormArray;
    answersArray.clear();
  }

  generateAnswerFormGroups(questionType: eSurveyQuestionTypes): FormGroup[] {
    if (questionType === eSurveyQuestionTypes.TEXT) {
      return [this.answerDynamicFormGroup({ optionIndex: 1, option: "" })];
    } else if (questionType === eSurveyQuestionTypes.EVALUATION) {
      return [
        this.answerDynamicFormGroup({ optionIndex: 1, option: null }),
        this.answerDynamicFormGroup({ optionIndex: 2, option: null }),
        this.answerDynamicFormGroup({ optionIndex: 3, option: null }),
      ];
    } else if (questionType === eSurveyQuestionTypes.YES_OR_NO) {
      return [
        this.answerDynamicFormGroup({ optionIndex: 1, option: "Yes" }),
        this.answerDynamicFormGroup({ optionIndex: 2, option: "No" }),
      ];
    } else {
      return [
        this.answerDynamicFormGroup({ optionIndex: 1, option: null }),
        this.answerDynamicFormGroup({ optionIndex: 2, option: null }),
      ];
    }
  }

  updateAnswersFormArray(questionIndex: number) {
    const selectedQuestionType = this.selectedOptions[questionIndex];
    const answerFormGroups =
      this.generateAnswerFormGroups(selectedQuestionType);
    const questionGroup = this.multiQuestionDynamicArray().at(
      questionIndex
    ) as FormGroup;
    const answersArray = questionGroup.get("answers") as FormArray;
    answersArray.clear();
    answerFormGroups.forEach((answerFormGroup) =>
      answersArray.push(answerFormGroup)
    );
  }

  toggleSelection(option: string, questionIndex: number) {
    if (this.questionAdvancedOptions[questionIndex].has(option)) {
      this.questionAdvancedOptions[questionIndex].delete(option);
    } else {
      this.questionAdvancedOptions[questionIndex].add(option);
    }
  }

  getAdvancedOptions(questionIndex: number): string[] {
    return Array.from(this.questionAdvancedOptions[questionIndex]);
  }

  getImageforEveryQuestionChange(event: Event, questionIndex: number) {
    this.ngZone.run(() => {
      const inputElement = event.target as HTMLInputElement;
      const file = inputElement.files?.[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target?.result as string;
          const questionGroup = this.multiQuestionDynamicArray().at(
            questionIndex
          ) as FormGroup;
          questionGroup.get("ImageFile")?.setValue(base64Image);
        };
        reader.readAsDataURL(file);
      }
    });
  }

  getQuestionImage(questionIndex: number): string | undefined {
    const questionGroup = this.multiQuestionDynamicArray().at(
      questionIndex
    ) as FormGroup;
    return questionGroup.get("ImageFile").value;
  }

  removeQuestionImage(questionIndex: number) {
    const questionGroup = this.multiQuestionDynamicArray().at(
      questionIndex
    ) as FormGroup;
    questionGroup.patchValue({
      ImageFile: null,
    });
  }

  onFileChangeForAnswersIndividual(
    event: Event,
    questionIndex: number,
    answerIndex: number
  ) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target?.result as string;
        const questionGroup = this.multiQuestionDynamicArray().at(
          questionIndex
        ) as FormGroup;
        const answerArray = this.answerDynamicArray(questionGroup);
        const answerGroup = answerArray.at(answerIndex) as FormGroup;

        answerGroup.get("imageUrl").setValue(base64Image);
      };

      reader.readAsDataURL(file);
    }
  }

  getAnswerImageForAnswer(questionIndex: number, answerIndex: number): string {
    const questionGroup = this.multiQuestionDynamicArray().at(
      questionIndex
    ) as FormGroup;
    const answerArray = this.answerDynamicArray(questionGroup);
    const answerGroup = answerArray.at(answerIndex) as FormGroup;

    return answerGroup.get("imageUrl").value;
  }

  submitQuestion() {
    this.loading = true;
    if (this.surveyForm.invalid) {
      this.surveyForm.markAllAsTouched();
      this.loading = false;

      return;
    }

    const multiQuestList = this.surveyForm.get("multiQuestList") as FormArray;
    multiQuestList.controls.forEach((control: FormGroup, index: number) => {
      const advancedOptions = this.getAdvancedOptions(index);
      control.patchValue({
        quickWorkflow: advancedOptions
          .includes(this.advancedQuestion.quickWorkflow)
          .toString(),
        isArrange: advancedOptions
          .includes(this.advancedQuestion.isArrange)
          .toString(),
        isYesNo: advancedOptions
          .includes(this.advancedQuestion.isYesNo)
          .toString(),
        addAnswerVisual: advancedOptions
          .includes(this.advancedQuestion.addAnswerVisual)
          .toString(),
        isadditionalText: advancedOptions
          .includes(this.advancedQuestion.isadditionalText)
          .toString(),
        noneOfAbove: advancedOptions
          .includes(this.advancedQuestion.noneOfAbove)
          .toString(),

        isOptional: advancedOptions
          .includes(this.advancedQuestion.isOptional)
          .toString(),
      });
    });
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 3000);
    const questionCount = multiQuestList.length;

    let questionCost = 3; // Default cost for 0-5 questions

    if (questionCount > 5 && questionCount <= 10) {
      questionCost = 4;
    } else if (questionCount > 10) {
      questionCost = 5;
    }

    this.surveyForm.patchValue({
      questionCost: questionCost,
    });
  }

  Next() {
    // this.surveyService.createLocalSurvey({
    //   ...this.surveyForm.value,
    //   //  language: this.locale,
    // });
    const multiQuestList = this.surveyForm.get("multiQuestList") as FormArray;
    const questionCount = multiQuestList.length;
    if (questionCount >= 1) {
      this.router.navigate(["/create-forth-custom-survey"]);
    } else {
      this.translateService.get("newSurveySecond.minimum").subscribe((text) => {
        this.toasterService.error(text);
      });
    }
  }

  submitData(formGroup: FormGroup): void {
    this.isButtonDisabled = true;
    if (this.selectOptionValidation === false) {
      this.translateService
        .get("newSurveySecond.QuestionType")
        .subscribe((text) => {
          this.toasterService.error(text);
        });
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 3000);
      return;
    }

    if (formGroup.invalid) {
      debugger;
      formGroup.markAllAsTouched();
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 3000);
      return;
    } else if (!this.authService.currentUser?._id) {
      this.surveyService.createLocalSurvey({
        ...this.surveyForm.value,
        //  language: this.locale,
      });
      this.resData = this.surveyForm.value;
      this.hideButton = true;
      this.resDataEdit = [];
      localStorage.setItem("survey", JSON.stringify(this.surveyForm.value));
      this.surveyForm.patchValue(this.surveyForm.value);
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 3000);
    } else {
      const surveyId = localStorage.getItem("survey");
      if (surveyId) {
        this.retrievedSurveyData = JSON.parse(surveyId);
      }
      const data = {
        ...this.surveyForm.value,
        surveyId: this?.retrievedSurveyData?._id,
        id: this?.retrievedSurveyData?._id,
      };
      this.surveyService.saveSurvey(data).subscribe(
        (res) => {
          this.surveyService.createLocalSurvey(res.body);
          this.resData = res.body;
          setTimeout(() => {
            this.isButtonDisabled = false;
          }, 3000);
          this.translateService.get("survey.saveSurvey").subscribe((text) => {
            this.toasterService.success(text);
          });
        },
        (err) => {
          console.log(
            "🚀 ~ file: create-second-custom-survey.component.ts:437 ~ CreateSecondCustomSurveyComponent ~ this.surveyService.saveSurvey ~ err:",
            err
          );
        },
        () => {
          localStorage.setItem("survey", JSON.stringify(this.resData));
          this.surveyForm.patchValue(this.resData);
          this.resDataEdit = [];
          this.hideButton = true;
        }
      );
    }
  }

  editQuestion(index: number) {
    this.resDataEdit.push({
      index,
      isEdit: true,
    });
  }

  isEdit(index: number): boolean {
    if (!this.resDataEdit) {
      return false;
    }
    return this.resDataEdit.some((item) => item.index === index);
  }

  removeQuestion(index: number) {
    this.resDataEdit = [];
    const question = this.resData.multiQuestList[index];
    this.resData.multiQuestList.splice(index, 1);
    const multiQuestListArray = this.surveyForm.get(
      "multiQuestList"
    ) as FormArray;
    multiQuestListArray.removeAt(index);
    localStorage.setItem("survey", JSON.stringify(this.resData));
    this.translateService.get("survey.Question_removed").subscribe((text) => {
      this.toasterService.success(text);
    });
    if (this.resData.multiQuestList.length === 0) {
      localStorage.removeItem("survey");
      this.hideButton = false;
    }
  }

  PreviewData() {
    this.loading = true;
    this.router.navigate(["/create-third-custom-survey"]);
  }
}
