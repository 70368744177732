import { Injectable } from '@angular/core';
import { WebApiService } from './webApi.service';

@Injectable()
export class RechargeService {

    constructor(private webApi: WebApiService) {
    }
    rechargeBalance(data: any) {
        return this.webApi.post(`/user/recharge`, data);
    }
    userRechargeWallet(data: any) {
        return this.webApi.post(`/user/walletRecharge`, data);
    }
}
