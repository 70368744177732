<div class="new-survey modal-content" [ngClass]="darkThemeService.getCurrentTheme">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center p-3 px-md-4 mb-3" style="width: inherit">
      <div class="d-flex align-items-center">
        <a href="https://kafuapp.com/" class="my-0 mr-md-auto font-weight-normal">
          <img [src]="
              darkThemeService.getCurrentTheme == 'light-mode'
                ? 'assets/imgs/logo.svg'
                : 'assets/imgs/logo-dark.png'
            " alt="" style="width: 50%" />
        </a>
      </div>
      <div>
        <h4 class="heading1"> {{ "fastAction.FAST"| translate }}</h4>
        <p class="paragraph sub">{{ "fastAction.sub"| translate }}</p>
      </div>
    </div>
  </div>
  <div class="pricing-container mt-5">
    <div class="card-deck mb-3 text-center">
      <div class="card mb-4 card1">
        <div class="card-body">
          <div class="card-img-top">
            <div class="image-box">
              <img [src]="
                  darkThemeService.getCurrentTheme == 'light-mode'
                    ? 'assets/imgs/new-survey/survey-light-one.png'
                    : 'assets/imgs/new-survey/survey-one.png'
                " alt="" class="img-fixed bg-color1" />
            </div>
          </div>
          <h1 class="card-title pricing-card-title font-heading">
            {{ "fastAction.start"| translate }}
          </h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>
              <p class="paragraph startSub">{{ "fastAction.startSub"| translate }}
              </p>
            </li>
          </ul>
          <div class="btnfixing">
            <button type="button" class="btn btn-lg btn-primary1" (click)="createCustomSurvey()">
              {{ "fastAction.Create"| translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="card mb-4 card2">
        <div class="card-body">
          <div class="card-img-top">
            <div class="image-box">
              <img [src]="
                  darkThemeService.getCurrentTheme == 'light-mode'
                    ? 'assets/imgs/new-survey/survey-light-two.png'
                    : 'assets/imgs/new-survey/survey-two.png'
                " alt="" class="bg-color2 img-fixed" />
            </div>
          </div>
          <h1 class="card-title pricing-card-title font-heading">
            {{ "fastAction.template"| translate }}
          </h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>
              <p class="paragraph">
                {{ "fastAction.templateSub"| translate }}
              </p>
            </li>
            <li>
              <div style="text-align: center" class="mt-2">
                <span class="text-area">{{ "fastAction.download"| translate }}</span><span class="text-area"
                  style="text-decoration: underline">{{ "fastAction.HERE"| translate }}</span>
              </div>
            </li>
          </ul>
          <div class="btnfixing">
            <button type="button" class="btn btn-lg btn-primary2" (click)="openComingSoonModal()">
              {{ "fastAction.Create"| translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="card mb-4 card3">
        <div class="card-body">
          <div class="card-img-top">
            <div class="image-box">
              <img [src]="
                  darkThemeService.getCurrentTheme == 'light-mode'
                    ? 'assets/imgs/new-survey/survey-light-three.png'
                    : 'assets/imgs/new-survey/survey-three.png'
                " alt="" class="bg-color3 img-fixed" />
            </div>
          </div>
          <h1 class="card-title pricing-card-title">
            <div>
              <span class="font-heading">{{ "fastAction.power"| translate }}</span>
              <span style="
                  color: #f05c6d;
                  font-size: 14px;
                  font-weight: 700;
                  text-transform: uppercase;
                  word-wrap: break-word;
                ">{{ "fastAction.Beta"| translate }}</span>
            </div>
          </h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>
              <p class="paragraph">
                {{ "fastAction.powerSub"| translate }}
              </p>
            </li>
          </ul>
          <div class="btnfixing">
            <button type="button" class="btn btn-lg btn-primary3" (click)="openComingSoonModal()">
              {{ "fastAction.Create"| translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>