<app-header></app-header>
<section class="thankyou-wrapper">
  <div class="thankyou-banner-container">
    <div class="thankyou-banner">
      <div *ngIf="language === 'Arabic'; else elseBlockArabic">
        <h2 class="thankyouArabic">
          {{ "survey.thankYou" | translate }}
        </h2>
      </div>
      <ng-template #elseBlockArabic>
        <h2 class="thankyou">
          {{ "survey.thankYou" | translate }}
        </h2>
      </ng-template>

    </div>
    <!-- <img src="./assets/imgs/thankyou-banner.svg" class="thankyou-banner" alt=""> -->
  </div>

  <div class="row justify-content-center text-center mb-4">
    <div class="col-md-8 col-12 mb-2">
      <h4 class="ty-heading">
        {{ "survey.weHopeYouEnjoyThisSurvey" | translate }}
      </h4>
    </div>
    <div class="col-md-5 col-12">
      <p class="ty-text">{{ "survey.thankyouText" | translate }}</p>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-md-12 col-11">
      <div class="row justify-content-center mb-4">
        <div class="col-md-6 col-12">
          <div class="feedback-container">
            <div class="row text-center">
              <div class="col-12">
                <div *ngIf="language === 'Arabic'; else elseBlock">
                  <div class="col-12">
                    <img
                      [src]="'thankYouData?.logo?.url' ? 'http://kafuapp.com/assets/imgs/logo.svg' : 'thankYouData?.logo?.url'"
                      class="company-feedback-icon" alt="" />

                  </div>
                </div>
                <ng-template #elseBlock>
                  <div class="col-12">

                  </div>
                </ng-template>
              </div>
              <div *ngIf="language === 'Arabic'; else elseBlock">
                <div class="col-12">
                  <h2> {{ this?.title}}</h2>
                  <p class="cashback-text">
                    {{ this?.description }}
                  </p>
                </div>
              </div>
              <ng-template #elseBlock>
                <div class="col-12">

                </div>
              </ng-template>

            </div>

            <div class="row justify-content-center mb-3">
              <div class="col-md-8 col-9">
                <div class="step-box">
                  <h4 class="step-heading">
                    {{ "survey.Step1" | translate }}
                  </h4>
                  <h4 class="step-title">
                    {{ "survey.likeThisSurvey" | translate }}
                  </h4>
                </div>
              </div>
              <div class="col-md-2 col-3">
                <div>
                  <button #btn type="button" class="heart-icon" [class.selected]="isLiked"
                    (click)="onLikeClick(); btn.disabled = true;"></button>
                </div>
                <!-- <div (click)="onLikeClick(); "></div> -->
              </div>
            </div>

            <div class="row justify-content-center mb-4">
              <div class="col-md-8 col-9">
                <div class="step-box">
                  <h4 class="step-heading">
                    {{ "survey.Step2" | translate }}
                  </h4>
                  <h4 class="step-title">
                    {{ "survey.shopAndGetCashback" | translate }}
                  </h4>
                </div>
              </div>
              <div class="col-md-2 col-3 d-flex align-self-center">
                <div *ngIf="language === 'Arabic'; else elseBlock">
                  <div class="col-12">
                    <a href="#">
                      <button class="btn m-btn-green m-border-radius-30 visitShop">
                        {{ "survey.visitShop" | translate }}
                      </button>
                    </a>
                  </div>
                </div>
                <ng-template #elseBlock>
                  <div class="col-12">
                    <a href="#">
                      <button class="btn m-btn-green m-border-radius-30 visitShop" disabled>
                        {{ "survey.visitShop" | translate }}
                      </button>
                    </a>
                  </div>
                </ng-template>

              </div>
            </div>

            <!-- <div class="row">
              <div class="col-12">
                <p class="discount-terms-note">
                  {{ "survey.discountTermsAndRightsNote" | translate }}
                </p>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-3 col-12 mb-3">
          <div class="share">
            <p class="align-self-center font-12 font-weight-bold">
              {{ "survey.bePartOfKafuFamily" | translate }}
            </p>
            <button class="m-btn m-btn-gradient-no-hover align-self-center" (click)="signup()">
              {{ "header.join us" | translate }}
            </button>
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="share">
            <p class="align-self-center font-12 font-weight-bold">
              {{ "survey.shareSurveyWithYourFriends" | translate }}
            </p>
            <button class="m-btn m-btn-share align-self-center" (click)="copyUrl()">
              <i class="fas fa-share-alt mr-2"></i>
              {{ "survey.share" | translate }}
            </button>
          </div>
        </div>
      </div>
      <section id="survey" class="survyes">
        <div class="container-fluid">
          <div class="text-center">
            <h3 class="kf-title">{{ "home.surveysPoll" | translate }}</h3>
            <h6 class="kf-sub-title">
              {{ "home.surveysDescription" | translate }}
            </h6>
          </div>
          <div class="survyes-container">
            <ul class="nav nav-tabs text-center" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="featured-tab" data-toggle="tab" href="#featured" role="tab"
                  aria-controls="home" aria-selected="true">{{ "home.featuredSurvey" | translate }}
                  <i class="fas fa-star"></i></a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="mostViewd-tab" data-toggle="tab" href="#mostViewd" role="tab"
                  aria-controls="profile" aria-selected="false">{{ "home.mostViewed" | translate }}</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="latestSurvyes-tab" data-toggle="tab" href="#latestSurvyes" role="tab"
                  aria-controls="contact" aria-selected="false">{{ "home.latestSurvyes" | translate }}</a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="featured" role="tabpanel" aria-labelledby="featured-tab">
                <div class="row" *ngIf="survey?.featured?.display?.length">
                  <div class="col-lg-4" *ngFor="
                      let survey of survey?.featured?.display;
                      let i = index
                    ">
                    <div class="survey-card">
                      <div class="top">
                        <div class="row">
                          <div class="col-md-4 col-3">
                            <div class="comp-logo">
                              <img [src]="
                                  survey?.surveyImage?.url
                                    ? survey?.surveyImage?.url
                                    : 'assets/imgs/logo.svg'
                                " alt="" />
                              <span>
                                <i class="fas fa-star"></i>
                              </span>
                            </div>
                          </div>

                          <div class="col-md-8 col-9">
                            <div class="text">
                              <p title="{{ survey.title }}">
                                {{ survey?.title }}
                              </p>
                              <div class="iconRow">
                                <div class="col-3 borderBox">
                                  <i class="fas fa-calendar-alt iconSetting"></i>
                                  <span class="surveyIconHeading">{{
                                    "survey.survey" | translate
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <i class="fas fa-heart iconSetting" style="color: #f05c6d !important"></i>
                                  <span class="surveyIconHeading">{{
                                    survey?.likes
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <img src="./assets/imgs/flame.svg" class="status-icon" alt="" />
                                  <span class="surveyIconHeading">{{
                                    "home.trend" | translate
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <i class="fas fa-heart iconSetting" style="
                                      color: #efa536 !important;
                                      right: 2px;
                                    "></i>
                                  <span class="surveyIconHeading">{{
                                    "home.win" | translate
                                    }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bottom">
                        <div class="timer d-flex">
                          <app-counter [dates]="{
                              startDate: survey?.startDate,
                              endDate: survey?.endDate
                            }"></app-counter>
                          <div>
                            <button (click)="surveyName(survey)" class="m-btn m-btn-green">
                              {{ "general.Start" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center" *ngIf="
                    survey.featured?.all?.length !=
                    survey.featured?.display?.length
                  ">
                  <div class="col-md-3 col-4">
                    <button class="m-btn btn-view-more" (click)="loadMoreSurvey('featured', 'allFeatured')">
                      {{ "home.viewMore" | translate }}
                      <i class="fas fa-arrow-circle-down icon font-40"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="mostViewd" role="tabpanel" aria-labelledby="mostViewd-tab">
                <div class="row" *ngIf="survey?.mostViewed?.display?.length">
                  <div class="col-lg-4" *ngFor="
                      let survey of survey?.mostViewed?.display;
                      let i = index
                    ">
                    <div class="survey-card">
                      <div class="top">
                        <div class="row">
                          <div class="col-md-4 col-3">
                            <div class="comp-logo">
                              <img [src]="
                                  survey?.surveyImage?.url
                                    ? survey?.surveyImage?.url
                                    : 'assets/imgs/logo.svg'
                                " alt="" />
                            </div>
                          </div>

                          <div class="col-md-8 col-9">
                            <div class="text">
                              <p title="{{ survey.title }}">
                                {{ survey?.title }}
                              </p>
                              <div class="iconRow">
                                <div class="col-3 borderBox">
                                  <i class="fas fa-calendar-alt iconSetting"></i>
                                  <span class="surveyIconHeading">{{
                                    "survey.survey" | translate
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <i class="fas fa-heart iconSetting" style="color: #f05c6d !important"></i>
                                  <span class="surveyIconHeading">{{
                                    survey?.likes
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <img src="./assets/imgs/flame.svg" class="status-icon" alt="" />
                                  <span class="surveyIconHeading">{{
                                    "home.trend" | translate
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <i class="fas fa-heart iconSetting" style="
                                      color: #efa536 !important;
                                      right: 2px;
                                    "></i>
                                  <span class="surveyIconHeading">{{
                                    "home.win" | translate
                                    }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bottom">
                        <div class="timer d-flex">
                          <app-counter [dates]="{
                              startDate: survey?.startDate,
                              endDate: survey?.endDate
                            }"></app-counter>
                          <div>
                            <button (click)="surveyName(survey)" class="m-btn m-btn-green">
                              {{ "general.Start" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center" *ngIf="
                    survey.mostViewed?.all?.length !=
                    survey.mostViewed?.display?.length
                  ">
                  <div class="col-md-3">
                    <button class="m-btn btn-view-more" (click)="loadMoreSurvey('mostViewed', 'allMostViewed')">
                      {{ "home.viewMore" | translate }}
                      <i class="fas fa-arrow-circle-down icon font-40"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="answers" role="tabpanel" aria-labelledby="answers-tab">
                <div class="row" *ngIf="survey?.answers?.display?.length">
                  <div class="col-lg-4" *ngFor="
                      let survey of survey?.answers?.display;
                      let i = index
                    ">
                    <div class="survey-card">
                      <div class="top">
                        <div class="row">
                          <div class="col-md-4 col-3">
                            <div class="comp-logo">
                              <img [src]="
                                  survey?.surveyImage?.url
                                    ? survey?.surveyImage?.url
                                    : 'assets/imgs/logo.svg'
                                " alt="" />
                            </div>
                          </div>
                          <div class="col-md-8 col-9">
                            <div class="text">
                              <p title="{{ survey.title }}">
                                {{ survey?.title }}
                              </p>
                              <div class="iconRow">
                                <div class="col-3 borderBox">
                                  <i class="fas fa-calendar-alt iconSetting"></i>
                                  <span class="surveyIconHeading">{{
                                    "survey.survey" | translate
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <i class="fas fa-heart iconSetting" style="color: #f05c6d !important"></i>
                                  <span class="surveyIconHeading">{{
                                    survey?.likes
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <img src="./assets/imgs/flame.svg" class="status-icon" alt="" />
                                  <span class="surveyIconHeading">{{
                                    "home.trend" | translate
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <i class="fas fa-heart iconSetting" style="
                                      color: #efa536 !important;
                                      right: 2px;
                                    "></i>
                                  <span class="surveyIconHeading">{{
                                    "home.win" | translate
                                    }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bottom">
                        <div class="timer d-flex">
                          <app-counter [dates]="{
                              startDate: survey?.startDate,
                              endDate: survey?.endDate
                            }"></app-counter>
                          <div>
                            <button (click)="surveyName(survey)" class="m-btn m-btn-green">
                              {{ "general.Start" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center" *ngIf="
                    survey.answers?.all?.length !=
                    survey.answers?.display?.length
                  ">
                  <div class="col-md-3">
                    <button class="m-btn btn-view-more" (click)="loadMoreSurvey('answers', 'allAnswers')">
                      View More
                      <i class="fas fa-arrow-circle-down icon font-40"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="latestSurvyes" role="tabpanel" aria-labelledby="latestSurvyes-tab">
                <div class="row" *ngIf="survey?.latest?.display?.length">
                  <div class="col-lg-4" *ngFor="
                      let survey of survey?.latest?.display;
                      let i = index
                    ">
                    <div class="survey-card">
                      <div class="top">
                        <div class="row">
                          <div class="col-md-4 col-3">
                            <div class="comp-logo">
                              <img [src]="
                                  survey?.surveyImage?.url
                                    ? survey?.surveyImage?.url
                                    : 'assets/imgs/logo.svg'
                                " alt="" />
                            </div>
                          </div>
                          <div class="col-md-8 col-9">
                            <div class="text">
                              <p title="{{ survey.title }}">
                                {{ survey?.title }}
                              </p>
                              <div class="iconRow">
                                <div class="col-3 borderBox">
                                  <i class="fas fa-calendar-alt iconSetting"></i>
                                  <span class="surveyIconHeading">{{
                                    "survey.survey" | translate
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <i class="fas fa-heart iconSetting" style="color: #f05c6d !important"></i>
                                  <span class="surveyIconHeading">{{
                                    survey?.likes
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <img src="./assets/imgs/flame.svg" class="status-icon" alt="" />
                                  <span class="surveyIconHeading">{{
                                    "home.trend" | translate
                                    }}</span>
                                </div>
                                <div class="col-3 borderBox">
                                  <i class="fas fa-heart iconSetting" style="
                                      color: #efa536 !important;
                                      right: 2px;
                                    "></i>
                                  <span class="surveyIconHeading">{{
                                    "home.win" | translate
                                    }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bottom">
                        <div class="timer d-flex">
                          <app-counter [dates]="{
                              startDate: survey?.startDate,
                              endDate: survey?.endDate
                            }"></app-counter>
                          <div>
                            <button (click)="surveyName(survey)" class="m-btn m-btn-green">
                              {{ "general.Start" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center" *ngIf="
                    survey.latest?.all?.length != survey.latest?.display?.length
                  ">
                  <div class="col-md-3">
                    <button class="m-btn btn-view-more" (click)="loadMoreSurvey('latest', 'allLatest')">
                      View More
                      <i class="fas fa-arrow-circle-down icon font-40"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="polls" role="tabpanel" aria-labelledby="polls-tab">
                <div class="row" *ngIf="survey?.polls?.display?.length">
                  <div class="col-lg-4" *ngFor="let survey of survey.polls?.display; let i = index">
                    <div class="survey-card">
                      <div class="top">
                        <div class="row">
                          <div class="col-md-4 col-3">
                            <div class="comp-logo">
                              <img [src]="
                                  survey?.surveyImage?.url
                                    ? survey?.surveyImage?.url
                                    : 'assets/imgs/logo.svg'
                                " alt="" />
                              <span>
                                <i class="fas fa-star"></i>
                              </span>
                            </div>
                          </div>
                          <div class="col-md-8 col-9">
                            <div class="text">
                              <p title="{{ survey.title }}">
                                {{ survey?.title }}
                              </p>

                              <h6>
                                <i class="fas fa-calendar-alt"></i>
                                Survey
                              </h6>
                              <h6>
                                <i class="fas fa-heart"></i>
                                1288
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="bottom">
                        <div class="timer d-flex">
                          <app-counter [dates]="{
                              startDate: survey?.startDate,
                              endDate: survey?.endDate
                            }"></app-counter>
                          <div>
                            <a href="#" class="m-btn">start</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center" *ngIf="
                    survey?.polls.all?.length != survey.polls?.display?.length
                  ">
                  <div class="col-md-3">
                    <button class="m-btn btn-view-more" (click)="loadMoreSurvey('polls', 'allPolls')">
                      View More
                      <i class="fas fa-arrow-circle-down icon font-40"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
<!-- <app-footer></app-footer> -->