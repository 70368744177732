export class SurveyConstants {
  public static SurveyTypes: any[] = [
    { name: 'Customer Satisfaction', value: 'customerSatisfaction' },
    { name: 'After Sales Opinion', value: 'afterSalesOpinion' },
    { name: 'For HR Purposes', value: 'forHRPurposes' },
  ]

  // public static QuestionTypes: any[] = [
  //   { name: 'Evaluation', value: 'evaluation' },
  //   { name: 'Evaluation', value: 'evaluation' },
  //   { name: 'Evaluation', value: 'evaluation' },
  //   { name: 'Evaluation', value: 'evaluation' },
  // ]

  public static QuestionRateRanges = {
    firstGroup: 5,
    secondGroup: 10,
    thirdGroup: 0
  }
}
