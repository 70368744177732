<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLongTitle">{{'forget.change password' | translate}}</h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <img (click)="close()" src="assets/imgs/times-icon.svg" alt="">
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="s-input" [ngClass]="{'invalid':form.get('password').invalid && form.get('password').dirty}">
      <input formControlName="password"
             type="password" placeholder="{{'forget.password' | translate}}">
      <i class="fas fa-user"></i>
    </div>
    <div class="s-input" [ngClass]="{'invalid':form.get('repeatPassword').invalid && form.get('repeatPassword').dirty}">
      <input formControlName="repeatPassword"
             type="password" placeholder="{{'forget.repeatPassword' | translate}}">
      <i class="fas fa-user"></i>
    </div>

    <button (click)="processForget()" class="m-btn m-btn-green w-100">{{'forget.change password' | translate}}</button>
  </form>
</div>


<app-loader [show]="showLoader"></app-loader>
