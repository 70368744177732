<!-- Start Wrapper -->
<div class="wrapper" [ngClass]="darkThemeService.getCurrentTheme">
  <!-- Start Main -->
  <main>
    <!-- Start Hero Section -->
    <section class="hero">
      <div class="container-fluid">
        <div class="top-sec">
          <owl-carousel-o class="join-slider" [options]="slideConfig">
            <!-- <ng-template carouselSlide id="ahsan">
              <div >
                <h1>Ahsan</h1>
              </div>
            </ng-template>

            <ng-template carouselSlide id="fahad">
              <div >
                <h1>Fahad</h1>
              </div>
            </ng-template> -->

            <ng-container *ngFor="let slide of slides; let i = index">
              <ng-template carouselSlide id="{{ i }}">
                <div class="item d-flex mobile-flex">
                  <div>
                    <h3>{{ slide.title }}</h3>
                    <h1>{{ slide.subtitle }}</h1>
                    <p class="description-text">{{ slide.description }}</p>
                    <!-- <a *ngIf="slide.url" target="_blank" href="{{ slide.url }}" class="button m-btn">
                      {{ slide.urlTitle }}
                    </a> -->
                    <a
                      *ngIf="slide.url"
                      class="button m-btn"
                      (click)="joinNowClick(slide.url)"
                    >
                      <ng-container>
                        {{ slide.urlTitle }}
                      </ng-container>
                    </a>
                  </div>
                  <div>
                    <img [src]="imageUrl + slide.image" alt="" />
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="bottom-sec">
              <owl-carousel-o
                class="statics-slider"
                [options]="statisticsConfig"
              >
                <ng-container
                  *ngFor="let statistic of statistics; let i = index"
                >
                  <ng-template carouselSlide id="{{ i }}">
                    <div class="item">
                      <div class="icons">
                        <img [src]="imageUrl + statistic.icon" alt="" />
                      </div>
                      <h2>{{ statistic.number }}</h2>
                      <h6>{{ statistic.title }}</h6>
                      <!-- <p id="static-desc{{ i }}">{{ statistic.description }}</p> -->
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Hero Section -->

    <!-- Start Services Section -->
    <section *ngIf="services?.length" id="services" class="services">
      <div class="container">
        <div class="row" style="margin-top: 12rem">
          <!-- Image Column -->
          <div
            class="col-lg-6 col-md-6 col-12 d-flex justify-content-lg-start justify-content-center"
          >
            <div class="img">
              <img
                *ngIf="currentService"
                [src]="imageUrl + currentService?.image"
                alt=""
              />
            </div>
          </div>

          <!-- Text Column -->
          <div class="col-lg-6 col-md-6 col-12">
            <div class="info">
              <h3 class="kf-title kf-title-s">
                {{ "home.our services" | translate }}
              </h3>
              <h1>{{ currentService?.title }}</h1>
              <p>{{ currentService?.description }}</p>
              <ul>
                <li *ngFor="let service of services">
                  <a (mouseenter)="mouseEnter(service)">
                    <img [src]="imageUrl + service.image" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- End Services Section -->
    <section id="survey" class="new-survyes">
      <div class="container-fluid">
        <div class="text-center">
          <h3 class="kf-title">{{ "home.surveysPoll" | translate }}</h3>
          <h6 class="kf-sub-title">
            {{ "home.surveysDescription" | translate }}
          </h6>
        </div>
        <div class="survyes-container">
          <ul class="nav nav-tabs text-center" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active"
                id="featured-tab"
                data-toggle="tab"
                href="#featured"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                (click)="tabChange(0, 6, 'isFeatured')"
                >{{ "home.featuredSurvey" | translate }}
                <i class="fas fa-star"></i
              ></a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="mostViewd-tab"
                data-toggle="tab"
                href="#mostViewd"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                (click)="tabChange(0, 6, 'isPublished')"
                >{{ "home.mostViewed" | translate }}</a
              >
            </li>
            <!-- <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="answers-tab"
                data-toggle="tab"
                href="#answers"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                >{{ "home.answers" | translate }}</a
              >
            </li> -->
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="latestSurvyes-tab"
                data-toggle="tab"
                href="#latestSurvyes"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                (click)="tabChange(0, 6, 'latest')"
                >{{ "home.latestSurvyes" | translate }}</a
              >
            </li>
            <!-- <li class="nav-item" role="presentation">
              <a class="nav-link" id="polls-tab" data-toggle="tab" href="#polls" role="tab" aria-controls="contact"
                aria-selected="false">polls</a>
            </li> -->
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="featured"
              role="tabpanel"
              aria-labelledby="featured-tab"
            >
              <div class="row" *ngIf="survey?.featured?.display?.length">
                <div
                  class="col-lg-4"
                  *ngFor="
                    let survey of survey?.featured?.display;
                    let i = index
                  "
                >
                  <div class="survey-card">
                    <div class="top">
                      <div class="row">
                        <div class="col-md-4 col-3">
                          <div class="comp-logo">
                            <img
                              [src]="
                                survey?.surveyImage
                                  ? survey?.surveyImage
                                  : 'assets/imgs/logo.svg'
                              "
                              alt=""
                            />
                            <span>
                              <i class="fas fa-star"></i>
                            </span>
                          </div>
                        </div>

                        <div class="col-md-8 col-9">
                          <div class="text">
                            <!-- <p title="{{ survey.title }}">
                              {{ survey?.title }}
                            </p> -->
                            <div class="iconRow">
                              <div class="col-3 borderBox">
                                <i class="fas fa-calendar-alt iconSetting"></i>
                                <span class="surveyIconHeading">{{
                                  "survey.survey" | translate
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <i
                                  class="fas fa-heart iconSetting"
                                  style="color: #f05c6d !important"
                                ></i>
                                <span class="surveyIconHeading">{{
                                  survey?.likes
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <img
                                  src="./assets/imgs/flame.svg"
                                  class="status-icon"
                                  alt=""
                                />
                                <span class="surveyIconHeading">{{
                                  "home.trend" | translate
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <i
                                  class="fas fa-heart iconSetting"
                                  style="color: #efa536 !important; right: 2px"
                                ></i>
                                <span class="surveyIconHeading">{{
                                  "home.win" | translate
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row overflow-hidden">
                        <div class="col-12 overflow-hidden">
                          <h4 title="{{ survey.title }}" class="title-style">
                            {{ survey?.title }}
                          </h4>
                          <p class="mt-2 text-description fix-description">
                            {{ survey?.description }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="timer d-flex">
                        <app-counter
                          [dates]="{
                            startDate: survey?.startDate,
                            endDate: survey?.endDate
                          }"
                        ></app-counter>

                        <!-- <div>
                          <h5>
                            {{ survey?.days }}
                            <span>{{ "general.days" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.hours }}
                            <span>{{ "general.hours" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.minutes }}
                            <span>{{ "general.mins" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.seconds }}
                            <span>{{ "general.secs" | translate }}</span>
                          </h5>
                        </div> -->
                        <div>
                          <button
                            (click)="surveyName(survey)"
                            class="m-btn m-btn-green"
                          >
                            {{ "general.Start" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center"
                *ngIf="count > 6 && survey.featured?.display?.length < count"
              >
                <div class="col-md-3 col-4 centerAligned">
                  <button
                    class="m-btn btn-view-more"
                    (click)="
                      newLoadMore(
                        'featured',
                        'isFeatured',
                        survey?.featured?.display,
                        currentPage
                      )
                    "
                  >
                    {{ "home.viewMore" | translate }}
                    <i class="fas fa-arrow-circle-down icon font-40"></i>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="mostViewd"
              role="tabpanel"
              aria-labelledby="mostViewd-tab"
            >
              <div class="row" *ngIf="survey?.mostViewed?.display?.length">
                <div
                  class="col-lg-4"
                  *ngFor="
                    let survey of survey?.mostViewed?.display;
                    let i = index
                  "
                >
                  <div class="survey-card">
                    <div class="top">
                      <div class="row">
                        <div class="col-md-4 col-3">
                          <div class="comp-logo">
                            <img
                              [src]="
                                survey?.surveyImage
                                  ? survey?.surveyImage
                                  : 'assets/imgs/logo.svg'
                              "
                              alt=""
                            />
                          </div>
                        </div>

                        <div class="col-md-8 col-9">
                          <div class="text">
                            <!-- <p title="{{ survey.title }}">
                              {{ survey?.title }}
                            </p> -->
                            <div class="iconRow">
                              <div class="col-3 borderBox">
                                <i class="fas fa-calendar-alt iconSetting"></i>
                                <span class="surveyIconHeading">{{
                                  "survey.survey" | translate
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <i
                                  class="fas fa-heart iconSetting"
                                  style="color: #f05c6d !important"
                                ></i>
                                <span class="surveyIconHeading">{{
                                  survey?.likes
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <img
                                  src="./assets/imgs/flame.svg"
                                  class="status-icon"
                                  alt=""
                                />
                                <span class="surveyIconHeading">{{
                                  "home.trend" | translate
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <i
                                  class="fas fa-heart iconSetting"
                                  style="color: #efa536 !important; right: 2px"
                                ></i>
                                <span class="surveyIconHeading">{{
                                  "home.win" | translate
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row overflow-hidden">
                        <div class="col-12 overflow-hidden">
                          <h4 title="{{ survey.title }}" class="title-style">
                            {{ survey?.title }}
                          </h4>
                          <p class="mt-2 text-description fix-description">
                            {{ survey?.description }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="timer d-flex">
                        <app-counter
                          [dates]="{
                            startDate: survey?.startDate,
                            endDate: survey?.endDate
                          }"
                        ></app-counter>

                        <!-- <div>
                          <h5>
                            {{ survey?.days }}
                            <span>{{ "general.days" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.hours }}
                            <span>{{ "general.hours" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.minutes }}
                            <span>{{ "general.mins" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.seconds }}
                            <span>{{ "general.secs" | translate }}</span>
                          </h5>
                        </div> -->
                        <div>
                          <button
                            (click)="surveyName(survey)"
                            class="m-btn m-btn-green"
                          >
                            {{ "general.Start" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center"
                *ngIf="count > 6 && survey?.mostViewed.displayLimit < count"
              >
                <div class="col-md-3 centerAligned">
                  <button
                    class="m-btn btn-view-more"
                    (click)="
                      newLoadMore(
                        'mostViewed',
                        'isPublished',
                        survey?.mostViewed?.display,
                        currentPage
                      )
                    "
                  >
                    <!-- <div class="kf-sub-title"> -->
                    {{ "home.viewMore" | translate }}
                    <!-- </div> -->
                    <i class="fas fa-arrow-circle-down icon font-40"></i>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="answers"
              role="tabpanel"
              aria-labelledby="answers-tab"
            >
              <div class="row" *ngIf="survey?.answers?.display?.length">
                <div
                  class="col-lg-4"
                  *ngFor="let survey of survey?.answers?.display; let i = index"
                >
                  <div class="survey-card">
                    <div class="top">
                      <div class="row">
                        <div class="col-md-4 col-3">
                          <div class="comp-logo">
                            <img
                              [src]="
                                survey?.surveyImage
                                  ? survey?.surveyImage
                                  : 'assets/imgs/logo.svg'
                              "
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="col-md-8 col-9">
                          <div class="text">
                            <!-- <p title="{{ survey.title }}">
                              {{ survey?.title }}
                            </p> -->
                            <div class="iconRow">
                              <div class="col-3 borderBox">
                                <i class="fas fa-calendar-alt iconSetting"></i>
                                <span class="surveyIconHeading">{{
                                  "survey.survey" | translate
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <i
                                  class="fas fa-heart iconSetting"
                                  style="color: #f05c6d !important"
                                ></i>
                                <span class="surveyIconHeading">{{
                                  survey?.likes
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <img
                                  src="./assets/imgs/flame.svg"
                                  class="status-icon"
                                  alt=""
                                />
                                <span class="surveyIconHeading">{{
                                  "home.trend" | translate
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <i
                                  class="fas fa-heart iconSetting"
                                  style="color: #efa536 !important; right: 2px"
                                ></i>
                                <span class="surveyIconHeading">{{
                                  "home.win" | translate
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row overflow-hidden">
                        <div class="col-12 overflow-hidden">
                          <h4 title="{{ survey.title }}" class="title-style">
                            {{ survey?.title }}
                          </h4>
                          <p class="mt-2 text-description fix-description">
                            {{ survey?.description }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="timer d-flex">
                        <app-counter
                          [dates]="{
                            startDate: survey?.startDate,
                            endDate: survey?.endDate
                          }"
                        ></app-counter>

                        <!-- <div>
                          <h5>
                            {{ survey?.days }}
                            <span>{{ "general.days" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.hours }}
                            <span>{{ "general.hours" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.minutes }}
                            <span>{{ "general.mins" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.seconds }}
                            <span>{{ "general.secs" | translate }}</span>
                          </h5>
                        </div> -->
                        <div>
                          <button
                            (click)="surveyName(survey)"
                            class="m-btn m-btn-green"
                          >
                            {{ "general.Start" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center"
                *ngIf="
                  survey.answers?.all?.length != survey.answers?.display?.length
                "
              >
                <div class="col-md-3 centerAligned">
                  <button
                    class="m-btn btn-view-more"
                    (click)="loadMoreSurvey('answers', 'allAnswers')"
                  >
                    {{ "home.viewMore" | translate }}

                    <i class="fas fa-arrow-circle-down icon font-40"></i>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="latestSurvyes"
              role="tabpanel"
              aria-labelledby="latestSurvyes-tab"
            >
              <div class="row" *ngIf="survey?.latest?.display?.length">
                <div
                  class="col-lg-4"
                  *ngFor="let survey of survey?.latest?.display; let i = index"
                >
                  <div class="survey-card">
                    <div class="top">
                      <div class="row">
                        <div class="col-md-4 col-3">
                          <div class="comp-logo">
                            <img
                              [src]="
                                survey?.surveyImage
                                  ? survey?.surveyImage
                                  : 'assets/imgs/logo.svg'
                              "
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="col-md-8 col-9">
                          <div class="text">
                            <!-- <p title="{{ survey.title }}">
                              {{ survey?.title }}
                            </p> -->
                            <div class="iconRow">
                              <div class="col-3 borderBox">
                                <i class="fas fa-calendar-alt iconSetting"></i>
                                <span class="surveyIconHeading">{{
                                  "survey.survey" | translate
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <i
                                  class="fas fa-heart iconSetting"
                                  style="color: #f05c6d !important"
                                ></i>
                                <span class="surveyIconHeading">{{
                                  survey?.likes
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <img
                                  src="./assets/imgs/flame.svg"
                                  class="status-icon"
                                  alt=""
                                />
                                <span class="surveyIconHeading">{{
                                  "home.trend" | translate
                                }}</span>
                              </div>
                              <div class="col-3 borderBox">
                                <i
                                  class="fas fa-heart iconSetting"
                                  style="color: #efa536 !important; right: 2px"
                                ></i>
                                <span class="surveyIconHeading">{{
                                  "home.win" | translate
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row overflow-hidden">
                        <div class="col-12 overflow-hidden">
                          <h4 title="{{ survey.title }}" class="title-style">
                            {{ survey?.title }}
                          </h4>
                          <p class="mt-2 text-description fix-description">
                            {{ survey?.description }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="timer d-flex">
                        <app-counter
                          [dates]="{
                            startDate: survey?.startDate,
                            endDate: survey?.endDate
                          }"
                        ></app-counter>

                        <!-- <div>
                          <h5>
                            {{ survey?.days }}
                            <span>{{ "general.days" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.hours }}
                            <span>{{ "general.hours" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.minutes }}
                            <span>{{ "general.mins" | translate }}</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.seconds }}
                            <span>{{ "general.secs" | translate }}</span>
                          </h5>
                        </div> -->
                        <div>
                          <button
                            (click)="surveyName(survey)"
                            class="m-btn m-btn-green"
                          >
                            {{ "general.Start" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center"
                *ngIf="count > 6 && survey.latest?.display?.length < count"
              >
                <div class="col-md-3 centerAligned">
                  <button
                    class="m-btn btn-view-more"
                    (click)="
                      newLoadMore(
                        'latest',
                        'latest',
                        survey?.latest?.display,
                        currentPage
                      )
                    "
                  >
                    {{ "home.viewMore" | translate }}

                    <i class="fas fa-arrow-circle-down icon font-40"></i>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="polls"
              role="tabpanel"
              aria-labelledby="polls-tab"
            >
              <div class="row" *ngIf="survey?.polls?.display?.length">
                <div
                  class="col-lg-4"
                  *ngFor="let survey of survey.polls?.display; let i = index"
                >
                  <div class="survey-card">
                    <div class="top">
                      <div class="row">
                        <div class="col-md-4 col-3">
                          <div class="comp-logo">
                            <img
                              [src]="
                                survey?.surveyImage
                                  ? survey?.surveyImage
                                  : 'assets/imgs/logo.svg'
                              "
                              alt=""
                            />
                            <span>
                              <i class="fas fa-star"></i>
                            </span>
                          </div>
                        </div>
                        <div class="col-md-8 col-9">
                          <div class="text">
                            <!-- <p title="{{ survey.title }}">
                              {{ survey?.title }}
                            </p> -->

                            <h6>
                              <i class="fas fa-calendar-alt"></i>
                              Survey
                            </h6>
                            <h6>
                              <i class="fas fa-heart"></i>
                              1288
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 overflow-hidden">
                          <p title="{{ survey.title }}">
                            {{ survey?.title }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="bottom">
                      <div class="timer d-flex">
                        <app-counter
                          [dates]="{
                            startDate: survey?.startDate,
                            endDate: survey?.endDate
                          }"
                        ></app-counter>

                        <!-- <div>
                          <h5>
                            {{ survey?.days }}

                            <span>days</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.hours }}

                            <span>hours</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.minutes }}

                            <span>mins</span>
                          </h5>
                          :
                          <h5>
                            {{ survey?.seconds }}

                            <span>secs</span>
                          </h5>
                        </div> -->
                        <div>
                          <a href="#" class="m-btn">start</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row justify-content-center"
                *ngIf="
                  survey?.polls.all?.length != survey.polls?.display?.length
                "
              >
                <div class="col-md-3 centerAligned">
                  <button
                    class="m-btn btn-view-more"
                    (click)="loadMoreSurvey('polls', 'allPolls')"
                  >
                    {{ "home.viewMore" | translate }}

                    <i class="fas fa-arrow-circle-down icon font-40"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Start Our Partners Section -->
    <section id="partners" class="our-partners color-remove">
      <div class="wave"></div>
      <div class="container-fluid">
        <div class="text-center">
          <h3 class="kf-title">
            {{ "home.Our Partners" | translate }}
          </h3>
          <h6 class="kf-sub-title">
            {{ "home.partners description" | translate }}
          </h6>
        </div>
        <owl-carousel-o
          class="partners-slider partners-slider-new"
          [options]="partnersConfig"
        >
          <ng-container *ngFor="let partner of partners; let i = index">
            <ng-template carouselSlide id="{{ partner.id }}">
              <div class="item partner-item">
                <div class="logo">
                  <img [src]="imageUrl + partner.image" alt="" />
                </div>
                <!-- <h5>{{ partner.title }}</h5> -->
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </section>
    <!-- End Our Partners Section -->

    <!-- Start contact section -->
    <section id="contact" class="contact-us new-contact-change">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-12">
            <div class="form-sec">
              <h2>{{ "home.get in touch" | translate }}</h2>
              <p>{{ "home.contact us description" | translate }}</p>
              <form [formGroup]="form">
                <div class="d-flex">
                  <div>
                    <input
                      formControlName="name"
                      type="text"
                      placeholder=" {{ 'home.yourName' | translate }}"
                      (input)="onInput($event)"
                    />
                    <div *ngIf="form.get('name').touched || formSubmitAttempt">
                      <div
                        *ngIf="form.get('name').hasError('required')"
                        class="invalid"
                      >
                        {{ "home.Name" | translate }}
                      </div>
                    </div>
                    <input
                      formControlName="email"
                      type="email"
                      placeholder="{{ 'home.yourEmail' | translate }}"
                    />
                    <div
                      *ngIf="form.get('email').touched || formSubmitAttempt"
                      class="invalid"
                    >
                      <div *ngIf="form.get('email').hasError('required')">
                        {{ "home.email" | translate }}
                      </div>
                      <div *ngIf="form.get('email').hasError('email')">
                        {{ "home.invalidEmailFormat" | translate }}
                      </div>
                    </div>
                    <input
                      formControlName="subject"
                      type="text"
                      placeholder="{{ 'home.yourSubject' | translate }}"
                    />
                    <div
                      *ngIf="form.get('subject').touched || formSubmitAttempt"
                      class="invalid"
                    >
                      <div *ngIf="form.get('subject').hasError('required')">
                        {{ "home.Subject" | translate }}
                      </div>
                    </div>
                    <input
                      type="tel"
                      formControlName="phone"
                      maxlength="13"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      placeholder="{{ 'home.yourMobile' | translate }}"
                    />
                    <div
                      *ngIf="form.get('phone').touched || formSubmitAttempt"
                      class="mt-1"
                      style="color: red"
                    >
                      <div *ngIf="form.get('phone').hasError('required')">
                        {{ "home.PhoneNumber" | translate }}
                      </div>
                    </div>
                  </div>
                  <div>
                    <textarea
                      formControlName="message"
                      placeholder="{{ 'home.yourMessage' | translate }}"
                    ></textarea>

                    <div
                      *ngIf="form.get('message').touched || formSubmitAttempt"
                      class="invalid"
                    >
                      <div *ngIf="form.get('message').hasError('required')">
                        {{ "home.Message" | translate }}
                      </div>
                    </div>
                    <button
                      (click)="sendMessage()"
                      class="m-btn m-btn-green"
                      [disabled]="homeService.isFormSubmitted"
                    >
                      {{ "home.sendMessage" | translate }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-4 col-12 mt-lg-0 mt-5">
            <div class="social-sec">
              <h6>{{ "home.getSocial" | translate }}</h6>
              <ul>
                <li>
                  <a href="https://www.facebook.com/kafuapp" target="_blank">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/kafuapp" target="_blank">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/kafuapp" target="_blank">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCdAZJ_tUPXMsyBgvjX3sZ-Q"
                    target="_blank"
                  >
                    <i class="fab fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://api.whatsapp.com/send/?phone=966555044271"
                    target="_blank"
                  >
                    <i class="fab fa-whatsapp"></i>
                  </a>
                </li>
              </ul>
              <div class="s-info">
                <h6>{{ "home.phone" | translate }}</h6>
                <div class="d-flex">
                  <img src="assets/imgs/phone-icon.svg" alt="" />
                  <a href="#">
                    <bdi>+{{ this?.kafuContactDetails?.phoneNumber }}</bdi>
                  </a>
                </div>
              </div>
              <div class="s-info">
                <h6>{{ "home.e-mail" | translate }}</h6>
                <div class="d-flex">
                  <img src="assets/imgs/mail-icon.svg" alt="" />
                  <a href="mailto:Support@kafuapp.com">
                    <bdi>{{ this?.kafuContactDetails?.email }}</bdi>
                  </a>
                </div>
              </div>
              <div class="s-info">
                <h6>{{ "home.website" | translate }}</h6>
                <div class="d-flex">
                  <img src="assets/imgs/website-icon.svg" alt="" />
                  <a href="#">
                    <bdi>{{ this?.kafuContactDetails?.website }}</bdi>
                  </a>
                </div>
              </div>
              <div class="s-info">
                <h6>{{ "home.address" | translate }}</h6>
                <div class="d-flex">
                  <img src="assets/imgs/address-icon.svg" alt="" />
                  <bdi class="address"
                    >{{ this?.kafuContactDetails?.address }}
                  </bdi>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End contact section -->

    <!-- Start Map Section -->
    <section class="map">
      <div style="width: 100%">
        <iframe
          width="100%"
          height="600"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="//maps.google.com/maps?q=24.667609,46.730033&z=15&output=embed"
          ><a href="https://www.maps.ie/distance-area-calculator.html"
            >distance maps</a
          ></iframe
        >
      </div>
    </section>
    <!-- End Map Section -->

    <!-- Start Newsletter section -->
    <section class="newsletter">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="text">
              <h1>
                <i class="fas fa-newspaper"></i>
                {{ "home.newsletter" | translate }}
              </h1>
              <p>
                {{ "home.newsletterDescription" | translate }}
              </p>
            </div>
          </div>
          <div class="col-lg-6 m-auto">
            <form [formGroup]="newsletter">
              <div>
                <input
                  type="email"
                  formControlName="email"
                  placeholder="{{ 'home.enterEmail' | translate }}"
                />
                <div
                  *ngIf="newsletter.get('email').touched || newsAttempt"
                  style="color: crimson"
                >
                  <div *ngIf="newsletter.get('email').hasError('required')">
                    {{ "home.email" | translate }}
                  </div>
                </div>
              </div>
              <button
                (click)="submit()"
                class="m-btn m-btn-green"
                [disabled]="homeService.isSubscribe"
              >
                {{ "home.subscribe" | translate }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- End Newsletter section -->
  </main>
  <!-- End Main -->
  <app-loader [show]="showLoader"></app-loader>
</div>
