<app-header></app-header>

<!-- Start Wrapper -->
<div class="wrapper privacy-terms-wrap" [ngClass]="darkThemeService.getCurrentTheme">
  <!-- Start Main -->
  <main>
    <div class="pg-head">
      <div class="container-fluid">
        <div class="d-flex">
          <div>
            <h1 class="title">{{ "terms.termsAndConditions" | translate }}</h1>
            <div class="kf-breadcrumb">
              <ul>
                <li>
                  <a>{{ "profile.Home" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.termsAndConditions" | translate }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Privacy Policy -->
    <section class="-container">
      <div class="container-fluid">
        <div class="row">
          <!-- <div class="col-lg-6">
            <img class="terms-img" src="assets/imgs/terms-pic.png" alt="" />
          </div> -->
          <div class="col-lg-12">
            <p>
              <b>{{ "survey.yourAgreement" | translate }}</b>
              {{ "terms.termsDescription" | translate }}
            </p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Tableofcontents" | translate }}
            </h3>
            <div class="col-12 mt-1">
              <ol>
                <li>
                  <a>{{ "terms.Accountsandmembership" | translate }}</a>
                </li>

                <li>
                  <a>{{ "terms.Linkstootherresources" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Prohibiteduses" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Linkstootherresources" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Intellectualpropertyrights" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Limitationofliability" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Indemnification" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Severability" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Disputeresolution" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Changesandamendments" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Acceptanceoftheseterms" | translate }}</a>
                </li>
                <li>
                  <a>{{ "terms.Contactingus" | translate }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-12 mt-1" id="Accountsandmembership">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Accountsandmembership" | translate }}
            </h3>
            <p>{{ "terms.accountDescription" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Linkstootherresources" | translate }}
            </h3>
            <p>{{ "terms.linkDescription" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Prohibiteduses" | translate }}
            </h3>
            <p>{{ "terms.prohibitedDescription" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Intellectualpropertyrights" | translate }}
            </h3>

            <p>{{ "terms.IntellectualDescription" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Limitationofliability" | translate }}
            </h3>
            <p>{{ "terms.limitationsDescription" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Indemnification" | translate }}
            </h3>
            <p>{{ "terms.IndemnificationDescription" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Severability" | translate }}
            </h3>
            <p>{{ "terms.SeverabilityDescription" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Disputeresolution" | translate }}
            </h3>
            <p>{{ "terms.DisputeresolutionDescription" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Changesandamendments" | translate }}
            </h3>
            <p>{{ "terms.changedes1" | translate }}</p>
            <p>{{ "terms.changedes2" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Acceptanceoftheseterms" | translate }}
            </h3>
            <p>{{ "terms.Acceptdes" | translate }}</p>
          </div>
          <div class="col-12 mt-1">
            <h3 class="font-700 font-size-20 my-2">
              {{ "terms.Contactingus" | translate }}
            </h3>
            <p>{{ "terms.desc" | translate }}</p>
            <div>
              <a href="https://www.kafuapp.com" target="_blank" class="link">https://www.kafuapp.com</a>
            </div>
            <a href="mailto:support@kafuapp.com" class="link">support@kafuapp.com</a>
          </div>
        </div>
      </div>
    </section>
    <!-- /Privacy Policy -->
  </main>
  <!-- End Main -->
</div>
<!-- End wrapper -->

<app-footer></app-footer>