<div class="modal-header">
  <button (click)="onClose()" type="button" class="close" data-dismiss="modal" aria-label="Close">
    <img src="assets/imgs/times-icon.svg" alt="" />
  </button>
</div>

<div class="main-div-body modal-body" [ngClass]="darkThemeService.getCurrentTheme">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="fw-700 stf-title">
          {{ "pricing.title" | translate }}
        </h2>
      </div>
      <div class="col-12 text-center stf-title" style="direction: ltr">
        <span>{{ "pricing.monthly" | translate }}</span>
        <label class="toggle-3">
          <input type="checkbox" [(ngModel)]="toggleValue" (change)="toggleValueChanged()" />
          <span class="slider-3"></span>
          <span class="labels-3" data-on="" data-off=""></span>
        </label>
        <span>{{ "pricing.yearly" | translate }}</span>
      </div>
    </div>
    <div class="row w-100 justify-content-center mx-auto" style="align-items: center">
      <div class="col-11 col-sm-8 col-md-3 marg-top-30 pad-zero marg-20" *ngFor="let plan of data; let i = index">
        <div class="row w-100 mx-auto justify-content-center c-survey-sec survey-title-format pad-bot pos-rel">
          <div class="col-12 stf-title stf-title-2 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 31 25" fill="none"
              style="padding: 3px">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M21.1342 14.1595C19.7849 14.1595 18.6957 13.256 18.6957 12.1367C18.6957 11.0175 19.7849 10.114 21.1342 10.114C22.4835 10.114 23.5727 11.0175 23.5727 12.1367C23.5727 13.256 22.4835 14.1595 21.1342 14.1595ZM14.6311 17.5302H30.8881V6.74217H14.6311V17.5302ZM29.2625 20.2275V21.576C29.2625 23.0594 27.7994 24.273 26.0111 24.273H3.25139C1.44687 24.273 0 23.0594 0 21.576V2.69701C0 1.21366 1.44687 0 3.25139 0H26.0111C27.7994 0 29.2625 1.21365 29.2625 2.69701V4.0455H14.6313C12.8267 4.0455 11.3799 5.25915 11.3799 6.74251V17.5305C11.3799 19.0139 12.8267 20.2275 14.6313 20.2275L29.2625 20.2275Z"
                fill="white" />
            </svg><span style="font-size: 17px" class="text-capitalize">
              {{ plan.title }}
            </span>
          </div>
          <div class="col-12 text-center marg-tb-20">
            <h2 class="orange-grad-color fw-700" *ngIf="plan.totalAmount == 0">
              {{ "pricing.plan" | translate }}
            </h2>
            <h2 class="purple-grad-color fw-700 inline-dis" *ngIf="plan.totalAmount > 0">
              {{ plan.totalAmount }} {{ "survey.SAR" | translate }}
            </h2>
            <span *ngIf="plan.totalAmount > 0">&nbsp;/ {{ plan.planType }}</span>
            <p>{{ "pricing.chose" | translate }}</p>
          </div>
          <div class="col-12 marg-tb-20 pad-zero">
            <ul class="plan-options pad-zero" *ngFor="let des of plan.description; let i = index">
              <li style=" white-space:nowrap; overflow: hidden; text-overflow: ellipsis;" [title]="des">
                <svg class=" tick-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                {{ des }}
              </li>
              <!-- <li>
                <svg class="tick-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Unlimited RSP
              </li>
              <li>
                <svg class="tick-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                No Brand
              </li>
              <li>
                <svg class="tick-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                1 Reports (basic PDF)
              </li>
              <li>
                <svg class="tick-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Public Survey Only
              </li>
              <li>
                <svg class="tick-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Kafu Network (Mobile)
              </li> -->
            </ul>
          </div>
          <button class="col-8 get-plan-btn orange-grad text-center cursor-pointer" (click)="recharge(plan)"
            *ngIf="type !== plan.id">
            {{ "pricing.buttonHeading" | translate }}
          </button>
          <button class="col-8 get-plan-btn green-grad text-center" [disabled]="type == plan.id"
            *ngIf="type == plan.id">
            {{ "pricing.current" | translate }}
          </button>
        </div>
      </div>
      <!-- <div class="col-11 col-sm-8 col-md-4 marg-top-30 pad-zero marg-20">
        <div class="row w-100 mx-auto justify-content-center c-survey-sec survey-title-format pad-bot pos-rel">
          <div class="col-12 stf-title stf-title-3 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 36 33" fill="none">
              <path d="M0.0615234 12.2615L16.179 30.8722L10.8091 12.2615H0.0615234Z" fill="white" />
              <path d="M12.0059 12.2615L17.9742 32.9434L23.9425 12.2615H12.0059Z" fill="white" />
              <path d="M4.98638 4.42908L0 11.1107H9.98035L4.98638 4.42908Z" fill="white" />
              <path d="M35.963 11.1107L30.9766 4.42908L25.9902 11.1107H35.963Z" fill="white" />
              <path d="M11.124 10.7196L16.54 3.47021H5.70801L11.124 10.7196Z" fill="white" />
              <path d="M30.2556 3.47021H19.416L24.8397 10.7272L30.2556 3.47021Z" fill="white" />
              <path d="M23.6894 11.1108L17.9819 3.47021L12.2744 11.1108H23.6894Z" fill="white" />
              <path d="M19.7686 30.8799L35.8937 12.2615H25.1462L19.7686 30.8799Z" fill="white" />
              <path
                d="M4.39901 3.99724L5.10339 2.41663L6.39763 1.99862L5.10339 1.57485L4.39901 0L3.69455 1.57485L2.40039 1.99862L3.69455 2.41663L4.39901 3.99724Z"
                fill="white" />
              <path
                d="M2.58323 6.41029L3.02809 5.42076L3.84126 5.15986L3.02809 4.89136L2.58323 3.89417L2.13055 4.89136L1.31738 5.15986L2.13055 5.42076L2.58323 6.41029Z"
                fill="white" />
              <path
                d="M8.37032 3.00258L8.72321 2.2201L9.35993 2.0129L8.72321 1.80577L8.37032 1.01562L8.01744 1.80577L7.37305 2.0129L8.01744 2.2201L8.37032 3.00258Z"
                fill="white" />
            </svg><span style="font-size: 17px;">Premium Plan</span>
          </div>
          <div class="col-12 text-center marg-tb-20">
            <h2 class="purple-grad-color fw-700 inline-dis">749 SAR</h2><span>&nbsp;/ Monthly</span>
            <p>Choose your Kafu plan</p>
          </div>
          <div class="col-12 marg-tb-20 pad-zero">
            <ul class="plan-options">
              <li>
                <svg class="tick-svg-2" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Unlimited Survey
              </li>
              <li>
                <svg class="tick-svg-2" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Unlimited RSP
              </li>
              <li>
                <svg class="tick-svg-2" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Unlimited Brand
              </li>
              <li>
                <svg class="tick-svg-2" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Unlimited Reports (PDF + Excel)
              </li>
              <li>
                <svg class="tick-svg-2" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Public/Private
              </li>
              <li>
                <svg class="tick-svg-2" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Free Audience Targeting
              </li>
              <li>
                <svg class="tick-svg-2" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Free contacts survey sms / email (email,mobile)
              </li>
              <li>
                <svg class="tick-svg-2" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Kafu Network (Mobile)
              </li>
              <li>
                <svg class="tick-svg-2" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Include in Newsletter survey marketing
              </li>
              <li>
                <svg class="tick-svg-2" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Kafu social media campaign
              </li>
            </ul>
          </div>
          <button class="col-8 get-plan-btn purple-grad text-center">{{ "pricing.buttonHeading" | translate }}</button>
        </div>
      </div>
      <div class="col-11 col-sm-8 col-md-3 marg-top-30 pad-zero marg-20">
        <div class="row w-100 mx-auto justify-content-center c-survey-sec survey-title-format pad-bot pos-rel">
          <div class="col-12 stf-title stf-title-1 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 41 40" fill="none">
              <path d="M21.8855 13.4408L12.7785 10.948L0.563477 30.5562L9.83498 34.2486L21.8855 13.4408Z"
                fill="white" />
              <path
                d="M23.6985 19.209L25.767 15.7198L23.2452 14.0309L10.9375 35.2857L11.893 39.1158L23.695 19.2143L23.6985 19.209Z"
                fill="white" />
              <path d="M24.7048 20.3979L21.9258 25.0867L29.764 27.0989L28.7578 20.988L24.7048 20.3979Z" fill="white" />
              <path
                d="M29.2404 19.5605L33.5717 16.9282L27.4694 15.6473C27.4537 15.7251 27.4309 15.8028 27.3889 15.8735L25.5234 19.0199L29.2404 19.5605Z"
                fill="white" />
              <path d="M19.0946 29.8602L16.4854 34.261L25.2966 37.1742L24.6579 31.1658L19.0946 29.8602Z" fill="white" />
              <path d="M31.1952 26.7473L37.1032 22.1081L35.2149 17.6614L30.2012 20.7071L31.1952 26.7473Z"
                fill="white" />
              <path d="M0.161 31.9908L0 35.9252L10.563 39.849L9.53225 35.7238L0.161 31.9908Z" fill="white" />
              <path d="M31.7822 28.1642L33.4412 29.8337L33.0282 27.1854L31.7822 28.1642Z" fill="white" />
              <path
                d="M38.5928 21.8501L38.6768 22.0497C38.808 22.3589 38.717 22.7193 38.4528 22.926L34.3525 26.1449L35.0543 30.6357L40.3795 26.6431L38.619 21.8572L38.5928 21.8501Z"
                fill="white" />
              <path
                d="M38.8207 12.0628L39.3842 10.948L39.9477 12.0628L40.9784 12.6723L39.9477 13.2818L39.3842 14.3966L38.8207 13.2818L37.7899 12.6723L38.8207 12.0628ZM29.3794 4.10397L29.9429 2.9892L30.5064 4.10397L31.5372 4.71347L30.5064 5.32297L29.9429 6.43773L29.3794 5.32297L28.3487 4.71347L29.3794 4.10397ZM12.7999 1.11477L13.3634 0L13.9269 1.11477L14.9577 1.72427L13.9269 2.33377L13.3634 3.44853L12.7999 2.33377L11.7692 1.72427L12.7999 1.11477ZM1.52119 1.11477L2.08469 0L2.64819 1.11477L3.67894 1.72427L2.64819 2.33377L2.08469 3.44853L1.52119 2.33377L0.492188 1.72427L1.52119 1.11477ZM4.17944 14.3966L3.14869 15.0061L2.58519 16.1208L2.02169 15.0061L0.990937 14.3966L2.02169 13.7871L2.58519 12.6723L3.14869 13.7871L4.17944 14.3966Z"
                fill="white" />
              <path d="M21.138 26.4152L19.8867 28.5246L25.2557 29.7824L28.4722 28.2967L21.138 26.4152Z" fill="white" />
              <path d="M26.1484 31.0014V31.27L26.7417 36.8685L33.3952 31.8795L30.5147 28.9839L26.1484 31.0014Z"
                fill="white" />
            </svg><span style="font-size: 17px;">Golden Plan</span>
          </div>
          <div class="col-12 text-center marg-tb-20">
            <h2 class="green-grad-color fw-700 inline-dis">349 SAR</h2><span>&nbsp;/ Monthly</span>
            <p>Choose your Kafu plan</p>
          </div>
          <div class="col-12 marg-tb-20 pad-zero">
            <ul class="plan-options">
              <li>
                <svg class="tick-svg-3" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Unlimited Survey
              </li>
              <li>
                <svg class="tick-svg-3" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Unlimited RSP
              </li>
              <li>
                <svg class="tick-svg-3" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                2 Brand
              </li>
              <li>
                <svg class="tick-svg-3" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Unlimited Reports (PDF + Excel)
              </li>
              <li>
                <svg class="tick-svg-3" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Public/Private
              </li>
              <li>
                <svg class="tick-svg-3" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                  fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.09053 9.49111L1.27147 5.72982L-0.00154266 6.98358L5.09053 11.9986L16.0021 1.25209L14.7291 -0.00167395L5.09053 9.49111Z"
                    fill="#1D1D49" />
                </svg>
                Kafu Network (Mobile)
              </li>
            </ul>
          </div>
          <button class="col-8 get-plan-btn green-grad text-center">{{ "pricing.buttonHeading" | translate }}</button>
        </div>
      </div> -->
    </div>
  </div>
</div>