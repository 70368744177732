<app-header></app-header>

<!-- Start Main -->

<div class="wrapper single-survey-wrap">
  <!-- Start Main -->
  <main class="navy-part">
    <section class="surv-info">
      <div class="container-fluid">
        <div class="welcome has-back d-md-flex justify-content-between">
          <div class="back-button">
            <a
              class="m-btn m-btn-gradient back-btn"
              [routerLink]="['/']"
            >
              <i class="fas fa-arrow-left"></i
              >{{ "general.back" | translate }}</a
            >

            <h1>
              {{ "survey.welcome" | translate }}
              {{ authService?.currentUser?.firstName }}
              <span> {{ "survey.welcome description" | translate }}</span>
            </h1>
          </div>

          <div>
            <app-counter [dates]="{ startDate: survey?.startDate, endDate: survey?.endDate}"
              countClass="m-text-white"
              countLabelClass="m-text-white"
              separatorClass="m-text-white"
              [showSeparator]="true">
            </app-counter>
          </div>

          <!-- <div class="survey-info counter-header">
            <ul class="counter">
              <li>
                <b>{{ days }}</b>
                <span>{{ "general.days" | translate }}</span>
              </li>
              <li>
                <b>{{ hours }}</b>
                <span>{{ "general.hours" | translate }}</span>
              </li>
              <li>
                <b>{{ minutes }}</b>
                <span>{{ "general.mins" | translate }}</span>
              </li>
              <li>
                <b>{{ seconds }}</b>
                <span>{{ "general.secs" | translate }}</span>
              </li>
            </ul>
            <div class="star d-md-flex align-items-center">
              <span><img src="assets/imgs/gold-star.svg" alt="" /></span>
            </div>
            <ul class="stats d-flex flex-row align-items-center">
              <li>
                <i class="fas fa-calendar-alt"></i>
                {{ "survey.survey" | translate }}
              </li>
              <li><i class="fas fa-heart"></i> 0</li>
            </ul>
          </div>
           -->
        </div>
        <div class="title-page">
          <div class="d-flex justify-content-between">
            <div class="survey-title d-flex align-items-center">
              <img
                [src]="
                survey?.surveyImage ? survey?.surveyImage : 'https://karateinthewoodlands.com/wp-content/uploads/2017/09/default-user-image.png'
                "
                alt=""
              />
              <h1>
                {{ survey?.title }}
                <span>{{ survey?.description }}</span>
              </h1>
            </div>
            <div class="pages">
              <h6>{{ "survey.pageNumber" | translate }}</h6>
              <pagination-controls
                (pageChange)="onPageChange($event)"
                previousLabel=""
                nextLabel=""
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Start Questions Sec -->

    <section  class="survey-answers-wrapper">
      <!-- surveyOption?.questions -->
      <!-- [formGroup]="surveyForm" -->
      <!-- | paginate: { itemsPerPage: 1, currentPage: page } -->
      <ng-container *ngIf="allAnswersSurvey?.length">
        <form *ngFor="let survey of allAnswersSurvey | paginate: { itemsPerPage: 1, currentPage: page }; let surveyIndex = index;" >
          <ng-container *ngFor="let ques of survey?.questions; let quesIndex = index">
            <div class="card question-card" [class.evaluation-question]="ques?.questionType === eQuesTypes.EVALUATION">
              <div class="card-header">
                {{ "survey.Question" | translate }}
                {{ ques?.questionNo }}
                <span class="question-type-title">({{ ques?.questionType }})</span>
                <span class="font-16 text-grey" *ngIf="ques?.isOptional">
                  ({{'survey.optional' | translate}})
                </span>

                <i class="fas fa-exclamation-circle question-info-icon"></i>
              </div>

              <!-- Question Template Start -->
              <div class="card-body card-body1">
                <div class="row mb-4">
                  <div class="col-md-2 col-3" *ngIf="ques?.imageUrl">
                    <div class="photo">
                      <!-- (click)="openPreviewModal(control.value?.imageUrl)" -->
                      <img class="question-img cursor-pointer" [src]="ques?.imageUrl"/>
                    </div>
                  </div>
                  <div [ngClass]="{'col-md-10 col-9 align-self-center': ques?.imageUrl, 'col-12': !ques?.imageUrl }">
                    <div class="partition-box">
                      <p class="card-question" id="ques-{{ quesIndex }}">
                        {{ ques?.question }}
                      </p>
                    </div>

                  </div>
                </div>
              </div>
              <!-- Question Template End -->

              <!-- Answers Template Start -->
              <div class="card-body">

                <ng-container *ngIf="!ques?.questionType?.isYesNo">

                  <!-- Text Answer Template Start -->
                  <ng-container *ngIf="ques?.questionType === eQuesTypes.TEXT">
                    <div class="form-group">
                      <textarea
                        readonly
                        disabled
                        [value]="ques?.selectedAnswer[0]"
                        class="form-control bgText"
                        placeholder="Enter your answer"
                        id="text-answer-{{ quesIndex }}"
                        name="text-answer-{{ quesIndex }}"
                        cols="20"
                        rows="3"
                      >
                      </textarea>
                    </div>
                  </ng-container>
                  <!-- Text Answer Template End -->

                  <!-- Multi Select Answer Template Start -->
                  <ng-container *ngIf="ques?.questionType === eQuesTypes.MULTI_SELECT">
                    <div class="row">
                      <ng-container *ngFor="let answer of ques?.answers; let ansIndex = index;">
                        <div class="col-md-6 col-12 my-5">
                          <div class="checkbox-round d-flex align-items-center">
                            <input
                              disabled
                              type="checkbox"
                              [value]="answer?.option"
                              id="option-checkbox-{{ quesIndex }}-{{ ansIndex }}"
                              [checked]="ques?.selectedAnswer?.includes(answer?.option)"
                            />
                            <label for="option-checkbox-{{ quesIndex }}-{{ ansIndex }}"></label>

                            <div class="option-icon-preview cursor-pointer">
                              <!-- (click)="openPreviewModal(answer.imageUrl)" -->
                              <img src="{{answer.imageUrl ? answer.imageUrl : './assets/imgs/nopath.png' }}"
                                alt=""
                              />
                            </div>
                            <!-- checkbox-text -->
                            <span class="ml-3 partition-box">{{answer?.option}}</span>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <!-- Multi Select Answer Template End -->

                  <!-- Single Select Answer Template Start -->
                  <ng-container *ngIf="ques?.questionType === eQuesTypes.SINGLE_SELECT">
                    <div class="row">
                      <ng-container *ngFor="let answer of ques?.answers; let sAnsIndex = index;">
                        <div class="col-md-6 col-12 my-5">
                          <div class="radio-round d-flex align-items-center">
                            <input
                              disabled
                              type="radio"
                              id="option-radio-{{ quesIndex }}-{{ sAnsIndex }}"
                              name="sq-radio-{{ quesIndex }}"
                              [checked]="ques?.selectedAnswer?.includes(answer?.option)"
                            />
                            <label for="option-radio-{{ quesIndex }}-{{ sAnsIndex }}"></label>
                              <div class="option-icon-preview cursor-pointer">
                                <!-- (click)="openPreviewModal(answer.imageUrl)" -->
                                <img
                                  src="{{answer.imageUrl ? answer.imageUrl : './assets/imgs/nopath.png'}}"
                                  alt=""
                                />
                              </div>
                            <span class="ml-3 partition-box">{{answer?.option}}</span>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <!-- Single Select Answer Template End -->

                  <!-- Evaluation Answer Template Start -->
                  <ng-container *ngIf="ques?.questionType === eQuesTypes.EVALUATION">
                    <div class="answer-area">
                      <div class="answer evaluation">
                        <ul>
                          <li>
                            <label for="evl-ans-{{ quesIndex }}-0"
                              [ngClass]="{ current: ques?.selectedAnswerIndex != null && ques?.selectedAnswerIndex >= 0}"
                            >
                              <span>1</span>
                            </label>
                            <div class="option" [ngClass]="{current: ques?.selectedAnswerIndex == 0}">
                              {{ ques?.answers[0]?.option }}
                            </div>
                          </li>
                          <li>
                            <label for="evl-ans-{{ quesIndex }}-1"
                              [ngClass]="{ current: ques?.selectedAnswerIndex >= 1 }"
                            >
                              <span>2</span>
                            </label>
                          </li>
                          <li>
                            <label for="evl-ans-{{ quesIndex }}-2"
                              [ngClass]="{current: ques?.selectedAnswerIndex >= 2}"
                            >
                              <span>3</span>
                            </label>
                            <div class="option" [ngClass]="{current: ques?.selectedAnswerIndex == 2}">
                              {{ques?.answers[1]?.option}}
                            </div>
                          </li>
                          <li>
                            <label for="evl-ans-{{ quesIndex }}-3"
                              [ngClass]="{ current: ques?.selectedAnswerIndex >= 3 }"
                            >
                              <span>4</span>
                            </label>
                          </li>
                          <li>
                            <label for="evl-ans-{{ quesIndex }}-4"
                              [ngClass]="{ current: ques?.selectedAnswerIndex >= 4 }"
                            >
                              <span>5</span>
                            </label>
                            <div class="option" [ngClass]="{ current: ques?.selectedAnswerIndex == 4 }">
                              {{ ques?.answers[2]?.option }}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </ng-container>
                  <!-- Evaluation Answer Template End -->
                </ng-container>
                <!-- Multi Select Answer Template Start -->

                <!-- Yes or No Template Start-->
                <ng-container *ngIf="ques?.isYesNo">
                  <div class="row my-4">
                    <div class="col-6" *ngFor="let answer of ques?.answers; let ansIndex = index;">
                      <input
                        type="radio"
                        disabled
                        hidden
                        class="yesNoHiddenRadio"
                        id="yesNo-option-{{quesIndex}}-{{ansIndex}}"
                        name="yesNo-{{ quesIndex }}"
                        [checked]="ques?.selectedAnswer?.includes(answer.option)"
                      />
                      <label
                        for="yesNo-option-{{ quesIndex }}-{{ansIndex}}"
                        class="yes-no-card"
                        [class.active]="ques?.selectedAnswer?.includes(answer.option)"
                      >
                        {{ "survey." + answer.option | translate }}
                      </label>
                    </div>
                  </div>
                </ng-container>
                <!-- Yes or No Template End-->

                <!-- Order Text Start-->
                <ng-container *ngIf="ques?.isOrderText">
                  <div class="row mt-4">
                    <div class="col-12">
                      <h4 class="font-weight-bold text-capitalize mb-2">
                        {{ "survey.Other (Text)" | translate }}
                      </h4>
                      <div class="-input">
                        <textarea
                          readonly
                          class="form-control h-auto"
                          [value]="ques?.additionalText"
                          placeholder="{{ 'survey.AdditionalText' | translate }}"
                          cols="30"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- Order Text Start-->

              </div>
              <!-- Answers Template End -->

            </div>
          </ng-container>
        </form>
      </ng-container>

    <ng-container *ngIf="!allAnswersSurvey?.length">
      <h2>{{ 'survey.notAnsweredYet' | translate }}</h2>
    </ng-container>

    </section>




    <!-- <section class="surv-questions">
      <div class="container-fluid">
        <div
          class="question-card"
          *ngFor="
            let question of surveyOption?.questions
              | paginate: { itemsPerPage: 2, currentPage: page };
            let i = index
          "
        >
          <div class="head d-flex justify-content-between">
            <h5>
              {{ "survey.Question" | translate }} {{ i + 1 }} ({{
                question.answerTypeLabel
              }})
            </h5>
          </div>
          <div class="question d-flex">
            <div>
              <div *ngIf="question.questionImage">
                <img [src]="question.questionImage" alt="" />
              </div>
            </div>
            <h1>
              {{ question.question }}
            </h1>
          </div>
          <div
            class="answer evaluation"
            *ngIf="question.answerTypeLabel === 'Evaluation'"
          >
            <ul>
              <li>
                <input type="checkbox" id="ans1" checked disabled hidden />
                <label for="ans1">
                  <span>1</span>
                </label>
                <input
                  type="text"
                  [value]="question.answers[0].answer"
                  readonly
                />
              </li>
              <li>
                <input type="checkbox" id="ans2" checked disabled hidden />
                <label for="ans2">
                  <span>2</span>
                </label>
                <input type="text" invisible />
              </li>
              <li>
                <input type="checkbox" id="ans3" checked disabled hidden />
                <label for="ans3">
                  <span>3</span>
                </label>
                <input
                  type="text"
                  [value]="question.answers[1].answer"
                  readonly
                />
              </li>
              <li>
                <input type="checkbox" id="ans4" checked disabled hidden />
                <label for="ans4">
                  <span>4</span>
                </label>
                <input type="text" invisible />
              </li>
              <li>
                <input type="checkbox" id="ans5" checked disabled hidden />
                <label for="ans5">
                  <span>5</span>
                </label>
                <input
                  type="text"
                  [value]="question.answers[2].answer"
                  readonly
                />
              </li>
            </ul>
          </div>

          <div
            class="answer multiple-choice"
            *ngIf="question.answerTypeLabel === 'Text'"
          >
            <ul *ngFor="let answer of question.answers; let i = index">
              <li>
                <input type="checkbox" id="pic1" hidden="" />
                <label for="pic1">
                  <p>
                    {{ answer.answer }}
                  </p>
                </label>
              </li>
            </ul>
          </div>

          <div
            class="answer multiple-choice"
            *ngIf="question.answerTypeLabel === 'MultipleChoice'"
          >
            <ul *ngFor="let answer of question.answers; let i = index">
              <li>
                <input type="checkbox" id="pic1" hidden="" />
                <label for="pic1">
                  <span><i class="fas fa-check"></i></span>
                  <div *ngIf="answer.answerImage">
                    <img [src]="answer.answerImage" alt="" />
                  </div>
                  <p>
                    {{ answer.answer }}
                  </p>
                </label>
              </li>
            </ul>
          </div>

          <div
            class="answer multiple-choice"
            *ngIf="question.answerTypeLabel === 'SingleChoice'"
          >
            <ul *ngFor="let answer of question.answers; let i = index">
              <li>
                <input type="radio" id="pic1" hidden="" />
                <label for="pic1">
                  <span><i class="fas fa-check"></i></span>
                  <div *ngIf="answer.answerImage">
                    <img [src]="answer.answerImage" alt="" />
                  </div>
                  <p>
                    {{ answer.answer }}
                  </p>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Questions Sec -->
  </main>
  <!-- End Main -->
</div>

<app-footer></app-footer>

<app-loader [show]="showLoader"></app-loader>
