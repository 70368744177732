import { TokenInterceptor } from "./../services/token.interceptor";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AsyncPipe, DatePipe } from "@angular/common";
import { NgOtpInputModule } from "ng-otp-input";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { LocalStorageModule } from "angular-2-local-storage";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { PartialsModule } from "./partials/partials.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { httpLoaderFactory } from "./home/home.module";
import { FormsModule, FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { APP_BASE_HREF } from "@angular/common";
import { ModalModule } from "ngx-bootstrap/modal";
import { PluginService } from "../services/pluginservice.service";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  FacebookLoginProvider,
} from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { environment } from "../environments/environment";
import { MessagingService } from "../services/messaging.service";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";

const config: SocketIoConfig = {
  url: environment.socketUrl, // socket server url;
  options: {
    transports: ["websocket"],
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    LocalStorageModule.forRoot({
      prefix: "Kafu",
      storageType: "localStorage",
    }),
    SocialLoginModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgOtpInputModule,
    PartialsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ModalModule.forRoot(),
    SocketIoModule.forRoot(config),

    ReactiveFormsModule,
    FormsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    FormBuilder,
    PluginService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    DatePipe,
    {
      provide: APP_BASE_HREF,
      useValue: "/",
    },
    MessagingService,
    AsyncPipe,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "985129344024-lvsi2e0ihmdfcagojrnv6fv99h7vp133.apps.googleusercontent.com",
              {
                scope: "profile email",
                plugin_name: "kafuweb",
              }
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
