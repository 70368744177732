import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { LanguageService } from "src/services/language.service";
import { SurveyService } from "src/services/survey.service";
import { CreateSurveyComponent } from "../create-survey/create-survey.component";
import { CreateCustomSurveyComponent } from "../create-custom-survey/create-custom-survey.component";

@Component({
  selector: "app-new-servey-second",
  templateUrl: "./new-servey-second.component.html",
  styleUrls: ["./new-servey-second.component.css"],
})
export class NewServeySecondComponent implements OnInit {
  darkMode = false;
  onClose: any;
  brand: any
  constructor(
    public darkThemeService: DarkLiteThemeService,
    private router: Router,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private surveyService: SurveyService,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.getBrand()
  }

  close() {
    this.onClose();
  }
  getBrand() {
    this.surveyService.getUserBrand().subscribe((userBrand) => {
      this.brand = userBrand;
    })
  }
  createSurvey() {
    this.onClose();
    const verifyModel = this.modalService.show(CreateSurveyComponent, {
      class:
        "modal-lg modal-dialog-centered sign-up c-survey-modal  create-survey-modal",
      backdrop: "static",
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
  }

  createCustomSurvey() {
    // brand list ha then send (["/create-custom-survey-list"])
    if (this.brand.length > 0) {
      this.onClose();
      console.log("assax")
      this.router.navigate(["/create-custom-survey-list"]);
    } else {
      this.onClose();
      console.log("else nothing")
      this.router.navigate(["/create-custom-survey"]);
    }

    // const verifyModel = this.modalService.show(CreateCustomSurveyComponent, {
    //   class: "new-survey create-survey-modal",
    //   backdrop: "static",
    // });
    // verifyModel.content.onClose = (auth) => {
    //   verifyModel.hide();
    // };
  }
}
