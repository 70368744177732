import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { eLanguage } from "src/app/general/enums/language.enum";
import { AuthService } from "src/services/auth.service";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { LanguageService } from "src/services/language.service";
import { CommingSoonModalComponent } from "../loggedin-home/comming-soon-modal/comming-soon-modal.component";
declare var $: any;
@Component({
  selector: "app-new-sidebar",
  templateUrl: "./new-sidebar.component.html",
  styleUrls: ["./new-sidebar.component.css"],
})
export class NewSidebarComponent implements OnInit {
  isOpen = false;
  user: any;
  showLoader = false;
  avatar: null;
  changeToLang: any;
  constructor(
    public authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    public darkThemeService: DarkLiteThemeService,
    public languageService: LanguageService,
    public activatedRoute: ActivatedRoute
  ) {
    this.user = this.authService.currentUser;
    this.changeToLang =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_English
        ? eLanguage.Short_Arabic
        : eLanguage.Short_English;
  }

  ngOnInit(): void {
    this.getUser();
  }
  logout() {
    this.authService.LogOut();
  }
  handleNavigate(path) {
    this.router.navigate([`/${path}`]);
  }
  handleWallet(path) {
    this.router.navigateByUrl(`/profile#${path}`);
  }
  isRouteActive(route: string): boolean {
    return this.router.isActive(route, false) && this.router.url === route;
  }
  RechargeWallet() {
    this.router.navigate(["/profile"]);
    $('.nav-tabs a[href="#Wallet"]').tab("show active");
  }
  toggleButtonToChangeThemeClass() {
    this.darkThemeService.toggleTheme();
  }
  Surveys() {
    this.router.navigate(["/reporting"]);
  }

  getUser() {
    this.showLoader = true;
    this.authService.getUser().subscribe(
      (result) => {
        this.user = result?.body ? result.body : {};
        if (this.user.avatar) {
          this.avatar = this.user.avatar;
          //  this.sanitization.bypassSecurityTrustStyle(
          //   `${this.user.avatar}`
          // );
        }

        if (result?.body) {
          this.authService.setCurrentUser(result?.body, null);
        }

        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
      }
    );
    var url = window.location.href;
    var activeTab = url.substring(url.indexOf("#") + 1);
    if (activeTab === "Wallet") {
      $(".tab-pane").removeClass("active in");
      $("#" + activeTab).addClass("active in");
      $('a[href="#' + activeTab + '"]').tab("show");
    }
  }
  SurveysPortal() {
    this.router.navigate(["/surveys"]);
  }
  changeLang(): any {
    if (
      this.languageService.getLanguageOrDefault() == eLanguage.Short_English
    ) {
      this.languageService.setLanguage(eLanguage.Short_Arabic);

      this.router.navigate(["."], {
        queryParams: {
          lang: eLanguage.Short_Arabic,
        },
        relativeTo: this.activatedRoute,
        queryParamsHandling: "merge",
      });
    } else {
      this.languageService.setLanguage(eLanguage.Short_English);

      this.router.navigate(["."], {
        queryParams: {
          lang: eLanguage.Short_English,
        },
        relativeTo: this.activatedRoute,
        queryParamsHandling: "merge",
      });
    }
    setTimeout(() => window.location.reload(), 0);
  }
  openComingSoonModal() {
    const entModel = this.modalService.show(CommingSoonModalComponent, {
      class: "redeem-modal-container modal-dialog-centered",
      // backdrop: 'static',
    });
    entModel.content.onClose = () => {
      entModel.hide();
    };
  }
  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }
}
