<app-header></app-header>

<div class="home-wrap my-stats">
  <div class="pg-head">
    <div class="container-fluid">
      <div class="d-flex">
        <div>
          <h1 class="title">{{ "home.myStats" | translate }}</h1>
          <div class="kf-breadcrumb">
            <ul>
              <li>
                <a class="text-capitalize">{{ "profile.Home" | translate }}</a>
              </li>
              <li>
                <a class="text-capitalize">{{
                  "profile.My Account" | translate
                }}</a>
              </li>
              <li>
                <a class="text-capitalize">{{ "home.myStats" | translate }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section>
    <div class="home-wrap my-stats">
      <div class="container-fluid">
        <div class="stats">
          <div class="d-flex justify-content-around">
            <div>
              <div class="icon">
                <b class="Count">{{ userStats?.surveys }}</b>
                <p class="d-none">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Lorem ipsum dolor sit amet, consectetur
                </p>
              </div>
              <h5 class="">{{ "home.mySurveys" | translate }}</h5>
            </div>
            <div>
              <div class="icon">
                <b class="Count">{{ userStats?.answers }}</b>
                <p class="d-none">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Lorem ipsum dolor sit amet, consectetur
                </p>
              </div>
              <h5 class="">{{ "home.answers" | translate }}</h5>
            </div>
            <div>
              <div class="icon">
                <b class="Count">{{ userStats?.spendingPoints }}</b>
                <p class="d-none">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Lorem ipsum dolor sit amet, consectetur
                </p>
              </div>
              <h5 class="">{{ "home.spendingPoints" | translate }}</h5>
            </div>
            <div>
              <div class="icon">
                <b class="Count">{{ userStats?.freePoints }}</b>
                <p class="d-none">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Lorem ipsum dolor sit amet, consectetur
                </p>
              </div>
              <h5 class="">{{ "home.freePoints" | translate }}</h5>
            </div>
            <div>
              <div class="icon">
                <b class="Count">{{ userStats?.referrals }}</b>
                <p class="d-none">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Lorem ipsum dolor sit amet, consectetur
                </p>
              </div>
              <h5 class="">{{ "home.referrals" | translate }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Chart Section-->
  <div class="container-fluid">
    <ul class="nav nav-tabs stats-tabs" id="statsTabs" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          id="visit-tab"
          data-toggle="tab"
          href="#visitTab"
          role="tab"
          aria-controls="visit"
          aria-selected="true"
        >
          {{ "survey.visit" | translate }}
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="responses-tab"
          data-toggle="tab"
          href="#responsesTab"
          role="tab"
          aria-controls="responses"
          aria-selected="false"
          >{{ "survey.responses" | translate }}</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="likes-tab"
          data-toggle="tab"
          href="#likesTab"
          role="tab"
          aria-controls="likes"
          aria-selected="false"
          >{{ "survey.likes" | translate }}</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="generations-tab"
          data-toggle="tab"
          href="#generationsTab"
          role="tab"
          aria-controls="generations"
          aria-selected="false"
          >{{ "survey.generation" | translate }}</a
        >
      </li>
    </ul>

    <div class="tab-content">
      <div
        class="tab-pane fade show active"
        id="sTabs"
        role="tabpanel"
        aria-labelledby="stats-tab"
      >
        <div class="mainbox">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <h5 class="mb-2 font-weight-bold">
                      {{ "profile.Gender" | translate }}
                    </h5>
                    <div class="doted-border-card">
                      <div class="d-flex align-items-center mb-5">
                        <div class="progressbar-title">
                          <label class="mr-2 text-capitalize">{{
                            "profile.male" | translate
                          }}</label>
                        </div>

                        <div class="progressbar-width">
                          <progressbar
                            [value]="male"
                            class="m-bg-magenta"
                          ></progressbar>
                        </div>
                        <label class="ml-2">{{ male }}%</label>
                      </div>

                      <div class="d-flex align-items-center">
                        <div class="progressbar-title">
                          <label class="mr-2 text-capitalize">{{
                            "profile.female" | translate
                          }}</label>
                        </div>
                        <div class="progressbar-width">
                          <progressbar
                            [value]="female"
                            class="m-bg-violet"
                          ></progressbar>
                        </div>
                        <label class="ml-2">{{ female }}%</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-12">
                  <div class="form-group">
                    <h5 class="mb-2 font-weight-bold">
                      {{ "home.Channel" | translate }}
                    </h5>
                    <div class="doted-border-card">
                      <div class="d-flex align-items-center mb-5">
                        <div class="progressbar-title">
                          <label class="mr-2">{{
                            "home.Web" | translate
                          }}</label>
                        </div>

                        <div class="progressbar-width">
                          <progressbar
                            [value]="web"
                            class="m-bg-magenta"
                          ></progressbar>
                        </div>
                        <label class="ml-2">{{ web }}%</label>
                      </div>

                      <div class="d-flex align-items-center">
                        <div class="progressbar-title">
                          <label class="mr-2">{{
                            "home.Mobile" | translate
                          }}</label>
                        </div>
                        <div class="progressbar-width">
                          <progressbar
                            [value]="mobile"
                            class="m-bg-violet"
                          ></progressbar>
                        </div>
                        <label class="ml-2">{{ mobile }}%</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <h5 class="mb-2 font-weight-bold">
                  {{ "home.answers" | translate }}
                </h5>
                <div class="doted-border-card">
                  <canvas id="anwersChart" class="w-100" height="280"></canvas>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <h5 class="mb-2 font-weight-bold">
                  {{ "profile.Country" | translate }}
                </h5>
                <div class="doted-border-card">
                  <!-- <div class="d-flex align-items-center my-3">
                    <div class="progressbar-title">
                      <label class="mr-2">Algeria</label>
                    </div>

                    <div class="progressbar-width">
                      <progressbar
                        [value]="30"
                        class="m-bg-magenta"
                      ></progressbar>
                    </div>
                    <label class="ml-2">30%</label>
                  </div> -->

                  <!-- <div class="d-flex align-items-center mb-3">
                    <div class="progressbar-title">
                      <label class="mr-2">Bahrain</label>
                    </div>

                    <div class="progressbar-width">
                      <progressbar
                        [value]="30"
                        class="m-bg-violet"
                      ></progressbar>
                    </div>
                    <label class="ml-2">30%</label>
                  </div> -->
                  <!-- 
                  <div class="d-flex align-items-center mb-3">
                    <div class="progressbar-title">
                      <label class="mr-2">Egypt</label>
                    </div>

                    <div class="progressbar-width">
                      <progressbar
                        [value]="30"
                        class="m-bg-skyBlue"
                      ></progressbar>
                    </div>
                    <label class="ml-2">30%</label>
                  </div>

                  <div class="d-flex align-items-center mb-3">
                    <div class="progressbar-title">
                      <label class="mr-2">Iraq</label>
                    </div>

                    <div class="progressbar-width">
                      <progressbar
                        [value]="30"
                        class="m-bg-light-green"
                      ></progressbar>
                    </div>
                    <label class="ml-2">30%</label>
                  </div>

                  <div class="d-flex align-items-center mb-3">
                    <div class="progressbar-title">
                      <label class="mr-2">Jordan</label>
                    </div>

                    <div class="progressbar-width">
                      <progressbar
                        [value]="30"
                        class="m-bg-light-yellow"
                      ></progressbar>
                    </div>
                    <label class="ml-2">30%</label>
                  </div>

                  <div class="d-flex align-items-center mb-3">
                    <div class="progressbar-title">
                      <label class="mr-2">Kuwait</label>
                    </div>

                    <div class="progressbar-width">
                      <progressbar
                        [value]="30"
                        class="m-bg-light-orange"
                      ></progressbar>
                    </div>
                    <label class="ml-2">30%</label>
                  </div>

                  <div class="d-flex align-items-center mb-3">
                    <div class="progressbar-title">
                      <label class="mr-2">Libya</label>
                    </div>

                    <div class="progressbar-width">
                      <progressbar
                        [value]="30"
                        class="m-bg-light-brown"
                      ></progressbar>
                    </div>
                    <label class="ml-2">30%</label>
                  </div> -->

                  <div
                    class="d-flex align-items-center mb-4"
                    *ngFor="let count of country"
                  >
                    <div class="progressbar-title">
                      <label class="mr-2">{{ count.name }}</label>
                    </div>

                    <div class="progressbar-width">
                      <progressbar
                        [value]="(count.count / total) * 100"
                        class="m-bg-light-gray"
                      ></progressbar>
                    </div>
                    <label class="ml-2">{{ count.count }}%</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <h5 class="mb-2 font-weight-bold">
                  {{ "profile.contacts" | translate }}
                </h5>
                <div class="doted-border-card">
                  <canvas id="contactChart" class="w-100"></canvas>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <div class="doted-border-card">
                  <canvas id="barChart" class="w-100"></canvas>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <div class="form-group">
                <div class="doted-border-card">
                  <canvas id="graphChart" class="w-100"></canvas>
                </div>
              </div>
            </div>
          </div>

          <div class="graph">
            <h4 class="font-700 mb-3 text-capitalize">
              {{ "profile.downloadBasicReport" | translate }}
            </h4>
            <div class="report mb-3">
              <button
                class="m-btn m-btn-download"
                (click)="basicReportDownload()"
              >
                {{ "profile.downloadExcelFile" | translate }}
                <i class="fas fa-download" style="padding-left: 10px"></i>
              </button>
              <button
                class="m-btn m-btn-download"
                (click)="basicReportDownload()"
              >
                {{ "profile.downloadPDFReport" | translate }}
                <i class="fas fa-download" style="padding-left: 10px"></i>
              </button>
            </div>
            <h4 class="font-700 mb-3">
              {{ "profile.downloadFullReport" | translate }}
            </h4>
            <div class="report">
              <button class="m-btn m-btn-download">
                {{ "profile.downloadExcelFile" | translate }}
                <i class="fas fa-download" style="padding-left: 10px"></i>
              </button>
              <button class="m-btn m-btn-download">
                {{ "profile.downloadPDFReport" | translate }}
                <i class="fas fa-download" style="padding-left: 10px"></i>
              </button>
            </div>
          </div>

          <div class="graph"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
