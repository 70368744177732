import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { sha256 } from "js-sha256"; //Lib For Encription
import { PlanService } from "src/services/plan.service";
import { PluginService } from "../../../services/pluginservice.service";
import { ResponseCode } from "src/constant/response-code";
import { LanguageService } from "../../../services/language.service";
import { eLanguage } from "src/app/general/enums/language.enum";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.css"],
})
export class PlanComponent implements OnInit {
  onClose: any;
  data = [];
  language: any;
  isChecked = false;
  redirectURL = "";
  showLoader = false;
  getTerminalId = environment.GET_TERMINAL_ID;
  getPassword = environment.GET_PASSWORD;
  getMerchantKey = environment.GET_MERCHANT_KEY
  serverUrl = environment.SERVER_URL

  router: any;
  close(data = null) {
    this.onClose(data);
  }
  constructor(
    public modal: BsModalRef,
    private planService: PlanService,
    private services: PluginService,
    // private route: Router,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private toastr: ToastrService,

  ) {
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    console.log("language", this.language);
    console.log(this.isChecked, "whats here");
  }

  ngOnInit(): void {
    this.getPlan();
    this.getOrderId();
  }
  // getPlan() {
  //   this.planService.getPlans().subscribe((result) => {
  //     this.data = result;
  //   });
  // }
  getPlan() {
    this.planService.getMemberShip().subscribe((result) => {
      console.log("🚀 ~ file: plan.component.ts:62 ~ PlanComponent ~ this.planService.getMemberShip ~ result:", result)
      this.data = result;
    });
  }
  checkValue(event: any) {
    this.isChecked = event
    console.log(this.isChecked);
  }
  recharge(plan: any) {
    const id = plan._id;
    this.planService.postPlan(id).subscribe((result) => { });
    console.log(plan, "plan");
    this.planService.createLocalPlanModal(plan);
    this.recharging(plan);
  }
  generateHash(arg: string) {
    // hashSequence = posted["trackid"]+"|"+posted["terminalId"]+"|"+posted["password"]+"|"+posted["secret"]+"|"+posted["amount"]+"|"+posted["currency"];
    var hashSequence = arg;
    return sha256(hashSequence);
  }
  getOrderId() {
    return Math.random().toString().slice(2, 5);
  }
  recharging(plan: any) {
    if (this.isChecked === false) {
      this.translateService
        .get("plan.error_message")
        .subscribe((text) => {
          this.toastr.error(text);
        });
    }
    let trackingId = this.getOrderId()
    // hashSequence = posted["trackid"]+"|"+posted["terminalId"]+"|"+posted["password"]+"|"+posted["secret"]+"|"+posted["amount"]+"|"+posted["currency"];
    let hashSequence = trackingId + "|" + this.getTerminalId + "|" + this.getPassword + "|" + this.getMerchantKey + "|" + plan.totalAmount + "|" + "SAR";
    console.log(hashSequence, "hashSequence")
    let hash = this.generateHash(hashSequence);
    let paymentForm = {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      trackid: trackingId,
      terminalId: this.getTerminalId,
      customerEmail: "hasnat98044@gmail.com",
      action: "1",
      merchantIp: "9.10.10.102",
      password: this.getPassword,
      currency: "SAR",
      country: "SA",
      transid: "",
      tokenOperation: "",
      cardToken: "",
      tokenizationType: "0",
      amount: plan.totalAmount,
      requestHash: hash,
      udf1: "",
      udf2: "",
      udf3: "",
      udf4: "",
      udf5: "",
    };
    debugger;
    this.services.paymentAPI(paymentForm).subscribe(async (res) => {
      let getResponse = JSON.parse(JSON.stringify(res));
      if (ResponseCode.response_msg[getResponse["responseCode"]]) {
        console.log(getResponse, "getResponse");
        this.router.navigate(["payment-status"], {
          queryParams: getResponse,
        });
      } else {
        window.location.assign(
          getResponse["targetUrl"].replace("?", "") +
          "?paymentid=" +
          getResponse["payid"]
        );

      }
      // this.redirectURL =
      //   getResponse["targetUrl"].replace("?", "") +
      //   "?paymentid=" +
      //   getResponse["payid"];
    });
  }

}
