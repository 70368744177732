import { Injectable } from "@angular/core";
import { WebApiService } from "./webApi.service";

@Injectable()
export class ThankYouService {
  constructor(private webApi: WebApiService) { }

  getThankYou(id: any) {
    let data = {
      surveyName: id
    };
    return this.webApi.post(`/thankyou/search`, data);
  }
}
