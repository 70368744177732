import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "../../../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { LanguageService } from "../../../services/language.service";
import { ChangeForgetPasswordComponent } from "../change-forget-password/change-forget-password.component";
import { first } from "rxjs/operators";
import { WelcomeComponent } from "../welcome/welcome.component";
import { NgOtpInputComponent } from "ng-otp-input";
import { PlanComponent } from "src/app/home/plan/plan.component";
@Component({
  selector: "app-verify",
  templateUrl: "./verify.component.html",
  styleUrls: ["./verify.component.css"],
})
export class VerifyComponent implements OnInit, OnDestroy {
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  showLoader = false;
  otp: number;
  otpConfig = {
    length: 4,
    allowNumbersOnly: true, // This is the configuration for ng-otp-input
  };
  timerDuration: number = 60;
  count = 0;
  onClose: any = () => {};
  form: FormGroup;
  forget: boolean = false;
  login: boolean = false;
  phoneNumber: any;
  phoneCode: any;
  authToken: any;
  timerInterval: any;
  showOtpComponent: true;
  constructor(
    public languageService: LanguageService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    public modal: BsModalRef
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      verificationCode: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]{4}$/)],
      ],
    });
    this.startTimer();
  }

  close(Result = null) {
    console.log(Result, "close");
    this.onClose();
  }
  ngOnDestroy() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  onCodeInput(code: any) {
    if (code) {
      this.form
        .get("verificationCode")
        .setValue(
          (this.form.value.verificationCode
            ? this.form.value.verificationCode
            : "") + code
        );
    }
  }
  onOtpChange(otp) {
    this.otp = otp;
  }
  startTimer() {
    if (this.count >= 2) {
      this.timerDuration = 120;
    }

    this.count++;

    this.timerInterval = setInterval(() => {
      if (this.timerDuration > 0) {
        this.timerDuration--;
      }
    }, 1000);
  }

  processVerify() {
    this.showLoader = true;
    // if (this.forget) {
    //   this.authService
    //     .verifyForgottenPassword(this.form.value)
    //     .pipe(first())
    //     .subscribe(
    //       (result) => {
    //         this.showLoader = false;
    //         this.close({});
    //         this.changeForgetPassword(result.headers.get("AuthToken"));
    //       },
    //       (err) => {
    //         this.showLoader = false;
    //         this.translateService
    //           .get("verify.error_message")
    //           .subscribe((text) => {
    //             this.toastr.error(text);
    //           });
    //       }
    //     );
    // } else
    if (this.login) {
      const data = {
        verificationCode: this.otp,
      };
      this.authService
        .verify(data, this.authToken)
        .pipe(first())
        .subscribe(
          (result) => {
            this.showLoader = false;
            this.close({});
            this.authService.setCurrentUser(result.body, this.authToken);
            this.close();
            // open welcome dialog
            // this.welcome();
          },
          (err) => {
            this.showLoader = false;
            this.translateService
              .get("verify.error_message")
              .subscribe((text) => {
                this.toastr.error(text);
              });
          }
        );
    } else {
      const data = {
        verificationCode: this.otp,
      };
      this.authService
        .verify(data, this.authToken)
        .pipe(first())
        .subscribe(
          (result) => {
            this.showLoader = false;
            this.close({});
            this.authService.setCurrentUser(result.body, this.authToken);
            // open welcome dialog
            // this.welcome();
          },
          (err) => {
            this.showLoader = false;
            this.translateService
              .get("verify.error_message")
              .subscribe((text) => {
                this.toastr.error(text);
              });
          }
        );
    }
  }

  resendVerificationCode() {
    this.showLoader = true;
    let action = null;
    if (this.count == 5) {
      console.log("Verification");
      this.showLoader = false;

      return this.translateService.get("forget.blocked").subscribe((text) => {
        this.toastr.error(text);
      });
    } else {
      clearInterval(this.timerInterval);
      this.timerDuration = 120;
      this.startTimer();
      action = this.authService.sendSMSVerificationCode(
        this.phoneNumber,
        this.phoneCode,
        this.authToken
      );
    }
    action.subscribe(
      (result) => {
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
        if (this.phoneNumber) {
          this.translateService
            .get("forget.error_message")
            .subscribe((text) => {
              this.toastr.error(text);
            });
        } else {
          this.translateService.get("forget.error_email").subscribe((text) => {
            this.toastr.error(text);
          });
        }
      }
    );
  }

  changeForgetPassword(authToken: string) {
    this.close({});
    const changeForgetModel = this.modalService.show(
      ChangeForgetPasswordComponent,
      {
        class: "modal-dialog-centered login",
        backdrop: "static",
        initialState: {
          token: authToken,
        },
      }
    );
    changeForgetModel.content.onClose = (auth) => {
      changeForgetModel.hide();
    };
  }

  welcome() {
    this.close({});
    const welcomeModel = this.modalService.show(WelcomeComponent, {
      class: "modal-dialog-centered welcome",
      backdrop: "static",
    });
    welcomeModel.content.onClose = (auth) => {
      welcomeModel.hide();
    };
  }
  Plan() {
    const PlanModel = this.modalService.show(PlanComponent, {
      class: "modal-dialog1 modal-content1",
      backdrop: "static",
    });
    PlanModel.content.onClose = () => {
      // this.getUpdatedWallet();
      // this.getTransaction();
      PlanModel.hide();
    };
  }
}
