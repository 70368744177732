// >> http-post-service
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { sha256 } from "js-sha256"; //Lib For Encription
import * as configJson from "../constant/configuration.json";
import { ActivatedRoute, Router } from "@angular/router";
import { ValidateMerchant } from "./validate-merchant";
import { DatePipe } from "@angular/common";
import { ResponseCode } from "../constant/response-code";
import { environment } from "src/environments/environment";
@Injectable()
export class PluginService {
  urlParameters: Map<string, string>;
  transDate = "";
  transId = "";
  cardToken = "";
  orderAmount = "";
  status = "";
  alertType = "";
  currentDate = Date.now() + "";
  private serverUrl = environment.SERVER_URL
  errorMsg = "";
  validateMerchantObj: ValidateMerchant;

  constructor(
    private datePipe: DatePipe,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  paymentAPI(arg: any) {
    console.log("🚀 ~ file: pluginservice.service.ts:33 ~ paymentAPI ~ arg:", arg.customerEmail)
    let options = this.RequestPara();
    let data = {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      trackid: arg.trackid,
      terminalId: environment.GET_TERMINAL_ID,
      customerEmail: arg.customerEmail,
      action: "1",
      merchantIp: "9.10.10.102",
      password: environment.GET_PASSWORD,
      currency: "SAR",
      country: "SA",
      transid: "",
      tokenOperation: "",
      cardToken: "",
      tokenizationType: "0",
      amount: arg.amount,
      requestHash: arg.requestHash,
      // udf1: `http://localhost:4200/profile`,
      udf1: `${environment.app_url}/${arg.udf1}`,
      // udf2: `http://localhost:4200/profile#wallet`,
      udf2: `${environment.app_url}/${arg.udf2}`,
      udf3: "Premium plan",
      udf4: "",
      udf5: arg.udf5,
    };
    return this.http.post(this.serverUrl, data, { headers: options });
  }
  // "Access-Control-Allow-Origin" :"http://10.10.10.101:8089/URWAYPGService/transaction/jsonProcess/JSONrequest",
  RequestPara() {
    // let headers = new HttpHeaders({
    //     "Content-Type": "application/json",
    //     "Accept":"application/json",
    //     "Access-Control-Allow-Origin" :"http://localhost:4200",
    //     "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS, READ",
    //     "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept, X-Auth-Token, authorization, XMLHttpRrequest,user-agent,accept",
    //     "Access-Control-Allow-Credentials":"true"
    // });
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Access-Control-Allow-Origin", "*")
      .set(
        "Access-Control-Allow-Methods",
        "GET, POST, PATCH, PUT, DELETE, OPTIONS, READ"
      )
      .set(
        "Access-Control-Allow-Headers",
        "Origin, Content-Type, X-Auth-Token,authorization,XMLHttpRequest, user-agent, accept"
      );
    return headers;
  }
}
