import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { LanguageService } from "src/services/language.service";
import { SurveyService } from "src/services/survey.service";
import { CreateSurveyComponent } from "../create-survey/create-survey.component";
import { CommingSoonModalComponent } from "../loggedin-home/comming-soon-modal/comming-soon-modal.component";
import { CreateCustomSurveyComponent } from "../create-custom-survey/create-custom-survey.component";
import { NewServeySecondComponent } from "../new-servey-second/new-servey-second.component";

@Component({
  selector: "app-new-servey",
  templateUrl: "./new-servey.component.html",
  styleUrls: ["./new-servey.component.css"],
})
export class NewServeyComponent implements OnInit {
  onClose: any;
  constructor(
    public darkThemeService: DarkLiteThemeService,
    private router: Router,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private surveyService: SurveyService,
    public languageService: LanguageService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    const theme = this.darkThemeService.getCurrentTheme;
    const elements = document.querySelectorAll(".modal-backdrop.fade.in.show");
    const backgroundColor = theme === "dark-mode" ? "#000" : "#000";
    elements.forEach((element) => {
      this.renderer.setStyle(element, "background-color", backgroundColor);
    });
  }
  openSurvey() {
    // this.router.navigate(["/new-second-survey"]);
    this.onClose();
    const verifyModel = this.modalService.show(NewServeySecondComponent, {
      class: "new-survey create-survey-modal",
      backdrop: "static",
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
  }

  createCustomSurvey() {
    // this.router.navigate(["/create-custom-survey"]);
    this.onClose();
    const verifyModel = this.modalService.show(NewServeySecondComponent, {
      class: "new-survey create-survey-modal",
      backdrop: "static",
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
  }

  close() {
    this.onClose();
  }
  openComingSoonModal() {
    this.close();
    const entModel = this.modalService.show(CommingSoonModalComponent, {
      class: "redeem-modal-container modal-dialog-centered",

      // backdrop: 'static',
    });
    entModel.content.onClose = () => {
      entModel.hide();
    };
  }
}
