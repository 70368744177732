<form [formGroup]="form">
  <div class="wrapper profile-wrap">
    <div class="interest-sec">
      <!-- <h5 class="sub-title">{{ "profile.Interests" | translate }}</h5> -->
      <div class="d-flex">
        <ng-container *ngFor="let interest of interestsList; let i = index">
          <div class="-input">
            <input (change)="changeInterest($event, interest)" [checked]="isChecked(interest)" type="checkbox"
              id="check{{ i }}" hidden />
            <label for="check{{ i }}" class="ch-label">
              <img [src]="interest.icon?imageUrl+interest.icon:'assets/imgs/new-sidebar/kafu.svg'"
                alt="{{ interest.label}}" />
              <h3>
                {{ interest.label }}
                <span>{{ interest.message }}</span>
              </h3>
            </label>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="btns-sec d-flex col-xs-12">
      <button (click)="save()" class="btn-save m-btn m-btn-green">
        {{ "profile.Save" | translate }}
      </button>
    </div>
  </div>
</form>