import { SurveyReportComponent } from "./survey-report/survey-report.component";
import { SurveyNameComponent } from "./survey-name/survey-name.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { AuthGuard } from "../../services/guards/auth.guard";
import { SurveyOptionsComponent } from "./survey-options/survey-options.component";
import { SurveyPreviewComponent } from "./survey-preview/survey-preview.component";
import { TermsComponent } from "./terms/terms.component";
import { PolicyComponent } from "./policy/policy.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { AnswerSurveyComponent } from "./answer-survey/answer-survey.component";
import { MyAccountComponent } from "./my-account/my-account.component";
import { MyStatsComponent } from "./my-stats/my-stats.component";
import { AllSurveysComponent } from "./all-surveys/all-surveys.component";
import { SurveyDetailsComponent } from "./survey-details/survey-details.component";
import { DesignSurveyComponent } from "./design-survey/design-survey.component";
import { EnterpriseAnswerComponent } from "./enterprise-answer/enterprise-answer.component";
import { ThankYouComponent } from "./thank-you/thank-you.component";
import { AnswerSurveyTwoComponent } from "./answer-survey-two/answer-survey-two.component";
import { WalletComponent } from "./wallet/wallet.component";
import { ReportingComponent } from "./reporting/reporting.component";
import { DashboardComponent } from "./new-dashboard/dashboard/dashboard.component";
import { NewServeyComponent } from "./new-servey/new-servey.component";
import { NewServeySecondComponent } from "./new-servey-second/new-servey-second.component";
import { CreateCustomSurveyComponent } from "./create-custom-survey/create-custom-survey.component";
import { CreateSecondCustomSurveyComponent } from "./create-second-custom-survey/create-second-custom-survey.component";
import { CreateCustomThirdSurveyComponent } from "./create-custom-third-survey/create-custom-third-survey.component";
import { CreateCustomForthSurveyComponent } from "./create-custom-forth-survey/create-custom-forth-survey.component";
import { CustomFifthSurveyComponent } from "./custom-fifth-survey/custom-fifth-survey.component";
import { PricingComponent } from "./pricing/pricing.component";
import { NewMyAccountComponent } from "./new-my-account/new-my-account.component";
import { MyNewDashboardComponent } from "./my-new-dashboard/my-new-dashboard.component";
import { NewMainDashboardComponent } from "./new-main-dashboard/new-main-dashboard.component";
import { NewWalletComponent } from "./new-wallet/new-wallet.component";
import { SurveyBrandListComponent } from "./survey-brand-list/survey-brand-list.component";
import { NewQuesAnswerComponent } from "./new-ques-answer/new-ques-answer.component";
import { NewFinishedSurveyComponent } from "./new-finished-survey/new-finished-survey.component";
import { NewCompletedSurveyComponent } from "./new-completed-survey/new-completed-survey.component";
import { NewPromoCodeComponent } from "./new-promo-code/new-promo-code.component";
const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  { path: "about-us", component: AboutUsComponent },
  { path: "my-stats", component: MyStatsComponent },
  {
    path: "answer-new-ui",
    children: [{ path: ":lang", component: NewQuesAnswerComponent }],
  },
  { path: "new-finished-survey", component: NewFinishedSurveyComponent },
  { path: "new-promo-code", component: NewPromoCodeComponent },
  { path: "new-completed-survey", component: NewCompletedSurveyComponent },
  { path: "new-survey", component: NewServeyComponent },
  { path: "create-custom-survey", component: CreateCustomSurveyComponent },
  { path: "create-custom-survey-list", component: SurveyBrandListComponent },
  {
    path: "create-second-custom-survey",
    component: CreateSecondCustomSurveyComponent,
  },
  {
    path: "create-third-custom-survey",
    component: CreateCustomThirdSurveyComponent,
  },
  {
    path: "create-forth-custom-survey",
    component: CreateCustomForthSurveyComponent,
  },
  { path: "fifth-custom-survey", component: CustomFifthSurveyComponent },
  { path: "new-second-survey", component: NewServeySecondComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "surveys", component: AllSurveysComponent },
  // { path: "surveys", component: AllSurveysComponent },
  { path: "surveys/enterprise", component: EnterpriseAnswerComponent },
  // { path: "profile#Wallet", component: WalletComponent },
  // { path: "profile#Wallet", component: NewWalletComponent },
  { path: "pricing", component: PricingComponent },
  { path: "new-dash", component: NewMainDashboardComponent },
  {
    path: "survey-answer",
    children: [
      { path: "", component: AnswerSurveyTwoComponent },
      { path: ":lang", component: AnswerSurveyTwoComponent },
      { path: ":surveyId/:lang", component: AnswerSurveyTwoComponent },
    ],
  },
  {
    path: "enterprise/survey-answer",
    children: [
      // { path: '', component: AnswerSurveyComponent },
      { path: ":lang", component: AnswerSurveyTwoComponent },
      { path: ":surveyId/:lang", component: AnswerSurveyTwoComponent },
    ],
  },
  {
    path: "profile",
    component: NewMyAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "new-profile",
    component: NewMyAccountComponent,
  },
  // {
  //   path: "survey/create1",
  //   component: CompleteSurveyComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "survey/create",
    component: DesignSurveyComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: "survey/edit",
    component: DesignSurveyComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: "survey/edit",
  //   component: EditSurveyComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "survey/options",
    component: SurveyOptionsComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: "survey/preview/:surveyId",
    component: SurveyPreviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "survey/details",
    component: SurveyReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "terms-conditions",
    component: TermsComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: "privacy-policy",
    component: PolicyComponent,
  },
  {
    path: "reporting",
    component: ReportingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "notifications",
    component: NotificationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "survey/thankyou/:surveyId",
    component: ThankYouComponent,
    // canActivate: [AuthGuard],
  },
  // {
  //   path: "survey/start",
  //   component: SurveyNameComponent,
  // },
  {
    path: "survey/start",
    children: [
      { path: ":lang", component: SurveyNameComponent },
      { path: ":surveyId/:lang", component: SurveyNameComponent },
    ],
  },
  {
    path: "not-found",
    component: NotFoundComponent,
  },
  {
    path: "**",
    redirectTo: "/not-found",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
