// shorten-number.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import * as numeral from 'numeral';

@Pipe({
    name: 'shortenNumber'
})
export class ShortenNumberPipe implements PipeTransform {
    transform(value: number): string {
        const formattedNumber = value ? numeral(value).format('0.00a') : '';
        return formattedNumber;
    }
}
